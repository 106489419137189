import React from 'react';

import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

import { MUNICIPALITY } from 'Constants/job';

interface Props {
  onSelect?: (item: any) => void;
  width?: any;
  limitTags?: any;
  noLabel?: boolean;
  value?: any;
  isMulty?: boolean;
  disabled?: boolean;
}

export default function MunicipalitiesMaterialAsyncSearch({
  onSelect,
  width,
  value,
  limitTags,
  noLabel,
  isMulty,
  disabled,
}: Props) {
  return (
    <Autocomplete
      multiple={isMulty}
      limitTags={limitTags ? limitTags : 1}
      size="small"
      defaultValue={[]}
      value={value}
      id="asynchronous-demo"
      style={{ width, minWidth: 150 }}
      isOptionEqualToValue={(option, value) => value && option.value === value.value}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      options={MUNICIPALITY}
      renderInput={(params) => (
        <TextField
          {...params}
          label={noLabel ? '' : 'Select municipality'}
          variant="outlined"
          placeholder="Municipality"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
      onChange={(event, value) => onSelect(value)}
      disabled={disabled}
    />
  );
}
