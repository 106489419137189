import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ImmutableArray } from 'seamless-immutable';

import { Backdrop, Box, CircularProgress } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { Department, DepartmentGroup } from 'types/Common/Companies';

import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import { useAppSelector } from 'createStore';

import { TABS } from '../constants';
import DepartmentsSettingsHeader from './DepartmentsSettingsHeader';
import DepartmentsTable from './DepartmentsTable';

const DepartmentsSettings = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const departments: ImmutableArray<Department> = useAppSelector((state) => state.app.departments);
  const departmentsGroups: ImmutableArray<DepartmentGroup> = useAppSelector((state) => state.app.departmentGroups);
  const departmentsLoading = useAppSelector((state) => state.app.departmentLoading);
  const departmentGroupsLoading = useAppSelector((state) => state.app.departmentGroupsLoading);
  const [changedDepartments, setChangedDepartments] = useState<{ [key: number]: Department }>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [tab, setTab] = useState<TABS>(TABS.departments);
  const { inProcess, promiseWrapper } = useProcessing();

  const isLoading = departmentsLoading || departmentGroupsLoading;

  const filteredDepartments = useMemo(() => {
    const departmentsByTab = {
      [TABS.departments]: departments?.asMutable({ deep: true }),
      [TABS.departmentsGroup]: departmentsGroups?.asMutable({ deep: true }),
    };
    const departmentsList: Department[] = departmentsByTab[tab] || [];
    if (!searchValue) {
      return departmentsList;
    }
    return departmentsList.filter((department) =>
      searchValue
        .split(' ')
        .filter(Boolean)
        .every((word) =>
          `${department.id || ''} ${department.name || ''}`?.toLowerCase().trim().includes(word.toLowerCase().trim())
        )
    );
  }, [departments, departmentsGroups, searchValue, tab]);

  const changeDepartment = useCallback(
    (id: number, update: Department | null) => {
      if (!update) {
        return setChangedDepartments((prev) => {
          const newChanges = { ...prev };
          delete newChanges[id];
          return newChanges;
        });
      }
      const department = filteredDepartments.find((department) => department.id === id);

      const departmentUpdated = Object.entries(department).some(([key, value]) => value !== update[key]);
      setChangedDepartments((prev) => {
        if (departmentUpdated) {
          return { ...prev, [id]: update };
        }
        const newChanges = { ...prev };
        delete newChanges[id];
        return newChanges;
      });
    },
    [filteredDepartments]
  );

  const isGroup = tab === TABS.departmentsGroup;

  const saveChanges = useCallback(
    async (id: number, updatedDepartment: Department & DepartmentGroup) => {
      if (updatedDepartment.hasOwnProperty('departments')) {
        delete updatedDepartment.departments;
      }

      try {
        await promiseWrapper(
          dispatch(
            isGroup
              ? actions.AppActions.updateDepartmentGroup(id, updatedDepartment)
              : actions.AppActions.updateDepartment(id, updatedDepartment)
          )
        );
        changeDepartment(id, null);
        showSuccessMessage(`Department${isGroup ? 's Group' : ''} updated successfully`);
      } catch (e) {
        showErrorMessage(e);
      }
    },
    [promiseWrapper, dispatch, changeDepartment, isGroup]
  );

  const cancelAllChanges = useCallback(() => {
    setChangedDepartments({});
  }, []);

  const changeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleChangeTab = (event: React.SyntheticEvent, tab: TABS) => {
    setTab(tab);
  };

  useEffect(() => {
    cancelAllChanges();
  }, [tab]);

  return (
    <Box display="flex" flexDirection="column" height="100%" gap="20px">
      <DepartmentsSettingsHeader
        selectedTab={tab}
        onChangeTab={handleChangeTab}
        searchValue={searchValue}
        onChangeSearchValue={changeSearchValue}
        changedDepartments={changedDepartments}
        onCancelChanges={cancelAllChanges}
      />
      <DepartmentsTable
        departments={filteredDepartments}
        changedDepartments={changedDepartments}
        onChangeDepartment={changeDepartment}
        onSaveChanges={saveChanges}
        isGroup={tab === TABS.departmentsGroup}
        loading={inProcess || isLoading}
      />

      <Backdrop
        open={isLoading}
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress size={40} color="primary" />
      </Backdrop>
    </Box>
  );
};

export default DepartmentsSettings;
