import React from 'react';
import { renderToString } from 'react-dom/server';

import { JobShift } from 'types/Common/JobData';

import { JobType } from 'Constants/job';
import UserAvatar from 'Images/user_avatar.png';
import { getWorketStatusString } from 'Utils/worker';

type Props = {
  avatar: string;
  name: string;
  status: number;
  phone: string;
  eta: number;
  jobType: number | string;
};

const WorkerPopup = ({ avatar = '', name = '', status = -1, phone = '', eta = 0, jobType = 1 }: Props) => {
  return (
    <div style={{ display: 'flex', paddingTop: 10 }}>
      <div>
        <img src={avatar || UserAvatar} style={{ width: 40, height: 40, marginRight: 10 }} alt="worker avatar" />
      </div>
      <div>
        <strong>{name}</strong>
        <p style={{ marginBottom: 5 }}>{getWorketStatusString(status, jobType)}</p>
        <a href={`tel:${phone}`}>{phone}</a>
        {eta && status === 1 ? <p>ETA: {eta} min</p> : ''}
      </div>
    </div>
  );
};

export const getWorkerPopupMarkup = (jobShift: JobShift, jobType: JobType | string = 1) => {
  const worker = {
    avatar: jobShift?.worker?.avatar,
    name: jobShift?.worker?.name,
    status: jobShift?.status,
    phone: jobShift?.worker?.phoneNumber,
    eta: jobShift?.eta,
    jobType,
  };
  return renderToString(<WorkerPopup {...worker} />);
};

export default WorkerPopup;
