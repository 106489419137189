export const GET_JOBS_REQUEST = 'jobs.get_jobs_request';
export const GET_JOBS_SUCCESS = 'jobs.get_jobs_success';
export const GET_JOBS_ERROR = 'jobs.get_jobs_error';

export const GET_JOBS_LOCATIONS_REQUEST = 'jobs.get_jobs_locations_request';
export const GET_JOBS_LOCATIONS_SUCCESS = 'jobs.get_jobs_locations_success';
export const GET_JOBS_LOCATIONS_ERROR = 'jobs.get_jobs_locations_error';

export const GET_JOBS_LOCATIONS_FILTERS_STATS = 'jobs.get_jobs_locations_filters_stats';

export const RETRIEVE_LOCATION_JOB_REQUEST = 'jobs.retrieve_location_job_request';
export const RETRIEVE_LOCATION_JOB_SUCCESS = 'jobs.retrieve_location_job_success';
export const RETRIEVE_LOCATION_JOB_ERROR = 'jobs.retrieve_location_job_error';

export const RECONSTRUCT_JOB_REQUEST = 'jobs.reconstruct_job_request';
export const RECONSTRUCT_JOB_SUCCESS = 'jobs.reconstruct_job_success';
export const RECONSTRUCT_JOB_ERROR = 'jobs.reconstruct_job_error';

export const ADD_WORKER_REQUEST = 'jobs.add_worker_request';
export const ADD_WORKER_SUCCESS = 'jobs.add_worker_success';
export const ADD_WORKER_ERROR = 'jobs.add_worker_error';

export const UPDATE_JOB_WORKER_REQUEST = 'jobs.update_job_worker_request';
export const UPDATE_JOB_WORKER_SUCCESS = 'jobs.update_job_worker_success';
export const UPDATE_JOB_WORKER_ERROR = 'jobs.update_job_worker_error';

export const ADD_JOB_LOCATION_REQUEST = 'jobs.add_job_location_request';
export const ADD_JOB_LOCATION_SUCCESS = 'jobs.add_job_location_success';
export const ADD_JOB_LOCATION_ERROR = 'jobs.add_job_location_error';

export const UPDATE_JOB_LOCATION_REQUEST = 'jobs.update_job_location_request';
export const UPDATE_JOB_LOCATION_SUCCESS = 'jobs.update_job_location_success';
export const UPDATE_JOB_LOCATION_ERROR = 'jobs.update_job_location_error';

export const DELETE_JOB_LOCATION_REQUEST = 'jobs.delete_job_location_request';
export const DELETE_JOB_LOCATION_SUCCESS = 'jobs.delete_job_location_success';
export const DELETE_JOB_LOCATION_ERROR = 'jobs.delete_job_location_error';

////

export const RETRIEVE_JOB_REQUEST = 'jobs.retrieve_job_request';
export const RETRIEVE_JOB_SUCCESS = 'jobs.retrieve_job_success';
export const RETRIEVE_JOB_ERROR = 'jobs.retrieve_job_error';

export const CLEAR_JOB = 'jobs.clear_job';

export const UPDATE_JOB_REQUEST = 'jobs.update_job_request';
export const UPDATE_JOB_SUCCESS = 'jobs.update_job_success';
export const UPDATE_JOB_ERROR = 'jobs.update_job_error';

export const UPDATE_JOB_STATUS_REQUEST = 'jobs.update_job_status_request';
export const UPDATE_JOB_STATUS_SUCCESS = 'jobs.update_job_status_success';
export const UPDATE_JOB_STATUS_ERROR = 'jobs.update_job_status_error';

export const UPDATE_JOB_WORKERS_REQUEST = 'jobs.update_job_workers_request';
export const UPDATE_JOB_WORKERS_SUCCESS = 'jobs.update_job_workers_success';
export const UPDATE_JOB_WORKERS_ERROR = 'jobs.update_job_workers_error';

export const JOB_UPLOAD_IMAGE_REQUEST = 'jobs.job_upload_image_request';
export const JOB_UPLOAD_IMAGE_SUCCESS = 'jobs.job_upload_image_success';
export const JOB_UPLOAD_IMAGE_ERROR = 'jobs.job_upload_image_error';

export const JOB_CREATE_REQUEST = 'jobs.job_create_request';
export const JOB_CREATE_SUCCESS = 'jobs.job_create_success';
export const JOB_CREATE_ERROR = 'jobs.job_create_error';

export const ADD_SHIFT_REQUEST = 'jobs.add_shift_request';
export const ADD_SHIFT_SUCCESS = 'jobs.add_shift_success';
export const ADD_SHIFT_ERROR = 'jobs.add_shift_error';

export const ASSIGN_WORKERS_TO_JOB = 'jobs.assign_workers_to_job';

export const CALCULATE_JOBS_STATS = 'jobs.calculate_jobs_stats';

export const CONFIRM_JOB_REQUEST = 'jobs.confirm_job_request';
export const CONFIRM_JOB_SUCCESS = 'jobs.confirm_job_success';
export const CONFIRM_JOB_ERROR = 'jobs.confirm_job_error';

export const UPDATE_PO_NUMBER_REQUEST = 'jobs.update_po_number_request';
export const UPDATE_PO_NUMBER_SUCCESS = 'jobs.update_po_number_success';
export const UPDATE_PO_NUMBER_ERROR = 'jobs.update_po_number_error';

export const REPLACE_JOB_WORKER_REQUEST = 'jobs.replace_job_worker_request';
export const REPLACE_JOB_WORKER_SUCCESS = 'jobs.replace_job_worker_success';
export const REPLACE_JOB_WORKER_ERROR = 'jobs.replace_job_worker_error';

export const RELEASE_JOB_WORKERS_REQUEST = 'jobs.release_job_workers_request';
export const RELEASE_JOB_WORKERS_SUCCESS = 'jobs.release_job_workers_success';
export const RELEASE_JOB_WORKERS_ERROR = 'jobs.release_job_workers_error';

export const INTEGRATION_REROUTE_REQUEST = 'jobs.integration_reroute_request';
export const INTEGRATION_REROUTE_SUCCESS = 'jobs.integration_reroute_success';
export const INTEGRATION_REROUTE_ERROR = 'jobs.integration_reroute_error';

export const OPEN_WORKER_PLAYBACK_MAP = 'jobs.open_worker_playback_map';
export const CLOSE_WORKER_PLAYBACK_MAP = 'jobs.close_worker_playback_map';

export const OPEN_EXTEND_JOB_TIME = 'jobs.open_extend_job_time';
export const CLOSE_EXTEND_JOB_TIME = 'jobs.close_extend_job_time';

export const EXTEND_WMS_JOB_END_TIME_REQUEST = 'jobs.extend_wms_job_end_time_request';
export const EXTEND_WMS_JOB_END_TIME_SUCCESS = 'jobs.extend_wms_job_end_time_success';
export const EXTEND_WMS_JOB_END_TIME_ERROR = 'jobs.extend_wms_job_end_time_error';

export const OPEN_ASSIGN_COMPANIES = 'jobs.open_assign_companies';
export const CLOSE_ASSIGN_COMPANIES = 'jobs.close_assign_companies';

export const SOCKET_JOB_UPDATED = 'socket.jobUpdated';

export const UPDATE_LOCATIONS_FILTERS = 'jobs.update_locations_filters';
export const UPDATE_FILTERS = 'jobs.update_filters';
export const SORT_ASAP = 'jobs.sort_asap';
