import { ReportType } from 'Constants/reports';
import ApiService from 'Services/HttpService';

export type ReportParams = {
  cutOffTimeStart?: string;
  cutOffTimeFinish?: string;
  municipalities?: number[];
  report_type?: string;
  date?: string;
};

class ReportsAPI {
  #TYPE = Object.freeze(ReportType);

  get TYPE() {
    return this.#TYPE;
  }

  getReportByType = async (type: (typeof this.TYPE)[keyof typeof this.TYPE], reportParams?: ReportParams) => {
    return ApiService.GET<Blob>(`/report/${type}`, reportParams);
  };

  downloadReportByType = async (type: (typeof this.TYPE)[keyof typeof this.TYPE], reportParams?: ReportParams) => {
    const { data } = await ApiService.DOWNLOAD_FILE(`/report/${type}`, reportParams);
    return data;
  };
}

export default new ReportsAPI();
