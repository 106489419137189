import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import moment from 'moment';

import { Box, Checkbox, SxProps, alpha } from '@mui/material';
import { red, grey, common } from '@mui/material/colors';

import { AppThunkDispatch } from 'types';
import { TimesheetTableItem, TimesheetTableItemWithColor } from 'types/Timesheet';

import { JobStatus } from 'Constants/job';
import { TimesheetStatus } from 'Constants/timesheet';
import { actions } from 'Services';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import usePagination from 'Utils/hooks/usePagination';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { ReduxState, useAppSelector } from 'createStore';

import { ActionButton, SelectedTimesheetsSummary } from '..';
import TimesheetActions from '../../Dialog/TimesheetActions/TimesheetActions';
import TimesheetTable from '../../TimesheetTable';
import { createTableColumnsConfig, useTimesheetActions } from '../../utils';
import MasterTimesheetOpenButton from '../MasterTimesheetOpenButton';
import MobileTimesheetsTable from '../MobileTimesheetsTable/MobileTimesheetsTable';
import PaidChips from '../PaidChips';
import TrimmedText from '../TrimmedText';
import VerifiedChip from '../VerifiedChip';

type Props = RouteComponentProps<{}>;

const overrides = {
  checkbox: {
    zIndex: 3,
    position: 'sticky',
    left: 0,
  },
};

const parseTableRowSx = ({ color, pending }: TimesheetTableItemWithColor): SxProps => {
  const hoverColor = color && alpha(color, 0.5);
  const selectedColor = color && alpha(color, 0.9);
  const fullColor = color && alpha(color, 1);

  return {
    cursor: 'pointer',
    bgcolor: color || (pending ? red[50] : common.white),
    transition: 'background-color 0.15s',

    '& .MuiTableCell-paddingCheckbox': {
      bgcolor: color ? fullColor : pending ? red[50] : common.white,
      transition: 'background-color 0.15s',
    },

    '&:hover': {
      bgcolor: color ? hoverColor : pending ? red[100] : grey[100],

      '& .MuiTableCell-paddingCheckbox': {
        bgcolor: color ? fullColor : pending ? red[100] : grey[100],
      },
    },

    '&.Mui-selected': {
      bgcolor: color ? selectedColor : pending ? red[200] : grey[200],

      '& .MuiTableCell-paddingCheckbox': {
        bgcolor: color ? fullColor : pending ? red[200] : grey[200],
      },

      '&:hover': {
        bgcolor: color ? fullColor : pending ? red[300] : grey[300],

        '& .MuiTableCell-paddingCheckbox': {
          bgcolor: color ? fullColor : pending ? red[300] : grey[300],
        },
      },
    },
  };
};

const MainTimesheetsTable = ({ history }: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [checkedRowsIds, setCheckedRowsIds] = useState(new Set<number>());

  const { selectedRow, anchorEl, open, close, edit } = useTimesheetActions<TimesheetTableItemWithColor>({ history });

  const { results: timesheets, current_page, total, per_page } = useAppSelector((state) => state.timesheets.timesheets);
  const searchOptions = useAppSelector((state) => state.timesheets.search_options);

  const canSeeSubcontractors = useAppSelector((state) => state.userPermissions.canSeePage.subcontractors);

  const updateFilters = (search_options: Partial<ReduxState['timesheets']['search_options']>) =>
    dispatch(actions.TimesheetsActions.updateFilters(search_options));

  const { onChangePage, onPerPageChange } = usePagination(updateFilters);

  const { isDesktop } = useAppMediaQuery();

  const checkTimesheet =
    (id = 0) =>
    (event: React.FormEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!id) return;
      setCheckedRowsIds((prev) => {
        const copy = new Set(prev);
        if (prev.has(id)) {
          copy.delete(id);
        } else {
          copy.add(id);
        }
        return copy;
      });
    };
  const resetAllChecked = () => setCheckedRowsIds(new Set());

  const toggleAllChecked = () => {
    setCheckedRowsIds((prev) => {
      if (prev.size && prev.size === timesheets.length) {
        return new Set();
      }
      return new Set(timesheets.map(({ id }) => id));
    });
  };
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  useEffect(() => {
    resetAllChecked();
  }, [searchOptions]);

  const selectedTimesheets = useMemo(
    () => timesheets.asMutable({ deep: true }).filter(({ id }) => checkedRowsIds.has(id)),
    [checkedRowsIds, timesheets]
  );

  const changeSelectedTimesheets = useCallback(
    (newSelected: TimesheetTableItem[] = []) => {
      setCheckedRowsIds(new Set(newSelected.map(({ id }) => id)));
    },
    [checkedRowsIds, timesheets]
  );

  const TimesheetColumns = createTableColumnsConfig<TimesheetTableItem>([
    {
      label: (
        <Checkbox
          sx={{ p: '0 0.5rem' }}
          checked={checkedRowsIds.size === timesheets.length}
          indeterminate={Boolean(checkedRowsIds.size && checkedRowsIds.size < timesheets.length)}
          onChange={toggleAllChecked}
        />
      ),
      headCellProps: () => ({ sx: { ...overrides.checkbox, zIndex: 4 } }),
      bodyCellProps: () => ({
        padding: 'checkbox',
        onClick: stopPropagation,
        sx: overrides.checkbox,
        sticky: 'true',
      }),
      renderData: (row) => (
        <Checkbox
          sx={{ p: '0 0.5rem' }}
          checked={checkedRowsIds.has(row.id)}
          onChangeCapture={checkTimesheet(row.id)}
        />
      ),
    },
    {
      label: 'Start Date',
      value: 'timesheets.start_at',
      key: 'startDate',
      sortable: true,
      renderData: (row) => moment(row.startDate).format('MM/DD/YY [•] HH:mm'),
    },
    {
      label: 'Finish Date',
      value: 'timesheets.finish_at',
      key: 'finishDate',
      sortable: true,
      renderData: (row) => moment(row.finishDate).format('MM/DD/YY [•] HH:mm'),
    },
    {
      label: 'Confirmation №',
      value: 'timesheets.job_id',
      sortable: true,
      key: 'confirmationNumber',
      renderData: ({ confirmationNumber, pending }) => `${confirmationNumber} ${pending ? '(Pending)' : ''}`,
      bodyCellProps: ({ is_asap }) => ({ style: is_asap ? { color: 'red' } : {} }),
    },
    { label: 'Shift Id', value: 'timesheets.job_worker_id', key: 'job_worker_id', sortable: true },
    { label: 'Total Hrs', value: 'totalHours', key: 'totalHours', sortable: true },
    { label: 'Worker', value: 'worker_name', key: 'worker_name', sortable: true },
    {
      label: 'Subcontractor',
      value: 'companies.name',
      sortable: true,
      renderData: ({ subcontractorName }) => <TrimmedText text={subcontractorName} length={150} />,
    },
    {
      label: 'PO#',
      value: 'jobs.po_number',
      sortable: true,
      renderData: ({ po }) => <TrimmedText text={po} />,
    },
    {
      label: 'Job Status',
      value: 'jobs.status',
      sortable: true,
      key: 'job_status',
      renderData: (row) => JobStatus.parsed(row.job_status),
    },
    {
      label: 'Status',
      value: 'status',
      key: 'status',
      sortable: true,
      renderData: (row) => TimesheetStatus.toUserString(row.status),
    },
    {
      label: 'CE Verified',
      value: 'supervisor_verified',
      key: 'supervisor_verified',
      sortable: true,
      renderData: (row) => (
        <VerifiedChip
          isVerified={row.supervisor_verified}
          signaturesCount={row.signatures_count}
          neededSignaturesCount={row.needed_signatures_count}
          title="CE"
        />
      ),
    },
    {
      label: 'CES Verified',
      value: 'timesheets.verified',
      key: 'isVerified',
      sortable: true,
      renderData: (row) => <VerifiedChip isVerified={row.isVerified} title="CES" />,
    },
    {
      label: 'Paid Status',
      value: 'paid',
      key: 'paid',
      sortable: true,
      renderData: (row) => <PaidChips paid={row.paid} workerPaid={row.workerPaid} invoiced={row.invoiced} />,
    },
    {
      label: 'Actions',
      value: 'actions',
      renderData: (row) => (
        <Box display="flex" gap={1}>
          <MasterTimesheetOpenButton
            confirmation={row.confirmationNumber}
            jobType={row.job_type}
            jobStatus={row.job_status}
          />

          <ActionButton onClick={(e) => open(e, row)} />
        </Box>
      ),
    },
  ]);

  return (
    <>
      <div className="timesheets-tables-wrap">
        <SelectedTimesheetsSummary
          timesheets={selectedTimesheets}
          canSeeSubcontractors={canSeeSubcontractors}
          changeSelectedTimesheets={changeSelectedTimesheets}
        />
        {isDesktop ? (
          <TimesheetTable<TimesheetTableItemWithColor>
            data={timesheets}
            changeOrder={updateFilters}
            orderByType={searchOptions.order_by_type}
            orderByValue={searchOptions.order_by}
            columnsConfig={TimesheetColumns}
            tableRowProps={(row) => ({
              onClick: () => edit(row.id),
              selected: checkedRowsIds.has(row.id),
              sx: parseTableRowSx(row),
              key: row.id,
            })}
          />
        ) : (
          <MobileTimesheetsTable
            data={timesheets}
            columnsConfig={TimesheetColumns}
            selectedTimesheets={selectedTimesheets}
          />
        )}

        <TimesheetActions handleClose={close} anchorEl={anchorEl} timesheet={selectedRow} editTimesheet={edit} />
      </div>

      <AppTablePagination
        page={current_page}
        onPaginationChange={onPerPageChange}
        total={total}
        perPage={per_page}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MainTimesheetsTable;
