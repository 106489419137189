import { ComponentProps, FC, forwardRef, useState } from 'react';

import PhoneInput, { Country } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import { TestKey } from 'Constants/tests';
import { supportedPhoneCountries } from 'Constants/validation';
import AppInputField from 'components/AppInputField/AppInputField';

import CountrySelect, { CountrySelectProps } from './CountrySelect';

type Props = ComponentProps<typeof PhoneInput> & {
  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    [TestKey]: string;
  };
  countrySelectProps?: Omit<CountrySelectProps, 'country' | 'onSelect' | 'options'>;
};

const ForwardedInput = forwardRef<HTMLInputElement>((props, ref) => <AppInputField {...props} inputRef={ref} />);

const PhoneNumberInput: FC<Props> = (props) => {
  const [defaultCountry, setDefaultCountry] = useState<Country>(props.defaultCountry || 'US');

  const changeCountry = (country: Country) => {
    setDefaultCountry(country);

    props.onChange?.('');
  };

  return (
    <PhoneInput
      {...props}
      international
      countryCallingCodeEditable={false}
      defaultCountry={defaultCountry}
      countryOptionsOrder={supportedPhoneCountries}
      countries={props.countries || supportedPhoneCountries}
      onCountryChange={setDefaultCountry}
      inputComponent={ForwardedInput}
      InputProps={{
        startAdornment: (
          <CountrySelect
            country={defaultCountry}
            onSelect={changeCountry}
            options={supportedPhoneCountries}
            {...props.countrySelectProps}
          />
        ),
      }}
      inputProps={{
        ...props.inputProps,
        sx: { paddingLeft: '0 !important' },
      }}
      countrySelectComponent={() => <></>}
      placeholder="Enter phone number"
    />
  );
};

export default PhoneNumberInput;
