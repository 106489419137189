import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';

import { isEqual } from 'lodash';
import { BsArrowRightShort } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';

import { LoadingButton } from '@mui/lab';
import { Button, Tooltip } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { JobLocation } from 'types/Job';

import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import { generateLocationImageName } from 'Containers/Job/utils';
import { actions } from 'Services';
import { JobsAPI } from 'Services/API';
import FilesUpload, { loadFilesFromDevice } from 'Utils/FilesUpload';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import fileSizeFilter from 'Utils/fileSizeFilter';
import { openGoogleMapStreetView } from 'Utils/links';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppSelector } from 'createStore';

import s from './LocationsPhotoEdit.module.scss';
import { fileToLocationImage, imageConverter, LocationImage } from './helpers';
import useMultipleDrop from './useMultipleDrop';

type Props = {
  id?: string;
  jobId: number;
  open: boolean;
  onClose: () => void;
  locations: JobLocation[];
  jobType: string | number;
};

const LocationsPhotoEdit = ({
  jobId = 0,
  id = 'photoEditRoot',
  open = false,
  onClose = () => {},
  locations,
  jobType,
}: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const userId = useAppSelector((state) => state.app.user.id);
  const [root, setRoot] = useState<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [jobLocations, setJobLocations] = useState(imageConverter(locations));
  const { openPopover, closePopover } = usePopoverContext();

  const { dropZoneRefs, dropZoneContainerRefs, dropZoneHandlers } = useMultipleDrop({
    acceptType: 'image/',
    classNames: {
      dropzoneContainer: s.dropzone,
      dropzoneHover: s.dropzone_hover,
      dropzoneVisible: s.dropzoneBorderVisible,
    },
    onDrop: (files, elemId) =>
      setJobLocations((prev) =>
        prev.map((loc) =>
          loc.id === parseInt(elemId)
            ? {
                ...loc,
                images: loc.images.concat(fileSizeFilter(files).map(fileToLocationImage)),
              }
            : loc
        )
      ),
  });

  useEffect(() => {
    let photoEditRoot = document.getElementById(id) as HTMLDivElement;
    if (!photoEditRoot) {
      photoEditRoot = document.createElement('div');
      photoEditRoot.id = id;
      document.body.appendChild(photoEditRoot);
    }
    setRoot(photoEditRoot);
    return () => {
      photoEditRoot.remove();
      setRoot(null);
    };
  }, [id]);

  useEffect(() => {
    if (!open) {
      setJobLocations([]);
      return;
    }
    setJobLocations(imageConverter(locations));
  }, [open, locations]);

  //   useEffect(() => {
  //     document.addEventListener('paste', (event) => {
  //       event.preventDefault();
  //       if (!event.clipboardData.files.length) return;
  //       const images = Array.from(event.clipboardData.files).filter((file) => file.type.includes('image/'));
  //       setJobLocations((prev) => prev.map((loc) => ({ ...loc, images: loc.images.concat(images) })));
  //     });
  //   }, []);

  const openGoogleMap =
    (lat = 0, lng = 0) =>
    () =>
      openGoogleMapStreetView({ lat, lng });

  const loadImageFromPc =
    (locationId = 0, locationImages: LocationImage[]) =>
    async () => {
      const files = await loadFilesFromDevice({ multiple: true, acceptType: 'image/*' });
      const newFiles = fileSizeFilter(files).filter((file) => locationImages.every((img) => img.name !== file.name));
      if (!newFiles.length) return;
      setJobLocations((prev) =>
        prev.map((loc) =>
          loc.id === locationId ? { ...loc, images: [...loc.images, ...newFiles.map(fileToLocationImage)] } : loc
        )
      );
    };

  const submitPhotos = async () => {
    setLoading(true);
    const update = {
      locations: jobLocations.map((loc) => ({
        ...loc,
        images: loc.images.map((image) => (image.isFile ? image.FileInstance : image.plainImage)),
        files: [] as string[],
      })),
    };
    for (let x = 0; x < update.locations.length; x++) {
      const location = update.locations[x];
      if (location.images && location.images.length > 0) {
        const imageFiles = new FilesUpload(update.locations[x].images, 'image_');

        try {
          if (!imageFiles.isAllFilesUploaded) {
            await imageFiles.uploadNew(JobsAPI.uploadImages);
          }
          update.locations[x].files = imageFiles.allUploaded;
        } catch (error) {
          throw new Error('');
        }
      } else {
        update.locations[x].files = [];
      }
    }
    try {
      await dispatch(actions.JobsActions.updateJobImages(jobId, update));
      showSuccessMessage('Photo updated successfully!');
      onClose();
    } catch (err) {
      showErrorMessage(err);
    } finally {
      setLoading(false);
    }
  };

  const removeImage = async (newImage: LocationImage, locationId: number) => {
    try {
      await new Promise<void>((resolve, reject) => {
        openPopover(
          'remove-image-confirmation',
          <AppPaperModal
            open
            modalId="remove-image-confirmation"
            onClose={() => closePopover('remove-image-confirmation')}
            submitButton={{
              title: 'Yes',
              onClick: () => resolve(),
            }}
            cancelButton={{
              title: 'Cancel',
              onClick: () => reject(),
            }}
            title={'Remove Image'}
          >
            <p>Are you sure you want to remove this image?</p>
          </AppPaperModal>
        );
      });

      if (!newImage) return;
      const cleanImageUrl = newImage.url.replace(/\?time=.*/, '');

      setJobLocations((prev) =>
        prev.map((loc) =>
          locationId === loc.id ? { ...loc, images: loc.images.filter((img) => img.url !== cleanImageUrl) } : loc
        )
      );
    } catch (error) {
      return;
    } finally {
      closePopover('remove-image-confirmation');
    }
  };

  if (!root || !open) return null;
  return createPortal(
    <div
      className={s.modalWrap}
      onClick={({ target, currentTarget }) => target === currentTarget && onClose()}
      {...dropZoneHandlers}
    >
      <div className={s.container}>
        <h4 className={s.title}>Edit Location Photos</h4>
        <div className={s.additionalText}>
          <p>You can add new photos using the "Add New" button</p>
          <p>
            You can go to the map and take a screenshot, or simply drag and drop the photo to the desired location row
          </p>
        </div>
        <ol className={s.locationsList}>
          {jobLocations.map(({ address, id, lat, lng, images, structure }) => (
            <li key={id} className={s.locationRow}>
              <div className={s.addressRow}>
                {address}
                <div className={s.buttonsContainer}>
                  <Tooltip disableInteractive title="Load new photo from PC">
                    <Button startIcon={<MdAdd size={16} fontWeight={500} />} onClick={loadImageFromPc(id, images)}>
                      Add New
                    </Button>
                  </Tooltip>
                  <Tooltip disableInteractive title="Open Google Map Street View with current location">
                    <Button
                      startIcon={<BsArrowRightShort size={16} fontWeight={500} />}
                      onClick={openGoogleMap(lat, lng)}
                    >
                      Go To Map
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div ref={(ref) => dropZoneContainerRefs.set(id.toString(), ref)}>
                <div id={id.toString()} ref={(ref) => dropZoneRefs.set(id.toString(), ref)} />
                <ImageViewer
                  images={images}
                  jobType={jobType}
                  showImagesRow
                  onSaveRotatedImage={() => dispatch(actions.JobsActions.retrieveJob(jobId))}
                  canRemoveImage={(image) =>
                    Boolean(image.name || image.user?.id === userId || UserPermissions.has.delete_job_photos)
                  }
                  onRemoveImage={(image) => removeImage(image, id)}
                  getDownloadedImageName={({ created_at }) =>
                    generateLocationImageName({
                      jobId,
                      address,
                      updated_at: created_at,
                      structure,
                    })
                  }
                />
              </div>
            </li>
          ))}
        </ol>
        <div className={s.actionButtons}>
          <Button variant="outlined" color="secondary" size="large" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            sx={{ px: 6 }}
            size="large"
            variant="contained"
            loading={loading}
            loadingIndicator="Saving..."
            disabled={isEqual(jobLocations, imageConverter(locations))}
            onClick={submitPhotos}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>,
    root
  );
};

export default LocationsPhotoEdit;
