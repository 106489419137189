import { FC } from 'react';

import { Box, Skeleton } from '@mui/material';

import NotificationItemSkeleton from 'Containers/Notifications/components/NotificationItem/NotificationItem.Skeleton';
import { repeatSkeleton } from 'Utils/skeleton';

import styles from './NotificationsList.module.scss';

const NotificationsListSkeleton: FC = () => {
  return (
    <Box className={styles.notificationsList}>
      {repeatSkeleton(10, (index) => (
        <NotificationItemSkeleton key={'notifications-item-skeleton-' + index} />
      ))}
    </Box>
  );
};

export default NotificationsListSkeleton;
