export const GET_WORKERS_REQUEST = 'workers.get_workers_request';
export const GET_WORKERS_SUCCESS = 'workers.get_workers_success';
export const GET_WORKERS_ERROR = 'workers.get_workers_error';

export const UPDATE_WORKER_STATUS_REQUEST = 'workers.update_worker_status_request';
export const UPDATE_WORKER_STATUS_SUCCESS = 'workers.update_worker_status_success';
export const UPDATE_WORKER_STATUS_ERROR = 'workers.update_worker_status_error';

export const UPDATE_WORKER_REQUEST = 'workers.update_worker_request';
export const UPDATE_WORKER_SUCCESS = 'workers.update_worker_success';
export const UPDATE_WORKER_ERROR = 'workers.update_worker_error';

export const UPLOAD_IMAGE_REQUEST = 'workers.UPLOAD_IMAGE_request';
export const UPLOAD_IMAGE_SUCCESS = 'workers.UPLOAD_IMAGE_success';
export const UPLOAD_IMAGE_ERROR = 'workers.UPLOAD_IMAGE_error';

export const EXPORT_TO_EXCEL_REQUEST = 'workers.export_to_excel_request';
export const EXPORT_TO_EXCEL_SUCCESS = 'workers.export_to_excel_success';
export const EXPORT_TO_EXCEL_ERROR = 'workers.export_to_excel_error';

export const UPDATE_FILTER_OPTIONS = 'workers.update_filter_options';
export const UPDATE_PAGINATION = 'workers.update_pagination';
