import { useMemo, useState } from 'react';

import Collapsible from 'react-collapsible';
import { ImmutableArray } from 'seamless-immutable';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';

import { TimesheetTableItemWithColor } from 'types/Timesheet';

import { TableColumnData } from 'Containers/Timesheets/utils';

type Props = {
  data: TimesheetTableItemWithColor[] | ImmutableArray<TimesheetTableItemWithColor>;
  columnsConfig: TableColumnData<TimesheetTableItemWithColor>[];
  selectedTimesheets: TimesheetTableItemWithColor[];
};

const MobileTimesheetsTable = function ({ data = [], columnsConfig = [], selectedTimesheets = [] }: Props) {
  const timesheetsIds = useMemo(() => data.map(({ id }: TimesheetTableItemWithColor) => id), [data]);
  const [openedTimesheetsIds, setOpenedTimesheetsIds] = useState<number[] | ImmutableArray<number>>(timesheetsIds);
  const selectedTimesheetsIds = useMemo(() => selectedTimesheets.map(({ id }) => id), [selectedTimesheets]);
  const {
    timesheetSelect: { label: toggleSelectAllTimesheetCheckbox, renderData: selectTimesheetCheckbox },
    timesheetActions: { renderData: timesheetActionsMenu },
    timesheetData,
  } = useMemo(() => {
    const [timesheetSelect, ...restColumns] = columnsConfig;
    const [timesheetActions, ...timesheetData] = [...restColumns].reverse();
    return { timesheetSelect, timesheetActions, timesheetData: timesheetData.reverse() };
  }, [columnsConfig]);

  const openAllTimesheets = () => setOpenedTimesheetsIds(timesheetsIds);

  const closeAllTimesheets = () => setOpenedTimesheetsIds([]);

  const toggleOpenedTimesheet = (id: number) => {
    if (openedTimesheetsIds.includes(id)) {
      setOpenedTimesheetsIds(openedTimesheetsIds.filter((openedId) => openedId !== id));
    } else {
      setOpenedTimesheetsIds([...openedTimesheetsIds, id]);
    }
  };

  const toggleOpenAllTimesheets = () => {
    if (openedTimesheetsIds.length) {
      closeAllTimesheets();
    } else {
      openAllTimesheets();
    }
  };

  const isTimesheetSelected = (id: number) => selectedTimesheetsIds.includes(id);

  const isTimesheetDetailsOpened = (id: number) => openedTimesheetsIds.includes(id);

  return (
    <Box display="flex" flexDirection="column" overflow="hidden">
      <Box
        width="100%"
        bgcolor="#ffffff"
        p={2}
        zIndex={1}
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        border="1px solid #fff"
        borderRadius="16px 16px 0 0"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {toggleSelectAllTimesheetCheckbox}
          <b>{selectedTimesheets.length === data.length ? 'Unselect all timesheets' : 'Select all timesheets'}</b>
        </Box>
        <Tooltip
          title={openedTimesheetsIds.length ? 'All timesheets expanded' : 'All timesheets collapsed'}
          disableInteractive
          disableHoverListener
          enterTouchDelay={0}
          leaveTouchDelay={1000}
        >
          <IconButton color="primary" onClick={toggleOpenAllTimesheets}>
            {openedTimesheetsIds.length ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      <Box overflow="auto" sx={{ scrollSnapType: 'y mandatory' }}>
        {data.map((timesheet: TimesheetTableItemWithColor) => (
          <Box
            position="relative"
            key={timesheet.id}
            border={`1px solid transparent`}
            borderBottom={`1px solid #00000010`}
            p={2}
            display="flex"
            bgcolor={timesheet.color || (timesheet.pending ? '#f5e6e6' : 'white')}
            flexDirection="column"
            sx={{ scrollSnapAlign: 'start', transition: 'all 0.15s ease-in-out' }}
            borderColor={(theme) => isTimesheetSelected(timesheet.id) && theme.palette.primary.main}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" gap={1}>
                <Box display="flex" alignItems="center">
                  {selectTimesheetCheckbox(timesheet)}
                  <Typography variant="body2">
                    <b>Shift #{timesheet.job_worker_id}</b>
                  </Typography>
                </Box>
                <Button
                  endIcon={
                    <ExpandMoreIcon
                      fontSize="small"
                      sx={{
                        transition: 'scale .15s ease-in-out',
                        scale: isTimesheetDetailsOpened(timesheet.id) && '1 -1',
                      }}
                    />
                  }
                  onClick={() => toggleOpenedTimesheet(timesheet.id)}
                >
                  {isTimesheetDetailsOpened(timesheet.id) ? 'Collapse' : 'Expand'}
                </Button>
              </Box>
              <Box>{timesheetActionsMenu(timesheet)}</Box>
            </Box>

            <Collapsible
              transitionTime={150}
              triggerDisabled
              trigger={''}
              open={isTimesheetDetailsOpened(timesheet.id)}
            >
              {timesheetData.map((column, index) => {
                const { value: fieldValue, renderData: renderValue, label: fieldName, key } = column;
                return (
                  <Box
                    key={key}
                    display="flex"
                    borderBottom={`1px solid #00000007`}
                    gap={1}
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="transparent"
                    borderTop={index === 0 ? '1px solid #00000010' : 'none'}
                    mt={index === 0 ? 1 : 0}
                    pt={index === 0 ? 1 : 0}
                  >
                    <Typography variant="body1">{fieldName}</Typography>
                    <Box fontSize={16} textAlign={'right'}>
                      {renderValue ? renderValue(timesheet) : timesheet[fieldValue]}
                    </Box>
                  </Box>
                );
              })}
            </Collapsible>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MobileTimesheetsTable;
