import { FC } from 'react';

import moment from 'moment';

import { ComplaintNote } from 'Models/APITypes';

type Props = {
  note: ComplaintNote;
  title?: string;
};

const ComplaintItemNote: FC<Props> = ({ note, title }) => {
  return (
    <>
      {title && (
        <div className="job-item border-bottom d-flex justify-content-between align-items-center header-item">
          <div className="d-flex align-items-center">
            <span className="ce-title">{title}</span>
          </div>
        </div>
      )}
      <div
        className="job-history-item pb-3"
        style={{
          padding: 20,
          paddingBottom: 0,
        }}
      >
        <div className="timeline">
          <div className="date">
            {moment(note.created_at).format('MM/DD/YY')}
            <br />
            {moment(note.created_at).format('HH:mm:ss')}
          </div>
          <div className="circle">
            <div className="point"></div>
          </div>
        </div>
        <div className="job-history-item-content">
          <div className="mb-4">
            <span className="mr-3">
              <span className="text-bold">Source</span>: {note.source ? note.source : 'Web'}
            </span>
            <br className="d-block d-md-none" />
            <span className="text-bold">Change Made By</span>: {note?.added_by_user_name || ''}
          </div>
          <div>{note.body && <td>{note.body}</td>}</div>
        </div>
      </div>
    </>
  );
};

export default ComplaintItemNote;
