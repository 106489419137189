import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import { maximumYearValue } from 'Constants/validation';
import { FORMATS } from 'Utils/Date';

export const EditDateTimeValidationSchema = Yup.object({
  start_at: Yup.string()
    .label('Start date')
    .nullable()
    .when('dates_required', ([dates_required]: [boolean], schema) => (dates_required ? schema.required() : schema))

    .when(['requestDate', 'dates_required'], ([requestDate = null, dates_required]: [Moment, boolean], schema) => {
      const minDate = requestDate || moment().set('year', 2020).startOf('year');
      const validationMessage = requestDate
        ? `You cannot set start date of timesheet earlier than the job start date (${requestDate.format(
            FORMATS.datetime
          )})`
        : `Start date must be after ${minDate.format('DD/MM/YYYY')}`;
      const validationCallback = (value: string) => {
        if (!value && !dates_required) return true;
        return moment(value).isAfter(minDate);
      };
      return schema
        .test('afterMinDate', validationMessage, validationCallback)
        .test('toLate', 'Start date is too late', (value) => {
          if (!value && !dates_required) return true;
          return moment(value).isBefore(maximumYearValue);
        });
    }),

  finish_at: Yup.string()
    .label('Finish date')
    .nullable()
    .test('beforeStart', 'Finish date must be after start date', function (value) {
      const { start_at } = this.parent;
      if (!start_at) return true;
      return moment(value).isAfter(moment(start_at));
    })
    .test('toLate', 'Finish date is too late', (value) => {
      if (!value) return true;
      return moment(value).isBefore(maximumYearValue);
    })
    .when('dates_required', ([dates_required]: [boolean], schema) => (dates_required ? schema.required() : schema)),
});
