import { FC } from 'react';

import { Box, Typography, useTheme } from '@mui/material';

import { LocationIcon } from 'Utils/Icon';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';

import { MasterTimesheetContentBox } from '../../styled';

type Props = {};

const MasterTimesheetLocations: FC<Props> = (props) => {
  const theme = useTheme();
  const { values } = useMasterTimesheetContext();

  return (
    <MasterTimesheetContentBox>
      {values?.locations?.map((location, index) => (
        <Box key={location.id} display="flex" flexDirection="column" gap="10px">
          <Typography variant="caption">Location #{index + 1}</Typography>
          <Box
            display="flex"
            alignItems="center"
            gap="10px"
            p="9px 16px"
            boxShadow={'0px 4px 3px 0px #F0F0F0'}
            border={`1px solid ${theme.palette.secondary.light}`}
            borderRadius="8px"
          >
            <LocationIcon color={theme.palette.primary.main} width={16} height={16} />
            <Typography fontSize={13} fontFamily={'Poppins Medium'} lineHeight="19.5px">
              {location.address} (Structure #{location.structure})
            </Typography>
          </Box>
        </Box>
      ))}
    </MasterTimesheetContentBox>
  );
};

export default MasterTimesheetLocations;
