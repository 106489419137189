import { Tabs, styled } from '@mui/material';

export const StyledSignaturesTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 'auto',
  '& .MuiTabs-flexContainer': {
    gap: '1px',
    '& .MuiTab-root': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.text.primary,
      padding: '8px 16px',
      minHeight: 'auto',
      textTransform: 'capitalize',

      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },

      '&:first-of-type': {
        borderRadius: '8px 0 0 8px',
      },

      '&:last-of-type': {
        borderRadius: '0 8px 8px 0',
      },
    },
  },

  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));
