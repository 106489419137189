import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';

import { TimesheetTableItem } from 'types/Timesheet';

import { ReactComponent as CancelIcon } from 'Assets/icons/cancel-icon.svg';
import 'Containers/Dispatch/dialog/CancelJobReason/CancelJobReason.scss';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  open: boolean;
  onClose: () => void;
  timesheet: TimesheetTableItem;

  updateDispute: (jobId: number, data: any) => Promise<any>;
  updateTimesheet: (id: number, data: any) => void;
};

class DisputeModal extends Component<Props> {
  state = {
    reason: '',
    processing: false,
    touched: false,
  };

  confirmDispute = async () => {
    this.setState({
      processing: true,
    });

    try {
      await this.props.updateDispute(this.props.timesheet.confirmationNumber, {
        id: this.props.timesheet.id,
        disputeReason: this.state.reason,
      });
      this.props.updateTimesheet(this.props.timesheet.id, {
        isVerified: 0,
      });
    } catch (e) {
      showErrorMessage(e);
    } finally {
      this.props.onClose();
      this.setState({
        processing: false,
      });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      reason: event.target.value,
      ...(!this.state.touched && { touched: true }),
    });
  };

  render() {
    const helperText = !this.state.reason.trim()
      ? 'The reason is required'
      : this.state.reason.trim().length < 10
      ? 'The reason is too short'
      : '';

    return (
      <AppPaperModal
        title="Reason for Dispute"
        open={this.props.open}
        onClose={this.props.onClose}
        submitButton={{
          onClick: this.confirmDispute,
          title: 'Confirm',
          disabled: Boolean(helperText),
          loading: this.state.processing,
        }}
      >
        <Box width="100%" maxWidth={350}>
          <CancelIcon style={{ width: '100%', marginBottom: 16 }} />
          <TextField
            fullWidth
            multiline
            style={{ marginBottom: 24 }}
            helperText={this.state.touched ? helperText : ''}
            error={this.state.touched && Boolean(helperText)}
            id="outlined-multiline-static"
            label="Please write the reason here..."
            variant="outlined"
            value={this.state.reason}
            onChange={this.handleChange}
          />
        </Box>
      </AppPaperModal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateTimesheet: (id, data) => dispatch(actions.TimesheetsActions.update(id, data)),
    updateDispute: (id, status) => dispatch(actions.BillingActions.updateDispute(id, status)),
  };
}

export default connect(null, mapDispatchToProps)(DisputeModal);
