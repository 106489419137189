import React, { useState } from 'react';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Alert, Box, Button, Chip, Collapse, IconButton, Typography } from '@mui/material';

import { ConedUser } from 'types/Common/User';

import { USER_STATUS_COLORS, USER_STATUSES_LABELS } from 'Constants/user';
import { useAppSelector } from 'createStore';

import { USER_STATUS_ICONS } from './RolesSortTable';

const TableLine = ({ label, children, noBorder = false }) => {
  return (
    <Box
      fontSize={16}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderBottom={noBorder ? 'none' : '1px solid #00000010'}
      gap={3}
      p={1}
    >
      <Typography variant="body2">{label}</Typography>
      {children}
    </Box>
  );
};

type RolesTableMobileProps = {
  users: ConedUser[];
  openActionsMenu: (event: React.MouseEvent<HTMLButtonElement>, user: ConedUser) => void;
  selectedUser?: ConedUser;
};

const RolesTableMobile = ({ users, openActionsMenu, selectedUser }: RolesTableMobileProps) => {
  const [showUserRoles, setShowUserRoles] = useState<number[]>([]);
  const ROLES_MAP = useAppSelector((state) => state.app.rolesMap);
  const handleShowUserRoles = (userID: number) => {
    setShowUserRoles((prev) => (prev.includes(userID) ? prev.filter((id) => id !== userID) : [...prev, userID]));
  };

  return (
    <Box display="flex" width={'100%'} flexWrap={'wrap'} gap={2}>
      {users.map((user) => (
        <Box
          key={user.id}
          width="100%"
          bgcolor="#ffffff"
          borderRadius={2}
          p={2}
          sx={{
            ...(selectedUser ? { filter: `blur(${selectedUser?.id !== user?.id ? '5px' : '0'})` } : {}),
            transition: 'scale 0.15s ease-in-out, filter 0.15s ease-in-out',
            scale: selectedUser?.id === user?.id ? '1.07' : '1',
          }}
        >
          <Box display="flex" justifyContent={'space-between'}>
            <Alert
              sx={{
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
                fontSize: 12,
                fontWeight: 500,
                padding: '0 8px',
                gap: '4px',
                '& .MuiAlert-icon': {
                  margin: 0,
                },
              }}
              icon={USER_STATUS_ICONS[user.status]}
              severity={USER_STATUS_COLORS[user.status]}
            >
              {USER_STATUSES_LABELS[user.status] || 'No status'}
            </Alert>
            <IconButton onClick={(event) => openActionsMenu(event, user)}>
              <MoreVertIcon />
            </IconButton>
          </Box>

          <TableLine label="Name">{user.name}</TableLine>
          <TableLine label="Email">{user.email}</TableLine>
          <TableLine label="Roles" noBorder>
            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth={'400px'}
              gap={1}
              justifyContent="flex-end"
              alignItems={'center'}
            >
              <Chip
                sx={{ backgroundColor: '#00000020' }}
                label={ROLES_MAP[user?.roles[0]]?.name}
                key={ROLES_MAP[user?.roles[0]]?.name}
              />
              <Collapse
                sx={{
                  width: '100%',
                  '& .MuiCollapse-wrapperInner': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                    justifyContent: 'flex-end',
                  },
                }}
                in={showUserRoles.includes(user.id)}
              >
                {user?.roles?.map(
                  (role, index) =>
                    index !== 0 && (
                      <Chip
                        sx={{ backgroundColor: '#00000020' }}
                        label={ROLES_MAP[role].name}
                        key={ROLES_MAP[role].name}
                      />
                    )
                )}
              </Collapse>
            </Box>
          </TableLine>
          {user?.roles?.length > 1 && (
            <Box display="flex" justifyContent="center">
              <Button sx={{ padding: 1 }} onClick={() => handleShowUserRoles(user.id)}>
                {showUserRoles.includes(user.id) ? 'Hide roles' : `Show All ${user?.roles?.length} Roles`}
              </Button>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default RolesTableMobile;
