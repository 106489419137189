import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { CloseRounded, Done, DoneAll, Edit, GetApp, HighlightOffSharp, VerifiedUser } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, Popover } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { TimesheetTableItem } from 'types/Timesheet';

import AppPermissions from 'Constants/permissions';
import { TimesheetStatus } from 'Constants/timesheet';
import DisputeModal from 'Containers/Billing/dialog/DisputeModal';
import { actions } from 'Services';
import { TimesheetsAPI } from 'Services/API';
import { downloadFileToPC } from 'Utils/downloadFile';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import UnverifyIcon from 'components/UnverifyIcon';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppSelector } from 'createStore';

import DeleteTimesheetConfirmation from '../DeleteTimesheetConfirmation/DeleteTimesheetConfirmation';
import TimesheetActionButton from './TimesheetActionButton';

const overrides = {
  paper: {
    '& .MuiPaper-rounded': {
      borderRadius: 4,
    },
  },
};

type Props = {
  anchorEl: Element;
  timesheet: TimesheetTableItem;
  handleClose: () => void;
  editTimesheet: (id: number) => void;
};

const TimesheetActions = ({
  anchorEl = null,
  timesheet = null,
  handleClose = () => {},
  editTimesheet = () => {},
}: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const permissions = useAppSelector((state) => state.app.permissions);
  const deleteLoading = useAppSelector((state) => state.timesheets.processing);

  const updateTimesheet = (update: Partial<TimesheetTableItem>) =>
    dispatch(actions.TimesheetsActions.update(id, { ...timesheet, ...update }));

  const deleteTimesheet = (id, conf) => dispatch(actions.TimesheetsActions.deleteTimesheet(id, conf));

  const { openPopover, closePopover, closeAll } = usePopoverContext();

  const {
    id = 0,
    status = 'clocked_out',
    isVerified = 0,
    invoiced = 0,
    workerPaid = 0,
    paid = 0,
    worker_name = '',
    pending = 0,
  } = timesheet || {};

  const downloadPdf = async () => {
    handleClose();
    const response = await TimesheetsAPI.downloadPdf(id);
    const url = window.URL.createObjectURL(new Blob([response]));
    downloadFileToPC(url, `${worker_name} - timesheet.pdf`);
  };

  const makeCesVerified = () => {
    updateTimesheet({ isVerified: 1 }).catch(showErrorMessage).finally(handleClose);
  };

  const makeWorkerPaid = () => {
    updateTimesheet({ workerPaid: 1 }).catch(showErrorMessage).finally(handleClose);
  };

  const makeUnverified = () => {
    updateTimesheet({ isVerified: 0 }).catch(showErrorMessage).finally(handleClose);
  };

  const makeWorkerUnpaid = () => {
    updateTimesheet({ workerPaid: 0 }).catch(showErrorMessage).finally(handleClose);
  };

  const confirmDelete = async () => {
    try {
      await deleteTimesheet(id, timesheet.confirmationNumber);
      showSuccessMessage('Timesheet deleted successfully');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      closePopover('deleteTimesheet');
    }
  };

  const openDisputeTimesheetModal = () => {
    handleClose();
    closeAll();
    openPopover(
      'disputeTimesheet',
      <DisputeModal timesheet={timesheet} open={true} onClose={() => closePopover('disputeTimesheet')} />
    );
  };

  const openDeleteConfirmationModal = () => {
    handleClose();
    closeAll();
    openPopover(
      'deleteTimesheet',
      <DeleteTimesheetConfirmation
        isOpen={true}
        onClose={() => closePopover('deleteTimesheet')}
        onConfirm={confirmDelete}
        loading={deleteLoading}
      />
    );
  };

  const deleteTimesheetEnabled = Boolean(
    !paid && !isVerified && !workerPaid && !invoiced && status !== TimesheetStatus.cancelled
  );
  const userCanDeleteTimesheet = permissions.includes(AppPermissions.delete_timesheets);

  const timesheetClockedOut = status === 'clocked_out';
  const timesheetInvoiced = invoiced || paid;
  const canDoTimesheetAction = permissions.includes(AppPermissions.can_do_timesheet_action);

  const tooltipTitles = {
    workerUnpaid: !workerPaid && 'The timesheet is Worker Unpaid',
    unverified: !isVerified && 'The timesheet is CES Unverified',
    notClockedOut: !timesheetClockedOut && 'The timesheet is not clocked out',
    notInvoiced: !timesheetInvoiced && 'The Timesheet must be Invoiced first',
    invoiced: timesheetInvoiced && 'The timesheet is already invoiced',
    paid: paid && 'Invoice already paid',
    workerPaid: workerPaid && 'Worker already paid',
    pending: pending && 'The timesheet is already pending',
    delete: !deleteTimesheetEnabled && 'The timesheet cannot be deleted if it is verified, paid, billed or cancelled',
  };

  const getConditionalTitle = (conditions: string[] = []) => conditions.find(Boolean) || '';

  return (
    <>
      <Popover
        id={'row-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        sx={overrides.paper}
      >
        <Box display="flex" flexDirection="column">
          <TimesheetActionButton onClick={() => editTimesheet(id)} Icon={<Edit />}>
            Edit Timesheet
          </TimesheetActionButton>
          <TimesheetActionButton onClick={downloadPdf} Icon={<GetApp />}>
            Download .pdf
          </TimesheetActionButton>

          {canDoTimesheetAction && (
            <>
              <TimesheetActionButton
                tooltipProps={{ disableInteractive: true }}
                tooltipTitle={getConditionalTitle([tooltipTitles.notClockedOut, tooltipTitles.invoiced])}
                onClick={makeCesVerified}
                Icon={<VerifiedUser />}
                disabled={isVerified || !timesheetClockedOut || invoiced || paid}
              >
                CES Verified
              </TimesheetActionButton>
              <TimesheetActionButton
                tooltipProps={{ disableInteractive: true }}
                tooltipTitle={getConditionalTitle([
                  tooltipTitles.notClockedOut,
                  tooltipTitles.workerPaid,
                  tooltipTitles.pending,
                ])}
                onClick={makeWorkerPaid}
                Icon={<Done />}
                disabled={!timesheetClockedOut || workerPaid || pending}
              >
                Worker Paid
              </TimesheetActionButton>
              <TimesheetActionButton
                tooltipProps={{ disableInteractive: true }}
                tooltipTitle={getConditionalTitle([tooltipTitles.unverified, tooltipTitles.invoiced])}
                onClick={makeUnverified}
                Icon={<UnverifyIcon />}
                disabled={!isVerified || invoiced}
              >
                CES Unverify
              </TimesheetActionButton>
              <TimesheetActionButton
                tooltipProps={{ disableInteractive: true }}
                tooltipTitle={getConditionalTitle([tooltipTitles.workerUnpaid])}
                onClick={makeWorkerUnpaid}
                Icon={<CloseRounded />}
                disabled={!timesheet?.workerPaid}
              >
                Worker Unpaid
              </TimesheetActionButton>
              <TimesheetActionButton
                tooltipProps={{ disableInteractive: true }}
                tooltipTitle={getConditionalTitle([
                  tooltipTitles.workerPaid,
                  tooltipTitles.invoiced,
                  tooltipTitles.notClockedOut,
                  tooltipTitles.pending,
                ])}
                disabled={timesheetInvoiced || pending || !timesheetClockedOut || workerPaid}
                Icon={<AccessTimeIcon />}
                onClick={openDisputeTimesheetModal}
              >
                Mark as Pending
              </TimesheetActionButton>
              {userCanDeleteTimesheet && (
                <TimesheetActionButton
                  tooltipProps={{ disableInteractive: true }}
                  tooltipTitle={getConditionalTitle([tooltipTitles.delete])}
                  onClick={openDeleteConfirmationModal}
                  Icon={<HighlightOffSharp />}
                  disabled={!deleteTimesheetEnabled}
                >
                  Delete Timesheet
                </TimesheetActionButton>
              )}
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default memo(TimesheetActions);
