import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { AppThunkDispatch } from 'types';
import { JobShift, JobShiftForReroute } from 'types/Common/JobData';
import { Job } from 'types/Job';

import { WorkerStatus, getWorkerNextStatus } from 'Constants/worker';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { getWorketStatusString } from 'Utils/worker';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  jobId: string | number;
  selectedWorkers: JobShiftForReroute[];
  open: boolean;
  onClose: () => void;
  jobDetail: Job;
  onChangeWorkers: (workers: JobShiftForReroute[]) => void;
  onChangeWorkerStatusUpdateId: (id: number) => void;
};

type WorkerUpdate = {
  workerId: number;
  status: number;
  shiftId: number;
};

const UpdateWorkersStatusesModal: FC<Props> = ({
  jobId,
  open,
  selectedWorkers,
  onClose,
  jobDetail,
  onChangeWorkers,
  onChangeWorkerStatusUpdateId,
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const updateJobWorkers = (jobId: string | number, worker: WorkerUpdate) => {
    return dispatch(actions.JobsActions.updateJobWorkers(jobId, worker));
  };

  const { inProcess, promiseWrapper } = useProcessing();

  const getWorkerStatusName = useCallback(
    (status: string | number) => getWorketStatusString(status, jobDetail.jobType),
    [jobDetail?.jobType]
  );

  const updateWorkersStatusesMessage = useMemo(() => {
    const workers = selectedWorkers && selectedWorkers.filter((worker) => worker.status !== WorkerStatus.Completed);
    return (
      <div>
        {workers?.map((worker) => (
          <p key={worker.id}>
            <b>{worker.worker.name}</b>{' '}
            <span>
              status will change from <b>{getWorkerStatusName(worker.status)}</b> to{' '}
              <b>{getWorkerStatusName(getWorkerNextStatus(worker.status))}</b>
            </span>
          </p>
        ))}
      </div>
    );
  }, [selectedWorkers]);

  async function updateStatusByQueue(workersQueueList) {
    const list = [...workersQueueList];
    const firstWorker = list[0];
    onChangeWorkerStatusUpdateId(firstWorker.id);

    try {
      await promiseWrapper(
        updateJobWorkers(jobId, {
          workerId: firstWorker.id,
          status: getWorkerNextStatus(firstWorker.status),
          shiftId: firstWorker.shift_id,
        })
      );
      list.shift();
      if (list.length) {
        updateStatusByQueue(list);
        onChangeWorkers([...list]);
        return;
      }
      onChangeWorkerStatusUpdateId(0);
      onChangeWorkers([]);
    } catch (error) {
      showErrorMessage(error);
      list.shift();
      if (list.length) {
        updateStatusByQueue(list);
        onChangeWorkers([...list]);
        return;
      }
      onChangeWorkerStatusUpdateId(0);
      onChangeWorkers([]);
    }
  }

  const updateWorkersStatuses = () => {
    onClose();
    if (selectedWorkers) {
      const selectedWorkersQueueList: JobShift[] = jobDetail.workers.filter((worker) =>
        selectedWorkers.some(
          (selectedWorker) =>
            selectedWorker.shift_id === worker.shift_id && selectedWorker.status !== WorkerStatus.Completed
        )
      );

      updateStatusByQueue(selectedWorkersQueueList);
    }
  };

  return (
    <AppPaperModal
      title="Update Worker Status"
      subtitle={`Do you want to change ${selectedWorkers.length > 1 ? 'workers statuses?' : 'worker status?'}`}
      modalId="update-worker-status-modal"
      onClose={onClose}
      submitButton={{
        title: 'Yes',
        loading: inProcess,
        onClick: updateWorkersStatuses,
      }}
      cancelButton={{
        title: 'No',
        onClick: onClose,
      }}
      open={open}
    >
      {updateWorkersStatusesMessage}
    </AppPaperModal>
  );
};

export default UpdateWorkersStatusesModal;
