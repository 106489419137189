import { FirebaseApp, initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported, Messaging } from 'firebase/messaging';
import { toast } from 'react-toastify';

import { baseToastConfig } from 'Constants/app';

import { AppAPI } from './API';

class FBMessaging {
  static #firebaseConfig = {
    apiKey: 'AIzaSyDG2w2bo-E5M4ZLdRydHCjlmvPXkYXhoH8',
    authDomain: 'ce-solutions.firebaseapp.com',
    projectId: 'ce-solutions',
    storageBucket: 'ce-solutions.appspot.com',
    messagingSenderId: '737435593834',
    appId: '1:737435593834:web:e9279c2378b03c3c0b2e13',
    measurementId: 'G-Q2ZGPVPQDB',
  };
  static #VAPID_KEY = 'BGQNoNMCIppv-icSoJSwDX9eLZMOTgxW3f5sZxntjOFtj_kOkYnhCN_olJMf6K57ncyOIr2V8s-bExCpCsgufZc';

  static #app: FirebaseApp;

  static #messaging: Messaging;

  static async init() {
    const messagingSupported = await isSupported();
    if (!messagingSupported) return;
    this.#app = initializeApp(this.#firebaseConfig);
    this.#messaging = getMessaging(this.#app);

    try {
      const haveNotificationPermission = await this.getNotificationPermission();
      if (!haveNotificationPermission) return;
      const webToken = await getToken(this.#messaging, { vapidKey: this.#VAPID_KEY });
      await this.sendFCMToken(webToken);
      this.#subscribeToFirebaseMessages();
    } catch (error) {
      toast.error(error, { autoClose: false, position: 'top-right' });
    }
  }

  static #subscribeToFirebaseMessages = () => {
    onMessage(this.#messaging, function (message) {
      if (!message.notification) return;
      if ('Notification' in window) {
        try {
          new window.Notification(message.notification.title ?? 'CE Solutinos', {
            body: message.notification.body,
            icon: './images/logo.png',
            silent: false,
          });
        } catch (error) {
          if (error.name === 'TypeError' && error.message?.includes('Illegal constructor')) {
            return;
          }
        }
      }
    });
  };

  static sendFCMToken = async (fcmToken = '') => {
    try {
      await AppAPI.sendFCMToken(fcmToken);
      return;
    } catch (error) {
      toast.error(
        'Something went wrong and therefore you will not be able to receive push notifications in this session',
        baseToastConfig
      );
      return;
    }
  };

  static getNotificationPermission = async () => {
    try {
      if (Notification.permission === 'granted') {
        return true;
      }
      if (Notification.permission === 'denied') {
        // eslint-disable-next-line no-throw-literal
        throw 'Permission denied';
      }
      const notificationPermission = await Notification.requestPermission();
      if (notificationPermission === 'granted') {
        return true;
      }
      // eslint-disable-next-line no-throw-literal
      throw 'Permission denied';
    } catch (error) {
      toast.error(
        `ERROR: ${error}\nNotification permission required to send you more information\nPlease click the "View site information" button to the left of the site address, enable notifications and reload the page\n`,
        {
          autoClose: false,
          position: 'top-right',
        }
      );

      return false;
    }
  };
}

export default FBMessaging;
