import { FC, memo, useCallback } from 'react';

import { ImageWithAuthor } from 'types/Location';

import { ThumbnailsProps } from 'components/ImagePreview/types';

import JobImageButton from './JobImageButton';
import classes from './ThumbnailImages.module.scss';

type Props = ThumbnailsProps<ImageWithAuthor>;

const ThumbnailImages: FC<Props> = ({
  images = [],
  processingURL = '',
  index: selectedImageIndex,
  onChangeImage = () => {},
}) => {
  const openImage = useCallback(
    (url: string) => {
      const imageIndex = images.findIndex((image) => image.url === url);
      onChangeImage(imageIndex, images[imageIndex]);
    },
    [images, onChangeImage]
  );
  return (
    <div className={classes.thumbnails}>
      {images.map(({ created_at, updated_at, user, is_rejected, url, ...image }, index) => {
        return (
          <JobImageButton
            key={url + '&i=' + index}
            created_at={created_at}
            updated_at={updated_at}
            user={user}
            is_rejected={is_rejected}
            url={url}
            selected={index === selectedImageIndex}
            onClick={openImage}
            className={classes.thumbWrapper}
            processingURL={processingURL}
            {...image}
          />
        );
      })}
    </div>
  );
};

export default memo(ThumbnailImages);
