// @ts-check
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';

import { Job } from 'types/Job';

import { BILLING_STATUS_OPTIONS, BillingStatus } from 'Constants/billing';
import DepartmentMaterialAsyncSearch from 'Containers/Components/Controls/DepartmentMaterialAsyncSearch';
import UpdatePODialog from 'Containers/Job/dialogs/UpdatePODialog';
import { actions } from 'Services';
import { BillingSearchOptions } from 'Services/billing/reducer';
import FilterGroup from 'components/FilterGroup/FilterGroup';
import DatePicker from 'components/Picker/DatePicker';
import Search from 'components/Search/Search';
import UpdatePOButton from 'components/UpdatePOButton/UpdatePOButton';
import { ReduxState } from 'createStore';

import '../billing.scss';
import BillingCheckbox from './BillingCheckbox';

const DEPT_CHECKBOXES = [{ name: 'non_invoiced_dept_jobs', label: 'Show Non Invoiced Depts' }];

const BILLING_CHECKBOXES = [
  { name: 'po_missing', label: 'PO# Missing' },
  { name: 'unverified', label: 'CES Unverified' },
];

interface Props extends ReduxProps {
  open?: boolean;
  openUpdatePOModal?: () => void;
  close?: () => void;
  updatePO?: (args: any) => void;
  selectedJobs?: Job[];
}

type State = {
  open: boolean;
};

class BillingFilters extends PureComponent<Props, State> {
  state = {
    open: false,
  };

  setOpen = (value) => {
    this.setState({ open: value });
  };

  onDepartmentSelect = (departments = [], department_groups = []) => {
    this.props.updateFilters({
      departments,
      department_groups,
      page: 1,
    });
  };

  handleChangeBillingStatus = (event) => {
    this.props.updateFilters({ statuses: event.target.value, page: 1 });
  };

  dateChange = (data) => {
    this.props.updateFilters({
      startDate: data.from_datetime ? data.from_datetime : '',
      finishDate: data.to_datetime ? data.to_datetime : '',
      page: 1,
    });
  };

  updateFilter = (filters) => {
    this.props.updateFilters(filters);
  };

  handleCheckbox = (event) => {
    const { checked } = event.target;

    this.props.updateFilters({
      [event.target.name]: checked,
      page: 1,
    });
  };

  onBillingSelect = (value) => {
    if (!value) {
      this.props.updateFilters({ statuses: [], page: 1 });
      return;
    }
    const statuses = value.map(({ value }) => value);

    this.props.updateFilters({ statuses, page: 1 });
  };

  onCheckBillable = () => {
    if (this.props.search_options.statuses.includes(BillingStatus.allowToBill)) {
      this.props.updateFilters({ statuses: [], page: 1 });
      return;
    }
    this.props.updateFilters({
      statuses: [BillingStatus.allowToBill],
      po_missing: false,
      unverified: false,
      page: 1,
    });
  };

  changeOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as BillingSearchOptions['order_by_field'];
    const value = e.target.value as BillingSearchOptions['order_direction'];
    if (!value) {
      this.props.updateFilters({ order_by_field: name, order_direction: 'desc' });
      return;
    }

    this.props.updateFilters({ order_by_field: name, order_direction: value });
  };

  resetDepartmentFilters = () => {
    this.props.updateFilters({
      departments: [],
      department_groups: [],
      non_invoiced_dept_jobs: false,
      page: 1,
    });
  };

  resetBillingFilters = () => {
    this.props.updateFilters({
      statuses: [],
      po_missing: false,
      unverified: false,
      page: 1,
    });
  };

  resetDateFilters = () => {
    this.props.updateFilters({
      startDate: '',
      finishDate: '',
      order_by_field: 'requestTime',
      order_direction: 'desc',
      page: 1,
    });
  };

  render() {
    const { search_options } = this.props;
    const {
      startDate,
      finishDate,
      search,
      order_by_field,
      order_direction,
      departments,
      department_groups,
      statuses,
      non_invoiced_dept_jobs,
    } = search_options;
    const billableChecked = statuses?.includes(BillingStatus.allowToBill);
    return (
      <div className="billing-header">
        <div className="block-white">
          <div style={{ minWidth: 50, flexGrow: 1, width: '100%' }}>
            <div style={{ height: 30, marginBottom: 16 }}>
              <Search
                name="id"
                updateFilters={this.updateFilter}
                placeholder={'Search ...'}
                instant={true}
                search_value={search}
              />
            </div>
            <DatePicker
              onChange={this.dateChange}
              from_datetime={startDate}
              to_datetime={finishDate}
              showClearDates
              style={{ height: 40, marginBottom: 16 }}
            />
            <FormControl style={{ width: '100%' }} variant="outlined" size="small">
              <InputLabel id="date-order">Sort Order</InputLabel>
              <Select
                labelId="date-order"
                name="requestTime"
                value={order_by_field === 'requestTime' ? order_direction : 'desc'}
                onChange={this.changeOrder}
                label="Sort Order"
              >
                <MenuItem value="desc">From Newest To Oldest</MenuItem>
                <MenuItem value="asc">From Oldest To Newest</MenuItem>
              </Select>
            </FormControl>
          </div>
          <FilterGroup title="Department" onReset={this.resetDepartmentFilters} gap={10}>
            <DepartmentMaterialAsyncSearch
              width={'100%'}
              departments={departments.asMutable({ deep: true })}
              departmentGroups={department_groups.asMutable({ deep: true })}
              onSelect={this.onDepartmentSelect}
              showNonInvoiced={non_invoiced_dept_jobs}
              minPopperWidth={300}
            />

            {DEPT_CHECKBOXES.map((checkbox) => (
              <BillingCheckbox
                key={checkbox.name}
                {...checkbox}
                checked={non_invoiced_dept_jobs}
                onChange={this.handleCheckbox}
              />
            ))}
          </FilterGroup>
          <FilterGroup title="Billing" onReset={this.resetBillingFilters}>
            <Autocomplete
              multiple
              limitTags={1}
              size="small"
              style={{ width: '100%', minWidth: 150 }}
              getOptionDisabled={(option) =>
                statuses?.includes(BillingStatus.allowToBill) && option.value !== BillingStatus.allowToBill
              }
              value={BILLING_STATUS_OPTIONS.filter(({ value }) => statuses?.includes(value))}
              getOptionLabel={(option) => option.label}
              options={BILLING_STATUS_OPTIONS}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={'Select Billing'}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                  }}
                />
              )}
              onChange={(event, value) => this.onBillingSelect(value)}
            />
            {BILLING_CHECKBOXES.map((checkbox) => (
              <BillingCheckbox
                key={checkbox.name}
                {...checkbox}
                disabled={billableChecked}
                checked={search_options[checkbox.name] && !billableChecked}
                onChange={this.handleCheckbox}
              />
            ))}
            <BillingCheckbox
              value={BillingStatus.allowToBill}
              checked={billableChecked}
              label="Billable"
              onChange={this.onCheckBillable}
            />
          </FilterGroup>

          <UpdatePOButton
            fullWidth
            onClick={this.props.openUpdatePOModal}
            disabled={!Object.values(this.props.selectedJobs).length}
          />
        </div>

        <UpdatePODialog
          open={this.props.open}
          close={this.props.close}
          updatePOS={this.props.updatePO}
          receiptUpdate
          selectedJobs={this.props.selectedJobs}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateFilters: (search_options: Partial<BillingSearchOptions>) =>
      dispatch(actions.BillingActions.updateFilters(search_options)),
  };
}

function mapStateToProps(state: ReduxState) {
  return {
    search_options: state.billing.search_options,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(BillingFilters);
