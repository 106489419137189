import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, BoxProps, Fade } from '@mui/material';

import { AuthFormVariant, AuthFormContentHelper } from '../constants';
import { AuthActionButton, AuthSubtitle, AuthTitle } from '../styled';

type Props = {
  variant: AuthFormVariant.ACTIVATE_ACCOUNT | AuthFormVariant.RECOVERY;
  containerProps?: BoxProps;
};

const SuccessPage: FC<Props> = ({ variant = AuthFormVariant.ACTIVATE_ACCOUNT, containerProps }) => {
  const history = useHistory();
  const backToLogin = () => {
    history.push('/');
  };

  const { successPageData } = new AuthFormContentHelper(variant);

  return (
    <Fade in timeout={300} mountOnEnter>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={'center'}
        flex={1}
        py={4}
        justifyContent="center"
        {...containerProps}
      >
        {successPageData.icon}
        <AuthTitle mt="30px">{successPageData.title}</AuthTitle>
        <AuthSubtitle mt="6px" textAlign="center">
          {successPageData.subtitle}
        </AuthSubtitle>
        <AuthActionButton variant="contained" fullWidth onClick={backToLogin} sx={{ mt: '50px' }}>
          {successPageData.action}
        </AuthActionButton>
      </Box>
    </Fade>
  );
};

export default SuccessPage;
