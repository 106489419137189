import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Select, { CSSObjectWithLabel, StylesConfig } from 'react-select';

import { LinearProgress } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { Department } from 'types/Common/Companies';
import { ConEdRequestor, ConEdSupervisor } from 'types/Common/User';
import { GrouppedTimesheetOptions } from 'types/Timesheet';

import { JOB_STATUS_OPTIONS, JOB_TYPE_STATUSES, canSeeCancelledTimesheets } from 'Constants/timesheet';
import DepartmentAsyncSearch from 'Containers/Components/Controls/DepartmentAsyncSearch';
import RequestorAsyncSearch from 'Containers/Components/Controls/RequestorAsyncSearch';
import { SupervisorsAsyncSearch } from 'Containers/Components/Controls/SupervisorsAsyncSearch';
import { retrieveGroupped } from 'Services/grouppedTimesheets/actions';
import { useAppSelector } from 'createStore';

import CancelledTimesheetButton from '../CancelledTimesheetButton';
import PendingTimesheetsFilterButton from '../PendingTimesheetsFilterButton';
import TimesheetsHeaderWrap from '../TimesheetsHeaderWrap';
import s from './GrouppedTimesheets.module.scss';
import GrouppedTimesheetsTable from './GrouppedTimesheetsTable';

const VERIFIED_STATUSES = [
  { label: 'CES Verified', value: true },
  { label: 'CES Unverified', value: false },
];

const CE_VERIFIED_STATUSES = [
  { label: 'CE Verified', value: true },
  { label: 'CE Unverified', value: false },
];

type Props = {} & RouteComponentProps;

const GrouppedTimesheets = (props: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const searchOptions = useAppSelector((state) => state.grouppedTimesheets.grouppedTimesheetsOptions);
  const user = useAppSelector((state) => state.app.user);
  const loading = useAppSelector((state) => state.timesheets.processing);
  const groupedLoading = useAppSelector((state) => state.grouppedTimesheets.processing);
  const userIsSubcontractor = useAppSelector((state) => state.app.userIsSubcontractor);

  const updateFilters = (options: Partial<GrouppedTimesheetOptions> = {}) => dispatch(retrieveGroupped(options));

  const handleChangeDepartment = (item: Department) => {
    updateFilters({ department: item?.id || '' });
  };

  const selectRequestor = (item?: ConEdRequestor) => {
    updateFilters({ requestor: item?.id || null });
  };

  const selectSupervisor = (item?: ConEdSupervisor) => {
    updateFilters({ supervisor: item?.id || null });
  };

  const toggleCancelledTimesheets = () => {
    if (searchOptions.show_cancelled_timesheets) {
      updateFilters({ show_cancelled_timesheets: false });
      return;
    }
    updateFilters({ show_cancelled_timesheets: true });
  };

  const styles: Partial<StylesConfig<{ label: string; value: any }, false>> = {
    menu: (base) => ({ ...base, zIndex: 10 } as CSSObjectWithLabel),
  };

  const togglePendingTimesheetsGroups = () => {
    updateFilters({
      has_pending_timesheets: searchOptions.has_pending_timesheets ? null : true,
    });
  };

  return (
    <>
      <TimesheetsHeaderWrap>
        <div className={s.filters_container}>
          <div>
            <DepartmentAsyncSearch onSelect={handleChangeDepartment} departmentId={searchOptions.department} />
          </div>
          {!userIsSubcontractor && (
            <div>
              <RequestorAsyncSearch
                onSelect={selectRequestor}
                onClear={selectRequestor}
                placeholder="Search Requestor"
                departmentId={searchOptions.department}
                requestorId={searchOptions.requestor}
              />
            </div>
          )}
          <div>
            <SupervisorsAsyncSearch
              onSelect={selectSupervisor}
              onClear={selectSupervisor}
              placeholder="Search Supervisor"
              departmentId={searchOptions.department}
              supervisorId={searchOptions.supervisor}
            />
          </div>
          <div>
            <Select
              isClearable
              name="jobStatus"
              options={JOB_STATUS_OPTIONS}
              value={JOB_STATUS_OPTIONS.find((v) => v?.value === searchOptions.jobStatus)}
              placeholder={'Job Status'}
              onChange={(v) => updateFilters({ jobStatus: v?.value })}
              styles={styles}
            />
          </div>
          <div>
            <Select
              isClearable
              name="jobType"
              options={JOB_TYPE_STATUSES}
              value={JOB_TYPE_STATUSES.find((v) => v?.value === searchOptions.jobType)}
              placeholder={'Job Type'}
              onChange={(v) => updateFilters({ jobType: v?.value })}
              styles={styles}
            />
          </div>
          <div className="mx-2">
            <Select
              isClearable
              options={CE_VERIFIED_STATUSES}
              value={CE_VERIFIED_STATUSES.find((v) => v.value === searchOptions.ce_verified)}
              placeholder={'CE Verified'}
              onChange={(v) => updateFilters({ ce_verified: v?.value })}
              styles={styles}
            />
          </div>
          <div className="mx-2">
            <Select
              isClearable
              options={VERIFIED_STATUSES}
              value={VERIFIED_STATUSES.find((v) => v.value === searchOptions.ces_verified)}
              placeholder={'CES Verified Status'}
              onChange={(v) => updateFilters({ ces_verified: v?.value })}
              styles={styles}
            />
          </div>
          {canSeeCancelledTimesheets(user.roles) && (
            <CancelledTimesheetButton
              active={searchOptions.show_cancelled_timesheets}
              onClick={toggleCancelledTimesheets}
              divProps={{ style: { flexBasis: 'max-content' } }}
            />
          )}
          <PendingTimesheetsFilterButton
            active={searchOptions.has_pending_timesheets}
            onClick={togglePendingTimesheetsGroups}
          />
        </div>
      </TimesheetsHeaderWrap>
      {loading || groupedLoading ? <LinearProgress /> : <div style={{ height: 4 }} />}

      <GrouppedTimesheetsTable {...props} />
    </>
  );
};

export default GrouppedTimesheets;
