export const GET_SUBCONTRACTORS_REQUEST = 'subcontractors.get_subcontractors_request';
export const GET_SUBCONTRACTORS_SUCCESS = 'subcontractors.get_subcontractors_success';
export const GET_SUBCONTRACTORS_ERROR = 'subcontractors.get_subcontractors_error';

export const CREATE_SUBCONTRACTOR_REQUEST = 'subcontractors.create_subcontractor_request';
export const CREATE_SUBCONTRACTOR_SUCCESS = 'subcontractors.create_subcontractor_success';
export const CREATE_SUBCONTRACTOR_ERROR = 'subcontractors.create_subcontractor_error';

export const UPDATE_SUBCONTRACTOR_REQUEST = 'subcontractors.update_subcontractor_request';
export const UPDATE_SUBCONTRACTOR_SUCCESS = 'subcontractors.update_subcontractor_success';
export const UPDATE_SUBCONTRACTOR_ERROR = 'subcontractors.update_subcontractor_error';

export const DELETE_SUBCONTRACTOR_REQUEST = 'subcontractors.delete_subcontractor_request';
export const DELETE_SUBCONTRACTOR_SUCCESS = 'subcontractors.delete_subcontractor_success';
export const DELETE_SUBCONTRACTOR_ERROR = 'subcontractors.delete_subcontractor_error';
