import Immutable from 'seamless-immutable';

import AppPermissions from 'Constants/permissions';

import { GET_PERMISSIONS_SUCCESS, LOGOUT } from '../app/actionTypes';

const initialState = Immutable({
  canSeePage: {
    dispatch: false,
    workers: false,
    subcontractors: false,
    scheduling: false,
    roles: false,
    billing: false,
    invoicing: false,
    reports: false,
    projectSettings: false,
  },
});

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case GET_PERMISSIONS_SUCCESS:
      const userPermissions: AppPermissions[] = action.permissions;
      return state.merge({
        canSeePage: {
          dispatch: userPermissions.includes(AppPermissions.can_see_dispatch),
          workers: userPermissions.includes(AppPermissions.can_see_workers),
          subcontractors: userPermissions.includes(AppPermissions.can_see_subcontractors),
          scheduling: userPermissions.includes(AppPermissions.can_see_scheduling),
          roles: userPermissions.includes(AppPermissions.can_see_roles),
          billing: userPermissions.includes(AppPermissions.can_see_billing),
          invoicing: userPermissions.includes(AppPermissions.can_see_invoicing),
          reports: userPermissions.includes(AppPermissions.can_see_reports),
          projectSettings: userPermissions.includes(AppPermissions.can_see_project_settings),
        },
      });

    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
