import { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Moment } from 'moment';
import { toast } from 'react-toastify';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Paper, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import Invoice from 'types/Invoices';

import { baseToastConfig } from 'Constants/app';
import AppPermissions from 'Constants/permissions';
import { EditDateTime } from 'Containers/Components';
import { actions, ActionTypes } from 'Services';
import useModal from 'Utils/hooks/useModal';
import Button from 'components/Button/Button';
import Notes from 'components/Notes/Notes';
import NotesProvider, { NotesConsumer } from 'context/NotesContext';
import { ReduxState } from 'createStore';

import AddInvoiceSliderComponent from '../InvoiceCreateSlide';
import '../Invoices.scss';
import { DownloadOutstanding, InvoicesSearch } from '../components';
import ToggleInvoicesNotes from '../components/ToggleInvoicesNotes';
import DeleteConfigurationModal from '../dialog/DeleteConfigurationModal';
import MarkTimesheetsAsPaid from '../dialog/MarkTimesheetsAsPaid';
import s from './InvoiceTable.module.css';
import InvoicesTableRow from './InvoicesTableRow';

interface Props extends RouteComponentProps<{ id: string }>, ReduxProps {}

const InvoicesTable = (props: Props) => {
  const [showDel, setShowDel] = useState(false);
  const [showedAddInvoice, setShowedAddInvoice] = useState(false);
  const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false);
  const markAsPaidModal = useModal();

  const config_id = props.match.params.id;

  useEffect(() => {
    props.getInvoices(props.match.params.id, {});

    return () => {
      props.dispatch({
        type: ActionTypes.InvoicesActionsTypes.UPDATE_INVOICES_OPTIONS,
        options: { page: 1 },
      });
    };
  }, [props.match.params.id]);

  const handleRowClick = (invoice: Invoice.Item) => {
    props.history.push(`/invoices/${props.match.params.id}/${invoice.id}`);
  };

  const goToConfigurations = () => {
    props.history.push('/invoices');
  };

  const generateNewInvoice = async (startDate: Moment, finishDate: Moment) => {
    const timeFormat = 'YYYY-MM-DD';
    const body =
      startDate.isValid() && finishDate.isValid()
        ? {
            from_datetime: startDate.format(timeFormat),
            to_datetime: finishDate.format(timeFormat),
          }
        : null;
    try {
      await props.createInvoiceFromConfig(props.match.params.id, body);
      toast.success('New invoice created!', baseToastConfig);
    } catch (error) {
      toast.error(typeof error.error === 'string' ? error.error : 'Something went wrong', baseToastConfig);
    }
  };

  const deactivate = () => {
    setShowDel(true);
  };

  const editConfig = () => {
    props.getConfiguration(config_id);
    setShowedAddInvoice(true);
  };

  const showGenerateInvoice = () => setOpenGenerateInvoice(true);
  const closeGenerateInvoice = () => setOpenGenerateInvoice(false);

  const closeDel = () => {
    setShowDel(false);
  };

  const closeEditInvoice = () => {
    setShowedAddInvoice(false);
  };

  const submit = async (invoice) => {
    //this.props.createInvoice(this.state);
    await props
      .updateConfig(config_id, invoice)
      .then(() => {
        toast.success('Invoice configuration updated', baseToastConfig);
        closeEditInvoice();
      })
      .catch((err) => {
        toast.error('Create new invoices error!', baseToastConfig);
      });
  };

  return (
    <NotesProvider entity_id={config_id} entity_type={'invoice_config'}>
      <div className="invoices-list-page">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="blue-bg mr-2" onClick={goToConfigurations}>
              <ArrowBackIcon style={{ color: 'white' }} />
            </div>
            <div className="page-title-gray mr-1" onClick={goToConfigurations} style={{ cursor: 'pointer' }}>
              Configurations /{' '}
            </div>
            <div className="page-title">{config_id} / Invoices</div>
          </div>
          <div className="button-row">
            <DownloadOutstanding config_id={config_id} />
            {props.canEditInvoice && (
              <>
                <Button
                  color={'dark'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={showGenerateInvoice}
                  processing={props.generate_invoice_processing}
                >
                  Generate New Invoice
                </Button>
                <Button
                  color={'dark'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={editConfig}
                  disabled={props.processing}
                  style={{
                    backgroundColor: '#009ad8',
                    color: '#FFF',
                  }}
                >
                  Edit Invoice Config
                </Button>
                <Button
                  color={'dark'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={deactivate}
                  processing={props.processing_deactivate}
                  disabled={props.processing_deactivate}
                  style={{
                    backgroundColor: 'red',
                    color: '#FFF',
                  }}
                >
                  Deactivate Config
                </Button>

                <Tooltip
                  disableInteractive
                  title="Mark Timesheet as Paid and/or Change Job's PO#"
                  arrow
                  aria-label="paid"
                >
                  <div>
                    <Button
                      color={'dark'}
                      width={'220px'}
                      borderRadius={'20px'}
                      textTransform={false}
                      onClick={markAsPaidModal.open}
                      style={{
                        backgroundColor: '#009ad8',
                        color: '#FFF',
                      }}
                    >
                      Mark Timesheets As Paid
                    </Button>
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        {props.loading ? <LinearProgress /> : <div style={{ height: 4 }} />}
        <div className={s.search_container}>
          <InvoicesSearch configId={config_id} />
          <ToggleInvoicesNotes />
        </div>

        <div className="Invoices-table Invoices-with-notes" style={{ flexDirection: 'row' }}>
          <InvoicesTableRow handleRowClick={handleRowClick} configId={config_id} />

          <NotesConsumer>
            {({ notes: derivedNotes, showNotes, ...rest }) =>
              showNotes ? (
                <Paper className="invoices-notes-container">
                  <Notes
                    derivedNotes={derivedNotes}
                    {...rest}
                    entity_id={config_id}
                    entity_type={'invoice_config'}
                    placeholder={'Leave a note and press enter…'}
                  />
                </Paper>
              ) : null
            }
          </NotesConsumer>
        </div>
        <DeleteConfigurationModal configId={config_id} onClose={closeDel} open={showDel} historyPush={'/invoices'} />
        <AddInvoiceSliderComponent
          //createInvoice={updateInvoice}
          submit={submit}
          invoice={props.config}
          showed={showedAddInvoice}
          closeSlide={(_) => closeEditInvoice()}
        />
        <MarkTimesheetsAsPaid
          onClose={markAsPaidModal.close}
          open={markAsPaidModal.isOpen}
          config_id={Number(config_id)}
        />
        <EditDateTime
          open={openGenerateInvoice}
          onClose={closeGenerateInvoice}
          defaultValue={{ start: '', finish: '' }}
          labels={{ start: 'Start Date', finish: 'Finish Date' }}
          title="Generate New Invoice"
          subTitle="Set start and end times for the new invoice, or leave fields blank"
          allowEmptyDate
          confirmButtonTitle="Generate"
          onSuccess={generateNewInvoice}
        />
      </div>
    </NotesProvider>
  );
};

function mapStateToProps({ invoices, app }: ReduxState) {
  return {
    loading: invoices.processing,
    generate_invoice_processing: invoices.generate_invoice_processing,
    processing: invoices.processing,
    processing_deactivate: invoices.processing_deactivate,
    config: invoices.conf,
    canEditInvoice: app.permissions.includes(AppPermissions.edit_invoices),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getInvoices: (id, search_options) => dispatch(actions.InvoicesActions.getConfigurationInvoices(id, search_options)),
    createInvoice: (data) => dispatch(actions.InvoicesActions.createInvoice(data)),
    createInvoiceFromConfig: (config_id, timeInterval) =>
      dispatch(actions.InvoicesActions.createInvoiceFromConfig(config_id, timeInterval)),
    updateConfig: (id, data) => dispatch(actions.InvoicesActions.updateInvoice(id, data)),
    getConfiguration: (id) => dispatch(actions.InvoicesActions.getConfiguration(id)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(InvoicesTable);
