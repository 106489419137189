import { Box, Typography } from '@mui/material';

import { TimesheetNames } from 'Constants/timesheet';
import ImageUpload from 'Containers/Components/ImageUpload/ImageUpload';
import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import { generateTimesheetPhotoName } from 'Containers/Timesheets/utils';
import UserPermissions from 'Utils/PermissionsHelper';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';

import styles from './UploadFiles.module.scss';

const UploadFiles = () => {
  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;
  const timesheetIsCancelled = timesheet.status === 'cancelled';
  const canEditTimesheetFiles = UserPermissions.has.can_edit_timesheet_files as boolean;

  const onChangeFiles = (field, values) => {
    formikBag.setFieldValue(field, values);
  };

  return (
    <TimesheetBox>
      <Box className={styles.files}>
        <Box>
          <Typography variant="h6" gutterBottom component="div">
            Photo of Paper Timesheet
          </Typography>
          <ImageUpload
            dropzoneInnerStyle={{
              padding: '50px 0',
            }}
            onChangeImage={(values) => onChangeFiles(TimesheetNames.images, values)}
            defaultImages={timesheet?.images ?? []}
            canRemove={canEditTimesheetFiles}
            disabled={timesheetIsCancelled || !canEditTimesheetFiles}
            getDownloadedImageName={({ is_rejected }) =>
              generateTimesheetPhotoName({
                shift_id: timesheet.shiftId,
                is_rejected,
              })
            }
          />
        </Box>
        <Box>
          <Typography variant="h6" gutterBottom component="div">
            Attach PDFs
          </Typography>
          <ImageUpload
            dropzoneInnerStyle={{
              padding: '50px 0',
            }}
            onChangeImage={(values) => onChangeFiles(TimesheetNames.timesheetPdfs, values)}
            defaultImages={timesheet?.timesheetPdfs ?? []}
            filesMode
            canRemove={canEditTimesheetFiles}
            disabled={timesheetIsCancelled || !canEditTimesheetFiles}
          />
        </Box>
      </Box>
    </TimesheetBox>
  );
};

export default UploadFiles;
