import React, { PropsWithChildren } from 'react';

import clsx from 'clsx';

type Props = { error?: string; label: string } & PropsWithChildren;

const JobInputBlock = ({ children, error, label }: Props) => {
  return (
    <div className={clsx('col-sm-4', { 'form-group': !error })}>
      <label className="d-block">{label}</label>
      {children}
      {!!error && <p style={{ margin: 0, color: '#a94442' }}>{error}</p>}
    </div>
  );
};

export default JobInputBlock;
