import { FC } from 'react';

import { DeleteForever } from '@mui/icons-material';
import { Box, Typography, Button, alpha } from '@mui/material';

import SignatureBox from 'Containers/Timesheets/components/SignatureBox/SignatureBox';
import { DeleteIcon } from 'Utils/Icon';
import UserPermissions from 'Utils/PermissionsHelper';
import AppInputField from 'components/AppInputField/AppInputField';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';
import { useAppSelector } from 'createStore';

import { StyledDeleteButton } from '../../styled';

const MasterTimesheetSignature: FC = () => {
  const masterTimesheet = useAppSelector((state) => state.timesheets.masterTimesheet)?.asMutable({ deep: true });
  const user = useAppSelector((state) => state.app.user);
  const { values, errors, setValues, handleChange } = useMasterTimesheetContext();
  const userCanSignTimesheets = UserPermissions.has.sign_timesheet;

  const changeSignature = (signatureData: string) => {
    setValues((prev) => ({
      ...prev,
      signature_data: signatureData,
      signee: user?.name,
      employee_number: values?.employee_number || user?.co_employee_id || '',
    }));
  };

  const removeSignature = () => {
    setValues((prev) => ({
      ...prev,
      signature_data: null,
      signee: null,
      employee_number: null,
    }));
  };

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption">Signature</Typography>
        {!masterTimesheet?.signed_at && values.signature_data && (
          <StyledDeleteButton startIcon={<DeleteIcon />} onClick={removeSignature}>
            Remove signature
          </StyledDeleteButton>
        )}
      </Box>
      <Box display="flex" gap="20px">
        <AppInputField size="small" fullWidth label="Signature Name" value={values?.signee} disabled />
        <AppInputField
          fullWidth
          size="small"
          label="Employee # "
          value={values?.employee_number}
          name="employee_number"
          onChange={handleChange}
          disabled={!!user?.co_employee_id || !!masterTimesheet?.signed_at || !userCanSignTimesheets}
          error={Boolean(errors.employee_number)}
          helperText={errors.employee_number}
        />
      </Box>
      <SignatureBox
        value={values.signature_data}
        onChange={changeSignature}
        disabled={!!masterTimesheet?.signed_at || !userCanSignTimesheets}
        helperText={!userCanSignTimesheets && 'You do not have permission to sign timesheets'}
      />
    </Box>
  );
};

export default MasterTimesheetSignature;
