import { Country, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

import { JobTypeHelper } from './job';
import { sectionRegExp, structureRegExp } from './reg_exp';

const numbersOrLettersRegExp = /^[a-zA-Z0-9]*$/;
const PORegExp = /^[a-zA-Z0-9]{0,7}$/;
const doublePORegExp = /^([a-zA-Z0-9]{0,7})$|^([a-zA-Z0-9]{1,7}[\/]{1,1}[a-zA-Z0-9]{1,7})$/;

export const supportedPhoneCountries: Country[] = ['US', 'MD', 'UA'];
const phoneNumberLengthMap = {
  US: 12,
  MD: 12,
  UA: 13,
} as const;

export const PO_Double_Validation = Yup.string()
  .matches(PORegExp, ({ value }) => {
    if (value.length > 7 && !value.includes('/')) {
      return "Max length is 7 symbols. If you want to enter two PO numbers, input '/' between them";
    }
    const isDoublePo = doublePORegExp.test(value);
    if (isDoublePo && value.length > 15) {
      return "Max length of two PO numbers is 15 symbols with '/' between them";
    }
    if (isDoublePo) {
      return;
    }
    if (value.includes('/')) {
      const [first, second] = value.split('/');
      if (!first.length || !second.length) {
        return `Input second PO# after '/' or remove '/'`;
      }
      if (first.length > 7) {
        return 'First PO# is too long. Max length is 7 symbols';
      }
      if (second.length > 7) {
        return 'Second PO# is too long. Max length is 7 symbols';
      }
    }
    return 'Must be only Latin letters or numbers';
  })
  .label('PO number');

/** Used only for Job Create */
export const PO_Validation = Yup.string()
  .max(7, 'PO # is too long')
  .matches(numbersOrLettersRegExp, 'Must be only Latin letters or numbers');

export const Receipt_Validation = Yup.string()
  .max(30, 'Receipt # is too long')
  .matches(numbersOrLettersRegExp, 'Must be only Latin letters or numbers');

export const Requisition_Validation = Yup.string()
  .max(30, 'Requisition # is too long')
  .matches(numbersOrLettersRegExp, 'Must be only Latin letters or numbers');

export const WR_Validation = Yup.string()
  .max(30, 'Work Request # is too long')
  .matches(numbersOrLettersRegExp, 'Must be only Latin letters or numbers');

export const Structure_Validation = Yup.string()
  .label('Structure')
  .nullable()
  .max(64, 'Maximum Structure name length = 64 characters')
  .matches(structureRegExp, {
    message: 'Structure allowed symbols: /, space, dash, A-z, 0-9',
    excludeEmptyString: true,
  });

export const Account_Number_Validation = Yup.string()
  .label('Job #')
  .trim()
  .max(32, 'Maximum Account Number length = 32 characters');

export const POET_Validation = Yup.string()
  .matches(/^[0-9]*\/?[0-9]*$/, 'Must be only digits or digits/digits')
  .max(30, 'Poet # is too long');

export const getStructureValidationByJobType = (jobType: number | string) => {
  const { isParking, isSignage } = new JobTypeHelper(jobType);
  if (isParking || isSignage) {
    return Structure_Validation.required().nullable();
  }
  return Structure_Validation.notRequired().nullable();
};

export const PhoneNumberValidation = Yup.string().test('phone-number', 'Phone Number is not correct', (value) => {
  const country = parsePhoneNumber(value)?.country;
  const isCountrySupported = Boolean(country) && supportedPhoneCountries.includes(country as Country);

  if (!isCountrySupported) return false;
  if (value.length > phoneNumberLengthMap[country as Country]) return false;

  return isValidPhoneNumber(value, country);
});

export const SectionValidation = Yup.string()
  .label('Section')
  .max(14, 'Maximum section name length = 14 characters')
  .matches(sectionRegExp, {
    message: 'Allowed symbols: #, space, dash, A-z, 0-9',
    excludeEmptyString: true,
  });

export const maximumYearValue = new Date(2038, 0);
