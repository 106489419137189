import React from 'react';

import { CircularProgress } from '@mui/material';

import Logo from 'components/Logo/Logo';

import { useAppSelector } from '../../../createStore';
import s from './AppFullPageLoader.module.scss';

type Props = { children: React.ReactElement };

export const AppFullPageLoaderComponent = () => (
  <div className={s.wrap}>
    <Logo size="large" />
    <CircularProgress size={40} />
  </div>
);

const AppFullPageLoader = ({ children }: Props) => {
  const userCredentionalsLoading = useAppSelector((state) => state.app.appLoading);
  if (userCredentionalsLoading) {
    return (
      <div className={s.wrap}>
        <Logo size="large" />
        <CircularProgress size={40} />
      </div>
    );
  }
  return children;
};

export default AppFullPageLoader;
