import React, { CSSProperties } from 'react';

import { ReceiptRounded, TimelapseRounded } from '@mui/icons-material';
import { Tooltip, alpha } from '@mui/material';

import { sumRounder } from 'Utils/numberRounder';
import { useAppSelector } from 'createStore';

import styles from './ThreadLegend.module.scss';

type ColorSheetsData = { totalHours: number; totalSheets: number };

type Props = {
  blockStyles?: CSSProperties;
  classNames?: string | string[];
  visible?: number | string | boolean;
};

const ThreadLegend = ({ classNames = '', blockStyles = {}, visible = false }: Props) => {
  const timesheets = useAppSelector((state) => state.timesheets.timesheets.results);

  const dataInfo: Record<string, ColorSheetsData> = timesheets.asMutable({ deep: true }).reduce((acc, timesheet) => {
    const existInfo: ColorSheetsData = acc[timesheet.color] || {
      totalHours: 0,
      totalSheets: 0,
    };
    const info = {
      ...acc,
      [timesheet.color]: {
        totalHours: existInfo.totalHours + parseFloat(timesheet.totalHours),
        totalSheets: existInfo.totalSheets + 1,
      },
    };
    return info;
  }, {});

  const dataMap = Object.keys(dataInfo).map((color) => ({
    color,
    totalHours: parseFloat(dataInfo[color].totalHours.toFixed(2)),
    totalSheets: dataInfo[color].totalSheets,
  }));

  const { hours, sheets } = dataMap.reduce(
    (acc, { totalSheets, totalHours }) => ({
      hours: sumRounder([acc.hours, totalHours], 2),
      sheets: acc.sheets + totalSheets,
    }),
    { hours: 0, sheets: 0 }
  );

  const classes = typeof classNames === 'string' ? classNames : classNames.join(' ');

  if (!visible) return null;
  return (
    <div style={blockStyles} className={`${styles.legendContainer} ${classes}`}>
      <div className={styles.legendBlock}>
        <p>
          <ReceiptRounded fontSize="small" /> Sheets: {sheets}
        </p>
        <p>
          <TimelapseRounded fontSize="small" /> Hours: {hours}
        </p>
        <p className={styles.colorText}>
          <Tooltip disableInteractive title="Number of threads" followCursor>
            <span>Color: {dataMap.length}</span>
          </Tooltip>
        </p>
      </div>
      <div className={styles.legendValues}>
        {dataMap.map(({ color, totalHours, totalSheets }) => (
          <div key={color} className={styles.legendBlock} style={{ borderColor: alpha(color, 1) }}>
            <p>
              <ReceiptRounded fontSize="small" /> {totalSheets}
            </p>
            <p>
              <TimelapseRounded fontSize="small" /> {totalHours}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ThreadLegend);
