import * as React from 'react';

import { Box, Typography } from '@mui/material';

// import './date.scss';

interface Props {
  title?: string;
  onChange?: Function;
  checked?: boolean;
  className?: string;
  id: string;
  name?: string;
}

export class RadioCustomComponent extends React.Component<Props> {
  public render() {
    return (
      <Box className={'custom-control custom-radio ' + this.props.className}>
        <input
          type="radio"
          className="custom-control-input"
          id={this.props.id}
          name={this.props.name}
          checked={this.props.checked}
          onChange={() => {
            if (this.props.onChange) {
              this.props.onChange();
            }
          }}
        ></input>
        <Typography
          component="label"
          sx={{ cursor: 'pointer' }}
          fontSize={12}
          whiteSpace="nowrap"
          htmlFor={this.props.id}
          className="custom-control-label"
        >
          {this.props.title}
        </Typography>
      </Box>
    );
  }
}

export default RadioCustomComponent;
