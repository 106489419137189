import { Button, IconButton, Tabs, alpha, styled } from '@mui/material';

export const TextButton = styled(Button)({
  padding: 0,
  lineHeight: '21px',
  '&:hover': { backgroundColor: 'unset' },
});

export const StyledJobDetailsTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 'auto',
  minWidth: 'max-content',
  flex: 1,
  borderBottom: `1px solid #00000010`,
  '& .MuiTabs-flexContainer': { gap: '24px' },
  '& .MuiTab-root': {
    fontFamily: 'Poppins SemiBold',
    fontSize: 13,
    textTransform: 'capitalize',
    padding: 0,
    fontWeight: 600,
    color: theme.palette.text.primary,
    opacity: 0.5,
    transition: 'color 0.15s ease-in-out, opacity 0.15s ease-in-out',
    letterSpacing: 0.5,
    '&.MuiButtonBase-root': {
      minHeight: 50,
    },

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      opacity: 1,
    },

    '&:hover:not(.Mui-Selected)': {
      opacity: 0.8,
    },
  },
}));

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'backdropBlur',
})<{
  backdropBlur?: boolean;
}>(({ theme, backdropBlur }) => ({
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: '8px',
  backdropFilter: backdropBlur ? 'blur(3px)' : 'unset',
  svg: {
    width: 14,
    height: 14,
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  '&:disabled': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
}));
