import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment';
import SeamlessImmutable, { ImmutableObject } from 'seamless-immutable';

import AddIcon from '@mui/icons-material/Add';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import LockIcon from '@mui/icons-material/Lock';
import LockResetIcon from '@mui/icons-material/LockReset';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import Invoice from 'types/Invoices';

import { CONFIGURATION_TABLE, InvoiceStatus } from 'Constants/invoices';
import AppPermissions from 'Constants/permissions';
import { actions } from 'Services';
import { ConfigurationInvoicesSearchOptions } from 'Services/invoices/reducer';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import { showSuccessMessage } from 'Utils/successMessage';
import app_history from 'app_history';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import TextOverflowTooltip from 'components/TextOverflowTooltip';
import { ReduxState } from 'createStore';

import AddInvoiceSliderComponent from './InvoiceCreateSlide';
import './Invoices.scss';
import DeleteConfigurationModal from './dialog/DeleteConfigurationModal';

interface Props extends ReduxProps {
  configurations?: SeamlessImmutable.ImmutableArray<Invoice.Configuration>;
  handleSort?: (data: any) => void;
  orderBy?: string;
  orderByType?: boolean;
  deleteInvoice?: (id: number) => void;
}
const ConfigurationTable: React.FC<Props> = ({
  configurations,
  handleSort,
  orderBy,
  orderByType,
  loading,
  search_options,
  getConfigs,
  getConfiguration,
  config,
  updateConfig,
  create,
  canEditInvoice = false,
  updateSubInvoicesFilters,
}) => {
  const [selectedConfig, setSelectedConfig] = useState<ImmutableObject<Invoice.Configuration>>(null);
  const editConfigModal = useModal();
  const [deleteConfigId, setDeleteConfigId] = useState<number>(0);
  const [configActionsMenuEl, setConfigActionsMenuEl] = useState<HTMLElement>(null);

  const openConfigActionsMenu = async (
    event: React.MouseEvent<HTMLButtonElement>,
    config: ImmutableObject<Invoice.Configuration>
  ) => {
    event.stopPropagation();
    getConfiguration(config.id);
    setSelectedConfig(config);
    setConfigActionsMenuEl(event.currentTarget);
  };

  const closeConfigActionsMenu = () => {
    setSelectedConfig(null);
    setConfigActionsMenuEl(null);
  };

  const openInvoicesPage = (id) => {
    app_history.push(`/invoices/${id}`);
  };

  const openEditConfigModal = useCallback(() => {
    closeConfigActionsMenu();
    if (!config) return;
    editConfigModal.open();
  }, [config]);

  const createInvoice = useCallback(async () => {
    closeConfigActionsMenu();
    if (!selectedConfig) return;
    try {
      await create(selectedConfig.id);
      showSuccessMessage('New invoice created!');
    } catch (error) {
      showErrorMessage(error);
    }
  }, [selectedConfig]);

  const openRemoveConfigModal = () => {
    if (!selectedConfig) return;
    closeConfigActionsMenu();
    setDeleteConfigId(selectedConfig.id);
  };

  const closeDeleteConfigModal = () => {
    setDeleteConfigId(0);
  };

  const submitEditConfig = async (invoice: Invoice.Configuration) => {
    try {
      await updateConfig(config.id, invoice);
      showSuccessMessage('Invoice configuration updated!');
      editConfigModal.close();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const onPerPageChange = (limit = 10) => {
    getConfigs({ page: 1, limit });
  };

  const onPageChange = (event, page) => {
    getConfigs({ page });
  };

  const handleChangeConfigurationStatus = async () => {
    if (!selectedConfig) return;
    const status = selectedConfig?.status === 'active' ? 'inactive' : 'active';
    const successMessage =
      status === 'active' ? 'Invoice configuration activated!' : 'Invoice configuration deactivated!';
    try {
      await updateConfig(selectedConfig.id, { status });
      showSuccessMessage(successMessage);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      closeConfigActionsMenu();
    }
  };

  const tableHead = useMemo(
    () => (
      <TableRow>
        {CONFIGURATION_TABLE.map((headCell, index) => {
          if (!canEditInvoice && headCell.value === 'actions') return null;
          if (headCell.sortable) {
            return (
              <TableCell key={headCell.value} style={{ backgroundColor: '#fff' }}>
                <TableSortLabel
                  active={orderBy === headCell.value}
                  direction={orderBy === headCell.value && orderByType ? 'desc' : 'asc'}
                  onClick={() => handleSort(headCell.value)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell key="actions" style={{ backgroundColor: '#fff' }}>
                {headCell.label}
              </TableCell>
            );
          }
        })}
      </TableRow>
    ),
    [handleSort, orderBy, orderByType, canEditInvoice]
  );

  const tableBody = useMemo(
    () =>
      configurations.map((invoiceConfig, index) => (
        <TableRow
          key={invoiceConfig.id}
          style={{
            cursor: 'pointer',
            opacity: invoiceConfig.status === 'inactive' ? 0.5 : 1,
            transition: 'all 0.3s ease',
            backgroundColor: invoiceConfig.id === selectedConfig?.id ? '#00000020' : '',
          }}
          onClick={() => openInvoicesPage(invoiceConfig.id)}
          hover
        >
          <TableCell>{invoiceConfig.id}</TableCell>
          <TableCell>{invoiceConfig.name}</TableCell>
          <TableCell>{invoiceConfig.date ? moment(invoiceConfig.date).format('MM/DD/YY') : ''}</TableCell>
          <TableCell>
            <TextOverflowTooltip disableInteractive title={invoiceConfig?.departments?.join(', ')}>
              <div style={{ maxWidth: 750, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {invoiceConfig?.departments?.join(', ')}
              </div>
            </TextOverflowTooltip>
          </TableCell>
          <TableCell>{invoiceConfig.timesheets_amount}</TableCell>
          <TableCell>{invoiceConfig.po_amount}</TableCell>
          <TableCell>{invoiceConfig.paid_amount}</TableCell>
          <TableCell>{invoiceConfig.billing_cycle}</TableCell>
          <TableCell>{invoiceConfig?.job_types?.join(' ')}</TableCell>
          <TableCell>{invoiceConfig.invoice_count}</TableCell>
          {canEditInvoice && (
            <TableCell>
              <IconButton
                id={String(invoiceConfig.id)}
                onClick={(event) => openConfigActionsMenu(event, invoiceConfig)}
              >
                <div className={'table-action_img-btn'}>
                  <SettingsIcon />
                </div>
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      )),
    [createInvoice, openEditConfigModal, configurations, canEditInvoice]
  );

  const configActive = selectedConfig?.status === 'active';

  useEffect(() => {
    updateSubInvoicesFilters(selectedConfig?.id, { id: '', po: '', conf: '', status: '' as InvoiceStatus });
  }, [selectedConfig?.id]);

  return (
    <div className="Invoices-table" style={{ paddingTop: 0 }}>
      {loading ? <LinearProgress /> : <div style={{ height: 4 }} />}
      <Paper style={{ height: '100%', overflow: 'hidden' }}>
        <TableContainer style={{ borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}>
          <Table stickyHeader>
            <TableHead style={{ background: '#fff' }}>{tableHead}</TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <AddInvoiceSliderComponent
        submit={submitEditConfig}
        invoice={config}
        showed={editConfigModal.isOpen}
        closeSlide={editConfigModal.close}
      />
      <DeleteConfigurationModal
        configId={deleteConfigId}
        onClose={closeDeleteConfigModal}
        open={Boolean(deleteConfigId)}
      />
      <AppTablePagination
        page={typeof search_options.page === 'string' ? parseInt(search_options.page) : search_options.page}
        total={search_options.total}
        perPage={search_options.limit}
        onChangePage={onPageChange}
        onPaginationChange={onPerPageChange}
      />
      <Menu
        id="config-actions-menu"
        anchorEl={configActionsMenuEl}
        keepMounted
        open={Boolean(configActionsMenuEl)}
        onClose={closeConfigActionsMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Tooltip
          disableInteractive
          style={{ cursor: configActive ? 'pointer' : 'not-allowed' }}
          title={!configActive && 'To create an invoice activate configuration first'}
        >
          <div>
            <MenuItem disabled={!configActive} onClick={createInvoice}>
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <Typography color={'rgba(0, 0, 0, 0.54)'} fontWeight={'bold'}>
                Create invoice
              </Typography>
            </MenuItem>
          </div>
        </Tooltip>

        <Tooltip
          disableInteractive
          style={{ cursor: configActive ? 'pointer' : 'not-allowed' }}
          title={
            !configActive ? 'To edit the configuration activate it first' : loading && 'Configuration is loading...'
          }
        >
          <div>
            <MenuItem disabled={!configActive || loading} onClick={openEditConfigModal}>
              <ListItemIcon>
                {loading ? <CircularProgress size={20} /> : <EditSharpIcon fontSize="small" />}
              </ListItemIcon>
              <Typography color={'rgba(0, 0, 0, 0.54)'} fontWeight={'bold'}>
                Edit
              </Typography>
            </MenuItem>
          </div>
        </Tooltip>

        <MenuItem onClick={handleChangeConfigurationStatus}>
          <ListItemIcon>
            {!configActive ? <LockResetIcon fontSize="small" /> : <LockIcon fontSize="small" />}
          </ListItemIcon>
          <Typography color={'rgba(0, 0, 0, 0.54)'} fontWeight={'bold'}>
            {!configActive ? 'Activate' : 'Deactivate'}
          </Typography>
        </MenuItem>

        <MenuItem onClick={openRemoveConfigModal}>
          <ListItemIcon>
            <HighlightOffSharpIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <Typography fontWeight={'bold'} color="#ed6c02">
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};
function mapStateToProps({ invoices, app }: ReduxState) {
  return {
    loading: invoices.processing,
    search_options: invoices.search_options,
    config: invoices.conf,
    canEditInvoice: app.permissions.includes(AppPermissions.edit_invoices),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getInvoices: (id, search_options) => dispatch(actions.InvoicesActions.getConfigurationInvoices(id, search_options)),
    getConfigs: (search_options: Partial<ReduxState['invoices']['search_options']>) =>
      dispatch(actions.InvoicesActions.updateFilters(search_options)),
    getConfiguration: (id) => dispatch(actions.InvoicesActions.getConfiguration(id)),
    updateConfig: (id, data) => dispatch(actions.InvoicesActions.updateInvoice(id, data)),
    updateSubInvoicesFilters: (id: number | string, search_options: ConfigurationInvoicesSearchOptions) =>
      dispatch(actions.InvoicesActions.updateInvoicesFilters(id, search_options)),
    create: (id) => dispatch(actions.InvoicesActions.createInvoiceFromConfig(id)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ConfigurationTable);
