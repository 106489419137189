import React from 'react';
import { createRoot } from 'react-dom/client';
// import * as serviceWorker from './serviceWorker';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';

import '@fontsource/nunito-sans/400.css';
import '@fontsource/nunito-sans/500.css';
import '@fontsource/nunito-sans/700.css';
import * as Sentry from '@sentry/react';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Geocode from 'react-geocode';
import { MapProvider } from 'react-map-gl';
import Smartlook from 'smartlook-client';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { EnvVariables } from 'Constants/app';
import app_history from 'app_history';
import ImagePreviewProvider from 'context/ImagePreview/Context';
import PopoverProvider from 'context/PopoversContext';

import App from './App';
import AppFullPageLoader from './Containers/Components/LoadAppComponent/AppFullPageLoader';
// import { index } from './Stores/index';
import { getDeviceType } from './Utils/Common';
import DialogsProvider from './context/Dialogs';
import store from './createStore';
import './index.scss';
import './mapboxConfig';
import muiThemeConfig from './theme/mui-theme-config';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://01cbcc5f0d4c4889be6b7e2ef19d6352@o876719.ingest.sentry.io/5975860',
    environment: import.meta.env.REACT_APP_ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    autoSessionTracking: false,
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.1,
    beforeSend(event) {
      const breadcrumbs = event.breadcrumbs ? [...event.breadcrumbs] : [];
      breadcrumbs.push({
        message: localStorage.getItem('Token')?.toString() || 'Empty',
        category: 'user_ls_token',
      });
      return { ...event, breadcrumbs };
    },
  });
  Smartlook.init('bdc2ae223a49f4e94b8b40ae6ff88cd2b553fa69');
}

Geocode.setApiKey(EnvVariables.GoogleMapApiKey);

(window as any).deviceType = getDeviceType();
const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router history={app_history}>
      <ThemeProvider theme={muiThemeConfig}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledEngineProvider injectFirst>
            <ImagePreviewProvider>
              <MapProvider>
                <DialogsProvider>
                  <PopoverProvider>
                    <AppFullPageLoader>
                      <App />
                    </AppFullPageLoader>
                  </PopoverProvider>
                </DialogsProvider>
              </MapProvider>
            </ImagePreviewProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  </Provider>
);

// serviceWorker.unregister();
