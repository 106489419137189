import moment from 'moment';

import { ConedUser } from 'types/Common/User';

import { EROLES } from 'Constants/user';

export const isUserSubcontractor = (user: { roles?: EROLES[] }): boolean => {
  return user.roles?.every((role) => role === EROLES.subcontractor);
};

export const getUserAuthSessionData = (user: ConedUser) => {
  const lastLogin = user.auth_sessions?.[0]?.created_at;
  const lastLoggedIP = user.auth_sessions?.[0]?.ip || 'Not logged in yet';

  return {
    lastLogin: lastLogin ? moment(lastLogin).format('MM/DD/YYYY hh.mm A') : 'Not logged in yet',
    lastLoggedIP,
  };
};
