import moment from 'moment';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

import { emailRegExp } from 'Constants/reg_exp';
import { PhoneNumberValidation } from 'Constants/validation';

export const WorkerValidation = Yup.object().shape({
  email: Yup.string().required('Email is required').matches(emailRegExp, 'Email is not correct'),
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s-]+$/, 'First Name can only contain Latin letters and dashes')
    .required('First Name is required'),
  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s-]+$/, 'Last Name can only contain Latin letters and dashes')
    .required('Last Name is required'),
  phoneNumber: PhoneNumberValidation.required('Phone Number is required'),
  workerTypes: Yup.array().min(1, 'Select at least one type').required('Worker type is required'),
  subcontractorId: Yup.string().notRequired(),
  password: Yup.string().min(6),
  ces_supervisor: Yup.boolean().transform((value) => !!value),
  begin_time: Yup.string(),
  end_time: Yup.string().when(
    ['begin_time', 'ces_supervisor'],
    ([begin_time, ces_supervisor]: [string, boolean], schema: Yup.StringSchema<string>) => {
      if (begin_time && ces_supervisor) {
        return schema
          .required('End time is required')
          .notOneOf([begin_time], 'End time must be different from start time')
          .test('end_time', 'End time must be at least 20 minutes after start time', async (end_time) => {
            const startMoment = moment(begin_time, 'HH:mm');
            const endMoment = moment(end_time, 'HH:mm');
            const diff = endMoment.diff(startMoment, 'minutes');
            const endIsAfterStart = endMoment.isAfter(startMoment);

            if (!endIsAfterStart || diff > 20) {
              return true;
            }

            return false;
          });
      }
      return schema;
    }
  ),
  address_zipcode: Yup.string()
    .transform((value: string) => value.trim())
    .matches(/^\d+$/, 'Must be only digits')
    .max(5, 'Zipcode must be 5-digit')
    .when('ces_supervisor', ([ces_supervisor]: [boolean], schema: Yup.StringSchema<string>) => {
      if (ces_supervisor) {
        return schema.required('Zipcode is required').min(5, 'Zipcode must be 5-digit');
      }
      return schema;
    }),
});

export const WorkerCreateValidation = WorkerValidation.concat(
  Yup.object().shape({
    password: Yup.string().min(6).required('Password is required'),
  })
);

export const AssignWorkersValidation = Yup.array().of(
  Yup.object().shape({
    startDate: Yup.date().required('Start date is a required field'),
    location: Yup.object()
      .shape({
        lat: Yup.number().required('Location is a required field'),
        lng: Yup.number().required('Location is a required field'),
        address: Yup.string().required('Location is a required field'),
      })
      .required('Location is a required field'),
    worker: Yup.object().required('Worker is a required field'),
    subcontractorId: Yup.string().notRequired(),
    subcontractor: Yup.object().notRequired(),
  })
);
