import { createContext, useContext } from 'react';

import { FormikProps } from 'formik';

import { TimesheetWithValidationProps } from 'Containers/Timesheets/types';

const TimesheetFormContext = createContext<
  FormikProps<TimesheetWithValidationProps> & { isLockedByConEdVerified?: boolean }
>(null);

export const useTimesheetFormContext = () => useContext(TimesheetFormContext);

export default TimesheetFormContext;
