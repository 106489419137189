import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import { IconButton, IconButtonProps } from '@mui/material';

type Props = IconButtonProps & { loading?: boolean };

const ActionButton = (props: Props) => {
  return (
    <LoadingButton loading={props.loading} sx={{ minWidth: 'auto', p: 0 }} disableRipple>
      <IconButton
        {...props}
        sx={
          props.sx || {
            bgcolor: 'secondary.light',
            borderRadius: '10px',
            width: 30,
            height: 30,
            svg: {
              width: 18,
              height: 18,
            },
          }
        }
        disabled={props.disabled || props.loading}
      >
        <MoreVertIcon />
      </IconButton>
    </LoadingButton>
  );
};

export default ActionButton;
