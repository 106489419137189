import { AppThunkAction } from 'types';

import { BillingAPI, JobsAPI } from 'Services/API';
import { UpdatePOSData } from 'Services/API/JobsAPI.service';

import {
  GET_JOBS_ERROR,
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  UPDATE_STATUS_JOB_ERROR,
  UPDATE_STATUS_JOB_REQUEST,
  UPDATE_STATUS_JOB_SUCCESS,
  UPDATE_DISPUTE_JOB_ERROR,
  UPDATE_DISPUTE_JOB_REQUEST,
  UPDATE_DISPUTE_JOB_SUCCESS,
  UPDATE_FILTERS,
  UPDATE_PO_NUMBER_REQUEST,
  UPDATE_PO_NUMBER_SUCCESS,
  UPDATE_PO_NUMBER_ERROR,
} from './actionTypes';
import { BillingSearchOptions } from './reducer';

type RetrieveOptions = Omit<Omit<Omit<BillingSearchOptions, 'departments'>, 'department_groups'>, 'statuses'> & {
  departments: number[];
  department_groups: number[];
  statuses: string;
};

export function retrieve(searchOptions: Partial<BillingSearchOptions> = {}): AppThunkAction<void> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_JOBS_REQUEST });
      const options = { ...getState().billing.search_options.asMutable({ deep: true }), ...searchOptions };
      const params: RetrieveOptions = {
        ...options,
        departments: options.departments.map(({ id }) => id),
        department_groups: options.department_groups.map(({ id }) => id),
        statuses: options.statuses.join(','),
      };
      const response = await BillingAPI.getJobs(params);
      const search_options = {
        page: response.page,
        limit: response.limit,
        totalPage: response.totalPage,
        total: response.total,
      };
      dispatch({
        type: GET_JOBS_SUCCESS,
        jobs: response.results,
        search_options: search_options,
      });
    } catch (error) {
      console.debug(error);
      dispatch({ type: GET_JOBS_ERROR });
    }
  };
}

export function updateDispute(id, data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_DISPUTE_JOB_REQUEST });
      const newStatus = 'coned_disputed';
      const response = await BillingAPI.updateDispute(id, data);
      dispatch({ type: UPDATE_DISPUTE_JOB_SUCCESS, result: { id, data, newStatus, response } });
      dispatch(retrieve());
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_DISPUTE_JOB_ERROR });
      throw error;
    }
  };
}

export function updateStatus(id, data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_STATUS_JOB_REQUEST });
      const status = data.status;
      const response = await BillingAPI.updateStatus(id, data);
      dispatch({ type: UPDATE_STATUS_JOB_SUCCESS, status: { id, status, response } });
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_STATUS_JOB_ERROR });
      throw error;
    }
  };
}

export function revertStatus(id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: UPDATE_STATUS_JOB_REQUEST });
      const response = await BillingAPI.revertStatus(id);
      const status = response.job.billing_status;
      dispatch({ type: UPDATE_STATUS_JOB_SUCCESS, status: { id, status, response } });
      return response;
    } catch (error) {
      dispatch({ type: UPDATE_STATUS_JOB_ERROR });
      throw error;
    }
  };
}

export function updateFilters(search_options: Partial<BillingSearchOptions>): AppThunkAction<void> {
  return function (dispatch, getState) {
    const current_search_options = getState().billing.search_options.asMutable({ deep: true });
    const new_search_options = { ...current_search_options, ...search_options };
    dispatch({ type: UPDATE_FILTERS, search_options: new_search_options });
    dispatch(retrieve(new_search_options));
  };
}
