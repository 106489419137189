import { Box, Button, Table } from '@mui/material';
import styled from '@mui/material/styles/styled';

export const RolesStyledTable = styled(Table)({
  borderCollapse: 'collapse',
  '& .MuiTableHead-root': {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,
    boxShadow: '0px 5px 5px #00000005',
    '& .MuiTableCell-root': {
      '&:last-child': {
        textAlign: 'center',
      },
    },
  },

  '& .MuiTableBody-root': {
    '& .MuiTableRow-root': {
      transition: '0.2s',
      cursor: 'pointer',
      boxSizing: 'border-box',
      border: 'none',
      borderBottom: '1px solid #00000020',

      '&:hover': {
        backgroundColor: '#cecece59',
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      '& .MuiTableCell-root': {
        padding: '8px 20px',
        border: 'none',
        '&:last-child': {
          textAlign: 'center',
        },
      },
    },
  },
});

export const BlackButton = styled(Button)({
  backgroundColor: '#000',
  '&:hover': {
    backgroundColor: '#00000099',
  },
});
