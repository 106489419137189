import Scheduling from '../types';

export const ZONES = [
  {
    id: 1,
    lat: 40.761991591162364,
    lon: -73.98158451570959,
    distance: '5',
    name: 'M',
    state: 'NY',
  },
  {
    id: 2,
    lat: 40.86082971108572,
    lon: -73.88937910497462,
    name: 'BX',
    state: 'NY',
  },
  {
    id: 3,
    lat: 40.634924,
    lon: -73.957821,
    name: 'BK',
    state: 'NY',
  },
  {
    id: 4,
    lat: 40.725285,
    lon: -73.852819,
    name: 'QU',
    state: 'NY',
  },
  {
    id: 5,
    lat: 40.596702,
    lon: -74.149541,
    name: 'SI',
    state: 'NY',
  },
  {
    id: 9,
    lat: 41.357751,
    lon: -73.84753,
    name: 'WEST-North',
    state: 'NY',
  },
  {
    id: 10,
    lat: 41.07222197989834,
    lon: -73.80124380500897,
    name: 'WEST-Center',
    state: 'NY',
  },
  {
    id: 11,
    lat: 40.97909173685917,
    lon: -73.68735576487228,
    name: 'WEST-SEast',
    state: 'NY',
  },
  {
    id: 12,
    lat: 41.01310626761778,
    lon: -73.87314720835161,
    name: 'WEST-SWest',
    state: 'NY',
  },
];

export const SCHEDULE_STATUSES_LABELS: Record<Scheduling.ScheduleStatus, string> = {
  pending: 'Pending',
  active: 'Active',
  completed: 'Completed',
} as const;

export const SCHEDULE_STATUSES: { value: Scheduling.ScheduleStatus; label: string }[] = [
  {
    value: 'pending',
    label: SCHEDULE_STATUSES_LABELS.pending,
  },
  {
    value: 'active',
    label: SCHEDULE_STATUSES_LABELS.active,
  },
  {
    value: 'completed',
    label: SCHEDULE_STATUSES_LABELS.completed,
  },
];
