import * as Yup from 'yup';

import { emailRegExp, spacesRegExp } from 'Constants/reg_exp';

const EmailSchema = Yup.string().label('Email').matches(emailRegExp, 'Invalid email').required();
const PasswordSchema = Yup.string().label('Password').required();

export const LoginSchema = Yup.object().shape({
  email: EmailSchema,
  password: PasswordSchema,
});

export const TwoFactorLoginSchema = Yup.object().shape({
  code: Yup.string()
    .min(6, 'The verification code must contain 6 characters.')
    .max(6, 'The verification code must contain 6 characters.')
    .label('Code')
    .required(),
});

export const RecoverySchema = Yup.object().shape({
  email: EmailSchema,
});

export const ConfirmPasswordsSchema = Yup.object().shape({
  password: PasswordSchema.matches(spacesRegExp, 'Password can not contain spaces').min(6),
  confirmPassword: PasswordSchema.matches(spacesRegExp, 'Password can not contain spaces')
    .min(6)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
