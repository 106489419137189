import { FC, memo, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import {
  Box,
  Icon,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  colors,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { MasterTimesheetItem } from 'types/Timesheet';

import { TimesheetStatus } from 'Constants/timesheet';
import { UserRoles } from 'Constants/user';
import { FORMATS } from 'Utils/Date';
import { SortTableIcon } from 'Utils/Icon';
import useSearchSortParams from 'Utils/hooks/useSearchSortParams';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';
import { useAppSelector } from 'createStore';

import { MasterTimesheetContentBox, StyledTable } from '../../styled';

const TableColumns: {
  key: keyof MasterTimesheetItem;
  label: string;
  bodyCell: (row: MasterTimesheetItem) => string | number | JSX.Element;
}[] = [
  {
    key: 'id',
    label: 'Shift ID',
    bodyCell: (row) => row.id,
  },
  {
    key: 'worker_name',
    label: 'Name',
    bodyCell: (row) => (
      <Typography fontSize={13} fontFamily={'Poppins Medium'} color={'primary.main'}>
        {row.worker_name}
      </Typography>
    ),
  },
  {
    key: 'start_at',
    label: 'Start Date',
    bodyCell: (row) => moment(row.start_at).format(FORMATS.masterTimesheetDateTimeChanges),
  },
  {
    key: 'finish_at',
    label: 'Finish Date',
    bodyCell: (row) => moment(row.finish_at).format(FORMATS.masterTimesheetDateTimeChanges),
  },
  {
    key: 'total_hours',
    label: 'Total Hrs',
    bodyCell: (row) => row.total_hours,
  },
  {
    key: 'status',
    label: 'Status',
    bodyCell: (row) => (
      <Box display="flex" alignItems="center" gap="6px" color={TimesheetStatus.getColor(row.status)}>
        <Icon sx={{ width: 12 }} component={TimesheetStatus.getIcon(row.status)} />
        {TimesheetStatus.toUserString(row.status)}
      </Box>
    ),
  },
];

const MasterTimesheetWorkers: FC = () => {
  const history = useHistory();
  const { values } = useMasterTimesheetContext();
  const { sortedData, order_by, order, setSort } = useSearchSortParams(values?.timesheets);

  const getHighlightedRowSx = useCallback(({ clocked_in_late, clocked_out_late }: MasterTimesheetItem): SxProps => {
    if (!UserRoles.has.CESRole || (!clocked_in_late && !clocked_out_late)) return {};

    return { bgcolor: colors.yellow[50] };
  }, []);

  const goToTimesheetEditPage = (id: number) => {
    history.push(`/timesheets/${id}/edit`);
  };

  return (
    <MasterTimesheetContentBox overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            {TableColumns.map((column) => (
              <TableCell key={column.key}>
                <TableSortLabel
                  key={column.key}
                  active={order_by === column.key}
                  direction={order_by === column.key ? order : 'asc'}
                  IconComponent={SortTableIcon}
                  onClick={() => setSort(column.key)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((timesheet) => (
            <TableRow
              key={timesheet.id}
              sx={{
                cursor: 'pointer',
                ...getHighlightedRowSx(timesheet),
                '&:hover': {
                  backgroundColor: grey[50],
                },
              }}
              onClick={() => goToTimesheetEditPage(timesheet.id)}
            >
              {TableColumns.map((column) => (
                <TableCell key={column.key}>{column.bodyCell(timesheet)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </MasterTimesheetContentBox>
  );
};

export default memo(MasterTimesheetWorkers);
