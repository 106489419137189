import { useMemo } from 'react';

import { useSearchParams } from './useSearchParams';

const useSearchSortParams = <T>(data: T[]) => {
  const {
    params: { order, order_by },
    setSearchParam,
    removeSearchParam,
  } = useSearchParams<{
    order_by: keyof T;
    order: 'asc' | 'desc';
  }>();

  const setOrder = (order: 'asc' | 'desc') => {
    setSearchParam('order', order);
  };

  const setOrderBy = (order_by: keyof T) => {
    setSearchParam('order_by', order_by);
  };

  const removeSort = () => {
    removeSearchParam('order_by');
    removeSearchParam('order');
  };

  const setSort = (property: keyof T) => {
    if (order_by === property && order === 'desc') {
      removeSort();
      return;
    }
    const isAsc = order_by === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = useMemo(() => {
    if (!data) return [];
    const newData = [...data];
    if (!order_by || !order) return newData;
    return newData?.sort((a, b) =>
      order === 'asc' ? (a[order_by] > b[order_by] ? 1 : -1) : a[order_by] < b[order_by] ? 1 : -1
    );
  }, [data, order, order_by]);

  return {
    order,
    order_by,
    sortedData,
    setSort,
    setOrder,
    removeSort,
  };
};

export default useSearchSortParams;
