import React, { useEffect, useMemo, useState } from 'react';

import { Typography } from '@mui/material';

import { ConedUser } from 'types/Common/User';

import { UsersAPI } from 'Services/API';
import useProcessing from 'Utils/hooks/useProcessing';
import parseFullName from 'Utils/parseFullName';

type Props = {
  id: string;
};

const UserSmallComponent = ({ id }: Props) => {
  const [worker, setWorker] = useState<ConedUser>(null);
  const { inProcess: loading, promiseWrapper } = useProcessing();

  const resetWorker = () => setWorker(null);

  const content = useMemo(() => {
    if (loading) return 'Loading...';
    if (!worker) return id;

    return parseFullName({
      first_name: worker.firstName,
      last_name: worker.lastName,
    });
  }, [worker, loading, id]);

  useEffect(() => {
    if (!id || id === '-') {
      setWorker(null);
      return;
    }
    promiseWrapper(UsersAPI.getById(id)).then(setWorker).catch(resetWorker);
  }, [id]);

  return (
    <Typography
      fontSize={13}
      fontStyle={loading ? 'italic' : 'normal'}
      color={loading ? 'text.secondary' : 'text.primary'}
    >
      {content}
    </Typography>
  );
};

export default UserSmallComponent;
