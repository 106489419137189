import { FC } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';

import { AppThunkDispatch } from 'types';

import { ReactComponent as CancelIcon } from 'Assets/icons/cancel-icon.svg';
import 'Containers/Dispatch/dialog/CancelJobReason/CancelJobReason.scss';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import app_history from 'app_history';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  open: boolean;
  onClose: () => void;
  configId: string | number;
  historyPush?: string;
};

const DeleteConfigurationModal: FC<Props> = ({ open, onClose, configId, historyPush }) => {
  const { inProcess, promiseWrapper } = useProcessing();
  const dispatch = useDispatch<AppThunkDispatch>();

  const deleteConfiguration = async () => {
    try {
      await promiseWrapper(dispatch(actions.InvoicesActions.deleteInvoice(configId)));
      showSuccessMessage('Configuration deleted successfully');
      if (historyPush) {
        app_history.push(historyPush);
      }
    } catch (error) {
      showErrorMessage(error);
    } finally {
      onClose();
    }
  };

  return (
    <AppPaperModal
      title="Delete configuration"
      open={open}
      onClose={onClose}
      submitButton={{ title: 'Delete', onClick: deleteConfiguration, loading: inProcess }}
    >
      <span>Are you sure you want to delete invoice configuration?</span>
      <Box className={'cancel-image-group'} mt={2} mb={2}>
        <CancelIcon />
      </Box>
    </AppPaperModal>
  );
};

export default DeleteConfigurationModal;
