export enum BILLING_CYCLE {
  Weekly = 'weekly',
  Monthly = 'monthly',
  Daily = 'daily',
}

export interface IInvoice {
  uid?: number;
  id?: string;
  job_types: number[];
  endDate?: Date;
  emails: string[];
  billing_cycle: string;
  completed_jobs_only: boolean;
  use_actual_breaks: boolean;
  ignore_breaks: boolean;
  forceBreakValue: boolean;
  force_break_time: string;
  date: null | Date;
  departments: number[];
  excel_template_id?: number;
  template_id?: number;
  totalAmount?: number;
  po?: string;
  paid?: string;
  data?: Date;
  validate_billing_status?: boolean;
  include_missing_po?: boolean;

  startDateTime?: Date;
  endDateTime?: Date;
  totalInvoiceAmount?: string | number;
  departmentNames?: string[];

  jobType?: number;
  overtime?: number;
  holiday?: number;
  emergency?: number;
  regular?: number;
  total?: number;
}
