import { FC } from 'react';

import { Tooltip, Box, Button, ButtonProps } from '@mui/material';

type Props = ButtonProps;

const UpdatePOButton: FC<Props> = (props) => {
  return (
    <Tooltip
      title={
        props.disabled ? 'Please select at least one job' : 'Update PO# / Requisition# / Receipt# / WR# / Job# / POET#'
      }
      disableInteractive
      arrow
    >
      <Box width={props.fullWidth ? '100%' : 'auto'}>
        <Button variant="contained" color="secondary" {...props}>
          Update #
        </Button>
      </Box>
    </Tooltip>
  );
};

export default UpdatePOButton;
