import { ImmutableObject } from 'seamless-immutable';

import { DispatchJob } from 'types/Dispatch';

export const requestorLetter = (email, name, jobId, locations) => {
  return `mailto:${email}?subject=Regarding%20Job%20${jobId}&body=Hello%20${encodeURI(
    name
  )}%2C%0D%0A%0D%0ARegarding%20Job%20${jobId}%2C%20at%20${encodeURIComponent(
    locations.map((location) => location.address).join(', ')
  )}`;
};

export const sendDispatchJobEmails = ({
  id,
  locations,
  added_by,
  supervisor,
  ccUser,
}: ImmutableObject<DispatchJob>) => {
  const emails = [];
  [added_by, supervisor].forEach((user) => {
    if (user) {
      emails.push(user.email);
    }
  });
  ccUser.forEach(({ email }) => {
    emails.push(email);
  });
  if (!emails.length) return;
  window.open(requestorLetter(emails.join(';'), '', id, locations), '_blank');
};
