import React, { useState, useEffect, useMemo, CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { ResponseSubcontractorItem } from 'types/Subcontractors';

import { actions } from 'Services';
import { ReduxState } from 'createStore';

interface Props {
  onSelectSubcontractor?: (subcontractor: ResponseSubcontractorItem | string) => void;
  selectedId?: number;
  inputSize?: TextFieldProps['size'];
  width?: CSSProperties['width'];
}

export default function SubcontractorMaterialAsyncSearch({
  onSelectSubcontractor,
  selectedId,
  inputSize = 'medium',
  width = 300,
}: Props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const subcontractors = useSelector((state: ReduxState) => state.subcontractors.subcontractors).asMutable({
    deep: true,
  });

  const getSubcontractors = () => dispatch(actions.SubcontractorsActions.retrieve());

  useEffect(() => {
    getSubcontractors();
  }, []);

  const value = useMemo(() => subcontractors.find(({ id }) => id === selectedId) || null, [subcontractors, selectedId]);

  return (
    <Autocomplete<ResponseSubcontractorItem, false>
      id="asynchronous-demo"
      style={{ width }}
      size={inputSize}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      options={subcontractors}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Subcontractor"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
      onChange={(event, value) => onSelectSubcontractor(value)}
    />
  );
}
