import React, { useCallback, useState } from 'react';

import { Autocomplete, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';

import { AppAPI } from 'Services/API';
import useProcessing from 'Utils/hooks/useProcessing';

interface Props {
  loading?: boolean;
  label?: string;
  inputSize?: 'small' | 'medium';
  onSelect?: (item: any) => void;
  defaultOptions?: { label: string; value: any }[];
  className?: string;
}

type GeoOption = {
  label: string;
  value: {
    address: string;
    lat: number;
    lng: number;
  };
};

export default function LocationsMaterialAsyncSearch({
  onSelect,
  loading,
  defaultOptions = [],
  className,
  inputSize = 'medium',
  label = 'Locations',
}: Props) {
  const [options, setOptions] = useState<GeoOption[]>([]);
  const [value, setValue] = useState('');
  const { promiseWrapper, inProcess } = useProcessing();

  const loadLocations = useCallback(
    async (searchAddress = '') => {
      if (!searchAddress) {
        setOptions([]);
        return;
      }
      try {
        const { places } = await promiseWrapper(AppAPI.getPlacesByAddress(searchAddress));
        if (!Array.isArray(places)) return;
        const newOptions = places.map((place) => ({
          label: place.address,
          value: {
            address: place.address,
            lat: place.lat,
            lng: place.lon,
          },
        }));
        setOptions(newOptions);
      } catch (error) {
        setOptions([]);
      }
    },
    [promiseWrapper]
  );

  return (
    <Autocomplete
      className={className}
      inputValue={value}
      freeSolo
      onInputChange={(event, newValue) => {
        setValue(newValue);
        loadLocations(newValue);
      }}
      size={inputSize}
      isOptionEqualToValue={(option, value) => option?.label === value?.label}
      getOptionLabel={(option) => (typeof option === 'string' ? option : String(option?.label ?? ''))}
      options={value ? options : defaultOptions}
      filterOptions={(options) => options}
      loadingText="Loading..."
      loading={inProcess || loading}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value?.id || option.label}>
            <span>{option.label}</span>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(inProcess || loading) && <CircularProgress color="inherit" size={20} style={{ marginRight: 30 }} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(event, value) => onSelect(value)}
    />
  );
}
