import Immutable from 'seamless-immutable';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = Immutable({
  jobLocation: null,
  currentLocation: null,
});

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    /*case actionTypes.GET_ZONES_REQUEST:
            return state.merge({
                get_zones_processing   : true
            });
        case actionTypes.GET_ZONES_SUCCESS:
            return state.merge({
                get_zones_processing  : false,
                zones                 : action.zones,
                stats                 : action.stats
            });
        case actionTypes.GET_ZONES_FAIL:
            return state.merge({
                get_zones_processing   : false
            });
        case actionTypes.GET_ZONES_ERROR:
            return state.merge({
                get_zones_processing   : false
            });*/
    case actionTypes.SET_CURRENT_LOCATION:
      return state.merge({
        currentLocation: action.currentLocation,
        jobLocation: action.jobLocation,
      });
    case LOGOUT:
      return state.merge(initialState);
    default:
      return state;
  }
}
