import { styled, Switch } from '@mui/material';

const SwitchOnOff = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'centered',
})<{ centered?: boolean }>(({ theme, centered }) => ({
  justifySelf: centered ? 'center' : 'flex-start',
  padding: 6,
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
  },
  '& .MuiSwitch-thumb': {
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      theme.palette.getContrastText(theme.palette.primary.light)
    )}" d="M19,13H5V11H19V13Z" /></svg>')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    boxShadow: 'none',
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      theme.palette.getContrastText(theme.palette.primary.main)
    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
  },
}));

export default SwitchOnOff;
