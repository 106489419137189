import { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { baseToastConfig } from 'Constants/app';
import { JobStatus } from 'Constants/job';
import { JobsAPI } from 'Services/API';

type NextJobStatus = (typeof JobStatus)['CANCELLED_BILL'] | (typeof JobStatus)['COMPLETED'];

/** @param {boolean} [recalculate] If true - the hook will send a new request for the total number of hours. If false, nothing will happen */
const useGetTotalHours = (
  status: NextJobStatus = JobStatus.CANCELLED_BILL,
  jobId: number = 0,
  recalculate: boolean = false
) => {
  const [calculated, setCalculated] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [error, setError] = useState('');
  const [controlledRecalculate, setControlledRecalculate] = useState(0);

  useEffect(() => {
    if (!recalculate) return;
    setCalculated(true);
    setError('');
    JobsAPI.getPaymentHours(jobId, { status })
      .then(({ total_hours }) => {
        setTotalHours(parseFloat(total_hours));
        setCalculated(false);
      })
      .catch((err) => {
        const serverError = `Total hours calculation: ${err?.error || err?.message || 'Something went wrong'}`;
        toast.error(serverError, baseToastConfig);
        setError(serverError);
        /* 
            We don't want to setTotalHoursPending to false when something went wrong while requesting total hours.
            If the user doesn't know the total number of hours for which he(she) has to pay he(she) can't cancel job        
        */
      });
  }, [recalculate, jobId, status, controlledRecalculate]);

  const reCalculate = useCallback(() => setControlledRecalculate(Math.random()), []);

  const responseStatusText = error ? '(Calculation failed)' : '(Calculating...)';

  const totalHoursString = parseFloat(totalHours.toFixed(2)).toString();

  const displayedTotalHours = !calculated && !error ? totalHoursString : responseStatusText;

  return { totalHours, calculated, error, reCalculate, displayedTotalHours };
};

export default useGetTotalHours;
