import React from 'react';

import Select from 'react-select';

import { MUNICIPALITY } from 'Constants/job';
import { MunicipalityOptionType } from 'Constants/job';

type SingleOrMulti<isMulti extends boolean = false> = isMulti extends true
  ? MunicipalityOptionType[]
  : MunicipalityOptionType;

type Props<isMulti extends boolean = false> = {
  onSelect?: (item: SingleOrMulti<isMulti>) => void;
  placeholder?: string;
  isClearable?: boolean;
  defaultValue?: SingleOrMulti<isMulti>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  defaultInputValue?: string;
  isMulti?: isMulti;
  disabled?: boolean;
  value?: SingleOrMulti<isMulti>;
};

const MunicipalitiesAsyncSearch = <Multi extends boolean = false>({
  value,
  defaultInputValue = '',
  defaultValue = null,
  isClearable = true,
  disabled = false,
  placeholder = 'Select municipality',
  onBlur = () => {},
  onSelect = () => {},
  isMulti,
}: Props<Multi>) => {
  return (
    <Select
      options={MUNICIPALITY}
      isDisabled={disabled}
      isSearchable
      onBlur={onBlur}
      isClearable={isClearable}
      placeholder={placeholder}
      onChange={onSelect}
      value={value}
      defaultValue={defaultValue}
      defaultInputValue={defaultInputValue}
      isMulti={isMulti}
    />
  );
};

export default MunicipalitiesAsyncSearch;
