import { AppThunkAction } from 'types';

import { SubcontractorsAPI } from 'Services/API';
import { retrieveUsers } from 'Services/users/actions';

import * as actionTypes from './actionTypes';

export function retrieve(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_SUBCONTRACTORS_REQUEST });
      const response = await SubcontractorsAPI.getAll();
      dispatch({
        type: actionTypes.GET_SUBCONTRACTORS_SUCCESS,
        subcontractors: response.results,
      });
    } catch (error) {
      dispatch({ type: actionTypes.GET_SUBCONTRACTORS_ERROR });
    }
  };
}
//Delete Subcontractor

export function deleteSubcontractor(id: number): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DELETE_SUBCONTRACTOR_REQUEST });
      const response = await SubcontractorsAPI.delete(id);
      dispatch({ type: actionTypes.DELETE_SUBCONTRACTOR_SUCCESS, item: { response, id } });
      dispatch(retrieveUsers());
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.DELETE_SUBCONTRACTOR_ERROR });
      throw error;
    }
  };
}
export function createSubcontractor(data) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.CREATE_SUBCONTRACTOR_REQUEST });
      const response = await SubcontractorsAPI.create(data);
      dispatch({
        type: actionTypes.CREATE_SUBCONTRACTOR_SUCCESS,
        subcontractor: response,
      });
      dispatch(retrieveUsers());
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_SUBCONTRACTOR_ERROR });
      throw error;
    }
  };
}

export function updateSubcontractor(id, data) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPDATE_SUBCONTRACTOR_REQUEST });
      const response = await SubcontractorsAPI.update(id, data);
      dispatch({
        type: actionTypes.UPDATE_SUBCONTRACTOR_SUCCESS,
        subcontractor: response,
      });
      dispatch(retrieveUsers());
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_SUBCONTRACTOR_ERROR });
      throw error;
    }
  };
}
