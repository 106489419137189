export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numbersRegExp = /^[0-9]+$/;

export const phoneNumberRegExp = /^[0-9-\(\)\ ]+$/;

export const lettersRegExp = /^[a-zA-Z]+$/;
export const lettersWithSpacesRegExp = /^[a-zA-Z\s]+$/;

export const profileNameRegExp = /^[a-zA-Z-\s]+$/;

export const lettersAndNumbersRegExp = /^[a-zA-Z0-9]+$/;
export const lettersAndNumbersWithSpacesRegExp = /^[a-zA-Z0-9\s]+$/;

export const workHoursFilterRegExp = /^[0-9]{1,3}$/;

export const structureRegExp = /^[\s]*[a-zA-Z0-9-\/]+[a-zA-Z0-9-\s\/]*$/;

export const spacesRegExp = /^[^\s]*$/;

export const invoiceConfigurationNameRegExp = /^[\w\-. ]+$/;

export const sectionRegExp = /^[\s]*[a-zA-Z0-9-#]+[a-zA-Z0-9-\s#]*$/;
