import React, { useEffect, useState } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';

import { useFormik } from 'formik';

import { Box, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { JobsAPI, WorkersAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';

import { CreateIssueValidation } from './CreateIssueValidation';

type Props = {
  closeModal: () => void;
  updateComplaintsData: () => void;
  isOpen: boolean;
};

type JobShiftsType = {
  [key: number]: number[];
};

const initialValues = {
  message: '',
  jobId: null as number | null,
  shiftId: null as number | null,
};

const CreateNewIssueModal = ({
  closeModal = () => {},
  updateComplaintsData = () => {},
  isOpen = false,
  match,
}: Props & RouteChildrenProps<{ id: string }>) => {
  const [jobShiftsIDs, setJobShiftsIDs] = useState<JobShiftsType>({});

  const { inProcess: jobsLoading, promiseWrapper } = useProcessing();
  const workerId = parseInt(match?.params?.id);

  const submitComplain = async (values: typeof initialValues, { resetForm }) => {
    try {
      await WorkersAPI.sendComplaint({ workerId, ...values });
      showSuccessMessage('Report created!');
      updateComplaintsData();
      resetForm();
      closeModal();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateIssueValidation,
    onSubmit: submitComplain,
  });

  const changeJobId = (event: React.ChangeEvent<{}>, option: string) => {
    formik.setValues({ ...formik.values, shiftId: null, jobId: Number(option) || null });
  };

  const changeShiftId = (event: React.ChangeEvent<{}>, shiftId: number) => {
    formik.setFieldValue('shiftId', shiftId || null);
  };

  useEffect(() => {
    const { jobId } = formik.values;
    if (!jobId) return;
    formik.setFieldValue('shiftId', jobShiftsIDs[jobId]?.length ? jobShiftsIDs[jobId][0] : 0);
  }, [formik.values.jobId, jobShiftsIDs]);

  useEffect(() => {
    promiseWrapper(JobsAPI.getJobs({ workerId })).then(({ results = [] }) => {
      const jobShiftIDs: JobShiftsType = results.reduce((acc: JobShiftsType, { id = 0, workers = [] }: any) => {
        const shiftIds = workers.filter(({ id }) => id === workerId).map(({ shift_id }) => shift_id);
        return { ...acc, [id]: shiftIds };
      }, {});
      setJobShiftsIDs(jobShiftIDs);
    });
  }, [workerId]);

  return (
    <AppPaperModal
      title="Report New Issue"
      subtitle="Fill all fields to create Complain"
      modalId="create-new-issue-modal"
      onClose={closeModal}
      open={isOpen}
      submitButton={{
        title: 'Report',
        onClick: formik.submitForm,
        loading: formik.isSubmitting,
      }}
      cancelButton={{
        title: 'Cancel',
        onClick: closeModal,
      }}
    >
      <Box
        display="flex"
        marginBottom="1rem"
        gap="20px"
        sx={{
          minWidth: {
            xs: 'unset',
            sm: 400,
          },
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Autocomplete
          fullWidth
          sx={{ flex: 1 }}
          options={Object.keys(jobShiftsIDs)}
          loading={jobsLoading || !Object.keys(jobShiftsIDs)?.length}
          loadingText={jobsLoading ? 'Loading...' : 'No jobs found'}
          value={formik.values.jobId ? String(formik.values.jobId) : null}
          onChange={changeJobId}
          ListboxProps={{ style: { maxHeight: 300 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              name="jobId"
              label="Confirmation Number"
              variant="outlined"
              error={Boolean(formik.errors.jobId) && formik.touched.jobId}
              helperText={formik.touched.jobId && formik.errors.jobId}
            />
          )}
        />
        <Autocomplete
          fullWidth
          sx={{ flex: 1 }}
          options={jobShiftsIDs[formik.values.jobId] || []}
          getOptionLabel={(shiftId) => shiftId?.toString() || ''}
          value={formik.values.shiftId || null}
          disabled={jobShiftsIDs[formik.values.jobId]?.length <= 1}
          onChange={changeShiftId}
          ListboxProps={{ style: { maxHeight: 300 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'none',
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={formik.handleBlur}
              label="Shift Id"
              variant="outlined"
              error={Boolean(formik.errors.shiftId) && formik.touched.shiftId}
              helperText={formik.touched.shiftId && formik.errors.shiftId}
            />
          )}
        />
      </Box>
      <TextField
        variant="outlined"
        fullWidth
        multiline
        label="Message"
        maxRows={4}
        minRows={4}
        placeholder={'Enter your message here'}
        value={formik.values.message}
        name="message"
        onChange={formik.handleChange}
        error={Boolean(formik.errors.message) && formik.touched.message}
        helperText={formik.touched.message && formik.errors.message}
      />
    </AppPaperModal>
  );
};

export default withRouter(CreateNewIssueModal);
