import { MutableRefObject, useEffect, useRef } from 'react';

export default function useResize<T>(
  resizeCallback: (
    event: Required<Partial<Event> & { target: Window }>,
    storedValue: MutableRefObject<T>
  ) => void = () => {},
  stored?: T
) {
  const storedValue = useRef(stored);

  useEffect(() => {
    const listenerCallback = (event) => resizeCallback(event, storedValue);

    window.addEventListener('resize', listenerCallback);
    return () => {
      window.removeEventListener('resize', listenerCallback);
    };
  }, []);
}
