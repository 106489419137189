/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, ReactNode, useMemo } from 'react';

import { ImmutableArray } from 'seamless-immutable';

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableRowProps,
  PaperProps,
} from '@mui/material';

import { useAppSelector } from 'createStore';

import { TableColumnData } from './utils';

type Props<ItemType> = {
  data: ItemType[] | ImmutableArray<ItemType>;
  orderByValue: string;
  orderByType: boolean;
  changeOrder: (params: { order_by_type?: boolean; order_by?: string }) => void;
  columnsConfig: TableColumnData<ItemType>[];
  tableRowProps?: (row?: ItemType) => TableRowProps;
  AdditionalRows?: (row?: ItemType) => ReactNode;
  PaperProps?: PaperProps;
};

const TimesheetTable = function <ItemType>({
  data = [],
  orderByType,
  orderByValue,
  changeOrder = () => {},
  tableRowProps = () => ({}),
  columnsConfig = [],
  AdditionalRows = () => null,
  PaperProps = {},
}: Props<ItemType>): JSX.Element {
  const canSeeSubcontractors = useAppSelector((state) => state.userPermissions.canSeePage.subcontractors);

  const TableHeader = useMemo(() => {
    if (!canSeeSubcontractors) {
      return columnsConfig.filter((headerCell) => headerCell.value !== 'companies.name');
    }
    if (canSeeSubcontractors) {
      return columnsConfig;
    }
  }, [canSeeSubcontractors, columnsConfig]);

  const handeleSort = (value) => {
    if (orderByValue === value) {
      changeOrder({ order_by_type: !orderByType });
    } else {
      changeOrder({ order_by: value, order_by_type: true });
    }
  };

  return (
    <Paper
      {...PaperProps}
      style={{
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        overflow: 'hidden',
        flex: 1,
        ...(PaperProps.style ? PaperProps.style : {}),
      }}
    >
      <TableContainer style={{ overflow: 'auto', height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {TableHeader.map(({ headCellProps, label, sortable, value }, index) => {
                if (sortable) {
                  return (
                    <TableCell key={index} {...headCellProps()}>
                      <TableSortLabel
                        active={orderByValue === value}
                        direction={orderByValue === value && orderByType ? 'desc' : 'asc'}
                        onClick={() => handeleSort(value)}
                      >
                        {label}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell key={index} {...headCellProps()}>
                      {label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <Fragment key={tableRowProps(row)?.key || row?.id || index}>
                <TableRow {...tableRowProps(row)}>
                  {TableHeader.map(({ bodyCellProps, renderData }, index) => (
                    <TableCell key={index} {...bodyCellProps(row)}>
                      {renderData(row)}
                    </TableCell>
                  ))}
                </TableRow>
                {AdditionalRows(row)}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TimesheetTable;
