import { Dialog, DialogActions, DialogTitle } from '@mui/material';

import Button from 'components/Button/Button';

type Props = {
  isOpen: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteTimesheetConfirmation = ({
  isOpen = false,
  onClose = () => {},
  onConfirm = () => {},
  loading = false,
}: Props) => {
  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle className={'cancel-title'} style={{ maxWidth: 365 }}>
        You will NOT be able to revert this action. Are you sure you want to delete this Timesheet?
      </DialogTitle>
      <DialogActions className={'action-button-group'}>
        <Button color={'gray'} width={'158px'} borderRadius={'20px'} textTransform={false} onClick={onClose}>
          Cancel
        </Button>
        <Button
          color={'dark'}
          width={'158px'}
          borderRadius={'20px'}
          textTransform={false}
          processing={loading}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTimesheetConfirmation;
