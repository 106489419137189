import AppPermissions from './permissions';

export const ReportType = {
  Payroll: 'payroll',
  TecorienPayroll: 'tecorien-payroll',
  Daily: 'daily',
  DailyOpen: 'open-jobs',
  Weekly: 'weekly',
  WeeklyUnpaid: 'weekly-unpaid',
  Metrics: 'metrics',
  Monthly: 'monthly',
  WorkersTotalHours: 'workers-total-hours',
  CESSupervisorsTotalHours: 'ces-supervisors-total-hours',
  DepartmentInvoices: 'department-invoices',
  PaidTimesheets: 'paid-timesheets',
  BilledTimesheets: 'billed-timesheets',
  UnpaidInvoices: 'unpaid-invoices',
  SuspiciousFlaggingTimesheets: 'suspicious-flagging-timesheets',
} as const;

export const ReportLabels = Object.freeze({
  [ReportType.Payroll]: 'Payroll',
  [ReportType.TecorienPayroll]: 'Tecorien Payroll',
  [ReportType.Daily]: 'Daily Report',
  [ReportType.DailyOpen]: 'Daily Open Jobs Report',
  [ReportType.Weekly]: 'Weekly Report',
  [ReportType.WeeklyUnpaid]: 'Closed/Unpaid Jobs Weekly Report',
  [ReportType.Metrics]: 'Metrics Report',
  [ReportType.Monthly]: 'Monthly Report',
  [ReportType.WorkersTotalHours]: 'Workers Total Hours Report',
  [ReportType.CESSupervisorsTotalHours]: 'CES Supervisors Total Hours Report',
  [ReportType.DepartmentInvoices]: 'Department Invoices Report',
  [ReportType.PaidTimesheets]: 'Paid Timesheets Report',
  [ReportType.BilledTimesheets]: 'Billed Timesheets Report',
  [ReportType.UnpaidInvoices]: 'Unpaid Invoices Report',
  [ReportType.SuspiciousFlaggingTimesheets]: 'Suspicious Flagging Timesheets Report',
});

export const REPORTS = [
  {
    value: ReportType.Payroll,
    label: ReportLabels[ReportType.Payroll],
    permission: AppPermissions.generate_payroll_report,
  },
  {
    value: ReportType.TecorienPayroll,
    label: ReportLabels[ReportType.TecorienPayroll],
    permission: AppPermissions.generate_tecorien_payroll_report,
  },
  {
    value: ReportType.Daily,
    label: ReportLabels[ReportType.Daily],
    permission: AppPermissions.generate_daily_report,
  },
  {
    value: ReportType.DailyOpen,
    label: ReportLabels[ReportType.DailyOpen],
    permission: AppPermissions.generate_daily_open_report,
  },
  {
    value: ReportType.Weekly,
    label: ReportLabels[ReportType.Weekly],
    permission: AppPermissions.generate_weekly_report,
  },
  {
    value: ReportType.WeeklyUnpaid,
    label: ReportLabels[ReportType.WeeklyUnpaid],
    permission: AppPermissions.generate_payroll_report,
  },
  {
    value: ReportType.Metrics,
    label: ReportLabels[ReportType.Metrics],
    permission: AppPermissions.generate_metrics_report,
  },
  {
    value: ReportType.Monthly,
    label: ReportLabels[ReportType.Monthly],
    permission: AppPermissions.generate_weekly_report,
  },
  {
    value: ReportType.WorkersTotalHours,
    label: ReportLabels[ReportType.WorkersTotalHours],
    permission: AppPermissions.generate_workers_total_hours_report,
  },
  {
    value: ReportType.CESSupervisorsTotalHours,
    label: ReportLabels[ReportType.CESSupervisorsTotalHours],
    permission: AppPermissions.generate_ces_supervisors_total_hours_report,
  },
  {
    value: ReportType.DepartmentInvoices,
    label: ReportLabels[ReportType.DepartmentInvoices],
    permission: AppPermissions.generate_ces_supervisors_total_hours_report,
  },
  {
    value: ReportType.PaidTimesheets,
    label: ReportLabels[ReportType.PaidTimesheets],
    permission: AppPermissions.generate_paid_timesheets_report,
  },
  {
    value: ReportType.BilledTimesheets,
    label: ReportLabels[ReportType.BilledTimesheets],
    permission: AppPermissions.generate_billed_timesheets_report,
  },
  {
    value: ReportType.UnpaidInvoices,
    label: ReportLabels[ReportType.UnpaidInvoices],
    permission: AppPermissions.generate_unpaid_invoices_report,
  },
  {
    value: ReportType.SuspiciousFlaggingTimesheets,
    label: ReportLabels[ReportType.SuspiciousFlaggingTimesheets],
    permission: AppPermissions.generate_suspicious_flagging_timesheets_report,
  },
];
