import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Job } from 'types/Job';

import { JOB_STATUSES } from 'Constants/job';
import { EROLES } from 'Constants/user';
import { actions } from 'Services';
import { ReduxState } from 'createStore';

import JobsMap from '../Dispatch/components/Map/JobsMapOld';
import FilterComponent from '../Searchs/Filter.Component';
import JobList from './JobList';
import styles from './Map.module.scss';

interface Props extends RouteComponentProps<{ id: string }>, ReduxProps {}

interface State {
  selectedId: number;
}

class Map extends React.Component<Props, State> {
  filterContainerRef = React.createRef<HTMLDivElement>();
  state = {
    selectedId: 0,
  };

  componentDidMount = () => {
    this.fetchNewData();
  };

  fetchNewData = async () => {
    if (this.props.match.params.id) {
      this.props
        .retrieveJob(this.props.match.params.id)
        .then((res) => {
          this.props.updateFilters({ search: res.id.toString() });
          this.setState({ selectedId: res.id });
        })
        .catch((err) => {
          this.setState({ selectedId: 0 });
        });
    } else {
      this.setState({ selectedId: 0 });
      this.props.refetchJobs();
    }
  };

  isSuperVisor = () => {
    const user = JSON.parse(localStorage.getItem('CurrentUser'));
    if (user && user.roles && user.roles.includes(EROLES.coned_field_supervisor)) {
      return true;
    }
    return false;
  };

  public render() {
    const hasSupervisor = this.isSuperVisor();
    const jobs = this.props.jobs.asMutable({ deep: true });
    const { selectedId } = this.state;
    const selectedJob: Job = selectedId ? jobs.find(({ id }) => id === selectedId) : null;
    return (
      <div className={styles['content']}>
        <div ref={this.filterContainerRef} className={styles['side-menu']}>
          <FilterComponent
            hasDepartment={true}
            hasRequestDate={true}
            hasCreateDate
            hasJobStatus={true}
            hasWorker={true}
            hasNumber={true}
            hasAddress={true}
            hasBorough={true}
            hasFieldSupervisor={hasSupervisor}
            hasFilter
            hasSort
            showSearch={true}
            numberMonth={1}
            onShowFilters={() => this.filterContainerRef.current.classList.add(styles['filter-shown'])}
            onHideFilters={() => this.filterContainerRef.current.classList.remove(styles['filter-shown'])}
          >
            <JobList
              jobs={jobs}
              isSelected={this.state.selectedId}
              selectJob={(job: Job) =>
                this.setState((prevState) => ({
                  ...prevState,
                  selectedId: prevState.selectedId && prevState.selectedId === job.id ? 0 : job.id,
                }))
              }
              selectedJob={selectedJob}
            />
          </FilterComponent>
        </div>
        <div className={styles['map-styles']}>
          <JobsMap
            workers={
              selectedJob &&
              ![JOB_STATUSES.Completed, JOB_STATUSES.Cancelled, JOB_STATUSES.CancelledBillable].includes(
                Number(selectedJob.jobStatus)
              )
                ? selectedJob?.workers?.filter(({ on_site }) => on_site) || []
                : []
            }
            locationJob={selectedJob}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    jobs: state.jobs.jobs.results,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateFilters: (search_options) => dispatch(actions.JobsActions.updateFilters(search_options)),
    retrieveJob: (id) => dispatch(actions.JobsActions.retrieveJob(id)),
    refetchJobs: () => dispatch(actions.JobsActions.retrieve()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Map);
