import { createContext, useContext } from 'react';

import { FormikProps } from 'formik';

import { MasterTimesheetFormikValues } from 'Containers/Timesheets/MasterTimesheet/MasterTimesheet';

const MasterTimesheetContext = createContext<FormikProps<MasterTimesheetFormikValues>>(null);

export const useMasterTimesheetContext = () => useContext(MasterTimesheetContext);

export default MasterTimesheetContext;
