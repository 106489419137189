import { DatasetTestIDs } from './tests';

export const TABLE_HEADER = [
  { label: 'ID', value: 'id', sortable: true, dataCy: DatasetTestIDs.pages.subcontractors.buttons.sort.id },
  { label: 'First Name', value: 'firstName', sortable: true },
  { label: 'Last Name', value: 'lastName', sortable: true },
  { label: 'Email', value: 'email', sortable: true },
  { label: 'Phone Number', value: 'phoneNumber', sortable: true },
  { label: 'Workers', value: 'workers', sortable: true },
  { label: 'Company Name', value: 'subcontractorName', sortable: true },
  { label: 'Online', value: 'isOnline', sortable: true },
  { label: '', value: '', sortable: false },
];

export const PER_PAGES = [
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

export const SubcontractorStatuses = {
  active: 'active',
  onHold: 'on_hold',
  deleted: 'deleted',
};
