import { FC } from 'react';

import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Tooltip, Icon, Typography, IconButton, CircularProgress } from '@mui/material';

import { ChatMessageStatus, SendChatMessageStatus } from 'Constants/job';
import { UserRoles } from 'Constants/user';
import { actions } from 'Services';
import { JobsAPI } from 'Services/API';
import { ChatMessage } from 'Services/API/JobsAPI.service';
import { FORMATS } from 'Utils/Date';
import { UserWorkerIcon, UserSolidBold } from 'Utils/Icon';
import { showErrorMessage } from 'Utils/errorMessage';
import useMessageConfig from 'Utils/hooks/useMessageConfig';
import useProcessing from 'Utils/hooks/useProcessing';
import AppPaperModal from 'components/AppPaperModal';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppDispatch, useAppSelector } from 'createStore';

type Props = {
  message: ChatMessage;
  isFirstUserMessageInChain: boolean;
  nextMessageFromSameUser: boolean;
  onSendMessageStatus?: () => void;
  jobID?: number | string;
  shiftID?: number;
};

const CONFIRM_ACTION_MODAL_ID = 'send-message-status-confirm-action-modal';

const ChatMessageBody: FC<Props> = ({
  message,
  isFirstUserMessageInChain = false,
  nextMessageFromSameUser = false,
  onSendMessageStatus = () => {},
  jobID = null,
  shiftID = null,
}) => {
  const dispatch = useAppDispatch();
  const userIsSubcontractor = useAppSelector((state) => state.app.userIsSubcontractor);
  const isRequiredCheckByDispatcher = message.status === ChatMessageStatus.required_check_by_dispatcher;
  const isMessageDeclinedByDispatcher = message.status === ChatMessageStatus.declined_by_dispatcher;
  const { inProcess, promiseWrapper } = useProcessing();
  const isEdited = message.updated_at && !moment(message.updated_at).isSame(message.created_at);
  const { openPopover, closePopover } = usePopoverContext();

  const userCanSeeRequiredToCheckAndDeclinedMessages = !UserRoles.has.ConEdRole && !userIsSubcontractor;

  const messageConfig = useMessageConfig({ message, nextMessageFromSameUser });

  const closeConfirmationModal = () => closePopover(CONFIRM_ACTION_MODAL_ID);

  const sendMessageStatus = async (status: SendChatMessageStatus) => {
    if (!jobID || !shiftID || !onSendMessageStatus) {
      return console.error('jobID / shiftID / retrieve messages function is not provided');
    }
    try {
      await new Promise<void>((res, rej) => {
        openPopover(
          CONFIRM_ACTION_MODAL_ID,
          <AppPaperModal
            title="Confirm action"
            modalId={CONFIRM_ACTION_MODAL_ID}
            open
            submitButton={{
              title: 'Confirm',
              onClick: () => res(),
            }}
            cancelButton={{
              title: 'Cancel',
              color: 'error',
              variant: 'translucent',
              onClick: () => rej(),
            }}
            onClose={() => rej()}
          >
            <Typography color="text.secondary" fontSize={13}>
              Are you sure you want to{' '}
              <Typography
                component="span"
                fontSize={13}
                fontFamily={'Poppins Medium'}
                color={status === SendChatMessageStatus.accepted ? 'primary' : 'error'}
              >
                {status === SendChatMessageStatus.accepted ? 'approve' : 'reject'} this message?
              </Typography>
            </Typography>
          </AppPaperModal>
        );
      });
      closeConfirmationModal();
      await JobsAPI.updateJobShiftChatMessageStatus({
        jobID,
        shiftID,
        messageID: message.id,
        status,
      });
      dispatch(actions.JobsActions.retrieveLocationJob({ job_id: Number(jobID) }));
      dispatch(actions.JobsActions.retrieveLocations());
      onSendMessageStatus();
    } catch (error) {
      closeConfirmationModal();
      if (!error) return;
      showErrorMessage(error);
    }
  };

  if (!userCanSeeRequiredToCheckAndDeclinedMessages && (isMessageDeclinedByDispatcher || isRequiredCheckByDispatcher)) {
    return;
  }

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={message.is_coned_supervisor ? 'flex-end' : 'flex-start'}
        gap={'10px'}
      >
        {isFirstUserMessageInChain && (
          <Box display="flex" color="text.secondary" alignItems="center" gap="6px">
            <Tooltip placement="top" title={message.is_coned_supervisor ? 'Coned Supervisor' : ''} disableInteractive>
              <Icon sx={{ width: 16, height: 16 }}>
                {message.is_coned_supervisor ? <UserWorkerIcon /> : <UserSolidBold />}
              </Icon>
            </Tooltip>

            <Typography color="text.primary" fontFamily={'Poppins Medium'} fontSize={15}>
              {message.user_name}
            </Typography>
          </Box>
        )}

        <Box
          display={'flex'}
          bgcolor={messageConfig.backgroundColor}
          px={'16px'}
          pt={'12px'}
          pb={'20px'}
          position={'relative'}
          maxWidth={'min(90%, 550px)'}
          minWidth={'180px'}
          sx={{
            ...messageConfig.borderRadiuses,
            opacity: isMessageDeclinedByDispatcher ? 0.5 : 1,
          }}
        >
          <Typography
            sx={{ overflowWrap: 'anywhere' }}
            fontSize={14}
            lineHeight={'22px'}
            color={messageConfig.textColor}
          >
            {message.message}
          </Typography>
          <Typography
            position="absolute"
            bottom={6}
            right={10}
            lineHeight={1}
            fontSize={11}
            whiteSpace={'nowrap'}
            color={isMessageDeclinedByDispatcher ? 'error' : messageConfig.dateTextColor}
            fontFamily={message.is_coned_supervisor && 'Poppins Medium'}
          >
            {isMessageDeclinedByDispatcher && 'Declined by dispatcher '}
            {!isMessageDeclinedByDispatcher && message.updated_at && isEdited ? 'edited ' : ''}
            {!isMessageDeclinedByDispatcher && moment(message.created_at).format(FORMATS.chatMessageCreateAt)}
          </Typography>
        </Box>
      </Box>

      <Box
        maxWidth={'min(90%, 550px)'}
        border={'1px solid #E8EDF1'}
        sx={{
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px',
        }}
        padding="10px 20px"
        hidden={!isRequiredCheckByDispatcher}
        boxShadow={'0px 4px 3.2px 0px #F0F0F0'}
      >
        <Box display="flex" gap={3} alignItems="start" justifyContent="space-between">
          <Box display="flex" gap={1}>
            <ErrorIcon sx={{ color: 'error.dark', mt: '4px' }} />
            <Typography fontStyle="italic" fontSize={12}>
              {message.explanation}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            <IconButton
              sx={{
                width: 24,
                height: 24,
                svg: { width: 14, height: 14 },
                bgcolor: '#126305',
                color: 'white',
                '&:hover': { bgcolor: 'success.dark' },
                '&:disabled': { bgcolor: 'success.dark', color: 'white', opacity: 0.5 },
              }}
              disabled={inProcess}
              onClick={() => promiseWrapper(sendMessageStatus(SendChatMessageStatus.accepted))}
            >
              {inProcess ? <CircularProgress size={14} sx={{ color: 'white' }} /> : <DoneIcon />}
            </IconButton>
            <IconButton
              sx={{
                width: 24,
                height: 24,
                svg: { width: 14, height: 14 },
                bgcolor: '#C52525',
                color: 'white',
                '&:hover': { bgcolor: 'error.dark' },
                '&:disabled': { bgcolor: 'error.dark', color: 'white', opacity: 0.5 },
              }}
              disabled={inProcess}
              onClick={() => promiseWrapper(sendMessageStatus(SendChatMessageStatus.declined))}
            >
              {inProcess ? <CircularProgress size={14} sx={{ color: 'white' }} /> : <CloseIcon />}
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatMessageBody;
