import Immutable from 'seamless-immutable';

import { Region } from 'types/Dispatch';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = Immutable({
  zones: [] as Region[],
  stats: {} as ZoneStats,
  get_zones_processing: false,
});

export default function RoutesReducer(state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.GET_ZONES_REQUEST:
      return state.merge({
        get_zones_processing: true,
      });
    case actionTypes.GET_ZONES_SUCCESS:
      return state.merge({
        get_zones_processing: false,
        zones: action.zones,
        stats: action.stats,
      });
    case actionTypes.GET_ZONES_ERROR:
      return state.merge({
        get_zones_processing: false,
      });
    case LOGOUT:
      return state.merge(initialState);
    default:
      return state;
  }
}

type ZoneStats = {
  today_jobs_count: string;
  today_late_jobs_count: string;
  today_unconfirmed_asap_shifts: string;
  tomorrow_jobs_count: string;
  tomorrow_unconfirmed_workers_count: string;
  total_unconfirmed_jobs: string;
};
