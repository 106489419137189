export const numberRounder = (
  values: number[] = [],
  decimalNumber = 0,
  callback: (sum: number, value: number) => number = () => 0
) => {
  const multiplier = Math.pow(10, decimalNumber);
  return values.reduce((sum, val) => callback(sum, Math.round(val * multiplier)), 0) / multiplier;
};

export const sumRounder = (values: number[] = [], decimalNumber = 0) =>
  numberRounder(values, decimalNumber, (sum, val) => sum + val);
