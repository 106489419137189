import React, {
  useState,
  useLayoutEffect,
  createRef,
  useEffect,
  useRef,
  forwardRef,
  memo,
  useImperativeHandle,
  useCallback,
} from 'react';

const isRotated = (rotation: number) => rotation && rotation % 180;

const calculateRotatedImageSize = (width: number, height: number, rotation: number) => {
  const canvasWidth =
    Math.abs(Math.cos((rotation * Math.PI) / 180) * width) + Math.abs(Math.sin((rotation * Math.PI) / 180) * height);
  const canvasHeight =
    Math.abs(Math.sin((rotation * Math.PI) / 180) * width) + Math.abs(Math.cos((rotation * Math.PI) / 180) * height);

  return { canvasWidth, canvasHeight };
};

const calculateCanvas = ({ width = 0, height = 0, rotation = 0, clientWidth = 0, clientHeight = 0 }) => {
  const { canvasWidth, canvasHeight } = calculateRotatedImageSize(width, height, rotation);

  const rotatedScaleFactor = isRotated(rotation) ? Math.max(width / height, height / width) : 1;

  const calculatedWidth = canvasWidth * rotatedScaleFactor;
  const calculatedHeight = canvasHeight * rotatedScaleFactor;

  const scaleFactor = Math.min(clientWidth / calculatedWidth, clientHeight / calculatedHeight);

  const renderedWidth = calculatedWidth * scaleFactor;
  const renderedHeight = calculatedHeight * scaleFactor;

  return { width: renderedWidth, height: renderedHeight };
};

type Props = {
  imageUrl: string;
  rotation?: number;
  containerRef: React.RefObject<HTMLDivElement>;
  onDraw?: (canvas?: HTMLCanvasElement) => void;
};

const RotatableImage = forwardRef(
  (
    { imageUrl, rotation = 0, containerRef = createRef(), onDraw = () => {} }: Props,
    ref: React.MutableRefObject<HTMLCanvasElement>
  ) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [image, setImage] = useState<HTMLImageElement>(null);

    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);

    useImperativeHandle(ref, () => canvasRef.current);

    const resizeCanvas = useCallback(() => {
      if (image?.width && image?.height && containerRef.current?.clientWidth && containerRef.current?.clientHeight) {
        const { width, height } = calculateCanvas({
          width: image.width,
          height: image.height,
          rotation,
          clientWidth: containerRef.current?.clientWidth,
          clientHeight: containerRef.current?.clientHeight,
        });
        setCanvasWidth(width);
        setCanvasHeight(height);
        return;
      }
    }, [image?.width, image?.height, rotation, containerRef.current]);

    useLayoutEffect(() => {
      resizeCanvas();
    }, [resizeCanvas]);

    useEffect(() => {
      const handleResize = () => {
        resizeCanvas();
        onDraw();
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, [onDraw, resizeCanvas]);

    useEffect(() => {
      const newImage = new Image();
      setImage(null);
      newImage.onload = () => {
        setImage(newImage);
        onDraw();
      };
      newImage.src = imageUrl;
    }, [imageUrl]);

    useLayoutEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas || !image) return;
      const ctx = canvas.getContext('2d');

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      ctx.translate(canvasWidth / 2, canvasHeight / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      const dx = -canvasWidth / 2;
      const dy = -canvasHeight / 2;

      if (isRotated(rotation)) {
        ctx.drawImage(image, dy, dx, canvasHeight, canvasWidth);
      } else {
        ctx.drawImage(image, dx, dy, canvasWidth, canvasHeight);
      }
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      onDraw();
    }, [canvasWidth, canvasHeight, rotation, onDraw, image]);

    return <canvas ref={canvasRef} width="100%" height="100%" style={{ objectFit: 'contain' }} />;
  }
);

export default memo(RotatableImage);
