import { ReactComponent as ShieldSecurity } from 'Assets/icons/shield-security.svg';
import { ReactComponent as ShieldTick } from 'Assets/icons/shield-tick.svg';
import image1 from 'Assets/login/loginimage1.jpg';
import image2 from 'Assets/login/loginimage2.jpg';

export const enum AuthFormVariant {
  LOGIN = 'LOGIN',
  TWO_FACTOR_LOGIN = 'TWO_FACTOR_LOGIN',
  RECOVERY = 'RECOVERY',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
}

export const pageImages = [image1, image2];

export const randomPageImage = pageImages[Math.floor(Math.random() * pageImages.length)];

export class AuthFormContentHelper {
  #variant: AuthFormVariant = AuthFormVariant.LOGIN;

  constructor(variant: AuthFormVariant) {
    this.#variant = variant;
  }

  get #title(): string {
    switch (this.#variant) {
      case AuthFormVariant.LOGIN:
        return 'Welcome back';
      case AuthFormVariant.RECOVERY:
        return 'Password Recovery';
      case AuthFormVariant.RESET_PASSWORD:
        return 'Reset Password';
      case AuthFormVariant.ACTIVATE_ACCOUNT:
        return 'Account Activation';
      case AuthFormVariant.TWO_FACTOR_LOGIN:
        return 'Two Factor Authentication';
      default:
        return '';
    }
  }

  get #subtitle(): string {
    switch (this.#variant) {
      case AuthFormVariant.LOGIN:
        return 'Please sign-in to your account';
      case AuthFormVariant.RECOVERY:
        return "Enter the email you're using for your account";
      case AuthFormVariant.RESET_PASSWORD:
        return 'Create a new password for your account';
      case AuthFormVariant.ACTIVATE_ACCOUNT:
        return 'Your account has been activated, please set you PW below';
      case AuthFormVariant.TWO_FACTOR_LOGIN:
        return 'Enter the code that was sent to your phone number';
      default:
        return '';
    }
  }

  get #submitButton(): string {
    switch (this.#variant) {
      case AuthFormVariant.LOGIN:
      case AuthFormVariant.TWO_FACTOR_LOGIN:
        return 'Login';
      case AuthFormVariant.RECOVERY:
        return 'Continue';
      case AuthFormVariant.RESET_PASSWORD:
        return 'Reset Password';
      case AuthFormVariant.ACTIVATE_ACCOUNT:
        return 'Activate';
      default:
        return '';
    }
  }

  get texts(): { title: string; subtitle: string; submitButton: string } {
    return {
      title: this.#title,
      subtitle: this.#subtitle,
      submitButton: this.#submitButton,
    };
  }

  get successPageData(): {
    icon?: JSX.Element;
    title: string;
    subtitle: string;
    action: string;
  } {
    switch (this.#variant) {
      case AuthFormVariant.ACTIVATE_ACCOUNT:
        return {
          icon: <ShieldTick />,
          title: 'Successful Activation',
          subtitle: 'The account was activated successfully',
          action: 'Login',
        };
      case AuthFormVariant.RECOVERY:
        return {
          icon: <ShieldSecurity />,
          title: 'Thank you',
          subtitle:
            "We've sent password reset instructions to your email address. If no email is received within ten minutes, check that the submitted address is correct.",
          action: 'Back to login',
        };
      default:
        break;
    }
  }
}
