import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import CancelJobImg from 'Assets/icons/cancel-icon.svg';
import AppPaperModal from 'components/AppPaperModal';

type Props = {
  open: boolean;
  loading: boolean;
  jobId: string;
  onClose: () => void;
  onConfirm: (jobId: string) => void;
};

const UnmarkBilledConfirmationModal: FC<Props> = ({ open, jobId, onClose, onConfirm, loading = false }) => {
  return (
    <AppPaperModal
      open={open}
      onClose={onClose}
      title="Unmark Billed/Paid"
      submitButton={{
        title: 'Unmark',
        onClick: () => onConfirm(jobId),
        loading,
      }}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems="center" gap={5}>
        <Typography>Are you sure you want to unmark billed and paid?</Typography>
        <img className={'complete-image'} src={CancelJobImg} alt="" />
      </Box>
    </AppPaperModal>
  );
};

export default UnmarkBilledConfirmationModal;
