import { FC, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import { InfoIcon, LocationIcon, GroupUsers, AddPhotoIcon, ChatIcon, DownloadIcon } from 'Utils/Icon';
import useProcessing from 'Utils/hooks/useProcessing';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import { useAppSelector } from 'createStore';

import { StyledMasterTimesheetsTabs } from '../../styled';

export enum MasterTimesheetTab {
  information = 'information',
  locations = 'locations',
  workers = 'workers',
  attachments = 'attachments',
  comments = 'comments',
}

const Tabs = [
  {
    value: MasterTimesheetTab.information,
    label: 'Information',
    Icon: InfoIcon,
  },
  {
    value: MasterTimesheetTab.locations,
    label: 'Locations',
    Icon: LocationIcon,
  },
  {
    value: MasterTimesheetTab.workers,
    label: 'Workers',
    Icon: GroupUsers,
  },
  {
    value: MasterTimesheetTab.attachments,
    label: 'Attachments',
    Icon: AddPhotoIcon,
  },
  {
    value: MasterTimesheetTab.comments,
    label: 'Comments',
    Icon: ChatIcon,
  },
];

type Props = {
  showDownloadButton: true;
  onDownloadAllTimesheets: () => Promise<void>;
};

const MasterTimesheetTabs: FC<Props | {}> = (props) => {
  const masterTimesheet = useAppSelector((state) => state.timesheets.masterTimesheet)?.asMutable({ deep: true });
  const { inProcess: loadingTimesheetsPDFs, promiseWrapper } = useProcessing();

  const { params, setSearchParam } = useSearchParams<{
    tab: MasterTimesheetTab;
  }>();

  useEffect(() => {
    if (!params.tab || !Object.values(MasterTimesheetTab).includes(params.tab)) {
      setSearchParam('tab', MasterTimesheetTab.information);
    }
  }, [params.tab]);

  return (
    <Box
      display="flex"
      justifyContent={'space-between'}
      bgcolor="white"
      borderRadius="10px"
      px="20px"
      position="sticky"
      top={0}
      zIndex={3}
    >
      <StyledMasterTimesheetsTabs
        value={params.tab || MasterTimesheetTab.information}
        onChange={(e, value) => {
          setSearchParam('tab', value);
        }}
        variant="scrollable"
        scrollButtons={false}
      >
        {Tabs.map(({ value, Icon, label }) => (
          <Tab
            disabled={!masterTimesheet}
            disableRipple
            key={'master-timesheet-tab-' + value}
            label={label}
            value={value}
            icon={<Icon />}
            iconPosition="start"
          />
        ))}
      </StyledMasterTimesheetsTabs>
      {'showDownloadButton' in props && props.showDownloadButton && (
        <Box p={1}>
          <LoadingButton
            color="primary"
            startIcon={<DownloadIcon />}
            size="small"
            sx={{ bgcolor: 'primary.light', whiteSpace: 'nowrap', padding: '5px 10px' }}
            loading={loadingTimesheetsPDFs}
            loadingPosition="start"
            onClick={() => promiseWrapper(props.onDownloadAllTimesheets())}
          >
            Download All Timesheets
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default MasterTimesheetTabs;
