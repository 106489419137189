import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { FormControl, Autocomplete, TextField } from '@mui/material';

import { AppAPI } from 'Services/API';
import { APP_API } from 'Services/API/AppAPI.service';
import useProcessing from 'Utils/hooks/useProcessing';

type Props = {
  address?: string;
  onSelectLocation: (data: {
    lat: number;
    lng: number;
    address: string;
    structure?: string;
    facility_id: string;
  }) => void;
};

const FacilityAutocomplete = ({ address, onSelectLocation = () => {} }: Props) => {
  const [locations, setLocations] = useState<APP_API.FacilityLocationItem[]>([]);
  const [inputValue, setInputValue] = useState(address || '');
  const { inProcess: loading, promiseWrapper } = useProcessing();

  const getLocationByFacilityId = useCallback(
    async (id = '') => {
      try {
        const { location } = await promiseWrapper(AppAPI.getLocationByFacilityId(id));
        setLocations([location]);
      } catch (error) {
        setLocations([]);
      }
    },

    [onSelectLocation, promiseWrapper, inputValue]
  );

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSelectLocation = (event, value: string | APP_API.FacilityLocationItem) => {
    if (value && typeof value !== 'string') {
      onSelectLocation({
        lat: Number(value.lat),
        lng: Number(value.lng),
        address: value.address,
        structure: value.structure,
        facility_id: inputValue,
      });
      setInputValue(value.address);
      return;
    }
    if (!value) {
      onSelectLocation({
        lat: 0,
        lng: 0,
        address: '',
        facility_id: '',
      });
      setInputValue('');
    }
  };

  const getOptionLabel = (option: string | APP_API.FacilityLocationItem) =>
    (typeof option !== 'string' ? option?.address : option) || '';

  useEffect(() => {
    if (!inputValue) {
      setLocations([]);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      getLocationByFacilityId(inputValue);
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  return (
    <FormControl style={{ width: '100%' }}>
      <Autocomplete
        options={locations}
        onChange={handleSelectLocation}
        filterOptions={(x) => x}
        inputValue={inputValue || ''}
        onInputChange={handleChangeInput}
        isOptionEqualToValue={() => true}
        getOptionLabel={getOptionLabel}
        loading={inputValue && (!locations.length || loading)}
        loadingText={loading ? 'Searching...' : `No locations found for this facility ID: ${inputValue}`}
        includeInputInList
        freeSolo
        sx={{
          '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            position: 'static',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              name: 'facility_id',
              autoComplete: 'none',
            }}
            InputLabelProps={{ shrink: true }}
            placeholder="Enter Facility ID"
            size="small"
          />
        )}
      />
    </FormControl>
  );
};

export default FacilityAutocomplete;
