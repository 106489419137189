import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { ArrowDropDown } from '@mui/icons-material';
import { Popover, Tooltip, Button as MUIButton, styled, tooltipClasses, TooltipProps } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { baseToastConfig } from 'Constants/app';
import { actions } from 'Services';
import Button from 'components/Button/Button';
import { useAppSelector } from 'createStore';

const DownloadButton = styled(MUIButton)({
  textTransform: 'none',
  width: '100%',
  maxWidth: 170,
});

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  fontSize: 18,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

type Props = {
  config_id: string | number;
};

const DownloadOutstanding = ({ config_id }: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const po = useAppSelector((state) => state.invoices.invoices_search_options.po);
  const conf = useAppSelector((state) => state.invoices.invoices_search_options.conf);
  const disabled = useAppSelector((state) => state.invoices.processing);
  const hasTimesheets = useAppSelector((state) => !!state.invoices.configuration_invoices.length);
  const [loading, setLoading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const buttonRef = useRef<HTMLDivElement>(null);

  const hasTimesheetsWithPO = po && hasTimesheets && !disabled;

  const hasTimesheetsWithPOandConf = hasTimesheetsWithPO && conf;

  const closePopover = () => setPopoverOpen(false);

  const handleClick = () => {
    if (hasTimesheetsWithPO) {
      setPopoverOpen(true);
      return;
    }
    downloadNonPaidTimesheets();
  };

  const download =
    (downloadCallback = async () => null) =>
    async () => {
      setLoading(true);
      closePopover();
      try {
        await downloadCallback();
      } catch (error) {
        toast.error(error.error || 'Somethings went wrong', baseToastConfig);
      } finally {
        setLoading(false);
      }
    };

  const downloadNonPaidTimesheets = () => dispatch(actions.InvoicesActions.downloadNonPaidTimesheets(config_id));
  const downloadNonPaidByPO = () => dispatch(actions.InvoicesActions.downloadNonPaidTimesheets(config_id, { po }));
  const downloadNonPaidByPOandConf = () =>
    dispatch(actions.InvoicesActions.downloadNonPaidTimesheets(config_id, { po, conf }));
  return (
    <>
      <Tooltip
        disableInteractive
        title={hasTimesheetsWithPO ? '' : 'Download non paid timesheets to your computer'}
        arrow
        aria-label="download"
      >
        <div ref={buttonRef}>
          <Button
            color={'dark'}
            borderRadius={'20px'}
            textTransform={false}
            onClick={handleClick}
            processing={loading}
            disabled={disabled}
            style={{ backgroundColor: 'grey', color: '#FFF' }}
          >
            Download {hasTimesheetsWithPO ? <ArrowDropDown /> : 'Outstanding'}
          </Button>
        </div>
      </Tooltip>
      <Popover
        anchorEl={buttonRef.current}
        open={popoverOpen}
        onClose={closePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          },
        }}
      >
        <LightTooltip title="Download non paid timesheets to your computer" followCursor>
          <DownloadButton onClick={download(downloadNonPaidTimesheets)}>All Outstanding</DownloadButton>
        </LightTooltip>
        <LightTooltip title={`Download non paid timesheets with PO #${po} to your computer`} followCursor>
          <DownloadButton onClick={downloadNonPaidByPO}>Outstanding with PO#</DownloadButton>
        </LightTooltip>
        {hasTimesheetsWithPOandConf ? (
          <LightTooltip
            title={`Download non paid timesheets with PO #${po} and Conf #${conf} to your computer`}
            followCursor
          >
            <DownloadButton onClick={downloadNonPaidByPOandConf}>Outstanding with PO# and Conf#</DownloadButton>
          </LightTooltip>
        ) : null}
      </Popover>
    </>
  );
};

export default DownloadOutstanding;
