import { Switch, alpha, styled } from '@mui/material';

const AppSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 18,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 12,
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 99999,
    opacity: 1,
    backgroundColor: '#919EB180',
    boxSizing: 'border-box',
  },
}));

export default AppSwitch;
