import React from 'react';

import clsx from 'clsx';

import { Box } from '@mui/material';

import styles from './TimesheetBox.module.scss';

const TimesheetBox = ({ children, classNames = '' }) => {
  return <Box className={clsx(styles.timesheetBox, classNames)}>{children}</Box>;
};

export default TimesheetBox;
