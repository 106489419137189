import { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, InputAdornment, TextField, TextFieldProps, Typography, styled } from '@mui/material';

type AuthStyledInputProps = Omit<TextFieldProps, 'variant' | 'InputLabelProps'>;

export const AuthActionButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: '14px',
  padding: '12px 0',
  borderRadius: '8px',
  fontFamily: 'Poppins Bold, system-ui, sans-serif',
  boxShadow: '0px 4px 3.2px 0px #00000015',
  backgroundColor: '#2F83FF',
  lineHeight: '24px',

  '&:hover': {
    backgroundColor: '#2F83FF',
  },
}));

export const AuthInput = styled((props: AuthStyledInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      {...props}
      inputProps={{
        ...props.inputProps,
        ...(props.type === 'password' && { type: showPassword ? 'text' : 'password' }),
      }}
      InputProps={{
        ...props.InputProps,
        ...(props.type === 'password' && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((prev) => !prev)} sx={{ zIndex: 1 }}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }),
      }}
    />
  );
})(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: '4px',
    fontFamily: 'Poppins Regular, system-ui, sans-serif',
    boxShadow: '0px 4px 3.2px 0px #00000010',

    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#00000030',
      },
    },
  },

  '& .MuiOutlinedInput-input': {
    padding: '16px 15px',
    opacity: 1,

    '&::placeholder': {
      color: '#A5BACB',
      opacity: 1,
      fontFamily: 'Poppins Regular, system-ui, sans-serif',
    },
  },

  '& .MuiOutlinedInput-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },

  '& .MuiOutlinedInput-root.Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E8EDF1',
    '& > legend': {
      maxWidth: 0,
    },
  },

  '& .MuiInputLabel-outlined': {
    position: 'static',
    transform: 'none',
    marginBottom: 8,
    fontSize: '14px',
    fontFamily: 'Poppins Regular, system-ui, sans-serif',
  },

  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(-25%, -50%)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    backgroundColor: 'transparent',
  },
}));

export const AuthTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Rubik',
  fontSize: '30px',
  fontWeight: 600,
  lineHeight: 'normal',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

export const AuthSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins Medium, system-ui, sans-serif',
  color: '#7E91A3',
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const AuthLinkButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Poppins Bold, system-ui, sans-serif',
  color: '#2F83FF',
}));

export const ChannelRadioBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  borderRadius: '8px',
  width: '100%',
  padding: '16px 20px 16px 16px',
  color: selected ? '#2F83FF' : '#9CA3A9',
  borderColor: selected ? '#2F83FF' : '#D6E2E9',
  borderStyle: 'solid',
  transition: 'all 0.2s ease-in-out',
  borderWidth: 1,

  '[data-testid="CheckCircleIcon"]': {
    display: selected ? 'block' : 'none',
  },

  '&:hover': {
    borderColor: selected ? '#2F83FF' : '#2F83FF90',
    color: selected ? '#2F83FF' : '#2F83FF90',
  },

  'svg [stroke]': {
    stroke: 'currentColor',
  },

  'svg [fill]': {
    fill: 'currentColor',
  },
}));

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({ selected }) => ({
  width: 'max-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '50%',
  backgroundColor: selected ? '#E3EEFF' : '#E1EAF1',
  color: 'inherit',
}));
