import { toast } from 'react-toastify';

import { baseToastConfig } from 'Constants/app';

type errorType = {
  error?: string;
  message?: string;
};

const normalizeMessage = (message: string): string => {
  return (message.at(0).toUpperCase() + message.slice(1)).replace(/_/g, ' ');
};

export const parseErrorMessage = (error: errorType | string): string => {
  const reserveMessage = 'Something went wrong. Please try again later.';
  if (typeof error === 'string') {
    return normalizeMessage(error || reserveMessage);
  }
  const errorMessage = error?.error || error?.message || reserveMessage;
  return normalizeMessage(errorMessage);
};

export const showErrorMessage = (error) => toast.error(parseErrorMessage(error), baseToastConfig);
