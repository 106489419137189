import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { Button, Tab, Tabs } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { ActionTypes } from 'Services';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import { useAppSelector } from 'createStore';

type Props = {} & PropsWithChildren;

const TimesheetsHeaderWrap = ({ children }: Props) => {
  const { params, setSearchParam, toBase } = useSearchParams<{ grouped: '1'; confirmation: string }>({
    baseUrl: '/timesheets',
  });

  const dispatch = useDispatch<AppThunkDispatch>();
  const showMobileFilters = useAppSelector((state) => state.timesheets).showMobileFilters;

  const { isDesktop } = useAppMediaQuery();

  const setMobileFilters = (show: boolean) =>
    dispatch({ type: ActionTypes.TimesheetsActionTypes.SET_MOBILE_FILTERS, payload: show });

  return (
    <div className="timesheets-page-header">
      <Tabs value={params.grouped === '1' ? 1 : 0}>
        <Tab value={0} label="Timesheets" onClick={toBase} />
        <Tab value={1} label="Grouped Timesheets" onClick={() => setSearchParam('grouped', '1')} />
      </Tabs>

      {!isDesktop && (
        <Button
          size="small"
          sx={{
            position: 'fixed',
            top: 10,
            right: 10,
            zIndex: 3,
          }}
          onClick={() => setMobileFilters(!showMobileFilters)}
          color="info"
          endIcon={showMobileFilters ? <FilterAltOffIcon /> : <FilterAltIcon />}
          variant={showMobileFilters ? 'contained' : 'outlined'}
        >
          {`${showMobileFilters ? 'Hide' : 'Show'} Filters`}
        </Button>
      )}
      {(isDesktop || showMobileFilters) && <div className="timesheets-header-filters">{children}</div>}
    </div>
  );
};

export default TimesheetsHeaderWrap;
