/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { FormikProps, withFormik } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';

import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { baseToastConfig } from 'Constants/app';
import { inputDateFormat } from 'Constants/time';
import { EROLES } from 'Constants/user';
import { actions, ActionTypes } from 'Services';
import { JobsAPI } from 'Services/API';
import { UPDATE_JOB_ERROR, UPDATE_JOB_REQUEST } from 'Services/jobs/actionTypes';
import FilesUpload from 'Utils/FilesUpload';
import UserPermissions from 'Utils/PermissionsHelper';
import confirmAlert from 'Utils/confirmAlert';
import AppDialog from 'components/AppDialog/AppDialog';
import { WithDialogsProps, withDialogs } from 'context/Dialogs';
import { ReduxState } from 'createStore';

import './JobCreate.scss';
import { CheckEndTimeValidation, JobEditValidation } from './JobCreateValidation';
import JobFormComponent, { JobCreateEdit } from './JobFormComponent';
import { JobsLeftBlock } from './components/JobsLeftBlock/JobsLeftBlock';

// eslint-disable-next-line
type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps & RouteComponentProps<{ id: string }>;

type State = { job: JobCreateEdit; initialJob: JobCreateEdit; localLoading: boolean };

class JobEdit extends React.Component<Props & FormikProps<JobCreateEdit> & WithDialogsProps, State> {
  state = {
    job: null,
    initialJob: null,
    localLoading: false,
  };
  times: any = {};
  myRef = React.createRef<HTMLDivElement>(); // Create a ref object

  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (id) {
      JobsAPI.getJob({ jobId: id }).then((job) => {
        const department = this.props.departments.find((dept) => dept.id === job.department) || {};
        const newJob: JobCreateEdit = {
          ...job,
          department: {
            ...department,
            id: job.department,
            name: job.departmentName,
          },
          requestor: {
            ...job.requestorObj,
          },
          supervisor: {
            ...job.supervisorObj,
          },
          requestTime: moment(job.requestTime).format(inputDateFormat),
          endTime: job.endTime ? moment(job.endTime).format(inputDateFormat) : job.endTime,
          locations: job.locations.map((location) => {
            let workersCount = 0;
            job.workers.forEach((worker) => {
              if (worker.locationID === location.id) {
                workersCount++;
              }
            });
            return {
              ...location,
              workersCount,
            };
          }),
          ccUser: job.ccUser.map((cc_user) => ({
            label: cc_user.name,
            value: cc_user,
          })),
          preferredWorkerIds: job.preferredWorkers.map(({ id }) => id),
          is_standby: job.is_standby,
        };
        delete newJob.preferredWorkers;
        // This condition must be the same as in userIsSubcontractor method into JobInfo component
        if (this.props.userRoles.every((role) => role === EROLES.subcontractor)) {
          toast.error('You cannot edit job info', { ...baseToastConfig, autoClose: false });
          this.props.history.replace({ pathname: `/job/${newJob.id}` });
          return;
        }
        if (newJob.exist_approved_timesheets && !UserPermissions.has.edit_job_with_approved_timesheets) {
          toast.info(
            'You can edit only job comment, PO #, Requisition # and Receipt #. You can add photos via Add Photos button on Job Details page. You can edit Department on Job Details page.',
            {
              autoClose: false,
              position: 'top-right',
            }
          );
        }
        this.props.dispatch({ type: ActionTypes.JobsActionTypes.RETRIEVE_JOB_SUCCESS, job });
        this.setState({
          job: newJob,
          initialJob: newJob,
        });
        this.props.setValues(newJob);
      });
    }
  };

  componentWillUnmount() {
    this.props.dispatch({ type: ActionTypes.JobsActionTypes.CLEAR_JOB });
  }

  save = async (event) => {
    event.preventDefault();
    event.persist();

    if (!moment(this.props.values.requestTime).isValid()) return;

    if (this.myRef.current) {
      this.myRef.current.scrollTop = 250;
    }

    try {
      CheckEndTimeValidation.validateSync(this.props.values);
    } catch (error) {
      try {
        await new Promise((res, rej) => {
          this.props.openFirstDialog(
            <AppDialog
              title="Finish Date is not set"
              containerStyle={{ maxWidth: 400 }}
              onClose={() => {
                this.props.closeFirstDialog();
                rej();
              }}
              submitButton={{
                title: 'Save Anyway',
                onClick: () => {
                  this.props.closeFirstDialog();
                  res('ok');
                },
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                It looks like you haven't set Finish Date. If You can't set Finish Date then provide information about
                this job to admin.
              </Typography>
              <Typography variant="subtitle1" style={{ fontWeight: 700, fontSize: 18 }}>
                Are you sure you want to continue saving?
              </Typography>
            </AppDialog>
          );
        });
        this.setState({ localLoading: false });
      } catch (error) {
        this.setState({ localLoading: false });
        return;
      }
    }
    if (isEqual(this.props.values, this.state.initialJob)) {
      this.props.history.push(`/job/${this.props.job.id}`);
      return;
    }

    if (this.props.job.exist_approved_timesheets) {
      confirmAlert({
        title: 'Job already has approved timesheet',
        message: 'A linked Timesheet for this Job has already been verified. Are you sure you want to edit this job?',
        buttons: [
          {
            label: 'No',
            onClick: (hideAlert) => {
              hideAlert();
            },
            btnType: 'success',
          },
          {
            label: 'Yes',
            onClick: (hideAlert) => {
              hideAlert();
              this.props.handleSubmit(event);
            },
            btnType: 'error',
          },
        ],
      });
      return;
    }

    this.props.handleSubmit(event);
  };

  handleChange = (idx, name, value) => {
    return new Promise((resolve) => {
      this.setState(
        {
          job: {
            ...this.state.job,
            [name]: value,
          },
        },
        () => resolve(true)
      );
      this.props.setFieldValue(name, value, false);
      if (this.props.isValidating === true) {
        this.props.validateField(name);
      }
    });
  };

  onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  public render() {
    if (!this.state.job) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress color="primary" size={60} />
        </div>
      );
    }
    const { errors, handleBlur, isValid } = this.props;
    const now = new Date();
    const requestTime = new Date(this.state.job.requestTime);
    const canEdit = now.getTime() < requestTime.getTime();
    return (
      <div
        ref={this.myRef}
        className="JobCreate pt-4"
        style={{
          overflowY: 'scroll',
          position: 'relative',
        }}
      >
        <JobsLeftBlock
          jobs={[this.state.job]}
          onSave={this.save}
          handleBlur={handleBlur}
          isValid={isValid}
          processing={this.props.processing || this.state.localLoading}
          isEdit
          errors={{ jobs: [errors] }}
          showErrors={Boolean(errors)}
          selectedIndex={0}
          changeJob={() => {}}
        />
        <form className="template-wrapper" onKeyDown={this.onKeyDown}>
          <JobFormComponent
            onJobFormChange={this.handleChange}
            job={this.state.job}
            index={0}
            errors={{ jobs: [errors] }}
            handleBlur={handleBlur}
            touchedSubmit={true}
            validateForm={this.props.validateForm}
            can_edit_location={canEdit && UserPermissions.has.edit_job_info}
            isEdit={true}
          ></JobFormComponent>
          <br />
          <br />
        </form>
      </div>
    );
  }
}

const formatter = (item: any) => {
  if (item.requestor) {
    item.requestor = item.requestor.id;
  }
  if (item.supervisor) {
    item.supervisor = item.supervisor.id;
  }
  if (item.department) {
    item.department = item.department.id;
  }
  return item;
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateJob: (id, data) => dispatch(actions.JobsActions.updateJob(id, data)),
    uploadImages: (images) => dispatch(actions.JobsActions.uploadImages(images)),
  };
}

function mapStateToProps(state: ReduxState) {
  return {
    processing: state.jobs.update_job_processing,
    job: state.jobs.job,
    userRoles: state.app.user?.roles || [],
    departments: state.app.departments,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(
  withFormik<Props, JobCreateEdit>({
    validationSchema: JobEditValidation,
    handleSubmit: (params, { props }) => {
      props.dispatch({ type: UPDATE_JOB_REQUEST });
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const values: any = { ...params };
          for (let x = 0; x < values.locations.length; x++) {
            const location = values.locations[x];
            if (location.images && location.images.length > 0) {
              const imageFiles = new FilesUpload(values.locations[x].images, 'image_');

              if (!imageFiles.isAllFilesUploaded) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await imageFiles.uploadNew(JobsAPI.uploadImages);
              }
              values.locations[x].files = imageFiles.allUploaded;
            } else {
              values.locations[x].files = [];
            }
          }

          if (values.jobPdfs && values.jobPdfs.length > 0) {
            const pdfFiles = new FilesUpload(values.jobPdfs, 'pdfs_');
            if (!pdfFiles.isAllFilesUploaded) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              await pdfFiles.uploadNew(JobsAPI.uploadImages);
            }
            values.jobPdfs = pdfFiles.allUploaded;
          } else {
            values.jobPdfs = [];
          }

          if (values.ccUser) {
            values.ccUser = values.ccUser.map((item) => item.value.id);
          }

          const response = await props.updateJob(values.id, formatter(values));
          props.history.push(`/job/${values.id}`);
          return response;
        } catch (e) {
          toast.error(e?.error || JSON.stringify(e) || 'Something went wrong...', baseToastConfig);
          props.dispatch({ type: UPDATE_JOB_ERROR });
          return reject();
        }
      });
    },
  })(withDialogs(JobEdit))
);
