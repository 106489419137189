import { FC } from 'react';

import { Box, BoxProps, Typography, useTheme } from '@mui/material';

import logoIcon from 'Assets/UFS_logo.png';

type Props = {
  size?: 'small' | 'medium' | 'large';
} & BoxProps;

const logoSizesMap = {
  small: {
    icon: {
      width: 24,
      height: 34,
    },
    fontSize: 12,
  },
  medium: {
    icon: {
      width: 32,
      height: 45,
    },
    fontSize: 14,
  },
  large: {
    icon: {
      width: 50,
      height: 71,
    },
    fontSize: 20,
  },
};

const Logo: FC<Props> = ({ size = 'medium', ...boxProps }) => {
  return (
    <Box display="flex" gap="12px" alignItems="center" {...boxProps}>
      <img src={logoIcon} alt="logo" {...logoSizesMap[size].icon} />
      <Typography
        fontFamily={'Poppins SemiBold'}
        fontSize={logoSizesMap[size].fontSize}
        style={{ whiteSpace: 'nowrap' }}
      >
        Utility Field Services
      </Typography>
    </Box>
  );
};

export default Logo;
