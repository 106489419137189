import { AppVersionObject } from '../types/Common/User';
import { ImageWithAuthor } from '../types/Location';

export const convertWorkerAppVersionsToString = (app_versions: AppVersionObject[] = []) => {
  if (!app_versions.length) return '';
  const lastVersion = Array.from(app_versions).sort(
    (a, b) =>
      Number(`${b.version_major}${b.version_minor}${b.version_patch}`) -
      Number(`${a.version_major}${a.version_minor}${a.version_patch}`)
  )[0];

  return `${lastVersion.version_major}.${lastVersion.version_minor}.${lastVersion.version_patch}`;
};

export const getFileLink = (file: string | ImageWithAuthor | File): string => {
  if (typeof file === 'string') {
    return file;
  } else if (file instanceof File) {
    return URL.createObjectURL(file);
  } else if (Object.hasOwn(file, 'url')) {
    return file.url;
  } else {
    return '';
  }
};
