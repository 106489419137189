import { useEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';
import ReactSignatureCanvas from 'react-signature-canvas';

import { Box, Button, Tooltip } from '@mui/material';

import PenImg from 'Assets/icons/pen.svg';
import { TimesheetNames } from 'Constants/timesheet';
import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import AppInput from 'components/AppInput';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';
import { useAppSelector } from 'createStore';

import styles from './Signature.module.scss';

const TimesheetSignatureBox = () => {
  const initTimesheet = useAppSelector((state) => state.timesheets.timesheet);
  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;
  const user = useAppSelector((state) => state.app.user);
  const signCanvas = useRef<ReactSignatureCanvas>(null);
  const canvasContainer = useRef<HTMLDivElement>(null);
  const signIsOriginal = useMemo(() => initTimesheet.sign === timesheet.sign, [initTimesheet.sign, timesheet.sign]);
  const currentSignatureBase64 = useRef<string>('');
  const canSignTimesheet = Boolean(timesheet.can_be_signed);

  const initSignatureBase64 = useMemo(() => {
    if (initTimesheet.sign) {
      const { data, type } = JSON.parse(initTimesheet.sign) as { data: string; type: string };
      return `data:${type};base64,${data}`;
    }
    return '';
  }, [initTimesheet.sign]);

  const onEndSignature = () => {
    if (signCanvas.current.isEmpty()) return;
    currentSignatureBase64.current = signCanvas.current.toDataURL();
    const data = currentSignatureBase64.current.replace('data:image/png;base64,', '');
    const signature = {
      data,
      type: 'image/jpeg',
      name: 'photo.jpg',
    };
    formikBag.setValues((values) => ({
      ...values,
      [TimesheetNames.sign]: JSON.stringify(signature),
      [TimesheetNames.signatureName]: user.name,
      [TimesheetNames.employeeNumber]: user.co_employee_id || '',
    }));
  };

  const cancelSignature = () => {
    formikBag.setValues((values) => ({
      ...values,
      [TimesheetNames.sign]: initTimesheet.sign,
      [TimesheetNames.signatureName]: initTimesheet.signatureName,
      [TimesheetNames.employeeNumber]: initTimesheet.employeeNumber,
    }));
  };

  const clearSignatureCanvas = () => {
    formikBag.setFieldValue(TimesheetNames.sign, ' ');
  };

  const clearSignature = () => {
    if (!signCanvas.current) return;
    if (signIsOriginal) {
      clearSignatureCanvas();
    }
  };

  const reSign = () => {
    if (!signCanvas.current) return;
    signCanvas.current.clear();
    currentSignatureBase64.current = '';
    formikBag.setValues((values) => ({
      ...values,
      [TimesheetNames.signatureName]: '',
      [TimesheetNames.employeeNumber]: '',
    }));
  };

  const restoreSignature = async () => {
    if (!initTimesheet.sign) return;
    currentSignatureBase64.current = '';
    cancelSignature();
  };

  useEffect(() => {
    if (!signCanvas.current) return;
    /* <------------ Restore signature ------------> */
    if (timesheet.sign && timesheet.sign === initTimesheet.sign) {
      const { width, height } = canvasContainer.current.getBoundingClientRect();
      signCanvas.current.clear();
      signCanvas.current.fromDataURL(initSignatureBase64, { width, height, ratio: 1 });
      return;
    }
    /* <------------ Clear signature ------------> */
    if (!timesheet.sign || timesheet.sign === ' ') {
      signCanvas.current.clear();
      return;
    }
  }, [timesheet.sign, initSignatureBase64, initTimesheet.sign]);

  useEffect(() => {
    const canvasResized = () => {
      if (!signCanvas.current) return;

      const { width, height } = canvasContainer.current.getBoundingClientRect();
      signCanvas.current.fromDataURL(signIsOriginal ? initSignatureBase64 : currentSignatureBase64.current, {
        width,
        height,
        ratio: 1,
      });
    };
    window.addEventListener('resize', canvasResized);
    return () => {
      window.removeEventListener('resize', canvasResized);
    };
  }, [timesheet.sign, initTimesheet.sign, signIsOriginal, initSignatureBase64]);

  const Signature = useMemo(() => {
    /* <---------User can sign---------> */

    if (canSignTimesheet) {
      if (timesheet.sign) {
        return (
          <SignatureCanvas
            canvasProps={{
              className: classNames(styles.canvas, formikBag.errors.sign && styles.canvas__empty),
              onMouseDownCapture: clearSignature,
            }}
            clearOnResize
            penColor="black"
            ref={signCanvas}
            onEnd={onEndSignature}
          />
        );
      }
      return (
        <div
          className={classNames(formikBag.errors.sign && styles.canvas__empty, styles.noSign)}
          onClick={() => formikBag.setFieldValue(TimesheetNames.sign, ' ')}
        >
          <img src={timesheet.sign ? initSignatureBase64 : PenImg} alt="Pen" />
          No Signature. Please Click Here To Sign
        </div>
      );
    }

    /* <---------User cannot sign---------> */

    if (!timesheet.sign) {
      return (
        <div
          className={classNames(formikBag.errors.sign && styles.canvas__empty, styles.noSign)}
          style={{ cursor: 'not-allowed' }}
        >
          <img src={PenImg} alt="Pen" />
          No Signature. You cannot sign
        </div>
      );
    }

    return <img src={initSignatureBase64} alt="Pen" className={styles.noSign} />;
  }, [timesheet.sign, canSignTimesheet, formikBag.errors.sign, formikBag.setFieldValue]);

  const ClearReSignButton = useMemo(() => {
    if (timesheet.sign) {
      const showReSign = signIsOriginal && !signCanvas.current?.isEmpty();
      return (
        <Button
          color="inherit"
          onClick={showReSign ? reSign : clearSignatureCanvas}
          disabled={signCanvas.current?.isEmpty()}
        >
          {showReSign ? 'Re-sign' : 'Clear'}
        </Button>
      );
    }
    return null;
  }, [timesheet.sign, signIsOriginal, signCanvas.current?.isEmpty()]);

  return (
    <TimesheetBox>
      <Box className={styles.timesheetSignatureBox}>
        <Box className={styles.inputs}>
          <Tooltip disableInteractive title={canSignTimesheet && 'Make signature and your name will fill automatic'}>
            <div>
              <AppInput
                fullWidth
                placeholder="Signature Name"
                name={TimesheetNames.signatureName}
                label="Signature Name"
                disabled
                value={timesheet?.signatureName ?? ''}
              />
            </div>
          </Tooltip>
          <Tooltip
            disableInteractive
            title={
              canSignTimesheet &&
              'Make new signature and then you can fill employee number or it was took from your profile'
            }
          >
            <div>
              <AppInput
                fullWidth
                name={TimesheetNames.employeeNumber}
                label="Employee #"
                placeholder="Employee #"
                FormHelperTextProps={{ className: styles.helperText }}
                error={!!formikBag.errors.employeeNumber}
                helperText={formikBag.errors.employeeNumber}
                disabled={
                  !timesheet.sign ||
                  timesheet.sign?.length < 5 ||
                  Boolean(timesheet.sign && user.co_employee_id) ||
                  !canSignTimesheet
                }
                value={timesheet.employeeNumber && timesheet.employeeNumber !== '0' ? timesheet.employeeNumber : ''}
                onChange={formikBag.handleChange}
                onBlur={formikBag.handleBlur}
              />
            </div>
          </Tooltip>
        </Box>
        <Box className={styles.signature}>
          <div className={styles.signature__header}>
            <span>Signature</span>
            <div>
              {initTimesheet.sign && !signIsOriginal && (
                <Button color="inherit" onClick={restoreSignature}>
                  Restore
                </Button>
              )}
              {!initTimesheet.sign && timesheet.sign && (
                <Button color="inherit" onClick={cancelSignature}>
                  Cancel
                </Button>
              )}
              {!!canSignTimesheet && ClearReSignButton}
            </div>
          </div>

          <div style={{ position: 'relative' }}>
            <div ref={canvasContainer} className={styles.canvasWrapper}>
              {Signature}
            </div>
            {formikBag.errors.sign && <p className={styles.helperText}>{formikBag.errors.sign}</p>}
          </div>
        </Box>
      </Box>
    </TimesheetBox>
  );
};

export default TimesheetSignatureBox;
