import { FC } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { NotificationActions } from 'Services/notifications/reducer';
import { useAppDispatch, useAppSelector } from 'createStore';

import NotificationsFiltersSkeleton from './NotificationsFilter.Skeleton';
import styles from './NotificationsFilter.module.scss';

const NotificationsFilter: FC = () => {
  const dispatch = useAppDispatch();

  const search_options = useAppSelector((state) => state.notifications.search_options);
  const filtersList = useAppSelector((state) => state.notifications.filtersList);
  const filtersProcessing = useAppSelector((state) => state.notifications.filtersProcessing);

  const updateSearchOptions = (notifiableType: number | null) => {
    if (notifiableType === search_options.notifiableType) return;
    return dispatch(NotificationActions.updateOptions({ notifiableType, cursor: null }));
  };

  if (filtersProcessing) {
    return <NotificationsFiltersSkeleton />;
  }

  return (
    <Box className={styles.notificationsFilterBox}>
      <Button
        className={styles.notificationsFilterButton}
        aria-selected={!search_options.notifiableType}
        onClick={() => updateSearchOptions(0)}
      >
        All Notifications
      </Button>
      <Box className={styles.notificationsFilterGroupsList}>
        {filtersList.map(({ groupName, options }) => (
          <Box key={groupName} className={styles.notificationsFilterGroup}>
            <Typography variant="body1" fontWeight="bold" color="text.primary">
              {groupName}
            </Typography>
            <Box display="flex" flexDirection="column">
              {options.map(({ value, label }) => (
                <Button
                  key={value}
                  aria-selected={value === search_options.notifiableType}
                  className={styles.notificationsFilterButton}
                  onClick={() => updateSearchOptions(value)}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NotificationsFilter;
