import * as Yup from 'yup';

import { emailRegExp, profileNameRegExp } from 'Constants/reg_exp';
import { RegExpEmployeeNumber } from 'Constants/user';
import { PhoneNumberValidation } from 'Constants/validation';

export const employeeNumberValidation = Yup.string()
  .label('Employee number')
  .nullable()
  .matches(RegExpEmployeeNumber, { message: 'Invalid value. Value must contain only numbers' });

export const ProfileEditValidation = Yup.object().shape({
  email: Yup.string().required('Email is required').matches(emailRegExp, 'Email is not correct'),
  firstName: Yup.string()
    .trim()
    .matches(profileNameRegExp, 'First Name can only contain Latin letters')
    .required('First Name is required'),
  lastName: Yup.string()
    .trim()
    .matches(profileNameRegExp, 'Last Name can only contain Latin letters')
    .required('Last Name is required'),
  phoneNumber: PhoneNumberValidation.required('Phone Number is required'),
  departments: Yup.array().of(Yup.object()).notRequired(),
  departmentGroups: Yup.array().of(Yup.object()).notRequired(),
  roles: Yup.array().of(Yup.number()).min(1, 'At least one role is required'),
  currentPassword: Yup.string().trim(),
  password: Yup.string().trim().notRequired().min(6, 'Password is too short'),
  repeatPassword: Yup.string()
    .trim()
    .when(['password'], ([password]: [string], schema) => {
      return password ? schema.required('Please repeat password') : schema.notRequired();
    })
    .oneOf([Yup.ref('password'), null], 'The repeated password must match the password'),
});

export const ProfileValidation = {
  edit: ProfileEditValidation,
  editWithCurrentPassword: ProfileEditValidation.shape({
    currentPassword: Yup.string()
      .trim()
      .when(['password'], ([password]: [string], schema) => {
        return password ? schema.required('Current password is required') : schema.notRequired();
      }),
  }),
};
