import React from 'react';

import generalStyles from 'general.module.scss';

import { Box } from '@mui/material';

import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import AppInput from 'components/AppInput';
import { useAppSelector } from 'createStore';

import styles from './JobLocationInfo.module.scss';

const JobLocationInfo = () => {
  const timesheet = useAppSelector((state) => state.timesheets.timesheet);
  return (
    <TimesheetBox classNames={styles.locationBox}>
      {timesheet.locations.map((location, index) => (
        <Box className={generalStyles.inputsBox} key={location.id}>
          <AppInput className={styles.locationField} label="Location" value={location.address ?? ''} disabled />
          <AppInput label="Structure #" value={location.structure ?? ''} disabled />
        </Box>
      ))}
    </TimesheetBox>
  );
};

export default JobLocationInfo;
