export const copyToClipboard = (value: string | number) => navigator.clipboard.writeText(value.toString());

export const copyWithInfo: (
  value: string | number,
  info?: string,
  options?: { offsetTop?: number; offsetLeft?: number; closeTimeout?: number; followCursor?: boolean }
) => (e: MouseEvent | React.MouseEvent) => Promise<void> =
  (value = '', info = 'Was copied', options = {}) =>
  async (e) => {
    e.stopPropagation();
    const { offsetTop = 5, offsetLeft = 15, closeTimeout = 1000, followCursor = false } = options;
    const text = document.createElement('div');
    text.id = 'mouseText';
    text.innerText = info;
    text.setAttribute('style', `top: ${e.pageY + offsetTop}px; left: ${e.pageX + offsetLeft}px`);

    let listener: (ev: MouseEvent) => void = null;
    try {
      await copyToClipboard(value);
      if (followCursor) {
        listener = (ev: MouseEvent) => {
          text.setAttribute('style', `top: ${ev.pageY + offsetTop}px; left: ${ev.pageX + offsetLeft}px`);
        };
        document.body.addEventListener('mousemove', listener);
      }
      document.body.appendChild(text);

      setTimeout(() => {
        text.remove();
        if (listener) {
          document.body.removeEventListener('mousemove', listener);
        }
      }, closeTimeout);
    } catch (error) {
      console.log(error);
    }
  };
