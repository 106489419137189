import React from 'react';

import { LoadingButton } from '@mui/lab';

import AppInput from 'components/AppInput';

import styles from '../TimesheetComments.module.scss';

interface Props {
  loading: boolean;
  onAddComment: () => void;
  onInputChange: (event) => void;
  newComment: string;
}

const AddCommentForm: React.FC<Props> = ({ loading, onAddComment, onInputChange, newComment }) => {
  return (
    <div className={styles.commentForm}>
      <AppInput
        placeholder="Leave a comment"
        className={styles.commentFormInput}
        label="New comment"
        multiline
        value={newComment}
        disabled={loading}
        onChange={onInputChange}
      />
      <LoadingButton
        variant="contained"
        className={styles.addCommentButton}
        disabled={!newComment.trim()}
        loading={loading}
        onClick={onAddComment}
      >
        Add Comment
      </LoadingButton>
    </div>
  );
};

export default AddCommentForm;
