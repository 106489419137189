import { FC } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, Tooltip } from '@mui/material';

type Props = {
  active: boolean;
  onClick: () => void;
};

const PendingTimesheetsFilterButton: FC<Props> = ({ active, onClick }) => {
  return (
    <Tooltip disableInteractive title={`Show ${active ? 'all' : 'only pending'} timesheets`} aria-label="import" arrow>
      <Button variant={active ? 'contained' : 'outlined'} style={{ marginInline: 8 }} onClick={onClick}>
        <AccessTimeIcon />
      </Button>
    </Tooltip>
  );
};

export default PendingTimesheetsFilterButton;
