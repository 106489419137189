import { GOOGLE_MAP_PLACE_LINK } from '../Constants/app';

export const openGoogleMapPlace = ({ lat = 0, lon = 0 }) => `${GOOGLE_MAP_PLACE_LINK}${lat},${lon}`;

export const openGoogleMapStreetView = ({ lat = 0, lon = 0, lng = 0 }) => {
  const latitude = lat;
  const longitude = lon || lng;
  if (!latitude || !longitude) return false;
  const params = new URLSearchParams({
    api: '1',
    map_action: 'pano',
    viewpoint: [latitude, longitude].join(','),
  });
  window.open(`https://www.google.com/maps/@?${params}`);
  return true;
};
