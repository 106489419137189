import React, { useEffect, useState } from 'react';

import { MenuItem, Pagination, Select, SelectChangeEvent } from '@mui/material';

import classNames from './AppTablePagination.module.scss';

const numRegExp = /\d*/;

type Props = {
  page: number | string;
  total: number | string;
  perPage?: number | 10 | 25 | 50 | 100 | string | '10' | '25' | '50' | '100';
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
  onPaginationChange: (itemsPerPage: number | 10 | 25 | 50 | 100) => void;
};

const AppTablePagination = (
  { page = 1, total = 0, onChangePage = () => {}, onPaginationChange = () => {}, perPage = 10 }: Props,
  ref: React.ForwardRefExoticComponent<HTMLDivElement>
) => {
  const [userPage, setUserPage] = useState<string>(null);
  const pageNumber = typeof page === 'string' ? parseInt(page) : page;
  const totalNumber = typeof total === 'string' ? parseInt(total) : total;
  const perPageNumber = typeof perPage === 'string' ? parseInt(perPage) : perPage;
  const totalPages = Math.ceil(totalNumber / perPageNumber);

  useEffect(() => {
    if (userPage !== null && parseInt(userPage) !== page) {
      setUserPage(null);
      return;
    }
  }, [pageNumber]);

  const changePagination = (event: SelectChangeEvent<string | number>) => {
    onPaginationChange(Number(event.target.value));
    setUserPage(null);
  };

  const changePage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!numRegExp.test(value)) return;
    if (value.length <= totalPages.toString().length) {
      setUserPage(value);
      if (!Number.isNaN(value)) {
        onChangePage(e, parseInt(value));
      }
    }
  };

  const selectPage = (event: React.ChangeEvent<unknown>, page: number) => {
    setUserPage(null);
    onChangePage(event, page);
  };

  return (
    <div className={classNames.container} ref={ref}>
      <div className={classNames.resizableBlock}>
        <div className={classNames.perPageBlock}>
          <p className={classNames.title}>Per Page:</p>
          <Select
            labelId="app-table-pagination-label"
            id="app-table-pagination"
            value={perPageNumber}
            onChange={changePagination}
            variant="standard"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            {![10, 25, 50, 100].includes(perPageNumber) ? <MenuItem value={perPageNumber}>{perPage}</MenuItem> : null}
          </Select>
        </div>
        <p className={classNames.title}>
          PAGE{' '}
          <input
            style={{ width: `${totalPages.toString().length * 10}px`, textAlign: 'right' }}
            value={userPage !== null ? userPage : pageNumber}
            onChange={changePage}
          />{' '}
          of {totalPages !== 0 ? totalPages : 1}
        </p>
      </div>
      <Pagination
        page={pageNumber}
        count={Math.max(0, totalPages)}
        onChange={selectPage}
        siblingCount={1}
        boundaryCount={1}
      />
    </div>
  );
};

export default React.memo(React.forwardRef(AppTablePagination));
