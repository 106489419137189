import React, { useEffect, useMemo } from 'react';

import { Autocomplete, CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';

import { ConEdSupervisor } from 'types/Common/User';

import { UsersAPI } from 'Services/API';
import useProcessing from 'Utils/hooks/useProcessing';

interface Props {
  onSelect?: (item: ConEdSupervisor) => void;
  width?: any;
  selectedId?: number;
  limitTags?: any;
  noLabel?: boolean;
  usersNotAvailable?: any;
  departmentId?: any;
  disabled?: boolean;
  inputSize?: 'small' | 'medium';
  fullWidth?: boolean;
  defaultSupervisor?: ConEdSupervisor;
  placeholder?: string;
}

export default function SupervisorMaterialAsyncSearch({
  onSelect,
  width,
  selectedId,
  limitTags,
  noLabel,
  departmentId,
  usersNotAvailable,
  disabled,
  inputSize = 'small',
  fullWidth = false,
  defaultSupervisor = null,
  placeholder = 'Supervisors',
}: Props) {
  const [supervisors, setSupervisors] = React.useState<ConEdSupervisor[]>([]);
  const { promiseWrapper, inProcess } = useProcessing(true);

  useEffect(() => {
    const getSupervisors = async () => {
      try {
        const response = await UsersAPI.getSupervisors({
          department_id: departmentId ? departmentId : '',
        });

        if (!response?.supervisors) {
          return setSupervisors([]);
        }

        if (usersNotAvailable && usersNotAvailable.some((userId) => userId !== null && userId !== undefined)) {
          return setSupervisors(response.supervisors.filter((worker) => usersNotAvailable.indexOf(worker.id) === -1));
        }

        return setSupervisors(response.supervisors);
      } catch (error) {
        setSupervisors([]);
      }
    };
    promiseWrapper(getSupervisors());
  }, [promiseWrapper, departmentId]);

  const autocompleteValue = useMemo(() => {
    const supervisor = supervisors.find((supervisor) => supervisor?.id === selectedId);
    return supervisor || defaultSupervisor || null;
  }, [selectedId, supervisors]);

  return (
    <Autocomplete
      limitTags={limitTags ? limitTags : 1}
      size={inputSize}
      value={autocompleteValue}
      style={{ width, minWidth: 150 }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <span>{option.name}</span>
        </li>
      )}
      options={supervisors}
      noOptionsText="There are no supervisors assigned to the selected department"
      loading={inProcess}
      renderInput={(params) => (
        <TextField
          {...params}
          label={noLabel ? '' : 'Supervisors'}
          placeholder={selectedId && !autocompleteValue ? 'Restoring...' : placeholder}
          variant="outlined"
          InputLabelProps={{ shrink: (selectedId && !autocompleteValue && inProcess) || !!autocompleteValue }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {inProcess ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(event, value) => onSelect(value)}
      disabled={disabled || inProcess}
      fullWidth={fullWidth}
    />
  );
}
