import React, { memo } from 'react';

import { Box, IconButton, MenuItem, Tooltip, TooltipProps, Typography } from '@mui/material';

type Props = {
  tooltipTitle?: string | JSX.Element;
  tooltipProps?: Omit<Omit<TooltipProps, 'title'>, 'children'>;
  children?: string | JSX.Element;
  Icon?: JSX.Element;
  onClick?: (event?: React.MouseEvent<HTMLLIElement>) => void;
  disabled?: boolean | string | number;
};

const TimesheetActionButton = ({
  tooltipTitle = '',
  tooltipProps = {},
  children = '',
  Icon = null,
  onClick = () => {},
  disabled = false,
}: Props) => {
  return (
    <Tooltip title={tooltipTitle} arrow placement="top" {...tooltipProps}>
      <div>
        <MenuItem disabled={Boolean(disabled)} onClick={onClick}>
          <Box display="flex" alignItems="center">
            <IconButton>{Icon}</IconButton>
            <Typography>{children}</Typography>
          </Box>
        </MenuItem>
      </div>
    </Tooltip>
  );
};

export default memo(TimesheetActionButton);
