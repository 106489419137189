import React, { PropsWithChildren } from 'react';

import { AddCircle, DeleteForeverRounded } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';

type Props = PropsWithChildren<{
  title: string;
  name: string;
  isActive: boolean;
  onActivate: (name: string) => void;
  onDeactivate: (name: string) => void;
  fullWidth?: boolean;
  disableActivation?: boolean;
}>;

const OptionalField = ({
  children,
  title = '',
  name = '',
  isActive = false,
  onActivate = () => {},
  onDeactivate = () => {},
  fullWidth = false,
  disableActivation = false,
}: Props) => {
  if (!isActive) {
    return (
      <Button
        sx={{ justifyContent: 'start', width: fullWidth ? '100%' : 'auto' }}
        onClick={() => onActivate(name)}
        disabled={disableActivation}
      >
        <AddCircle sx={{ marginRight: 1 }} />
        {title}
      </Button>
    );
  }

  return (
    <Box display="flex" alignItems="center" width="100%">
      {children}
      <IconButton color="error" onClick={() => onDeactivate(name)}>
        <DeleteForeverRounded />
      </IconButton>
    </Box>
  );
};

export default OptionalField;
