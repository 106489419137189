/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '@mui/material';
import { TextField } from '@mui/material';

import Invoice from 'types/Invoices';

import AppPermissions from 'Constants/permissions';
import { actions } from 'Services';
import { updateSearchInvoiceInputs } from 'Services/invoices/actions';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import { ReduxState, useAppSelector } from 'createStore';

import ConfigurationTable from './ConfigurationTable';
import Header from './HeaderInvoices';
import AddInvoiceSliderComponent from './InvoiceCreateSlide';
import InvoicesTableRow from './InvoiceTable/InvoicesTableRow';
import './Invoices.scss';

interface Props extends ReduxProps {}

const Invoices: React.FC<Props> = ({
  invoice_search_options,
  configurations,
  has_access,
  createInvoice,
  setSearchInvoiceId,
  updateSearchOptions,
  updateInvoicesFilters,
}) => {
  const [showedAddInvoice, setShowedAddInvoice] = useState(null);
  const [order, setOrder] = useState({ order_by: 'uid', order_by_type: true });
  const searchInvoiceId = useAppSelector((state) => state.invoices.invoices_search_options.id);
  const history = useHistory();

  const addInvoice = () => {
    setShowedAddInvoice(true);
  };

  const closeInvoice = () => {
    setShowedAddInvoice(false);
  };

  const applyQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const create = urlParams.get('create');
    if (create) {
      setShowedAddInvoice(true);
      window.history.replaceState(urlParams.toString(), '', '/');
    }
  };

  const submit = async (invoice) => {
    try {
      await createInvoice(invoice);
      showSuccessMessage('New invoices created!');
      closeInvoice();
    } catch (e) {
      showErrorMessage(e);
    }
  };

  const handleSort = async (value) => {
    const order_by = value;
    const order_by_type = order.order_by === value ? !order.order_by_type : true;

    updateSearchOptions({
      order_by,
      order_by_type,
    });
    setOrder({ order_by, order_by_type });
  };

  const openConfigurationPage = (invoice: Invoice.Item) => {
    history.push(`/invoices/${invoice.config_id}/${invoice.id}`);
  };

  useEffect(() => {
    applyQueryParams();
    updateSearchOptions();
  }, []);

  useEffect(() => {
    updateSearchOptions({ invoice_id: searchInvoiceId, page: 1 });
  }, [searchInvoiceId]);

  return (
    <div className="invoices-list-page">
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="page-title" style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
          Configurations
          <TextField
            value={searchInvoiceId}
            onChange={({ target: { value } }) => setSearchInvoiceId(value ?? '')}
            placeholder="Invoice #"
            label="Search for invoice"
          />
          <TextField
            value={invoice_search_options.po}
            onChange={({ target: { value } }) => updateInvoicesFilters({ po: value })}
            placeholder="PO #"
            label="Search by PO"
          />
        </div>

        {has_access && (
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: 245,
              borderRadius: 20,
              textTransform: 'none',
            }}
            onClick={() => addInvoice()}
          >
            Configure New Invoice
          </Button>
        )}
      </div>
      {invoice_search_options.po ? (
        <InvoicesTableRow handleRowClick={openConfigurationPage} style={{ margin: 20, width: 'auto', flexGrow: 0 }} />
      ) : (
        <>
          <Header />
          <ConfigurationTable
            configurations={configurations}
            handleSort={handleSort}
            orderBy={order.order_by}
            orderByType={order.order_by_type}
          />
        </>
      )}

      {showedAddInvoice ? (
        <AddInvoiceSliderComponent submit={submit} showed={showedAddInvoice} closeSlide={() => closeInvoice()} />
      ) : null}
    </div>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    invoice_search_options: state.invoices.invoices_search_options,
    configurations: state.invoices.configurations,
    loading: state.invoices.processing,
    has_access: state.app.permissions.includes(AppPermissions.edit_invoices),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateSearchOptions: (search_options?: Partial<ReduxState['invoices']['search_options']>) =>
      dispatch(actions.InvoicesActions.updateFilters(search_options)),
    updateInvoicesFilters: (search_options) =>
      dispatch(actions.InvoicesActions.updateInvoicesFilters(null, search_options)),
    setSearchInvoiceId: (value) => dispatch(updateSearchInvoiceInputs({ id: value })),

    createInvoice: (data) => dispatch(actions.InvoicesActions.createInvoice(data)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Invoices);
