import React from 'react';

import { Dialog } from '@mui/material';

import './ReconstructJob.scss';

/**
@param {{
  isOpen?: boolean,
  alertText?: string | JSX.Element,
  onClose?: () => void,
  onSuccess?: () => void,
  loadingText?: string | JSX.Element,
  successButtonTitle?: string | JSX.Element,
  cancelButtonTitle?: string | JSX.Element,
  maxWidth?: import('@mui/material').DialogProps['maxWidth'],
}} props 
 */
const AttentionModal = ({
  isOpen = false,
  alertText = '',
  onClose = () => {},
  onSuccess = () => {},
  loadingText = '',
  cancelButtonTitle = 'No',
  successButtonTitle = 'Yes',
  maxWidth = 'md',
}) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth}>
    <div className="p-3 pt-0">
      <p className="text-center text-danger alert-text">ATTENTION!</p>
      <div>{alertText}</div>
      <div className="text-center actions">
        <button type="button" className="btn btn-success btn-add height-42 button-decline" onClick={onClose}>
          {cancelButtonTitle}
        </button>
        <button
          type="button"
          className="btn btn-alert btn-add height-42 button-approve alert-button"
          onClick={onSuccess}
          style={{ marginLeft: 24 }}
        >
          {loadingText || successButtonTitle}
        </button>
      </div>
    </div>
  </Dialog>
);

export default AttentionModal;
