import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import { BiCopy } from 'react-icons/bi';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box, Button } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { TimesheetStatus } from 'Constants/timesheet';
import { UserRoles } from 'Constants/user';
import { NotesModal } from 'Containers/Dispatch/dialog/NotesModal/NotesModal';
import TimesheetInfo from 'Containers/Timesheets/containers/TimesheetEditHeader/TimesheetInfo';
import { actions } from 'Services';
import { downloadTimesheetPDF } from 'Services/timesheets/actions';
import * as CeIcon from 'Utils/Icon';
import UserPermissions from 'Utils/PermissionsHelper';
import { copyWithInfo } from 'Utils/copy';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import AppPaperModal from 'components/AppPaperModal';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppSelector } from 'createStore';

import styles from './TimesheetEditHeader.module.scss';

type Props = {
  historyPage: boolean;
  historyPageToggle: () => void;
  isLockedByConEdVerified?: boolean;
  unlockFields?: () => void;
};

const TimesheetEditHeader: React.FC<Props> = ({
  historyPage,
  historyPageToggle,
  isLockedByConEdVerified = false,
  unlockFields,
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const timesheet = useAppSelector((state) => state.timesheets.timesheet);
  const timesheetIsCancelled = timesheet?.status === TimesheetStatus.cancelled;
  const userCanAdjustCEVerifiedTimesheets = UserPermissions.has.adjust_ce_verified_timesheets;
  const userCanSeeTimesheetHistory = UserPermissions.has.see_timesheet_history;
  const unlockFieldsConfirmModal = useModal();
  const { openPopover, closePopover } = usePopoverContext();
  const canSeeDispatchNotes = useMemo(() => !UserRoles.has.ConEdRole, []);

  const onDownloadPDF = () => {
    downloadTimesheetPDF(timesheet.id).catch(showErrorMessage);
  };

  const reviveTimesheet = () => {
    dispatch(actions.TimesheetsActions.reviveTimesheet(timesheet.id)).catch(showErrorMessage);
  };

  const openDispatchHistoryModal = async () => {
    try {
      openPopover(
        'job-notes',
        <NotesModal jobId={timesheet.jobId} open onClose={() => closePopover('job-notes')} title="Dispatch Notes" />
      );
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <Box className={styles.wrapper}>
      <TimesheetInfo />
      <div className={styles.actions}>
        <Button className={styles.actionButton} onClick={onDownloadPDF}>
          <CeIcon.DownloadInvoiceIcon />
          Download
        </Button>
        {userCanSeeTimesheetHistory && (
          <Button className={styles.actionButton} onClick={historyPageToggle}>
            <RestoreIcon fontSize="small" />
            {historyPage ? 'Timesheet' : 'History'}
          </Button>
        )}
        {canSeeDispatchNotes && (
          <Button className={styles.actionButton} onClick={openDispatchHistoryModal}>
            <RestoreIcon fontSize="small" />
            Dispatch Notes
          </Button>
        )}
        {userCanAdjustCEVerifiedTimesheets && isLockedByConEdVerified && (
          <>
            <Button className={styles.actionButton} onClick={unlockFieldsConfirmModal.open}>
              <LockOpenIcon />
              Unlock
            </Button>
            {unlockFieldsConfirmModal.isOpen && (
              <AppPaperModal
                modalId="unlock-fields-confirm-modal"
                title={'Edit CES Verified timesheet'}
                open={unlockFieldsConfirmModal.isOpen}
                onClose={unlockFieldsConfirmModal.close}
                submitButton={{
                  title: 'Edit Anyway',
                  onClick: unlockFields,
                }}
              >
                <div style={{ maxWidth: '550px' }}>
                  <p>This timesheet has already been verified by ConEd user, {timesheet.signatureName}.</p>
                  <p>No changes should be made.</p>
                  <p>
                    If you want to edit anyway, and need ConEd to re-verify, please uncheck <b>“ConEd Verified“</b>,
                    before submitting changes.
                  </p>
                  <p>Otherwise, ConEd will not be notified of changes.</p>
                </div>
              </AppPaperModal>
            )}
          </>
        )}
        {timesheetIsCancelled && (
          <>
            <Button
              className={classNames(styles.actionButton, styles.blue)}
              onClick={copyWithInfo(window.location.href, 'Link Copied!')}
            >
              <BiCopy />
              Copy to Share
            </Button>
            <Button className={classNames(styles.actionButton, styles.red)} onClick={reviveTimesheet}>
              Revive
            </Button>
          </>
        )}
      </div>
    </Box>
  );
};

export default TimesheetEditHeader;
