import React, { useCallback, useState } from 'react';

import { FormikErrors } from 'formik';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { BaseLocation } from 'types/Common/LocationData';

import UserPermissions from 'Utils/PermissionsHelper';
import Button from 'components/Button/Button';

import { JobCreateEdit } from '../../JobFormComponent';
import DeleteJob from '../../dialogs/DeleteJobDialog';
import './JobsLeftBlock.scss';

type Structures = {
  [key: string]: string[];
};

interface IProps {
  jobs: JobCreateEdit[];
  isValid: boolean;
  onSave: any;
  handleBlur: any;
  processing: boolean;
  isEdit?: boolean;
  errors: { jobs: FormikErrors<JobCreateEdit[]> };
  changeJob: any;
  deleteJob?: any;
  selectedIndex: number;
  addAnotherJob?: boolean;
  addNewJob?: any;
  showErrors?: boolean;
  addCheckDuplicates?: boolean;
  checkDuplicates?: () => void;
  duplicates?: Array<any>;
}

export const JobsLeftBlock = (props: IProps) => {
  const userCanCloneJob = UserPermissions.has.can_clone_job;
  const [openDeleteJob, setOpenDeleteJob] = useState(false);
  const [index, setIndex] = useState(0);

  const closeDeleteJob = () => {
    setOpenDeleteJob(false);
  };

  const openDeleteJobDialog = (index) => {
    setOpenDeleteJob(true);
    setIndex(index);
  };

  const deleteJob = () => {
    props.deleteJob(index);
    closeDeleteJob();
  };

  const getErrorMsg = (errors: FormikErrors<JobCreateEdit>): string => {
    const error = Object.values(errors).shift();
    if (typeof error === 'string') return error;
    if (Array.isArray(error)) {
      for (let i = 0; i < error.length; i++) {
        // @ts-ignore
        if (error[i]) return Object.values(error[i]).shift();
      }
    }
  };

  const CheckTooltip = () => {
    const duplicatesCount = props.duplicates ? props.duplicates.length : 0;

    return (
      <React.Fragment>
        <span style={{ fontSize: '12px', padding: '10px', lineHeight: '20px' }}>
          Duplicates found - {duplicatesCount}.{' '}
          {duplicatesCount ? (
            <u style={{ cursor: 'pointer' }} onClick={props.checkDuplicates}>
              SHOW
            </u>
          ) : null}
        </span>
      </React.Fragment>
    );
  };

  const getAddressesList = useCallback((locations: BaseLocation[]) => {
    if (!locations) return '';

    const structures: Structures = locations.reduce((acc: Structures, { address, structure }) => {
      if (!address || (acc[address] && !structure)) return acc;
      return acc[address]
        ? { ...acc, [address]: [...acc[address], structure] }
        : { ...acc, [address]: structure ? [structure] : [] };
    }, {});

    return Object.entries(structures).map(([address, structures]) => (
      <div className="location-item" key={address}>
        <p className="location-text">{`${address}${structures.length ? ':' : ''}`}</p>
        <p className="location-text">{structures.map((structure) => `#${structure}`).join(', ')}</p>
      </div>
    ));
  }, []);

  return (
    <>
      <div className="left-white-block">
        <div className="block-row">
          <p className="title-left">Added Job List</p>
        </div>
        {props.jobs.map((job, index) => {
          const hasErrors =
            props.errors.jobs && props.errors.jobs[index] && Object.values(props.errors.jobs[index]).shift();
          const hasNoErrors =
            (props.errors.jobs && props.errors.jobs[index] && !Object.values(props.errors.jobs[index]).shift()) ||
            !props.errors.jobs;
          const addressesList = getAddressesList(job.locations);
          const helperText = hasErrors && props.showErrors ? getErrorMsg(props.errors.jobs[index]) : 'Editing...';

          return (
            <div
              key={String(index)}
              className={`block-row cursor-pointer ${index === props.selectedIndex && 'active-job-block'}`}
              onClick={() => props.changeJob(index)}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                <b>Job #{index + 1} </b>
                {hasErrors && props.showErrors ? (
                  <WarningAmberIcon fontSize="small" color="error" />
                ) : hasNoErrors ? (
                  <CheckCircleOutlineIcon fontSize="small" color="success" />
                ) : (
                  <BorderColorIcon fontSize="small" sx={{ color: '#00000040' }} />
                )}
                <Box display="flex" justifyContent="flex-end" flex={1}>
                  {!props.isEdit && (
                    <>
                      {userCanCloneJob && (
                        <IconButton size="small" onClick={() => props.addNewJob(job)}>
                          <ContentCopyIcon />
                        </IconButton>
                      )}
                      {props.jobs.length > 1 && (
                        <IconButton size="small" color="error" onClick={() => openDeleteJobDialog(index)}>
                          <DeleteForeverOutlinedIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </Box>
              </Box>
              {addressesList}
              <Typography variant="caption" color={hasErrors && props.showErrors ? 'error' : 'text'}>
                {helperText}
              </Typography>
            </div>
          );
        })}

        {props.addAnotherJob && (
          <div className="block-row cursor-pointer" onClick={() => props.addNewJob()}>
            <div className="text-16 text-blue add-another-job-block">
              Add Another Job
              <AddCircleOutlineIcon color="primary" />
            </div>
          </div>
        )}

        <Tooltip
          title={
            !props.isValid ? (
              'Some required data is no valid. Please, check again and correct'
            ) : props.addCheckDuplicates && props.jobs.length > 1 ? (
              <CheckTooltip />
            ) : (
              ''
            )
          }
          arrow
          aria-label="submit"
          disableInteractive={props.isValid && !props.addCheckDuplicates}
        >
          <div className="block-row border-none">
            <Button
              color={'dark'}
              width={'100%'}
              height={'42px'}
              borderRadius={'20px'}
              type="submit"
              processing={props.processing}
              onBlur={props.handleBlur}
              onClick={props.onSave}
            >
              {!props.showErrors ? 'Verify jobs' : !props.errors.jobs ? 'Save Jobs' : 'Verify jobs'}
            </Button>
          </div>
        </Tooltip>
      </div>
      <DeleteJob open={openDeleteJob} onClose={closeDeleteJob} clicked={deleteJob} />
    </>
  );
};
