import { FC, useEffect, useRef } from 'react';

import { useFormik } from 'formik';
import SignaturePad from 'react-signature-canvas';

import { Box, Button, Typography } from '@mui/material';

import { TimesheetTableItem } from 'types/Timesheet';

import SignatureBox from 'Containers/Timesheets/components/SignatureBox/SignatureBox';
import { actions } from 'Services';
import AppInputField from 'components/AppInputField/AppInputField';
import AppPaperModal from 'components/AppPaperModal';
import { useAppSelector } from 'createStore';

import ApproveTimesheetsValidationSchema from './ApproveTimesheets.Validation';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedTimesheets: TimesheetTableItem[];
  modalId?: string;
  onApprove: (
    timesheets: number[],
    update: Parameters<(typeof actions)['TimesheetsActions']['updateMultiple']>['1']
  ) => Promise<void>;
};

const ApproveTimesheetsModal: FC<Props> = ({
  modalId = 'approve-selected-timesheets-modal',
  open,
  onClose,
  selectedTimesheets,
  onApprove,
}) => {
  const user = useAppSelector((state) => state.app.user);
  const canvasContainer = useRef<HTMLDivElement>(null);
  const signCanvas = useRef<SignaturePad>(null);
  const currentSignatureBase64 = useRef<string>('');
  const { values, errors, isSubmitting, setFieldValue, setValues, handleChange, submitForm } = useFormik({
    initialValues: {
      signatureName: user.name,
      employee_number: user.co_employee_id,
      sign: '',
      signature_on: false,
    },
    onSubmit: (values) => {
      const timesheetIds = selectedTimesheets.map((timesheet) => timesheet.id);
      const update = { ...values };
      delete update.signature_on;
      delete update.signatureName;

      onApprove(timesheetIds, update);
    },
    validationSchema: ApproveTimesheetsValidationSchema,
  });

  const changeSignatureValue = (value: string) => {
    setFieldValue('sign', value);
  };

  const clearSignatureCanvas = () => {
    setFieldValue('sign', '');
  };

  useEffect(() => {
    const onResizeWindow = () => {
      if (!signCanvas.current) return;

      const { width, height } = canvasContainer.current.getBoundingClientRect();
      signCanvas.current.fromDataURL(currentSignatureBase64.current || '', {
        width,
        height,
        ratio: 1,
      });
    };

    window.addEventListener('resize', onResizeWindow);

    return () => {
      window.removeEventListener('resize', onResizeWindow);
    };
  }, []);

  return (
    <AppPaperModal
      modalId={modalId}
      title="Approve timesheets"
      open={open}
      onClose={onClose}
      containerStyle={{ width: '100%', maxWidth: 524 }}
      submitButton={{
        title: 'Approve',
        onClick: submitForm,
        loading: isSubmitting,
      }}
    >
      <Box display="flex" flexDirection="column" gap="14px">
        <Box
          display="flex"
          gap={'14px'}
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
          }}
        >
          <AppInputField
            disabled
            fullWidth
            value={values.signatureName}
            label="Signature name"
            placeholder="Signature Name"
          />
          <AppInputField
            disabled={Boolean(user.co_employee_id)}
            name="employee_number"
            onChange={handleChange}
            fullWidth
            value={values.employee_number}
            label="Employee #"
            placeholder="Employee #"
            error={Boolean(errors.employee_number)}
            helperText={errors.employee_number}
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                color: (theme) => errors.sign && theme.palette.error.main,
              }}
            >
              Signature
            </Typography>

            <Button size="small" onClick={clearSignatureCanvas} disabled={!values.sign}>
              Clear
            </Button>
          </Box>

          <SignatureBox value={values.sign} onChange={changeSignatureValue} error={!!errors.sign} />

          <Typography sx={{ pl: 2 }} variant="caption" color={errors.sign ? 'error' : 'text.secondary'}>
            {errors.sign || 'You can put your signature'}
          </Typography>
        </Box>
      </Box>
    </AppPaperModal>
  );
};

export default ApproveTimesheetsModal;
