import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ifvisible from 'ifvisible';

import { AppThunkDispatch } from 'types';
import { Note } from 'types/Notes';

import { actions } from 'Services';

type Props = PropsWithChildren<{
  entity_id: string | number;
  entity_type: string;
}>;

type NotesContextType = {
  notes?: Note[];
  showNotes: boolean;
  toggleNotes: () => void;
  refetch: () => Promise<Note[]>;
};

const NotesContext = createContext<NotesContextType>({
  refetch: () => Promise.resolve([]),
  showNotes: false,
  toggleNotes: () => {},
});

export const useNotesContext = () => useContext(NotesContext);

export const withNotes = (Component: React.ComponentType) => {
  const WithDialogs = (props: any) => {
    const context = useNotesContext();
    return <Component {...context} {...props} />;
  };
  return WithDialogs;
};

export const NotesConsumer = NotesContext.Consumer;

const NotesProvider = ({ children, entity_id, entity_type }: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [notes, setNotes] = useState([]);

  const [showNotes, setShowNotes] = useState(true);

  const fetchNotes = useCallback(async () => {
    try {
      const fetchedNotes = await dispatch(actions.NotesActions.getNotes(entity_id, entity_type));
      setNotes(fetchedNotes);
    } catch (error) {
      return [];
    }
  }, [dispatch, entity_id, entity_type]);

  useEffect(() => {
    fetchNotes();
    const every5subscription = ifvisible.onEvery(30, fetchNotes);
    ifvisible.on('focus', fetchNotes);

    return () => {
      every5subscription.stop();
      ifvisible.off('focus', fetchNotes);
    };
  }, [fetchNotes]);

  const toggleNotes = useCallback(() => {
    setShowNotes((prev) => !prev);
  }, []);

  const value = useMemo(() => {
    return {
      notes,
      showNotes,
      toggleNotes,
      refetch: fetchNotes,
    };
  }, [notes, showNotes, toggleNotes]);

  return <NotesContext.Provider value={value}>{children}</NotesContext.Provider>;
};

export default NotesProvider;
