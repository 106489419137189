import React, { memo } from 'react';

import { Tooltip } from '@mui/material';

type Props = { text: string; length?: number };

/**
 * Trim text to desired length(default 12)
 * @param {Props} props
 * @returns Span with text trimmed to length prop wrapped in Tooltup with followCursor and interactive props
 */
const TrimmedText = ({ text = '', length = 12 }: Props) => {
  let tooltipText = '';
  let displayText = text;
  if (text?.length > length && text?.length !== length + 1) {
    tooltipText = text;
    displayText = `${text?.slice(0, length)}...`;
  }

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <Tooltip followCursor title={tooltipText ? <span onClick={stopPropagation}>{tooltipText}</span> : ''}>
      <span>{displayText}</span>
    </Tooltip>
  );
};

export default memo(TrimmedText);
