import { FC, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Box, Button, InputAdornment, Tooltip, colors } from '@mui/material';

import { Department } from 'types/Common/Companies';

import { shouldShowDepartmentOnJobCreation } from 'Constants/companies';
import { SectionValidation } from 'Constants/validation';
import DepartmentMaterialAsyncSearch from 'Containers/Components/Controls/DepartmentMaterialAsyncSearch';
import AppInputField from 'components/AppInputField/AppInputField';
import AppPaperModal from 'components/AppPaperModal';
import { useAppSelector } from 'createStore';

export type EditDepartmentFormValues = {
  department: Department;
  section: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: EditDepartmentFormValues) => Promise<void>;
};

const validationSchema = Yup.object().shape({
  department: Yup.object().required('Department is required'),
  section: SectionValidation.required(),
});

const EditDepartmentAndSectionModal: FC<Props> = ({ open, onClose, onSubmit }) => {
  const job = useAppSelector((state) => state.jobs.job);
  const departments = useAppSelector((state) => state.app.departments);
  const departmentsMap = useAppSelector((state) => state.app.departmentsMap);
  const departmentsSectionsMap = departments.reduce((acc, department) => {
    acc[department.section] = department;
    return acc;
  }, {});

  const { values, errors, isValid, isSubmitting, setFieldValue, handleChange, submitForm } =
    useFormik<EditDepartmentFormValues>({
      initialValues: {
        department: departmentsMap[job.department] || null,
        section: job.section || '',
      },
      onSubmit,
      validationSchema,
    });

  const onSelectDepartments = (departments: Department[]) => {
    const department = departments[0];

    setFieldValue('department', department);
  };

  const isSectionMatchDepartment = useMemo(
    () =>
      !!departmentsSectionsMap[values.section] && departmentsSectionsMap[values.section].id === values.department?.id,
    [values, departmentsSectionsMap]
  );

  const isDepartmentWithSectionExists = useMemo(
    () => !!departmentsSectionsMap[values.section],
    [values.section, departmentsSectionsMap]
  );

  const getFromSectionButtonTooltipTitle = useMemo(() => {
    if (!values.section) {
      return 'Please enter the section first to get the department';
    }
    if (!isDepartmentWithSectionExists) {
      return `No department with preset section ${values.section} exists.`;
    }
    if (!isSectionMatchDepartment) {
      return `Will be applied: ${departmentsSectionsMap[values.section].name}`;
    }
    return 'The section matches with the department preset section.';
  }, [isDepartmentWithSectionExists, isSectionMatchDepartment, departmentsSectionsMap, values.section]);

  const resetSectionTooltipTitle = useMemo(() => {
    if (!values.department) {
      return 'Please select a department first';
    }
    if (!values.department?.section) {
      return `Department ${values.department?.name} does not have a preset section`;
    }
    if (isSectionMatchDepartment) {
      return 'Section already match with the department';
    }
    return `Will be applied: ${values.department?.section} (section of ${values.department?.name})`;
  }, [values.department, isSectionMatchDepartment]);

  return (
    <AppPaperModal
      containerStyle={{ width: '100%', maxWidth: 400 }}
      modalId="edit-department-modal"
      open={open}
      onClose={onClose}
      title={'Edit department'}
      submitButton={{
        title: 'Save',
        onClick: submitForm,
        disabled: !isValid,
        loading: isSubmitting,
      }}
    >
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Box display="flex" alignItems="center" width="100%" position="relative">
          <DepartmentMaterialAsyncSearch
            width="100%"
            departments={[values.department]}
            onSelect={onSelectDepartments}
            searchFilterFunction={shouldShowDepartmentOnJobCreation}
            onlySingleDepartment
            notMulty
            renderInput={(props) => (
              <AppInputField
                {...props}
                fullWidth
                label="Department"
                error={!!errors.department}
                helperText={<>{errors.department}</>}
              />
            )}
          />
          <Tooltip
            sx={{ position: 'absolute', right: 0, top: 0, transform: 'translate(0, -25%)', zIndex: 1 }}
            disableInteractive
            title={getFromSectionButtonTooltipTitle}
          >
            <Box display="flex" justifyContent="center" alignItems="center" width="max-content">
              <Button
                onClick={() => {
                  if (!isDepartmentWithSectionExists) return;

                  if (!isSectionMatchDepartment) {
                    setFieldValue('department', departmentsSectionsMap[values.section]);
                  }
                }}
                disabled={isSectionMatchDepartment || !isDepartmentWithSectionExists}
                size="small"
              >
                Get from Section
              </Button>
            </Box>
          </Tooltip>
        </Box>
        <AppInputField
          value={values.section}
          onChange={handleChange}
          error={!!errors.section}
          helperText={
            errors.section ||
            (!isSectionMatchDepartment &&
              values.department?.section &&
              `Department ${values.department?.name} has a preset section: ${values.department?.section}. If you make a mistake, you can correct the section by clicking the Reset button, or apply a new department by clicking the Get From Section button.`)
          }
          size="small"
          label="Section"
          name="section"
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              ...(!isSectionMatchDepartment && values.department?.section ? { borderColor: colors.orange[800] } : {}),
            },
          }}
          FormHelperTextProps={{
            sx: {
              color: colors.orange[800],
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={resetSectionTooltipTitle} disableInteractive>
                  <Box>
                    <Button
                      onClick={() => setFieldValue('section', values.department?.section)}
                      disabled={isSectionMatchDepartment || !values.department?.section}
                    >
                      Reset
                    </Button>
                  </Box>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </AppPaperModal>
  );
};

export default EditDepartmentAndSectionModal;
