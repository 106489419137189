export const FILTERS = Object.freeze({
  empty_array: 'EMPTY_ARRAY',
  empty_object: 'EMPTY_OBJECT',
});

export const objectValuesFilter = function <T extends Record<string, any>>(
  targetObject: T,
  exclude: (null | string | boolean | number | undefined)[] = [],
  truthy = false
): Partial<T> {
  return Object.entries(targetObject || {}).reduce((acc, [key, value]) => {
    if (truthy && Boolean(value)) {
      return { ...acc, [key]: value };
    }
    if (!exclude.includes(value)) {
      // Exculde empty arrays
      if (Array.isArray(value) && exclude.includes(FILTERS.empty_array) && !value.length) {
        return acc;
      }
      // Exculde empty objects
      if (
        typeof value === 'object' &&
        value !== null &&
        exclude.includes(FILTERS.empty_object) &&
        !Object.keys(value).length
      ) {
        return acc;
      }
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
};
