import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ValidationError } from 'yup';

import { TabContext, TabPanel } from '@mui/lab';
import { MenuItem, Tab, Tabs, TextField, TextFieldProps, styled } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { baseToastConfig } from 'Constants/app';
import { TimeLimitation } from 'Constants/companies';
import { inputDateFormat } from 'Constants/time';
import { maximumYearValue } from 'Constants/validation';
import { ActionTypes, actions } from 'Services';
import { parseErrorMessage } from 'Utils/errorMessage';
import { useWaitTimer } from 'Utils/hooks/useWaitTimer';
import { applyDepartmentLitimations } from 'Utils/validation';
import AppPaperModal from 'components/AppPaperModal';
import { useAppSelector } from 'createStore';

import s from './ExtendEndDate.module.css';

const StyledInput = styled((props: TextFieldProps) => (
  <TextField fullWidth variant="outlined" InputLabelProps={{ shrink: true }} {...props} />
))({
  '& .MuiInputBase-input::placeholder': {
    fontSize: 14,
  },
});

const HOURS = [4, 8, 12];

const TABS = {
  hours: 'hours',
  date: 'date',
} as const;

const STEPS = {
  edit: 'edit',
  confirm: 'confirm',
} as const;

type TSteps = (typeof STEPS)[keyof typeof STEPS];

const initialErrors = { finishDate: '', reason: '' };
const userDateFormat = 'MMMM DD, h:mm A';

const ExtendEndDateComponent = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const processing = useAppSelector((state) => state.jobs.extendWMSEndDate.processing);
  const jobFinishAt = useAppSelector((state) => state.jobs.location_job?.finish_at);

  const jobFinishDate = useMemo(() => moment(jobFinishAt).startOf('minute'), [jobFinishAt]);
  const jobTimeLimitation = useAppSelector((state) => state.jobs.location_job?.time_limitation);

  const [activeTab, setActiveTab] = useState(TABS.hours);
  const [finishDate, setFinishDate] = useState(jobFinishDate.clone().add(HOURS[0], 'hours'));
  const [reason, setReason] = useState('');
  const [errors, setErrors] = useState(initialErrors);
  const [step, setStep] = useState<TSteps>(STEPS.edit);
  const { timer, runTimer, clearTimer } = useWaitTimer(3);

  const formattedTimes = useMemo(() => {
    return {
      minInput: jobFinishDate.format(inputDateFormat),
      minUser: jobFinishDate.format(userDateFormat),
    };
  }, [jobFinishAt]);

  const closeExtendJobTime = () => dispatch({ type: ActionTypes.JobsActionTypes.CLOSE_EXTEND_JOB_TIME });

  const validation = useMemo(
    () =>
      Yup.object().shape({
        time_limitation: Yup.string().notRequired().nullable(),
        finishDate: Yup.date()
          .min(jobFinishDate.toDate(), `Finish date must be later than ${formattedTimes.minUser}`)
          .max(maximumYearValue, 'Finish date is too late')
          .required()
          .when(['time_limitation'], ([time_limitation]: [TimeLimitation], schema: Yup.DateSchema) =>
            time_limitation ? applyDepartmentLitimations('finishDate', time_limitation, schema) : schema
          )
          .typeError('Finish Date is required field'),
        reason: Yup.string().required().label('Reason').trim().min(8, 'Min 8 symbols required'),
      }),
    [jobFinishDate]
  );

  useEffect(() => {
    try {
      validation.validateSync(
        { finishDate, reason, time_limitation: jobTimeLimitation },
        { abortEarly: false, recursive: true }
      );
      setErrors(initialErrors);
    } catch (error) {
      const newErrors =
        (error as ValidationError).inner?.reduce(
          (acc, err) => ({ ...acc, [err.path]: acc[err.path] ? `${acc[err.path]}, ${err.message}` : err.message }),
          { ...initialErrors }
        ) || initialErrors;
      setErrors(newErrors);
    }
  }, [reason, finishDate, jobTimeLimitation]);

  const changeFinishDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFinishDate(moment(e.target.value));
  }, []);

  const changeReason = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setReason(value),
    []
  );

  const goToConfirmStep = useCallback(() => {
    setStep(STEPS.confirm);
    runTimer(3);
  }, [step]);

  const sendNewEndDate = useCallback(async () => {
    try {
      const job = await dispatch(
        actions.JobsActions.extendWMSJobEndDate({ end_time: finishDate.format('YYYY-MM-DD HH:mm:ss'), reason })
      );
      toast.success(
        `End time for the job #${job.id} was extended to ${finishDate.format(userDateFormat)}`,
        baseToastConfig
      );
      closeExtendJobTime();
    } catch (error) {
      toast.error(parseErrorMessage(error), baseToastConfig);
    }
  }, [reason, finishDate, step]);

  const backToEditStep = () => {
    setStep(STEPS.edit);
    clearTimer();
  };

  const hoursOptions = useMemo(
    () =>
      HOURS.map((hour) => ({
        value: jobFinishDate.clone().add(hour, 'hours').format(inputDateFormat),
        label: hour,
      })),
    [jobFinishDate]
  );

  const isEditStep = step === STEPS.edit;

  const finishDateValues = useMemo(
    () => ({ input: finishDate.format(inputDateFormat), forUser: finishDate.format(userDateFormat) }),
    [finishDate]
  );

  return (
    <AppPaperModal
      submitButton={{
        disabled: isEditStep ? Boolean(errors.finishDate || errors.reason) : Boolean(timer),
        onClick: isEditStep ? goToConfirmStep : sendNewEndDate,
        loading: processing,
        title: isEditStep ? 'Extend' : timer ? `Wait...${timer}` : 'Yes. Confirm',
      }}
      cancelButton={isEditStep ? {} : { title: 'Go Back', onClick: backToEditStep }}
      open
      onClose={closeExtendJobTime}
      title={isEditStep ? 'Extend Job Time' : 'Is everything right?'}
      subtitle={isEditStep ? 'Select new end time and write a reason' : 'Please, check all information'}
      containerStyle={{ maxWidth: 500, width: '100%' }}
    >
      <div className={s.form}>
        {isEditStep ? (
          <>
            <TabContext value={activeTab}>
              <Tabs
                value={activeTab}
                variant="fullWidth"
                TabIndicatorProps={{ style: { backgroundColor: '#000' } }}
                style={{ marginTop: -24 }}
                onChange={(_, selectedTab) => setActiveTab(selectedTab)}
              >
                <Tab label="Add Hours" value={TABS.hours} />
                <Tab label="Select Date" value={TABS.date} />
              </Tabs>
              <TabPanel value={TABS.hours} style={{ padding: 0 }}>
                <StyledInput
                  select
                  label="Choose how many hours you need to add"
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'bottom',
                      },
                      transformOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                      },
                    },
                  }}
                  value={hoursOptions.find(({ value }) => value === finishDateValues.input)?.value || ''}
                  onChange={changeFinishDate}
                  helperText={errors.finishDate || `Current Job finish date: ${formattedTimes.minUser}`}
                  error={Boolean(errors.finishDate)}
                >
                  {hoursOptions.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label} hours
                    </MenuItem>
                  ))}
                </StyledInput>
              </TabPanel>
              <TabPanel value={TABS.date} style={{ padding: 0 }}>
                <StyledInput
                  label="Select new date and time"
                  type="datetime-local"
                  contentEditable={false}
                  inputProps={{ min: formattedTimes.minInput }}
                  value={finishDateValues.input}
                  onChange={changeFinishDate}
                  error={Boolean(errors.finishDate)}
                  helperText={errors.finishDate || `Current Job finish date: ${formattedTimes.minUser}`}
                />
              </TabPanel>
            </TabContext>

            <StyledInput
              label="Reason"
              multiline
              value={reason}
              onChange={changeReason}
              minRows={2}
              placeholder="Write reason why you need extend end date"
              error={Boolean(errors.reason)}
              helperText={errors.reason}
            />
            <div className={s.centeredRow} style={{ justifyContent: 'space-between' }}>
              <p>New End Time:</p>
              <h6>{finishDateValues.forUser}</h6>
            </div>
          </>
        ) : (
          <>
            <div className={s.centeredRow}>
              <h6>New End Time:</h6>
              <p>{finishDateValues.forUser}</p>
            </div>

            <div className={s.centeredRow}>
              <h6>Reason:</h6>
              <p>{reason}</p>
            </div>
          </>
        )}
      </div>
    </AppPaperModal>
  );
};

const ExtendEndDate = () => {
  const showExtendJobTime = useAppSelector((state) => state.jobs.extendWMSEndDate.show);
  if (showExtendJobTime) {
    return <ExtendEndDateComponent />;
  }
  return null;
};

export default ExtendEndDate;
