import React from 'react';
import { useDispatch } from 'react-redux';

import CloseIcon from 'Images/close-regular.png';
import { actions } from 'Services';

import CEModal from '../Components/Modal/Modal.Component';

interface Props {
  workerId?: number;
  workerName?: string;
  newStatus?: string;
  onClose: () => void;
}

const ChangeWorkerStatusModal = ({ workerId = 0, workerName = '', newStatus = '', onClose = () => {} }: Props) => {
  const dispatch = useDispatch();

  const changeStatus = () => {
    dispatch(actions.WorkersActions.updateWorkerStatus(workerId, { status: newStatus }));
    onClose();
  };

  if (!workerId) return null;

  return (
    <CEModal show onClose={onClose} size="ce-modal-content">
      <div>
        <div className="ce-flex-right">
          <span className="pull-right" onClick={onClose}>
            <img src={CloseIcon} alt="" />
          </span>
        </div>
        <div className="text-center">
          <div className="m-3">
            <span>
              {`Are you sure to change `}
              <b>{workerName}</b>
              {` to `} <b>{newStatus}</b> ?
            </span>
          </div>
          <div className="d-flex justify-content-between mx-2 mt-40 mb-25">
            <span className="btn ce-btn-modal-save mr-5" onClick={changeStatus}>
              <span>Yes</span>
            </span>
            <span className="btn ce-btn-modal-cancel" onClick={onClose}>
              <span>No</span>
            </span>
          </div>
        </div>
      </div>
    </CEModal>
  );
};

export default React.memo(ChangeWorkerStatusModal);
