import PageTitle from 'Containers/Components/Typography/PageTitle';

import DepartmentsSettings from './components/DepartmentsSettings';
import { ProjectSettingsContainer, ProjectSettingsContent, ProjectSettingsWrapper } from './components/styled';

const ProjectSettings = () => {
  return (
    <ProjectSettingsWrapper>
      <ProjectSettingsContainer>
        <PageTitle>Department(s)</PageTitle>
        <ProjectSettingsContent>
          <DepartmentsSettings />
        </ProjectSettingsContent>
      </ProjectSettingsContainer>
    </ProjectSettingsWrapper>
  );
};

export default ProjectSettings;
