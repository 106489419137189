import { Box, Skeleton } from '@mui/material';

const ProfileModal = () => {
  return (
    <Box pt={2}>
      <Box display="flex" gap={'14px'}>
        <Skeleton variant="rectangular" width={120} height={33} animation="wave" />
        <Skeleton variant="rectangular" width={100} height={33} animation="wave" />
        <Skeleton variant="rectangular" width={190} height={33} animation="wave" />
      </Box>
      <Box mt={3}>
        <Skeleton variant="rectangular" width={74} height={16} />
        <Box display="flex" gap={2} mt={2}>
          <Skeleton variant="circular" width={74} height={74} />
          <Box display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="rectangular" width={162} height={32} animation="wave" />
            <Skeleton variant="rectangular" width={220} height={32} animation="wave" />
          </Box>
        </Box>
        <Box display="flex" gap={2} mt={2}>
          <Box mt={2} display="flex" flexDirection="column" gap={1} width="100%">
            <Skeleton variant="rectangular" width={70} height={20} animation="wave" />
            <Skeleton variant="rectangular" width={'100%'} height={51} animation="wave" />
          </Box>
          <Box mt={2} display="flex" flexDirection="column" gap={1} width="100%">
            <Skeleton variant="rectangular" width={70} height={20} animation="wave" />
            <Skeleton variant="rectangular" width={'100%'} height={51} animation="wave" />
          </Box>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={70} height={20} animation="wave" />
          <Skeleton variant="rectangular" width={'100%'} height={51} animation="wave" />
        </Box>
        <Box mt={2} display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={70} height={20} animation="wave" />
          <Skeleton variant="rectangular" width={'100%'} height={51} animation="wave" />
        </Box>
        <Box mt={2} display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={70} height={20} animation="wave" />
          <Skeleton variant="rectangular" width={'100%'} height={51} animation="wave" />
        </Box>
        <Box mt={2} display="flex" flexDirection="column" gap={1}>
          <Skeleton variant="rectangular" width={70} height={20} animation="wave" />
          <Skeleton variant="rectangular" width={'100%'} height={51} animation="wave" />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileModal;
