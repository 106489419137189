import * as React from 'react';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

import './CheckboxComponent.scss';

interface Props {
  name?: string;
  title?: string;
  checked?: boolean;
  className?: string;
  id: any;
  style?: any;
  color?: string;
  forwardedRef?: React.Ref<HTMLDivElement>;
}

const UncontrolledCheckboxComponent = ({
  title,
  checked,
  className = '',
  id,
  style = {},
  color,
  forwardedRef,
}: Props) => {
  return (
    <div ref={forwardedRef} className={`ce-chk-control ml-0 ` + className} style={{ cursor: 'not-allowed', ...style }}>
      {checked ? (
        <CircleCheckedFilled style={{ ...(color && { fill: color }) }} />
      ) : (
        <CircleUnchecked style={{ color: 'darkgrey' }} />
      )}

      <label
        className="ce-title no-margin"
        style={!checked ? { color: 'darkgrey', cursor: 'not-allowed' } : { cursor: 'not-allowed' }}
        htmlFor={id}
      >
        {title && <span className="ce-ml-10">{title}</span>}
      </label>
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <UncontrolledCheckboxComponent forwardedRef={ref} {...props} />
));
