import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { Box, Button, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';

import { baseToastConfig } from 'Constants/app';
import { JobStatus } from 'Constants/job';
import AttentionModal from 'Containers/Dispatch/dialog/ReconstructJob/AttentionModal';
import { actions } from 'Services';
import useGetTotalHours from 'Utils/hooks/useGetTotalHours';

type Props = {
  jobId: number;
  open: boolean;
  onClose: () => void;
};

const ReleaseWorkersModal = ({ jobId, open, onClose }: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { calculated, error, reCalculate, displayedTotalHours } = useGetTotalHours(JobStatus.COMPLETED, jobId, open);
  const [canReleaseWorkers, setCanReleaseWorkers] = useState(true);

  const releaseWorkers = async () => {
    if (!canReleaseWorkers) return;
    if (calculated && error) {
      try {
        setCanReleaseWorkers(false);
        await new Promise((res, rej) =>
          toast.error(
            <Box>
              <Typography>
                Something went wrong while calculating the total number of hours. Fees may apply if this action is
                further confirmed. Close this for retry
              </Typography>
              <Button variant="contained" color="primary" onClick={res}>
                Confirm anyway
              </Button>
            </Box>,
            { ...baseToastConfig, autoClose: false, onClose: rej, onClick: rej }
          )
        );
      } catch (error) {
        setCanReleaseWorkers(true);
        reCalculate();
        return;
      }
    }
    setCanReleaseWorkers(true);
    onClose();
    try {
      await dispatch(actions.JobsActions.releaseWorkers(jobId));
      toast.success('The workers were released and the job status was set to completed', baseToastConfig);
    } catch (error) {
      toast.error(error, baseToastConfig);
    }
  };

  return (
    <AttentionModal
      alertText={
        <p className="h5 pb-3" style={{ fontFamily: 'Roboto Regular', textAlign: 'center' }}>
          Are you sure you want to release workers and complete the job?
          <span className="pt-2" style={{ display: 'block' }}>
            This job has already started and release workers would invoke a {displayedTotalHours} Hour
          </span>
        </p>
      }
      onSuccess={releaseWorkers}
      onClose={onClose}
      isOpen={open}
    />
  );
};

export default ReleaseWorkersModal;
