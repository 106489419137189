import moment from 'moment';

/**
 *
 * @param {string} start_at Time string when work starts(selected from frontend in local timezone)
 * @param {string} created_at Time string when work was registered on backend(may returned from backend in UTC!!!)
 * @param {boolean} start_at_utc Set to true, if want to convert param start_at from utc before calculation
 * @param {boolean} created_at_utc Set to true, if want to convert param created_at from utc before calculation
 * @returns {Number} difference between start time and created time in minutes
 */
export const getASAPdiff = (start_at, created_at, start_at_utc = false, created_at_utc = false) => {
  const createTime = created_at_utc ? moment.utc(created_at).local().format('YYYY-MM-DD HH:mm:ss') : created_at;
  const requestTime = start_at_utc ? moment.utc(start_at).local().format('YYYY-MM-DD HH:mm:ss') : start_at;

  const diff = Math.abs(moment(createTime).diff(requestTime, 'minutes'));
  return diff;
};
