import { FC, useState } from 'react';

import { on } from 'ifvisible';
import Cropper from 'react-easy-crop';
import async from 'react-select/dist/declarations/src/async/index';

import { Button } from '@mui/material';

import { showErrorMessage } from 'Utils/errorMessage';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import AppPaperModal from 'components/AppPaperModal';

import getCroppedImgFile from './cropImage';

type Props = {
  image: string;
  open: boolean;
  onClose: () => void;
  onCrop: (croppedImageFile: File) => void;
};

const ImageCropper: FC<Props> = ({ image, open, onClose, onCrop }) => {
  const { isMobile } = useAppMediaQuery();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const submit = async () => {
    try {
      const croppedImageFile = await getCroppedImgFile(image, croppedAreaPixels);
      onCrop(croppedImageFile);
      onClose();
    } catch (e) {
      showErrorMessage(e);
    }
  };

  return (
    <AppPaperModal
      open={open}
      onClose={onClose}
      title={'Crop your avatar'}
      containerStyle={{ minWidth: !isMobile ? '50%' : '100%', height: '100%' }}
      contentStyle={{ height: '100%', position: 'relative', margin: '20px 0' }}
      submitButton={{
        onClick: submit,
      }}
    >
      <Cropper
        crop={crop}
        image={image}
        zoom={zoom}
        cropShape="round"
        onZoomChange={setZoom}
        aspect={1 / 1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
      />
    </AppPaperModal>
  );
};

export default ImageCropper;
