import { FC, useCallback, useMemo, useState } from 'react';

import { AutoSizer, Table, Column } from 'react-virtualized';

import { Box, MenuItem, Typography } from '@mui/material';

import { Department } from 'types/Common/Companies';

import { TimeLimitation, TimeLimitationReadableValues } from 'Constants/companies';
import AppInputField from 'components/AppInputField/AppInputField';
import { AppSelect } from 'components/AppSelect/AppSelect';
import AppSwitch from 'components/AppSwitch/AppSwitch';
import TextOverflowTooltip from 'components/TextOverflowTooltip';
import { useAppSelector } from 'createStore';

import DepartmentActions from './DepartmentActions';

type Props = {
  departments: Department[];
  changedDepartments: { [key: number]: Department };
  onChangeDepartment: (id: number, update: Department | null) => void;
  onSaveChanges: (id: number, update: Department) => void;
  isGroup: boolean;
  loading: boolean;
};

const TableSettingsWidths = {
  rowHeight: 76,
  id: 50,
  name: 260,
  job_time_limitation: 126,
  ot_break: 84,
  alert_after_minutes: 120,
  alert_message: 200,
  priority: 110,
  actions: 220,
} as const;

const DepartmentsTable: FC<Props> = ({
  departments,
  changedDepartments,
  onChangeDepartment,
  onSaveChanges,
  isGroup,
  loading,
}) => {
  const [focusedDepartment, setFocusedDepartment] = useState<number | null>(null);

  const departmentsLoading = useAppSelector((state) => state.app.departmentLoading);
  const departmentGroupsLoading = useAppSelector((state) => state.app.departmentGroupsLoading);

  const departmentTableColumns = useMemo(
    () => [
      {
        key: 'id',
        label: '#',
        width: TableSettingsWidths.id,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <>
              {changes && (
                <Box
                  sx={{ pointerEvents: 'none' }}
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  bgcolor="#00000003"
                />
              )}
              <Typography fontSize={13} noWrap>
                {department.id}
              </Typography>
            </>
          );
        },
      },
      {
        key: 'name',
        label: 'Department',
        width: TableSettingsWidths.name,
        cellRenderer: (department: Department) => {
          return (
            <TextOverflowTooltip title={department.name}>
              <Typography fontSize={13} noWrap>
                {department.name}
              </Typography>
            </TextOverflowTooltip>
          );
        },
      },
      {
        key: 'job_time_limitation',
        label: 'Time Limitation',
        visible: !isGroup,
        width: TableSettingsWidths.job_time_limitation,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSelect
              value={{ ...department, ...changes }.job_time_limitation}
              onChange={(event) =>
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  job_time_limitation: event.target.value as TimeLimitation,
                })
              }
            >
              <MenuItem value={TimeLimitation.WithoutLimit} sx={{ textTransform: 'capitalize' }}>
                {TimeLimitationReadableValues[TimeLimitation.WithoutLimit]}
              </MenuItem>
              <MenuItem value={TimeLimitation.OnlyHours} sx={{ textTransform: 'capitalize' }}>
                {TimeLimitationReadableValues[TimeLimitation.OnlyHours]}
              </MenuItem>
            </AppSelect>
          );
        },
      },
      {
        key: 'ot_break',
        label: 'OT Break',
        width: TableSettingsWidths.ot_break,
        visible: !isGroup,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="ot_break"
              checked={!!{ ...department, ...changes }.ot_break}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'skip_invoicing_to_be_billed',
        label: 'Skip Invoicing to be Billed',
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="skip_invoicing_to_be_billed"
              checked={!!{ ...department, ...changes }.skip_invoicing_to_be_billed}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'create_integration_jobs',
        label: 'Create Integration Jobs',
        visible: !isGroup,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="create_integration_jobs"
              checked={!!{ ...department, ...changes }.create_integration_jobs}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'show_on_create_job_page',
        label: 'Show on Create Job Page',
        visible: !isGroup,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="show_on_create_job_page"
              checked={!!{ ...department, ...changes }.show_on_create_job_page}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'enforce_flagging_end_time',
        label: 'Enforce Flagging End Time',
        visible: !isGroup,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="enforce_flagging_end_time"
              checked={!!{ ...department, ...changes }.enforce_flagging_end_time}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'enforce_parking_end_time',
        label: 'Enforce Parking End Time',
        visible: !isGroup,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="enforce_parking_end_time"
              checked={!!{ ...department, ...changes }.enforce_parking_end_time}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'enforce_standby_end_time',
        label: 'Enforce Standby End Time',
        visible: !isGroup,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppSwitch
              name="enforce_standby_end_time"
              checked={!!{ ...department, ...changes }.enforce_standby_end_time}
              onChange={({ target: { name, checked } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: checked,
                });
              }}
            />
          );
        },
      },
      {
        key: 'alert_after_minutes',
        label: 'Alert After Minutes',
        visible: !isGroup,
        width: TableSettingsWidths.alert_after_minutes,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          const ALERT_TIMES = [0, 5, 10, 15, 30, 60, 120];
          return (
            <AppSelect
              displayEmpty
              value={{ ...department, ...changes }.alert_after_minutes}
              sx={{ textTransform: 'none' }}
              onChange={(event) =>
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  alert_after_minutes: event.target.value as number,
                })
              }
            >
              <MenuItem value={null}>None</MenuItem>
              {ALERT_TIMES.map((value) => (
                <MenuItem key={`alert-${value}`} value={value}>{`${value} min`}</MenuItem>
              ))}
            </AppSelect>
          );
        },
      },
      {
        key: 'alert_message',
        label: 'Alert Message',
        visible: !isGroup,
        width: TableSettingsWidths.alert_message,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppInputField
              size="small"
              fullWidth
              multiline
              placeholder="No alert message"
              rows={focusedDepartment === department.id ? 2 : 1}
              sx={{
                textarea: {
                  transition: 'all 0.15s ease-in-out',
                  bgcolor: 'white',
                },
              }}
              value={{ ...department, ...changes }.alert_message || ''}
              onBlur={() => setFocusedDepartment(null)}
              onFocus={() => setFocusedDepartment(department.id)}
              onChange={(event) =>
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  alert_message: event.target.value,
                })
              }
            />
          );
        },
      },
      {
        key: 'priority',
        label: 'Priority',
        visible: !isGroup,
        width: TableSettingsWidths.priority,

        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          return (
            <AppInputField
              size="small"
              inputProps={{ type: 'number', min: 0 }}
              name="priority"
              value={{ ...department, ...changes }.priority.toString().trim() || 0}
              onChange={({ target: { name, value } }) => {
                onChangeDepartment(department.id, {
                  ...department,
                  ...changes,
                  [name]: value ? parseInt(value) : 0,
                });
              }}
            />
          );
        },
      },
      {
        key: 'actions',
        label: '',
        width: TableSettingsWidths.actions,
        cellRenderer: (department: Department) => {
          const changes = changedDepartments[department.id];
          if (!changes) return;
          return (
            <DepartmentActions
              onSubmit={() => onSaveChanges(department.id, changes)}
              onCancel={() => onChangeDepartment(department.id, null)}
              loading={loading}
            />
          );
        },
      },
    ],
    [changedDepartments, onChangeDepartment, loading, isGroup, focusedDepartment]
  );

  const tableWidth = useMemo(
    () => departmentTableColumns.reduce((acc, { width = 160, visible = true }) => (visible ? acc + width : acc), 0),
    [departmentTableColumns]
  );

  const getDepartmentBackgroundColor = useCallback(
    (index: number) => {
      const isDepartmentChanged = !!changedDepartments[departments[index]?.id];
      return isDepartmentChanged ? '#F2F3F7B2' : 'transparent';
    },
    [changedDepartments, departments]
  );

  const headerRowRenderer = useCallback(() => {
    return (
      <Box width={tableWidth} display="flex" alignItems="center" paddingX="25px" height={TableSettingsWidths.rowHeight}>
        {departmentTableColumns.map(
          ({ key, visible = true, label, width = 160 }) =>
            visible && (
              <TextOverflowTooltip key={key} title={label}>
                <Typography
                  key={key}
                  minWidth={width}
                  fontSize={13}
                  fontFamily={'Poppins Medium'}
                  sx={{ flex: `0 1 ${width}px`, px: '12px' }}
                  noWrap
                >
                  {label}
                </Typography>
              </TextOverflowTooltip>
            )
        )}
      </Box>
    );
  }, [departmentTableColumns, tableWidth]);

  return (
    <Box
      display="flex"
      borderRadius="16px"
      height="100%"
      sx={{
        overflowY: 'hidden',
        bgcolor: 'white',
      }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={Math.max(width, tableWidth)}
            rowHeight={TableSettingsWidths.rowHeight}
            rowStyle={({ index }) => {
              return {
                padding: '0 25px',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #F2F2F2',
                backgroundColor: getDepartmentBackgroundColor(index),
                overflow: 'clip',
              };
            }}
            headerHeight={TableSettingsWidths.rowHeight}
            rowCount={departments.length}
            rowGetter={({ index }) => departments[index]}
            headerRowRenderer={headerRowRenderer}
          >
            {departmentTableColumns.map(
              ({ key, visible = true, width = 160, cellRenderer }) =>
                visible && (
                  <Column
                    style={{
                      padding: '0 12px',
                      overflow: 'visible',
                    }}
                    key={key}
                    dataKey={key}
                    width={width}
                    minWidth={width}
                    cellRenderer={({ rowData }: { rowData: Department }) => cellRenderer(rowData)}
                  />
                )
            )}
          </Table>
        )}
      </AutoSizer>

      {(departmentsLoading || departmentGroupsLoading || !departments.length) && (
        <Typography variant={'body1'} color={'#828282'} textAlign={'center'} marginTop={'70px'} marginBottom={'20px'}>
          {departmentsLoading
            ? 'Loading departments...'
            : departmentGroupsLoading
            ? 'Loading department groups...'
            : 'No departments found'}
        </Typography>
      )}
    </Box>
  );
};

export default DepartmentsTable;
