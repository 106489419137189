import { FC, memo } from 'react';

import { Box, Tab } from '@mui/material';

import { StyledJobDetailsTabs } from 'Containers/Job/styled';
import { GetChatsHistoryResponse } from 'Services/API/JobsAPI.service';
import { UserSolidBold, WorkerIcon } from 'Utils/Icon';

export enum ChatsTabsEnum {
  worker = 'supervisor_worker',
  inspector = 'supervisor_inspector',
}

type Props = {
  activeTab: ChatsTabsEnum;
  onSelectTab: (tab: ChatsTabsEnum) => void;
  chats: GetChatsHistoryResponse;
};

const ChatsTabs: FC<Props> = ({ activeTab, onSelectTab, chats }) => {
  return (
    <Box>
      <StyledJobDetailsTabs value={activeTab} onChange={(_, value) => onSelectTab(value)}>
        <Tab
          icon={<UserSolidBold />}
          iconPosition="start"
          value={ChatsTabsEnum.worker}
          label="Worker"
          disabled={!chats.supervisor_worker}
        />
        <Tab
          icon={<WorkerIcon />}
          iconPosition="start"
          value={ChatsTabsEnum.inspector}
          label="CES Supervisor"
          disabled={!chats.supervisor_inspector}
        />
      </StyledJobDetailsTabs>
    </Box>
  );
};

export default memo(ChatsTabs);
