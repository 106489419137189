import moment from 'moment';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

import { TimesheetNames, TimesheetStatus } from 'Constants/timesheet';
import { getIsTimesheetApproved } from 'Containers/Timesheets/utils';
import { FORMATS } from 'Utils/Date';
import UserPermissions from 'Utils/PermissionsHelper';
import useModal from 'Utils/hooks/useModal';
import AppPaperModal from 'components/AppPaperModal';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';

import styles from './FormSubmitButtons.module.scss';

const FormSubmitButtons = ({ saving = false, saveEnabled = false, onCancel = () => {} }) => {
  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;
  const userCanEditTimesheet = UserPermissions.has.edit_timesheets;
  const timesheetIsCancelled = timesheet?.status === TimesheetStatus.cancelled;
  const timesheetIsClockedIn = timesheet?.status === TimesheetStatus.clockedIn;
  const userCanAdjustCEVerifiedTimesheets = UserPermissions.has.adjust_ces_verified_timesheets;
  const userCanSignTimesheet = UserPermissions.has.sign_timesheet;
  const confirmationModal = useModal();
  const adminConfirmModal = useModal();
  const ConEdVerified = getIsTimesheetApproved(timesheet);

  const openSubmitModal = () => {
    if (userCanAdjustCEVerifiedTimesheets) {
      if (ConEdVerified) {
        adminConfirmModal.open();
        return;
      }
    }
    if (userCanSignTimesheet && !timesheetIsClockedIn) {
      confirmationModal.open();
      return;
    }
    formikBag.submitForm();
  };

  return (
    <div className={styles.timesheetEditControls}>
      <Button onClick={onCancel} size="large" style={{ width: 145, height: 45 }}>
        Cancel
      </Button>
      {userCanEditTimesheet && !timesheetIsCancelled && (
        <>
          <LoadingButton
            variant={'contained'}
            onClick={openSubmitModal}
            loading={!!saving}
            disabled={!formikBag.isValid || !saveEnabled}
            style={{ width: 145, height: 45 }}
            size="large"
          >
            Save
          </LoadingButton>
          {confirmationModal.isOpen && (
            <AppPaperModal
              modalId={'confirm-timesheet-changes-save'}
              open={confirmationModal.isOpen}
              onClose={confirmationModal.close}
              title={'Verify your changes'}
              submitButton={{
                title: 'Verify',
                onClick: formikBag.submitForm,
                loading: saving,
              }}
            >
              <div style={{ maxWidth: 430 }}>
                <p>Saving these changes will mark this timesheet as ConEd verified, no further changes can be made. </p>
                <p>
                  Please confirm, the hours are{' '}
                  <b>
                    {moment(formikBag.values[TimesheetNames.startDate]).format(FORMATS.timesheetDate)} -{' '}
                    {moment(formikBag.values[TimesheetNames.finishDate]).format(FORMATS.timesheetDate)}
                  </b>
                  , for a total of <b>{formikBag.values[TimesheetNames.totalHours].toFixed(2)}</b>
                </p>
              </div>
            </AppPaperModal>
          )}
          {adminConfirmModal.isOpen && (
            <AppPaperModal
              modalId={'confirm-timesheet-changes-save-admin'}
              title={'"ConEd Verified" is checked'}
              open={adminConfirmModal.isOpen}
              onClose={adminConfirmModal.close}
              submitButton={{
                title: 'Save Anyway',
                onClick: formikBag.submitForm,
                loading: saving,
              }}
            >
              <div style={{ maxWidth: 430 }}>
                <span>
                  You have NOT unchecked <b>“ConEd Verified“</b>, no notifications will be sent.
                </span>
              </div>
            </AppPaperModal>
          )}
        </>
      )}
    </div>
  );
};

export default FormSubmitButtons;
