import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import moment from 'moment';
import { MdDelete, MdEdit, MdCheck, MdClose } from 'react-icons/md';

import { Button, ClickAwayListener, IconButton } from '@mui/material';

import { Comment } from 'types/Timesheet';

import { TimesheetStatus } from 'Constants/timesheet';
import { parseCommentAuthorFullName } from 'Containers/Timesheets/utils';
import UserPermissions from 'Utils/PermissionsHelper';
import AppInput from 'components/AppInput';
import { ReduxState, useAppSelector } from 'createStore';

import styles from '../TimesheetComments.module.scss';

interface Props {
  onDeleteComment: (comment: Comment) => void;
  onUpdateComment: () => void;
  onChangeComment: (id: number, comment: string) => void;
  onCloseComment: () => void;
  willUpdatedComment: { id: number; comment: string };
}

const CommentsList: React.FC<Props> = (props) => {
  const comments = useSelector((state: ReduxState) => state.comments.comments);
  const timesheet = useSelector((state: ReduxState) => state.timesheets.timesheet);
  const isTimesheetCancelled = timesheet?.status === TimesheetStatus.cancelled;
  const user = useAppSelector((state) => state.app.user);
  const userCanEditAnyTimesheetComment = UserPermissions.has.edit_any_timesheet_comment;
  const processing = useAppSelector((state) => state.comments.processing);
  const processing_update = useAppSelector((state) => state.comments.processing_update);
  const [shortList, setShortList] = useState<boolean>(comments.length > 2);

  const shortListToggle = () => setShortList((prev) => !prev);
  const isUserAuthor = (comment: Comment) => comment.added_by_user_id === user.id;
  const commentAuthor = (comment: Comment) => {
    if (isUserAuthor(comment)) {
      return 'You';
    }
    return parseCommentAuthorFullName(comment);
  };
  const isCommentUpdated = (comment: Comment) => comment.created_at !== comment.updated_at;
  const commentDate = (comment: Comment) => moment(comment.created_at).format('[Added] MMM, DD [at] h:mm a');
  const modifiedStatus = (comment: Comment) =>
    isCommentUpdated(comment) ? moment(comment.updated_at).format('[(modified] MMM, DD [at] h:mm a[)]') : '';
  const canEditComment = (comment: Comment) => userCanEditAnyTimesheetComment || isUserAuthor(comment);
  const isCommentEditing = (comment: Comment) => props.willUpdatedComment.id === comment.id;

  useEffect(() => {
    shortList && setShortList(comments.length > 2);
  }, [comments]);

  return (
    <div className={classNames(styles.commentsList, props.willUpdatedComment.id && styles.commentsList__blured)}>
      {comments
        .filter((comment, index) => (shortList ? index < 2 : true))
        .map((comment: Comment, index) => (
          <ClickAwayListener key={comment?.id || index} onClickAway={props.onCloseComment}>
            <div
              className={classNames(
                styles.comment,
                !processing && !processing_update && isCommentEditing(comment) && styles.comment__editing,
                props.willUpdatedComment.id && styles.comment__blured
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <AppInput
                inputProps={{ className: styles.comment__input }}
                inputRef={(input) => input && input.focus()}
                focused={isCommentEditing(comment) && !processing_update && !processing}
                color={isCommentEditing(comment) ? 'primary' : 'secondary'}
                label={commentAuthor(comment) + ':'}
                onChange={(event) => props.onChangeComment(comment.id, event.target.value)}
                disabled={!isCommentEditing(comment)}
                fullWidth
                multiline
                name="edit-comment"
                value={isCommentEditing(comment) ? props.willUpdatedComment.comment : comment.body ?? comment.comment}
              />
              <p className={styles.date}>{`${commentDate(comment)} ${modifiedStatus(comment)}`}</p>
              {!isTimesheetCancelled && canEditComment(comment) && (
                <div className={styles.commentActions}>
                  {isCommentEditing(comment) ? (
                    <>
                      <IconButton onClick={props.onUpdateComment} size="small">
                        <MdCheck />
                      </IconButton>
                      <IconButton onClick={props.onCloseComment} size="small">
                        <MdClose />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => props.onChangeComment(comment.id, comment.comment ?? comment.body)}
                        size={'small'}
                      >
                        <MdEdit />
                      </IconButton>

                      <IconButton onClick={() => props.onDeleteComment(comment)} size="small">
                        <MdDelete />
                      </IconButton>
                    </>
                  )}
                </div>
              )}
            </div>
          </ClickAwayListener>
        ))}
      {comments.length > 2 && (
        <Button className={styles.showMoreButton} onClick={shortListToggle} color="primary" size="small">
          {shortList ? 'Show more' : 'Less'}
        </Button>
      )}
    </div>
  );
};

export default CommentsList;
