import { FC, memo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import moment from 'moment';
import { Props } from 'react-select';

import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import grey from '@mui/material/colors/grey';

import { MasterTimesheetItem } from 'types/Timesheet';

import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import { FORMATS } from 'Utils/Date';
import { LoadIcon, SortTableIcon } from 'Utils/Icon';
import useSearchSortParams from 'Utils/hooks/useSearchSortParams';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';

import { MasterTimesheetContentBox, StyledTable } from '../../styled';

const TableColumns: {
  key: keyof MasterTimesheetItem;
  label: string;
  bodyCell: (row: MasterTimesheetItem) => string | number | JSX.Element;
  width?: string;
}[] = [
  {
    key: 'id',
    label: 'Shift ID',
    bodyCell: (row) => row.id,
  },
  {
    key: 'worker_name',
    label: 'Name',
    bodyCell: (row) => (
      <Typography fontSize={13} fontFamily={'Poppins Medium'} color={'primary.main'}>
        {row.worker_name}
      </Typography>
    ),
  },
  {
    key: 'images',
    label: 'Images',
    bodyCell: (row) => (
      <Box width={250} display="flex" flexWrap="wrap" gap={'2px'}>
        {row.images?.length ? (
          <ImageViewer images={row.images} size="small" showImagesRow />
        ) : (
          <Typography color="text.secondary">No images attached</Typography>
        )}
      </Box>
    ),
  },
  {
    key: 'pdfs',
    label: 'PDFs',
    bodyCell: (row) => (
      <Box display="flex">
        {row.pdfs?.length ? (
          row.pdfs.map((pdf, index) => (
            <Tooltip
              key={`pdf-by-${pdf.user?.id}-${index}`}
              title={`Loaded ${moment(pdf.updated_at).format(FORMATS.masterTimesheetDateTimePicker)} ${
                pdf.user?.name ? 'by ' + pdf.user?.name : ''
              }`}
              disableInteractive
            >
              <Button
                endIcon={<LoadIcon />}
                color="secondary"
                sx={{ p: '0 10px', textTransform: 'none' }}
                target="_blank"
                href={pdf.url}
                download
              >
                Doc #{index + 1}
              </Button>
            </Tooltip>
          ))
        ) : (
          <Typography color="text.secondary">No files attached</Typography>
        )}
      </Box>
    ),
    width: '100%',
  },
];

const MasterTimesheetAttachments: FC<Props> = (props) => {
  const history = useHistory();
  const { values } = useMasterTimesheetContext();
  const { sortedData, order_by, order, setSort } = useSearchSortParams(values?.timesheets);

  const goToTimesheetEditPage = (id: number) => {
    history.push(`/timesheets/${id}/edit`);
  };

  return (
    <MasterTimesheetContentBox overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            {TableColumns.map((column) => (
              <TableCell key={column.key}>
                <TableSortLabel
                  key={column.key}
                  active={order_by === column.key}
                  direction={order}
                  IconComponent={SortTableIcon}
                  onClick={() => setSort(column.key)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: grey[50],
                },
              }}
              onClick={() => goToTimesheetEditPage(row.id)}
            >
              {TableColumns.map((column) => (
                <TableCell key={column.key} width={column.width || 'auto'}>
                  {column.bodyCell(row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </MasterTimesheetContentBox>
  );
};

export default memo(MasterTimesheetAttachments);
