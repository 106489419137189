import React from 'react';

import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

import { UsersAPI } from 'Services/API';

interface Props {
  onSelect?: (item: any) => void;
  width?: any;
  limitTags?: any;
  noLabel?: boolean;
  searchParams?: {};
  value?: any;
  departmentId?: any;
  isMulty?: boolean;
  disabled?: boolean;
  inputSize?: 'small' | 'medium';
}

export default function RequestorMaterialAsyncSearch({
  onSelect,
  width,
  value,
  limitTags,
  noLabel,
  isMulty,
  searchParams,
  departmentId,
  disabled,
  inputSize = 'small',
}: Props) {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>([]);
  const loading = open && users.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async (value = '') => {
      const response = await UsersAPI.getRequestors({
        ...searchParams,
        firstName: value,
        department_id: departmentId ? departmentId : '',
      });
      if (response) {
        const requestors = response.results.map((requestor) => ({
          label: requestor.name,
          value: requestor,
        }));
        setUsers(requestors);
      }
    })();
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setUsers([]);
    }
  }, [open]);

  return (
    <Autocomplete
      multiple={isMulty}
      limitTags={limitTags ? limitTags : 1}
      size={inputSize}
      defaultValue={[]}
      value={value}
      id="asynchronous-demo"
      style={{ width, minWidth: 150 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => value.value && option?.value?.id === value.value?.id}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      renderOption={(props, option) => (
        <li {...props} key={option.value.id}>
          <span>{option.label}</span>
        </li>
      )}
      options={users}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={noLabel ? '' : 'Requestors'}
          placeholder="Requestors"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
      onChange={(event, value) => onSelect(value)}
      disabled={disabled}
    />
  );
}
