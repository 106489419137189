type RecursiveTransformedElements<O, C extends (value: string) => ReturnType<C>> = {
  [K in keyof O]: O[K] extends string ? ReturnType<C> : RecursiveTransformedElements<O[K], C>;
};

export const recursiveConvertIDs = <O, C extends (value: string) => ReturnType<C>>(
  obj: O,
  cyCallback: C
): RecursiveTransformedElements<O, C> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!['string', 'object'].includes(typeof value)) {
      throw new Error('Value must be a string or object');
    }

    if (typeof value === 'string' && typeof cyCallback === 'function') {
      return {
        ...acc,
        [key]: cyCallback(value),
      };
    }
    return {
      ...acc,
      [key]: recursiveConvertIDs(value, cyCallback),
    };
  }, {} as RecursiveTransformedElements<O, C>);
};
