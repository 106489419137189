import { ToastOptions } from 'react-toastify';

export const baseToastConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const GOOGLE_MAP_PLACE_LINK = 'http://www.google.com/maps/place/';

export const EnvVariables = Object.freeze({
  GoogleMapApiKey: import.meta.env.REACT_APP_GOOGLE_MAP_API_KEY,
  MapCenterLat: import.meta.env.REACT_APP_MAP_CENTER_LAT,
  MapCenterLng: import.meta.env.REACT_APP_MAP_CENTER_LNG,
});
