/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppThunkDispatch } from 'types';
import { CreateEditSubcontractor, ResponseSubcontractorItem } from 'types/Subcontractors';

import { TestIDs, addTestID } from 'Constants/tests';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';

import AddSubcontractorDialog from './AddSubcontractorDialog';
import SubcontractorsTable from './SubcontractorsTable';

const Subcontractors = () => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const retrieveUsers = () => dispatch(actions.SubcontractorsActions.retrieve());
  const createSubcontractor = (values) => dispatch(actions.SubcontractorsActions.createSubcontractor(values));
  const updateSubcontractor = (id: number, values: CreateEditSubcontractor) =>
    dispatch(actions.SubcontractorsActions.updateSubcontractor(id, values));

  const [isToggleModal, setIsToggleModal] = useState(false);

  const [editSubcontId, setEditSubcontId] = useState(0);

  const [data, setData] = useState<CreateEditSubcontractor>(null);

  const resetData = () => setData(null);

  const showModal = () => {
    setIsToggleModal(true);
  };

  const closeModal = () => {
    setIsToggleModal(false);
    setEditSubcontId(0);
  };

  const handleEdit = (subcontractorData: ResponseSubcontractorItem = null) => {
    if (!subcontractorData) return;
    const { name, color, workerIds, id } = subcontractorData;
    const { firstName, lastName, email, phoneNumber } = subcontractorData.subcontractor;
    setData({ firstName, lastName, email, companyColor: color, phoneNumber, companyName: name, workerIds: workerIds });
    setEditSubcontId(id);
    showModal();
  };

  const submitSubcontractor = async (values: CreateEditSubcontractor) => {
    try {
      if (editSubcontId) {
        await updateSubcontractor(editSubcontId, values);
      } else {
        await createSubcontractor(values);
      }
      const successMessage = `Subcontractor ${editSubcontId ? 'updated' : 'created'}!`;
      showSuccessMessage(successMessage);
      retrieveUsers();
      closeModal();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <div
      className="subcontractors-list-page sub-contractor-page"
      style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
    >
      <div className="timesheets-page-header">
        <div className="page-title">Subcontractors</div>
        <button
          type="button"
          {...addTestID(TestIDs.pages.subcontractors.buttons.addNewSubcontractor)}
          className="btn btn-success btn-add"
          onClick={() => showModal()}
        >
          Add New
        </button>
      </div>
      <SubcontractorsTable onRowClick={handleEdit} />
      {isToggleModal && (
        <AddSubcontractorDialog
          isEdit={editSubcontId}
          subcontractor={data}
          open={isToggleModal}
          onClose={closeModal}
          submit={submitSubcontractor}
          resetData={resetData}
        />
      )}
    </div>
  );
};

export default Subcontractors;
