import React, { FC, useMemo } from 'react';

import { ChatMessageStatus } from 'Constants/job';
import { ChatMessage } from 'Services/API/JobsAPI.service';

type Props = {
  message: ChatMessage;
  nextMessageFromSameUser: boolean;
};

const useMessageConfig = ({ message, nextMessageFromSameUser }: Props) => {
  const isRequiredCheckByDispatcher = message.status === ChatMessageStatus.required_check_by_dispatcher;

  return useMemo(() => {
    const borderRadiuses = {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    };

    const colors = {
      background: 'secondary.light',
      text: 'text.primary',
      dateText: 'text.secondary',
    };

    if (isRequiredCheckByDispatcher) {
      colors.background = 'error.dark';
      colors.text = 'white';
      colors.dateText = 'white';

      borderRadiuses.borderBottomLeftRadius = '0';
      borderRadiuses.borderBottomRightRadius = '0';
    }

    if (message.is_coned_supervisor) {
      colors.background = 'primary.main';
      colors.text = 'white';
      colors.dateText = '#ffffff';
    }

    if (!nextMessageFromSameUser) {
      if (message.is_coned_supervisor) {
        borderRadiuses.borderTopRightRadius = '0';
      } else {
        borderRadiuses.borderTopLeftRadius = '0';
      }
    }

    return {
      borderRadiuses,
      textColor: colors.text,
      backgroundColor: colors.background,
      dateTextColor: colors.dateText,
    };
  }, [message, nextMessageFromSameUser]);
};

export default useMessageConfig;
