import classNames from 'classnames';
import { parsePhoneNumber } from 'react-phone-number-input';

import { Alert, AlertTitle, Typography } from '@mui/material';

import defaultAvatar from 'Assets/avatar.png';
import AppPermissions from 'Constants/permissions';
import { TimesheetStatus } from 'Constants/timesheet';
import { useAppSelector } from 'createStore';

import styles from './TimesheetEditHeader.module.scss';

const TimesheetInfo = () => {
  const timesheet = useAppSelector((state) => state.timesheets.timesheet);
  const permissions = useAppSelector((state) => state.app.permissions);
  const canSeeSubcontractors = permissions.includes(AppPermissions.can_see_subcontractors);

  type InfoLine = {
    title: string;
    value: string | number;
    status?: string | number;
  };

  const infoListContent: InfoLine[] = [
    {
      title: 'Shift id',
      value: timesheet?.shiftId,
      status: TimesheetStatus.toUserString(timesheet?.status),
    },
    {
      title: 'Subcontractor',
      value: canSeeSubcontractors && timesheet?.worker?.subcontractorName,
    },
    {
      title: 'Email',
      value: timesheet?.worker?.email,
    },
    {
      title: 'Phone',
      value: parsePhoneNumber(timesheet?.worker?.phoneNumber)?.formatInternational() || timesheet?.worker?.phoneNumber,
    },
  ];

  return (
    <div className={styles.info}>
      <img
        className={styles.avatar}
        src={timesheet.worker.avatar && timesheet.worker.avatar !== 'null' ? timesheet.worker.avatar : defaultAvatar}
        alt="avatar"
      />
      <div className={styles.infoMain}>
        <p className={styles.title}>
          <span>{timesheet.worker.name}</span>
          <span className={timesheet.is_asap && styles.red}>#{timesheet.confirmationNumber}</span>
          {timesheet.job_type}
        </p>
        <ul className={styles.infoLines}>
          {infoListContent.map(
            ({ title, value, status }) =>
              value && (
                <li key={'timesheet_info_line_' + title} className={styles.infoLine}>
                  {title}: <span className={styles.infoTitle}>{value}</span>
                  {status && <span className={classNames(styles.status, styles[timesheet?.status])}>({status})</span>}
                </li>
              )
          )}
          {Boolean(timesheet.pending) && (
            <Alert severity="error" variant="outlined" sx={{ maxWidth: 'max-content' }}>
              <AlertTitle>Timesheet is Pending!</AlertTitle>
              <Typography variant="body2" maxWidth={700}>
                <b>Reason:</b> {timesheet.pending_reason}
              </Typography>
            </Alert>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TimesheetInfo;
