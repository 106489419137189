import { toast } from 'react-toastify';

import { AppThunkAction } from 'types';
import { Mutable } from 'types/utils';

import { baseToastConfig } from 'Constants/app';
import { APPROVE } from 'Constants/user';
import { User } from 'Models/APITypes';
import { UsersAPI } from 'Services/API';
import { retrieve as retrieveSubcontractors } from 'Services/subcontractors/actions';
import { showErrorMessage } from 'Utils/errorMessage';
import { ReduxState } from 'createStore';

import {
  CREATE_USER_ERROR,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_BY_ID_ERROR,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_FILTERS,
  UPDATE_FILTER_OPTIONS,
  UPDATE_SEARCH_VALUE,
  USER_ACTIVATE_EMAIL_ERROR,
  USER_ACTIVATE_EMAIL_REQUEST,
  USER_ACTIVATE_EMAIL_SUCCESS,
  USER_APPROVE_ERROR,
  USER_APPROVE_REQUEST,
  USER_APPROVE_SUCCESS,
} from './actionTypes';

export const FILTERS_STORAGE_KEY = 'users.filters';

//get users
export function retrieveUsers(): any {
  return async function (dispatch, getState) {
    try {
      const processing_key = Math.random();
      dispatch({ type: GET_USERS_REQUEST, processing_key });
      const search_options = getState().users.search_options.asMutable();
      const response = await UsersAPI.getUsers(search_options);
      dispatch({
        type: GET_USERS_SUCCESS,
        users: response.results,
        processing_key,
      });
    } catch (error) {
      dispatch({ type: GET_USERS_ERROR });
    }
  };
}

//get user by id
export function getUserId(id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: GET_USER_BY_ID_REQUEST });
      const response = await UsersAPI.getById(id);
      dispatch({ type: GET_USER_BY_ID_SUCCESS, user: response });
    } catch (error) {
      dispatch({ type: GET_USER_BY_ID_ERROR });
    }
  };
}

// create user
export function createUser(user: User): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: CREATE_USER_REQUEST });
      const response = await UsersAPI.createUser(user);
      dispatch({ type: CREATE_USER_SUCCESS, user: response.user });
      dispatch(retrieveSubcontractors());
      return response;
    } catch (error) {
      dispatch({ type: CREATE_USER_ERROR });
      throw error;
    }
  };
}

// approve user
export function updateApprove(id, approve = APPROVE.waiting): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: USER_APPROVE_REQUEST });
      const response = await UsersAPI.changeUserStatus(id, approve);
      dispatch({ type: USER_APPROVE_SUCCESS, user: { id: id, isApproved: approve } });
      return response;
    } catch (error) {
      dispatch({ type: USER_APPROVE_ERROR });
      throw error;
    }
  };
}

// approve user
export function activateEmail(id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: USER_ACTIVATE_EMAIL_REQUEST });
      const response = await UsersAPI.activateEmail(id);
      dispatch({ type: USER_ACTIVATE_EMAIL_SUCCESS, response: response });
      toast.success('Activation Email was send!', baseToastConfig);
      return response;
    } catch (error) {
      dispatch({ type: USER_ACTIVATE_EMAIL_ERROR });
      showErrorMessage(error);
      throw error;
    }
  };
}

//update filters
export function updateFilters(search_options): any {
  return function (dispatch, getState) {
    const storage_filters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    dispatch({
      type: UPDATE_FILTERS,
      filters: { ...storage_filters, ...search_options },
    });
    dispatch(retrieveUsers());
  };
}

export function updateSearch(search: string): AppThunkAction {
  return function (dispatch) {
    dispatch({ type: UPDATE_SEARCH_VALUE, search });
  };
}

export function updatePagination(
  search_options: Partial<Mutable<ReduxState['users']['filterOptions']>>
): AppThunkAction {
  return function (dispatch) {
    dispatch({ type: UPDATE_FILTER_OPTIONS, filterOptions: search_options });
  };
}

export function deleteUser(id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: DELETE_USER_REQUEST });
      await UsersAPI.deleteUser(id);
      dispatch({ type: DELETE_USER_SUCCESS, user: { id: id } });
      dispatch(retrieveSubcontractors());
    } catch (error) {
      dispatch({ type: DELETE_USER_ERROR });
      throw error;
    }
  };
}
