import React, { FC } from 'react';

import { Moment } from 'moment';

import { styled } from '@mui/material';
import { DateTimePicker, DateTimePickerProps, PickersLayout } from '@mui/x-date-pickers';

import { CalendarIcon } from 'Utils/Icon';
import AppInputField from 'components/AppInputField/AppInputField';

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: '8px',
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
    color: 'text.primary',
    boxShadow: '0px 4px 3.2px 0px #00000010',

    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#00000030',
      },
    },
  },

  '& .MuiOutlinedInput-input': {
    padding: '14px 16px',
    fontSize: '13px',
    height: 20,
    opacity: 1,

    '&::placeholder': {
      color: '#7D98AE',
      opacity: 1,
      fontFamily: 'Poppins Medium, system-ui, sans-serif',
    },

    [theme.breakpoints.down('md')]: {
      padding: '9px 16px',
    },
  },

  '& .MuiOutlinedInput-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },

  '& .MuiOutlinedInput-root.Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E8EDF1',
    '& > legend': {
      maxWidth: 0,
    },
  },

  '& .MuiInputLabel-outlined': {
    position: 'static',
    transform: 'none',
    marginBottom: 10,
    fontSize: '12px',
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
    color: 'text.primary',
    height: 18,

    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },

    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },

  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate(-25%, -50%)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    backgroundColor: 'transparent',
  },

  '& .MuiInputBase-sizeSmall': {
    '& .MuiOutlinedInput-input': {
      padding: '9px 16px',
      fontSize: '13px',
      height: 20,
      opacity: 1,
    },
  },

  '& .MuiIconButton-edgeEnd': {
    marginRight: 0,
  },
}));

const StyledPickersLayout = styled(PickersLayout)(({ theme }) => ({
  '.MuiList-root': {
    scrollbarWidth: 'none',
  },
}));

type Props = DateTimePickerProps<Moment> & {
  error?: string | boolean | React.ReactNode;
  helperText?: string | React.ReactNode;
  fullWidth?: boolean;
};

const AppDateTimePicker: FC<Props> = (props) => {
  return (
    <DateTimePicker
      {...props}
      slots={{
        layout: StyledPickersLayout,
        textField: AppInputField,
        openPickerIcon: CalendarIcon,
        ...props.slots,
      }}
      slotProps={{
        ...props.slotProps,
        textField: {
          sx: {
            width: props.fullWidth ? '100%' : 'auto',
            '& .MuiInputAdornment-positionEnd': {
              position: 'absolute',
              right: 16,
              '& .MuiButtonBase-root': { color: 'text.secondary' },
            },
          },
          error: Boolean(props.error),
          helperText: props.helperText,
          ...props.slotProps?.textField,
        },
      }}
    />
  );
};

export default AppDateTimePicker;
