export default function usePagination(updateFilters: (options: unknown) => void = () => {}) {
  const onChangePage = (event, page: number) => {
    updateFilters({ page });
  };

  const onPerPageChange = (per_page: number): void => {
    updateFilters({ per_page });
  };

  return { onChangePage, onPerPageChange };
}
