import generalStyles from 'general.module.scss';

import { Box } from '@mui/material';

import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import AppInput from 'components/AppInput';
import { useAppSelector } from 'createStore';

const TimesheetJobInfo = () => {
  const timesheet = useAppSelector((state) => state.timesheets.timesheet);
  return (
    <TimesheetBox>
      <Box className={generalStyles.inputsBox}>
        <AppInput label="Department" value={timesheet.departmentName ?? ''} placeholder="–––" disabled />
        <AppInput label="Section #" value={timesheet.section ?? ''} placeholder="–––" disabled />
        <AppInput label="POET #" value={timesheet.poet ?? ''} placeholder="–––" disabled />
        <AppInput label="Work Request #" value={timesheet.workRequest ?? ''} placeholder="–––" disabled />
        <AppInput label="PO #" value={timesheet.po ?? ''} placeholder="–––" disabled />
        <AppInput label="Receipt #" value={timesheet.receipt ?? ''} placeholder="–––" disabled />
      </Box>
    </TimesheetBox>
  );
};

export default TimesheetJobInfo;
