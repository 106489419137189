import { toast } from 'react-toastify';
import Immutable from 'seamless-immutable';

import { FileType } from 'types/Common/App';
import { Department, DepartmentGroup } from 'types/Common/Companies';
import { Role } from 'types/Common/User';

import { NOTIFIABLE_TYPES } from 'Constants/job';
import AppPermissions from 'Constants/permissions';
import { hideMenuWidth } from 'Containers/Menus/constants';
import { NotificationsObject } from 'Containers/Profile/components/UserNotifications';
import { isUserSubcontractor } from 'Utils/user';

import * as actionTypes from './actionTypes';
import appDefaultStates from './app-default-states';

let data = null;
const initialState = Immutable({
  token: '',
  user: appDefaultStates.user,
  roles: [] as Role[],
  rolesMap: {} as Record<string, Role>,
  departments: [] as Department[],
  departmentLoading: false,
  departmentGroups: [] as DepartmentGroup[],
  departmentGroupsLoading: false,
  departmentsMap: {} as Record<string, Department>,
  notifications: {} as NotificationsObject,
  processing: false,
  permissions: [] as AppPermissions[],
  userIsSubcontractor: false,
  logged: false,
  file_types: [] as FileType[],
  appLoading: false,
  dispatchPageConfig: {
    displayType: 'list_map' as 'list_map' | 'list',
    showAllFilters: false,
    smallDisplay: document.body.clientWidth <= hideMenuWidth,
  },
});

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.TOKEN_SUCCESS:
      return state.merge({
        token: action.token,
      });
    /** GET ACCOUNT **/
    case actionTypes.GET_ACCOUNT_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_ACCOUNT_SUCCESS:
      return state.merge({
        processing: false,
        user: action.user,
        userIsSubcontractor: isUserSubcontractor(action.user),
      });
    case actionTypes.GET_ACCOUNT_ERROR:
      return state.merge({
        processing: false,
        user: appDefaultStates.user,
      });
    /** LOGIN **/
    case actionTypes.LOGIN_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.LOGIN_SUCCESS:
      localStorage.setItem('Logged', JSON.stringify(true));
      localStorage.setItem('Token', JSON.stringify(action.response.token));
      localStorage.setItem('CurrentUser', JSON.stringify(action.response.user));
      const user = action.response.user;
      return state.merge({
        processing: false,
        user: user,
        userIsSubcontractor: isUserSubcontractor(user),
        logged: true,
        token: action.response.token,
      });
    case actionTypes.LOGIN_ERROR:
      return state.merge({
        processing: false,
        user: appDefaultStates.user,
      });

    case actionTypes.GET_FILE_TYPES_SUCCESS:
      return state.merge({
        file_types: action.file_types,
      });

    case actionTypes.GET_ROLES_SUCCESS:
      return state.merge({
        roles: action.roles,
        rolesMap: action.roles.reduce((acc, role) => {
          acc[String(role.id)] = role;
          return acc;
        }, {}),
      });
    case actionTypes.GET_PERMISSIONS_SUCCESS:
      return state.merge({
        permissions: action.permissions,
      });
    case actionTypes.GET_DEPARTMENTS_REQUEST:
      return state.merge({ departmentLoading: true });
    case actionTypes.GET_DEPARTMENTS_SUCCESS:
      return state.merge({
        departments: action.departments,
        departmentsMap: action.departments.reduce((acc, department) => {
          acc[department.id] = department;
          return acc;
        }, {}),
        departmentLoading: false,
      });
    case actionTypes.GET_DEPARTMENTS_ERROR:
      return state.merge({ departmentLoading: false });
    case actionTypes.GET_DEPARTMENT_GROUPS_REQUEST:
      return state.merge({ departmentGroupsLoading: true });
    case actionTypes.GET_DEPARTMENT_GROUPS_SUCCESS:
      return state.merge({
        departmentGroups: action.departmentGroups,
        departmentGroupsLoading: false,
      });

    case actionTypes.GET_DEPARTMENT_GROUPS_ERROR:
      return state.merge({ departmentGroupsLoading: false });

    case actionTypes.GET_NOTIFICATIONS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return state.merge({
        processing: false,
        notifications: action.notifications,
      });
    case actionTypes.GET_NOTIFICATIONS_ERROR:
      return state.merge({
        processing: false,
      });
    case actionTypes.UPDATE_NOTIFICATIONS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.UPDATE_NOTIFICATIONS_SUCCESS:
      return state.merge({
        processing: false,
        notifications: action.notifications,
      });
    case actionTypes.UPDATE_NOTIFICATIONS_ERROR:
      return state.merge({
        processing: false,
      });
    case actionTypes.UPDATE_USER_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.UPDATE_USER_SUCCESS:
      return state.merge({
        processing: false,
        user: action.user,
        userIsSubcontractor: isUserSubcontractor(action.user),
      });
    case actionTypes.UPDATE_USER_ERROR:
      return state.merge({
        processing: false,
      });

    case actionTypes.SOCKET_NOTIFICATIONS:
      data = JSON.parse(action.data);
      switch (data.notifiableType) {
        case NOTIFIABLE_TYPES.CREATE_JOB:
          toast.success(
            <span
              dangerouslySetInnerHTML={{ __html: data.message ? data.message : data.notifiableGroup.message }}
            ></span>
          );
          break;
        case NOTIFIABLE_TYPES.CANCEL_JOB:
          toast.warn(
            <span
              dangerouslySetInnerHTML={{ __html: data.message ? data.message : data.notifiableGroup.message }}
            ></span>
          );
          break;

        default:
          toast.info(
            <span
              dangerouslySetInnerHTML={{ __html: data.message ? data.message : data.notifiableGroup.message }}
            ></span>
          );
          break;
      }
      return state;
    case actionTypes.CHANGE_DISPATCH_PAGE_CONFIG:
      return state.merge({
        dispatchPageConfig: { ...state.dispatchPageConfig, ...action.payload },
      });
    case actionTypes.APP_INIT:
      return state.merge({ appLoading: true });
    case actionTypes.APP_INIT_FINISHED:
      return state.merge({ appLoading: false });

    case actionTypes.LOGOUT:
      return state.merge(initialState.asMutable({ deep: true }));

    default:
      return state;
  }
}
