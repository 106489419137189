import {
  GrouppedTimesheetsResponse,
  MasterTimesheetType,
  MasterTimesheetUpdatedItem,
  Timesheet,
  TimesheetsResponse,
} from 'types/Timesheet';

import ApiService, { API } from 'Services/HttpService';

export type UpdateMasterTimesheetParams = {
  startDate: string;
  startDateTimesheetIds: number[];
  finishDate: string;
  finishDateTimesheetIds: number[];
  signatures: {
    signature_type_id: number;
    employee_number: string;
    signature_data: string;
  }[];
  sign?: string;
  employeeNumber?: string;
};

export type MasterTimesheetForUpdateResponse = {
  old_total_hours: number;
  old_total_billable_hours: number;
  old_master_start_at: string;
  old_master_finish_at: string;
  new_total_hours: number;
  new_total_billable_hours: number;
  new_master_start_at: string;
  new_master_finish_at: string;
  timesheets: MasterTimesheetUpdatedItem[];
};

class TimesheetsAPI {
  getAll = (params: API.RequestBody) => {
    return ApiService.GET<TimesheetsResponse>('/timesheets', params);
  };

  getGrouppedTimesheets = (params: API.RequestBody) => {
    return ApiService.GET<GrouppedTimesheetsResponse>(`/timesheets/groups`, params);
  };

  getTimesheetById = (id: string | number) => {
    return ApiService.GET<Timesheet>(`/timesheets/${id}`);
  };

  getMasterTimesheet = (confirmation_id: number) => {
    return ApiService.GET<MasterTimesheetType>(`/jobs/${confirmation_id}/timesheets/master`);
  };

  getMasterTimesheetForUpdatesData = (
    confirmation_id: number,
    params: {
      startDate: string;
      finishDate: string;
    }
  ) => {
    return ApiService.GET<MasterTimesheetForUpdateResponse & { status_code: number }>(
      `/jobs/${confirmation_id}/timesheets/master-for-update?${new URLSearchParams(params)}`
    );
  };

  updateMasterTimesheet = (confirmation_id: number, body: UpdateMasterTimesheetParams) => {
    return ApiService.POST_JSON(`/jobs/${confirmation_id}/timesheets/master`, body);
  };

  create = (data: API.Body<'json'>) => {
    return ApiService.POST_JSON('/timesheets', data);
  };

  update = (id: string | number, data: API.Body<'json'>) => {
    return ApiService.PUT_JSON(`/timesheets/${id}`, data);
  };

  delete = (id: string | number) => {
    return ApiService.DELETE(`/timesheets/${id}`);
  };

  uploadImages = (images: FormData) => {
    return ApiService.POST_FDATA(`/timesheets/upload/images`, images);
  };

  reviveTimesheet = (id: string | number) => {
    return ApiService.POST(`/timesheets/${id}/revive`);
  };

  importFromExcel = (excelFile: File) => {
    return ApiService.POST_FDATA('/timesheets/import-excel', { excel: excelFile });
  };

  downloadPdf = (id: string | number) => {
    return ApiService.GET<Blob>(
      `/timesheets/${id}/pdf?tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      {},
      { responseType: 'blob' }
    );
  };

  downloadMultipleAsPdf = (params: API.RequestBody) => {
    return ApiService.GET<Blob>(`/timesheets/pdf`, params, { responseType: 'blob' });
  };

  exportToCSV = (params: API.RequestBody) => {
    return ApiService.GET<Blob>(`/timesheets/export-grid?`, params, { responseType: 'blob' });
  };

  rejectBadTimesheetPhoto = (id: string | number, reason: string) => {
    return ApiService.POST_JSON<{ status_code: number; message: string }>(`/timesheets/${id}/reject-photo`, { reason });
  };

  updateMultiple = (params: {
    timesheet_ids: string[];
    verified?: number;
    worker_paid?: number;
    invoiced?: number;
    paid?: number;
    sign?: string;
    employee_number?: string;
  }) => {
    return ApiService.POST_JSON<{ status_code: number; message: string }>(`/timesheets/edit`, params);
  };

  approveMultipleWithServiceDept = (params: {
    timesheet_ids: string[];
    signatures: { signature_type_id: number; employee_number: string; signature_data: string }[];
  }) => {
    return ApiService.POST_JSON<{ status_code: number; message: string }>(`/timesheets/signatures`, params);
  };
}

export default new TimesheetsAPI();
