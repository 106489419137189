export function fastSort<T>(targetArray: T[] = [], sortBy: keyof T, sortDirection: 'asc' | 'desc' = 'asc') {
  const resultArray = [...targetArray];
  const direction = sortDirection === 'asc' ? 1 : -1;
  resultArray.sort((first, second) => {
    const firstItem = first[sortBy];
    const secondItem = second[sortBy];
    const normalizedFirst = typeof firstItem === 'string' ? firstItem.toLowerCase() : firstItem;
    const normalizedSecond = typeof secondItem === 'string' ? secondItem.toLowerCase() : secondItem;

    if (normalizedFirst > normalizedSecond) {
      return direction;
    }
    if (normalizedFirst < normalizedSecond) {
      return -direction;
    }
    return 0;
  });
  return resultArray;
}
