import { ResponseSubcontractorItem } from 'types/Subcontractors';

import ApiService, { API } from 'Services/HttpService';

class SubcontractorsAPI {
  getAll = (params?: API.RequestBody) => {
    return ApiService.GET<{ results: ResponseSubcontractorItem[] }>(`/subcontractors`, params);
  };

  create = (subcontractorData: API.RequestBody) => {
    return ApiService.POST(`/subcontractors`, subcontractorData);
  };

  update = (id: string | number, subcontractorData: API.Body<'json'>) => {
    return ApiService.PUT_JSON(`/subcontractors/${id}`, subcontractorData);
  };

  delete = (id: string | number) => {
    return ApiService.DELETE(`/subcontractors/${id}`);
  };
}

export default new SubcontractorsAPI();
