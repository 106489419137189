import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Path } from 'history';

import { useAppSelector } from '../../createStore';

interface Props extends RouteProps<Path> {}

const PublicRoute = ({ component: Component, ...routeProps }: Props) => {
  const token = useAppSelector((state) => state.app.token);

  return (
    <Route
      {...routeProps}
      render={(props) =>
        token ? <Redirect to={window.innerWidth < 800 ? '/map' : '/job-grid'} push={false} /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
