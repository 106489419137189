// @ts-check
import Immutable from 'seamless-immutable';

import { ConedUser } from 'types/Common/User';

import { EROLES, USER_STATUSES } from 'Constants/user';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';
import { FILTERS_STORAGE_KEY } from './actions';

const initialState = Immutable({
  search_options: {
    firstName: '',
    email: '',
    phoneNumber: '',
    statuses: [],
    roles: [],
  } as {
    firstName: string;
    email: string;
    phoneNumber: string;
    statuses: string[];
    roles: number[];
  },
  user: {} as ConedUser,
  users: [] as ConedUser[],
  filterOptions: {
    page: 1,
    per_page: 100,
    sortBy: 'name' as keyof ConedUser,
    sortByType: 'asc' as 'desc' | 'asc',
  },
  searchString: '',
  notifications: [],
  processing: false,
  processingUsers: false,
  processing_key: '',
  currentPage: 1,
  user_approved: {},
});

let filters;
let users = [];

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.GET_USER_BY_ID_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_USER_BY_ID_SUCCESS:
      return state.merge({
        processing: false,
        user: action.user,
      });
    case actionTypes.GET_USER_BY_ID_ERROR:
      return state.merge({
        processing: false,
      });
    case actionTypes.GET_USERS_REQUEST:
      return state.merge({
        processingUsers: true,
        processing_key: action.processing_key,
      });
    case actionTypes.GET_USERS_SUCCESS:
      if (action.processing_key === state.processing_key) {
        return state.merge({
          processingUsers: false,
          users: action.users.filter((user: ConedUser) => !user.roles.includes(EROLES.worker)),
          filterOptions: {
            ...state.filterOptions,
            page: 1,
          },
        });
      } else {
        return state;
      }
    case actionTypes.GET_USERS_ERROR:
      return state.merge({
        processingUsers: false,
      });

    // UPDATE FILTER OPTIONS

    case actionTypes.UPDATE_FILTER_OPTIONS:
      return state.merge({
        filterOptions: {
          ...state.filterOptions,
          ...action.filterOptions,
        },
      });

    // UPDATE SEARCH VALUE

    case actionTypes.UPDATE_SEARCH_VALUE:
      return state.merge({
        searchString: action.search,
      });

    // CREATE USER

    case actionTypes.CREATE_USER_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.CREATE_USER_SUCCESS:
      users = state.users.asMutable();
      users.push(action.user);

      return state.merge({
        processing: false,
        users: users,
      });
    case actionTypes.CREATE_USER_ERROR:
      return state.merge({
        processing: false,
      });

    // UPDATE WORKER
    case actionTypes.UPDATE_WORKER_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.UPDATE_WORKER_SUCCESS:
      let workers = state.users.asMutable();
      workers = workers.map((worker) =>
        worker.id === action.worker.id ? { ...action.worker, status: action.worker.status.toLowerCase() } : worker
      );
      return state.merge({
        processing: false,
        user: action.worker,
        users: workers,
      });
    case actionTypes.UPDATE_WORKER_ERROR:
      return state.merge({
        processing: false,
      });

    case actionTypes.USER_APPROVE_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.USER_APPROVE_SUCCESS:
      users = state.users.asMutable();
      users = users.map((user) =>
        user.id === action.user.id
          ? {
              ...user,
              isApproved: action.user.isApproved,
              status: USER_STATUSES[action.user.isApproved],
            }
          : user
      );
      return state.merge({
        processing: false,
        users: users,
      });
    case actionTypes.USER_APPROVE_ERROR:
      return state.merge({
        processing: false,
      });
    case actionTypes.UPDATE_FILTERS:
      filters = {
        ...state.search_options,
        ...action.filters,
      };
      localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));
      return state.merge({
        search_options: filters,
      });

    case actionTypes.DELETE_USER_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.DELETE_USER_SUCCESS:
      users = state.users.asMutable();
      users = users.filter((user) => user.id !== action.user.id);
      return state.merge({
        processing: false,
        users: users,
      });
    case actionTypes.DELETE_USER_ERROR:
      return state.merge({
        processing: false,
      });

    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
