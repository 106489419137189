import { FC, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Box, Fade } from '@mui/material';

import { baseToastConfig } from 'Constants/app';
import { actions } from 'Services';
import { AppAPI } from 'Services/API';
import { AUTH_LOGIN_RESPONSE_TYPE } from 'Services/API/AppAPI.service';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { showSuccessMessage } from 'Utils/successMessage';
import Logo from 'components/Logo/Logo';
import { useAppDispatch } from 'createStore';

import AuthForm, { TwoFactorCodeChannel } from './components/AuthForm';
import SuccessPage from './components/SuccessPage';
import { AuthFormVariant, randomPageImage } from './constants';

const AuthComponent: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { token: resetPasswordToken } = useParams<{ token: string }>();
  const activationAccountToken = new URLSearchParams(history.location.search).get('token');

  const [recoveryMode, setRecoveryMode] = useState(false);
  const [twoFactorAuthLoginData, setTwoFactorAuthLoginData] = useState<{
    email: string;
    password: string;
    codeSent?: boolean;
  }>(null);

  const [recoverySuccessPage, setRecoverySuccessPage] = useState(false);
  const [activationSuccessPage, setActivationSuccessPage] = useState(false);

  const { isDesktop } = useAppMediaQuery();

  const login = async (email: string, password: string) => {
    try {
      const response = await dispatch(actions.AppActions.login({ email, password }));
      if (response.response_type === AUTH_LOGIN_RESPONSE_TYPE.two_factor_auth_enabled) {
        setTwoFactorAuthLoginData({ email, password });
        return;
      }
      history.push('/');
    } catch (e) {
      throw e;
    }
  };

  const twoFactorLogin = async (code: string, channel: TwoFactorCodeChannel) => {
    try {
      const { email, password } = twoFactorAuthLoginData;
      await dispatch(actions.AppActions.twoFactorAuthLogin({ email, password, code, channel }));
      history.push('/');
    } catch (e) {
      throw e;
    }
  };

  const sendTwoFactorCode = async (channel: TwoFactorCodeChannel) => {
    try {
      const { email, password } = twoFactorAuthLoginData;
      const response = await dispatch(actions.AppActions.twoFactorAuthSendCode({ email, password, channel }));
      showSuccessMessage(response.message);
      setTwoFactorAuthLoginData({ ...twoFactorAuthLoginData, codeSent: true });
    } catch (e) {
      throw e;
    }
  };

  const activateAccount = async ({ token, password }: { token: string; password: string }) => {
    try {
      await dispatch(actions.AppActions.activateAccount(token, password));
      showSuccessMessage('Account successfully activated');
      setActivationSuccessPage(true);
    } catch (e) {
      throw e;
    }
  };

  const recoveryPassword = async (email: string) => {
    try {
      await AppAPI.passwordForgot(email);
      toast.info('Recovery email sent. Please check your email', baseToastConfig);
      setRecoverySuccessPage(true);
    } catch (e) {
      throw e;
    }
  };

  const resetPassword = async (resetData: { password: string; token: string }) => {
    try {
      await AppAPI.passwordRestore(resetData);
      showSuccessMessage('Password successfully changed');
      history.push('/login');
    } catch (e) {
      throw e;
    }
  };

  const backToLogin = () => {
    setRecoveryMode(false);
    setTwoFactorAuthLoginData(null);
    history.push('/login');
  };

  const authContentElement = useMemo(() => {
    if (activationSuccessPage) {
      return <SuccessPage variant={AuthFormVariant.ACTIVATE_ACCOUNT} />;
    }

    if (recoverySuccessPage) {
      return <SuccessPage variant={AuthFormVariant.RECOVERY} />;
    }

    if (activationAccountToken) {
      return (
        <AuthForm
          variant={AuthFormVariant.ACTIVATE_ACCOUNT}
          token={activationAccountToken}
          onActivateAccount={activateAccount}
        />
      );
    }

    if (resetPasswordToken) {
      return (
        <AuthForm
          variant={AuthFormVariant.RESET_PASSWORD}
          token={resetPasswordToken}
          onResetPassword={resetPassword}
          onBackToLogin={backToLogin}
        />
      );
    }

    if (recoveryMode) {
      return (
        <AuthForm
          variant={AuthFormVariant.RECOVERY}
          onRecoveryPassword={recoveryPassword}
          onBackToLogin={backToLogin}
        />
      );
    }

    if (twoFactorAuthLoginData) {
      return (
        <AuthForm
          variant={AuthFormVariant.TWO_FACTOR_LOGIN}
          codeSent={twoFactorAuthLoginData.codeSent}
          onTwoFactorLogin={twoFactorLogin}
          onBackToLogin={backToLogin}
          onSendTwoFactorCode={sendTwoFactorCode}
        />
      );
    }

    return <AuthForm variant={AuthFormVariant.LOGIN} onLogin={login} onChangeRecoveryMode={setRecoveryMode} />;
  }, [activationAccountToken, login, recoveryMode, recoveryPassword, resetPasswordToken, twoFactorAuthLoginData]);

  return (
    <Box width="100%" height="100%" display="flex">
      <Box display="flex" flex={1} py={isDesktop ? '60px' : '24px'} overflow="auto">
        <Fade in timeout={300} mountOnEnter>
          <Box display="flex" flexDirection="column" flex={1} maxWidth={440} width="100%" mx="auto" px="16px">
            <Logo />
            {authContentElement}
          </Box>
        </Fade>
      </Box>
      {isDesktop && (
        <Box width="56%" height="100%">
          <Box
            width="100%"
            height="100%"
            component="img"
            sx={{ objectFit: 'cover' }}
            src={randomPageImage}
            alt="Coned Login Page"
          />
        </Box>
      )}
    </Box>
  );
};

export default AuthComponent;
