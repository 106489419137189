import { useCallback, useMemo } from 'react';

import { Settings } from '@mui/icons-material';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { Divider, ListItemIcon, ListItemText, Box, Avatar, Typography, Icon } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';
import { actions } from 'Services';
import * as CeIcon from 'Utils/Icon';
import useMenu from 'Utils/hooks/useMenu';
import app_history from 'app_history';
import { useAppDispatch, useAppSelector } from 'createStore';

import { StyledProfileMenu, StyledProfileMenuButton, StyledProfileMenuItem } from './styled';

const enum ProfileMenuPaths {
  roles = '/roles',
  profile = '/profile',
  notifications = '/notifications',
  projectSettings = '/project-settings',
}

const ProfileMenu = ({ onCloseProfile = () => {} }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.app.user);
  const canSeePage = useAppSelector((state) => state.userPermissions.canSeePage);
  const profileMenu = useMenu();

  const currentPage = app_history.location.pathname;

  const navigateTo = useCallback(
    (path: ProfileMenuPaths) => {
      app_history.push(path);
      profileMenu.close();
      onCloseProfile();
    },
    [onCloseProfile, profileMenu]
  );

  const toRoles = useCallback(() => {
    navigateTo(ProfileMenuPaths.roles);
  }, [navigateTo]);

  const toProfile = useCallback(() => {
    navigateTo(ProfileMenuPaths.profile);
  }, [navigateTo]);

  const toNotification = useCallback(() => {
    navigateTo(ProfileMenuPaths.notifications);
  }, [navigateTo]);

  const toProjectSettings = useCallback(() => {
    navigateTo(ProfileMenuPaths.projectSettings);
  }, [navigateTo]);

  const logout = () => dispatch(actions.AppActions.logout());

  const profileMenuItems: {
    label: string;
    Icon: JSX.Element;
    onClick: () => void;
    dataCy: Record<string, string>;
    dividerBefore?: boolean;
    dividerAfter?: boolean;
    isCurrentPath?: boolean;
    visible?: boolean;
  }[] = useMemo(
    () => [
      {
        label: 'Manage Roles',
        Icon: <CeIcon.GroupUsers />,
        onClick: toRoles,
        visible: canSeePage.roles,
        dividerAfter: true,
        isCurrentPath: currentPage === ProfileMenuPaths.roles,
        dataCy: DatasetTestIDs.components.profileMenu.buttons.manageRoles,
      },
      {
        label: 'Profile Settings',
        Icon: <Settings />,
        onClick: toProfile,
        isCurrentPath: currentPage === ProfileMenuPaths.profile,
        dataCy: DatasetTestIDs.components.profileMenu.buttons.profileSettings,
      },
      {
        label: 'Edit Notifications',
        Icon: <CeIcon.NotificationsIcon />,
        onClick: toNotification,
        isCurrentPath: currentPage === ProfileMenuPaths.notifications,
        dataCy: DatasetTestIDs.components.profileMenu.buttons.editNotifications,
      },
      {
        label: 'Project Settings',
        Icon: <DisplaySettingsIcon />,
        onClick: toProjectSettings,
        isCurrentPath: currentPage === ProfileMenuPaths.projectSettings,
        dataCy: DatasetTestIDs.components.profileMenu.buttons.projectSettings,
        dividerBefore: true,
        visible: canSeePage.projectSettings,
      },
      {
        label: 'Log Out',
        Icon: <CeIcon.Logout />,
        onClick: logout,
        dividerBefore: true,
        dataCy: DatasetTestIDs.components.profileMenu.buttons.logout,
      },
    ],
    [
      canSeePage.projectSettings,
      canSeePage.roles,
      currentPage,
      logout,
      toNotification,
      toProfile,
      toProjectSettings,
      toRoles,
    ]
  );

  return (
    <>
      <Box display="flex" alignItems="center">
        <StyledProfileMenuButton
          onClick={profileMenu.open}
          {...DatasetTestIDs.navBar.profileMenu}
          sx={{
            backgroundColor: profileMenu.isOpen ? 'primary.light' : 'transparent',
            color: profileMenu.isOpen ? 'primary.main' : 'text.secondary',
            '& .MuiIcon-root': {
              transform: profileMenu.isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
              color: profileMenu.isOpen ? 'primary.main' : 'text.secondary',
            },
            '&:hover': {
              backgroundColor: 'secondary.light',
            },
          }}
        >
          <Avatar
            src={user.avatar}
            alt="avatar"
            sx={{
              width: 32,
              height: 32,
              backgroundColor: 'primary.main',
              fontFamily: 'Poppins SemiBold, sans-serif',
              fontSize: '14px',
            }}
          >
            {user.name.at(0)?.toUpperCase()}
          </Avatar>
          <Typography fontSize={14} color={profileMenu.isOpen ? 'primary.main' : 'text.primary'}>
            {user.name}
          </Typography>
          <Icon>
            <CeIcon.KeyLeftIcon />
          </Icon>
        </StyledProfileMenuButton>
      </Box>

      <StyledProfileMenu anchorEl={profileMenu.anchorEl} open={profileMenu.isOpen} onClose={profileMenu.close}>
        {profileMenuItems.map(
          (
            {
              label,
              Icon,
              onClick,
              dataCy,
              dividerBefore = false,
              dividerAfter = false,
              isCurrentPath = false,
              visible = true,
            },
            index
          ) => {
            if (!visible) return null;
            return (
              <Box key={index}>
                {dividerBefore && <Divider />}
                <StyledProfileMenuItem onClick={onClick} isActive={isCurrentPath} {...dataCy}>
                  <ListItemIcon>{Icon}</ListItemIcon>
                  <ListItemText secondary={label} />
                </StyledProfileMenuItem>
                {dividerAfter && <Divider />}
              </Box>
            );
          }
        )}
      </StyledProfileMenu>
    </>
  );
};

export default ProfileMenu;
