import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = {
  processing: false,
};

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.GET_REPORT_REQUEST:
      return { processing: true };
    case actionTypes.GET_REPORT_SUCCESS:
    case actionTypes.GET_REPORT_ERROR:
      return { processing: false };
    case LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
