import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Notifications } from 'types/Notifications';
import { AtLeastOne } from 'types/utils';

import { LOGOUT } from '../app/actionTypes';
import {
  getNotificationsList,
  deleteNotification,
  markNotificationsAsRead,
  getCurrentUserNotifications,
} from './actions';

type NullableString = string | null;

type SearchOptions = Notifications.API.GET_List.Request;

export type UpdateSearchNotificationsOptions = AtLeastOne<Required<SearchOptions>>;

const initialState = {
  notifications: [] as Notifications.Notification[],
  processing: false,
  filtersProcessing: false,
  search_options: {
    limit: 10,
    unread: false,
    cursor: null,
    notifiableType: null,
  } as SearchOptions,
  filtersList: [] as Notifications.Filter[],
  prev_cursor: null as NullableString,
  next_cursor: null as NullableString,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateOptions: (state, action: PayloadAction<UpdateSearchNotificationsOptions>) => {
      state.search_options = { ...state.search_options, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsList.pending, (state) => {
        state.processing = true;
      })
      .addCase(getNotificationsList.fulfilled, (state, action) => {
        state.processing = false;
        state.notifications = action.payload.results;
        state.search_options.limit = action.payload.limit;
        state.next_cursor = action.payload.next_cursor;
        state.prev_cursor = action.payload.prev_cursor;
      })
      .addCase(getNotificationsList.rejected, (state) => {
        state.processing = false;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.processing = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.processing = false;
        state.notifications = state.notifications.filter((notification) => notification.id !== Number(action.meta.arg));
      })
      .addCase(deleteNotification.rejected, (state) => {
        state.processing = false;
      })
      .addCase(markNotificationsAsRead.pending, (state) => {
        state.processing = true;
      })
      .addCase(markNotificationsAsRead.fulfilled, (state, action) => {
        state.processing = false;
        state.notifications = state.notifications.map((notification) => {
          if (notification.id === action.meta.arg.id) {
            return {
              ...notification,
              isRead: 1,
            };
          }
          return notification;
        });
      })
      .addCase(markNotificationsAsRead.rejected, (state) => {
        state.processing = false;
      })
      .addCase(LOGOUT, () => {
        return { ...initialState };
      })
      .addCase(getCurrentUserNotifications.pending, (state) => {
        state.filtersProcessing = true;
      })
      .addCase(getCurrentUserNotifications.fulfilled, (state, action) => {
        state.filtersList = Object.entries(action.payload.notifications).map(([groupName, options]) => ({
          groupName,
          options: options.map(({ id, title }) => ({
            label: title,
            value: id,
          })),
        }));
        state.filtersProcessing = false;
      })
      .addCase(getCurrentUserNotifications.rejected, (state) => {
        state.filtersProcessing = false;
      });
  },
});

export const NotificationActions = slice.actions;

export default slice.reducer;
