import { FC, useEffect, useState, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Box, Chip, Tooltip, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import { Job } from 'types/Job';

import {
  Account_Number_Validation,
  POET_Validation,
  PO_Double_Validation,
  Receipt_Validation,
  Requisition_Validation,
  WR_Validation,
} from 'Constants/validation';
import { UpdatePOSData } from 'Services/API/JobsAPI.service';
import AppPaperModal from 'components/AppPaperModal/AppPaperModal';
import { useAppSelector } from 'createStore';

import OptionalField from '../components/OptionalField';
import './Dialog.scss';

const validationSchema = Yup.object().shape({
  newPo: PO_Double_Validation.notRequired(),
  requisition: Requisition_Validation.notRequired(),
  receipt: Receipt_Validation.notRequired(),
  wr: WR_Validation.notRequired(),
  account_no: Account_Number_Validation.notRequired(),
  poet: POET_Validation.notRequired(),
});

type Props = {
  selectedJobs: Job[];
  open: boolean;
  close: () => void;
  updatePOS: (posData: UpdatePOSData) => void;
  receiptUpdate?: boolean;
};

const UpdatePODialog: FC<Props> = ({ selectedJobs, open, close, updatePOS, receiptUpdate = false }) => {
  const processing = useAppSelector((state) => state.jobs.update_po_number_processing);
  const departments = useAppSelector((state) => state.app.departments);
  const departmentsMap = departments.reduce((acc, department) => {
    acc[department.name] = department;
    return acc;
  }, {});

  const [jobsForUpdate, setJobsForUpdate] = useState<Job[]>([]);

  const isAnySelectedJobImported = useMemo(
    () => jobsForUpdate.some(({ coned_imported }) => Boolean(coned_imported)),
    [jobsForUpdate]
  );

  const doesEachSelectedJobHaveServiceDepartment = useMemo(
    () => jobsForUpdate.every(({ departmentName }) => Boolean(departmentsMap[departmentName]?.is_service_dept)),
    [jobsForUpdate, departmentsMap]
  );

  const { values, errors, dirty, handleChange, submitForm, setFieldValue, resetForm } = useFormik<UpdatePOSData>({
    initialValues: {
      ids: selectedJobs?.map((job) => job.id) || [],
      newPo: '',
      requisition: '',
      receipt: '',
      wr: '',
      account_no: '',
      poet: '',
      update_po: false,
      update_receipt: false,
      update_requisition: false,
      update_wr: false,
      update_account_no: false,
      update_poet: false,
    },
    validationSchema,
    onSubmit: (values) => {
      updatePOS({
        ...values,
        ids: jobsForUpdate.map(({ id }) => id),
      });
      close();
    },
  });

  const activateField = (name: keyof UpdatePOSData) => {
    setFieldValue(name, true);
  };

  const deactivateField = (name: keyof UpdatePOSData) => {
    setFieldValue(name, false);
  };

  useEffect(() => {
    setJobsForUpdate(selectedJobs);
    resetForm();
  }, [selectedJobs]);

  return (
    <AppPaperModal
      modalId="update-po-dialog"
      open={open}
      onClose={close}
      title="Update #"
      subtitle={'These fields can replace existing values'}
      containerStyle={{ maxWidth: 440, width: '100%' }}
      submitButton={{
        title: 'Update',
        onClick: submitForm,
        loading: processing,
        disabled: !dirty,
      }}
    >
      <div className="main-update-po-dialog">
        <Typography>Selected Jobs:</Typography>
        <Box display="flex" flexWrap="wrap" gap="6px" marginBottom={1}>
          {jobsForUpdate.map((job) => (
            <Tooltip key={job.id} title={jobsForUpdate.length === 1 ? 'At least one job should be selected' : ''}>
              <div>
                <Chip
                  disabled={jobsForUpdate.length === 1}
                  onDelete={() => setJobsForUpdate((selectedJobs) => selectedJobs.filter(({ id }) => id !== job.id))}
                  label={`#${job.id}`}
                />
              </div>
            </Tooltip>
          ))}
        </Box>
        <OptionalField
          isActive={values.update_po}
          name="update_po"
          onActivate={activateField}
          onDeactivate={deactivateField}
          title={'PO #'}
        >
          <TextField
            autoFocus
            margin="dense"
            label=""
            placeholder="Enter PO#"
            name="newPo"
            fullWidth
            value={values.newPo}
            onChange={handleChange}
            variant="outlined"
            error={Boolean(errors.newPo)}
            helperText={Boolean(errors.newPo) && errors.newPo}
          />
        </OptionalField>
        <OptionalField
          isActive={values.update_requisition}
          name="update_requisition"
          onActivate={activateField}
          onDeactivate={deactivateField}
          title={'Requisition #'}
        >
          <TextField
            margin="dense"
            label=""
            placeholder="Enter Requisition #"
            name="requisition"
            fullWidth
            value={values.requisition}
            onChange={handleChange}
            variant="outlined"
            error={Boolean(errors.requisition)}
            helperText={Boolean(errors.requisition) && errors.requisition}
          />
        </OptionalField>
        <OptionalField
          isActive={receiptUpdate && values.update_receipt}
          name="update_receipt"
          onActivate={activateField}
          onDeactivate={deactivateField}
          title={'Receipt #'}
        >
          <TextField
            margin="dense"
            label=""
            placeholder="Enter Receipt #"
            name="receipt"
            fullWidth
            value={values.receipt}
            onChange={handleChange}
            variant="outlined"
            error={Boolean(errors.receipt)}
            helperText={Boolean(errors.receipt) && errors.receipt}
          />
        </OptionalField>
        {doesEachSelectedJobHaveServiceDepartment && (
          <>
            <OptionalField
              isActive={values.update_account_no}
              name="update_account_no"
              onActivate={activateField}
              onDeactivate={deactivateField}
              title={'Job #'}
            >
              <TextField
                margin="dense"
                label=""
                placeholder="Enter Job #"
                name="account_no"
                fullWidth
                value={values.account_no}
                onChange={handleChange}
                variant="outlined"
                error={Boolean(errors.account_no)}
                helperText={Boolean(errors.account_no) && errors.account_no}
              />
            </OptionalField>
            <OptionalField
              isActive={values.update_poet}
              name="update_poet"
              onActivate={activateField}
              onDeactivate={deactivateField}
              title={'POET #'}
            >
              <TextField
                margin="dense"
                label=""
                placeholder="Enter POET"
                name="poet"
                fullWidth
                value={values.poet}
                onChange={handleChange}
                variant="outlined"
                error={Boolean(errors.poet)}
                helperText={Boolean(errors.poet) && errors.poet}
              />
            </OptionalField>
          </>
        )}

        <Tooltip title={isAnySelectedJobImported && 'You cannot update WR# for imported jobs'} disableInteractive>
          <div>
            <OptionalField
              fullWidth
              disableActivation={isAnySelectedJobImported}
              name="update_wr"
              isActive={values.update_wr}
              onActivate={activateField}
              onDeactivate={deactivateField}
              title={'WR #'}
            >
              <TextField
                fullWidth
                disabled={isAnySelectedJobImported}
                margin="dense"
                label=""
                placeholder="Enter WR #"
                name="wr"
                value={values.wr}
                onChange={handleChange}
                variant="outlined"
                error={Boolean(errors.wr)}
                helperText={Boolean(errors.wr) && errors.wr}
              />
            </OptionalField>
          </div>
        </Tooltip>
      </div>
    </AppPaperModal>
  );
};

export default UpdatePODialog;
