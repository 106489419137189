import React, { useCallback, useRef, useState } from 'react';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Button, Menu, MenuItem } from '@mui/material';

type Props = {
  id?: string | number;
  name?: string;
  userName?: string;
  phoneNumber?: string;
  email?: string;
};

const contactToStyles: React.CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  textDecorationLine: 'underline',
  textTransform: 'capitalize',
  position: 'absolute',
  right: '5px',
  top: '-6px',
};

const ContactToMenu = ({ id, name, userName, phoneNumber, email }: Props) => {
  const [open, setOpen] = useState(false);
  const openMenu = useCallback(() => setOpen(true), []);
  const closeMenu = useCallback(() => setOpen(false), []);
  const anchorEl = useRef();
  return (
    <Box>
      <Button
        ref={anchorEl}
        style={contactToStyles}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        Contact to {name}
      </Button>
      <Menu id="simple-menu-requestor" anchorEl={anchorEl.current} keepMounted open={open} onClose={closeMenu}>
        <Box
          style={{
            borderBottom: '1px solid #F2F2F2',
            padding: '10px 16px',
          }}
        >
          <Box className="text-id">№{id}</Box>
          <Box className="text-name">{userName}</Box>
        </Box>
        <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none', color: '#000' }}>
          <MenuItem style={{ paddingRight: '50px' }}>
            Call {name}
            <PhoneIcon
              fontSize="small"
              style={{
                fill: '#E0E0E0',
                position: 'absolute',
                right: '15px',
                top: '7px',
              }}
            />
          </MenuItem>
        </a>
        <a href={`mailto:${email}`} style={{ textDecoration: 'none', color: '#000' }}>
          <MenuItem style={{ paddingRight: '50px' }}>
            Send E-mail
            <MailOutlineIcon
              fontSize="small"
              style={{
                fill: '#E0E0E0',
                position: 'absolute',
                right: '15px',
                top: '7px',
              }}
            />
          </MenuItem>
        </a>
      </Menu>
    </Box>
  );
};

export default React.memo(ContactToMenu);
