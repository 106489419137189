import FileWorker from './FileWorker';
import propertyBinder from './propertyBinder';

class FilesUpload {
  #files: FileWorker[] = [];

  #old: FileWorker[] = [];

  #new: FileWorker[] = [];

  #uploadedUrls: string[] = [];

  #fileKey = 'file_';

  constructor(files = [], fileKey = 'file_') {
    this.#files = files.map((file) => new FileWorker(file));
    this.#old = this.#files.filter(({ uploaded }) => uploaded);
    this.#new = this.#files.filter(({ uploaded }) => !uploaded);
    this.#fileKey = fileKey;
    propertyBinder(FilesUpload.prototype, this);
  }

  uploadNew = async <T>(
    uploadCallback: (data: unknown) => Promise<T> = () => Promise.resolve(null),
    responseParser = (res) => res
  ) => {
    try {
      const newFilesUrls = await FileWorker.uploadMultiple({
        files: this.#new,
        uploadCallback,
        responseParser,
        fileKey: this.#fileKey,
      });
      this.#uploadedUrls = newFilesUrls;
      return newFilesUrls;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  get allUploaded() {
    return [...this.#uploadedUrls, ...FileWorker.getUploadedURLs(this.#old)];
  }

  get isAllFilesUploaded() {
    return this.#files.length === this.allUploaded.length;
  }
}

export default FilesUpload;

export const loadFilesFromDevice = ({ multiple = false, acceptType = '*' }) => {
  return new Promise<File[]>((resolve) => {
    let blockResolve = false;
    const filesInput = document.createElement('input');
    filesInput.type = 'file';
    filesInput.accept = acceptType;
    filesInput.multiple = multiple;
    filesInput.addEventListener(
      'change',
      () => {
        blockResolve = true;
        resolve(Array.from(filesInput.files));
        filesInput.remove();
      },
      { once: true }
    );
    window.addEventListener(
      'focus',
      () => {
        setTimeout(() => {
          if (!filesInput.files?.length && !blockResolve) {
            filesInput.remove();
            resolve([]);
          }
        }, 500);
      },
      { once: true }
    );
    filesInput.click();
  });
};
