import { FC, useMemo, useState } from 'react';

import moment from 'moment';

import { AuthSession } from 'types/Common/User';

import { FORMATS } from 'Utils/Date';
import StyledAppTable, { TableSortOrder } from 'components/StyledAppTable/StyledAppTable';

type Props = {
  sessions: AuthSession[];
};

const LoginsTable: FC<Props> = ({ sessions }) => {
  const [order, setOrder] = useState<TableSortOrder>(TableSortOrder.DESC);
  const [orderBy, setOrderBy] = useState<keyof AuthSession>('created_at');

  const handleSort = (property: keyof AuthSession) => {
    const isAsc = orderBy === property && order === TableSortOrder.ASC;
    setOrder(isAsc ? TableSortOrder.DESC : TableSortOrder.ASC);
    setOrderBy(property);
  };

  const sortedSessions = useMemo(() => {
    if (!sessions) return [];
    return sessions.sort((a, b) => {
      if (orderBy === 'created_at') {
        return order === TableSortOrder.ASC
          ? a.created_at.localeCompare(b.created_at)
          : b.created_at.localeCompare(a.created_at);
      }

      return order === TableSortOrder.ASC ? a.ip.localeCompare(b.ip) : b.ip.localeCompare(a.ip);
    });
  }, [sessions, order, orderBy]);

  const columns: {
    key: keyof AuthSession;
    label: string;
    sorted?: boolean;
    width?: string | number;
    renderCell?: (row: AuthSession) => JSX.Element | string;
  }[] = [
    {
      key: 'ip',
      label: 'IP',
      sorted: true,
      width: 50,
    },
    {
      key: 'created_at',
      label: 'Datetime',
      sorted: true,
      renderCell: ({ created_at }) =>
        created_at ? moment(created_at).format(FORMATS.masterTimesheetDateTimeChanges) : '',
    },
  ];

  return (
    <StyledAppTable
      stickyHeader
      data={sortedSessions}
      columns={columns}
      order_by={orderBy}
      order={order}
      onSort={handleSort}
      sx={{
        '& .MuiTableCell-stickyHeader': {
          top: 48,
        },
      }}
    />
  );
};

export default LoginsTable;
