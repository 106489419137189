import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AccessTime,
  CheckCircle,
  ExpandLess,
  ExpandMore,
  MessageOutlined,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import {
  Checkbox,
  Collapse,
  Dialog,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';

import { JobType } from 'Constants/job';
import { workHoursFilterRegExp } from 'Constants/reg_exp';
import { WorkersAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import Button from 'components/Button/Button';

import './PushMessagesModal.scss';

const JobTypes = {
  Flagging: JobType.Flagging,
  Parking: JobType.Parking,
  Signage: JobType.Signage,
};

const createDefaultMessage = (jobTypes = []) =>
  `Utility Field Services: ${jobTypes
    .map((type) => Object.keys(JobTypes).find((key) => JobTypes[key] === type))
    .join(', ')} Job is available. Call in, please, to get the job assigned.`;

export default function PushMessagesModal({ closeModal = () => {}, isOpen = false }) {
  // Request Data for get push groups:
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [workHoursFilter, setWorkHoursFilter] = useState(24);
  //   Response From get push groups method:
  /** @type {[WorkerGroup[], React.Dispatch<React.SetStateAction<WorkerGroup[]>>]} Response */
  const [workerGroups, setWorkerGroups] = useState([]);
  //   Request Data for send message:
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [message, setMessage] = useState(createDefaultMessage(selectedJobTypes));
  //   UI Show/Hide worker groups:
  const [openedGroups, setOpenedGroups] = useState({});
  //   UI Message to Worker:
  const [messageTemplates, setMessageTemplates] = useState([createDefaultMessage(selectedJobTypes)]);
  const [baseTemplateSelected, setBaseTemplateSelected] = useState(true);
  //   Loaders:
  const [workerGroupsLoading, setWorkerGroupsLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const workHoursIsValid = workHoursFilterRegExp.test(workHoursFilter.toString());

  useEffect(() => {
    if (!workHoursIsValid) return;
    getPushGroups({ hours: workHoursFilter, jobTypes: selectedJobTypes });
  }, [selectedJobTypes, workHoursFilter, workHoursIsValid]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedJobTypes([]);
      setWorkHoursFilter(24);
      setMessage(createDefaultMessage([]));
      setWorkerGroups([]);
      setSelectedWorkers([]);
      setOpenedGroups({});
    }
  }, [isOpen]);

  const getPushGroups = async ({ hours = 0, jobTypes = [] }) => {
    try {
      setWorkerGroupsLoading(true);
      const response = await WorkersAPI.getPushGroups({ hours, jobTypes });
      setWorkerGroups(response.result);
      const responseGroups = response.result.reduce((acc, { group_name }) => ({ ...acc, [group_name]: true }), {});
      setOpenedGroups((prev) =>
        Object.keys(responseGroups).reduce(
          (acc, key) => (Object.hasOwn(prev, key) ? { ...acc, [key]: prev[key] } : { ...acc, [key]: true }),
          {}
        )
      );
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setWorkerGroupsLoading(false);
    }
  };

  const sendPushNotification = async ({ message = '', workerIds = [] }) => {
    try {
      setSending(true);
      await WorkersAPI.sendNotificationToWorkers({ message, workerIds });
      showSuccessMessage('Notification sent');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    const defaultMessage = createDefaultMessage(selectedJobTypes);
    setMessageTemplates((prev) => [defaultMessage, ...prev.slice(1)]);
    if (baseTemplateSelected) {
      setMessage(defaultMessage);
      return;
    }
  }, [selectedJobTypes, baseTemplateSelected]);

  const resetAllFilters = () => {
    setSelectedJobTypes([]);
    setWorkHoursFilter(24);
  };

  const resetJobTypeFilters = () => setSelectedJobTypes([]);
  const resetHoursFilter = () => setWorkHoursFilter(24);

  const toggleJobType =
    (jobType = '') =>
    () => {
      const value = JobTypes[jobType];
      if (selectedJobTypes.includes(value)) {
        setSelectedJobTypes((prev) => prev.filter((type) => type !== value));
        return;
      }
      setSelectedJobTypes((prev) => [...prev, value]);
    };

  const updateHours = ({ target: { value } }) => {
    setWorkHoursFilter(Number(value).toFixed(0));
  };

  const selectTemplate = useCallback(
    (template = '', index = 0) =>
      () => {
        setMessage(template);
        if (index === 0) setBaseTemplateSelected(true);
      },
    [messageTemplates]
  );

  const messageEdit = ({ target: { value } }) => {
    setBaseTemplateSelected(false);
    setMessage(value);
  };

  const toggleWorkGroup =
    (group_name = '', expand = false) =>
    () =>
      setOpenedGroups((prev) => ({ ...prev, [group_name]: expand }));

  const selectAllGroupWorkers = useCallback(
    (groupChecked = false, workers = []) =>
      () =>
        groupChecked
          ? setSelectedWorkers((prev) => prev.filter((ID) => workers.findIndex(({ id }) => id === ID) === -1))
          : setSelectedWorkers((prev) => Array.from(new Set([...prev, ...workers.map(({ id }) => id)]))),
    []
  );

  const allWorkersIds = useMemo(
    () => new Set(workerGroups.reduce((acc, { workers }) => [...acc, ...workers.map(({ id }) => id)], [])),
    [workerGroups]
  );
  const selectResetAllAvailable = () =>
    setSelectedWorkers((prev) => (prev.length > 0 && prev.length === allWorkersIds.size ? [] : [...allWorkersIds]));
  const resetAllSelectedWorkers = () => setSelectedWorkers([]);

  const sendMessagesToWorkers = useCallback(
    () => sendPushNotification({ message, workerIds: selectedWorkers }),
    [message, selectedWorkers]
  );

  const toggleWorkerChecked = useCallback(
    (checked = false, workerId = 0) =>
      () =>
        checked
          ? setSelectedWorkers((prev) => prev.filter((id) => id !== workerId))
          : setSelectedWorkers((prev) => [...prev, workerId]),
    []
  );

  return (
    <Dialog onClose={closeModal} aria-labelledby="push-messages-modal" open={isOpen} maxWidth="md" fullWidth>
      <h2 className="h4 text-bold pt-2 mb-3 text-center">Message Blast to Workers</h2>
      <div className="d-flex flex-row col-12 pt-2">
        <div className="container col-6">
          <div className="col-12 d-flex justify-content-between align-items-center pl-3 pb-2 mb-2 border-bottom">
            <h3 className="mb-0 h5 text-bold">Sort Workers</h3>
            <p className="mb-0 link" onClick={resetAllFilters}>
              Reset All
            </p>
          </div>
          <div className="col-12 px-0 pt-2">
            <div className="container col-12 px-0 mb-3">
              <div className="row col-12 align-items-center justify-content-between mx-0 mb-2">
                <h4 className="mb-0 h6 text-bold">Job Type</h4>
                <p className="mb-0 link" onClick={resetJobTypeFilters}>
                  Reset Selected
                </p>
              </div>
              <List className="d-flex py-0">
                {Object.keys(JobTypes).map((jobType) => (
                  <ListItem key={jobType} style={{ paddingTop: 0, paddingBottom: 0, marginLeft: -9 }}>
                    <Checkbox
                      checked={selectedJobTypes.includes(JobTypes[jobType])}
                      onClick={toggleJobType(jobType)}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle color="primary" />}
                    />
                    <ListItemText primary={jobType} />
                  </ListItem>
                ))}
              </List>
            </div>
            <div className="container col-12 px-0 mb-3">
              <div className="row col-12 align-items-center justify-content-between mx-0 mb-2">
                <h4 className="mb-0 h6 text-bold">Work Hours</h4>
                <p className="mb-0 link" onClick={resetHoursFilter}>
                  Reset
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between px-3">
                <p style={{ margin: 0, width: 100 }}>No work within {workHoursFilter} hours</p>
                <TextField
                  variant="outlined"
                  value={workHoursFilter}
                  onChange={updateHours}
                  error={!workHoursIsValid || !Number(workHoursFilter)}
                  label="Hours"
                  type="number"
                  helperText={workHoursIsValid ? 'Only positive integers' : 'Only 3 integers allowed'}
                  style={{ paddingLeft: 0, marginLeft: 16, width: 180, marginBottom: -20 }}
                  inputProps={{ step: 1, pattern: workHoursFilterRegExp.source, min: 0, max: 100 }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ marginLeft: -6 }}>
                        <AccessTime style={{ marginRight: 5 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 justify-content-between align-items-center pt-2 pl-3 pb-2 mb-3 border-bottom">
            <h3 className="mb-0 h5 text-bold">Message Blast</h3>
          </div>
          <div className="col-12 px-0 pt-2">
            <div className="col-12 px-0">
              <div className="col-12 mb-3">
                <TextField
                  variant="outlined"
                  InputProps={{ style: { borderWidth: 0 } }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{ style: { borderWidth: 0, borderRadius: 16 } }}
                  InputLabelProps={{ style: { backgroundColor: 'white' } }}
                  fullWidth
                  multiline
                  label="Message"
                  maxRows={3}
                  minRows={3}
                  placeholder="Enter message"
                  helperText="Maximum 128 characters"
                  error={message.length > 128}
                  value={message}
                  onChange={messageEdit}
                />
              </div>
              <div className="row col-12 align-items-center justify-content-between mx-0">
                <h4 className="mb-0 h6 text-bold">Templates</h4>
                {/* <p className="mb-0 link">Add new</p> */}
              </div>
              <div>
                <List style={{ height: 170, overflowY: 'scroll' }}>
                  {messageTemplates.map((template, index) => (
                    <ListItem
                      key={template + index}
                      style={{ fontSize: 12 }}
                      button
                      onClick={selectTemplate(template, index)}
                    >
                      <MessageOutlined
                        style={{ marginRight: 10, color: template === message ? '#007bff' : 'inherit' }}
                      />
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography style={{ fontSize: 12, color: template === message ? '#007bff' : 'inherit' }}>
                            {template}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </div>
            </div>
          </div>
        </div>
        <div className="container col-6">
          <div className="container col-12">
            <div className="col-12 d-flex justify-content-between align-items-center pl-3 pb-2 mb-2 border-bottom">
              <h2 className="mb-0 h5 text-bold">Choose Workers</h2>
              <p className="mb-0 link" onClick={resetAllSelectedWorkers}>
                Reset All
              </p>
            </div>
          </div>
          <div className="container col-12 pt-2 mb-4" style={{ position: 'relative' }}>
            <List component={'nav'} style={{ height: 550, overflowY: 'scroll', paddingTop: 0 }}>
              {!workerGroups.length ? <Typography>Nothing found, try changing your search options</Typography> : null}
              <ListItem
                key={'SelectResetAllAvialable'}
                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
                component="div"
              >
                <Checkbox
                  checked={allWorkersIds.size === selectedWorkers.length && Boolean(selectedWorkers.length)}
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={<CheckCircle color="primary" />}
                  onClick={selectResetAllAvailable}
                  style={{ paddingTop: 2, paddingBottom: 2 }}
                />
                <ListItemText primary="Select All Available Workers" />
              </ListItem>
              {workerGroups.map(({ group_name, workers }) => {
                const groupChecked = workers.every(({ id }) => selectedWorkers.includes(id));

                return (
                  <React.Fragment key={group_name}>
                    <ListItem key={group_name} style={{ paddingLeft: 20, paddingRight: 0 }}>
                      <Checkbox
                        checked={groupChecked}
                        onClick={selectAllGroupWorkers(groupChecked, workers)}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle color="primary" />}
                      />
                      <ListItemText primary={group_name} />
                      {openedGroups[group_name] ? (
                        <IconButton onClick={toggleWorkGroup(group_name, false)}>
                          <ExpandLess />
                        </IconButton>
                      ) : (
                        <IconButton onClick={toggleWorkGroup(group_name, true)}>
                          <ExpandMore />
                        </IconButton>
                      )}
                    </ListItem>
                    <Collapse in={openedGroups[group_name]} unmountOnExit>
                      {workers.map(({ id, first_name, last_name }) => {
                        const checked = selectedWorkers.includes(id);

                        return (
                          <ListItem
                            key={id}
                            style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 40 }}
                            component="div"
                          >
                            <Checkbox
                              checked={checked}
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<CheckCircle color="primary" />}
                              onClick={toggleWorkerChecked(checked, id)}
                            />
                            <ListItemText primary={`${first_name} ${last_name}`} />
                          </ListItem>
                        );
                      })}
                    </Collapse>
                  </React.Fragment>
                );
              })}
            </List>
            {workerGroupsLoading ? (
              <div className="loading-background">
                <Typography>Loading...</Typography>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="container d-flex justify-content-end mb-3">
        <Button
          color={'gray'}
          width={'200px'}
          borderRadius={'20px'}
          textTransform={false}
          onClick={closeModal}
          style={{ marginRight: 16 }}
          processing={sending}
        >
          Cancel
        </Button>
        <Button
          color={'dark'}
          width={'200px'}
          borderRadius={'20px'}
          textTransform={false}
          onClick={sendMessagesToWorkers}
          disabled={!selectedWorkers.length || !workHoursIsValid || !Number(workHoursFilter)}
        >
          Send Blast Messages
        </Button>
      </div>
    </Dialog>
  );
}

/** 
@typedef {{
    group_name: string,
    workers: {
        id: number,
        first_name: string,
        last_name: string,
    }[]
}} WorkerGroup
 */
