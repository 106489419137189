import { FC, useEffect, useMemo } from 'react';

import { DeleteForever } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import UserPermissions from 'Utils/PermissionsHelper';
import AppInput from 'components/AppInput';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';
import { useAppSelector } from 'createStore';

import SignatureBox from '../../components/SignatureBox/SignatureBox';

const Signatures = () => {
  const user = useAppSelector((state) => state.app.user);
  const { values, setFieldValue, errors } = useTimesheetFormContext();
  const userCanEditTimesheet = UserPermissions.has.edit_timesheets;

  const filteredSignatures = useMemo(() => {
    return values.signatures.filter((signature) => signature.can_be_signed || signature.signature_data);
  }, [values.signatures]);

  const changeSignatureEmployeeNumber =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const updatedSignatures = values.signatures.map((signature) => {
        if (signature.signature_type_id === id) {
          signature.employee_number = event.target.value;
        }
        return signature;
      });

      setFieldValue('signatures', updatedSignatures);
    };

  const changeSignatureData = (id: number) => (value: string) => {
    const updatedSignatures = values.signatures.map((signature) => {
      if (signature.signature_type_id === id) {
        signature.signature_data = value;
        signature.signee = user.name;
        signature.employee_number = user.co_employee_id || '';
      }
      return signature;
    });

    setFieldValue('signatures', updatedSignatures);
  };

  const removeSignature = (id: number) => (event) => {
    const updatedSignatures = values.signatures.map((signature) => {
      if (signature.signature_type_id === id) {
        signature.signature_data = '';
      }
      return signature;
    });

    setFieldValue('signatures', updatedSignatures);
  };

  return (
    <Box display="flex" flexDirection="column">
      {filteredSignatures.map((signature, index) => (
        <TimesheetBox key={index}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{signature.name}</Typography>
            {signature.can_be_signed && userCanEditTimesheet && (
              <Button
                startIcon={<DeleteForever />}
                color="error"
                variant="contained"
                onClick={removeSignature(signature.signature_type_id)}
                disabled={!signature.signature_data}
              >
                Remove signature
              </Button>
            )}
          </Box>
          <Box display="flex" gap="14px" mb={2}>
            <AppInput
              label="Signature Name"
              value={signature.signature_data ? signature.signee : ''}
              disabled
              fullWidth
            />
            <AppInput
              label="Employee #"
              value={signature.signature_data ? signature.employee_number : ''}
              disabled={
                !!user.co_employee_id || !signature.can_be_signed || !signature.signature_data || !userCanEditTimesheet
              }
              onChange={changeSignatureEmployeeNumber(signature.signature_type_id)}
              fullWidth
              // @ts-ignore
              error={!!errors.signatures?.[index]?.employee_number}
              // @ts-ignore
              helperText={errors.signatures?.[index]?.employee_number}
            />
          </Box>
          <SignatureBox
            value={signature.signature_data}
            onChange={changeSignatureData(signature.signature_type_id)}
            disabled={!signature.can_be_signed || !userCanEditTimesheet}
            // @ts-ignore
            error={!!errors.signatures?.[index]?.signature_data}
          />
        </TimesheetBox>
      ))}
    </Box>
  );
};

export default Signatures;
