import { DateSchema } from 'yup';

import { TimeLimitation } from 'Constants/companies';
import { roundHoursUp } from 'Containers/Job/utils';

export const applyDepartmentLitimations = (
  fieldName: string,
  timeLimitation: TimeLimitation,
  schema: DateSchema<Date>
) => {
  if (timeLimitation === TimeLimitation.WithoutLimit) {
    return schema;
  }
  if (timeLimitation === TimeLimitation.OnlyHours) {
    return schema.test(
      fieldName,
      'Due to WMS Limitations, for this Dept the time format must be XX:00, please update time accordingly',
      (value: Date) => {
        if (!value) return true;
        const validTime = roundHoursUp(value);

        return value.toISOString() === validTime.toISOString();
      }
    );
  }
  return schema;
};
