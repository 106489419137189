import React from 'react';

import { FaFileImport } from 'react-icons/fa';

import { Button, ButtonProps, CircularProgress } from '@mui/material';

import s from './ExportToFileButton.module.scss';

type Props = ButtonProps & {
  processing?: boolean;
};

const ExportToFileButton = ({ processing = false, ...rest }: Props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      style={{ alignSelf: 'center', position: 'relative' }}
      endIcon={
        <>
          <FaFileImport style={{ marginRight: 4 }} color="white" size={20} />
        </>
      }
      {...rest}
    >
      {rest.children}
      {processing ? (
        <div className={s.loading_container}>
          <CircularProgress size={20} />
        </div>
      ) : null}
    </Button>
  );
};

export default ExportToFileButton;
