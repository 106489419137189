import { FC, memo, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import TextOverflowTooltip from 'components/TextOverflowTooltip';

import { ChatItemAvatar } from '../styled';
import { parseNameInitials } from '../utils';
import { ChatsTabsEnum } from './ChatsTabs';

type Props = {
  chatID: string;
  userName: string;
  chatType: ChatsTabsEnum;
  onSelectChat: (chatID: number) => void;
  isSelected?: boolean;
  isConedSupervisor: boolean;
};

const ChatTypesMap = {
  [ChatsTabsEnum.worker]: 'Shift',
  [ChatsTabsEnum.inspector]: 'Schedule',
};

const UserRolesByChatTypeMap = {
  [ChatsTabsEnum.worker]: 'Worker',
  [ChatsTabsEnum.inspector]: 'CES Inspector',
};

const ChatItem: FC<Props> = ({ chatID, userName, isSelected = false, onSelectChat, chatType, isConedSupervisor }) => {
  const userRole = useMemo(() => {
    return isConedSupervisor ? 'Coned Supervisor' : UserRolesByChatTypeMap[chatType];
  }, [isConedSupervisor, chatType]);

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      gap="12px"
      px="8px"
      py="7px"
      onClick={() => onSelectChat(+chatID)}
      borderRadius="10px"
      bgcolor={isSelected ? 'secondary.light' : 'transparent'}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.15s ease-in-out',
        '&:hover': {
          bgcolor: isSelected ? 'secondary.light' : '#00000005',
        },
      }}
    >
      <ChatItemAvatar isSelected={isSelected}>{parseNameInitials(userName)}</ChatItemAvatar>
      <TextOverflowTooltip title={userName} sx={{ flex: 1 }}>
        <Typography
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          fontFamily="Poppins Medium"
          fontSize={13}
        >
          <Typography fontSize={9} fontFamily="Poppins Medium" color="text.secondary">
            {userRole}
          </Typography>
          {userName}
        </Typography>
      </TextOverflowTooltip>

      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Typography color="text.secondary" fontFamily="Poppins Medium" fontSize={9}>
          {ChatTypesMap[chatType]}
        </Typography>

        <Typography color="primary" fontFamily="Poppins Medium" fontSize={12}>
          #{chatID}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(ChatItem);
