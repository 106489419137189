import React from 'react';

import { JobType } from 'Constants/job';

import MapBase from './MapBase';
import { getWorkerPopupMarkup } from './WorkerPopup';

const styles = {
  map: {
    flex: 1,
    height: '100%',
    position: 'relative',
  },
};

class JobsMap extends MapBase {
  static defaultProps = {
    /** @type {import('types/Common/JobData').JobShift[]} */
    workers: [],
    /** @type {import('types/Job').Job} */
    locationJob: {},
  };
  /** @type {{workers:import('types/Common/JobData').JobShift[], locationJob: import('types/Job').Job }}  */
  props;

  /** @type {InstanceType<import('mapbox-gl')['Popup']>} */
  openedPopup = null;
  openedPopupWorkerId = 0;

  /** @param {import('./MapBase').MapBaseClickEvent} e */
  handleDriverClick = (e) => {
    const props = e.features[0].properties;
    const coordinates = e.features[0].geometry.coordinates.slice();

    this.openedPopupWorkerId = props.shift_id;
    this.openedPopup = new this.Mapbox.Popup({ offset: 20 });
    this.openedPopup
      .setLngLat(coordinates)
      .setHTML(
        getWorkerPopupMarkup(
          this.props.workers?.find(({ shift_id }) => shift_id === props.shift_id),
          this.props.locationJob.jobType
        )
      )
      .addTo(this.map)
      .once('close', () => {
        this.openedPopup = null;
        this.openedPopupWorkerId = 0;
      });
  };
  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    this.updateMap(prevProps.locationJob?.id, this.props.locationJob?.id);
  }

  closePopup = () => {
    this.openedPopup?.remove && this.openedPopup.remove();
  };

  updatePopup = () => {
    if (this.openedPopup) {
      const openedWorker = this.props.workers?.find(({ shift_id }) => shift_id === this.openedPopupWorkerId);
      this.openedPopup.setHTML(getWorkerPopupMarkup(openedWorker, this.props.locationJob.jobType));
    }
  };

  fitMap = () => {
    const jobData = this.props.locationJob.locations;
    const workerData = this.props.workers;

    const jobP = jobData.reduce(
      (points, { lon = 0, lng, lat }) => ((lon || lng) && lat ? [...points, [lon || lng, lat]] : points),
      []
    );
    const workerP = workerData.reduce((points, { lat, lon }) => (lon && lat ? [...points, [lon, lat]] : points), []);
    if (jobP.length && workerP.length) {
      this.map.fitBounds([jobP[0], workerP[0]], {
        padding: { top: 50, bottom: 50, left: 20, right: 20 },
      });
      return;
    }
    if (jobP.length) {
      this.centerMap(...jobP[0]);
    }
  };

  createWorkersFeatures = () => {
    const { locationJob, workers } = this.props;
    const isFlagging = locationJob?.jobType === JobType.Flagging;
    const markers = workers.reduce((acc, jobWorker) => {
      const image = isFlagging ? this.images.flagging : this.images.parking;

      if (!jobWorker.workerId || !jobWorker.lat || !jobWorker.lon) return acc;
      const {
        lat,
        lon,
        shift_id,
        worker: { name: title },
      } = jobWorker;
      const marker = {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [lon || '', lat || ''] },
        properties: { image, shift_id, title },
      };
      return [...acc, marker];
    }, []);
    return markers;
  };

  createJobsFeatures = () => {
    const { locations = [], id: title } = this.props.locationJob || { locations: [], id: 0 };
    const jobMarkers = locations.map((loc, i) => {
      const { lat, lng, lon = 0, address, id: loc_id } = loc;
      const label = `${i + 1} - ${address?.split(',')[0]}`;
      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [lng || lon, lat] },
        properties: { loc_id, address, title, label, image: this.images.job },
      };
    });
    return jobMarkers;
  };

  render() {
    return <div ref={this.mapContainer} style={styles.map} />;
  }
}

export default JobsMap;
