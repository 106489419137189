import * as Yup from 'yup';

import { employeeNumberValidation } from 'Containers/Profile/Profile.Validation';

const ApproveTimesheetsValidationSchema = Yup.object().shape({
  employee_number: employeeNumberValidation.required('Employee number is required'),
  sign: Yup.string().required('Signature is required'),
});

export default ApproveTimesheetsValidationSchema;
