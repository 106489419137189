import React, { PropsWithChildren, useEffect, useMemo } from 'react';

import { CloseRounded } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, Button, ButtonProps, IconButton, Typography, alpha, styled, useTheme } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';
import { useWaitTimer } from 'Utils/hooks/useWaitTimer';

import ModalBase from '../ModalBase/ModalBase';
import s from './AppPaperModal.module.scss';

type Props = {
  open: boolean;
  onClose: () => void;
  title: string | React.ReactNode;
  subtitle?: string;
  modalId?: string;
  containerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  confirmRequired?: boolean;
  noActions?: boolean;
  submitButton?: LoadingButtonProps & { dataCy?: string; timer?: number };
  cancelButton?: ButtonProps;
} & PropsWithChildren;

const AppPaperModal = ({
  open,
  onClose,
  title,
  subtitle = '',
  submitButton = {},
  cancelButton = {},
  containerStyle = {},
  contentStyle = {},
  confirmRequired = false,
  noActions = false,
  modalId,
  children,
}: Props) => {
  const { timer, runTimer, clearTimer } = useWaitTimer(submitButton.timer || 0);

  useEffect(() => {
    if (open && submitButton.timer) {
      runTimer();
    }
    return () => {
      clearTimer();
    };
  }, [open, submitButton.timer, runTimer, clearTimer]);

  const submitButtonTitle = useMemo(() => {
    if (timer) {
      return `Wait...(${timer})`;
    }
    return submitButton.title || submitButton.children || 'Save Changes';
  }, [timer, submitButton.title, submitButton.children]);

  return (
    <ModalBase id={modalId} open={open} onClose={confirmRequired ? () => {} : onClose}>
      <div
        className={s.paperContainer}
        style={{
          ...containerStyle,
          maxHeight: containerStyle.maxHeight
            ? `min(${getCssSize(containerStyle.maxHeight)}, calc(100% - 24px))`
            : 'calc(100% - 24px)',
          maxWidth: containerStyle.maxWidth
            ? `min(${getCssSize(containerStyle.maxWidth)}, calc(100vw - 24px))`
            : 'calc(100vw - 24px)',
        }}
      >
        <div className={s.headerContainer}>
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            justifyContent="center"
            fontFamily={'Poppins Semibold'}
            color="text.primary"
          >
            <Box fontSize={20}>{title}</Box>
            {subtitle && (
              <Typography fontSize={12} fontFamily={'Poppins Medium'} sx={{ opacity: 0.5 }}>
                {subtitle}
              </Typography>
            )}
          </Box>
          {!confirmRequired && (
            <IconButton sx={{ width: 38, height: 38, backgroundColor: '#F2F3F7' }} onClick={onClose}>
              <CloseRounded />
            </IconButton>
          )}
        </div>
        {children && (
          <div style={contentStyle} className={s.content}>
            {children}
          </div>
        )}
        {!noActions && (
          <div className={s.buttonContainer}>
            <Button
              variant="translucent"
              {...DatasetTestIDs.components.appPaperModal.buttons.cancel}
              fullWidth
              {...cancelButton}
              onClick={cancelButton?.onClick || onClose}
              size={cancelButton?.size || 'large'}
            >
              {cancelButton?.title ?? cancelButton?.children ?? 'Cancel'}
            </Button>
            <LoadingButton
              {...DatasetTestIDs.components.appPaperModal.buttons.confirm}
              fullWidth
              {...submitButton}
              size={submitButton?.size || 'large'}
              variant={submitButton?.variant || 'contained'}
              disabled={submitButton?.disabled || Boolean(timer)}
            >
              {submitButtonTitle}
            </LoadingButton>
          </div>
        )}
      </div>
    </ModalBase>
  );
};

export default AppPaperModal;

function getCssSize(size: string | number) {
  if (typeof size === 'number') {
    return `${size}px`;
  }
  return size;
}
