import { MenuItem, Select, Tabs, alpha, styled } from '@mui/material';

import AppInputField from 'components/AppInputField/AppInputField';

export const AppInputAutocomplete = styled(AppInputField)(({ theme }) => ({
  '& .MuiOutlinedInput-root .MuiInputBase-input': {
    padding: 0,
    minHeight: '33px',
    [theme.breakpoints.down('md')]: {
      minHeight: '28px',
    },
  },

  '& .MuiOutlinedInput-root .MuiInputBase-input.MuiInputBase-inputSizeSmall': {
    minHeight: '23px',
  },

  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    paddingLeft: '12px !important',
    paddingTop: '5px !important',
    paddingBottom: '5px !important',
  },

  '& .MuiChip-root': {
    fontFamily: 'Poppins Medium',
    color: '#667085',
    backgroundColor: '#F2F3F7',
    borderRadius: 999,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down('md')]: {
      height: '22px',
      fontSize: '12px',
    },
  },
}));

export const ProfileSettingsTabs = styled(Tabs)(({ theme }) => ({
  width: '100%',
  maxWidth: 280,
  height: 'max-content',
  position: 'sticky',
  top: 0,

  '& .MuiTabs-flexContainer': {
    gap: '8px',
  },

  '& .MuiButtonBase-root': {
    color: '#7D98AE',
    fontFamily: 'Poppins Bold',
    textTransform: 'none',
    padding: '10px 16px',
    justifyContent: 'flex-start',
    gap: '10px',
    minHeight: 'auto',
    borderRadius: '8px',
    transition: 'background-color 0.2s ease-in-out',
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
    },
    '&:hover:not(.Mui-selected)': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
  },
  '& svg, & .MuiSvgIcon-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 !important',
    width: 18,
    height: 18,
  },
}));

export const ProfileSettingsSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Poppins Bold',
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },

  '& .MuiList-root .MuiMenuItem-root': {
    fontFamily: 'Poppins Bold',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.main,
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiSelect-select[aria-expanded="true"] ~ .MuiOutlinedInput-notchedOutline': {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

export const ProfileSettingsSelectMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '10px 16px',
  fontFamily: 'Poppins Bold',
  display: 'flex',
  alignItems: 'center',
  color: '#7D98AE',
  gap: '10px',
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
  },

  '& svg, & .MuiSvgIcon-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 !important',
    width: 16,
  },
}));
