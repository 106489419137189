import React, { useMemo } from 'react';

import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

import { UsersAPI } from 'Services/API';

interface Props {
  onSelect?: (item: any) => void;
  width?: any;
  limitTags?: any;
  noLabel?: boolean;
  searchParams?: {};
  value?: any;
  usersNotAvailable?: any;
  isMulty?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export default function UsersMaterialAsyncSearch({
  onSelect,
  width,
  value,
  limitTags,
  noLabel,
  searchParams,
  isMulty,
  usersNotAvailable,
  disabled,
  size = 'small',
}: Props) {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState<any[]>([]);
  const loading = open && users?.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await UsersAPI.getUsers(searchParams);

      if (response) {
        let workers = response.results.map((worker: any) => ({
          label: worker.name,
          value: worker,
        }));
        if (usersNotAvailable && usersNotAvailable.some((userId) => userId !== null && userId !== undefined)) {
          workers = workers.filter((worker) => usersNotAvailable.indexOf(worker.value.id) === -1);
        }

        setUsers(workers);
      }
    })();
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setUsers([]);
    }
  }, [open]);

  const autocompleteValue = useMemo(() => {
    if (value) {
      return value;
    }
    // No value cases
    if (isMulty) {
      return [];
    }
    return null;
  }, [value, isMulty]);

  return (
    <Autocomplete
      multiple={isMulty}
      limitTags={limitTags ? limitTags : 1}
      size={size}
      defaultValue={[]}
      value={autocompleteValue}
      id="asynchronous-demo"
      style={{ width, minWidth: 150 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => value.value && option.value?.id === value.value?.id}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      renderOption={(props, option) => (
        <li {...props} key={option.value.id}>
          <span>{option.label}</span>
        </li>
      )}
      options={users}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={noLabel ? '' : 'Users'}
          placeholder={value ? '' : 'CC Users'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
      onChange={(event, value) => onSelect(value)}
      disabled={disabled}
    />
  );
}
