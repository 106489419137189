import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete, AutocompleteRenderInputParams, Popper, alpha } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { BaseDepartment, Department, DepartmentGroup } from 'types/Common/Companies';

import { DatasetTestIDs } from 'Constants/tests';
import { actions } from 'Services';
import { ReduxState, useAppSelector } from 'createStore';

export enum DepartmentGroups {
  single = 'Department',
  grouped = 'Department Groups',
}

interface DepartmentOption extends Department {
  group?: DepartmentGroups;
  departments?: Department[];
}

interface Props {
  onSelect?: (departments?: Department[], departmentGroups?: DepartmentOption[]) => void;
  width?: string | number;
  limitTags?: number;
  noLabel?: boolean;
  onlyOwnDept?: boolean;
  notMulty?: boolean;
  onlySingleDepartment?: boolean;
  disabled?: boolean;
  departments?: Department[];
  departmentGroups?: DepartmentGroup[];
  showNonInvoiced?: boolean;
  minPopperWidth?: string | number;
  inputSize?: TextFieldProps['size'];
  searchFilterFunction?: (department: Department) => boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const createDepartmentOptions = (departments: Department[] = [], group = DepartmentGroups.single) =>
  departments.filter(Boolean).map((dept) => ({ ...dept, group }));

const fastDeaprtmentGroupSplitter = (allDepartments: DepartmentOption[] = []) => {
  const grouped: DepartmentOption[] = [];
  const single: DepartmentOption[] = [];
  for (const dept of allDepartments) {
    if (dept?.group === DepartmentGroups.grouped) {
      grouped.push(dept);
      continue;
    }
    if (dept?.group === DepartmentGroups.single) {
      single.push(dept);
      continue;
    }
    single.push(dept);
  }
  return { grouped, single };
};

export default function DepartmentMaterialAsyncSearch({
  onSelect,
  width,
  limitTags,
  noLabel,
  onlyOwnDept,
  notMulty,
  onlySingleDepartment,
  disabled,
  departments = [],
  departmentGroups = [],
  showNonInvoiced = false,
  minPopperWidth = 0,
  inputSize = 'small',
  searchFilterFunction,
  renderInput,
}: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const allDepartments = useSelector((state: ReduxState) => state.app.departments);
  const allDepartmentGroups = useSelector((state: ReduxState) => state.app.departmentGroups);
  const user = useSelector((state: ReduxState) => state.app.user);
  const departmentsLoading = useAppSelector((state) => state.app.departmentLoading);
  const departmentGroupsLoading = useAppSelector((state) => state.app.departmentGroupsLoading);

  const filteredDepartments = useMemo(() => {
    if (searchFilterFunction) {
      return allDepartments.filter(searchFilterFunction);
    }
    return allDepartments;
  }, [allDepartments, searchFilterFunction]);

  const allDepartmentsMap = useMemo(
    () => new Map<number, Department>(filteredDepartments.map((item) => [item.id, item])),
    [filteredDepartments]
  );

  const userOwnDepartments = useMemo(() => {
    const departmentsMap = new Map<number, Department>();
    const userDepartmentsGroupIds = user.departmentGroups.map((item) => item.id);
    const grouppedDepartments = allDepartmentGroups.reduce(
      (acc, group) => (userDepartmentsGroupIds.includes(group.id) ? [...acc, ...group.departments] : acc),
      []
    );
    [...user.departments, ...grouppedDepartments].forEach((item) => {
      const department = allDepartmentsMap.get(item.id);
      departmentsMap.set(item.id, department);
    });
    return Array.from(departmentsMap.values());
  }, [user.departments, user.departmentGroups, allDepartmentGroups, allDepartmentsMap]);

  const filterOwnDept = (departments = []) => {
    if (onlyOwnDept) {
      return userOwnDepartments;
    }
    return departments;
  };

  const departmentGroupsOptions = useMemo(
    () =>
      allDepartmentGroups.asMutable({ deep: true }).map((depGroup) => ({
        name: depGroup?.name,
        id: depGroup?.id,
        departments: depGroup.departments.map((dept) => allDepartmentsMap.get(dept.id)),
        group: DepartmentGroups.grouped,
        section: null,
      })),
    [allDepartmentGroups, allDepartmentsMap]
  );

  const groupSelected = useMemo(() => {
    const selectedGroupDepartment: BaseDepartment[] = allDepartmentGroups
      .asMutable({ deep: true })
      .filter((dept) => departmentGroups.some((deptGroup) => dept.id === deptGroup?.id))
      .map(({ departments }) => departments)
      .flat();
    return selectedGroupDepartment;
  }, [departmentGroups, allDepartmentGroups]);

  const departmentOptions = useMemo(
    () => createDepartmentOptions(filterOwnDept(filteredDepartments.asMutable())),
    [onlyOwnDept, filteredDepartments]
  );

  const options =
    onlySingleDepartment || showNonInvoiced ? departmentOptions : [...departmentGroupsOptions, ...departmentOptions];

  useEffect(() => {
    dispatch(actions.AppActions.getDepartments(showNonInvoiced ? { non_invoiced: true } : {}));
    dispatch(actions.AppActions.getDepartmentGroups());
  }, [showNonInvoiced]);

  const autocompleteValue = useMemo(
    () =>
      notMulty
        ? onlySingleDepartment
          ? createDepartmentOptions(departments)[0]
          : departments[0]
        : [
            ...createDepartmentOptions(departments),
            ...createDepartmentOptions(
              departmentGroups.map((group) => ({
                ...group,
                departments:
                  allDepartmentGroups
                    .find(({ id }) => id === group.id)
                    ?.departments?.map((dept) => allDepartmentsMap.get(dept.id)) || [],
                section: null,
              })),
              DepartmentGroups.grouped
            ),
          ],
    [departments, departmentGroups, notMulty, allDepartmentsMap, allDepartmentGroups]
  );

  const onChange = useCallback<(event: any, value: DepartmentOption[] | DepartmentOption) => void>(
    (event, value) => {
      if ((onlySingleDepartment || notMulty) && !Array.isArray(value)) {
        onSelect([value as DepartmentOption]);
        return;
      }
      const { single, grouped } = fastDeaprtmentGroupSplitter(value as DepartmentOption[]);
      onSelect(single, grouped);
    },
    [notMulty, onlySingleDepartment]
  );

  const getOptionDisabled = useCallback(
    (option) =>
      groupSelected.some((groupedOptions) => groupedOptions.id === option.id && groupedOptions.name === option.name),
    [departmentGroups, allDepartmentGroups]
  );

  return (
    <Autocomplete<DepartmentOption, boolean, boolean, boolean>
      multiple={!notMulty}
      limitTags={limitTags ? limitTags : 1}
      size={inputSize}
      value={autocompleteValue || null}
      id="department-search"
      style={{ width, minWidth: 150 }}
      open={open}
      getOptionDisabled={getOptionDisabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => value?.id && option?.id === value?.id && option?.group === value?.group}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name || '')}
      options={options}
      groupBy={(item) => (onlySingleDepartment || showNonInvoiced ? null : item?.group)}
      loading={departmentsLoading || departmentGroupsLoading}
      renderOption={(props, option) => (
        <li {...props} key={option.id} {...DatasetTestIDs.components.profileForm.options.departments}>
          <span>{option.name}</span>
        </li>
      )}
      loadingText="Loading..."
      PopperComponent={(props) => (
        <Popper
          {...props}
          placement="bottom-start"
          sx={(theme) => ({
            minWidth: minPopperWidth ? minPopperWidth : props.style.minWidth,
            '& .MuiAutocomplete-listbox': {
              '& .MuiAutocomplete-option': {
                backgroundColor: 'transparent',
                '&[aria-selected="true"]': {
                  color: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.primary.main, 0.15),
                  '&.Mui-focused': {
                    color: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, 0.15),
                  },
                  '@media (hover: none)': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.15),
                    '&.Mui-focused': {
                      color: theme.palette.primary.main,
                      backgroundColor: alpha(theme.palette.primary.main, 0.15),
                    },
                  },
                },
                '&.Mui-focused': {
                  backgroundColor: 'transparent',
                },
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
              },
            },
          })}
        />
      )}
      renderInput={
        renderInput
          ? (params) => renderInput(params)
          : (params) => (
              <TextField
                {...params}
                label={noLabel ? '' : 'Departments'}
                variant="outlined"
                placeholder={notMulty ? 'Departments' : ''}
              />
            )
      }
      onChange={onChange}
      disabled={disabled}
    />
  );
}
