import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';

import { JobShiftForReroute } from 'types/Common/JobData';

import { JobType, JobTypeHelper } from 'Constants/job';
import { Structure_Validation, WR_Validation } from 'Constants/validation';
import ConfNumberMaterialAsyncSelect from 'Containers/Components/Controls/ConfNumberMaterialAsyncSelect';
import SupervisorMaterialAsyncSearch from 'Containers/Components/Controls/SupervisorMaterialAsyncSearch';
import ImageUpload from 'Containers/Components/ImageUpload/ImageUpload';
import { JobsAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal/AppPaperModal';

import MapSelect, { MapLocationItem } from '../RerouteWorker/MapSelect';
import RerouteWorkersList from '../RerouteWorker/RerouteWorkersList';
import OptionalField from '../components/OptionalField';
import styles from './RerouteWorkersModal.module.scss';

type Props = {
  initToJob?: boolean;
  open: boolean;
  jobId: string | number;
  selectedWorkers: JobShiftForReroute[];
  department: number;
  jobType: string | JobType;
  confirmationNumber?: number;
  is_standby: 0 | 1;
  onClose: () => void;
  onSaveSuccess?: () => void;
};

const validationSchema = Yup.object().shape({
  supervisor: Yup.number(),
  wr: WR_Validation,
});

const RerouteWorkersModal: FC<Props> = ({
  jobId,
  onClose,
  open,
  selectedWorkers,
  is_standby,
  department,
  confirmationNumber = 0,
  jobType,
  onSaveSuccess = () => {},
  initToJob = false,
}) => {
  const [rerouteToJob, setRerouteToJob] = useState(initToJob);
  const [newConfirmationNumber, setNewConfirmationNumber] = useState(null);
  const [newLocation, setNewLocation] = useState<MapLocationItem>(null);
  const [locationImages, setLocationImages] = useState<File[]>(null);
  const [workers, setWorkers] = useState(selectedWorkers);
  const [selectedFields, setSelectedFields] = useState({
    wr: false,
    supervisor: false,
  });
  const { isFlagging } = useMemo(() => new JobTypeHelper(jobType), [jobType]);
  const { inProcess, promiseWrapper, start: startLoading } = useProcessing();

  const unselectWorker = useCallback((worker: JobShiftForReroute) => {
    setWorkers((prev) => prev.filter((item) => item.workerId !== worker.workerId));
  }, []);

  const { values, errors, resetForm, setFieldValue, handleChange } = useFormik({
    initialValues: {
      supervisor: 0,
      wr: '',
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const closeModalHandler = () => {
    setRerouteToJob(false);
    setNewConfirmationNumber(null);
    setNewLocation(null);
    setLocationImages(null);
    setWorkers([]);
    resetForm();
    onClose();
  };

  const isStructureValueValid = (value: string) => {
    try {
      return Structure_Validation.isValidSync(value);
    } catch (error) {
      return false;
    }
  };

  const activateOptionalField = useCallback((field: keyof typeof values) => {
    setSelectedFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  }, []);

  const deactivateOptionalField = useCallback((field: keyof typeof values) => {
    setSelectedFields((prev) => ({
      ...prev,
      [field]: false,
    }));
  }, []);

  const titleText = useMemo(() => {
    if (!rerouteToJob) {
      return 'Re-Route to New Location';
    }
    if (newConfirmationNumber) {
      return `Re-Route to Existing Job`;
    }
    return 'Re-Route to New Job';
  }, [rerouteToJob, newConfirmationNumber]);

  const subtitleText = useMemo(() => {
    if (!rerouteToJob) {
      return 'Fill in the details of this place and add information for workers';
    }
    if (newConfirmationNumber) {
      return `${
        workers.length > 1 ? 'These workers' : 'This worker'
      } will be rerouted to the existing job ${newConfirmationNumber}`;
    }
    return 'Fill in the details of the new job and add information for workers';
  }, [rerouteToJob, newConfirmationNumber, workers.length > 1]);

  const submitEnabled = useMemo(() => {
    return !!newLocation && workers.length && workers?.every(({ structure }) => isStructureValueValid(structure));
  }, [newLocation, workers]);

  const rerouteWorkers = async () => {
    startLoading();
    const workerIds = workers?.map((worker) => {
      return {
        id: worker.workerId,
        shift_id: worker.shift_id,
        structure: worker.structure,
        note: worker.note,
      };
    });
    let images: string[] = [];
    if (locationImages) {
      const formData = new FormData();
      locationImages.forEach((image, index) => formData.append('images_' + index, image));
      try {
        images = await JobsAPI.uploadImages(formData);
      } catch (error) {
        showErrorMessage(error);
        return;
      }
    }

    const createNewJob = rerouteToJob && !newConfirmationNumber;

    const reroute_job_id = rerouteToJob ? newConfirmationNumber : null;
    const reroute_job_data = createNewJob
      ? Object.entries(values).reduce((acc, [key, value]) => {
          if (value && selectedFields[key]) {
            return { ...acc, [key]: value };
          }
          return acc;
        }, {} as Partial<typeof values>)
      : null;

    const data = {
      job_id: jobId,
      workers: workerIds,
      location: newLocation?.exist
        ? { id: newLocation.id, files: images }
        : { ...newLocation, structure: workerIds[0].structure, note: workerIds[0].note, files: images },
      files: images,
      ...(reroute_job_id && { reroute_job_id }),
      ...(reroute_job_data && { reroute_job_data }),
    };

    try {
      await promiseWrapper(rerouteToJob ? JobsAPI.rerouteWorkersFromStandBy(data) : JobsAPI.rerouteWorkers(data));
      closeModalHandler();
      showSuccessMessage('Workers have been rerouted successfully');
      onSaveSuccess();
    } catch (e) {
      showErrorMessage(e);
    }
  };

  const confirmationNumberSubtitle = useMemo(() => {
    return newConfirmationNumber
      ? 'Worker will be reroute to existing job'
      : 'Enter the confirmation number of existing job or left blank to reroute to the new one';
  }, [newConfirmationNumber]);

  useEffect(() => {
    setNewConfirmationNumber('');
    setNewLocation(null);
    resetForm();
  }, [rerouteToJob]);

  useEffect(() => {
    setWorkers((prev) =>
      prev.map((worker) =>
        newLocation?.exist
          ? { ...worker, structure: newLocation?.structure, note: newLocation?.note }
          : { ...worker, structure: '', note: '' }
      )
    );
  }, [newLocation]);

  return (
    <AppPaperModal
      modalId="reroute-workers-modal"
      title={titleText}
      subtitle={subtitleText}
      open={open}
      onClose={closeModalHandler}
      containerStyle={{
        maxWidth: 900,
        width: '100%',
      }}
      submitButton={{
        title: 'Confirm reroute',
        onClick: rerouteWorkers,
        loading: inProcess,
        disabled: !submitEnabled,
      }}
    >
      {/* Radio buttons Block */}
      {isFlagging && Boolean(is_standby) && (
        <FormControl className={classNames(styles.radioButtons, styles.wrapper)}>
          <p className={styles.subtitleModal}>Reroute to</p>
          <RadioGroup className={styles.radioGroup} row aria-label="reroute-radio-group-label">
            <FormControlLabel
              className={styles.radioLabel}
              label="New Location"
              control={<Radio checked={!rerouteToJob} onChange={() => setRerouteToJob((prev) => !prev)} />}
            />
            <FormControlLabel
              className={styles.radioLabel}
              label={newConfirmationNumber ? 'Existing Job' : 'New Job'}
              control={<Radio checked={rerouteToJob} onChange={() => setRerouteToJob((prev) => !prev)} />}
            />
          </RadioGroup>
          {rerouteToJob && <span className={styles.subtitleModal}>{confirmationNumberSubtitle}</span>}
        </FormControl>
      )}

      <Box className={classNames(styles.wrapper, styles.formWrapper)}>
        {/* Confirmation Number Select Block */}
        {rerouteToJob && (
          <Box className={classNames(styles.form)}>
            <Box>
              <ConfNumberMaterialAsyncSelect
                inputSize="small"
                label="Confirmation #"
                onSelect={(option) => setNewConfirmationNumber(option?.label)}
              />
            </Box>

            {/* Additional fields Block */}
            {!newConfirmationNumber && (
              <>
                <Box className={styles.subtitleModal}>
                  <p>These fields can replace existing values</p>
                  <Box style={{ cursor: 'pointer' }}>
                    <Tooltip
                      disableInteractive
                      title="Any fields left blank will be replaced with the values of the current job"
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </Box>
                </Box>
                <Box className={styles.additionalFields}>
                  <OptionalField
                    title="Supervisor"
                    isActive={selectedFields.supervisor}
                    name="supervisor"
                    onActivate={activateOptionalField}
                    onDeactivate={deactivateOptionalField}
                  >
                    <SupervisorMaterialAsyncSearch
                      selectedId={values.supervisor}
                      onSelect={(option) => setFieldValue('supervisor', option?.id || 0)}
                      inputSize="small"
                      departmentId={department}
                      fullWidth
                    />
                  </OptionalField>
                  <OptionalField
                    title="Work Request #"
                    name="wr"
                    isActive={selectedFields.wr}
                    onActivate={activateOptionalField}
                    onDeactivate={deactivateOptionalField}
                  >
                    <TextField
                      size="small"
                      inputProps={{ style: { height: 25 } }}
                      fullWidth
                      name="wr"
                      label="WR"
                      value={values.wr}
                      onChange={handleChange}
                      error={!!errors.wr}
                      helperText={errors.wr}
                    />
                  </OptionalField>
                </Box>
              </>
            )}
          </Box>
        )}

        {/* Location Select Block */}
        <Box className={classNames(styles.location)}>
          <MapSelect
            location={newLocation}
            onChangeLocation={setNewLocation}
            confirmationNumber={rerouteToJob ? newConfirmationNumber : confirmationNumber}
            withMap
          />
        </Box>
      </Box>

      {/* Image Upload Block */}
      <Box className={classNames(styles.wrapper, styles.imageUpload)}>
        <p className={styles.subtitleModal}>Upload the necessary photos for the selected location</p>
        <ImageUpload onChangeImage={setLocationImages} />
      </Box>

      {/* Workers Block */}
      <RerouteWorkersList
        jobType={jobType}
        location={newLocation}
        workers={workers}
        closeModal={closeModalHandler}
        onChangeWorkers={setWorkers}
        unselectWorker={unselectWorker}
      />
    </AppPaperModal>
  );
};

export default RerouteWorkersModal;
