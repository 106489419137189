import { FC, useEffect, useMemo } from 'react';

import { useFormik } from 'formik';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import { Box, Collapse, Typography } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';
import { EmailChannel, SmsChannel } from 'Utils/Icon';
import useProcessing from 'Utils/hooks/useProcessing';

import { ConfirmPasswordsSchema, LoginSchema, RecoverySchema, TwoFactorLoginSchema } from '../Validations';
import { AuthFormVariant, AuthFormContentHelper } from '../constants';
import {
  AuthActionButton,
  AuthInput,
  AuthLinkButton,
  AuthSubtitle,
  AuthTitle,
  ChannelRadioBox,
  IconWrapper,
} from '../styled';

export type TwoFactorCodeChannel = 'email' | 'sms';

export type AuthFormValues = {
  email: string;
  password: string;
  code: string;
  confirmPassword: string;
  channel: TwoFactorCodeChannel;
  apiError?: string;
};

const ValidationSchemasMap = {
  [AuthFormVariant.LOGIN]: LoginSchema,
  [AuthFormVariant.TWO_FACTOR_LOGIN]: TwoFactorLoginSchema,
  [AuthFormVariant.RECOVERY]: RecoverySchema,
  [AuthFormVariant.RESET_PASSWORD]: ConfirmPasswordsSchema,
  [AuthFormVariant.ACTIVATE_ACCOUNT]: ConfirmPasswordsSchema,
};

type LoginProps = {
  variant: AuthFormVariant.LOGIN;
  onLogin: (email: string, password: string) => Promise<void>;
  onChangeRecoveryMode: (isRecovery: boolean) => void;
};

type TwoFactorProps = {
  variant: AuthFormVariant.TWO_FACTOR_LOGIN;
  codeSent: boolean;
  onTwoFactorLogin: (code: string, channel: TwoFactorCodeChannel) => Promise<void>;
  onBackToLogin: () => void;
  onSendTwoFactorCode: (channel: TwoFactorCodeChannel) => Promise<void>;
};

type RecoveryProps = {
  variant: AuthFormVariant.RECOVERY;
  onRecoveryPassword: (email: string) => Promise<void>;
  onBackToLogin: () => void;
};

type ResetPasswordProps = {
  variant: AuthFormVariant.RESET_PASSWORD;
  token: string;
  onResetPassword: ({ password, token }: { password: string; token: string }) => Promise<void>;
  onBackToLogin: () => void;
};

type ActivateAccountProps = {
  variant: AuthFormVariant.ACTIVATE_ACCOUNT;
  token: string;
  onActivateAccount: ({ password, token }: { password: string; token: string }) => Promise<void>;
};

type Props = LoginProps | TwoFactorProps | RecoveryProps | ResetPasswordProps | ActivateAccountProps;

const AuthForm: FC<Props> = (props) => {
  const { inProcess, promiseWrapper } = useProcessing();
  const isLogin = props.variant === AuthFormVariant.LOGIN;
  const isRecovery = props.variant === AuthFormVariant.RECOVERY;
  const isResetPassword = props.variant === AuthFormVariant.RESET_PASSWORD;
  const isActivateAccount = props.variant === AuthFormVariant.ACTIVATE_ACCOUNT;
  const isTwoFactorLogin = props.variant === AuthFormVariant.TWO_FACTOR_LOGIN;
  const isCodeSent = isTwoFactorLogin && props.codeSent;

  const { texts } = new AuthFormContentHelper(props.variant);

  const { values, handleChange, errors, touched, submitForm, isSubmitting, setErrors, setFieldValue } =
    useFormik<AuthFormValues>({
      initialValues: {
        email: '',
        password: '',
        code: '',
        channel: 'email' as TwoFactorCodeChannel,
        confirmPassword: '',
      },
      validationSchema: isTwoFactorLogin && !props.codeSent ? null : ValidationSchemasMap[props.variant],
      onSubmit: async ({ email, password, channel, code }) => {
        try {
          if (isLogin) {
            await props.onLogin(email, password);
            return;
          }

          if (isRecovery) {
            await props.onRecoveryPassword(email);
            return;
          }

          if (isResetPassword) {
            await props.onResetPassword({ password, token: props.token });
            return;
          }

          if (isActivateAccount) {
            await props.onActivateAccount({ password, token: props.token });
            return;
          }

          if (isTwoFactorLogin) {
            if (!props.codeSent) {
              await props.onSendTwoFactorCode(channel);
              return;
            }
            await props.onTwoFactorLogin(code, channel);
            return;
          }
        } catch (e) {
          setErrors({ apiError: e.error || e.message || 'Something went wrong. Please try again later' });
        }
      },
    });

  const toRecovery = () => {
    if (props.variant === AuthFormVariant.LOGIN) {
      props.onChangeRecoveryMode(true);
      return;
    }
  };

  const toLogin = () => {
    switch (props.variant) {
      case AuthFormVariant.RECOVERY:
      case AuthFormVariant.RESET_PASSWORD:
      case AuthFormVariant.TWO_FACTOR_LOGIN:
        props.onBackToLogin();
        break;
      default:
        break;
    }
  };

  const resendCode = async () => {
    if (!isTwoFactorLogin) return;
    try {
      await promiseWrapper(props.onSendTwoFactorCode(values.channel));
    } catch (e) {
      setErrors({ apiError: e.error || e.message || 'Something went wrong. Please try again later' });
    }
  };

  const changeChannel = (channel: 'email' | 'sms') => {
    setFieldValue('channel', channel);
  };

  const formSubtitle = useMemo(() => {
    if (isTwoFactorLogin) {
      if (!isCodeSent) {
        return 'Please select where to send your verification code';
      }
      return `Enter the code that was sent to your ${values.channel === 'email' ? 'email' : 'phone number'}.`;
    }
    return texts.subtitle;
  }, [isTwoFactorLogin, isCodeSent, values.channel]);

  useEffect(() => {
    setErrors({ apiError: '' });
  }, [props.variant]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submitForm();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" flex={1} py={4} justifyContent="center">
      {/* Back to login  */}
      <Collapse in={isRecovery || isResetPassword || isTwoFactorLogin}>
        <Box width="100%" display="flex" mb={1}>
          <AuthLinkButton
            onClick={toLogin}
            startIcon={<ArrowBackIcon />}
            {...DatasetTestIDs.pages.login.buttons.backToLogin}
          >
            Back to login
          </AuthLinkButton>
        </Box>
      </Collapse>

      <AuthTitle>{texts.title}</AuthTitle>
      <AuthSubtitle mt={'6px'}>{formSubtitle}</AuthSubtitle>

      {/* Email Input */}
      <Collapse in={isLogin || isRecovery}>
        <AuthInput
          name="email"
          value={values.email}
          onChange={handleChange}
          sx={{ mt: '30px' }}
          fullWidth
          label="Email"
          placeholder="Input your email here"
          error={(!!touched.email && !!errors.email) || !!errors.apiError}
          helperText={!!touched.email && errors.email}
          inputProps={{ ...DatasetTestIDs.pages.login.fields.email }}
        />
      </Collapse>

      {/* Password Input */}
      <Collapse in={!isRecovery && !isTwoFactorLogin}>
        <AuthInput
          name="password"
          value={values.password}
          onChange={handleChange}
          sx={{ mt: 2 }}
          fullWidth
          label={isResetPassword || isActivateAccount ? 'New Password' : 'Password'}
          placeholder={isResetPassword || isActivateAccount ? 'New Password' : 'Password'}
          type="password"
          error={(!!touched.password && !!errors.password) || !!errors.apiError}
          helperText={!!touched.password && errors.password}
          inputProps={{ ...DatasetTestIDs.pages.login.fields.password }}
        />
      </Collapse>

      {/* Forgot password  */}
      <Collapse in={isLogin}>
        <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
          <AuthLinkButton onClick={toRecovery} {...DatasetTestIDs.pages.login.buttons.forgotPassword}>
            Forgot Password
          </AuthLinkButton>
        </Box>
      </Collapse>

      {/* Confirm Password Input */}
      <Collapse in={isActivateAccount || isResetPassword}>
        <AuthInput
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          sx={{ mt: 2 }}
          fullWidth
          label="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          error={(!!touched.confirmPassword && !!errors.confirmPassword) || !!errors.apiError}
          helperText={!!touched.confirmPassword && errors.confirmPassword}
        />
      </Collapse>

      {/* Channel to receive code radio buttons */}
      <Collapse in={isTwoFactorLogin}>
        <Box display="flex" flexDirection="column" mt={'30px'} gap={'10px'}>
          <ChannelRadioBox
            selected={values.channel === 'email'}
            onClick={() => changeChannel('email')}
            {...DatasetTestIDs.pages.login.radio.twoFactorAuthByEmail}
          >
            <Box display="flex" alignItems="flex-start" gap="16px">
              <IconWrapper selected={values.channel === 'email'}>
                <EmailChannel />
              </IconWrapper>
              <Box display="flex" flexDirection="column" gap={'2px'} maxWidth={240}>
                <Typography fontFamily={'Poppins Medium'} color="#000" fontSize={14}>
                  Email
                </Typography>
                <Typography fontFamily={'Poppins Regular'} color="#7E91A3" fontSize={12} lineHeight={'150%'}>
                  The code will be sent to your email
                </Typography>
              </Box>
            </Box>
            <CheckCircleIcon sx={{ fontSize: 24 }} />
          </ChannelRadioBox>
          <ChannelRadioBox
            selected={values.channel === 'sms'}
            onClick={() => changeChannel('sms')}
            {...DatasetTestIDs.pages.login.radio.twoFactorAuthBySms}
          >
            <Box display="flex" alignItems="flex-start" gap="16px">
              <IconWrapper selected={values.channel === 'sms'}>
                <SmsChannel />
              </IconWrapper>
              <Box display="flex" flexDirection="column" gap={'2px'} maxWidth={240}>
                <Typography fontFamily={'Poppins Medium'} color="#000" fontSize={14}>
                  Phone number
                </Typography>
                <Typography fontFamily={'Poppins Regular'} color="#7E91A3" fontSize={12} lineHeight={'150%'}>
                  The code will be sent to your phone number
                </Typography>
              </Box>
            </Box>
            <CheckCircleIcon sx={{ fontSize: 24 }} />
          </ChannelRadioBox>
        </Box>
      </Collapse>

      {/* Code Input */}
      <Collapse in={isTwoFactorLogin && props.codeSent}>
        <AuthInput
          name="code"
          value={values.code}
          onChange={handleChange}
          sx={{ mt: 2 }}
          fullWidth
          label="Code"
          placeholder="Input your code here"
          autoComplete="one-time-code"
          error={(!!touched.code && !!errors.code) || !!errors.apiError}
          helperText={!!touched.code && errors.code}
          inputProps={{ ...DatasetTestIDs.pages.login.fields.code }}
        />
      </Collapse>

      {/* API Error */}
      <Collapse in={!!errors.apiError} sx={{ mt: 2 }}>
        <Typography variant="caption" color="error" fontSize={14} fontFamily="Poppins Regular">
          {errors.apiError ? `*${errors.apiError}` : ' '}
        </Typography>
      </Collapse>

      {/* Submit Button */}
      <AuthActionButton
        sx={{ mt: 2 }}
        fullWidth
        variant="contained"
        loading={isSubmitting}
        onClick={submitForm}
        {...DatasetTestIDs.pages.login.buttons.signIn}
      >
        {isTwoFactorLogin && !isCodeSent ? 'Send' : texts.submitButton}
      </AuthActionButton>

      <Collapse in={isTwoFactorLogin}>
        <Box display="flex" justifyContent="center" alignItems="center" mt="30px" gap={1}>
          <Typography fontFamily={'Poppins Medium'} color="#7E91A3">
            Haven’t received the code?
          </Typography>
          <LoadingButton
            onClick={resendCode}
            loading={inProcess}
            sx={{ textDecoration: 'underline', p: 0, fontFamily: 'Poppins Bold' }}
            color="primary"
          >
            Resend
          </LoadingButton>
        </Box>
      </Collapse>
    </Box>
  );
};

export default AuthForm;
