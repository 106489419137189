import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { Field } from 'formik';
import generalStyles from 'general.module.scss';
import moment from 'moment';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Box, FormControlLabel, Typography, Checkbox, Select, MenuItem, Collapse } from '@mui/material';

import { JobTypeHelper } from 'Constants/job';
import AppPermissions from 'Constants/permissions';
import { inputDateFormat } from 'Constants/time';
import { TimesheetNames, TimesheetStatus } from 'Constants/timesheet';
import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import { FORMATS } from 'Utils/Date';
import UserPermissions from 'Utils/PermissionsHelper';
import useModal from 'Utils/hooks/useModal';
import AppInput from 'components/AppInput';
import AppPaperModal from 'components/AppPaperModal';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';
import { ReduxState, useAppSelector } from 'createStore';

import styles from './DateTimeInfo.module.scss';

const DateTimeInfo = () => {
  const initTimesheet = useAppSelector((state) => state.timesheets.timesheet);

  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;
  const permissions = useSelector((state: ReduxState) => state.app.permissions);

  const billableHoursShowed = timesheet.status !== TimesheetStatus.clockedIn;
  const dateConfirmationModal = useModal();
  const { isParking, isFlagging } = new JobTypeHelper(timesheet.job_type);
  const showHoursLimitRadioButtons = isFlagging && UserPermissions.has.override_timesheets_min_flagging_hours_limit;
  const [overrideHoursLimit, setOverrideHoursLimit] = useState(timesheet.use_min_flagging_hours_limit !== null);

  const isDateEnabled = () => {
    // If the job type is parking and timesheet is verified - user can change date but signature is required
    // User must has special permission for this action
    const canChangeParkingDate = isParking
      ? !formikBag.isLockedByConEdVerified && permissions.includes(AppPermissions.adjust_ces_verified_timesheets)
      : !formikBag.isLockedByConEdVerified;
    return (
      !timesheet.workerPaid &&
      timesheet.job_status !== 'cancelled' &&
      timesheet.status !== TimesheetStatus.cancelled &&
      canChangeParkingDate &&
      !timesheet.invoiced &&
      !timesheet.paid
    );
  };

  const onBlurStartDate = () => {
    if (moment(timesheet.startDate).isBefore(timesheet.requestDate)) {
      dateConfirmationModal.open();
    }
  };

  const resetDated = () => {
    formikBag.setFieldValue('startDate', initTimesheet.startDate);
    dateConfirmationModal.isOpen && dateConfirmationModal.close();
  };

  useEffect(() => {
    const totalHours = moment(formikBag.values.finishDate).diff(formikBag.values.startDate, 'hours', true);
    if (formikBag.values.use_min_flagging_hours_limit && totalHours < 4) {
      formikBag.setFieldValue(TimesheetNames.totalHours, 4);
      return;
    }
    formikBag.setFieldValue(TimesheetNames.totalHours, totalHours);
  }, [formikBag.values.startDate, formikBag.values.finishDate, formikBag.values.use_min_flagging_hours_limit]);

  return (
    <>
      <TimesheetBox>
        <Box className={generalStyles.inputsBox}>
          <AppInput label="Requestor" disabled value={timesheet.requestorName} />

          <AppInput label="Request Date" type="datetime-local" value={timesheet.requestDate} disabled />
          <Field name={TimesheetNames.startDate}>
            {({ field }) => (
              <AppInput
                {...field}
                onBlur={onBlurStartDate}
                FormHelperTextProps={{ className: styles.helperText }}
                onChange={field.onChange}
                className={styles.date}
                error={formikBag.errors.startDate}
                helperText={!!formikBag.errors.startDate && formikBag.errors.startDate}
                label="Start Date"
                type="datetime-local"
                disabled={!isDateEnabled()}
              />
            )}
          </Field>
          <Field name={TimesheetNames.finishDate}>
            {({ field }) => (
              <AppInput
                {...field}
                FormHelperTextProps={{ className: styles.helperText }}
                onChange={field.onChange}
                className={styles.date}
                inputProps={{ min: moment(timesheet.startDate).format(inputDateFormat) }}
                error={formikBag.errors.finishDate}
                helperText={!!formikBag.errors.finishDate && formikBag.errors.finishDate}
                label="Finish Date"
                type="datetime-local"
                disabled={!isDateEnabled()}
              />
            )}
          </Field>
          <Box className={styles.hours}>
            <Box display="flex">
              <AppInput
                className={classNames(formikBag.errors.totalHours && styles.error)}
                color={'secondary'}
                name={TimesheetNames.totalHours}
                error={!!formikBag.errors.totalHours}
                sx={{
                  '& .MuiOutlinedInput-root': billableHoursShowed
                    ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                    : {},
                }}
                disabled
                fullWidth
                label="Total hours"
                value={timesheet.totalHours >= 0 ? timesheet.totalHours.toFixed(2) : '–.––'}
              />
              {billableHoursShowed && (
                <AppInput
                  color={'secondary'}
                  name={TimesheetNames.total_billable_hours}
                  disabled
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,

                      '& > fieldset': {
                        borderLeft: 'none',
                      },
                    },
                  }}
                  label="Billable hours"
                  value={timesheet.total_billable_hours.toFixed(2)}
                />
              )}
              {formikBag.errors.totalHours && (
                <span className={styles.validateMessage}>{formikBag.errors.totalHours}</span>
              )}
            </Box>
            {showHoursLimitRadioButtons && (
              <Box display="flex" flexDirection="column" width="100%">
                <FormControlLabel
                  sx={{ m: 0, '& .MuiFormControlLabel-label': { fontSize: 14 } }}
                  checked={overrideHoursLimit}
                  onChange={(e, checked) => {
                    setOverrideHoursLimit(checked);
                    formikBag.setFieldValue(TimesheetNames.use_min_flagging_hours_limit, checked ? 1 : null);
                  }}
                  control={
                    <Checkbox size="small" icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} />
                  }
                  label={`Override 4 hours min rule`}
                />
                <Collapse in={overrideHoursLimit}>
                  <Select
                    size="small"
                    sx={{ width: '100%' }}
                    value={timesheet.use_min_flagging_hours_limit}
                    onChange={(e) =>
                      formikBag.setFieldValue(TimesheetNames.use_min_flagging_hours_limit, e.target.value)
                    }
                  >
                    <MenuItem value={1}>Apply 4 hour min</MenuItem>
                    <MenuItem value={0}>Apply actual hours</MenuItem>
                  </Select>
                </Collapse>
              </Box>
            )}
          </Box>
        </Box>
      </TimesheetBox>

      {dateConfirmationModal.isOpen && (
        <AppPaperModal
          confirmRequired
          open={dateConfirmationModal.isOpen}
          onClose={resetDated}
          title={'Start date confirmation'}
          subtitle={'Please confirm a new start date'}
          submitButton={{
            title: 'Confirm',
            onClick: dateConfirmationModal.close,
          }}
          cancelButton={{
            title: 'Reset',
            onClick: resetDated,
          }}
        >
          <Typography maxWidth={500}>
            The new start date <b>({moment(formikBag.values.startDate).format(FORMATS.timesheetDate)})</b> of this
            timesheet is before the work request date{' '}
            <b>({moment(formikBag.values.requestDate).format(FORMATS.timesheetDate)})</b>.
          </Typography>
          <br />
          <Typography fontWeight={'bold'}>Do you really want to change this date?</Typography>
        </AppPaperModal>
      )}
    </>
  );
};

export default DateTimeInfo;
