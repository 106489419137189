import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Select, { CSSObjectWithLabel } from 'react-select';

import { ConEdWorkerForTable } from 'types/Common/User';

import { actions } from 'Services';
import { useAppSelector } from 'createStore';

interface Props {
  onSelect?: (item: { label: string; value: ConEdWorkerForTable }) => void;
  onClear?: () => void;
  placeholder?: string;
  isClearable?: boolean;
  disabled?: boolean;
  selectedId?: number;
  showAllWorkers?: boolean;
  className?: string;
}

export const WorkerAsyncSearch = ({
  onSelect = () => {},
  onClear = () => {},
  placeholder = 'Select Worker',
  isClearable = false,
  disabled = false,
  selectedId = 0,
  showAllWorkers = false,
  className = '',
}: Props) => {
  const dispatch = useDispatch();
  const allWorkers = useAppSelector((state) => state.workers.workers);
  const options = useMemo(() => {
    if (showAllWorkers) {
      return allWorkers.asMutable({ deep: true }).map((value) => ({ label: value.name, value }));
    }
    return allWorkers
      .asMutable({ deep: true })
      .filter(({ status }) => status.toLowerCase() === 'active')
      .map((value) => ({ label: value.name, value }));
  }, [allWorkers]);

  useEffect(() => {
    dispatch(actions.WorkersActions.retrieve());
  }, []);

  const currentValue = options.find(({ value }) => value?.id === selectedId) || null;

  return (
    <Select
      isClearable={isClearable}
      placeholder={placeholder}
      options={options}
      isDisabled={disabled}
      onChange={onSelect}
      defaultInputValue={currentValue?.label}
      value={currentValue}
      className={className}
      styles={{ menu: (base) => ({ ...base, zIndex: 10 } as CSSObjectWithLabel) }}
    />
  );
};
