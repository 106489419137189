import { FC, memo } from 'react';

import { Typography } from '@mui/material';

type Props = {
  title: string;
};

const ChatsTitle: FC<Props> = ({ title }) => {
  return (
    <Typography variant="h4" mb="20px">
      {title}
    </Typography>
  );
};

export default memo(ChatsTitle);
