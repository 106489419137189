import moment from 'moment';

import { Tooltip } from '@mui/material';

import { JobStatus, JobType } from 'Constants/job';
import { Complaints } from 'Models/APITypes';
import app_history from 'app_history';

import ComplaintItemNote from './ComplaintItemNote';

type Props = {
  complaint: Complaints;
};

const ComplaintItem = ({ complaint }: Props) => {
  const gotoDetails = () => {
    app_history.push(`/job/${complaint.job_id}`);
  };

  return (
    <div className="box-item-body hover-item-body cursor-pointer radius">
      <div>
        <div
          className="job-item border-bottom d-flex justify-content-between align-items-center header-item"
          onClick={gotoDetails}
        >
          <div className="d-flex align-items-center">
            <span className="ce-title">{`${JobType[complaint.type_id]} #${complaint.job_id}`}</span>
          </div>
          <div className="d-flex">
            <div className="text-right">
              <div className="job-status">{JobStatus.parsedFromNumber(parseInt(complaint.jobStatus))}</div>
            </div>
          </div>
        </div>
        <div className="job-item flex-mobile d-flex align-items-center job-details border-bottom" onClick={gotoDetails}>
          <div className="text-left job-info">
            <div className="label" style={{ textTransform: 'capitalize' }}>
              Request Time & date
            </div>
            <div className="text-14-500">{moment(complaint.start_at).format('MM/DD/YY HH:mm')}</div>
          </div>

          <div className="text-left job-info">
            <div className="label" style={{ textTransform: 'capitalize' }}>
              Supervisor
            </div>
            <div className="text-14-500">{complaint.supervisor_name ? complaint.supervisor_name : '—'}</div>
          </div>

          <div className="text-left job-info">
            <div className="label" style={{ textTransform: 'capitalize' }}>
              Department
            </div>
            <div className="text-14-500">{complaint.department_name}</div>
          </div>

          <div className="text-left job-info">
            <div className="label" style={{ textTransform: 'capitalize' }}>
              Requestor
            </div>
            <div className="text-14-500">{complaint.requestor_name}</div>
          </div>

          <div className="w-100 job-info-right job-max-workers">
            <div className="label" style={{ textTransform: 'capitalize' }}>
              Max Workers
            </div>
            <div className="text-14-500">{complaint.max_workers}</div>
          </div>
          <Tooltip title={complaint.po_number ? complaint.po_number : ''} arrow aria-label="po">
            <div className="w-100 job-info-right">
              <div className="label">PO#</div>
              <div className="text-14-500">
                {complaint.po_number && complaint.po_number.length > 12
                  ? `${complaint.po_number.slice(0, 11)}...`
                  : complaint.po_number}
                {'\u00A0'}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="job-history-page" style={{ padding: 0 }}>
        {complaint.notes.map((note) => (
          <ComplaintItemNote note={note} key={note.id} />
        ))}
      </div>
    </div>
  );
};

export default ComplaintItem;
