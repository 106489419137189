/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  Tooltip,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import AppPermissions from 'Constants/permissions';
import { TABLE_HEADER, WORKER_STATUS_SELECT } from 'Constants/worker';
import { actions } from 'Services';
import { clipArrayPerPage } from 'Utils/worker';
import app_history from 'app_history';
import { ReduxState } from 'createStore';

import ChangeWorkerStatusModal from './ChangeWorkerStatusModal';
import { WORKER_TYPE } from './Constants';
import classes from './WorkersSortTable.module.scss';

const WorkersSortTable = () => {
  const dispatch = useDispatch();

  const permissions = useSelector((state: ReduxState) => state.app.permissions);
  const filteredWorkers = useSelector((state: ReduxState) => state.workers.filteredWorkers);
  const tablePagination = useSelector((state: ReduxState) => state.workers.tablePagination);
  const searchParams = useSelector((state: ReduxState) => state.workers.search_options);

  const [statusSelectOpened, setStatusSelectOpened] = useState('');

  const openStatusSelect = useCallback((e: React.SyntheticEvent) => setStatusSelectOpened((e.target as any).id), []);
  const closeStatusSelect = useCallback((e: React.ChangeEvent<{}>) => {
    e.stopPropagation();
    setStatusSelectOpened('');
  }, []);

  const [updateWorker, setUpdateWorker] = useState<{ workerId: number; workerName: string; newStatus: string }>(null);

  const handleRowClick = useCallback(
    (id = 0) =>
      (e: React.MouseEvent<HTMLTableRowElement> & React.ChangeEvent<{ id: string }>) => {
        app_history.push(`/workers/${id}`);
      },
    []
  );

  const handleChange = useCallback(
    (id, name) => (event: SelectChangeEvent<string>) => {
      event.stopPropagation();
      const { value } = event.target;
      const newStatus = typeof value === 'string' ? value : '';
      setUpdateWorker({
        workerId: id,
        workerName: name,
        newStatus,
      });
    },
    []
  );

  const stopPropagation = useCallback((e) => e.stopPropagation(), []);

  const handleSort = (order_by = '') => {
    const { order_by: oldOrderBy, order_by_type } = searchParams;
    dispatch(
      actions.WorkersActions.updateFilters({
        order_by,
        order_by_type: oldOrderBy === order_by ? !order_by_type : true,
      })
    );
  };

  const renderTableCellLabel = (label) => {
    if (label.length > 20) {
      return (
        <Tooltip title={label} placement="top">
          <div>{label.substr(0, 20)}...</div>
        </Tooltip>
      );
    } else {
      return label;
    }
  };

  const renderType = (workerTypes: number[]) => {
    const typeList = [];
    WORKER_TYPE.map((type) => (workerTypes.find((item) => type.value === item) ? typeList.push(type.label) : ''));
    const typeString = typeList.join(', ');
    if (typeString.length > 20) {
      return (
        <Tooltip title={typeString} placement="top">
          <div>{typeString.substr(0, 20)}...</div>
        </Tooltip>
      );
    } else {
      return typeString;
    }
  };

  const HeaderRow = useMemo(
    () =>
      TABLE_HEADER.map((headCell) => {
        if (headCell.sortable) {
          return (
            <TableCell key={headCell.value} className={classes.tableHeaderCell}>
              <TableSortLabel
                active={searchParams.order_by === headCell.value}
                direction={searchParams.order_by === headCell.value && searchParams.order_by_type ? 'asc' : 'desc'}
                onClick={() => handleSort(headCell.value)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        } else {
          return (
            <TableCell key={headCell.value} className={classes.tableHeaderCell}>
              {headCell.label}
            </TableCell>
          );
        }
      }),
    [searchParams.order_by, searchParams.order_by_type]
  );

  const SelectOptions = useMemo(
    () =>
      WORKER_STATUS_SELECT.map((status, index) => (
        <MenuItem key={index} value={status.value} disabled={status.value === 'waiting_for_approval'}>
          {status.label}
        </MenuItem>
      )),
    [WORKER_STATUS_SELECT]
  );

  const FilteredWorkers = useMemo(
    () =>
      clipArrayPerPage(filteredWorkers.asMutable({ deep: true }), tablePagination).map((row) => (
        <TableRow
          className={classes.tableBodyRow}
          key={row.id + row.name}
          id="table-row"
          onClick={handleRowClick(row.id)}
        >
          <TableCell className={classes.tableBodyCell}>{row.uid}</TableCell>
          <TableCell className={classes.tableBodyCell}>
            {permissions.includes(AppPermissions.deactivate_workers) ? (
              <Select
                className={classNames({
                  'is-active': row.status.toLowerCase() === 'active',
                  'is-inactive': row.status.toLowerCase() === 'inactive',
                  'is-onhold': row.status.toLowerCase() === 'onhold',
                  'is-rejected': row.status.toLowerCase() === 'rejected',
                  'is-waiting': row.status.toLowerCase() === 'waiting_for_approval',
                })}
                style={{ zIndex: 10 }}
                labelId={row.id + row.name + '-label'}
                id={row.id + row.name}
                open={statusSelectOpened === row.id + row.name}
                onClose={closeStatusSelect}
                onOpen={openStatusSelect}
                value={row.status.toLowerCase()}
                onChange={handleChange(row.id, row.name)}
                onClick={stopPropagation}
                variant="standard"
              >
                {SelectOptions}
              </Select>
            ) : (
              row.status
            )}
          </TableCell>
          <TableCell className={classes.workerName}>{row.name}</TableCell>
          <TableCell className={classes.tableBodyCell}>{row.phoneNumber}</TableCell>
          <TableCell className={classes.tableBodyCell}>{row.email}</TableCell>
          <TableCell className={classes.tableBodyCell}>
            {Array.isArray(row.workerTypes) ? renderType(row.workerTypes) : null}
          </TableCell>
          <TableCell className={classes.tableBodyCell}>
            {row.subcontractorName && renderTableCellLabel(row.subcontractorName)}
          </TableCell>
          <TableCell className={classes.tableBodyCell}>
            {renderTableCellLabel(row.app_versions) || 'Unavailable'}
          </TableCell>
        </TableRow>
      )),
    [filteredWorkers, statusSelectOpened, tablePagination]
  );

  return (
    <>
      <Paper style={{ borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>{HeaderRow}</TableRow>
            </TableHead>
            <TableBody>{FilteredWorkers}</TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <ChangeWorkerStatusModal {...updateWorker} onClose={() => setUpdateWorker(null)} />
    </>
  );
};

export default React.memo(WorkersSortTable);
