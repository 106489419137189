import { JOB_STATUSES, JobStatusHelper, JobStatusString, JobTypeHelper } from 'Constants/job';
import { UpdatePOSData } from 'Services/API/JobsAPI.service';

export const isJobVerifiable = (jobType: string | number, jobIsImported: boolean | number) => {
  const { isParking, isFlagging } = new JobTypeHelper(jobType);

  return (isParking || isFlagging) && !jobIsImported;
};

export const canVerifyJob = (
  jobType: string | number,
  jobStatus: JobStatusString | JOB_STATUSES,
  jobIsImported: boolean | number
) => {
  const jobVerifiable = isJobVerifiable(jobType, jobIsImported);
  const { isCancelled, isCancelledBillable } = new JobStatusHelper(jobStatus);

  return jobVerifiable && !isCancelled && !isCancelledBillable;
};

export const getUpdatedPOFields = (posData: UpdatePOSData) => {
  const { update_po, update_receipt, update_requisition, update_wr, update_account_no, update_poet } = posData;
  return [
    update_po ? 'PO#' : null,
    update_receipt ? 'Receipt#' : null,
    update_requisition ? 'Requisition#' : null,
    update_wr ? 'WR#' : null,
    update_account_no ? 'Job#' : null,
    update_poet ? 'POET#' : null,
  ]
    .filter((el) => el)
    .join(', ');
};
