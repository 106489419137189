export const GET_CONFIGURATIONS_REQUEST = '.get_configurations_request';
export const GET_CONFIGURATIONS_SUCCESS = '.get_configurations_success';
export const GET_CONFIGURATIONS_ERROR = '.get_configurations_error';

export const GET_CONFIGURATION_REQUEST = '.get_configuration_request';
export const GET_CONFIGURATION_SUCCESS = '.get_configuration_success';
export const GET_CONFIGURATION_ERROR = '.get_configuration_error';

export const GET_CONFIGURATION_INVOICES_REQUEST = '.get_configuration_invoices_request';
export const GET_CONFIGURATION_INVOICES_SUCCESS = '.get_configuration_invoices_success';
export const GET_CONFIGURATION_INVOICES_ERROR = '.get_configuration_invoices_error';

export const GET_INVOICES_REQUEST = '.get_invoices_request';
export const GET_INVOICES_SUCCESS = '.get_invoices_success';
export const GET_INVOICES_ERROR = '.get_invoices_error';

export const UPDATE_INVOICES_OPTIONS = 'invoices.update_invoices_options';

export const UPDATE_INVOICES_SEARCH_OPTIONS = 'invoices.update_sub_invoices_search_options';

export const GET_INVOICE_TIMESHEETS_REQUEST = '.get_invoice_timesheets_request';
export const GET_INVOICE_TIMESHEETS_SUCCESS = '.get_invoice_timesheets_success';
export const GET_INVOICE_TIMESHEETS_ERROR = '.get_invoice_timesheets_error';

export const GET_INVOICE_REQUEST = '.get_sub_invoice_request';
export const GET_INVOICE_SUCCESS = '.get_sub_invoice_success';
export const GET_INVOICE_ERROR = '.get_sub_invoice_error';

export const CREATE_INVOICE_REQUEST = 'invoices.create_sub_invoice_request';
export const CREATE_INVOICE_SUCCESS = 'invoices.create_sub_invoice_success';
export const CREATE_INVOICE_ERROR = 'invoices.create_sub_invoice_error';

export const CREATE_INVOICE_FROM_CONFIG_REQUEST = 'invoices.create_invoice_from_config_request';
export const CREATE_INVOICE_FROM_CONFIG_SUCCESS = 'invoices.create_invoice_from_config_success';
export const CREATE_INVOICE_FROM_CONFIG_ERROR = 'invoices.create_invoice_from_config_error';

export const UPDATE_INVOICE_REQUEST = 'invoices.update_invoice_request';
export const UPDATE_INVOICE_SUCCESS = 'invoices.update_invoice_success';
export const UPDATE_INVOICE_ERROR = 'invoices.update_invoice_error';

export const DELETE_INVOICE_REQUEST = 'invoices.delete_invoice_request';
export const DELETE_INVOICE_SUCCESS = 'invoices.delete_invoice_success';
export const DELETE_INVOICE_ERROR = 'invoices.delete_invoice_error';

export const REVERT_INVOICE_REQUEST = 'invoices.revert_invoice_request';
export const REVERT_INVOICE_SUCCESS = 'invoices.revert_invoice_success';
export const REVERT_INVOICE_ERROR = 'invoices.revert_invoice_error';

export const UPDATE_INVOICE_STATUS_REQUEST = 'invoices.update_invoice_status_request';
export const UPDATE_INVOICE_STATUS_SUCCESS = 'invoices.update_invoice_status_success';
export const UPDATE_INVOICE_STATUS_ERROR = 'invoices.update_invoice_status_error';

export const ASSIGN_MORE_TIMESHEETS_REQUEST = 'invoices.assign_more_timesheets_request';
export const ASSIGN_MORE_TIMESHEETS_SUCCESS = 'invoices.assign_more_timesheets_success';
export const ASSIGN_MORE_TIMESHEETS_ERROR = 'invoices.assign_more_timesheets_error';

export const DOWNLOAD_INVOICE_REQUEST = 'invoices.download_invoice_request';
export const DOWNLOAD_INVOICE_SUCCESS = 'invoices.download_invoice_success';
export const DOWNLOAD_INVOICE_ERROR = 'invoices.download_invoice_error';

export const DOWNLOAD_POS_REQUEST = 'invoices.download_pos_request';
export const DOWNLOAD_POS_SUCCESS = 'invoices.download_pos_success';
export const DOWNLOAD_POS_ERROR = 'invoices.download_pos_error';

export const EDIT_TIME_INVOICE_REQUEST = 'invoices.edit_time_invoice_request';
export const EDIT_TIME_INVOICE_SUCCESS = 'invoices.edit_time_invoice_success';
export const EDIT_TIME_INVOICE_ERROR = 'invoices.edit_time_invoice_error';

export const MARK_TIMESHEET_AS_PAID_REQUEST = 'invoices.mark_timesheet_as_paid_request';
export const MARK_TIMESHEET_AS_PAID_SUCCESS = 'invoices.mark_timesheet_as_paid_success';
export const MARK_TIMESHEET_AS_PAID_ERROR = 'invoices.mark_timesheet_as_paid_error';

export const GET_TIMESHEETS_TOTAL_HOURS_REQUEST = 'invoices.get_timesheets_total_hours_request';
export const GET_TIMESHEETS_TOTAL_HOURS_SUCCESS = 'invoices.get_timesheets_total_hours_success';
export const GET_TIMESHEETS_TOTAL_HOURS_ERROR = 'invoices.get_timesheets_total_hours_error';

export const REMOVE_UNPAID_TIMESHEET_REQUEST = 'invoices.remove_unpaid_timesheet_request';
export const REMOVE_UNPAID_TIMESHEET_SUCCESS = 'invoices.remove_unpaid_timesheet_success';
export const REMOVE_UNPAID_TIMESHEET_ERROR = 'invoices.remove_unpaid_timesheet_error';

export const UPDATE_FILTERS = 'invoices.update_fiters_invoices';
