import { Component } from 'react';
import { connect } from 'react-redux';

import { CSSObjectWithLabel, components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { ResponseSubcontractorItem } from 'types/Subcontractors';

import { addTestID } from 'Constants/tests';
import { SubcontractorsAPI } from 'Services/API';
import { ReduxState } from 'createStore';

export interface SubcontractorSelectItem {
  label: string;
  value: ResponseSubcontractorItem;
}
interface Props {
  onSelect?: (item: SubcontractorSelectItem) => void;
  onClear?: () => void;
  placeholder?: string;
  isClearable?: boolean;
  filterFunc?: (item: SubcontractorSelectItem) => boolean;
  searchParams?: any;
  selectedName?: string;
  selectedId?: number;
  isMulti?: boolean;
  subcontractors: ReduxState['subcontractors']['subcontractors'];
  processing: boolean;
  testID?: string;
  menuListTestID?: string;
}

class SubcontractorAsyncSearch extends Component<Props> {
  static defaultProps: Partial<Props> = {
    onSelect: undefined,
    onClear: undefined,
    isClearable: true,
    filterFunc: (item) => true,
    searchParams: {},
    selectedId: 0,
    selectedName: '',
  };

  responseToOption = (subcontractorsResponse: ResponseSubcontractorItem[]) =>
    subcontractorsResponse
      .filter((subcontractor) => subcontractor.subcontractor != null)
      .map((subcontractor) => ({
        label: subcontractor.subcontractor.subcontractorName,
        value: subcontractor,
      }))
      .filter(this.props.filterFunc);

  findSubcontractors = async (value) => {
    const result = [];

    const response = await SubcontractorsAPI.getAll({
      ...this.props.searchParams,
      firstName: value,
    });
    if (response) {
      const subcontractors = this.responseToOption(response.results);

      return [...result, ...subcontractors];
    }
    return [...result];
  };

  findSubcontractor = (options: SubcontractorSelectItem[] = [], name = '', id = 0) => {
    if (name && id) {
      return options.find((s) => s.value?.id === id && s.label === name);
    }
    if (name && !id) {
      return options.find((s) => s.label === name);
    }
    if (!name && id) {
      return options.find((s) => s.value?.id === id);
    }
    return null;
  };

  render() {
    const { selectedId, selectedName, subcontractors, testID = '', menuListTestID = '' } = this.props;
    const defaultOptions = this.responseToOption(subcontractors.asMutable({ deep: true }));
    const selectValue = this.findSubcontractor(defaultOptions, selectedName, selectedId);

    return (
      <AsyncSelect
        value={selectValue}
        components={{
          Input: (props) => <components.Input {...props} {...addTestID(testID)} />,
          MenuList: (props) => (
            <components.MenuList {...props} innerProps={{ ...props.innerProps, ...addTestID(menuListTestID) }} />
          ),
        }}
        onChange={this.props.onSelect}
        cacheOptions
        isLoading={this.props.processing}
        defaultOptions={defaultOptions}
        isClearable={this.props.isClearable}
        placeholder={this.props.placeholder || 'Add Subcontractor'}
        loadOptions={this.findSubcontractors}
        styles={{ menu: (base) => ({ ...base, zIndex: 10 } as CSSObjectWithLabel) }}
      />
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  subcontractors: state.subcontractors.subcontractors,
  processing: state.subcontractors.processing,
});

export default connect(mapStateToProps, null)(SubcontractorAsyncSearch);
