import React, { useState } from 'react';

import { Dialog, DialogActions, DialogTitle } from '@mui/material';

import { DispatchJob } from 'types/Dispatch';
import { Job } from 'types/Job';

import { JobStatus } from 'Constants/job';
import AppPermissions from 'Constants/permissions';
import { ShiftStatus, WorkerStatus } from 'Constants/worker';
import CancelJobReason from 'Containers/Dispatch/dialog/CancelJobReason/CancelJobReason';
import confirmAlert from 'Utils/confirmAlert';
import useGetTotalHours from 'Utils/hooks/useGetTotalHours';
import Button from 'components/Button/Button';
import { useAppSelector } from 'createStore';

import '../JobDetails.scss';

interface Props {
  job: DispatchJob | Job;
  open: boolean;
  onClose: (open: boolean) => void;
}

const CancelJob = ({ job, open = false, onClose = () => {} }: Props) => {
  const permissions = useAppSelector((state) => state.app.permissions);
  const [nextJobStatus, setNextJobStatus] = useState<'cancel' | 'cancel_billable'>('cancel');
  const [openCancelationReason, setOpenCancelationReason] = useState(false);
  const { calculated, displayedTotalHours } = useGetTotalHours(JobStatus['CANCELLED_BILL'], job.id, open);

  const closeCancelationReason = () => setOpenCancelationReason(false);

  const cancelBillableJob = () => {
    setNextJobStatus('cancel_billable');
    setOpenCancelationReason(true);
    onClose(false);
  };

  const cancelJob = () => {
    setNextJobStatus('cancel');
    const jobHasClockedOutTimesheet = job.workers.some((worker) => {
      if (typeof worker.status === 'string') {
        return worker.status === ShiftStatus.review || worker.status === ShiftStatus.review_finished;
      }

      if (typeof worker.status === 'number') {
        return worker.status === WorkerStatus.Review || worker.status === WorkerStatus.Completed;
      }
      return true;
    });
    onClose(false);
    if (jobHasClockedOutTimesheet) {
      confirmAlert({
        title: 'This job has clocked out timesheets',
        message:
          'Cancelling this job now, will cancel all the associated timesheets.' +
          ' ConEd will not be billed, and the workers will not be paid.' +
          ' Are you sure you want to continue?',
        buttons: [
          {
            label: 'No',
            onClick: (hideAlert) => {
              hideAlert();
            },
            btnType: 'success',
          },
          {
            label: 'Yes',
            onClick: (hideAlert) => {
              hideAlert();
              setOpenCancelationReason(true);
              onClose(false);
            },
            btnType: 'error',
          },
        ],
      });
      return;
    }
    setOpenCancelationReason(true);
  };

  if (permissions.includes(AppPermissions.can_cancel_without_charging)) {
    return (
      <React.Fragment>
        <Dialog onClose={() => onClose(false)} open={open} maxWidth="lg">
          <DialogTitle className={'cancel-title'}>
            This job has already started, cancelling would invoke a {displayedTotalHours} Hour. Would you like to:
          </DialogTitle>
          <DialogActions className={'action-button-group d-flex space-between'}>
            <Button //cancell-billable
              disabled={job.status === JobStatus.CANCELLED_BILL || calculated}
              color={'gray'}
              width={'170px'}
              borderRadius={'20px'}
              textTransform={false}
              onClick={cancelBillableJob}
            >
              Accept Late Cancel
            </Button>
            <Button //cancell
              color={'gray'}
              //width={'170px'}
              disabled={calculated}
              borderRadius={'20px'}
              textTransform={false}
              onClick={cancelJob}
            >
              Cancel w/out Charging
            </Button>
            <Button
              color={'dark'}
              width={'170px'}
              borderRadius={'20px'}
              textTransform={false}
              onClick={() => onClose(false)}
            >
              Don’t Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <CancelJobReason
          open={openCancelationReason}
          onClose={closeCancelationReason}
          job_id={job.id}
          status={nextJobStatus}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Dialog onClose={() => onClose(false)} open={open}>
        <DialogTitle className={'cancel-title'}>
          The Job has already started, if you close it now, you will be charged for {displayedTotalHours} hours
        </DialogTitle>
        <DialogActions className={'action-button-group d-flex space-between'}>
          <Button
            color={'gray'}
            width={'158px'}
            borderRadius={'20px'}
            textTransform={false}
            onClick={() => onClose(false)}
          >
            Don't Cancel
          </Button>
          <Button
            disabled={calculated}
            color={'dark'}
            width={'158px'}
            borderRadius={'20px'}
            textTransform={false}
            onClick={cancelBillableJob}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CancelJobReason
        open={openCancelationReason}
        onClose={closeCancelationReason}
        job_id={job.id}
        status={nextJobStatus}
      />
    </React.Fragment>
  );
};

export default CancelJob;
