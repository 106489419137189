import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import Telegram from '@mui/icons-material/Telegram';
import { Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import AppPermissions from 'Constants/permissions';
import { addTestID } from 'Constants/tests';
import { TestIDs } from 'Constants/tests';
import { EROLES } from 'Constants/user';
import ExportToFileButton from 'Containers/Components/ExportToFileButton/ExportToFileButton';
import { actions } from 'Services';
import { WorkersAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { ReduxState } from 'createStore';

import { PushMessagesModal } from './PushMessagesModal';
import WorkersFilter from './WorkersFilter';
import WorkersSortTable from './WorkersSortTable';

interface Props extends RouteComponentProps<{}>, ReduxProps {}

class Workers extends React.Component<Props> {
  state = {
    loading: false,
    pushMessagesModalOpened: false,
  };
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pushMessagesModalOpened: false,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.props.getAllWorkers();
  }

  resetValue = (e) => {
    e.target.value = null;
  };

  handleChangeFileExcel = async (e: any) => {
    this.setState({ loading: true });
    const file = e.target.files[0];
    try {
      const res = await WorkersAPI.importFromExcel(file);
      showSuccessMessage(res?.message || 'Workers import successfully!');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  triggerInputFile = () => {
    this.inputRef.current.click();
  };

  openPushMessagesModal = () => {
    this.setState({ pushMessagesModalOpened: true });
  };

  closePushMessagesModal = () => {
    this.setState({ pushMessagesModalOpened: false });
  };

  render() {
    const { loading, pushMessagesModalOpened } = this.state;
    const { processing, roles } = this.props;

    const isSubcontractor = roles.every((role) => role === EROLES.subcontractor);

    return (
      <div className="workers-list-page">
        <div className="page-header sub-header">
          <div className="page-title">Workers</div>
          <WorkersFilter />
          <div className="user-role-action d-flex align-items-center">
            {this.props.permissions.includes(AppPermissions.add_workers) && (
              <Link className="goto-create-role mr-3" to={`/workers/create`}>
                <button className="btn-new btn-success btn-add" {...addTestID(TestIDs.pages.workers.buttons.addWorker)}>
                  Add New
                </button>
              </Link>
            )}
            {!isSubcontractor && (
              <>
                <input
                  className="input-excel"
                  accept=".xls,.xlsx"
                  ref={this.inputRef}
                  type="file"
                  onClick={this.resetValue}
                  onChange={this.handleChangeFileExcel}
                />
                <button className="btn-import btn-success btn-add bg-color-primary" onClick={this.triggerInputFile}>
                  Import from Excel
                </button>
              </>
            )}
            <Tooltip disableInteractive title="Export to CSV. Non-empty filters will be applied">
              <span>
                <ExportToFileButton
                  sx={{ marginLeft: 1, padding: '11.5px', lineHeight: '22px', '& .MuiButton-endIcon': { margin: 0 } }}
                  onClick={this.props.exportToCsv}
                  processing={this.props.exportToCsvLoading}
                />
              </span>
            </Tooltip>

            {!isSubcontractor && (
              <Tooltip disableInteractive title="Message Blast to Workers">
                <button
                  className="btn bg-color-primary"
                  style={{ height: 43, borderRadius: 12, marginLeft: '0.5vw' }}
                  onClick={this.openPushMessagesModal}
                >
                  <Telegram style={{ color: 'white' }} />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        {loading || processing ? <LinearProgress /> : <div style={{ height: 4 }}> </div>}
        <div className="workers-body">
          <div style={{ overflow: 'auto' }}>
            <WorkersSortTable />
          </div>
          <AppTablePagination
            page={this.props.tablePagination.page}
            total={this.props.tablePagination.total}
            perPage={this.props.tablePagination.perPage}
            onChangePage={(_, page) => this.props.updatePagination({ page })}
            onPaginationChange={(perPage) => this.props.updatePagination({ perPage, page: 1 })}
          />
        </div>
        <PushMessagesModal isOpen={pushMessagesModalOpened} closeModal={this.closePushMessagesModal} />
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    processing: state.workers.processing,
    tablePagination: state.workers.tablePagination,
    permissions: state.app.permissions,
    roles: state.app.user?.roles || [],
    exportToCsvLoading: state.workers.exportToCsvLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getAllWorkers: () => dispatch(actions.WorkersActions.retrieve()),
    updatePagination: (options: Partial<ReturnType<ReduxState['workers']['tablePagination']['asMutable']>>) =>
      dispatch(actions.WorkersActions.updatePagination(options)),
    exportToCsv: () => dispatch(actions.WorkersActions.exportToCsv()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Workers);
