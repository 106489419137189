import * as React from 'react';

import { addTestID } from 'Constants/tests';
import search from 'Images/search.png';

interface Props {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
  name?: string;
  placeholder?: string;
  testID?: string;
}

export class CETSearchRadiusInput extends React.Component<Props> {
  public render() {
    return (
      <div className="form-control-search">
        <img src={search} alt="" />
        <input
          {...addTestID(this.props.testID || '')}
          className="ce-form-control"
          name={this.props.name}
          type="search"
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default CETSearchRadiusInput;
