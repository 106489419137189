import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import clsx from 'clsx';

import { useTheme } from '@mui/material';

import s from './ModalBase.module.css';

type Props = {
  id?: string;
  open: boolean;
  overlayProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  onClose: () => void;
} & PropsWithChildren;

const ModalBase = ({ id = 'modalBase', children, overlayProps = {}, onClose = () => {}, open = false }: Props) => {
  const [root, setRoot] = useState<HTMLDivElement>(null);
  const theme = useTheme();

  useLayoutEffect(() => {
    let modalRoot = document.getElementById(id) as HTMLDivElement;
    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.id = id;
      document.body.appendChild(modalRoot);
    }
    setRoot(modalRoot);
    return () => {
      modalRoot?.remove();
      setRoot(null);
    };
  }, [id]);

  useEffect(() => {
    const escapeListener = (ev: KeyboardEvent) => {
      if (ev.code === 'Escape') {
        onClose();
        window.removeEventListener('keydown', escapeListener);
      }
    };
    if (open) {
      window.addEventListener('keydown', escapeListener);
    }
    return () => {
      window.removeEventListener('keydown', escapeListener);
    };
  }, [open, onClose]);

  if (!root || !open) return null;
  return createPortal(
    <div
      className={clsx(s.modalWrap, overlayProps?.className)}
      onClick={(event) => {
        event.stopPropagation();
        if (event.target !== event.currentTarget) return;
        onClose();
      }}
      {...overlayProps}
      style={{
        zIndex: theme?.zIndex?.modal || 999,
        ...overlayProps.style,
      }}
    >
      {children}
    </div>,
    root
  );
};

export default ModalBase;
