export const GET_TIMESHEETS_REQUEST = 'timesheets.get_timesheets_request';
export const GET_TIMESHEETS_SUCCESS = 'timesheets.get_timesheets_success';
export const GET_TIMESHEETS_ERROR = 'timesheets.get_timesheets_error';

export const GET_MASTER_TIMESHEET_REQUEST = 'timesheets.get_master_timesheet_request';
export const GET_MASTER_TIMESHEET_SUCCESS = 'timesheets.get_master_timesheet_success';
export const GET_MASTER_TIMESHEET_ERROR = 'timesheets.get_master_timesheet_error';

export const UPDATE_FILTERS = 'timesheets.update_filters';
export const UPDATE_SAVED_DEPARTMENT = 'timesheets.update_saved_department';
export const UPDATE_SAVED_SUPERVISOR = 'timesheets.update_saved_supervisor';
export const UPDATE_SAVED_REQUESTOR = 'timesheets.update_saved_requestor';
export const LOAD_EXACT_PAGE = 'timesheets.load_exact_page';

export const VIRIFY_TIMESHEET_REQUEST = 'timesheet.verify_request';
export const VIRIFY_TIMESHEET_SUCCESS = 'timesheet.verify_success';
export const VIRIFY_TIMESHEET_ERROR = 'timesheet.verify_error';

export const DELETE_TIMESHEETS_REQUEST = 'timesheets.delete_timesheet_request';
export const DELETE_TIMESHEETS_SUCCESS = 'timesheets.delete_timesheet_success';
export const DELETE_TIMESHEETS_ERROR = 'timesheets.delete_timesheet_error';

export const MARK_TIMESHEETS_AS_WORKER_PAID_REQUEST = 'timesheets.mark_tomesheets_as_worker_paid_request';
export const MARK_TIMESHEETS_AS_WORKER_PAID_SUCCESS = 'timesheets.mark_tomesheets_as_worker_paid_success';
export const MARK_TIMESHEETS_AS_WORKER_PAID_ERROR = 'timesheets.mark_tomesheets_as_worker_paid_error';

export const UPDATE_TIMESHEETS_REQUEST = 'timesheets.update_request';
export const UPDATE_TIMESHEETS_SUCCESS = 'timesheets.update_success';
export const UPDATE_TIMESHEETS_ERROR = 'timesheets.update_error';

export const CREATE_TIMESHEET_REQUEST = 'timesheets.create_request';
export const CREATE_TIMESHEET_SUCCESS = 'timesheets.create_success';
export const CREATE_TIMESHEET_ERROR = 'timesheets.create_error';

export const GET_TIMESHEET_REQUEST = 'timesheets.get_request';
export const GET_TIMESHEET_SUCCESS = 'timesheets.get_success';
export const GET_TIMESHEET_ERROR = 'timesheets.get_error';

export const UPLOAD_IMAGE_REQUEST = 'timesheets.UPLOAD_IMAGE_request';
export const UPLOAD_IMAGE_SUCCESS = 'timesheets.UPLOAD_IMAGE_success';
export const UPLOAD_IMAGE_ERROR = 'timesheets.UPLOAD_IMAGE_error';

export const UPDATE_CHECKED_TIMESHEETS_REQUEST = 'timesheets.update_checked_request';
export const UPDATE_CHECKED_TIMESHEETS_SUCCESS = 'timesheets.update_checked_success';
export const UPDATE_CHECKED_TIMESHEETS_ERROR = 'timesheets.update_checked_error';

export const DOWNLOAD_MULTIPLE_TIMESHEETS_REQUEST = 'timesheets.download_multiple_request';
export const DOWNLOAD_MULTIPLE_TIMESHEETS_SUCCESS = 'timesheets.download_multiple_success';
export const DOWNLOAD_MULTIPLE_TIMESHEETS_ERROR = 'timesheets.download_multiple_error';

export const EXPORT_CSV_REQUEST = 'timesheets.export_csv_request';
export const EXPORT_CSV_SUCCESS = 'timesheets.export_csv_success';
export const EXPORT_CSV_ERROR = 'timesheets.export_csv_error';

export const REVIVE_TIMESHEETS_REQUEST = 'timesheets.revive_timesheet_request';
export const REVIVE_TIMESHEETS_SUCCESS = 'timesheets.revive_timesheet_success';
export const REVIVE_TIMESHEETS_ERROR = 'timesheets.revive_timesheet_error';

export const SET_MOBILE_FILTERS = 'timesheets.set_mobile_filters';
