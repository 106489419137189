import React, { PureComponent } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { SelectChangeEvent, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { Department } from 'types/Common/Companies';

import { BILLING_CYCLE } from 'Constants/billing';
import { JobType } from 'Constants/job';
import { actions } from 'Services';
import { InvoicesSearchOptions } from 'Services/invoices/reducer';
import { retrieveDepartmentsFromLS, saveDepartmentsToLS } from 'Utils/departments';
import DatePicker from 'components/Picker/DatePicker';
import { ReduxState } from 'createStore';

import DepartmentMaterialAsyncSearch from '../Components/Controls/DepartmentMaterialAsyncSearch';
import './Invoices.scss';

const JOB_TYPES = [
  {
    value: JobType.Flagging,
    label: 'Flagging',
  },
  {
    value: JobType.Parking,
    label: 'Parking',
  },
  {
    value: JobType.Signage,
    label: 'Signage',
  },
];

type SavedSearchOptions = InvoicesSearchOptions;

interface State {
  departments: Department[];
  departmentGroups: Department[];
}

const STORAGE_KEY_INVOICES_DEPARTMENTS = 'invoices.filters.departments';

class Header extends PureComponent<PropsFromRedux, State> {
  state: State = retrieveDepartmentsFromLS(STORAGE_KEY_INVOICES_DEPARTMENTS);

  handleChangeBillingCycle = (event: SelectChangeEvent<SavedSearchOptions['billing_cycles']>) => {
    this.props.updateFilters({
      billing_cycles: Array.isArray(event.target.value) ? event.target.value : event.target.value.split(','),
      page: 1,
    });
  };

  handleChangeJobTypes = (event: SelectChangeEvent<number[]>) => {
    this.props.updateFilters({
      job_types: Array.isArray(event.target.value) ? event.target.value : event.target.value.split(',').map(Number),
      page: 1,
    });
  };

  dateChange = (data) => {
    this.props.updateFilters({
      startDate: data.from_datetime ? data.from_datetime : '',
      finishDate: data.to_datetime ? data.to_datetime : '',
      page: 1,
    });
  };

  onDepartmentSelect = (departments: Department[] = [], departmentGroups: Department[] = []) => {
    saveDepartmentsToLS(STORAGE_KEY_INVOICES_DEPARTMENTS, { departments, departmentGroups });
    this.setState({ departments, departmentGroups }, () =>
      this.props.updateFilters({
        departments: departments.map(({ id }) => id),
        department_groups: departmentGroups.map(({ id }) => id),
        page: 1,
      })
    );
  };

  renderInputValue =
    (selectedOptions: { value: any; label: string }[]) =>
    (selected: any[] = []) =>
      selectedOptions
        .reduce((result, status) => (selected.includes(status.value) ? [...result, status.label] : result), [])
        .join(', ');

  render() {
    const { search_options } = this.props;
    const options = search_options.asMutable({ deep: true });
    return (
      <div className="header">
        <div className="block-white">
          <div className="d-grid-4">
            <DepartmentMaterialAsyncSearch
              onSelect={this.onDepartmentSelect}
              departments={this.state.departments}
              departmentGroups={this.state.departmentGroups}
              inputSize="medium"
              width="100%"
            />
            <FormControl variant="outlined">
              <TextField
                select
                variant="outlined"
                label="Billing Cycle"
                SelectProps={{
                  id: 'billing-page-status-selector',
                  renderValue: this.renderInputValue(BILLING_CYCLE),
                  multiple: true,
                  onChange: this.handleChangeBillingCycle,
                }}
                value={options.billing_cycles}
                placeholder={'Select Billing Cycle'}
              >
                {BILLING_CYCLE.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    <Checkbox
                      color="primary"
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={options.billing_cycles?.indexOf(status.value) > -1}
                    />
                    <ListItemText primary={status.label} />
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl variant="outlined">
              <TextField
                select
                variant="outlined"
                label="Job Types"
                SelectProps={{
                  id: 'billing-page-status-selector',
                  renderValue: this.renderInputValue(JOB_TYPES),
                  multiple: true,
                  onChange: this.handleChangeJobTypes,
                }}
                value={options.job_types}
              >
                {JOB_TYPES.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    <Checkbox
                      color="primary"
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      checked={options.job_types?.indexOf(status.value) > -1}
                    />
                    <ListItemText primary={status.label} />
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <div className="d-flex justify-content-between align-items-center">
              <DatePicker
                onChange={this.dateChange}
                from_datetime={options.startDate}
                to_datetime={options.finishDate}
                showClearDates={true}
                style={{ width: 220 }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    updateFilters: (search_options) => dispatch(actions.InvoicesActions.updateFilters(search_options)),
  };
}

function mapStateToProps(state: ReduxState) {
  return {
    search_options: state.invoices.search_options,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
