import { DispatchJob, JobAlert } from 'types/Dispatch';
import { Job } from 'types/Job';

import { SendChatMessageStatus, JobStatus, ChatMessageStatus } from 'Constants/job';
import ApiService, { API } from 'Services/HttpService';
import { initialLocationsFiltersStats } from 'Services/jobs/reducer';
import { FILTERS, objectValuesFilter } from 'Utils/valueFilter';

class JobsAPI {
  getJobs = (params: API.RequestBody) => {
    return ApiService.GET(`/jobs`, params);
  };

  getShifts = (params: API.RequestBody) => {
    return ApiService.GET(`/jobs/locations`, params);
  };

  getLocationsFiltersStats = (
    params: API.RequestBody
  ): Promise<{
    status: number;
    stats: typeof initialLocationsFiltersStats;
  }> => {
    return ApiService.GET(`/jobs/locations/stats`, params);
  };

  getDispatchJobDetails = (jobId: string | number) => {
    return ApiService.GET<{ job: DispatchJob }>(`/jobs/${jobId}/details`);
  };

  getJob = ({
    jobId = 0,
    ...params
  }: {
    jobId: string | number;
    workerId?: string | number;
    show_deleted_notes?: boolean | number;
  }) => {
    return ApiService.GET<Job>(`/jobs/${jobId}`, params);
  };

  reconstructJob = (jobId: string | number, reconstructData: API.RequestBody) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/reconstruct`, reconstructData);
  };

  integrationReroute = (jobId: string | number, rerouteData: API.RequestBody) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/integration-re-route`, rerouteData);
  };

  addWorker = (jobId: string | number, workerData: API.RequestBody) => {
    return ApiService.POST(`/jobs/${jobId}/workers`, workerData);
  };

  updateJob = (jobId: string | number, jobData) => {
    return ApiService.PUT_JSON(`/jobs/${jobId}`, jobData);
  };

  updateJobImages = (jobId: string | number, jobData: API.RequestBody) => {
    return ApiService.PUT_JSON(`/jobs/${jobId}/job-images`, jobData);
  };

  updateJobDeptAndSection = (jobId: string | number, jobData: API.Body<'json'>) => {
    return ApiService.PUT_JSON(`/jobs/${jobId}/department-section`, jobData);
  };

  updateJobStatus = (
    jobId: string | number,
    jobData: { status: string; note?: string; value?: number; timesheet_pdfs?: string[] }
  ) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/status`, jobData);
  };

  updateJobShift = (jobId: string | number, shiftId: string | number, workerData: API.RequestBody) => {
    return ApiService.PUT_JSON(`/jobs/${jobId}/workers/${shiftId}`, workerData);
  };

  updateUnconfirmedCancel = (jobId: string | number, shiftId: string | number) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/workers/${shiftId}/confirmed-cancel`);
  };

  addShift = (jobId: string | number, location_id: string | number) => {
    return ApiService.POST(`/jobs/${jobId}/locations/${location_id}/add-shift`);
  };

  removeShift = (jobId: string | number, shiftId: string | number) => {
    return ApiService.PUT(`/jobs/${jobId}/workers/${shiftId}`, { status: 'cancel' });
  };

  revertShiftStatus = (jobId: string | number, shiftId: string | number) => {
    return ApiService.PUT(`/jobs/${jobId}/workers/${shiftId}`, { status: 'revert' });
  };

  updateShift = (
    jobId: string | number,
    shiftData: { workerId: string | number; status: string; shiftId: string | number }
  ) => {
    return ApiService.PUT(`/jobs/${jobId}/worker-admin`, shiftData);
  };

  addLocation = (jobId: string | number, locationData: API.RequestBody) => {
    return ApiService.POST(`/jobs/${jobId}/locations`, locationData);
  };

  updateLocation = (jobId: string | number, locationId: string | number, locationData: API.RequestBody) => {
    return ApiService.PUT(`/jobs/${jobId}/locations/${locationId}`, locationData);
  };

  deleteLocation = (jobId: string | number, locationId: string | number) => {
    return ApiService.DELETE(`/jobs/${jobId}/locations/${locationId}`);
  };

  confirmJobs = (jobIds: string[] | number[]) => {
    return ApiService.POST(`/jobs/confirm`, { ids: jobIds, confirmed: 1 });
  };

  releaseWorkers = ({ job_id, ...restRequestBody }: ReleaseWorkersRequestData) => {
    return ApiService.POST_JSON(`/jobs/${job_id}/status`, { status: 'complete', ...restRequestBody });
  };

  releaseSelectedWorkers = ({ job_id, ...restRequestBody }: ReleaseSelectedWorkersRequestData) => {
    return ApiService.POST_JSON(`/jobs/${job_id}/workers/release`, { ...restRequestBody });
  };

  updatePOS = (posData: UpdatePOSData) => {
    return ApiService.POST(`/jobs/pos`, posData);
  };

  uploadImages = (images: Record<string, string | Blob> | FormData) => {
    return ApiService.POST_FDATA<string[]>(`/jobs/upload/job-image`, images);
  };

  downloadAllImages = (jobId: string | number) => {
    return ApiService.DOWNLOAD_FILE(`/jobs/${jobId}/images/download`);
  };

  createJob = (jobData: API.Body<'json'>) => {
    return ApiService.POST_JSON(`/jobs`, jobData);
  };

  replaceWorker = (jobId: string | number, workerData: API.Body<'json'>) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/replace-worker`, workerData);
  };

  exportJobsToCSV = (params: API.UriData) => {
    return ApiService.GET<Blob>(
      `/jobs/export-grid`,
      objectValuesFilter(params, [null, undefined, '', FILTERS.empty_array]),
      { responseType: 'blob' }
    );
  };

  extendWMSJobEndTime = (jobId: string | number, extendData: { end_time: string; reason: string }) => {
    return ApiService.POST(`/jobs/${jobId}/extend-end-time`, extendData);
  };

  rerouteWorkers = (workerData: RerouteWorkerRequest) => {
    return ApiService.POST_JSON(`/jobs/${workerData?.job_id}/re-route`, workerData);
  };

  rerouteWorkersFromStandBy = (workerData: RerouteWorkerRequest) => {
    return ApiService.POST_JSON(`/jobs/${workerData?.job_id}/standby-reroute`, workerData, {
      headers: { Accept: 'application/json' },
    });
  };

  downloadPdf = (jobId: string | number) => {
    return ApiService.POST<Blob>(`/jobs/${jobId}/pdf`, {}, { responseType: 'blob' });
  };

  downloadHistoryPdf = (jobId: string | number) => {
    return ApiService.POST<Blob>(`/jobs/${jobId}/history-pdf`, {}, { responseType: 'blob' });
  };

  loadConfNumbers = (params: LoadConfNumbersParams): Promise<{ jobs: ConfirmationNumber[] }> => {
    return ApiService.GET(`/jobs/conf-numbers`, params);
  };

  checkDuplicate = (params: API.RequestBody) => {
    return ApiService.GET(`/jobs/check-duplicate`, params);
  };

  getPaymentHours = (
    jobId: string | number,
    { status = 'completed' }: { status: (typeof JobStatus)['CANCELLED_BILL'] | (typeof JobStatus)['COMPLETED'] }
  ) => {
    return ApiService.GET<{ total_hours: string }>(`/jobs/${jobId}/waiting-payment-hours`, { status });
  };

  getWorkerGPSHistory = (jobId: string | number, shiftId: string | number) => {
    return ApiService.GET<{ gps_history: GPSHistoryItemType[] }>(`/jobs/${jobId}/workers/${shiftId}/gps-history`);
  };

  getScheduleGPSHistory = (jobId: string | number) => {
    return ApiService.GET<{ gps_history: GPSHistoryItemType[] }>(`/supervisor-schedules/${jobId}/gps-history`);
  };

  assignCompanies = (jobIds: number[], companyIds: number[]) => {
    return ApiService.POST_JSON('/jobs/companies', { job_ids: jobIds, company_ids: companyIds });
  };

  getJobAlerts = (jobId: string | number) => {
    return ApiService.GET<{ job_alerts: JobAlert[] }>(`/jobs/${jobId}/alerts`);
  };

  addJobAlert = (jobId: string | number, alertData: JobAlertData) => {
    return ApiService.POST(`/jobs/${jobId}/alerts`, alertData);
  };

  updateJobAlert = (jobId: string | number, alertId: string | number, alertData: JobAlertData) => {
    return ApiService.PUT(`/jobs/${jobId}/alerts/${alertId}`, alertData);
  };

  deleteJobAlert = (jobId: string | number, alertId: string | number) => {
    return ApiService.DELETE(`/jobs/${jobId}/alerts/${alertId}`);
  };

  splitJob = (jobId: string | number, splitData: SplitJobData) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/split`, splitData);
  };

  undoSplitJob = (jobId: string | number, split_job_id: string | number) => {
    return ApiService.POST_JSON(`/jobs/${jobId}/undo-split`, { split_job_id });
  };

  sendNoCrewEmail = (jobId: string | number, body?: string) => {
    return ApiService.POST(`/jobs/${jobId}/no-crew-email`, { body });
  };

  getDefaultNoCrewEmailBody = (jobId: string | number) => {
    return ApiService.GET(
      `/jobs/${jobId}/no-crew-email/body`,
      {},
      {
        responseType: 'text',
      }
    );
  };

  getJobChatsHistory = (jobId: string | number) => {
    return ApiService.GET<GetChatsHistoryResponse>(`/jobs/${jobId}/chats/history`);
  };

  updateJobEmergencyType = (jobId: string | number, emergency: boolean) => {
    return ApiService.POST(`/jobs/${jobId}/emergency-type`, { emergency });
  };

  getJobShiftChats = ({ jobID, shiftID }: { jobID: string | number; shiftID: string | number }) => {
    return ApiService.GET<{ messages: ChatMessage[] }>(`/jobs/${jobID}/workers/${shiftID}/chats`);
  };

  updateJobShiftChatMessageStatus = ({
    jobID,
    shiftID,
    messageID,
    status,
  }: {
    jobID: string | number;
    shiftID: string | number;
    messageID: string | number;
    status: SendChatMessageStatus;
  }) => {
    return ApiService.POST(`/jobs/${jobID}/workers/${shiftID}/chats/messages/${messageID}/dispatcher-check`, {
      status,
    });
  };
}

export default new JobsAPI();

export type ConfirmationNumber = {
  id: string;
  confirmNumber: string;
  reference_id: string;
  is_asap: string;
};

export type LoadConfNumbersParams = {
  jobId?: string | number;
  workerId?: string | number;
};

type RerouteWorkerRequest = {
  job_id: string | number;
  workers: {
    id: number;
    shift_id: number;
    structure?: string;
    note?: string;
  }[];
  location: any;
  files: string[];
  structure?: string;
};

export type UpdatePOSData = {
  ids: string[] | number[];
  newPo?: string;
  requisition?: string;
  receipt?: string;
  wr?: string;
  account_no?: string;
  poet?: string;
  update_po?: boolean;
  update_requisition?: boolean;
  update_receipt?: boolean;
  update_wr?: boolean;
  update_account_no?: boolean;
  update_poet?: boolean;
};

export type JobAlertData = {
  alert_message: string;
  alert_after_minutes: number;
};

export type SplitJobData = {
  po: string;
  split_by_datetime: string;
};

export type ReleaseWorkersRequestData = {
  job_id: string | number;
  timesheet_pdfs?: string[];
};

export type ReleaseSelectedWorkersRequestData = ReleaseWorkersRequestData & {
  job_worker_ids: string[] | number[];
};

export type GPSHistoryItemType = {
  lat: number;
  lon: number;
  datetime: string;
};

export type ChatMessage = {
  job_worker_id: number;
  id: number;
  chat_id: number;
  user_id: number;
  user_name: string;
  message: string;
  updated_at: string;
  created_at: string;
  deleted_at: string | null;
  is_coned_supervisor: 0 | 1;
  rating: number;
  status: ChatMessageStatus;
  explanation: string;
};

export type GetChatsHistoryResponse = {
  supervisor_worker: Record<string, ChatMessage[]> | [];
  supervisor_inspector: Record<string, ChatMessage[]> | [];
};
