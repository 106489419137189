export class IfVisibleListeners {
  static #listeners: (() => void)[] = [];

  static addListener(listener: () => void) {
    this.#listeners.push(listener);
  }

  static addMultipleListeners(newListeners: (() => void)[]) {
    for (const listener of newListeners) {
      this.addListener(listener);
    }
  }

  static clearListeners() {
    this.#listeners.forEach((unsubscribe) => {
      unsubscribe?.();
    });
    this.#listeners = [];
  }
}
