import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { ResponseSubcontractorItem } from 'types/Subcontractors';

import { TestIDs } from 'Constants/tests';
import { WORKER_STATUS_SELECT } from 'Constants/worker';
import { actions } from 'Services';
import { ReduxState, useAppSelector } from 'createStore';

import CETSearchRadiusInput from '../Components/Controls/SearchRadiusInput.Component';
import SubcontractorMaterialAsyncSearch from '../Components/Controls/SubcontractorMaterialAsyncSearch';
import { WORKER_TYPE } from './Constants';

const WorkersFilter = () => {
  const dispatch = useDispatch();
  const searchParams = useSelector((state: ReduxState) => state.workers.search_options);
  const canSeeSubcontractors = useAppSelector((state) => state.userPermissions.canSeePage.subcontractors);

  const updateFilters = (filters: Partial<ReturnType<(typeof searchParams)['asMutable']>>) =>
    dispatch(actions.WorkersActions.updateFilters(filters));

  const handleChangeStatus = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    updateFilters({ status: event.target.value });
  };

  const handleChangeType = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    updateFilters({ workerType: Number(event.target.value) });
  };

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFilters({ query: event.target.value });
  };

  const selectSubcontractor = (subcontractor: ResponseSubcontractorItem) => {
    updateFilters({ subcontractorId: subcontractor?.id || 0 });
  };

  return (
    <div style={{ ...styles.filtersStyles, ...(canSeeSubcontractors ? {} : { gridTemplateColumns: '1fr 1fr 1fr' }) }}>
      <div>
        <CETSearchRadiusInput
          name="searchWorkers"
          placeholder="Search by name, phone, email or number"
          onChange={handleChangeQuery}
          value={searchParams.query}
          testID={TestIDs.pages.workers.fields.workerSearchInput}
        />
      </div>
      {canSeeSubcontractors && (
        <div>
          <SubcontractorMaterialAsyncSearch
            selectedId={searchParams.subcontractorId}
            onSelectSubcontractor={selectSubcontractor}
            inputSize="small"
            width="auto"
          />
        </div>
      )}
      <div>
        <TextField
          label="Status"
          name="status"
          onChange={handleChangeStatus}
          value={searchParams.status}
          variant="outlined"
          fullWidth
          size="small"
          select
        >
          <MenuItem value="">All</MenuItem>
          {WORKER_STATUS_SELECT.map((status, index) => (
            <MenuItem key={index} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div>
        <TextField
          label="Worker Types"
          name="workerTypes"
          onChange={handleChangeType}
          value={searchParams.workerType || ''}
          variant="outlined"
          fullWidth
          size="small"
          select
        >
          <MenuItem value="">All</MenuItem>
          {WORKER_TYPE.map((type, index) => (
            <MenuItem key={index} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
};

const styles = {
  filtersStyles: {
    backgroundColor: '#fff',
    borderRadius: '1rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    alignItems: 'end',
    gridGap: 20,
  },
  disabledFilterBtn: {
    background: '#BDBDBD',
    color: '#fff',
  },
};

export default WorkersFilter;
