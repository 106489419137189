import { ReactNode, memo, useMemo } from 'react';

import { Box, Chip, LinearProgress, colors } from '@mui/material';

type Props = {
  isVerified: boolean | number | string;
  title?: string;
  verifiedText?: string | ReactNode;
  unVerifiedText?: string | ReactNode;
  signaturesCount?: number;
  neededSignaturesCount?: number;
};

const VerifiedChip = ({
  isVerified = false,
  title = '',
  verifiedText = 'Verified',
  unVerifiedText = 'Unverified',
  signaturesCount = 0,
  neededSignaturesCount = 0,
}: Props) => {
  const label = useMemo(() => {
    if (isVerified) return `${title} ${verifiedText}`;
    if (neededSignaturesCount > 0 && signaturesCount > 0)
      return `${signaturesCount}/${neededSignaturesCount} ${verifiedText}`;
    return `${title} ${isVerified ? verifiedText : unVerifiedText}`;
  }, [isVerified, title, verifiedText, unVerifiedText, signaturesCount, neededSignaturesCount]);

  return (
    <Box display="flex" flexDirection="column" gap="5px">
      <Chip
        label={label}
        sx={(theme) => ({
          fontSize: 13,
          bgcolor: isVerified
            ? theme.palette.success.dark
            : signaturesCount
            ? theme.palette.warning.dark
            : theme.palette.error.dark,
          color: colors.common.white,
        })}
      />
      {neededSignaturesCount > 1 && (
        <Box display="flex" gap="2px">
          {Array.from({ length: neededSignaturesCount }, (_, index) => (
            <LinearProgress
              key={'verified-progress' + index}
              sx={(theme) => ({
                flex: 1,
                bgcolor: index < signaturesCount ? theme.palette.success.dark : colors.grey[300],
                borderRadius: 2,
                '& .MuiLinearProgress-bar': { bgcolor: 'inherit' },
              })}
              variant="determinate"
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default memo(VerifiedChip);
