import { AppThunkAction } from 'types';
import { OrderOtpions } from 'types/Common/App';
import { GrouppedTimesheetOptions, GrouppedTimesheetsResponse, TimesheetsResponse } from 'types/Timesheet';

import { TimesheetStatus } from 'Constants/timesheet';
import { TimesheetsAPI } from 'Services/API';
import { objectValuesFilter } from 'Utils/valueFilter';

import * as actionTypes from './actionTypes';

export const retrieveGroupped =
  (options: Partial<GrouppedTimesheetOptions>): AppThunkAction<Promise<GrouppedTimesheetsResponse>> =>
  async (dispatch, getState) => {
    try {
      const request_key = Math.random();
      dispatch({ type: actionTypes.GET_GROUPPED_TIMESHEETS_REQUEST, payload: request_key });
      dispatch({ type: actionTypes.UPDATE_GROUPPED_FILTERS, payload: options });
      const current_filters = getState().grouppedTimesheets.grouppedTimesheetsOptions.asMutable({ deep: true });
      const filters = objectValuesFilter({ ...current_filters, ...options }, ['', null, undefined]);
      const response = await TimesheetsAPI.getGrouppedTimesheets(filters);
      dispatch({ type: actionTypes.GET_GROUPPED_TIMESHEETS_SUCCESS, payload: { ...response, request_key } });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.GET_GROUPPED_TIMESHEETS_ERROR });
      throw error;
    }
  };

export const retrieveTimesheets =
  (options: Partial<OrderOtpions> & { confirmation?: number } = {}): AppThunkAction<Promise<TimesheetsResponse>> =>
  async (dispatch, getState) => {
    try {
      const currentConf = getState().grouppedTimesheets.conf_number;
      const confirmation = options.confirmation || currentConf;
      dispatch({ type: actionTypes.GET_CONF_TIMESHEETS_REQUEST, payload: confirmation });
      const current_filters = getState().grouppedTimesheets.timesheetsOptions.asMutable();
      const { show_cancelled_timesheets } = getState().grouppedTimesheets.grouppedTimesheetsOptions;
      const filters = {
        ...current_filters,
        ...options,
        confirmation,
        show_cancelled_timesheets,
        status: show_cancelled_timesheets ? TimesheetStatus.cancelled : null,
      };
      dispatch({ type: actionTypes.UPDATE_CONF_TIMESHEETS_FILTERS, payload: { confirmation, options } });
      const response = await TimesheetsAPI.getAll(filters);
      dispatch({ type: actionTypes.GET_CONF_TIMESHEETS_SUCCESS, payload: { ...response, confirmation } });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.GET_CONF_TIMESHEETS_ERROR });
      throw error;
    }
  };

export const updateTimesheetsConfNum =
  (confirmation: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const currentConf = getState().grouppedTimesheets.conf_number;
    if (currentConf && !confirmation) {
      dispatch({ type: actionTypes.CHANGE_CONF_NUMBER, payload: null });
      return;
    }
    if (confirmation && currentConf !== confirmation) {
      dispatch({ type: actionTypes.CHANGE_CONF_NUMBER, payload: confirmation });
    }
    // if conf number is same - refetching timesheets
    dispatch(retrieveTimesheets({ confirmation }));
  };

export const refetchTimesheetsWithConf =
  (confirmation: number): AppThunkAction<void> =>
  (dispatch, getState) => {
    const confNum = getState().grouppedTimesheets.conf_number;
    if (confirmation === confNum) {
      dispatch(retrieveTimesheets());
    }
    dispatch(retrieveGroupped({}));
  };
