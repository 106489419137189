import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkDispatch } from 'types';

import { ReduxState } from 'createStore';

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: ReduxState;
  dispatch: AppThunkDispatch;
  rejectValue: string;
  extra: { s: string; n: number };
}>();
