import moment from 'moment';
import * as Yup from 'yup';

import { Department } from 'types/Common/Companies';

import { applyDepartmentLitimations } from 'Utils/validation';

import { JobType, JobTypeHelper } from '../../Constants/job';
import {
  Account_Number_Validation,
  POET_Validation,
  PO_Double_Validation,
  PO_Validation,
  Receipt_Validation,
  Requisition_Validation,
  SectionValidation,
  maximumYearValue,
} from '../../Constants/validation';
import { getCanEditEndDate } from './utils';

const transformDate = (value: string | Date) => {
  const date = moment(value);
  if (date.isValid()) {
    return date.toDate();
  }
  return null;
};

const location = Yup.object().shape({
  address: Yup.string().required().label('Locations'),
  structure: Yup.string()
    .label('Structure')
    .max(64, 'Maximum Structure name length = 64 characters')
    .matches(/^[\s]*[a-zA-Z0-9-/]+[a-zA-Z0-9-\s/]*$/, {
      message: 'Structure allowed symbols: /, space, dash, A-z, 0-9',
      excludeEmptyString: true,
    }),
});

const locationStructure = Yup.object().shape({
  address: Yup.string().required().label('Locations'),
  structure: Yup.string()
    .required()
    .label('Structure')
    .max(64, 'Maximum Structure name length = 64 characters')
    .matches(/^[\s]*[a-zA-Z0-9-/]+[a-zA-Z0-9-\s/]*$/, {
      message: 'Structure allowed symbols: /, space, dash, A-z, 0-9',
      excludeEmptyString: true,
    }),
});

const locationNotReqStructure = Yup.object().shape({
  address: Yup.string().required().label('Locations'),
  structure: Yup.string()
    .label('Structures')
    .notRequired()
    .max(64, 'Maximum Structure name length = 64 characters')
    .matches(/^[\s]*[a-zA-Z0-9-\s/]*$/, 'Structure allowed symbols: /, space, dash, A-z, 0-9'),
});

export const requestTimeValidation = Yup.date()
  .required('Start Date is required field')
  .label('Start Date')
  .transform(transformDate)
  .min(new Date(2020, 0), 'Start Date is too early')
  .max(maximumYearValue, 'Start Date is too late')
  .when(['department'], ([department]: [Department], schema: Yup.DateSchema<Date>) => {
    return applyDepartmentLitimations('requestTime', department?.job_time_limitation, schema);
  })
  .typeError('Start Date is required field');

export const job = Yup.object().shape({
  jobType: Yup.number()
    .test('valid job type', 'Job Type is required option', (value) => Object.values(JobType).includes(value))
    .required()
    .label('Job type'),
  requestTime: requestTimeValidation,
  endTime: Yup.date()
    .max(maximumYearValue, 'Finish Date is too late')
    .nullable()
    .transform(transformDate)
    .when(
      ['jobType', 'department'],
      ([jobType, department]: [JobType, Department], sourceSchema: Yup.DateSchema<Date>) => {
        const canEditEndDate = getCanEditEndDate({ jobType, department });

        if (!canEditEndDate) {
          return sourceSchema.notRequired().typeError('');
        }

        const schema = applyDepartmentLitimations('endTime', department?.job_time_limitation, sourceSchema);

        const validationSchema = schema
          .label('Finish Date')
          .typeError('Invalid date')
          .when('requestTime', ([requestTime]: [string | Date], schema) =>
            requestTime instanceof Date || requestTime?.length > 10
              ? schema.min(moment(requestTime).add(1, 'minute').toDate(), 'Finish Date must be later than Start Date')
              : schema
          );
        if (jobType === JobType.Signage || department.create_integration_jobs) {
          return validationSchema.required('Finish date is required field');
        }

        return validationSchema;
      }
    ),
  requestor: Yup.object().required().label('Requestor'),
  supervisor: Yup.object().required().label('Supervisor'),
  department: Yup.object()
    .test('department', 'Department is required field', async (value: Department) => !!value?.id)
    .required()
    .label('Department'),
  section: SectionValidation.required(),
  municipality: Yup.object().required().label('Municipality'),
  account_no: Account_Number_Validation.notRequired(),
  maxWorkers: Yup.number()
    .positive()
    .when('jobType', ([jobType]: [JobType], schema) =>
      jobType === JobType.Signage
        ? schema.max(1, 'This job can has only one worker')
        : schema.max(50, 'Max Workers must be less than or equal to 50')
    )
    .required('Max Workers is a required field')
    .label('Max Workers'),
  po: PO_Validation.notRequired().nullable(),
  feeder: Yup.string()
    .notRequired()
    .matches(/^[a-zA-Z0-9]*$/, 'Must be only Latin letters or numbers')
    .max(30, 'Feeder # is too long')
    .nullable(),
  poet: Yup.string()
    .notRequired()
    .matches(/^[0-9]*\/?[0-9]*$/, 'Must be only digits or digits/digits')
    .max(30, 'Poet # is too long')
    .nullable(),
  wr: Yup.string()
    .notRequired()
    .matches(/^[a-zA-Z0-9]*$/, 'Must be only Latin letters or numbers')
    .max(30, 'Work Request # is too long')
    .nullable(),
  requisition: Requisition_Validation.notRequired().nullable(),
  receipt: Receipt_Validation.notRequired().nullable(),
  locations: Yup.array()
    .min(1, 'Location is required')
    .when('jobType', ([jobType]: [JobType]) => {
      if (jobType === JobType.Parking || jobType === JobType.Signage) {
        return Yup.array().of(locationStructure);
      }
      if (jobType === JobType.Flagging) {
        return Yup.array().of(locationNotReqStructure);
      } else {
        return Yup.array().of(location);
      }
    }),
});

export const JobCreateValidation = Yup.object().shape({
  jobs: Yup.array().of(job),
  is_standby: Yup.number().notRequired(),
  account_no: Account_Number_Validation.notRequired(),
});

export const JobEditValidation = Yup.object().shape({
  jobType: Yup.number().required().label('Job type'),
  requestTime: Yup.date()
    .required()
    .label('Request Date')
    .nullable()
    .min(new Date(2022, 0), 'Request Date is too early')
    .max(maximumYearValue, 'Request Date is too late')
    .when(['department'], ([department]: [Department], schema: Yup.DateSchema<Date>) => {
      return applyDepartmentLitimations('requestTime', department?.job_time_limitation, schema);
    })
    .typeError('Request Date is required field'),
  endTime: Yup.date()
    .max(maximumYearValue, 'Finish Date is too late')
    .when(['jobType', 'department'], ([jobType, department]: [JobType, Department], schema) =>
      getCanEditEndDate({ jobType, department, isEdit: true })
        ? schema
            .label('Finish Date')
            .when('requestTime', ([requestTime]: [string], schema) =>
              moment(requestTime).isValid()
                ? applyDepartmentLitimations('endTime', department?.job_time_limitation, schema).min(
                    moment(moment(requestTime).add(1, 'minute').toDate()).toDate(),
                    'Finish Date must be later than Request Date'
                  )
                : schema
            )
            .nullable()
            .typeError('Finish Date is required field')
        : schema.nullable().notRequired().typeError('')
    ),
  requestor: Yup.object().required().label('Requester'),
  supervisor: Yup.object().required().label('Supervisor'),
  department: Yup.object().required().label('Department'),
  section: SectionValidation.required(),
  municipality: Yup.object().required().label('Municipality'),
  account_no: Account_Number_Validation.notRequired(),
  maxWorkers: Yup.number()
    .positive()
    .max(50, 'Max Workers must be less than or equal to 50')
    .required('Max Workers is a required field')
    .label('Max Workers'),
  po: PO_Double_Validation.notRequired().nullable(),
  feeder: Yup.string()
    .notRequired()
    .matches(/^[a-zA-Z0-9]*$/, 'Must be only Latin letters or numbers')
    .max(30, 'Feeder # is too long')
    .nullable(),
  poet: POET_Validation.notRequired().nullable(),
  wr: Yup.string()
    .notRequired()
    .matches(/^[a-zA-Z0-9]*$/, 'Must be only Latin letters or numbers')
    .max(30, 'Work Request # is too long')
    .nullable(),
  requisition: Requisition_Validation.notRequired().nullable(),
  receipt: Receipt_Validation.notRequired().nullable(),
  locations: Yup.array().min(1, 'Location is required').of(locationNotReqStructure),
});

export const CreateApointerJobValudation = Yup.object().shape({
  job: Yup.object().required().label('Job'),
  startDate: Yup.date().required().label('Start Date'),
  location: Yup.object()
    .shape({
      address: Yup.string().notRequired(),
      lat: Yup.number().notRequired(),
      lng: Yup.number().notRequired(),
    })
    .label('Locations')
    .required(),
});

export const CheckEndTimeValidation = Yup.object().shape({
  jobType: job.fields.jobType,
  requestTime: job.fields.requestTime,
  endTime: job.fields.endTime,
});
