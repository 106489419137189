import { ImmutableArray, ImmutableObject } from 'seamless-immutable';

import { ConedUser } from 'types/Common/User';

export enum EROLES {
  requestor = 1,
  department_supervisor,
  coned_field_supervisor,
  coned_billing_admin,
  dispatcher,
  dispatcher_supervisor,
  billing,
  superadmin,
  worker,
  subcontractor = 11,
  project_owner,
  crew_member,
  jobs_verifier,
}

export const ConEdRoles = [
  EROLES.requestor,
  EROLES.department_supervisor,
  EROLES.coned_field_supervisor,
  EROLES.coned_billing_admin,
  EROLES.crew_member,
];

export const CESRoles = [
  EROLES.dispatcher,
  EROLES.dispatcher_supervisor,
  EROLES.billing,
  EROLES.superadmin,
  EROLES.worker,
  EROLES.jobs_verifier,
  EROLES.project_owner,
];

export enum USER_STATUSES {
  waiting_for_approval,
  active,
  rejected,
}

export const enum USER_STATUSES_STRING {
  waiting_for_approval = 'waiting_for_approval',
  active = 'active',
  rejected = 'rejected',
}

export const USER_STATUSES_LABELS = {
  [USER_STATUSES_STRING.waiting_for_approval]: 'Waiting for approval',
  [USER_STATUSES_STRING.active]: 'Approved',
  [USER_STATUSES_STRING.rejected]: 'Rejected',
};

export const USER_STATUS_COLORS = {
  [USER_STATUSES_STRING.waiting_for_approval]: 'info',
  [USER_STATUSES_STRING.active]: 'success',
  [USER_STATUSES_STRING.rejected]: 'error',
};

export enum APPROVE {
  waiting = 0,
  approved = 1,
  rejected = 2,
}

export enum DEPARTMENT_GROUPS {
  CONSTRUCTION_SERVICE_GROUP,
  ELECTRIC_GROUP,
  GAS_PRESSURE_CONTROL_GROUP,
  TRANSMISSION_SERVICE,
}

export const TABLE_HEADER = [
  { label: 'Name', value: 'name', sortable: true },
  { label: 'Email', value: 'email', sortable: true },
  { label: 'IP Address', value: 'ip_address', sortable: true },
  { label: 'Last login', value: 'last_login', sortable: true },
  { label: 'Roles', value: 'roles', sortable: false },
  { label: 'Status', value: 'status', sortable: true },
  { label: 'Action', value: 'action', sortable: false },
];

export const PER_PAGES = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

export const RegExpEmployeeNumber = /^[0-9]{1,50}$/;
export const RegExpConEdisonTruck = /^[a-zA-Z0-9 ]{0,12}$/;
export const RegExpSignatureName = /[a-zA-Z0-9., -]{1,50}/;

export const hasEmployeeNumber = (roles: EROLES[] | ImmutableArray<EROLES>) =>
  roles.includes(EROLES.coned_field_supervisor);

class UserRolesHelper {
  #userRoles: ConedUser['roles'] | ImmutableObject<ConedUser>['roles'] = [];

  set(roles: ConedUser['roles'] | ImmutableObject<ConedUser>['roles']) {
    this.#userRoles = roles;
  }

  get has() {
    return {
      ConEdRole: this.#userRoles.some((role) => ConEdRoles.includes(role)),
      CESRole: this.#userRoles.some((role) => CESRoles.includes(role)),
    };
  }
}

export const UserRoles = new UserRolesHelper();
