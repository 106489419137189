import clsx from 'clsx';

import { PhotoRounded } from '@mui/icons-material';
import { Icon } from '@mui/material';

import { ImageWithAuthor } from 'types/Location';

import { ImageIcon } from 'Utils/Icon';
import ImageButton from 'components/ImageButton';

import classes from './ImagePreviewRow.module.scss';
import JobImageButton from './JobImageButton';

export type ShowRemoveButton<ImageType extends ImageWithAuthor = ImageWithAuthor> =
  | boolean
  | ((image: ImageType, index: number) => boolean);

type Props = {
  images: ImageWithAuthor[];
  showRemoveButton?: ShowRemoveButton;
  previewImagesCount?: number;
  onRemoveImage?: (image: ImageWithAuthor, index: number) => void;
  onOpenImage?: (url: string) => void;
  size?: 'small' | 'medium' | 'large';
  countButtonStyles?: {
    container?: React.CSSProperties;
    content?: React.CSSProperties;
  };
};

const ImagePreviewRow = ({
  images = [],
  showRemoveButton = false,
  previewImagesCount = -1,
  onRemoveImage = () => {},
  onOpenImage = () => {},
  size = 'large',
  countButtonStyles = {
    container: {},
    content: {},
  },
}: Props) => {
  const CountButton = (
    <ImageButton
      url=""
      className={classes[size]}
      withoutImage
      onClick={() => onOpenImage(images[previewImagesCount].url)}
      noImagesStyle={countButtonStyles}
    >
      <Icon component={ImageIcon} fontSize={size} />
      <span>
        +{images.length - previewImagesCount} {size === 'large' && 'pictures'}
      </span>
    </ImageButton>
  );

  const slicedImages = images.slice(0, previewImagesCount >= 0 ? previewImagesCount : images.length);

  return (
    <>
      {slicedImages.map((image, index) => (
        <JobImageButton
          key={image.url + '&i=' + index}
          {...image}
          onClick={onOpenImage}
          className={clsx(classes.thumbWrapper, classes[size])}
          haveCloseIcon={typeof showRemoveButton === 'function' ? showRemoveButton(image, index) : showRemoveButton}
          onClosePress={() => onRemoveImage(image, index)}
        />
      ))}
      {previewImagesCount >= 0 && slicedImages.length < images.length && CountButton}
    </>
  );
};

export default ImagePreviewRow;
