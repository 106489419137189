import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import { TbZoomReset } from 'react-icons/tb';

import { GetApp } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, Tooltip, createSvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import WarningDialog from 'Containers/Components/WarnindDialog/WarningDialog';
import FileWorker from 'Utils/FileWorker';
import { downloadFileToPC } from 'Utils/downloadFile';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import picture from 'Utils/picture';
import ModalBase from 'components/ModalBase/ModalBase';
import RotatableImage from 'components/RotatableImage';
import ZoomableImage, { ZoomableImageRef } from 'components/ZoomableImage';

import classes from './ImagePreview.module.scss';
import { AppImage, BoolOrCallbackImage, ImagePreviewProps } from './types';

const ResetZoomIcon = createSvgIcon(<TbZoomReset size={24} />, 'ResetZoomIcon');

function getBoolean<ImageType>(value: BoolOrCallbackImage<ImageType>, image: ImageType, index: number) {
  if (typeof value === 'function') {
    return value(image, index);
  }
  return value;
}

const ImagePreview = <ImageType extends AppImage>({
  images = [],
  open = false,
  imageIndex = 0,
  onChangeImage = () => {},
  onClose = () => {},
  ImageTitleHeader = () => null,
  ThumbnailImages = () => null,
  HeaderButtons = () => null,
  shouldSaveRotate = false,
  onSaveRotated = () => Promise.resolve(),
  getDownloadedImageName = () => '',
}: ImagePreviewProps<ImageType>) => {
  const [rotation, setRotation] = useState(0);
  const [processingURL, setProcessingURL] = useState('');
  const imageTooltip = useModal();
  const saveRotatedImageWarning = useModal();
  const cancelModalAction = useRef(onClose);
  const zoomRef = useRef<ZoomableImageRef>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const currentImage = images[imageIndex];

  const { isFile, isFileLink, url } = new FileWorker(currentImage);

  const resetZoom = useCallback(() => {
    zoomRef.current?.centerView(1);
  }, []);

  const centerImage = useCallback(() => {
    zoomRef.current?.centerView(1, 0, 'linear');
  }, []);

  const handleSaveRotated = async () => {
    const currentUrl = new FileWorker(currentImage).url;
    const data = {
      url: currentUrl,
      degree: -rotation,
    };
    centerImage();
    saveRotatedImageWarning.close();
    setProcessingURL(currentUrl);
    try {
      await onSaveRotated({ image: currentImage, index: imageIndex, update: data });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setProcessingURL('');
      setRotation(0);
    }
  };

  const _shouldSaveRotate = getBoolean(shouldSaveRotate, currentImage, imageIndex);

  const next = useCallback(
    (event: MouseEvent<HTMLButtonElement> | true = null) => {
      if (rotation !== 0 && event) {
        if (_shouldSaveRotate) {
          saveRotatedImageWarning.open();
          cancelModalAction.current = next;
          return;
        }
        setRotation(0);
      }
      if (imageIndex < images.length - 1) {
        onChangeImage(imageIndex + 1);
      } else {
        onChangeImage(0);
      }
      centerImage();
      setRotation(0);
    },
    [centerImage, imageIndex, images.length, onChangeImage, rotation, _shouldSaveRotate]
  );

  const back = useCallback(
    (event: MouseEvent<HTMLButtonElement> | true = null) => {
      if (rotation !== 0 && event) {
        if (_shouldSaveRotate) {
          saveRotatedImageWarning.open();
          cancelModalAction.current = back;
          return;
        }
        setRotation(0);
      }
      if (imageIndex > 0) {
        onChangeImage(imageIndex - 1);
      } else {
        onChangeImage(images.length - 1);
      }
      centerImage();
      setRotation(0);
    },
    [centerImage, imageIndex, images.length, onChangeImage, rotation, _shouldSaveRotate]
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        event.stopPropagation();
        next(true);
      } else if (event.key === 'ArrowLeft') {
        event.stopPropagation();
        back(true);
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [back, next]);

  const changeImage = (index: number) => {
    centerImage();
    if (rotation !== 0) {
      if (_shouldSaveRotate) {
        saveRotatedImageWarning.open();
        cancelModalAction.current = () => {
          onChangeImage(index);
          setRotation(0);
        };
        return;
      }
      setRotation(0);
    }
    onChangeImage(index);
  };

  const handleRotate = () => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const closeSaveRotatedImageWarning = () => {
    saveRotatedImageWarning.close();
    cancelModalAction.current();
  };

  const forceCloseViewer = useCallback(() => {
    onChangeImage(-1);
    setRotation(0);
    onClose();
  }, [onChangeImage, onClose]);

  const closeViewer = useCallback(() => {
    if (rotation) {
      if (_shouldSaveRotate) {
        saveRotatedImageWarning.open();
        cancelModalAction.current = forceCloseViewer;
        return;
      }
      setRotation(0);
    }
    forceCloseViewer();
  }, [forceCloseViewer, rotation, _shouldSaveRotate]);

  const downloadImage = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      downloadFileToPC(URL.createObjectURL(blob), getDownloadedImageName?.(currentImage) || blob.name, blob.type);
    } catch (error) {
      showErrorMessage('Something went wrong');
    }
  };

  const showTooltip = () => {
    imageTooltip.open();
    setTimeout(() => {
      imageTooltip.close();
    }, 1500);
  };

  return (
    <ModalBase id="ImagePreview" open={open} onClose={closeViewer}>
      <div className={classes.overlay}>
        <div className={classes.controls}>
          <div className={classes.fill}></div>
          <div className={classes.image_title}>
            <ImageTitleHeader image={currentImage} index={imageIndex} />
          </div>
          <div className={classes.buttons}>
            <HeaderButtons images={images} image={currentImage} index={imageIndex} processingURL={processingURL} />
            <Tooltip disableInteractive title="Reset zoom" aria-label="zoomin">
              <IconButton aria-label="rotate" onClick={resetZoom}>
                <ResetZoomIcon fontSize="large" style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            {!isFile ? (
              <Tooltip disableInteractive title="Rotate Image" aria-label="rotate">
                <IconButton aria-label="rotate" onClick={handleRotate}>
                  <Rotate90DegreesCcwIcon fontSize="large" style={{ color: 'white', transform: 'scaleX(-1)' }} />
                </IconButton>
              </Tooltip>
            ) : null}
            {_shouldSaveRotate && !isFile && !isFileLink ? (
              <Tooltip disableInteractive title="Save Rotated Image" aria-label="save">
                <div>
                  <IconButton aria-label="rotate" disabled={rotation === 0} onClick={handleSaveRotated}>
                    <SaveIcon fontSize="large" style={{ color: rotation !== 0 ? 'white' : 'gray' }} />
                  </IconButton>
                </div>
              </Tooltip>
            ) : null}
            <Tooltip disableInteractive title="Download Image" aria-label="download">
              <IconButton aria-label="download" onClick={downloadImage}>
                <GetApp fontSize="large" style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
            <Tooltip disableInteractive title="Close" aria-label="close">
              <IconButton aria-label="close" onClick={closeViewer}>
                <CloseIcon fontSize="large" style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div className={classes.slider}>
          <IconButton aria-label="left" onClick={back}>
            <ArrowBackIcon fontSize="large" style={{ color: 'white' }} />
          </IconButton>

          <Tooltip
            followCursor
            open={imageTooltip.isOpen}
            title="Double click or scroll to zoom"
            disableInteractive
            placement="bottom-start"
          >
            <div
              className={classes.image_view_wrap}
              ref={imageContainerRef}
              onMouseEnter={showTooltip}
              onMouseLeave={imageTooltip.close}
            >
              {processingURL === url ? (
                <div className={classes.absolute_center}>
                  <CircularProgress size={80} />
                </div>
              ) : null}
              <ZoomableImage ref={zoomRef}>
                <RotatableImage
                  onDraw={centerImage}
                  containerRef={imageContainerRef}
                  imageUrl={url}
                  rotation={rotation}
                />
              </ZoomableImage>
            </div>
          </Tooltip>

          <IconButton aria-label="right" onClick={next}>
            <ArrowForwardIcon fontSize="large" style={{ color: 'white' }} />
          </IconButton>
        </div>
        <ThumbnailImages
          images={images}
          image={images[imageIndex]}
          index={imageIndex}
          processingURL={processingURL}
          onChangeImage={changeImage}
        />
      </div>
      <WarningDialog
        open={saveRotatedImageWarning.isOpen}
        close={closeSaveRotatedImageWarning}
        text="Do you want to save rotated image?"
        processing={Boolean(processingURL)}
        confirm={handleSaveRotated}
      />
    </ModalBase>
  );
};

export default ImagePreview;
