import { FC } from 'react';

import { Country, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, BoxProps, Button, Menu, MenuItem, MenuListProps, Typography, alpha } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

import { TestKey } from 'Constants/tests';
import { DatasetTestIDs } from 'Constants/tests';
import useMenu from 'Utils/hooks/useMenu';

import CountryFlagImage from './CountryFlagImage';

export type CountrySelectProps = {
  country: Country | undefined;
  options: Country[];
  onSelect: (country: Country | undefined) => void;
  containerProps?: BoxProps & { [TestKey]?: string };
  menuProps?: MenuListProps & { [TestKey]?: string };
};

const CountrySelect: FC<CountrySelectProps> = ({ country, options, onSelect, containerProps = {}, menuProps = {} }) => {
  const selectMenu = useMenu();

  const handleSelect = (optionCountry: Country) => {
    onSelect(optionCountry);
    selectMenu.close();
  };

  return (
    <Box display="flex" alignItems="center" height="100%" {...containerProps}>
      <Button
        onClick={selectMenu.open}
        startIcon={<CountryFlagImage country={country} rounded />}
        endIcon={!selectMenu.isOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          borderRadius: '8px',
          '& .MuiButton-startIcon': {
            m: 0,
          },
          '& .MuiButton-endIcon': {
            m: 0,
          },
        })}
      />

      <Menu
        MenuListProps={menuProps}
        anchorEl={selectMenu.anchorEl}
        open={selectMenu.isOpen}
        onClose={selectMenu.close}
      >
        {options.map((optionCountry) => (
          <MenuItem
            key={optionCountry}
            value={optionCountry}
            {...DatasetTestIDs.components.profileForm.options.countryOption}
            sx={(theme) => ({
              background: optionCountry === country && alpha(theme.palette.primary.main, 0.1),
              '&:hover': {
                background: alpha(theme.palette.primary.main, 0.1),
              },
            })}
            onClick={() => {
              handleSelect(optionCountry);
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              gap={10}
              sx={(theme) => ({ color: optionCountry === country && theme.palette.primary.main })}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <CountryFlagImage country={optionCountry} rounded />
                <Typography fontFamily="Poppins Medium, sans-serif" color="inherit">
                  {en[optionCountry]}
                </Typography>
              </Box>

              <Typography fontFamily="Poppins Medium, sans-serif" color="inherit">
                {`+${getCountryCallingCode(optionCountry)}`}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CountrySelect;
