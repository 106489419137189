export const GET_GROUPPED_TIMESHEETS_REQUEST = 'grouppedTimesheets.get_list_request';
export const GET_GROUPPED_TIMESHEETS_SUCCESS = 'grouppedTimesheets.get_list_success';
export const GET_GROUPPED_TIMESHEETS_FAIL = 'grouppedTimesheets.get_list_fail';
export const GET_GROUPPED_TIMESHEETS_ERROR = 'grouppedTimesheets.get_list_error';

export const GET_CONF_TIMESHEETS_REQUEST = 'grouppedTimesheets.get_conf_timesheets_request';
export const GET_CONF_TIMESHEETS_SUCCESS = 'grouppedTimesheets.get_conf_timesheets_success';
export const GET_CONF_TIMESHEETS_FAIL = 'grouppedTimesheets.get_conf_timesheets_fail';
export const GET_CONF_TIMESHEETS_ERROR = 'grouppedTimesheets.get_conf_timesheets_error';

export const CHANGE_CONF_NUMBER = 'grouppedTimesheets.change_conf_number';
export const UPDATE_GROUPPED_FILTERS = 'grouppedTimesheets.update_groupped_filters';
export const UPDATE_CONF_TIMESHEETS_FILTERS = 'grouppedTimesheets.update_conf_timesheets_filters';
export const ClEAR_TIMESHEETS_FILTERS = 'grouppedTimesheets.clear_timesheets_filters';
