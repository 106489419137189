import { FC, useEffect, useMemo, useRef } from 'react';

import moment from 'moment';

import { Box, Tooltip, Typography } from '@mui/material';

import { ChatMessage } from 'Services/API/JobsAPI.service';

import { ChatMessageAvatar } from '../styled';
import { parseNameInitials } from '../utils';
import ChatMessageBody from './ChatMessageBody';

type MessagesChain = {
  userName: string;
  isSupervisorsChain: boolean;
  messages: ChatMessage[];
};

type Props = {
  messages: ChatMessage[];
  jobID?: number | string;
  shiftID?: number;
  retrieveMessages?: () => void;
};

const ChatMessages: FC<Props> = ({ messages, retrieveMessages = () => {}, jobID, shiftID }) => {
  const messagesBoxRef = useRef<HTMLDivElement>(null);

  const chainsOfMessagesPerDay = useMemo(() => {
    const messagesPerDay = messages
      .filter(({ deleted_at }) => !deleted_at)
      .reduce((acc, message) => {
        const date = moment(message.created_at).calendar(null, {
          sameDay: '[Today]',
          sameElse: 'DD MMMM YYYY',
          lastDay: 'DD MMMM YYYY',
          lastWeek: 'DD MMMM YYYY',
        });
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {} as Record<string, ChatMessage[]>);

    const chainsPerDay = Object.entries(messagesPerDay).reduce((acc, [date, messages]) => {
      const chains = messages.reduce((acc, message, index) => {
        if (message.user_id === messages[index - 1]?.user_id) {
          acc[acc.length - 1].messages.push(message);
        } else {
          acc.push({
            userName: message.user_name,
            isSupervisorsChain: Boolean(message.is_coned_supervisor),
            messages: [message],
          });
        }
        return acc;
      }, [] as MessagesChain[]);
      acc[date] = chains;
      return acc;
    }, {} as Record<string, MessagesChain[]>);

    return chainsPerDay;
  }, [messages]);

  useEffect(() => {
    messagesBoxRef.current?.scrollTo(0, messagesBoxRef.current.scrollHeight);
  }, [messages.length]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      flex={1}
      overflow="auto"
      py="30px"
      px="24px"
      sx={{
        scrollBehavior: 'smooth',
      }}
      ref={messagesBoxRef}
    >
      {Object.entries(chainsOfMessagesPerDay).map(([date, messageChains], index) => (
        <Box display="flex" flexDirection="column" gap="10px" key={date + index}>
          <Typography
            mx="auto"
            width="max-content"
            fontFamily={'Poppins Medium'}
            fontSize={14}
            lineHeight={1}
            p="10px"
            bgcolor="secondary.light"
            borderRadius={9999}
          >
            {date}
          </Typography>

          {messageChains.map((chain, chainIndex) => (
            <Box
              display="flex"
              justifyContent={chain.isSupervisorsChain ? 'flex-end' : 'flex-start'}
              width="100%"
              key={chainIndex}
            >
              <Box height={'100%'} mr={'14px'} minWidth={'40px'}>
                {!chain.isSupervisorsChain && (
                  <Tooltip title={chain.userName} disableInteractive>
                    <ChatMessageAvatar>{parseNameInitials(chain.userName)}</ChatMessageAvatar>
                  </Tooltip>
                )}
              </Box>

              <Box display={'flex'} flexDirection="column" gap={1} key={chainIndex} flex={1}>
                {chain.messages.map((message, messageIndex) => (
                  <ChatMessageBody
                    key={message.id}
                    message={message}
                    isFirstUserMessageInChain={messageIndex === 0}
                    nextMessageFromSameUser={messageIndex + 1 <= chain.messages.length - 1}
                    onSendMessageStatus={retrieveMessages}
                    jobID={jobID}
                    shiftID={shiftID}
                  />
                ))}
              </Box>

              <Box height={'100%'} ml={'14px'} minWidth={'40px'}>
                {chain.isSupervisorsChain && (
                  <Tooltip title={chain.userName} disableInteractive>
                    <ChatMessageAvatar isConedSupervisor>{parseNameInitials(chain.userName)}</ChatMessageAvatar>
                  </Tooltip>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default ChatMessages;
