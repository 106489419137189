import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AppThunkDispatch } from 'types';

import CompleteJobImg from 'Assets/icons/complete-job.svg';
import { JobStatus } from 'Constants/job';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useGetTotalHours from 'Utils/hooks/useGetTotalHours';
import useProcessing from 'Utils/hooks/useProcessing';
import AppPaperModal from 'components/AppPaperModal';

import './CompleteJob.scss';

const CompleteJob = ({
  setComplete = () => {},
  onClose = () => {},
  open = false,
  job_id = 0,
  calculateTotalHours = false,
}) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { inProcess: processing, promiseWrapper } = useProcessing();
  const { calculated, displayedTotalHours } = useGetTotalHours(
    JobStatus.COMPLETED,
    job_id,
    open && calculateTotalHours
  );

  const updateJobStatus = (job_id: string | number, options: { status: string }) =>
    dispatch(actions.JobsActions.updateJobStatus(job_id, options));

  const confirmComplete = async () => {
    try {
      setComplete();
      await updateJobStatus(job_id, { status: 'complete' });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      onClose();
    }
  };

  return (
    <AppPaperModal
      onClose={onClose}
      open={open}
      title="Complete Job?"
      subtitle="Has the work been completed?"
      containerStyle={{
        width: '100%',
        maxWidth: '500px',
      }}
      submitButton={{
        title: 'Confirm',
        onClick: () => promiseWrapper(confirmComplete()),
        loading: processing,
        disabled: calculateTotalHours && calculated,
      }}
    >
      <Box className={'complete-image-group'} mt={2} mb={2}>
        <img className={'complete-image'} src={CompleteJobImg} alt="" />
      </Box>
      <Box className={'complete-image-group'} mt={2} mb={2}>
        <Typography color="textSecondary">Has the work been completed?</Typography>
      </Box>
      {calculateTotalHours && (
        <Box className={'complete-image-group'} mt={2} mb={2}>
          <Typography color="textPrimary" variant="h6">
            You will be charged for {displayedTotalHours} hours
          </Typography>
        </Box>
      )}
    </AppPaperModal>
  );
};

export default CompleteJob;
