import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import { TextField } from '@mui/material';

import { actions } from 'Services/index';
import { showErrorMessage } from 'Utils/errorMessage';

import './notes.scss';

class Notes extends React.Component {
  /** @type {React.RefObject<HTMLInputElement>} */
  fieldRef = React.createRef();
  state = {
    note: '',
    category: 'default',
    focused: false,
  };

  componentDidMount() {
    if (this.props.derivedNotes) return;
    this.props.getNotes(this.props.entity_id, this.props.entity_type);
  }

  handleChange = (event) => {
    this.setState({ note: event.target.value });
  };

  /** @param {React.KeyboardEvent<HTMLDivElement>} event */
  enterFunction = async (event) => {
    if (this.state.note !== '' && event.key === 'Enter') {
      event.preventDefault();
      const action_data = {
        entity_type: this.props.entity_type,
        entity_id: this.props.entity_id,
        category: this.state.category,
        body: this.state.note,
      };
      try {
        await this.props.createNote(action_data);
        this.fieldRef.current.blur();
        this.setState({ note: '' });
        if (this.props.derivedNotes) {
          this.props.refetch();
        }
      } catch (error) {
        showErrorMessage(error);
      }
    }
  };

  render() {
    const { notes, derivedNotes } = this.props;

    const notes_formatted = (derivedNotes ? derivedNotes : notes).map((note, index) => {
      return (
        <div className="note-item" key={index}>
          <p className="note-body">{note.body}</p>
          <p className="note-date">
            {moment(note.created_at).format('MMM Do YY h:mm a')}
            {note.added_by && <span className="person">{` by ${note.added_by}`}</span>}
          </p>
        </div>
      );
    });

    return (
      <div className="notes-container">
        <div className="create-note-field">
          <TextField
            fullWidth
            label="Comment"
            InputLabelProps={{ shrink: true }}
            placeholder="Type and Press enter"
            id="note-field"
            margin="normal"
            variant="outlined"
            multiline
            size="small"
            maxRows={3}
            style={{ borderRadius: 4 }}
            inputRef={this.fieldRef}
            value={this.state.note}
            onKeyDown={this.enterFunction}
            onChange={this.handleChange}
          />
        </div>
        <div className="notes-list">{notes_formatted}</div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getNotes: (entity_id, entity_type) => dispatch(actions.NotesActions.getNotes(entity_id, entity_type)),
    createNote: (action_data) => dispatch(actions.NotesActions.createNote(action_data)),
  };
}

function mapStateToProps(state) {
  return {
    notes: state.notes.notes,
    notes_entity_id: state.notes.entity_id,
    notes_entity_type: state.notes.entity_type,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
