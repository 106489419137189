import { TimeLimitation } from 'Constants/companies';

import { Department } from '../types/Common/Companies';

interface StoredDepartments {
  departments: Department[];
  departmentGroups: Department[];
}

export const retrieveDepartmentsFromLS = (key = '') => {
  const defaultStorageDepartments: StoredDepartments = {
    departments: [],
    departmentGroups: [],
  };

  const billingDepartmentsFilters = localStorage.getItem(key);
  const { departments = [], departmentGroups = [] }: StoredDepartments = billingDepartmentsFilters
    ? JSON.parse(billingDepartmentsFilters)
    : defaultStorageDepartments;
  return { departments, departmentGroups };
};

export const saveDepartmentsToLS = (key = '', value: StoredDepartments) => {
  localStorage.setItem(key, JSON.stringify(value));
};
class DepartmentsHelper {
  #departmentsMap: Record<number, Department> = {};

  set(departments: Department[]) {
    this.#departmentsMap = departments.reduce((acc, department) => {
      return {
        ...acc,
        [department.id]: department,
      };
    }, {} as Record<number, Department>);
  }

  get map() {
    return this.#departmentsMap;
  }

  isService(id: number): boolean {
    return Boolean(this.#departmentsMap[id]?.is_service_dept || false);
  }

  isOnlyHoursLimitation(id: number): boolean {
    return this.#departmentsMap[id]?.job_time_limitation === TimeLimitation.OnlyHours || false;
  }
}

export const Departments = new DepartmentsHelper();
