import React, { FC } from 'react';

import { Box, Tabs, InputAdornment } from '@mui/material';

import { Department } from 'types/Common/Companies';

import { JobIcon, LocationIcon, SearchIcon } from 'Utils/Icon';

import { TABS } from '../constants';
import { StyledTab, StyledActionButton, SearchDepartmentInput } from './styled';

type Props = {
  selectedTab: TABS;
  onChangeTab: (event: React.SyntheticEvent, tab: TABS) => void;
  searchValue: string;
  onChangeSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  changedDepartments: { [key: number]: Department };
  onCancelChanges: () => void;
};

const DepartmentsSettingsHeader: FC<Props> = ({
  selectedTab,
  onChangeTab,
  searchValue,
  onChangeSearchValue,
  changedDepartments,
  onCancelChanges,
}) => {
  const changesCount = Object.keys(changedDepartments).length;

  return (
    <Box
      display="flex"
      gap={1}
      justifyContent="space-between"
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex="1"
        bgcolor="white"
        padding="0 10px 0 20px"
        borderRadius={4}
      >
        <Tabs value={selectedTab} onChange={onChangeTab} sx={{ '& .MuiTabs-flexContainer': { gap: 3 } }}>
          <StyledTab label={TABS.departments} value={TABS.departments} icon={<JobIcon />} iconPosition="start" />
          <StyledTab
            label={TABS.departmentsGroup}
            value={TABS.departmentsGroup}
            icon={<LocationIcon />}
            iconPosition="start"
          />
        </Tabs>
        {Boolean(changesCount) && (
          <StyledActionButton
            cancelButton
            onClick={onCancelChanges}
            sx={{
              top: 12,
              right: 16,
              position: {
                xs: 'absolute',
                sm: 'static',
              },
            }}
          >
            Cancel all
          </StyledActionButton>
        )}
      </Box>
      <SearchDepartmentInput
        value={searchValue}
        onChange={onChangeSearchValue}
        placeholder="Search by department"
        name="search-department"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DepartmentsSettingsHeader;
