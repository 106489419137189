import { memo } from 'react';

import { Box, Chip } from '@mui/material';

type Props = {
  paid?: number | boolean | string;
  workerPaid?: number | boolean | string;
  invoiced?: number | boolean | string;
};

const PaidChips = ({ paid, workerPaid, invoiced }: Props) => {
  return (
    <Box display="flex" gap="2px">
      {workerPaid && workerPaid !== '0' ? <Chip label="Worker Paid" /> : null}
      {invoiced && invoiced !== '0' ? <Chip label="Invoiced" /> : null}
      {paid && paid !== '0' ? <Chip label="Invoice Paid" /> : null}
    </Box>
  );
};

export default memo(PaidChips);
