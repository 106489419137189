import { useState } from 'react';
import { connect } from 'react-redux';

import { ListAltRounded, LocationOnRounded, PeopleAltOutlined } from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';

import { JobStatus, JobType } from 'Constants/job';
import useResize from 'Utils/hooks/useResize';
import { useAppSelector } from 'createStore';

import ExpandableBlock from './components/ExpandableBlock/ExpandableBlock';
import './jobmenuitem.scss';

const JobMenuItemComponent = () => {
  const job = useAppSelector((state) => state.jobs.job);
  const screenIsLow = window.screen.width <= 450;
  const [isLowWidth, setIsLowWidth] = useState(screenIsLow);
  useResize(({ target }, stored) => {
    const { innerWidth = 0 } = target;
    if (innerWidth <= 450 && !stored.current) {
      setIsLowWidth(true);
      stored.current = true;
      return;
    }

    if (innerWidth > 450 && stored.current) {
      setIsLowWidth(false);
      stored.current = false;
      return;
    }
  }, screenIsLow);

  const FullWidthContent = (
    <>
      <div className="content">
        <ExpandableBlock title="Workers" additionalInfo={job.workers?.length}>
          <div style={{ padding: '0.7rem 1rem 1rem' }}>
            {job.workers?.map((jobWorker, index) => (
              <div key={'worker-' + jobWorker.id} style={{ display: 'flex', alignItems: 'center', marginBottom: -5 }}>
                <Avatar src={jobWorker.worker?.avatar} style={{ width: 30, height: 30, marginRight: 8 }} />
                <Typography style={{ fontSize: 12 }}>{jobWorker.worker?.name}</Typography>
              </div>
            ))}
          </div>
        </ExpandableBlock>
      </div>
      <div className="job-time-sheet">
        <ExpandableBlock title="Timesheets" additionalInfo={job.total_count_timesheets}>
          <div style={{ padding: '0.7rem 1rem 1rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
              <span>Paid</span>
              <span>{job.total_timesheets_paid}</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span>Unpaid</span>
              <span>{job.total_timesheets_unpaid}</span>
            </div>
          </div>
        </ExpandableBlock>
      </div>
      <div className="job-location">
        <ExpandableBlock title="Locations" additionalInfo={job.locations?.length}>
          <div style={{ padding: '0.7rem 1rem 1rem' }}>
            {job.locations?.map((location, index) => (
              <div className="job-worker-list-item" key={index}>
                <span>{location.address}</span>
              </div>
            ))}
          </div>
        </ExpandableBlock>
      </div>
    </>
  );

  const LowWidthContent = (
    <>
      <div>
        <PeopleAltOutlined htmlColor="orange" style={{ marginRight: 3 }} />
        <span>{job.workers?.length}</span>
      </div>
      <div>
        <ListAltRounded htmlColor="green" style={{ marginRight: 3 }} />
        <span>{job.total_count_timesheets}</span>
      </div>
      <div>
        <LocationOnRounded color="primary" />
        <span>{job.locations?.length}</span>
      </div>
    </>
  );

  return (
    <div className="left-item-body job-details-info">
      <div className="job-menu-item">
        <div className="menu-header">
          <div className="header-title">
            <span className="title">{JobType[job.jobType]}</span>
            <div className={`circle-status circle-${job.status ? job.status.toString().toLowerCase() : ''}`}></div>
            <span className="cursor-pointer m_title">{JobStatus.parsed(job.status)}</span>
          </div>
          {isLowWidth ? LowWidthContent : null}
        </div>
        {!isLowWidth ? FullWidthContent : null}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    job: state.jobs.job,
  };
}

export default connect(mapStateToProps, null)(JobMenuItemComponent);
