import { FC } from 'react';

import moment from 'moment';

import { Box, IconButton, Typography } from '@mui/material';

import { Notifications } from 'types/Notifications';

import { DeleteIcon } from 'Utils/Icon';

import styles from './NotificationItem.module.scss';

type Props = {
  notification: Notifications.Notification;
  onDelete: () => void;
};

const NotificationItem: FC<Props> = ({ notification, onDelete }) => {
  return (
    <Box className={styles.notificationItemWrapper}>
      <Box className={styles.notificationItem}>
        <Typography
          className={styles.message}
          component="span"
          dangerouslySetInnerHTML={{ __html: notification.message }}
        />
        <Typography className={styles.date} component="span">
          {moment(notification.createdAt).format('MM/DD/YYYY hh:mm A')}
        </Typography>
      </Box>
      <IconButton className={styles.deleteNotificationButton} color="primary" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default NotificationItem;
