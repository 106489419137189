import React, { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Box, Modal, ModalProps } from '@mui/material';

type OpenDialog = (
  children: ModalProps['children'],
  props?: Omit<Omit<ModalProps, 'children'>, 'open'> & { open?: ModalProps['open'] }
) => void;

type DialogContext = {
  openFirstDialog: OpenDialog;
  closeFirstDialog: () => void;
  openSecondDialog: OpenDialog;
  closeSecondDialog: () => void;
  closeAllDialogs: () => void;
};

const DialogsContext = createContext<DialogContext>({
  openFirstDialog: () => {},
  closeFirstDialog: () => {},
  openSecondDialog: () => {},
  closeSecondDialog: () => {},
  closeAllDialogs: () => {},
});

export type WithDialogsProps = DialogContext;

export const useDialogsContext = () => useContext(DialogsContext);

export const withDialogs = (Component: React.ComponentType) => {
  const WithDialogs = (props: any) => {
    const context = useDialogsContext();
    return <Component {...context} {...props} />;
  };
  return WithDialogs;
};

const DialogsProvider = ({ children }: PropsWithChildren) => {
  const [firstDialogProps, setFirstDialogProps] = useState<ModalProps>(null);
  const [secondDialogProps, setSecondDialogProps] = useState<ModalProps>(null);

  const openFirstDialog: OpenDialog = useCallback((children = null, props) => {
    const ModifiedChildren = (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        {children}
      </Box>
    );
    setFirstDialogProps({ open: true, children: ModifiedChildren, ...(props ? props : {}) });
  }, []);

  const closeFirstDialog = useCallback(() => {
    setFirstDialogProps(null);
  }, []);

  const openSecondDialog: OpenDialog = useCallback((children = null, props) => {
    const ModifiedChildren = (
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        {children}
      </Box>
    );
    setSecondDialogProps({ open: true, children: ModifiedChildren, ...(props ? props : {}) });
  }, []);

  const closeSecondDialog = useCallback(() => {
    setSecondDialogProps(null);
  }, []);

  const closeAllDialogs = useCallback(() => {
    setFirstDialogProps(null);
    setSecondDialogProps(null);
  }, []);

  const value = useMemo(() => {
    return {
      openFirstDialog,
      closeFirstDialog,
      openSecondDialog,
      closeSecondDialog,
      closeAllDialogs,
    };
  }, [openFirstDialog, closeFirstDialog, openSecondDialog, closeSecondDialog, closeAllDialogs]);

  return (
    <DialogsContext.Provider value={value}>
      {children}
      <Modal
        keepMounted
        onClose={closeFirstDialog}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(firstDialogProps?.style || {}),
        }}
        {...(firstDialogProps || {})}
        open={Boolean(firstDialogProps?.open)}
      >
        {firstDialogProps?.children || <div />}
      </Modal>
      <Modal
        keepMounted
        onClose={closeSecondDialog}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 100000,
          ...(secondDialogProps?.style || {}),
        }}
        {...(secondDialogProps || {})}
        open={Boolean(secondDialogProps?.open)}
      >
        {secondDialogProps?.children || <div />}
      </Modal>
    </DialogsContext.Provider>
  );
};

export default DialogsProvider;
