import moment, { isDate } from 'moment/moment';
import * as Yup from 'yup';

import { TimesheetNames, TimesheetStatus } from 'Constants/timesheet';
import { RegExpConEdisonTruck, RegExpEmployeeNumber } from 'Constants/user';

export const signatureSchema = Yup.object().shape({
  signature_data: Yup.string().nullable(),
  employee_number: Yup.string()
    .nullable()
    .when(['signature_data', 'can_be_signed'], ([signatureData, can_be_signed]: [string, boolean], schema) => {
      if (signatureData && can_be_signed) {
        return schema
          .required('Employee Number is required')
          .matches(RegExpEmployeeNumber, 'Employee Number is not valid');
      }
      return schema;
    }),
});

const signatureRequiredSchema = signatureSchema.shape({
  signature_data: Yup.string()
    .nullable()
    .when('can_be_signed', ([canBeSigned]: [boolean], schema) => {
      if (canBeSigned) {
        return schema.required('Signature is required');
      }
      return schema;
    }),
});

export const TimesheetValidationSchema = Yup.object().shape({
  initialStartDate: Yup.date().nullable(),
  initialFinishDate: Yup.date().nullable(),
  initialSignatureName: Yup.string().nullable(),
  initialSign: Yup.string().nullable(),
  signatureRequired: Yup.boolean().nullable(),
  [TimesheetNames.signatureName]: Yup.string().nullable(),
  [TimesheetNames.startDate]: Yup.date().required('Start Date is required'),
  [TimesheetNames.finishDate]: Yup.date()
    .required('Finish Date is required')
    .transform((value, originalValue) => {
      if (isDate(originalValue)) return originalValue;
      return moment(originalValue).toDate();
    })
    .when('startDate', ([startDate]: [Date], schema: Yup.DateSchema<Date>) =>
      schema.min(moment(startDate).toDate(), 'Finish Date must be later than Start Date')
    ),
  [TimesheetNames.totalHours]: Yup.number()
    .max(16, 'Max hours per shift: 16.00. Need to change dates')
    .typeError('Dates are required'),
  status: Yup.string(),
  [TimesheetNames.sign]: Yup.string()
    .label('Signature')
    .when(
      ['status', 'signatureRequired', 'initialSign', 'signatures'],
      ([status, signatureRequired, initialSign, signatures]: [string, boolean, string, any[]], schema) => {
        if (!signatureRequired || signatures.length) return schema;
        if (status === TimesheetStatus.clockedOut) {
          return schema
            .required('Signature is required')
            .test(
              'signature is new',
              'Please, re-sign timesheet',
              (currentSignature) => currentSignature !== initialSign
            )
            .min(5, 'Signature is empty');
        }
        return schema;
      }
    )
    .nullable(),
  [TimesheetNames.employeeNumber]: Yup.string()
    .label('Employee Number')
    .when(
      [
        'status',
        'initialStartDate',
        'initialFinishDate',
        'startDate',
        'finishDate',
        'signatureName',
        'initialSignatureName',
        'sign',
        'initialSign',
        'signatureRequired',
      ],
      (
        [
          status,
          initialStartDate,
          initialFinishDate,
          startDate,
          finishDate,
          signatureName,
          initialSignatureName,
          sign,
          initialSign,
          signatureRequired,
        ]: [string, Date, Date, Date, Date, string, string, string, string, boolean],
        schema
      ) => {
        if (status === TimesheetStatus.clockedOut) {
          if (!signatureRequired) {
            return schema;
          }
          if (
            initialStartDate?.toISOString() !== startDate?.toISOString() ||
            initialFinishDate?.toISOString() !== finishDate?.toISOString() ||
            initialSignatureName !== signatureName ||
            initialSign !== sign
          ) {
            return schema
              .required('Employee Number is required')
              .min(1, 'Employee Number is required')
              .matches(RegExpEmployeeNumber, 'Employee Number is not valid')
              .test('is valid', 'Employee Number is required', (value) => value !== '0' && value !== 'null')
              .typeError('Employee Number is required');
          }
          return schema;
        }
        return schema;
      }
    )
    .nullable()
    .typeError('Employee Number is required'),
  [TimesheetNames.conEdisonTruck]: Yup.string()
    .nullable()
    .max(12, 'Con Edison Truck max length is 12 symbols')
    .matches(RegExpConEdisonTruck, 'Con Edison Truck is not valid. Allowed symbols: 0-9, A-Z, a-z and space'),
  [TimesheetNames.signatures]: Yup.array().when('signatureRequired', ([signatureRequired]: [boolean], schema) => {
    return schema.of(signatureRequired ? signatureRequiredSchema : signatureSchema);
  }),
});
