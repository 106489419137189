import React, { PropsWithChildren, useEffect } from 'react';

import clsx from 'clsx';

import { CloseRounded } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { useWaitTimer } from 'Utils/hooks/useWaitTimer';

import s from './AppDialog.module.scss';

type Props = {
  onClose: () => void;
  title: string;
  subtitle?: string;
  containerStyle?: React.CSSProperties;
  confirmRequired?: boolean;
  waitTimer?: {
    initialValue: number;
    getButtonTitle?: (timer: number) => string;
  };
  submitButton: {
    title?: string;
    loading?: boolean;
  } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  cancelButton?: {
    title?: string;
  } & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  contentProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
} & PropsWithChildren;

const AppDialog = ({
  onClose,
  title,
  subtitle = '',
  submitButton = {},
  cancelButton = {},
  containerStyle = {},
  contentProps = {},
  confirmRequired = false,
  waitTimer = {
    initialValue: 0,
    getButtonTitle: (timer) => `Wait...${timer}`,
  },
  children,
}: Props) => {
  const { loading, ...submitButtonProps } = submitButton || { loading: false };

  const { timer, runTimer, clearTimer } = useWaitTimer(waitTimer?.initialValue || 0);

  useEffect(() => {
    if (timer === 0 && waitTimer?.initialValue > 0) {
      clearTimer();
    }
    if (timer === waitTimer?.initialValue) {
      runTimer();
    }
  }, [timer, waitTimer?.initialValue]);

  const getWaitTimerButtonTitle = waitTimer?.getButtonTitle || ((timer) => `Wait...${timer}`);

  const timerText = timer && getWaitTimerButtonTitle(timer);

  return (
    <div
      className={s.paperContainer}
      style={{
        ...containerStyle,
        maxHeight: containerStyle.maxHeight
          ? `min(${getCssSize(containerStyle.maxHeight)}, calc(100% - 24px))`
          : 'calc(100% - 24px)',
        maxWidth: containerStyle.maxWidth
          ? `min(${getCssSize(containerStyle.maxWidth)}, calc(100vw - 24px))`
          : 'calc(100vw - 24px)',
      }}
    >
      <div className={s.headerContainer}>
        <div className={s.titleWrap}>
          <h6 className={s.title}>{title}</h6>
          {subtitle && <div className={s.subtitle}>{subtitle}</div>}
        </div>
        {!confirmRequired && (
          <div className={s.closeButton} onClick={onClose}>
            <CloseRounded />
          </div>
        )}
      </div>
      {children && (
        <div {...contentProps} className={clsx(s.content, contentProps?.className)}>
          {children}
        </div>
      )}
      <div className={s.buttonContainer}>
        <button
          type="button"
          onClick={onClose}
          {...cancelButton}
          className={clsx(s.actionButton, s.cancelButton, cancelButton?.className)}
        >
          {cancelButton?.title || 'Cancel'}
        </button>
        <button
          type="button"
          {...submitButtonProps}
          className={clsx(s.actionButton, s.confirmButton, submitButton?.className, { [s.waiting]: Boolean(timer) })}
          disabled={loading || submitButton?.disabled || Boolean(timer)}
        >
          {loading && (
            <div className="absolute-fill-center">
              <CircularProgress color="inherit" size={30} style={{ marginRight: 6 }} />
            </div>
          )}
          {timerText || submitButton?.title || 'Save Changes'}
        </button>
      </div>
    </div>
  );
};

export default AppDialog;

function getCssSize(size: string | number) {
  if (typeof size === 'number') {
    return `${size}px`;
  }
  return size;
}
