import { FC } from 'react';

import { Box, IconButton, Skeleton } from '@mui/material';

import { DeleteIcon } from 'Utils/Icon';

import styles from './NotificationItem.module.scss';

const NotificationItemSkeleton: FC = () => {
  return (
    <Box className={styles.notificationItemWrapper}>
      <Box className={styles.notificationItem} width="100%">
        <Skeleton variant="text" className={styles.message} component="span" width="55%" />
        <Skeleton variant="text" className={styles.date} component="span" width="15%" />
      </Box>
      <Skeleton variant="rounded" className={styles.deleteNotificationButton} width={30} height={30} />
    </Box>
  );
};

export default NotificationItemSkeleton;
