import { useEffect } from 'react';

import { ImagePreviewProps } from 'components/ImagePreview/types';

import { useImagePreview } from './Context';

export default function ImagePreviewConsumer<ImageType extends { url: string }>(props: ImagePreviewProps<ImageType>) {
  const { updateProps, openedId, updateOpenedId, viewerId } = useImagePreview();

  useEffect(() => {
    const show = props.open || props.imageIndex >= 0;
    if (show) {
      updateOpenedId(viewerId);
      updateProps((prev) => ({ ...prev, ...props }));
      return () => {
        updateProps((prev) => ({ ...prev, open: false, imageIndex: -1 }));
        updateOpenedId('');
      };
    }
    if (!show && openedId === viewerId) {
      updateProps((prev) => ({ ...prev, open: false, imageIndex: -1 }));
      updateOpenedId('');
      return;
    }
    if (openedId === viewerId) {
      updateProps((prev) => ({ ...prev, ...props }));
      return () => {
        updateProps((prev) => ({ ...prev, open: false, imageIndex: -1 }));
        updateOpenedId('');
      };
    }
  }, [...Object.values(props), updateProps, openedId, viewerId]);

  return null;
}
