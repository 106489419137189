import { AppThunkAction } from 'types';

import { InvoicesAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import { objectValuesFilter } from 'Utils/valueFilter';
import app_history from 'app_history';

import { getNotes } from '../notes/actions';
import * as actionTypes from './actionTypes';
import { InvoicesReducer, ConfigurationInvoicesSearchOptions } from './reducer';

export const FILTERS_STORAGE_KEY = 'invoices.filters';

export function getConfigurationInvoices(
  id: string | number,
  search_options: Partial<ReturnType<InvoicesReducer['invoices_pagination']['asMutable']>> = {}
): AppThunkAction<any> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_CONFIGURATION_INVOICES_REQUEST });
      const { invoices_pagination, invoices_search_options } = getState().invoices;
      const searchOptions = {
        ...invoices_pagination.asMutable(),
        ...invoices_search_options.asMutable(),
        ...search_options,
      };
      const response = await InvoicesAPI.getConfigInvoices(id, objectValuesFilter(searchOptions, [null, '']));
      const options = {
        ...searchOptions,
        page: response.page,
        limit: response.limit,
        total: response.total,
      };
      dispatch({ type: actionTypes.GET_CONFIGURATION_INVOICES_SUCCESS, list: response.results, options, id });
    } catch (error) {
      dispatch({ type: actionTypes.GET_CONFIGURATION_INVOICES_ERROR });
    }
  };
}

export function getInvoices(
  search_options: Partial<ReturnType<InvoicesReducer['invoices_pagination']['asMutable']>> = {}
): AppThunkAction<any> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_INVOICES_REQUEST });
      const { invoices_pagination, invoices_search_options } = getState().invoices;
      const searchOptions = {
        ...invoices_pagination.asMutable(),
        ...invoices_search_options.asMutable(),
        ...search_options,
      };
      const response = await InvoicesAPI.getInvoices(objectValuesFilter(searchOptions, [null, '']));
      const options = {
        ...searchOptions,
        page: response.page,
        limit: response.limit,
        total: response.total,
      };
      dispatch({ type: actionTypes.GET_INVOICES_SUCCESS, list: response.results, options });
    } catch (error) {
      dispatch({ type: actionTypes.GET_INVOICES_ERROR });
    }
  };
}

export function getTimesheets(
  config_id: string | number,
  invoice_id: string | number,
  search_options: Partial<ReturnType<InvoicesReducer['timesheet_pagination']['asMutable']>> = { limit: 10, page: 1 }
): AppThunkAction<void> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_INVOICE_TIMESHEETS_REQUEST });
      const allOptions = { ...getState().invoices.timesheet_pagination.asMutable(), ...search_options };
      const filteredOptions = objectValuesFilter(allOptions, [null]);
      const response = await InvoicesAPI.getInvoiceTimesheets(config_id, invoice_id, filteredOptions);
      const pagination = {
        ...filteredOptions,
        page: response.page,
        limit: response.limit,
        totalPage: response.totalPage,
        total: response.total,
      };
      dispatch({
        type: actionTypes.GET_INVOICE_TIMESHEETS_SUCCESS,
        timesheets: response.results,
        invoice: response.invoice,
        pagination,
        invoice_id,
        config_id,
      });
    } catch (error) {
      dispatch({ type: actionTypes.GET_INVOICE_TIMESHEETS_ERROR });
    }
  };
}

export function markTimesheets(config_id: string | number, data): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.MARK_TIMESHEET_AS_PAID_REQUEST });
      const response = await InvoicesAPI.markTimesheetsAsPaid(data);
      const invoice_id = getState().invoices.timesheets_invoice_id;

      dispatch({ type: actionTypes.MARK_TIMESHEET_AS_PAID_SUCCESS });
      dispatch(getConfigurationInvoices(config_id, {}));
      dispatch(getTimesheets(config_id, invoice_id, {}));
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.MARK_TIMESHEET_AS_PAID_ERROR });
      throw error;
    }
  };
}

export function getConfiguration(config_id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_CONFIGURATION_REQUEST });
      const response = await InvoicesAPI.getConfig(config_id);

      dispatch({
        type: actionTypes.GET_CONFIGURATION_SUCCESS,
        conf: response.config,
      });
    } catch (error) {
      dispatch({ type: actionTypes.GET_CONFIGURATION_ERROR });
    }
  };
}

//iptate invoice configuration
export function updateInvoice(id, data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPDATE_INVOICE_REQUEST });
      const response = await InvoicesAPI.updateConfig(id, data);

      dispatch({ type: actionTypes.UPDATE_INVOICE_SUCCESS, item: response.invoice_config });

      dispatch(retrieve());
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_INVOICE_ERROR });
      throw error;
    }
  };
}

//Deactivate invoice configuration
export function deleteInvoice(id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DELETE_INVOICE_REQUEST });
      const response = await InvoicesAPI.deleteConfig(id);

      dispatch({ type: actionTypes.DELETE_INVOICE_SUCCESS, item: { response, id } });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.DELETE_INVOICE_ERROR });
      throw error;
    }
  };
}

export function retrieve(search_options = null): AppThunkAction<Promise<void>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_CONFIGURATIONS_REQUEST });
      if (search_options === null) {
        search_options = getState().invoices.search_options.asMutable();
      }
      const response = await InvoicesAPI.getAllConfigs(search_options);

      const state_search_options = getState().invoices.search_options;

      if (JSON.stringify(search_options) !== JSON.stringify(state_search_options)) {
        return;
      }

      const search_option = {
        ...search_options,
        page: response.page,
        limit: response.limit,
        totalPage: response.totalPage,
        total: response.total,
      };

      dispatch({
        type: actionTypes.GET_CONFIGURATIONS_SUCCESS,
        list: response.results,
        search_options: search_option,
      });
    } catch (error) {
      dispatch({ type: actionTypes.GET_CONFIGURATIONS_ERROR });
    }
  };
}

export function createInvoice(data) {
  return async function (dispatch, getState) {
    try {
      const current_search_options = getState().invoices.search_options;
      dispatch({ type: actionTypes.CREATE_INVOICE_REQUEST });
      const response = await InvoicesAPI.createConfig(data);

      dispatch({
        type: actionTypes.CREATE_INVOICE_SUCCESS,
        list: response.results,
      });
      dispatch(retrieve(current_search_options));
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_INVOICE_ERROR });
      throw error;
    }
  };
}

export function createInvoiceFromConfig(
  id: string | number,
  timeInterval?: { from_datetime: string; to_datetime: string }
): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.CREATE_INVOICE_FROM_CONFIG_REQUEST });
      const response = await InvoicesAPI.generateConfigInvoice(id, timeInterval);

      dispatch({
        type: actionTypes.CREATE_INVOICE_FROM_CONFIG_SUCCESS,
        config: response.config,
      });
      app_history.push(`/invoices/${response.config.id}/${response.invoice.id}`);
      dispatch(getConfigurationInvoices(id, { page: 1 }));
      //dispatch(retrieve(current_search_options));
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_INVOICE_FROM_CONFIG_ERROR });
      throw error;
    }
  };
}
export function revertInvoice(config_id, invoice_id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.REVERT_INVOICE_REQUEST });
      const response = await InvoicesAPI.revertInvoice(config_id, invoice_id);

      dispatch({
        type: actionTypes.REVERT_INVOICE_SUCCESS,
      });
      app_history.push(`/invoices/${config_id}`);
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.REVERT_INVOICE_ERROR });
      showErrorMessage(error);
      throw error;
    }
  };
}
export function updateInvoiceStatus(config_id, invoice_id, status) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPDATE_INVOICE_STATUS_REQUEST });
      const response = await InvoicesAPI.updateInvoiceStatus(config_id, invoice_id, status);

      dispatch({
        type: actionTypes.UPDATE_INVOICE_STATUS_SUCCESS,
        invoice: response.invoice,
      });
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_INVOICE_STATUS_ERROR });
    }
  };
}
export function assignMoreTimesheets(config_id, invoice_id) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.ASSIGN_MORE_TIMESHEETS_REQUEST });
      await InvoicesAPI.assignTimesheetsToInvoice(config_id, invoice_id);

      dispatch({
        type: actionTypes.ASSIGN_MORE_TIMESHEETS_SUCCESS,
      });
      dispatch(getNotes(invoice_id, 'invoice'));
      dispatch(getTimesheets(config_id, invoice_id));
    } catch (error) {
      dispatch({ type: actionTypes.ASSIGN_MORE_TIMESHEETS_ERROR });
    }
  };
}

export function downloadInvoice(confId, invId): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DOWNLOAD_INVOICE_REQUEST });
      const file = await InvoicesAPI.downloadInvoice(confId, invId);

      dispatch({
        type: actionTypes.DOWNLOAD_INVOICE_SUCCESS,
      });
      return file;
    } catch (error) {
      dispatch({ type: actionTypes.DOWNLOAD_INVOICE_ERROR });
      throw error;
    }
  };
}

export function downloadPOs(confId, invId): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DOWNLOAD_POS_REQUEST });
      const file = await InvoicesAPI.downloadPOs(confId, invId);

      dispatch({
        type: actionTypes.DOWNLOAD_POS_SUCCESS,
      });
      return file;
    } catch (error) {
      dispatch({ type: actionTypes.DOWNLOAD_POS_ERROR });
      throw error;
    }
  };
}

export function downloadNonPaidTimesheets(
  confId: number | string,
  params: {
    po?: string | number;
    conf?: string | number;
  } = {}
): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    return InvoicesAPI.downloadNonPaidTimesheets(confId, params);
  };
}

export function updateInvoiceTime(config_id, invoice_id, data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.EDIT_TIME_INVOICE_REQUEST });
      const response = await InvoicesAPI.updateInvoiceDate(config_id, invoice_id, data);

      dispatch({ type: actionTypes.EDIT_TIME_INVOICE_SUCCESS });
      // refresh
      dispatch(getNotes(invoice_id, 'invoice'));
      dispatch(getTimesheets(config_id, invoice_id));
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.EDIT_TIME_INVOICE_ERROR });
      throw error;
    }
  };
}

export function removeUnpaidTimesheet(
  config_id: string | number,
  invoice_id: string | number,
  timesheet_id: number | string
): AppThunkAction<Promise<any>> {
  return async function (dispatch, getState) {
    dispatch({ type: actionTypes.REMOVE_UNPAID_TIMESHEET_REQUEST });
    try {
      await InvoicesAPI.removeUnpaidTimesheet(config_id, invoice_id, timesheet_id);
      dispatch({ type: actionTypes.REMOVE_UNPAID_TIMESHEET_SUCCESS });
      dispatch(getNotes(invoice_id, 'invoice'));
      dispatch(getTimesheets(config_id, invoice_id));
    } catch (error) {
      dispatch({ type: actionTypes.REMOVE_UNPAID_TIMESHEET_ERROR });
      throw error;
    }
  };
}

export function initFilters(): any {
  return function (dispatch, getState) {
    const storage_filters = JSON.parse(localStorage.getItem(FILTERS_STORAGE_KEY));
    dispatch({ type: actionTypes.UPDATE_FILTERS, filters: storage_filters });
    dispatch(retrieve(storage_filters));
  };
}

export function updateFilters(search_options = {}): AppThunkAction<void> {
  return function (dispatch, getState) {
    const current_search_options = getState().invoices.search_options;
    const new_search_options = { ...current_search_options, ...search_options };
    dispatch({ type: actionTypes.UPDATE_FILTERS, filters: new_search_options });
    dispatch(retrieve(new_search_options));
    localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(new_search_options));
  };
}

export function updateSearchInvoiceInputs(search_options: {
  conf?: string;
  po?: string;
  id?: string;
}): AppThunkAction<void> {
  return function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_INVOICES_SEARCH_OPTIONS, search_options });
  };
}

export function updateInvoicesFilters(
  config_id: string | number | null,
  search_options: ConfigurationInvoicesSearchOptions
): AppThunkAction<void> {
  return function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_INVOICES_SEARCH_OPTIONS, search_options });
    if (config_id) {
      dispatch(getConfigurationInvoices(config_id, { page: 1 }));
    } else {
      dispatch(getInvoices({ page: 1 }));
    }
  };
}
