import { NotesAPI } from 'Services/API';

import * as actionTypes from './actionTypes';

const search_options = {
  page: 1,
  per_page: 1000,
  entity_type: 'car',
  entity_id: 0,
  sort_by: 'created_at',
  sort_by_type: true,
};

export function createNote(action) {
  return async function (dispatch, getState) {
    if (!action.entity_id || !action.body || !action.body.length) {
      dispatch({ type: actionTypes.CREATE_NOTE_ERROR });
    }
    try {
      const data = {
        entity_type: action.entity_type,
        entity_id: action.entity_id,
        category: action.category,
        body: action.body,
      };
      dispatch({ type: actionTypes.CREATE_NOTE_REQUEST });
      await NotesAPI.create(data);
      dispatch({
        type: actionTypes.CREATE_NOTE_SUCCESS,
        note: data,
      });
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_NOTE_ERROR });
    }
  };
}

export function updateNote(note) {
  return async function (dispatch, getState) {
    if (!note.entity_id || !note.body || !note.body.length) {
      dispatch({ type: actionTypes.UPDATE_NOTE_ERROR });
    }

    try {
      dispatch({ type: actionTypes.UPDATE_NOTE_REQUEST });
      await NotesAPI.update(note.id, note);
      dispatch({
        type: actionTypes.UPDATE_NOTE_SUCCESS,
        note: note,
      });
      // dispatch(actions.NotificationsActions.alert('Great!', 'You have updated note', 'success'));
      return;
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_NOTE_ERROR });
      throw error;
    }
  };
}

/**
 *
 * @param {string|number} entity_id
 * @param {string|number} entity_type
 * @returns {import('types').AppThunkAction<Promise<import('types/Notes').Note[]>>}
 */
export function getNotes(entity_id, entity_type) {
  return async function (dispatch, getState) {
    if (!entity_id || !entity_type) {
      dispatch({ type: actionTypes.GET_NOTES_ERROR, entity_id, entity_type });
    }
    try {
      search_options.entity_id = entity_id;
      search_options.entity_type = entity_type;

      dispatch({ type: actionTypes.GET_NOTES_REQUEST });
      const response = await NotesAPI.getAll(search_options);
      dispatch({
        type: actionTypes.GET_NOTES_SUCCESS,
        notes: response.notes.data,
        entity_id: entity_id,
        entity_type: entity_type,
      });
      return response.notes.data;
    } catch (error) {
      dispatch({ type: actionTypes.GET_NOTES_ERROR });
      throw error;
    }
  };
}
