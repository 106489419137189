import { cloneElement, memo, MouseEvent, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

const TextOverflowTooltip = ({ children, title, ...rest }: TooltipProps) => {
  const childrenRef = useRef<HTMLElement>();
  const [tooltipTitle, setTooltipTitle] = useState(null);

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;
    const updateTitle = () => {
      if (!childrenRef || !childrenRef.current) {
        resizeObserver?.disconnect?.();
        return;
      }
      if (childrenRef.current.clientWidth < childrenRef.current.scrollWidth) {
        setTooltipTitle(title);
        return;
      }
      setTooltipTitle(null);
    };
    if ('ResizeObserver' in window) {
      resizeObserver = new ResizeObserver(updateTitle);
      resizeObserver.observe(childrenRef.current);
    }

    updateTitle();
    return () => {
      try {
        resizeObserver?.disconnect();
      } catch (error) {
        return;
      }
    };
  }, [title]);

  const onClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <Tooltip ref={childrenRef} PopperProps={{ onClick }} title={tooltipTitle ?? ''} {...rest}>
      {cloneElement(children, { ref: childrenRef })}
    </Tooltip>
  );
};

export default memo(TextOverflowTooltip);
