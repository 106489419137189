import { styled, TextField, TextFieldProps } from '@mui/material';

type AppInputProps = Omit<TextFieldProps, 'variant'> & { grayLabel?: boolean };

const AppInput = styled(function AppInput(props: AppInputProps) {
  return (
    <TextField
      {...props}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
        ...props.InputLabelProps,
        style: {
          ...props.InputLabelProps?.style,
          ...(props.value && !props.error ? { color: props.grayLabel ? '#BDBDBD' : '#4F4F4F' } : {}),
        },
      }}
    />
  );
})({
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
  },

  '& .Mui-disabled .MuiOutlinedInput-input': {
    WebkitTextFillColor: '#727272',
    color: '#727272',
  },

  '& .MuiOutlinedInput-input, & .MuiInputBase-multiline': {
    padding: '12px 16px',
  },

  '& .MuiInputBase-inputMultiline': {
    padding: 0,
  },

  '& .Mui-disabled .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E0E0E0 !important',

    '& > legend': {
      maxWidth: 0,
    },
  },

  '& .MuiInputLabel-outlined': {
    position: 'static',
    transform: 'none',
    marginBottom: 8,
    fontSize: '0.75rem',
    fontWeight: 500,
    fontFamily: 'Roboto',
  },
});

export default AppInput;
