import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Container } from '@mui/material';

import { AppThunkDispatch } from 'types';

import PageTitle from 'Containers/Components/Typography/PageTitle';
import { getCurrentUserNotifications } from 'Services/notifications/actions';

import styles from './Notifications.module.scss';
import NotificationsFilter from './containers/NotificationsFilter/NotificationsFilter';
import NotificationsList from './containers/NotificationsList/NotificationsList';

export type NotificationsFilterType = {
  groupName: string;
  options: {
    label: string;
    value: number;
  }[];
};

const Notifications = () => {
  const dispatch = useDispatch<AppThunkDispatch>();

  useEffect(() => {
    dispatch(getCurrentUserNotifications());
  }, [dispatch]);

  return (
    <Container maxWidth="lg" className={styles.notificationPageContainer}>
      <PageTitle>Notifications </PageTitle>
      <Box className={styles.notificationContentWrapper}>
        <NotificationsFilter />
        <NotificationsList />
      </Box>
    </Container>
  );
};

export default Notifications;
