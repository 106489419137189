import React, { MouseEvent, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import moment from 'moment';
import { toast } from 'react-toastify';

import { AddRounded, RemoveRounded } from '@mui/icons-material';
import { Box, LinearProgress, TableCell, TableRow, colors } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { OrderOtpions } from 'types/Common/App';
import { GrouppedTimesheetItem, TimesheetTableItem, TimesheetsSearchOption } from 'types/Timesheet';

import { baseToastConfig } from 'Constants/app';
import { JobStatus } from 'Constants/job';
import { TimesheetStatus } from 'Constants/timesheet';
import { ActionTypes } from 'Services';
import { retrieveGroupped, retrieveTimesheets, updateTimesheetsConfNum } from 'Services/grouppedTimesheets/actions';
import { fetchTimesheets } from 'Services/timesheets/actions';
import usePagination from 'Utils/hooks/usePagination';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { useAppSelector } from 'createStore';

import { ActionButton } from '..';
import SelectedTimesheetActions from '../../Dialog/TimesheetActions/SelectedTimesheetActions';
import TimesheetActions from '../../Dialog/TimesheetActions/TimesheetActions';
import TimesheetTable from '../../TimesheetTable';
import { createTableColumnsConfig, useTimesheetActions } from '../../utils';
import MasterTimesheetOpenButton from '../MasterTimesheetOpenButton';
import PaidChips from '../PaidChips';
import TrimmedText from '../TrimmedText';
import VerifiedChip from '../VerifiedChip';

type Props = {} & RouteComponentProps<any>;

const GrouppedTimesheetsTable = (props: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  const timesheets = useAppSelector((state) => state.grouppedTimesheets.list);
  const searchOptions = useAppSelector((state) => state.grouppedTimesheets.grouppedTimesheetsOptions);
  const loading = useAppSelector((state) => state.grouppedTimesheets.processing);

  const expandedTimesheets = useAppSelector((state) => state.grouppedTimesheets.timesheets);
  const timesheetsLoading = useAppSelector((state) => state.grouppedTimesheets.timesheets_processing);
  const timesheetOptions = useAppSelector((state) => state.grouppedTimesheets.timesheetsOptions);
  const expandedRowId = useAppSelector((state) => state.grouppedTimesheets.conf_number);

  const downloading = useAppSelector((state) => state.timesheets.downloadingChecked);

  const { selectedRow, anchorEl, open, close, edit } = useTimesheetActions<TimesheetTableItem>({
    history: props.history,
  });

  const { params } = useSearchParams<{ confirmation: string }>();

  const updateFilters = (search_options: Partial<OrderOtpions>) => dispatch(retrieveTimesheets(search_options));

  const updateGrouppedFilters = (search_options: Partial<TimesheetsSearchOption>) =>
    dispatch(retrieveGroupped(search_options));

  const { onChangePage, onPerPageChange } = usePagination(updateGrouppedFilters);

  const expandGroup =
    (confirmation = 0) =>
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      if (confirmation === expandedRowId) {
        dispatch(updateTimesheetsConfNum(null));
        return;
      }
      if (confirmation === cachedTimesheets[0]?.confirmationNumber) {
        dispatch({
          type: ActionTypes.GrouppedTimesheetsActionTypes.CHANGE_CONF_NUMBER,
          payload: confirmation,
        });
        dispatch({
          type: ActionTypes.GrouppedTimesheetsActionTypes.GET_CONF_TIMESHEETS_SUCCESS,
          payload: { confirmation, results: cachedTimesheets },
        });
      }
      dispatch(updateTimesheetsConfNum(confirmation));
    };

  const { order_by, order_by_type } = searchOptions;

  useEffect(() => {
    dispatch(retrieveGroupped({}));
  }, []);

  const [confRowAnchor, setConfRowAnchor] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState<GrouppedTimesheetItem>();
  const [selectedTimesheets, setSelectedTimesheets] = useState<TimesheetTableItem[]>([]);
  const [confActionsLoadingId, setConfActionsLoadingId] = useState(0);
  const [cachedTimesheets, setCachedTimesheets] = useState<TimesheetTableItem[]>([]);

  const openConfActions = async (e: MouseEvent<HTMLButtonElement>, row: GrouppedTimesheetItem) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedGroup(row);

    const alreadyFetchedTimesheets =
      row.confirmation === expandedRowId
        ? expandedTimesheets.asMutable({ deep: true })
        : row.confirmation === cachedTimesheets[0]?.confirmationNumber
        ? cachedTimesheets
        : [];
    if (alreadyFetchedTimesheets.length) {
      setSelectedTimesheets(alreadyFetchedTimesheets);
      setCachedTimesheets(alreadyFetchedTimesheets);
      setConfRowAnchor(e.target);
      return;
    }
    try {
      setConfActionsLoadingId(row.confirmation);
      const timesheets = await fetchTimesheets({ confirmation: row.confirmation.toString() });
      setSelectedTimesheets(timesheets.results);
      setCachedTimesheets(timesheets.results);

      setConfRowAnchor(e.target);
    } catch (error) {
      toast.error(error?.message || 'Somethings went wrong...', baseToastConfig);
    } finally {
      setConfActionsLoadingId(0);
    }
  };

  const closeConfActions = () => {
    setConfRowAnchor(null);
    setSelectedTimesheets([]);
    setSelectedGroup(null);
  };

  // TODO: FIX value prop according to info from backend's developer
  const TimesheetsColumns = createTableColumnsConfig<GrouppedTimesheetItem>([
    {
      label: '',
      value: 'expand_status',
      renderData: (row) =>
        expandedRowId === row.confirmation ? <RemoveRounded fontSize="small" /> : <AddRounded fontSize="small" />,
      headCellProps: () => ({ style: { paddingRight: 0 } }),
    },
    {
      label: 'Start Date',
      value: 'start_at',
      key: 'start_at',
      sortable: true,
      renderData: (row) => moment(row.start_at).format('MM/DD/YY [•] HH:mm'),
    },
    {
      label: 'Finish Date',
      value: 'finish_at',
      key: 'finish_at',
      sortable: true,
      renderData: (row) => moment(row.finish_at).format('MM/DD/YY [•] HH:mm'),
    },
    { label: 'Confirmation №', value: 'confirmation', key: 'confirmation', sortable: true },
    { label: 'Shifts #', value: 'shifts_count', key: 'shifts_count', sortable: true },
    { label: 'Total Hrs', value: 'total_hours', key: 'total_hours', sortable: true },
    { label: 'Workers #', value: 'max_workers', key: 'max_workers', sortable: true },
    {
      label: 'PO#',
      value: 'po_number',
      key: 'po_number',
      sortable: true,
      renderData: (row) => <TrimmedText text={row.po_number} />,
    },
    {
      label: 'Job Status',
      value: 'status',
      key: 'status',
      sortable: true,
      renderData: (row) => JobStatus.parsed(String(row.status)),
    },
    {
      label: 'CE Verified',
      value: 'ce_verified_count',
      key: 'ce_verified_count',
      sortable: true,
      renderData: (row) => `${row.ce_verified_count}/${row.timesheets_count}`,
    },
    {
      label: 'CES Verified',
      value: 'ces_verified_count',
      key: 'ces_verified_count',
      sortable: true,
      renderData: (row) => `${row.ces_verified_count}/${row.timesheets_count}`,
    },
    {
      label: 'Paid',
      value: 'paid_count',
      key: 'paid_count',
      sortable: true,
      renderData: (row) => `${row.paid_count}/${row.timesheets_count}`,
    },
    {
      label: 'Actions',
      value: 'actions',
      renderData: (row) => (
        <Box display="flex" gap={1}>
          <MasterTimesheetOpenButton confirmation={row.confirmation} jobType={row.type_id} jobStatus={row.status} />
          <ActionButton
            loading={
              confActionsLoadingId === row.confirmation ||
              (row.confirmation === selectedTimesheets[0]?.confirmationNumber && downloading)
            }
            onClick={(e) => openConfActions(e, row)}
          />
        </Box>
      ),
    },
  ]);

  const ExpandedTableConfig = createTableColumnsConfig<TimesheetTableItem>([
    {
      label: 'Start Date',
      value: 'startDate',
      key: 'startDate',
      sortable: true,
      renderData: (row) => moment(row.startDate).format('MM/DD/YY [•] HH:mm'),
    },
    {
      label: 'Finish Date',
      value: 'finishDate',
      key: 'finishDate',
      sortable: true,
      renderData: (row) => moment(row.finishDate).format('MM/DD/YY [•] HH:mm'),
    },
    { label: 'Shift Id', value: 'job_worker_id', key: 'job_worker_id', sortable: true },
    { label: 'Total Hrs', value: 'totalHours', key: 'totalHours', sortable: true },
    { label: 'Worker', value: 'worker_name', key: 'worker_name', sortable: true },
    {
      label: 'Status',
      value: 'status',
      key: 'status',
      sortable: true,
      renderData: (row) => TimesheetStatus.toUserString(row.status),
    },
    {
      label: 'CE Verified',
      value: 'supervisor_verified',
      key: 'supervisor_verified',
      sortable: true,
      renderData: (row) => (
        <VerifiedChip
          isVerified={row.supervisor_verified}
          title="CE"
          signaturesCount={row.signatures_count}
          neededSignaturesCount={row.needed_signatures_count}
        />
      ),
    },
    {
      label: 'CES Verified',
      value: 'verified',
      key: 'isVerified',
      sortable: true,
      renderData: (row) => <VerifiedChip isVerified={row.isVerified} title="CES" />,
    },
    {
      label: 'Paid',
      value: 'paid',
      key: 'paid',
      sortable: true,
      renderData: (row) => <PaidChips paid={row.paid} workerPaid={row.workerPaid} invoiced={row.invoiced} />,
    },
    {
      label: 'Actions',
      value: 'actions',
      renderData: (row) => (
        <>
          <ActionButton onClick={(e) => open(e, row)} />
        </>
      ),
    },
  ]);

  // Nested Table Scroll code block - Start. This block of code is needed to smoothly scroll to end of nested table, when nested table is bigger than main table.
  const paginationRef = useRef<HTMLDivElement>();
  const scrollValueRef = useRef(0);
  const bodyRef = useRef<HTMLDivElement>();
  const refForScroll = useRef<HTMLTableRowElement>();
  const confRowRef = useRef<HTMLTableRowElement>();
  const confTimesheetsRef = useRef<HTMLDivElement>();

  // Height of table cell in px
  const cellHeight = 45;
  const maxHeight = useMemo(() => {
    if (!bodyRef.current || !paginationRef.current) return cellHeight * 4;
    const mainHeight = bodyRef.current.clientHeight;
    const paginationHeight = paginationRef.current.clientHeight;
    // Sum of padding-top and padding-bottom of 'timesheet-body' className;
    const bodyVerticalPadding = 20;
    // Sum of padding-top and padding-bottom of table cell;
    const cellVerticalPadding = 20;
    return mainHeight - paginationHeight - cellHeight * 2 - cellVerticalPadding - bodyVerticalPadding;
  }, [bodyRef.current, paginationRef.current]);

  useEffect(() => {
    if (!expandedTimesheets.length && loading) return;
    const currentScrollPosition = scrollValueRef.current;
    const confTimesheetsHeight = confTimesheetsRef.current?.clientHeight || 0;
    const confRowOffset = confRowRef.current?.offsetTop || 0;
    const isConfTableExceedTable =
      confRowOffset - currentScrollPosition - cellHeight + confTimesheetsHeight > maxHeight;
    if (isConfTableExceedTable) {
      refForScroll.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
  }, [expandedTimesheets, loading]);

  const onScrollCapture = useCallback((event: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.target as HTMLDivElement;
    scrollValueRef.current = scrollTop;
  }, []);

  useEffect(() => {
    dispatch(retrieveTimesheets({}));
  }, []);

  useEffect(() => {
    if (params.confirmation) {
      updateGrouppedFilters({ confirmation: params.confirmation });
      dispatch(updateTimesheetsConfNum(Number(params.confirmation)));
    } else {
      updateGrouppedFilters({ confirmation: null });
      dispatch(updateTimesheetsConfNum(null));
    }
  }, [params.confirmation]);

  // Nested Table Scroll code block - End.

  return (
    <div className="timesheet-body" ref={/**  Nested Table Scroll code value */ bodyRef}>
      <div className="timesheets-tables-wrap">
        <TimesheetTable<GrouppedTimesheetItem>
          data={timesheets}
          columnsConfig={TimesheetsColumns}
          changeOrder={updateGrouppedFilters}
          orderByType={order_by_type}
          orderByValue={order_by}
          tableRowProps={(row) => ({
            onClick: expandGroup(row.confirmation),
            selected: expandedRowId === row.confirmation,
            // Nested Table Scroll code line
            ref: expandedRowId === row.confirmation ? confRowRef : null,
          })}
          // Nested Table Scroll code line
          PaperProps={{ onScrollCapture }}
          AdditionalRows={(row) =>
            expandedRowId === row.confirmation && (
              <TableRow ref={/**  Nested Table Scroll code value */ refForScroll}>
                <TableCell colSpan={13} style={{ position: 'relative', backgroundColor: '#cecece59' }}>
                  {timesheetsLoading && <LinearProgress style={{ position: 'absolute', top: 0, left: 0, right: 0 }} />}
                  <div style={{ maxHeight, height: '100%', display: 'flex' }}>
                    <TimesheetTable<TimesheetTableItem>
                      data={expandedTimesheets}
                      columnsConfig={ExpandedTableConfig}
                      orderByType={timesheetOptions.order_by_type}
                      orderByValue={timesheetOptions.order_by}
                      changeOrder={updateFilters}
                      // Nested Table Scroll code line
                      PaperProps={{ ref: confTimesheetsRef }}
                      tableRowProps={(row) => ({
                        onClick: () => edit(row.id),
                        selected: selectedRow?.id === row.id,
                        style: { backgroundColor: row.pending ? colors.red[50] : 'transparent' },
                      })}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )
          }
        />
      </div>

      <AppTablePagination
        ref={/**  Nested Table Scroll code value */ paginationRef}
        page={searchOptions.page}
        onPaginationChange={onPerPageChange}
        total={searchOptions.total}
        perPage={searchOptions.per_page}
        onChangePage={onChangePage}
      />

      <TimesheetActions handleClose={close} anchorEl={anchorEl} timesheet={selectedRow} editTimesheet={edit} />
      <SelectedTimesheetActions
        anchorEl={confRowAnchor}
        handleClose={closeConfActions}
        selectedTimesheets={selectedTimesheets}
        uncheckSome={setSelectedTimesheets}
        selectedGroup={selectedGroup}
        hideUncheckButton
      />
    </div>
  );
};

export default GrouppedTimesheetsTable;
