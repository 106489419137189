import { CSSProperties, ChangeEvent, useCallback, useState } from 'react';

import _ from 'lodash';

import { Autocomplete, FormControl, TextField, TextFieldProps } from '@mui/material';

import { AppAPI } from 'Services/API';
import { APP_API } from 'Services/API/AppAPI.service';
import useProcessing from 'Utils/hooks/useProcessing';

type Props = {
  value: Omit<APP_API.PlaceItem, 'place_id'>;
  name?: string;
  style?: CSSProperties;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  onBlur?: TextFieldProps['onBlur'];
  size?: TextFieldProps['size'];
  onChange: (data: { name: string; lat: number; lon: number; address: string }) => void;
  error?: boolean;
  helperText?: string;
};

const AddressAutocomplete = ({
  value = null,
  name = '',
  disabled = false,
  style = {},
  placeholder = '',
  label = '',
  onBlur = () => {},
  size = 'medium',
  onChange = () => {},
  error = false,
  helperText = '',
}: Props) => {
  const [places, setPlaces] = useState<APP_API.PlaceItem[]>([]);
  const [inputValue, setInputValue] = useState(value?.address || '');
  const { inProcess: loading, promiseWrapper } = useProcessing();

  const throttledRequest = useCallback(
    _.throttle(
      (searchValue = '') => {
        promiseWrapper(AppAPI.getPlacesByAddress(searchValue))
          .then((response) => {
            setPlaces(response.places);
          })
          .catch(() => {
            setPlaces([]);
            onChange({
              name: name,
              lat: 0,
              lon: 0,
              address: searchValue,
            });
          });
      },
      500,
      { trailing: true }
    ),
    [onChange, name]
  );

  const handleAddressInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: newInputValue } = e.target;
    if (value?.address) {
      onChange({
        name,
        lat: 0,
        lon: 0,
        address: '',
      });
    }
    setInputValue(newInputValue);
    setPlaces([]);
    throttledRequest(newInputValue.toLowerCase());
  };

  const selectAdress = (event, value: string | APP_API.PlaceItem) => {
    if (value && typeof value !== 'string') {
      onChange({
        name,
        lat: value.lat,
        lon: value.lon,
        address: value.address,
      });
      setInputValue(value.address);
      setPlaces([value]);
      return;
    }
    if (!value) {
      onChange({
        name,
        lat: 0,
        lon: 0,
        address: '',
      });
      setInputValue('');
      setPlaces([]);
    }
  };

  const getOptionLabel = (option: string | APP_API.PlaceItem) =>
    (typeof option !== 'string' ? option?.address : option) || '';

  return (
    <FormControl style={{ width: '100%', ...style }}>
      <Autocomplete<APP_API.PlaceItem, false, true, true>
        disabled={disabled}
        freeSolo
        includeInputInList
        inputValue={inputValue}
        options={places}
        loading={!places.length || loading}
        loadingText={loading ? 'Searching...' : 'No locations found'}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => option.address === value.address}
        value={value as APP_API.PlaceItem}
        onChange={selectAdress}
        filterOptions={(x) => x}
        onInput={handleAddressInput}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            position: 'static',
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              name: name,
              autoComplete: 'none',
              'aria-autocomplete': 'none',
              'aria-invalid': 'false',
            }}
            InputLabelProps={{ shrink: true }}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            onBlur={onBlur}
            size={size}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </FormControl>
  );
};

export default AddressAutocomplete;
