const hexRegExp = /^#([0-9A-Fa-f]{2,2})([0-9A-Fa-f]{2,2})([0-9A-Fa-f]{2,2})$/;

const hexToRgb = (hex = '#FFFFFF') => {
  const defaultReturn = [255, 255, 255];
  if (!hex) return defaultReturn;
  const testedArray = hexRegExp.exec(hex);
  if (!testedArray) return defaultReturn;
  const [, r, g, b] = testedArray;
  if (!r || !g || !b) return defaultReturn;
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function needWhiteIcon(hex: string) {
  const [r, g, b] = hexToRgb(hex);
  const minColor = 170;
  return (r < minColor && g < minColor) || [r, g, b].every((v) => v < minColor);
}

export default { hexToRgb, stringToColor, needWhiteIcon };
