import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { CloseRounded, TuneRounded } from '@mui/icons-material';
import { Button, Divider, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';

import { ConEdSupervisor } from 'types/Common/User';

import { JOB_STATUSES, JobType } from 'Constants/job';
import { MUNICIPALITY } from 'Constants/job';
import RolesAsyncSearch from 'Containers/Components/Controls/RolesAsyncSearch';
import search from 'Images/search.png';
import { actions } from 'Services';
import * as CeIcon from 'Utils/Icon';
import { downloadFileToPC } from 'Utils/downloadFile';
import { showErrorMessage } from 'Utils/errorMessage';
import DatePicker from 'components/Picker/DatePicker';
import { ReduxState } from 'createStore';

import DepartmentAsyncSearch from '../Components/Controls/DepartmentAsyncSearch';
import MunicipalitiesAsyncSearch from '../Components/Controls/MunicipalitiesAsyncSearch';
import RequestorAsyncSearch from '../Components/Controls/RequestorAsyncSearch';
import { SupervisorsAsyncSearch } from '../Components/Controls/SupervisorsAsyncSearch';
import CETextInputComponent from '../Components/Controls/TextInput.Component';
import { WorkerAsyncSearch } from '../Components/Controls/WorkerAsyncSearch';
import ExportToFileButton from '../Components/ExportToFileButton/ExportToFileButton';
import './searchstyle.scss';
import './style.scss';

const jobStatusesFilter = [
  { label: 'New', name: 'new', value: JOB_STATUSES.New },
  { label: 'On Hold', name: 'on_hold', value: JOB_STATUSES.OnHold },
  { label: 'In Progress', name: 'inprogress', value: JOB_STATUSES.InProgress },
  { label: 'Under Review', name: 'review', value: JOB_STATUSES.Review },
  { label: 'Completed', name: 'completed', value: JOB_STATUSES.Completed },
  { label: 'Cancelled', name: 'cancelledJobs', value: JOB_STATUSES.Cancelled },
  { label: 'Cancelled Billable', name: 'cacnelledBillable', value: JOB_STATUSES.CancelledBillable },
];

enum JobSource {
  All = '0',
  Imported = '1',
  NotImported = '2',
}

interface Props {
  hasExportButton?: boolean;
  hasJobStatus?: boolean;
  hasRequestDate?: boolean;
  hasCreateDate?: boolean;
  hasDepartment?: boolean;
  hasRequestor?: boolean;
  hasWorker?: boolean;
  hasAddress?: boolean;
  hasFieldSupervisor?: boolean;
  hasBorough?: boolean;
  hasNumber?: boolean;
  hasFilter?: boolean;
  hasSort?: boolean;
  showFilter?: boolean;
  showSearch?: boolean;
  onFilter?: Function;
  numberMonth?: number;
  onFilterPress?: (event) => void;
  search: (params: any) => void;
  onFilterByLocation?: (location, radius, radiusType) => void;
  onShowFilters?: () => void;
  onHideFilters?: () => void;
}

const initialSearch = {
  jobType: [],
  jobStatus: [0, 1],
  schedules_needed: null,
  late_workers: null,
  unassigned: null,
  requestor_roles: [] as number[],
  requestDate: {
    from: null,
    to: null,
  },
  createdDate: {
    from: null,
    to: null,
  },
  searchByEffectiveRequestDate: false,
  location: null,
  municipality: null,
  requestor: null,
  supervisor: null,
  department: null,
  worker: null,
  search: null,
  radius: '',
  radiusType: null,
  structure: '',
  purchase: '',
  worker_number: '',
  page: 0,
  secured_jobs: false,
  field_supervisor: true,
  coned_imported: null as boolean | null,
};

type State = {
  supervisor: ConEdSupervisor;
  search: typeof initialSearch;
  initSearch: boolean;
  showSort: boolean;
  showFilter: boolean;
  showSearch: boolean;
  exportProcessing: boolean;
};

const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: 0,
  '& .PrivateSwitchBase-root-2': {
    padding: 5,
  },
  '& .MuiCheckbox-root.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

export class FilterComponent extends React.Component<React.PropsWithChildren<Props & PropsFromRedux>, State> {
  static defaultProps = {
    onFilter: () => {},
  };
  state = {
    supervisor: null,
    search: { ...initialSearch },
    initSearch: true,
    showSort: false,
    showFilter: this.props.showFilter,
    showSearch: this.props.showSearch,
    exportProcessing: false,
  };
  static getDerivedStateFromProps(props, state) {
    if (state.initSearch) {
      return {
        search: { ...initialSearch, ...props.search_options },
        initSearch: false,
      };
    }
    return state;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search_options !== this.props.search_options) {
      this.setState({ search: { ...this.state.search, ...this.props.search_options.asMutable({ deep: true }) } });
    }
  }

  toggleFilterView = () => {
    this.setState(
      (state: any) => ({ showFilter: !state.showFilter }),
      () => {
        if (this.state.showFilter) {
          this.props.onShowFilters?.();
          return;
        }
        this.props.onHideFilters?.();
      }
    );
  };

  clearFilters = () => {
    this.setState({
      search: { ...initialSearch },
      supervisor: null,
    });
    this.onFilterChanged({ ...initialSearch });
    this.forceUpdate();
  };

  onFilterChanged = (filters = null) => {
    this.props.search(filters ?? this.state.search);
    this.props.onFilter();
  };

  handleChangeSecuredJobs = () => {
    const { search } = this.state;
    this.setState({ search: { ...search, secured_jobs: !search.secured_jobs } }, () => {
      this.onFilterChanged();
    });
  };

  toggleConedImported = (_, value: JobSource) => {
    this.setState(
      ({ search }) => ({
        search: {
          ...search,
          coned_imported: value === JobSource.All ? null : value === JobSource.Imported ? true : false,
        },
      }),
      () => {
        this.onFilterChanged();
      }
    );
  };

  toggleEffectiveRequestDate = () => {
    this.setState(
      ({ search }) => ({
        search: {
          ...search,
          searchByEffectiveRequestDate: !search.searchByEffectiveRequestDate,
        },
      }),
      () => {
        this.onFilterChanged();
      }
    );
  };

  toggleLastYearJobsOnly = () => {
    this.props.search({
      ...this.props.search_options,
      last_year_jobs_only: !this.props.search_options.last_year_jobs_only,
    });

    this.props.onFilter();
  };

  handleResetJobType = () => {
    this.setState(
      (state: any) => ({
        search: {
          ...state.search,
          jobType: this.state.search.jobType.length ? [] : [JobType.Parking, JobType.Flagging, JobType.Signage],
        },
      }),
      this.onFilterChanged
    );
  };

  handleChangeJobType = (name) => (event) => {
    const checked = event.target.checked;
    if (name === 'all') {
      this.setState(
        (state: any) => ({
          search: {
            ...state.search,
            jobType: checked ? [JobType.Parking, JobType.Flagging, JobType.Signage] : [],
          },
        }),
        this.onFilterChanged
      );
      return;
    }
    this.setState(
      (state: any) => ({
        search: {
          ...state.search,
          jobType: checked ? [...state.search.jobType, name] : state.search.jobType.filter((type) => type !== name),
        },
      }),
      this.onFilterChanged
    );
  };

  handleResetJobStatus = () => {
    if (this.state.search.jobStatus.length > 0) {
      this.setState(
        (state: any) => ({
          search: {
            ...state.search,
            jobStatus: [],
          },
        }),
        this.onFilterChanged
      );
    } else {
      this.setState(
        (state: any) => ({
          search: {
            ...state.search,
            jobStatus: [
              JOB_STATUSES.New,
              JOB_STATUSES.OnHold,
              JOB_STATUSES.InProgress,
              JOB_STATUSES.Completed,
              JOB_STATUSES.Billed,
              JOB_STATUSES.Paid,
              JOB_STATUSES.Review,
              JOB_STATUSES.Cancelled,
              JOB_STATUSES.CancelledBillable,
            ],
          },
        }),
        this.onFilterChanged
      );
    }
  };

  exportJobsToCSV = async () => {
    this.setState({ exportProcessing: true });
    try {
      const file: Blob = await this.props.exportJobGridToCSV();
      const fileUrl = URL.createObjectURL(file);
      downloadFileToPC(fileUrl, `jobs-table`, 'xlsx');
    } catch (error) {
      const message = typeof error.error === 'string' ? error.error : 'Something went wrong';
      showErrorMessage(message);
    } finally {
      this.setState({ exportProcessing: false });
    }
  };

  handleChangeJobStatus = (name) => (event) => {
    const checked = event.target.checked;
    if (name === 'all') {
      this.setState(
        (state: any) => ({
          search: {
            ...state.search,
            jobStatus: checked
              ? [
                  JOB_STATUSES.New,
                  JOB_STATUSES.InProgress,
                  JOB_STATUSES.Completed,
                  JOB_STATUSES.Billed,
                  JOB_STATUSES.Paid,
                  JOB_STATUSES.Cancelled,
                  JOB_STATUSES.CancelledBillable,
                ]
              : [],
          },
        }),
        this.onFilterChanged
      );
      return;
    }

    this.setState(
      (state: any) => ({
        search: {
          ...state.search,
          jobStatus: checked
            ? [...state.search.jobStatus, name]
            : state.search.jobStatus.filter((type) => type !== name),
        },
      }),
      this.onFilterChanged
    );
  };

  handleChangeFilter = (name, value, fields = {}) => {
    this.setState(
      (state: any) => ({
        search: {
          ...state.search,
          [name]: value,
          ...fields,
        },
      }),
      this.onFilterChanged
    );
  };

  handleChangeInput = (event) => {
    const {
      currentTarget: { name, value },
    } = event;
    this.handleChangeFilter(name, value);
  };

  onKeyUpSearch = (event) => {
    if (event.keyCode === 13) {
      const {
        currentTarget: { name, value },
      } = event;
      this.handleChangeFilter(name, value);
    }
  };

  renderHeader = () => {
    return (
      <div className="left-item search-box__wrapper">
        <div className="left-item-body d-flex">
          <div className="form-control-search mr-2 w-100">
            <img src={search} alt="" />
            <input
              className="ce-form-control "
              placeholder="Search"
              name="search"
              type="search"
              onChange={(e) => this.handleChangeInput(e)}
              value={this.state.search.search ? this.state.search.search : ''}
              onBlur={this.handleChangeInput}
              onKeyUp={this.onKeyUpSearch}
            />
          </div>

          <div className="btn-group">
            {this.props.hasFilter ? (
              <Tooltip disableInteractive title="Filter" aria-label="filter" arrow>
                <button
                  onClick={this.toggleFilterView}
                  className={`btn border d-flex align-items-center p-relative ${
                    this.state.showFilter ? 'sort-active' : ''
                  }`}
                  type="button"
                >
                  {this.state.showFilter ? <CeIcon.FilterWhiteIcon /> : <CeIcon.FilterIcon />}
                </button>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  renderBody = () => {
    const { requestor_roles } = this.state.search;
    return (
      <div className="filter-box" style={{ overflowX: 'hidden' }}>
        <div className="left-item">
          <div className="filter-box__wrapper job-type mb-2">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span className="filter-box__label">Job Type</span>
              <Button onClick={this.handleResetJobType} sx={{ fontSize: 12, textDecoration: 'underline' }}>
                {this.state.search?.jobType?.length ? 'Reset Selected' : 'Select All'}
              </Button>
            </div>
            <div className="left-item-body">
              <div className="filter-box__checkboxes-group">
                <FormGroup>
                  <StyledFormLabel
                    control={
                      <Checkbox
                        checked={this.state.search.jobType.includes(JobType.Flagging)}
                        onChange={this.handleChangeJobType(JobType.Flagging)}
                        name="flagging"
                      />
                    }
                    label="Flagging"
                  />
                  <StyledFormLabel
                    control={
                      <Checkbox
                        checked={this.state.search.jobType.includes(JobType.Parking)}
                        onChange={this.handleChangeJobType(JobType.Parking)}
                        name="parking"
                      />
                    }
                    label="Parking"
                  />
                  <StyledFormLabel
                    control={
                      <Checkbox
                        checked={this.state.search.jobType.includes(JobType.Signage)}
                        onChange={this.handleChangeJobType(JobType.Signage)}
                        name="signage"
                      />
                    }
                    label="Signage"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <Divider />

          {this.props.hasJobStatus && (
            <div className="filter-box__wrapper job-status">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span className="filter-box__label">Job Status</span>
                <Button onClick={this.handleResetJobStatus} sx={{ fontSize: 12, textDecoration: 'underline' }}>
                  {this.state.search.jobStatus.length ? 'Reset Selected' : 'Select All'}
                </Button>
              </div>
              <div className="left-item-body">
                <div className="filter-box__checkboxes-group">
                  <div className=" ">
                    <FormGroup>
                      {jobStatusesFilter.map(({ value, label, name }) => (
                        <StyledFormLabel
                          key={value}
                          control={
                            <Checkbox
                              checked={this.state.search.jobStatus.includes(value)}
                              onChange={this.handleChangeJobStatus(value)}
                              name={name}
                            />
                          }
                          label={label}
                        />
                      ))}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Divider />

          <div className="left-item">
            <div className="filter-box__wrapper job-type mb-2">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="filter-box__checkboxes-group">
                  <FormGroup>
                    <StyledFormLabel
                      style={{ marginBottom: 8 }}
                      control={
                        <Checkbox
                          checked={this.state.search.secured_jobs}
                          onChange={this.handleChangeSecuredJobs}
                          name="secured_jobs"
                        />
                      }
                      label="Secured Jobs Only"
                    />
                  </FormGroup>
                  <FormGroup style={{ marginBottom: 16 }}>
                    <Typography variant="subtitle1">Job Source:</Typography>
                    <ToggleButtonGroup
                      value={
                        this.state.search.coned_imported === null
                          ? JobSource.All
                          : this.state.search.coned_imported
                          ? JobSource.Imported
                          : JobSource.NotImported
                      }
                      onChange={this.toggleConedImported}
                      size="small"
                      exclusive
                      color="primary"
                    >
                      <ToggleButton sx={{ flex: 1 }} value={JobSource.All}>
                        All
                      </ToggleButton>
                      <ToggleButton sx={{ flex: 1 }} value={JobSource.Imported}>
                        WMS
                      </ToggleButton>
                      <ToggleButton sx={{ flex: 1 }} value={JobSource.NotImported}>
                        UFS
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          {this.props.hasExportButton ? (
            <>
              <div className="filter-box__wrapper">
                <ExportToFileButton
                  onClick={this.exportJobsToCSV}
                  processing={this.state.exportProcessing}
                  disabled={this.state.exportProcessing}
                >
                  Export jobs to CSV
                </ExportToFileButton>
              </div>
              <Divider />
            </>
          ) : null}

          {this.props.hasRequestDate && (
            <div className="filter-box__wrapper request-date" style={{ borderBottom: '1px solid #eeeeee' }}>
              <div className="left-item-header" style={{ marginBottom: 12 }}>
                <span className="filter-box__label_small">
                  {this.props.search_options.searchByEffectiveRequestDate ? 'Effective Request Date' : 'Request Date'}
                </span>
              </div>
              <div className="request-date-content" style={{ position: 'relative' }}>
                <DatePicker
                  showClearDates
                  numberMonth={this.props.numberMonth}
                  onChange={(date) => {
                    this.handleChangeFilter('requestDate', {
                      from: date.from_datetime,
                      to: date.to_datetime,
                    });
                  }}
                  from_datetime={this.props.search_options.requestDate?.from || null}
                  to_datetime={this.props.search_options.requestDate?.to || null}
                />
              </div>
              <StyledFormLabel
                control={
                  <Checkbox
                    checked={this.props.search_options.searchByEffectiveRequestDate}
                    onChange={this.toggleEffectiveRequestDate}
                    name="effectiveRequestDate"
                  />
                }
                label="Use Effective Dates"
              />
            </div>
          )}
          {this.props.hasCreateDate && (
            <div
              className="filter-box__wrapper request-date"
              style={{ display: 'flex', flexDirection: 'column', gap: 12 }}
            >
              <div className="left-item-header">
                <span className="filter-box__label_small">Create Date</span>
              </div>
              <div className="request-date-content" style={{ position: 'relative' }}>
                <DatePicker
                  showClearDates
                  numberMonth={this.props.numberMonth}
                  onChange={(date) => {
                    this.handleChangeFilter('createdDate', {
                      from: date.from_datetime,
                      to: date.to_datetime,
                    });
                  }}
                  from_datetime={this.props.search_options.createdDate?.from || null}
                  to_datetime={this.props.search_options.createdDate?.to || null}
                />
              </div>
            </div>
          )}

          {/* Invert checkbox logic below to use the opposite label. "Include Archived Jobs" instead of "Last Year Jobs Only". Task CS-1657. */}
          <div className="filter-box__wrapper">
            <StyledFormLabel
              control={
                <Checkbox
                  checked={!this.props.search_options.last_year_jobs_only}
                  onChange={this.toggleLastYearJobsOnly}
                  name="last_year_jobs_only"
                />
              }
              label="Include Archived Jobs"
            />
          </div>

          <Divider />

          <div className="filter-box__wrapper">
            <label style={{ display: 'block' }}>
              <span style={{ fontSize: 12 }}>
                Roles {requestor_roles.length >= 3 ? `(${requestor_roles.length} options selected)` : ''}
              </span>
              <RolesAsyncSearch
                isMulti
                value={requestor_roles}
                defaultValue={requestor_roles}
                placeholder="Roles that created job"
                onChange={(value) => {
                  this.handleChangeFilter('requestor_roles', value);
                }}
              />
            </label>
          </div>

          {this.props.hasDepartment && (
            <div className="filter-box__wrapper">
              <div className="left-item-header">
                <span className="filter-box__label_small">Department</span>
              </div>
              <div>
                <DepartmentAsyncSearch
                  value={this.state.search.department}
                  onSelect={(item) => this.handleChangeFilter('department', item ? item : null)}
                />
              </div>
            </div>
          )}
          {this.props.hasRequestor && (
            <div className="filter-box__wrapper">
              <div className="left-item-header ">
                <span className="filter-box__label_small">Requester</span>
              </div>
              <div>
                <RequestorAsyncSearch
                  isClearable
                  value={this.state.search.requestor}
                  onSelect={(item) => this.handleChangeFilter('requestor', item ? item : null)}
                />
              </div>
            </div>
          )}
          <div className="filter-box__wrapper">
            <div className="left-item-header ">
              <span className="filter-box__label_small">Supervisor</span>
            </div>
            <div>
              <SupervisorsAsyncSearch
                isClearable
                supervisorId={
                  !Number.isNaN(this.state.search.supervisor) ? Number(this.state.search.supervisor) : undefined
                }
                value={this.state.supervisor}
                onSelect={(item) => {
                  this.handleChangeFilter('supervisor', item ? item?.id : null);
                  this.setState({ supervisor: item ? item : null });
                }}
              />
            </div>
          </div>
          {this.props.hasFieldSupervisor && (
            <div className="filter-box__wrapper field-supervisor">
              <div className="left-item-header">
                <span className="filter-box__label_small">Field Supervisor</span>
              </div>
              <div className="left-item-body">
                <StyledFormLabel
                  control={
                    <Checkbox
                      checked={this.state.search.field_supervisor}
                      onChange={(event) => {
                        this.handleChangeFilter('field_supervisor', event.target.checked);
                      }}
                      name="unassigned"
                    />
                  }
                  label="View All Dept Jobs"
                />
              </div>
            </div>
          )}
          {this.props.hasWorker && (
            <div className="filter-box__wrapper">
              <div className="left-item-header">
                <span className="filter-box__label_small">Worker</span>
              </div>
              <div>
                <WorkerAsyncSearch
                  selectedId={this.state.search.worker?.id}
                  isClearable
                  onSelect={(item) => this.handleChangeFilter('worker', item ? item.value : null)}
                />
              </div>
            </div>
          )}
          {this.props.hasAddress && (
            <>
              <div className="filter-box__wrapper">
                <div className="left-item-body">
                  <CETextInputComponent
                    onChange={this.handleChangeInput}
                    name={'location'}
                    title="Address"
                    value={this.state.search.location || ''}
                    placeholder="Find locations"
                  />
                </div>
              </div>
            </>
          )}
          {this.props.hasBorough && (
            <div className="filter-box__wrapper">
              <div className="left-item-header">
                <span className="filter-box__label_small">Municipality</span>
              </div>
              <div className="left-item-body d-flex">
                <div className="d-block w-100">
                  <MunicipalitiesAsyncSearch
                    value={
                      this.state.search.municipality
                        ? MUNICIPALITY.find((item) => item.value === this.state.search.municipality?.[0]) || null
                        : null
                    }
                    onSelect={(item) => {
                      this.handleChangeFilter('municipality', item ? [item.value] : null);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {this.props.hasNumber && (
            <>
              <div className="filter-box__wrapper">
                <div className="left-item-body">
                  <div className="left-item-header">
                    <span className="filter-box__label_small">Numbers</span>
                  </div>
                </div>
              </div>
              <div className="filter-box__wrapper">
                <div className="left-item-body">
                  <CETextInputComponent
                    onChange={this.handleChangeInput}
                    name={'structure'}
                    className="ce-pd-bottom-20"
                    title="Structure Number"
                    value={this.state.search.structure}
                  />
                </div>
              </div>
              <div className="filter-box__wrapper">
                <CETextInputComponent
                  onChange={this.handleChangeInput}
                  name={'purchase'}
                  className="ce-pd-bottom-20"
                  title="Purchase Order Number"
                  value={this.state.search.purchase}
                />
              </div>
              <div className="filter-box__wrapper">
                <div className="left-item-body">
                  <CETextInputComponent
                    onChange={this.handleChangeInput}
                    name={'worker_number'}
                    className="ce-pd-bottom-20"
                    title="Worker Request Number"
                    value={this.state.search.worker_number}
                  />
                </div>
              </div>
            </>
          )}

          <div className="filter-box__wrapper">
            <button className="btn filter-button" onClick={this.clearFilters} style={{ width: '100%' }}>
              Clear filters
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { showFilter } = this.state;
    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
        {this.state.showSearch && (
          <div>
            {this.renderHeader()}
            <Divider />
          </div>
        )}

        <div className={showFilter ? 'map-page-filter-wrapper' : 'hidden'}>{this.renderBody()}</div>

        {this.props.hasFilter && !this.props.showSearch ? (
          <div className="filter-container" data-open={String(showFilter)} onClick={this.toggleFilterView}>
            {showFilter ? <CloseRounded /> : <TuneRounded />}
          </div>
        ) : null}
        <div style={{ flex: 1 }} className={`w-100 ${showFilter ? 'hidden d-none' : 'd-flex flex-column'}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    search_options: state.jobs.search_options,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    search: (search_opt) => dispatch(actions.JobsActions.updateFilters(search_opt)),
    exportJobGridToCSV: () => dispatch(actions.JobsActions.exportToCSV()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FilterComponent);
