import React from 'react';

const statusLabel = (status) => {
  let component = '';
  const default_style = {
    minWidth: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    //boxShadow: '3px 3px 2px 1px rgba(108, 108, 109, 0.082)'
  };
  const default_text_style = {
    fontSize: 20,
    color: '#FFF',
  };
  switch (status) {
    case 'draft':
      component = (
        <div style={{ ...default_style, backgroundColor: '#ffb328' }}>
          <span style={{ ...default_text_style, color: '#FFF' }}>Draft</span>
        </div>
      );
      break;
    case 'billed':
      component = (
        <div style={{ ...default_style, backgroundColor: '#0099d8' }}>
          <span style={{ ...default_text_style, color: '#FFFFFF' }}>Billed</span>
        </div>
      );
      break;
    case 'paid':
      component = (
        <div style={{ ...default_style, backgroundColor: '#02b337' }}>
          <span style={{ ...default_text_style, color: '#FFFFFF' }}>Paid</span>
        </div>
      );
      break;
    default:
      break;
  }
  return component;
};

export { statusLabel };
