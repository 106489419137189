import Immutable from 'seamless-immutable';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = Immutable({
  search_options: {
    per_page: 100,
    entity_type: 'car',
    entity_id: 0,
  },
  comments: [] as {
    id: number;
    comment?: string;
    body?: string;
    added_by?: string;
    author_first_name?: string;
    added_by_user_id: number;
  }[],
  processing_key: '',
  processing: false,
  processing_delete: false,
  processing_update: false,
  processing_create: false,

  currentPage: 1,
});

let comments;
//let comment;

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    // GET ALL COMMENTS
    case actionTypes.GET_COMMENTS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_COMMENTS_SUCCESS:
      return state.merge({
        processing: false,
        comments: action.comments,
      });
    case actionTypes.GET_COMMENTS_FAIL:
      return state.merge({
        processing: false,
      });
    case actionTypes.GET_COMMENTS_ERROR:
      return state.merge({
        processing: false,
      });

    // CREATE COMMENT
    case actionTypes.CREATE_COMMENTS_REQUEST:
      return state.merge({
        processing_create: true,
      });
    case actionTypes.CREATE_COMMENTS_SUCCESS:
      return state.merge({
        processing_create: false,
        comments: [...state.asMutable({ deep: true }).comments, action.comment.comment],
      });
    case actionTypes.CREATE_COMMENTS_FAIL:
      return state.merge({
        processing_create: false,
      });
    case actionTypes.CREATE_COMMENTS_ERROR:
      return state.merge({
        processing_create: false,
      });

    // UPDATE COMMENT
    case actionTypes.UPDATE_COMMENTS_REQUEST:
      return state.merge({
        processing_update: true,
      });
    case actionTypes.UPDATE_COMMENTS_SUCCESS:
      //console.log(state.timesheets.results);
      const index = state.comments ? state.comments.findIndex((i) => i.id === action.comment.id) : -1;
      if (index === -1) {
        return state;
      } else {
        comments = JSON.parse(JSON.stringify(state.comments));
        //comment = Immutable.asMutable(comments[index]);
        //comment = action.comment;
        comments[index] = action.comment;
        //console.log(job);
        //console.log(timesheets)
        return state.merge({
          processing_update: false,
          comments: comments,
        });
      }
    case actionTypes.UPDATE_COMMENTS_FAIL:
      return state.merge({
        processing_update: false,
      });
    case actionTypes.UPDATE_COMMENTS_ERROR:
      return state.merge({
        processing_update: false,
      });

    // DELEET COMMENT
    case actionTypes.DELETE_COMMENTS_REQUEST:
      return state.merge({
        processing_delete: true,
      });
    case actionTypes.DELETE_COMMENTS_SUCCESS:
      const i = state.comments.findIndex((i) => i.id === action.comment.id);
      if (i === -1) {
        return state;
      } else {
        comments = JSON.parse(JSON.stringify(state.comments));
        comments = comments.filter((i) => i.id !== action.comment.id);
        return state.merge({
          processing_delete: false,
          comments: comments,
        });
      }
    case actionTypes.DELETE_COMMENTS_FAIL:
      return state.merge({
        processing_delete: false,
      });
    case actionTypes.DELETE_COMMENTS_ERROR:
      return state.merge({
        processing_delete: false,
      });

    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
