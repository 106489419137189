import { LoadingButton } from '@mui/lab';
import { Box, Tab, TextField, alpha, styled } from '@mui/material';

export const ProjectSettingsWrapper = styled(Box)(() => ({
  width: '100%',
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
}));

export const ProjectSettingsContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1840,
  margin: '0 auto',
  padding: '0 16px',
}));

export const ProjectSettingsContent = styled(Box)(() => ({
  flex: 1,
  border: '1px solid #f2f2f2',
  boxShadow: '0px 6px 58px 0px rgba(196, 203, 214, 0.1)',
  overflow: 'hidden',
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Poppins SemiBold, system-ui, sans-serif',
  color: theme.palette.text.primary,
  padding: '16px 0',
  fontSize: 13,
  textTransform: 'none',
  lineHeight: '19.5px',
  minHeight: 0,
  opacity: 0.5,
  transition: 'all 0.15s ease',
  '& .MuiTab-wrapper': {
    lineHeight: 1,
  },
  '&:first-of-type': {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    '&:not(.Mui-selected)': {
      borderRight: 'none',
    },
  },
  '&:last-of-type': {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    '&:not(.Mui-selected)': {
      borderLeft: 'none',
    },
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    opacity: 1,
  },

  '&:hover': {
    opacity: 0.9,
  },
}));

export const SearchDepartmentInput = styled(TextField)(({ theme }) => ({
  minWidth: 333,
  height: '50px',
  '& .MuiInputBase-root': {
    height: 50,
    borderRadius: '10px',
    backgroundColor: 'white',
  },
  '& .MuiInputBase-input': { padding: '0' },
  '& .MuiInputAdornment-positionStart': { ml: 1, color: 'text.secondary' },
  fieldset: {
    borderRadius: '10px',
  },
  [theme.breakpoints.down('xs')]: {
    height: '100%',
    '& .MuiInputBase-root': {
      [theme.breakpoints.down('md')]: {
        height: '100%',
      },
    },
  },
}));

export const StyledActionButton = styled(LoadingButton, {
  shouldForwardProp: (props) => props !== 'cancelButton',
})<{ cancelButton?: boolean }>(({ cancelButton, theme }) => {
  const color = cancelButton ? theme.palette.error.main : 'white';
  const backgroundColor = cancelButton ? alpha(theme.palette.error.main, 0.1) : theme.palette.primary.main;
  return {
    color,
    height: 'max-content',
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
    textTransform: 'capitalize',
    backgroundColor,
    '&:hover': {
      backgroundColor,
      filter: 'brightness(95%)',
    },
  };
});
