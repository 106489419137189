/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { SketchPicker } from 'react-color';

import { FormatColorFillOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { CreateEditSubcontractor } from 'types/Subcontractors';

import { DatasetTestIDs, TestIDs } from 'Constants/tests';
import NoBackgroundImg from 'Images/no-background.png';
import colorConverter from 'Utils/colorConverter';
import AppInputField from 'components/AppInputField/AppInputField';
import AppPaperModal from 'components/AppPaperModal';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';

import WorkersMaterialAsyncSearch from '../Components/Controls/WorkersMaterialAsyncSearch';
import { CreateSubcontractorValidation } from './subcontractorValidation';

interface Props {
  onClose: () => void;
  subcontractor: CreateEditSubcontractor;
  open: boolean;
  isEdit: boolean | number;
  submit: (values: CreateEditSubcontractor) => void;
  resetData: () => void;
}

const newSubcontractor: CreateEditSubcontractor = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  workerIds: [],
  companyColor: null,
};

const AddSubcontractorDialog: React.FC<Props> = ({ isEdit, subcontractor, open, onClose, submit, resetData }) => {
  const [pickerOpened, setPickerOpened] = useState(false);

  const { setFieldValue, submitForm, handleChange, handleBlur, values, touched, errors } =
    useFormik<CreateEditSubcontractor>({
      initialValues: subcontractor || newSubcontractor,
      validationSchema: CreateSubcontractorValidation,
      onSubmit: (values) => {
        submit(values);
      },
    });

  const onWorkerSelect = (workers) =>
    setFieldValue(
      'workerIds',
      workers.map(({ id }) => id)
    );

  const openColorPicker = () => {
    setPickerOpened(true);
  };

  const closeColorPicker = () => {
    setPickerOpened(false);
  };

  useEffect(() => {
    return () => {
      resetData();
    };
  }, []);

  return (
    <AppPaperModal
      title={isEdit ? 'Edit Subcontractor' : 'Add New Subcontractor'}
      modalId="subcontractor-form-modal"
      open={open}
      onClose={onClose}
      containerStyle={{
        width: '100%',
        maxWidth: 460,
      }}
      submitButton={{
        title: isEdit ? 'Update' : 'Add',
        onClick: submitForm,
        dataCy: TestIDs.components.subcontractorForm.buttons.saveSubcontractor,
      }}
    >
      <Box display="flex" flexDirection="column" gap="14px">
        <Box
          display="flex"
          gap="14px"
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
          }}
        >
          <AppInputField
            value={values.firstName}
            name={'firstName'}
            label={'First Name'}
            placeholder={'Enter First Name'}
            onChange={handleChange}
            size="small"
            fullWidth
            error={Boolean(errors.firstName && touched.firstName)}
            helperText={errors.firstName && touched.firstName && errors.firstName}
            {...DatasetTestIDs.components.subcontractorForm.fields.firstName}
          />
          <AppInputField
            value={values.lastName}
            name={'lastName'}
            label={'Last Name'}
            placeholder={'Enter Last Name'}
            onChange={handleChange}
            size="small"
            fullWidth
            error={Boolean(errors.lastName && touched.lastName)}
            helperText={errors.lastName && touched.lastName && errors.lastName}
            {...DatasetTestIDs.components.subcontractorForm.fields.lastName}
          />
        </Box>

        <AppInputField
          value={values.email}
          name={'email'}
          label={'Email'}
          placeholder={'Enter Email'}
          onChange={handleChange}
          size="small"
          fullWidth
          error={Boolean(errors.email && touched.email)}
          helperText={errors.email && touched.email && errors.email}
          {...DatasetTestIDs.components.subcontractorForm.fields.email}
        />

        <PhoneNumberInput
          size="small"
          label="Phone Number"
          value={values.phoneNumber}
          onChange={(value) => setFieldValue('phoneNumber', value)}
          error={errors.phoneNumber}
          helperText={errors.phoneNumber}
          inputProps={{
            ...DatasetTestIDs.components.subcontractorForm.fields.phoneNumber,
          }}
        />

        <Box position="relative">
          <AppInputField
            size="small"
            InputProps={{
              startAdornment: Boolean(isEdit) && (
                <Tooltip
                  disableInteractive
                  title={values.companyColor ? 'Edit Company color' : 'Add color for Company'}
                >
                  <IconButton
                    onClick={openColorPicker}
                    sx={{
                      left: 0,
                      height: 38,
                      borderRadius: 2,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      background: values.companyColor || `no-repeat center/500% url(${NoBackgroundImg})`,
                      '&:hover': {
                        bgcolor: values.companyColor,
                      },
                    }}
                    {...DatasetTestIDs.components.subcontractorForm.fields.companyColor}
                  >
                    <FormatColorFillOutlined
                      fontSize="small"
                      htmlColor={colorConverter.needWhiteIcon(values.companyColor) ? 'white' : 'black'}
                    />
                  </IconButton>
                </Tooltip>
              ),
            }}
            value={values.companyName}
            onChange={handleChange}
            name="companyName"
            label="Company Name"
            placeholder="Enter Company Name"
            fullWidth
            error={Boolean(errors.companyName && touched.companyName)}
            helperText={errors.companyName && touched.companyName && errors.companyName}
            inputProps={{
              ...DatasetTestIDs.components.subcontractorForm.fields.companyName,
            }}
          />
          {pickerOpened && (
            <Box position="absolute" left={20} bottom={20} zIndex={2}>
              <Box position="fixed" top={0} left={0} right={0} bottom={0} onClick={closeColorPicker} />
              <SketchPicker
                disableAlpha
                width="270px"
                color={values.companyColor || '#000'}
                onChange={({ hex }) => setFieldValue('companyColor', hex)}
              />
            </Box>
          )}
        </Box>

        {Boolean(isEdit) && (
          <Box position="relative">
            <WorkersMaterialAsyncSearch
              {...DatasetTestIDs.components.subcontractorForm.fields.workers}
              selectedWorkerIds={values.workerIds}
              limitTags={10}
              width="100%"
              inputSize="small"
              onSelect={(worker) => onWorkerSelect(worker)}
              placeholder="Select workers"
              optionDataCy={DatasetTestIDs.components.subcontractorForm.fields.workersSelectOption}
              InputComponent={(params) => <AppInputField label="Workers" size="small" {...params} />}
            />
          </Box>
        )}
      </Box>
    </AppPaperModal>
  );
};

export default AddSubcontractorDialog;
