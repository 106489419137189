import React, { useCallback } from 'react';

const useProcessing = (initState = false) => {
  const [inProcess, setInProcess] = React.useState<boolean>(initState);

  const start = useCallback(() => setInProcess(true), []);
  const stop = useCallback(() => setInProcess(false), []);

  const promiseWrapper = useCallback(async <T>(promise: Promise<T>) => {
    try {
      start();
      return await promise;
    } catch (error) {
      throw error;
    } finally {
      stop();
    }
  }, []);

  return { inProcess, start, stop, promiseWrapper };
};

export default useProcessing;
