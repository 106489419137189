import React, { memo, useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import moment from 'moment';
import { FcCancel } from 'react-icons/fc';

import { Tooltip } from '@mui/material';

import { ImageWithAuthor } from 'types/Location';

import ImageButton from 'components/ImageButton';

type Props = Omit<ImageWithAuthor, 'lat' | 'lon'> & {
  processingURL?: string;
  selected?: boolean;
  onClick: (url: string) => void;
  className?: string;
  style?: React.CSSProperties;
  haveCloseIcon?: boolean;
  onClosePress?: () => void;
};

const RejectedOverlay = styled('span')({
  position: 'absolute',
  bottom: '50%',
  right: '50%',
  transform: 'translate(50%, 50%)',
  borderRadius: '50%',
  backgroundColor: '#fffc',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
  color: 'red',
  pointerEvents: 'none',
});

const JobImageButton = ({
  url,
  created_at,
  is_rejected,
  user,
  processingURL,
  selected,
  title = '',
  className,
  style,
  haveCloseIcon,
  onClosePress = () => {},
  onClick = () => {},
}: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const isFile = url.includes('blob:');

  useEffect(() => {
    if (selected && buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selected]);

  const photoUploadInfo =
    created_at && !isFile ? moment(created_at).format(`ddd, MMM, D [at] h:mm a [by ${user?.name}]`) : '';

  const rejectedText = is_rejected ? <span style={{ color: 'red', paddingLeft: 3 }}>(Rejected)</span> : '';

  const notUploadedText = isFile ? 'Not uploaded yet' : '';

  return (
    <Tooltip
      disableInteractive
      title={
        title || photoUploadInfo || rejectedText || notUploadedText ? (
          <span>
            {title}
            {photoUploadInfo}
            {notUploadedText}
            {rejectedText}
          </span>
        ) : (
          ''
        )
      }
    >
      <ImageButton
        url={url}
        loading={processingURL === url}
        className={className}
        style={style}
        ref={buttonRef}
        openImage={onClick}
        selected={selected}
        haveCloseIcon={haveCloseIcon}
        onClosePress={onClosePress}
      >
        {is_rejected ? (
          <RejectedOverlay>
            <FcCancel />
          </RejectedOverlay>
        ) : null}
      </ImageButton>
    </Tooltip>
  );
};

export default memo(JobImageButton);
