import SeamlessImmutable from 'seamless-immutable';

import { OrderOtpions } from 'types/Common/App';
import { GrouppedTimesheetItem, GrouppedTimesheetOptions, TimesheetTableItem } from 'types/Timesheet';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = SeamlessImmutable({
  request_key: '',
  processing: false,
  list: [] as Array<GrouppedTimesheetItem>,
  grouppedTimesheetsOptions: {
    order_by: 'start_at',
    order_by_type: true,
    per_page: 10,
    total: 1,
    page: 1,
    department: null,
    requestor: null,
    supervisor: null,
    po: '',
    jobStatus: null,
    jobType: null,
    show_cancelled_timesheets: false,
    ce_verified: undefined,
    ces_verified: undefined,
    has_pending_timesheets: null,
  } as GrouppedTimesheetOptions,
  conf_number: null as number,
  timesheets: [] as Array<TimesheetTableItem>,
  timesheetsOptions: {
    order_by: 'startDate',
    order_by_type: true,
    show_cancelled_timesheets: false,
    status: null,
  } as OrderOtpions & { show_cancelled_timesheets: boolean; status: string },
  timesheets_processing: false,
  mainTableScrollPosition: 0,
  nestedTableScrollPosition: 0,
});

const grouppedTimesheets = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_GROUPPED_FILTERS:
      if (Object.keys(payload).length) {
        return state.merge({
          grouppedTimesheetsOptions: {
            page: 1,
            ...state.grouppedTimesheetsOptions,
            ...payload,
          },
          conf_number: null,
          timesheetsOptions: { ...initialState.timesheetsOptions },
        });
      }
      return state;
    case actionTypes.GET_GROUPPED_TIMESHEETS_REQUEST:
      return state.merge({ processing: true, request_key: payload });
    case actionTypes.GET_GROUPPED_TIMESHEETS_SUCCESS:
      if (state.request_key === payload.request_key) {
        return state.merge({
          processing: false,
          list: payload.results,
          grouppedTimesheetsOptions: {
            ...state.grouppedTimesheetsOptions,
            page: payload.current_page,
            total: payload.total,
          },
        });
      }
      return state;
    case actionTypes.GET_GROUPPED_TIMESHEETS_FAIL:
    case actionTypes.GET_GROUPPED_TIMESHEETS_ERROR:
      return state.merge({ processing: false });

    case actionTypes.CHANGE_CONF_NUMBER:
      return state.merge({
        conf_number: payload,
        timesheets: [],
        timesheetsOptions: { ...initialState.timesheetsOptions },
      });
    case actionTypes.UPDATE_CONF_TIMESHEETS_FILTERS:
      return state.merge({
        conf_number: payload.confirmation,
        timesheetsOptions: {
          ...state.timesheetsOptions,
          ...payload.options,
        },
      });
    case actionTypes.GET_CONF_TIMESHEETS_REQUEST:
      return state.merge({ timesheets_processing: true, conf_number: payload });
    case actionTypes.GET_CONF_TIMESHEETS_SUCCESS:
      if (state.conf_number === payload.confirmation) {
        return state.merge({
          timesheets_processing: false,
          timesheets: payload.results,
        });
      }
      return state.merge({ timesheets_processing: false });
    case actionTypes.GET_CONF_TIMESHEETS_FAIL:
    case actionTypes.GET_CONF_TIMESHEETS_ERROR:
      return state.merge({ timesheets_processing: false });
    case LOGOUT:
      return state.merge(initialState);
    default:
      return state;
  }
};

export default grouppedTimesheets;
