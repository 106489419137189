import React, { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { toast } from 'react-toastify';

import CopyAllIcon from '@mui/icons-material/CopyAll';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import Invoice from 'types/Invoices';

import { baseToastConfig } from 'Constants/app';
import { PO_Double_Validation } from 'Constants/validation';
import { actions } from 'Services';
import { InvoicesAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { ReduxState } from 'createStore';

const timesheetIdsRegExp = /(^(\d+(\,|\s+|\,\s+))+\d+$)|(^\d+$)/;

type Props = ReduxProps & {
  onClose: () => void;
  timesheet?: number;
  open: boolean;
  config_id: number;
  singleTimesheetMode?: boolean;
};

type State = {
  timesheets: string | number;
  po: string;
  processing: boolean;
  helperText: string;
  error: boolean;
  errorPo: string;
  showPo: boolean;
  getHoursProcessing: boolean;
  totalHoursStats: Invoice.TotalHoursStats;
  totalHoursPerInvoice: Invoice.TotalHoursPerInvoice[];
  paid_timesheets: Invoice.PaidTimesheet[];
};

const parseCopyToClipboardValue = ({ id, po_number }: { id: number; po_number: string }): string => {
  return `Timesheet: ${id}, PO: ${po_number || '---'}`;
};

class MarkTimesheetsAsPaid extends Component<Props, State> {
  state: State = {
    timesheets: this.props.timesheet && this.props.timesheet !== -1 ? this.props.timesheet : '',
    po: '',
    processing: false,
    helperText: '',
    error: false,
    errorPo: 'PO # is required field',
    showPo: Boolean(this.props.timesheet),
    totalHoursStats: {
      emergency_hours: 0,
      holiday_hours: 0,
      overtime_hours: 0,
      regular_hours: 0,
      total_billable_hours: 0,
      total_hours: 0,
    },
    totalHoursPerInvoice: [],
    getHoursProcessing: false,
    paid_timesheets: [],
  };

  componentDidMount() {
    this.setState(
      {
        timesheets: this.props.timesheet ? this.props.timesheet : '',
        po: '',
        processing: false,
        helperText: '',
        error: false,
        showPo: this.props.timesheet ? true : false,
        errorPo: this.validatePo(''),
      },
      () => {
        if (this.state.timesheets !== '' && this.state.timesheets !== -1) this.getTotal();
      }
    );
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.timesheet !== -1 && this.props.timesheet !== prevProps.timesheet) {
      this.setState(
        {
          timesheets: this.props.timesheet ? this.props.timesheet : '',
          po: '',
          processing: false,
          helperText: '',
          error: false,
          showPo: this.props.timesheet ? true : false,
          errorPo: this.validatePo(''),
        },
        () => {
          if (this.state.timesheets !== '' && this.state.timesheets !== -1) this.getTotal();
        }
      );
    }
  };

  handleChangeIds = (event) => {
    const timesheets = event.target.value;
    this.setState({ timesheets, showPo: false, po: '', errorPo: this.validatePo('') });
    if (timesheetIdsRegExp.test(timesheets.trim())) {
      this.setState({ helperText: '', error: false });
    } else {
      this.setState({
        helperText: 'You should enter list of timesheet ids separated by comma and/or space only',
        error: true,
      });
    }
    if (!event.target.value) {
      this.setState({ errorPo: this.validatePo('') });
    }
  };

  enterPressed = (event: React.KeyboardEvent) => {
    const { error, timesheets } = this.state;
    if (event.key === 'Enter' && !error && timesheets.toString().trim() !== '') {
      this.getTotal();
    }
  };

  handleChangePo = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    let { errorPo } = this.state;
    errorPo = this.validatePo(value);
    this.setState({ po: value, errorPo });
  };

  validatePo = (poNumber: string = ''): string => {
    try {
      PO_Double_Validation.min(1, 'PO # is required field').required('PO # is required field').validateSync(poNumber);
      return '';
    } catch (error) {
      return error.message;
    }
  };

  confirm = async () => {
    await this.props
      .markTimesheets(this.props.config_id, {
        timesheetIds: this.state.timesheets.toString().trim(),
        po: this.state.po,
      })
      .then(({ message, status_code }) => {
        if (status_code === 210) {
          toast.warn(message, { ...baseToastConfig, autoClose: false });
        } else {
          showSuccessMessage(message || 'Timesheets marked as paid');
        }
        this.setState({
          timesheets: '',
          po: '',
          processing: false,
          helperText: '',
          error: false,
          showPo: false,
        });
        this.props.onClose();
      })
      .catch(showErrorMessage);
  };

  getTotal = async () => {
    this.setState({ getHoursProcessing: true });
    try {
      const response = await InvoicesAPI.getTimesheetsTotalHours({ timesheetIds: this.state.timesheets });
      this.setState({
        totalHoursStats: response.result.totals,
        totalHoursPerInvoice: response.result.timesheets,
        paid_timesheets: response.result.paid_timesheets || [],
        showPo: true,
        po: this.state.po || response.result.po_number,
        errorPo: this.validatePo(this.state.po || response.result.po_number),
      });
    } catch (error) {
      showErrorMessage(error);
      this.setState({ showPo: false });
    } finally {
      this.setState({ getHoursProcessing: false });
    }
  };

  render() {
    const { singleTimesheetMode, mark_timesheets_processing } = this.props;
    const { totalHoursStats, totalHoursPerInvoice, getHoursProcessing } = this.state;
    const submitEnabled = this.state.timesheets && !this.state.error && this.state.showPo && !this.state.errorPo;
    return (
      <AppPaperModal
        modalId="pay-timesheets-modal"
        open={this.props.open}
        onClose={this.props.onClose}
        title="Pay Timesheets"
        submitButton={{
          title: 'Pay',
          onClick: this.confirm,
          loading: mark_timesheets_processing,
          disabled: !submitEnabled,
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={'20px'}>
          <TextField
            fullWidth
            name="ids"
            label="Timesheets Id"
            id="outlined-size-normal"
            variant="outlined"
            value={this.state.timesheets}
            onKeyDown={this.enterPressed}
            onChange={this.handleChangeIds}
            multiline
            maxRows={3}
            helperText={
              this.state.helperText ||
              'Enter timesheet ids separated by comma and/or space and press Enter or button below'
            }
            error={this.state.error}
            required
            disabled={singleTimesheetMode}
          />

          <LoadingButton
            color="secondary"
            variant="contained"
            onClick={this.getTotal}
            loading={getHoursProcessing}
            disabled={!this.state.timesheets || this.state.error}
          >
            See Total Hours
          </LoadingButton>

          {this.state.showPo && (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right">Regular</TableCell>
                      <TableCell align="right">Overtime</TableCell>
                      <TableCell align="right">Total Billable</TableCell>
                      <TableCell align="right">Holiday</TableCell>
                      <TableCell align="right">Emergency</TableCell>
                      <TableCell align="right">Invoice ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {totalHoursPerInvoice.length > 1 &&
                      totalHoursPerInvoice.map((row) => (
                        <TableRow key={row.invoice_id}>
                          <TableCell align="right">Hours:</TableCell>
                          <TableCell align="right">{row.total_hours}</TableCell>
                          <TableCell align="right">{row.regular_hours}</TableCell>
                          <TableCell align="right">{row.overtime_hours}</TableCell>
                          <TableCell align="right">{row.total_billable_hours}</TableCell>
                          <TableCell align="right">{row.holiday_hours}</TableCell>
                          <TableCell align="right">{row.emergency_hours}</TableCell>
                          <TableCell align="right">{row.invoice_id}</TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell align="right">Total Hours:</TableCell>
                      <TableCell align="right">{totalHoursStats.total_hours}</TableCell>
                      <TableCell align="right">{totalHoursStats.regular_hours}</TableCell>
                      <TableCell align="right">{totalHoursStats.overtime_hours}</TableCell>
                      <TableCell align="right">{totalHoursStats.total_billable_hours}</TableCell>
                      <TableCell align="right">{totalHoursStats.holiday_hours}</TableCell>
                      <TableCell align="right">{totalHoursStats.emergency_hours}</TableCell>
                      <TableCell align="right">
                        {totalHoursPerInvoice.length === 1 ? totalHoursPerInvoice[0]?.invoice_id : ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {Boolean(this.state.paid_timesheets.length) && (
                <>
                  <Divider> Already Paid Timesheets</Divider>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Timesheet ID</TableCell>
                          <TableCell>PO #</TableCell>
                          <TableCell width={50}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.paid_timesheets.map(({ id, po_number }) => (
                          <TableRow key={id}>
                            <TableCell>{id}</TableCell>
                            <TableCell>{po_number}</TableCell>
                            <TableCell align="right">
                              <CopyToClipboardButton
                                snackbarMessage={'Timesheet copied to clipboard'}
                                buttonProps={{
                                  color: 'primary',
                                }}
                                value={parseCopyToClipboardValue({ id, po_number })}
                                tooltipText="Copy timesheet to clipboard."
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        {this.state.paid_timesheets.length > 1 && (
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                              <CopyToClipboardButton
                                buttonProps={{
                                  color: 'primary',
                                  startIcon: <CopyAllIcon fontSize="small" />,
                                }}
                                snackbarMessage="All timesheets copied to clipboard"
                                tooltipText="Copy all timesheets to clipboard."
                                value={this.state.paid_timesheets.map(parseCopyToClipboardValue).join('; \n') + ';'}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              <TextField
                fullWidth
                name="po"
                label="PO"
                id="outlined-size-normal"
                variant="outlined"
                value={this.state.po}
                onChange={this.handleChangePo}
                helperText={this.state.errorPo}
                error={!!this.state.errorPo}
              />
            </>
          )}
        </Box>
      </AppPaperModal>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    mark_timesheets_processing: state.invoices.mark_timesheets_processing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    markTimesheets: (config_id: string | number, data) =>
      dispatch(actions.InvoicesActions.markTimesheets(config_id, data)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(MarkTimesheetsAsPaid);
