import { JobType } from 'Constants/job';

export const WORKER_TYPE = [
  {
    label: 'Flagging',
    value: JobType.Flagging,
  },
  {
    label: 'Parking',
    value: JobType.Parking,
  },
  {
    label: 'Signage',
    value: JobType.Signage,
  },
];
