import * as Yup from 'yup';

export const CreateIssueValidation = Yup.object({
  jobId: Yup.number().notRequired(),
  shiftId: Yup.number().when('jobId', ([jobId]: [number], schema) => {
    return jobId ? schema.min(1, 'Shift id not selected').required('Shift id not selected') : schema.notRequired();
  }),
  message: Yup.string()
    .trim()
    .min(16, 'The message must be at least 16 characters long.')
    .max(256, 'The message may not be greater than 256 characters.')
    .required('Message is required.'),
});
