import React, { useMemo } from 'react';

import Select, { CSSObjectWithLabel } from 'react-select';

import { Department, DepartmentGroup } from 'types/Common/Companies';

import { useAppSelector } from 'createStore';

type Option = {
  id: string | number;
  name: string;
};

interface Props {
  onSelect?: (item: Department) => void;
  onClear?: () => void;
  placeholder?: string;
  isClearable?: boolean;
  defaultValue?: Department;
  defaultInputValue?: string;
  disabled?: boolean;
  onlyOwnDept?: boolean;
  value?: Department;
  departmentId?: number | string;
}

const DepartmentAsyncSearch = ({
  onSelect = () => {},
  onClear = () => {},
  placeholder,
  isClearable = true,
  defaultValue,
  defaultInputValue,
  disabled,
  onlyOwnDept = false,
  value,
  departmentId,
}: Props) => {
  const user = useAppSelector((state) => state.app.user);
  const departments = useAppSelector((state) => state.app.departments);
  const departmentGroups = useAppSelector((state) => state.app.departmentGroups);

  const filterOwnDepartments = (departments: Department[] = []): Option[] => {
    if (onlyOwnDept) {
      return departments.filter((item) => user.departments.findIndex((item2) => item.name === item2.name) >= 0);
    }
    return departments;
  };

  const convertDepartmentGroups = (deptGroups: DepartmentGroup[] = []): Option[] => {
    return deptGroups.map((group) => ({
      id: filterOwnDepartments(group.departments)
        .map((dept) => dept.id)
        .join(','),
      name: group.name,
    }));
  };

  const departmentOptions = useMemo(() => filterOwnDepartments(departments.asMutable()), [departments, user]);

  const departmentGroupOptions = useMemo(
    () => convertDepartmentGroups(departmentGroups.asMutable({ deep: true })),
    [departmentGroups]
  );

  const options = [
    {
      label: 'Department Groups',
      options: departmentGroupOptions,
    },
    {
      label: 'Departments',
      options: departmentOptions,
    },
  ];

  const selectValue = useMemo(() => {
    if (value?.id && value?.name) {
      return value;
    }
    if (departmentId) {
      return [...departmentGroupOptions, ...departmentOptions].find(
        ({ id }) => id?.toString() === departmentId.toString()
      );
    }
    return null;
  }, [departmentOptions, departmentGroupOptions, value, departmentId]);

  const currentSelected: string[] = selectValue?.id?.toString().split(',');

  return (
    <Select<Option, false>
      isClearable={isClearable}
      placeholder={placeholder || 'Select department'}
      isOptionDisabled={(option) => currentSelected?.includes(option?.id?.toString())}
      isOptionSelected={(option) => option.id === selectValue?.id}
      options={options}
      isDisabled={disabled}
      defaultValue={defaultValue}
      onChange={onSelect}
      defaultInputValue={defaultInputValue}
      getOptionLabel={(option) => option?.name}
      value={selectValue}
      styles={{ menu: (base) => ({ ...base, zIndex: 10 } as CSSObjectWithLabel) }}
    />
  );
};

export default DepartmentAsyncSearch;
