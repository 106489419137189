import { useState, useEffect, useRef, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { useFormik } from 'formik';
import moment, { Moment } from 'moment';

import { CommentRounded } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { Tooltip, TextField, IconButton, Fab, Badge, Paper, Typography, Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { ReactComponent as EditInvoiceIcon } from 'Assets/icons/edit-invoice.svg';
import AppPermissions from 'Constants/permissions';
import { EditDateTime } from 'Containers/Components';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import { showSuccessMessage } from 'Utils/successMessage';
import app_history from 'app_history';
import AppPaperModal from 'components/AppPaperModal/AppPaperModal';
import Button from 'components/Button/Button';
import Notes from 'components/Notes/Notes';
import { ReduxState } from 'createStore';

import '../Invoices.scss';
import { statusLabel } from '../components/StatusLabel';
import MarkTimesheetsAsPaid from '../dialog/MarkTimesheetsAsPaid';
import TimesheetsTableRow from './TimesheetsTableRow';

const goBack = () => {
  app_history.goBack();
};

interface Props extends ReduxProps, RouteComponentProps<{ id: string; invoiceId: string }> {}

const TimesheetsTable = (props: Props) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { values, handleChange } = useFormik({
    initialValues: {
      timesheet_id: '',
      job_id: '',
      page: 1,
    },
    onSubmit: () => {},
  });
  const [showNotes, setShowNotes] = useState(true);
  const markAsPaidModal = useModal();
  const [removeTimesheetId, setRemoveTimesheetId] = useState(0);

  const fabRef = useRef<HTMLButtonElement>(null);

  const config_id = parseInt(props.match.params.id);
  const invoice_id = parseInt(props.match.params.invoiceId);

  const revertInvoice = () => {
    props.revertInvoice(props.match.params.id, props.match.params.invoiceId);
  };

  const goToInvoices = () => {
    app_history.push(`/invoices/${props.match.params.id}`);
  };

  const goToConfigurations = () => {
    app_history.push('/invoices');
  };

  const downloadInvoice = () => {
    props.downloadInvoice(props.match.params.id, props.match.params.invoiceId);
  };

  const downloadPOs = async () => {
    try {
      await props.downloadPOs(props.match.params.id, props.match.params.invoiceId);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const markAsBilled = () => {
    props.updateInvoiceStatus(props.match.params.id, props.match.params.invoiceId, 'billed');
  };

  const assignMoreTimesheets = () => {
    props.assignMoreTimesheets(props.match.params.id, props.match.params.invoiceId);
  };

  const openEditInvoice = (show) => {
    setOpenEdit(show);
  };

  const updateInvoiceTime = async (start: Moment, finish: Moment) => {
    try {
      await props.updateInvoiceTime(config_id, invoice_id, {
        from_datetime: start.format('YYYY-MM-DD'),
        to_datetime: finish.format('YYYY-MM-DD'),
      });
      showSuccessMessage('Invoice dates updated');
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const removeUnpaidTimesheet = async () => {
    try {
      await props.removeUnpaidTimesheet(config_id, invoice_id, removeTimesheetId);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setRemoveTimesheetId(0);
    }
  };

  const canRevertInvoice =
    props.invoice?.status && props.invoice?.status !== 'billed' && props.invoice?.status !== 'paid';

  const editDateTimeDefaultValue = useMemo(() => {
    const dateThreeMonthsAgo = moment().subtract(3, 'months');
    const start = dateThreeMonthsAgo.isBefore(props.invoice.from_datetime)
      ? props.invoice.from_datetime
      : dateThreeMonthsAgo;
    const finish = props.invoice.to_datetime;

    return {
      start,
      finish,
    };
  }, [props.invoice.from_datetime, props.invoice.to_datetime]);

  useEffect(() => {
    props.getTimesheets(props.match.params.id, props.match.params.invoiceId, values);
  }, [props.match.params.id, props.match.params.invoiceId, values]);

  return (
    <div className="invoices-list-page">
      <div className="page-header d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center" style={{ marginRight: 20 }}>
          <div className="blue-bg mr-2" onClick={goBack}>
            <ArrowBackIcon style={{ color: 'white' }} />
          </div>
          <div className="page-title-gray mr-1">
            <span onClick={goToConfigurations} style={{ cursor: 'pointer' }}>
              Configurations
            </span>{' '}
            /{' '}
            <span onClick={goToInvoices} style={{ cursor: 'pointer' }}>
              {config_id}
            </span>{' '}
            /{' '}
            <span onClick={goToInvoices} style={{ cursor: 'pointer' }}>
              Invoices
            </span>{' '}
            /{' '}
          </div>
          <div className="page-title">{invoice_id} / Timesheets</div>
          {props.invoice && props.invoice.id > 0 && (
            <div style={{ marginLeft: 30 }}>{statusLabel(props.invoice.status)}</div>
          )}
          {props.invoice && props.invoice.id > 0 && (
            <span style={{ marginLeft: 10, fontSize: 16 }}>
              {' '}
              {moment(props.invoice.from_datetime).format('MM/DD/YYYY')} -{' '}
              {moment(props.invoice.to_datetime).format('MM/DD/YYYY')}
            </span>
          )}
          {props.invoice && props.invoice.id > 0 && props.canEditInvoice && (
            <Tooltip
              disableInteractive
              title={
                props.invoice.status !== 'draft'
                  ? 'You can edit only draft invoice'
                  : 'Update dates for the invoice and reassign timesheets accordingly'
              }
              arrow
            >
              <div className="table-action_icon-btn">
                <IconButton
                  size="small"
                  onClick={(_) => openEditInvoice(true)}
                  disabled={props.invoice.status !== 'draft'}
                >
                  <EditSharpIcon style={{ color: 'black' }} fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 20,
          }}
        >
          {props.invoice && props.invoice.status === 'draft' && props.canEditInvoice && (
            <Tooltip
              disableInteractive
              title="Check and assign new timesheets under invoice if there are any"
              arrow
              aria-label="assign"
            >
              <div>
                <Button
                  color={'dark'}
                  width={'200px'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={assignMoreTimesheets}
                  /*processing={props.revert_invoice_processing}*/
                  disabled={props.processing}
                  style={{
                    backgroundColor: '#009ad8',
                    color: '#FFF',
                  }}
                >
                  Assign Timesheets
                </Button>
              </div>
            </Tooltip>
          )}
          {props.canEditInvoice && canRevertInvoice && (
            <Tooltip disableInteractive title="Unassign timesheets and delete invoice" arrow aria-label="revert">
              <div>
                <Button
                  color={'dark'}
                  width={'100px'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={revertInvoice}
                  processing={props.revert_invoice_processing}
                  disabled={props.processing}
                  style={{
                    backgroundColor: 'red',
                    color: '#FFF',
                  }}
                >
                  Revert
                </Button>
              </div>
            </Tooltip>
          )}
          <Tooltip disableInteractive title="Download invoice to your computer" arrow aria-label="download">
            <div>
              <Button
                color={'dark'}
                width={'100px'}
                borderRadius={'20px'}
                textTransform={false}
                onClick={downloadInvoice}
                processing={props.download_invoice_processing}
                disabled={props.processing}
                style={{
                  backgroundColor: 'grey',
                  color: '#FFF',
                }}
              >
                Download
              </Button>
            </div>
          </Tooltip>
          {props.invoice && props.invoice.status === 'draft' && props.canEditInvoice && (
            <Tooltip
              disableInteractive
              title={
                props.timesheetsCount === 0
                  ? 'Cannot mark as billed because no timesheets are attached'
                  : 'Mark invoice as billed'
              }
              arrow
              aria-label="mark"
            >
              <div>
                <Button
                  color={'dark'}
                  width={'200px'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={markAsBilled}
                  processing={props.download_invoice_processing}
                  disabled={props.processing || props.timesheetsCount === 0}
                  style={{
                    backgroundColor: '#0099d8',
                    color: '#FFF',
                  }}
                >
                  Mark Invoice as Billed
                </Button>
              </div>
            </Tooltip>
          )}

          <Button
            processing={props.download_pos_processing}
            disabled={props.processing}
            onClick={downloadPOs}
            style={{
              backgroundColor: 'rgb(2, 179, 55)',
              borderRadius: '20px',
            }}
          >
            Download PO's
          </Button>

          {props.canEditInvoice && (
            <Tooltip disableInteractive title="Mark Timesheet as Paid and/or Change Job's PO#" arrow aria-label="paid">
              <div>
                <Button
                  color={'dark'}
                  width={'220px'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={markAsPaidModal.open}
                  style={{
                    backgroundColor: '#009ad8',
                    color: '#FFF',
                  }}
                >
                  Mark Timesheets As Paid
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {props.loading ? <LinearProgress /> : <div style={{ height: 4 }} />}
      <div className="searchId">
        <Box display="flex" gap={1}>
          {[
            { key: 'timesheet_id', label: 'Timesheet ID' },
            { key: 'job_id', label: 'Confirmation ID' },
          ].map(({ key, label }) => (
            <TextField
              key={key}
              name={key}
              label={label}
              variant="outlined"
              InputProps={{ style: { backgroundColor: '#fff' } }}
              value={values[key]}
              size="small"
              onChange={handleChange}
            />
          ))}
        </Box>
        <div>
          <Badge badgeContent={props.notes.length} color="primary" overlap="circular">
            <Tooltip disableInteractive title="Show/hide comments">
              <Fab
                ref={fabRef}
                style={{ backgroundColor: '#FFF', zIndex: 1 }}
                onClick={() => setShowNotes((prev) => !prev)}
                size="small"
              >
                <CommentRounded />
              </Fab>
            </Tooltip>
          </Badge>
        </div>
      </div>
      <div className="Invoices-table Invoices-with-notes" style={{ flexDirection: 'row' }}>
        <TimesheetsTableRow invoice_id={invoice_id} config_id={config_id} onRemoveTimesheet={setRemoveTimesheetId} />
        <Paper className={showNotes ? 'invoice_timesheets-notes--show' : 'invoice_timesheets-notes--hide'}>
          <Notes entity_id={invoice_id} entity_type={'invoice'} placeholder={'Leave a note and press enter…'} />
        </Paper>
      </div>

      <EditDateTime
        open={openEdit}
        onClose={() => openEditInvoice(false)}
        defaultValue={editDateTimeDefaultValue}
        labels={{ start: 'Start Date', finish: 'Finish Date' }}
        subTitle="Change start and end times for the invoice"
        onSuccess={updateInvoiceTime}
      />
      <MarkTimesheetsAsPaid onClose={markAsPaidModal.close} open={markAsPaidModal.isOpen} config_id={config_id} />
      <AppPaperModal
        title="Remove Unpaid Timesheet"
        open={Boolean(removeTimesheetId)}
        onClose={() => setRemoveTimesheetId(0)}
        modalId="removeUnpaidTimesheet"
        submitButton={{
          onClick: removeUnpaidTimesheet,
          title: 'Remove',
          loading: props.remove_timesheet_processing,
          disabled: props.remove_timesheet_processing,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 350 }}>
          <EditInvoiceIcon height={300} style={{ marginBottom: 16 }} />
          <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 16 }}>
            Are you sure you want to remove this timesheet from the invoice?
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Timesheet will be removed from the invoice and marked as CES Unverified
          </Typography>
        </div>
      </AppPaperModal>
    </div>
  );
};

function mapStateToProps({ invoices, app, notes }: ReduxState) {
  return {
    invoice: invoices.invoice,
    timesheetsCount: invoices.configurations.length,
    searchOptions: invoices.search_options,
    loading: invoices.processing,
    processing: invoices.processing,
    revert_invoice_processing: invoices.revert_invoice_processing,
    download_invoice_processing: invoices.download_invoice_processing,
    download_pos_processing: invoices.download_pos_processing,
    notes: notes.notes,
    timesheet_pagination: invoices.timesheet_pagination,
    canEditInvoice: app.permissions.includes(AppPermissions.edit_invoices),
    remove_timesheet_processing: invoices.remove_timesheet_processing,
  };
}

type RemoveUnpaidParams = Parameters<(typeof actions)['InvoicesActions']['removeUnpaidTimesheet']>;

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getTimesheets: (
      conf_id: string | number,
      invoice_id: string | number,
      search_options?: Partial<ReduxState['invoices']['timesheet_pagination']>
    ) => dispatch(actions.InvoicesActions.getTimesheets(conf_id, invoice_id, search_options)),
    revertInvoice: (config_id, invoice_id) => dispatch(actions.InvoicesActions.revertInvoice(config_id, invoice_id)),
    downloadInvoice: (config_id, invoice_id) =>
      dispatch(actions.InvoicesActions.downloadInvoice(config_id, invoice_id)),
    downloadPOs: (config_id, invoice_id) => dispatch(actions.InvoicesActions.downloadPOs(config_id, invoice_id)),
    updateInvoiceStatus: (config_id, invoice_id, status) =>
      dispatch(actions.InvoicesActions.updateInvoiceStatus(config_id, invoice_id, status)),
    assignMoreTimesheets: (config_id, invoice_id) =>
      dispatch(actions.InvoicesActions.assignMoreTimesheets(config_id, invoice_id)),
    updateInvoiceTime: (config_id, invoice_id, data) =>
      dispatch(actions.InvoicesActions.updateInvoiceTime(config_id, invoice_id, data)),
    removeUnpaidTimesheet: (
      config_id: RemoveUnpaidParams[0],
      invoice_id: RemoveUnpaidParams[1],
      timesheet_id: RemoveUnpaidParams[2]
    ) => dispatch(actions.InvoicesActions.removeUnpaidTimesheet(config_id, invoice_id, timesheet_id)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsTable);
