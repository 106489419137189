/* eslint-disable no-useless-catch */

/* eslint-disable @typescript-eslint/no-namespace */
import { downloadFileToPC } from 'Utils/downloadFile';
import app_history from 'app_history';

import { AppThunkDispatch } from '../types';
import { LOGOUT } from './app/actionTypes';
import { API_BASE_ENDPOINT } from './endpoint';
import { IfVisibleListeners } from './ifvisibleListeners';

class AuthorizationService {
  static #token = '';
  static get token() {
    return this.#token;
  }

  static setToken(token = '') {
    this.#token = token;
  }
}

type RequestType = 'json' | 'encoded';

type EncodeUriData<RequestDataType extends RequestType = 'encoded'> = RequestDataType extends 'json'
  ? Record<string, any>
  : Record<string, string | number | number[] | string[] | boolean>;

type RequestOptions = RequestInit & { withoutAuthorization?: boolean; responseType?: 'json' | 'blob' | 'text' };

type FetchApiOptions = {
  endpoint: string;
  options?: RequestOptions & { withResponseObject?: boolean };
};

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type WithResponse = true | false;

interface ApiCall<DataType extends RequestType = 'encoded', WithReponse extends boolean = false> {
  <ResponseType = any>(
    endpoint: string,
    data?: EncodeUriData<DataType> | URLSearchParams,
    options?: RequestOptions
  ): Promise<WithReponse extends true ? { data: ResponseType; response: Response } : ResponseType>;
}

interface ApiFromDataCall {
  <ResponseType = any>(
    endpoint: string,
    data?: Record<string, string | Blob> | FormData,
    options?: RequestOptions
  ): Promise<ResponseType>;
}

type BodyType = 'json' | 'urlEncoded' | 'formData';

export namespace API {
  export type RequestBody = EncodeUriData | URLSearchParams;

  export type UriData = EncodeUriData<'encoded'>;

  export type Body<TypeOfData extends RequestType = 'encoded'> = EncodeUriData<TypeOfData>;
}

export class ApiServiceHelper extends AuthorizationService {
  #abortQueue: AbortController[] = [];
  #dispatch: AppThunkDispatch = () => null;
  GET: ApiCall;
  POST: ApiCall;
  PUT: ApiCall;
  DELETE: ApiCall;

  POST_JSON: ApiCall<'json'>;
  PUT_JSON: ApiCall<'json'>;

  POST_FDATA: ApiFromDataCall;
  PUT_FDATA: ApiFromDataCall;

  GET_FILE: ApiCall<'encoded', true>;

  #ROOT_URL = '';

  constructor(root_url: string = API_BASE_ENDPOINT) {
    super();
    this.#ROOT_URL = root_url;

    this.GET = this.#REQUEST_TEMPLATE.bind(this, ['GET', 'urlEncoded']);
    this.POST = this.#REQUEST_TEMPLATE.bind(this, ['POST', 'urlEncoded']);
    this.PUT = this.#REQUEST_TEMPLATE.bind(this, ['PUT', 'urlEncoded']);
    this.DELETE = this.#REQUEST_TEMPLATE.bind(this, ['DELETE']);

    this.POST_JSON = this.#REQUEST_TEMPLATE.bind(this, ['POST', 'json']);
    this.PUT_JSON = this.#REQUEST_TEMPLATE.bind(this, ['PUT', 'json']);

    this.POST_FDATA = this.#REQUEST_TEMPLATE.bind(this, ['POST', 'formData']);
    this.PUT_FDATA = this.#REQUEST_TEMPLATE.bind(this, ['PUT', 'formData']);

    this.GET_FILE = this.#REQUEST_TEMPLATE.bind(this, ['GET', 'urlEncoded', true]);
  }

  #stringifyData = (data: EncodeUriData<'encoded'>) => {
    const result = Object.entries(data).reduce((acc, [key, value]) => {
      if (key.includes('[]') && Array.isArray(value)) {
        value.forEach((v) => acc.push([key, v?.toString() ?? (v as string)]));
        return acc;
      }
      if (Array.isArray(value) && value.some((val) => val && typeof val === 'object')) {
        value.forEach((v) => acc.push([`${key}[]`, JSON.stringify(v)]));
        return acc;
      }
      acc.push([key, value?.toString() ?? (value as string)]);
      return acc;
    }, []);
    return result;
  };

  #prepareBody = (data: EncodeUriData, method: RequestMethod, bodyType: BodyType = 'urlEncoded') => {
    if (method === 'GET') {
      if (data instanceof URLSearchParams) return '?' + data.toString();
      if (data && typeof data !== 'string' && typeof data !== 'number' && Object.keys(data).length) {
        return '?' + new URLSearchParams(this.#stringifyData(data)).toString();
      }
      return '';
    }
    switch (bodyType) {
      case 'json':
        return JSON.stringify(data);
      case 'urlEncoded':
        if (data instanceof URLSearchParams) return data.toString();
        return new URLSearchParams(this.#stringifyData(data)).toString();
      case 'formData': {
        if (data instanceof FormData) return data;
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(
            key,
            value instanceof File || value instanceof Blob ? value : value?.toString() ?? (value as string)
          );
        });
        return formData;
      }
      default:
        if (data instanceof URLSearchParams) return data.toString();
        return new URLSearchParams(this.#stringifyData(data)).toString();
    }
  };

  #getContentType = (bodyType: BodyType, method: RequestMethod) => {
    if (method === 'GET') return {};
    switch (bodyType) {
      case 'json':
        return { 'Content-Type': 'application/json' };
      case 'formData':
        return {};
      case 'urlEncoded':
        return { 'Content-Type': 'application/x-www-form-urlencoded' };
      default:
        return { 'Content-Type': 'application/x-www-form-urlencoded' };
    }
  };

  #prepareHeaders = (
    headers: RequestInit['headers'],
    requestMethod: RequestMethod,
    bodyType: BodyType = 'urlEncoded',
    withoutAuthorization = false
  ) => {
    return {
      ...this.#getContentType(bodyType, requestMethod),
      Authorization: withoutAuthorization ? '' : 'Bearer ' + AuthorizationService.token,
      ...headers,
    };
  };

  #REQUEST_TEMPLATE = (
    initData: [RequestMethod, BodyType, WithResponse] = ['GET', 'urlEncoded', false],
    apiEndpoint = '',
    data: EncodeUriData = {},
    options: RequestOptions = {}
  ) => {
    const method = (options?.method as RequestMethod) || initData[0];
    const bodyType = initData[1];
    const withResponseObject = Boolean(initData[2]);
    const body = this.#prepareBody(data, method, bodyType);
    const headers = this.#prepareHeaders(options?.headers, method, bodyType, options.withoutAuthorization);
    return this.#FETCH_API({
      endpoint: method === 'GET' ? apiEndpoint + body : apiEndpoint,
      options: {
        ...options,
        withResponseObject,
        method,
        headers,
        body: method === 'GET' ? undefined : body,
      },
    });
  };

  #FETCH_API = async ({ endpoint = '', options = {} }: FetchApiOptions) => {
    const {
      withoutAuthorization = false,
      withResponseObject = false,
      responseType = 'json',
      ...requestOptions
    } = options;
    try {
      const controller = new AbortController();
      const { signal } = controller;
      this.#abortQueue.push(controller);

      const response = await fetch(this.#ROOT_URL + endpoint, {
        ...requestOptions,
        headers: {
          // 'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: withoutAuthorization ? '' : 'Bearer ' + AuthorizationService.token,
          ...options.headers,
        },
        signal,
      });
      if (response.status >= 200 && response.status < 300) {
        const responseData = await (responseType === 'json'
          ? response.json()
          : responseType === 'blob'
          ? response.blob()
          : response.text());
        if (withResponseObject) {
          return {
            data: responseData,
            response,
          };
        }
        return responseData;
      }
      if (response.status === 401) {
        this.cancelAuthorization();
      }
      const responseData = await response.json();
      throw responseData;
    } catch (error) {
      throw error;
    }
  };

  DOWNLOAD_FILE = async (
    endpoint: string,
    params: EncodeUriData<'encoded'> = {},
    options: RequestInit & { fileName?: string } = {}
  ): Promise<{ data: Blob; response: Response }> => {
    const { data, response } = await this.GET_FILE(endpoint, params, { responseType: 'blob', ...options });
    const fileName = new Headers(response.headers).get('content-disposition').split('=')[1];
    downloadFileToPC(URL.createObjectURL(data), options?.fileName || fileName);
    return { data, response };
  };

  pureApiFetch = (endpoint = '', options: RequestInit = {}) => {
    const controller = new AbortController();
    const { signal } = controller;
    this.#abortQueue.push(controller);
    return fetch(this.#ROOT_URL + endpoint, {
      ...options,
      headers: {
        Authorization: 'Bearer ' + AuthorizationService.token,
        'Content-Type': 'application/x-www-form-urlencoded',
        ...options.headers,
      },
      signal,
    });
  };

  setToken(token = '') {
    AuthorizationService.setToken(token);
  }

  assignDispatch = (dispatch: AppThunkDispatch) => {
    this.#dispatch = dispatch;
  };

  cancelAuthorization() {
    this.setToken('');
    localStorage.clear();
    app_history.replace('/login');
    this.#dispatch({ type: LOGOUT });
    IfVisibleListeners.clearListeners();
  }

  abortAllRequests = () => {
    this.#abortQueue.forEach((controller) => controller?.abort());
    this.#abortQueue = [];
  };
}

const ApiService = new ApiServiceHelper();
export default ApiService;
