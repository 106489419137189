import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment-timezone';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import { TextField, MenuItem, Tooltip, styled, Box, Typography, Button } from '@mui/material';

import { RegExpConEdisonTruck } from 'Constants/user';
import ConfNumberMaterialAsyncSelect, {
  ConfirmationNumberOption,
} from 'Containers/Components/Controls/ConfNumberMaterialAsyncSelect';
import UsersMaterialAsyncSearch from 'Containers/Components/Controls/UsersMaterialAsyncSearch';
import { actions } from 'Services';
import { renderTime } from 'Utils/Date';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import app_history from 'app_history';

import CheckboxComponent from '../Components/Controls/Checkbox.Component';
import UncontrolledCheckboxComponent from '../Components/Controls/UncontrolledCheckboxComponent';
import ImageUpload from '../Components/ImageUpload/ImageUpload';
import AddCommentModal from './Dialog/AddCommentModal/AddCommentModal';
import './TimesheetCreate.scss';

const dateTimeFormat = 'YYYY-MM-DDThh:mm';

const WhiteStyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: '16px',
  padding: '32px 80px',
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1);',
  scrollSnapAlign: 'start',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

class TimesheetCreate extends React.Component<any> {
  state = {
    timesheet: null,
    signature: false,
    selectedWorker: null,
    worker_id: '',
    workerObj: null,
    startDate: '',
    finishDate: '',
    addCommentModal: false,
    entity_id: null,
    processing: false,
  };

  handleValueChange = (name, value) => {
    this.setState({
      [name]: value,
    });

    const state = this.state;
    const workerFields = ['startDate', 'finishDate', 'totalHours'];

    if (workerFields.includes(name) && value && value.toString().length > 0) {
      const startDate = name === 'startDate' ? value : state.startDate;
      const finishDate = name === 'finishDate' ? value : state.finishDate;
      const totalHours = actions.TimesheetsActions.getTimesheetTotalHours(startDate, finishDate);

      this.setState({
        workerObj: {
          ...state.workerObj,
          [name]: value,
          totalHours,
        },
      });
    } else {
      this.setState({ timesheet: { ...state.timesheet, [name]: value } });
    }
  };

  handleInputChange = (event) => {
    const { name, value, type } = event.currentTarget;
    if (type === 'number') {
      return this.handleValueChange(name, Number(value));
    }
    return this.handleValueChange(name, value);
  };

  workerUpdated = (selectedWorkerId) => {
    this.setState({ worker_id: selectedWorkerId });

    const selectedWorker = this.props.job.workers.find((jobWorker) => jobWorker.id === selectedWorkerId);
    if (!selectedWorker) return;

    const startDate = moment(selectedWorker.startDate);
    const endDate = moment(selectedWorker.endDate);
    const totalHours = actions.TimesheetsActions.getTimesheetTotalHours(startDate, endDate);

    this.setState({
      selectedWorker,
      workerObj: { worker: selectedWorker, totalHours },
      startDate: moment(selectedWorker.startDate).format(dateTimeFormat),
      finishDate: moment(selectedWorker.finishDate).format(dateTimeFormat),
      endDate: moment(selectedWorker.endDate).format(dateTimeFormat),
    });
  };

  save =
    (saveWithComment = false) =>
    async (event) => {
      try {
        this.setState({ processing: true });
        const { timesheet, worker_id, workerObj, startDate, finishDate } = this.state;

        let images = [];
        if (timesheet?.images?.length > 0) {
          const formData = new FormData();
          timesheet.images.forEach((image, index) => formData.append('images_' + index, image));
          images = await this.props.uploadImages(formData);
        }

        let timesheetPdfs = [];
        if (timesheet?.timesheetPdfs?.length > 0) {
          const pdfFiles = new FormData();
          timesheet.timesheetPdfs.forEach((pdfFile, index) => {
            pdfFiles.append(`timesheetPdfs-${index}`, pdfFile);
          });
          timesheetPdfs = await this.props.uploadImages(pdfFiles);
        }

        const _timesheet = {
          jobId: timesheet.jobId,
          date: timesheet.date,
          locations: workerObj.location,
          startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
          startTime: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
          finishDate: moment(finishDate).format('YYYY-MM-DDTHH:mm:ss'),
          finishTime: moment(finishDate).format('YYYY-MM-DDTHH:mm:ss'),
          totalHours: workerObj.totalHours,
          conEdisonTruck: timesheet.conEdisonTruck,
          noBreak: false,
          hasDinner: false,
          hasLunch: false,
          workerId: worker_id,
          jobWorkerId: workerObj?.worker?.shift_id,
          confirmationNumber: timesheet.jobId,
          department: timesheet.department,
          departmentName: timesheet.departmentName,
          municipality: timesheet.municipality,
          po: timesheet.po,
          requestDate: timesheet.requestTime,
          requestor: timesheet.requestor,
          requestorName: timesheet.requestorName,
          section: timesheet.section,
          workRequest: timesheet.workRequest,
          invoiced: !!timesheet.invoiced,
          paid: !!timesheet.paid,
          isVerified: !!timesheet.isVerified,
          workerPaid: !!timesheet.workerPaid,
          comments: timesheet.comments,
          images,
          timesheetPdfs,
        };

        const createdTimesheet = await this.props.createTimesheet({ ..._timesheet });
        if (saveWithComment) {
          this.setState({ entity_id: createdTimesheet.id, addCommentModal: true });
          return;
        }
        app_history.push('/timesheets');
      } catch (error) {
        showErrorMessage(error);
      } finally {
        this.setState({ processing: false });
      }
    };

  onJobSelect = (selectedJob: ConfirmationNumberOption) => {
    this.setState({ workerObj: null, worker_id: '', finishDate: '' });

    if (!selectedJob) {
      return this.setState({ timesheet: null, selectedWorker: null });
    }

    const jobId = selectedJob.value.id;
    const { selectedWorker } = this.state;

    this.props.retrieveJob(jobId, selectedWorker?.workerId ?? '').then((job) => {
      const timesheet = {
        ...job,
        jobId: job.id,
        department: { id: job.department, name: job.departmentName },
        date: job.requestTime,
        poet: job.poet || '',
        workRequest: job.maxWorkers,
        po: job.po,
        jobType: job.jobType,
        supervisorName: job.supervisorName,
        jobStatus: job.jobStatus,
      };

      if (selectedWorker && job.workers.length) {
        const jobShift = job.workers.find((jobWorker) => jobWorker.workerId === selectedWorker.id);
        const workerObj = jobShift
          ? {
              ...jobShift,
              finishDate: new Date().toISOString(),
            }
          : null;
        this.setState({ workerObj });
      }
      this.setState({ timesheet });
    });
  };

  public render() {
    const state = this.state;
    const userCanDoTimeshseetAction = UserPermissions.has.can_do_timesheet_action;
    const userCanEditTimesheets = UserPermissions.has.edit_timesheets;
    const userCanEditFiles = UserPermissions.has.can_edit_timesheet_files;
    const conEdisonTruckFieldValid = RegExpConEdisonTruck.test(state.timesheet?.conEdisonTruck || '');
    const submitButtonEnabled =
      state.timesheet &&
      state.worker_id &&
      userCanEditTimesheets &&
      userCanDoTimeshseetAction &&
      state.workerObj?.totalHours > 0 &&
      conEdisonTruckFieldValid;

    const tooltipErrorText = !state.timesheet
      ? 'Please select a job'
      : !state.worker_id
      ? 'Please select a worker'
      : !userCanEditTimesheets || !userCanDoTimeshseetAction
      ? "You can't do timesheet actions"
      : state.workerObj?.totalHours <= 0
      ? 'Total hours must be greater than 0'
      : !conEdisonTruckFieldValid
      ? 'ConEdison truck number is incorrect.'
      : '';

    return (
      <Box
        overflow="auto"
        width="100%"
        maxWidth={1100}
        mx="auto"
        px={2}
        sx={{
          '& div.Mui-disabled': { bgcolor: '#00000005', opacity: 0.6 },
          '@media (max-width: 600px)': {
            scrollSnapType: 'y mandatory',
          },
        }}
      >
        <Typography
          variant="body1"
          py={2}
          fontWeight={500}
          sx={{
            scrollSnapAlign: 'start',
          }}
        >
          Create Timesheet
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{ opacity: state.processing ? 0.5 : 1, pointerEvents: state.processing ? 'none' : 'all' }}
        >
          <WhiteStyledBox>
            <Box flex={1} width="100%">
              <ConfNumberMaterialAsyncSelect
                onSelect={this.onJobSelect}
                placeholder="Select conf number"
                searchParams={{
                  workerId: state.selectedWorker?.id || null,
                }}
              />
            </Box>
            <TextField
              sx={{ flex: 1, width: '100%' }}
              select
              variant="outlined"
              label="Worker"
              value={this.state.worker_id}
              onChange={(event) => this.workerUpdated(event.target.value)}
              disabled={this.props.retrieve_job_processing || !this.state.timesheet || !this.props.job.workers.length}
              style={{ flex: 1 }}
            >
              {this.props.job?.workers?.length > 0 ? (
                this.props.job.workers.reduce((list, worker, index) => {
                  if (worker.workerStatus === 'assigned' || worker.workerStatus === 'en_route') {
                    return list;
                  }
                  return [
                    ...list,
                    <MenuItem value={worker.worker.id} key={index}>
                      {worker.worker.name}, Shift ID: {worker.shift_id}
                    </MenuItem>,
                  ];
                }, [])
              ) : (
                <MenuItem value="" disabled>
                  No workers
                </MenuItem>
              )}
            </TextField>
          </WhiteStyledBox>
          <WhiteStyledBox>
            <Box
              display="flex"
              width="100%"
              sx={{
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-end', sm: 'center' },
              }}
              gap={4}
            >
              <Box
                display="grid"
                gap={2}
                sx={{
                  width: { xs: '100%', sm: '70%' },
                  gridTemplateColumns: {
                    xs: '100%',
                    sm: '50% 50%',
                  },
                }}
              >
                <UsersMaterialAsyncSearch
                  disabled
                  size="medium"
                  value={{
                    label: state.timesheet ? state.timesheet.requestorName : '',
                    value: state.timesheet ? state.timesheet.requestor : '',
                  }}
                />
                <TextField
                  fullWidth
                  label="Request Date"
                  type="datetime-local"
                  variant={'outlined'}
                  value={state.timesheet ? moment(state.timesheet.date).format(dateTimeFormat) : ''}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  label="Start Date"
                  type="datetime-local"
                  variant={'outlined'}
                  value={state.timesheet ? moment(state.timesheet?.start_at).format(dateTimeFormat) : ''}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  id={'finish-datetime-input'}
                  label="Finish Date"
                  type="datetime-local"
                  variant={'outlined'}
                  error={this.state.workerObj && this.state.workerObj.totalHours < 0}
                  onChange={(date) => this.handleValueChange('finishDate', date.target.value)}
                  value={this.state.finishDate}
                  disabled={!(state.workerObj && !this.state.timesheet.workerPaid)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: moment(this.state.startDate).format(dateTimeFormat),
                  }}
                />
              </Box>
              <TextField
                label="Total Hours"
                disabled
                onChange={this.handleInputChange}
                name="totalHours"
                value={renderTime(state.workerObj && state.workerObj.totalHours ? state.workerObj.totalHours : 0)}
              />
            </Box>
          </WhiteStyledBox>
          <WhiteStyledBox
            sx={{
              display: {
                xs: 'grid',
                md: 'flex',
              },
              gridTemplateColumns: {
                xs: '1fr ',
                sm: '1fr 1fr ',
                lg: '1fr 1fr 1fr',
              },
            }}
          >
            <TextField
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              label="Department"
              placeholder="Department"
              value={state.timesheet?.department?.name}
            />

            <TextField
              fullWidth
              disabled
              id="section"
              label=" Section #"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="000"
              value={`${state.timesheet ? state.timesheet?.section || '---' : ''}`}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled
              id="poet"
              label=" POET #"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="00000"
              value={`${state.timesheet ? state.timesheet?.poet || '---' : ''}`}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled
              id="workRequest"
              label="Work Request  #"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="00000"
              value={`${state.timesheet ? state.timesheet?.wr || '---' : ''}`}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled
              id="po"
              label="PO #"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="PO #..."
              value={state.timesheet ? state.timesheet?.po || '---' : ''}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled
              id="outlined-disabled"
              label="Receipt #"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Receipt #..."
              value={`${state.timesheet ? state.timesheet.receipt || '---' : ''}`}
              variant="outlined"
            />
          </WhiteStyledBox>
          <WhiteStyledBox>
            <TextField
              fullWidth
              disabled
              id="locationAddress"
              label=" Location Address #1"
              placeholder="BX, Gleason Ave/ Virginia Ave"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.workerObj?.worker?.location?.address ?? ''}
              variant="outlined"
            />
            <TextField
              fullWidth
              disabled
              id="locationAddress"
              label="Structure #"
              placeholder="Structure #"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.workerObj?.worker?.location?.structure ?? ''}
              variant="outlined"
            />
          </WhiteStyledBox>
          <WhiteStyledBox>
            <TextField
              fullWidth
              disabled={!state.timesheet}
              id="conEdisonTruck"
              name="conEdisonTruck"
              label="ConEdison Truck #"
              placeholder="000"
              variant="outlined"
              error={!conEdisonTruckFieldValid}
              helperText={
                !conEdisonTruckFieldValid && 'Allowed symbols: 0-9, A-Z, a-z and space. Max length is 12 symbols.'
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleInputChange}
            />
            <TextField
              fullWidth
              disabled
              id="section"
              name="section"
              InputLabelProps={{
                shrink: true,
              }}
              value={state.timesheet?.supervisorName ?? ''}
              label="ConEdison supervisor"
              variant="outlined"
            />
          </WhiteStyledBox>
          <WhiteStyledBox
            sx={{
              display: {
                xs: 'flex',
                sm: 'grid',
              },
              gridTemplateColumns: {
                xs: '1fr ',
                sm: '1fr 1fr',
                md: 'repeat(5,1fr)',
              },
              justifyItems: {
                xs: 'start',
                md: 'center',
              },
              alignItems: 'start',
            }}
            flexWrap={'wrap'}
          >
            <Tooltip
              disableInteractive
              title={
                state?.timesheet?.employeeNumber && state?.timesheet?.employeeNumber !== '0' && !!state?.timesheet?.sign
                  ? 'ConEd Supervisor approved the timesheet'
                  : 'ConEd Supervisor did not approve the timesheet'
              }
            >
              <div>
                <UncontrolledCheckboxComponent
                  id="ConEdVerified"
                  title="ConEd Verified"
                  checked={
                    state?.timesheet?.employeeNumber &&
                    state?.timesheet?.employeeNumber !== '0' &&
                    !!state?.timesheet?.sign
                  }
                />
              </div>
            </Tooltip>
            <CheckboxComponent
              id="Verified"
              disabled={!state.timesheet || !userCanDoTimeshseetAction}
              hasTitle="CES Verified"
              checked={state.timesheet && state.timesheet.isVerified}
              onChange={(checked) => this.handleValueChange('isVerified', checked)}
            />
            <CheckboxComponent
              id="Worker Paid"
              disabled={!state.timesheet || !userCanDoTimeshseetAction}
              hasTitle="Worker Paid"
              checked={state.timesheet && state.timesheet.workerPaid}
              onChange={(checked) => this.handleValueChange('workerPaid', checked)}
            />
            <CheckboxComponent
              id="Invliced"
              disabled={!state.timesheet || !userCanDoTimeshseetAction}
              hasTitle="Invoiced"
              checked={state.timesheet && state.timesheet.invoiced}
              onChange={(checked) => this.handleValueChange('invoiced', checked)}
            />
            <CheckboxComponent
              id="Paid"
              disabled
              hasTitle="Paid"
              checked={state.timesheet && state.timesheet.paid}
              onChange={(checked) => this.handleValueChange('paid', checked)}
            />
          </WhiteStyledBox>
          <WhiteStyledBox sx={{ alignItems: 'flex-start' }}>
            <Box flex={1}>
              <Typography variant="body2">Photo of Paper Timesheet</Typography>
              <ImageUpload
                onChangeImage={(images) => this.handleValueChange('images', images)}
                disabled={!state.timesheet || !userCanEditFiles}
              />
            </Box>
            <Box flex={1}>
              <Typography variant="body2">Attach PDFs</Typography>
              <ImageUpload
                onChangeImage={(pdfFile) => this.handleValueChange('timesheetPdfs', pdfFile)}
                disabled={!state.timesheet || !userCanEditFiles}
                filesMode
              />
            </Box>
          </WhiteStyledBox>
        </Box>

        <Box display="flex" justifyContent="space-between" mt={4} mb={12} flexWrap={'wrap'} gap={2}>
          <Tooltip title={tooltipErrorText}>
            <Box>
              <LoadingButton
                onClick={this.save(true)}
                loading={state.processing}
                disabled={!submitButtonEnabled}
                startIcon={<AddCircleIcon fontSize="large" />}
                loadingPosition="start"
              >
                Save & Add Comment
              </LoadingButton>
            </Box>
          </Tooltip>
          <Box
            display="flex"
            gap={2}
            sx={{
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
          >
            <Button
              size="large"
              sx={{
                fontWeight: 'bold',
                color: 'black',
                borderColor: 'black',
                fontSize: '1rem',
                width: {
                  xs: '40%',
                  sm: '150px',
                },
                '&:hover': { color: 'black', borderColor: 'black' },
              }}
              variant="outlined"
              onClick={() => this.props.history.push('/timesheets')}
            >
              Cancel
            </Button>

            <Tooltip title={tooltipErrorText}>
              <Box>
                <LoadingButton
                  sx={{
                    fontWeight: 'bold',
                    width: {
                      xs: '60%',
                      sm: '120px',
                    },
                  }}
                  size="large"
                  variant="contained"
                  onClick={this.save()}
                  loading={state.processing}
                  disabled={!submitButtonEnabled}
                  loadingPosition="start"
                >
                  Save
                </LoadingButton>
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <AddCommentModal
          open={this.state.addCommentModal}
          onClose={() => {
            this.setState({ addCommentModal: false });
            app_history.push('/timesheets');
          }}
          entity_id={this.state.entity_id}
          onSubmit={() => app_history.push('/timesheets')}
        />
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    job: state.jobs.job,
    retrieve_job_processing: state.jobs.retrieve_job_processing,
    user: state.app.user,
    timesheet: state.timesheets.timesheet,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    retrieveJob: (job_id, worker_id) => dispatch(actions.JobsActions.retrieveJob(job_id, worker_id)),
    uploadImages: (images) => dispatch(actions.TimesheetsActions.uploadImages(images)),
    createTimesheet: (data) => dispatch(actions.TimesheetsActions.create(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetCreate);
