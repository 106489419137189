import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { FaDownload } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { Button, CircularProgress, Tooltip } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { ConEdRequestor, ConEdSupervisor } from 'types/Common/User';

import { baseToastConfig } from 'Constants/app';
import { EROLES } from 'Constants/user';
import RequestorAsyncSearch from 'Containers/Components/Controls/RequestorAsyncSearch';
import CETSearchInput from 'Containers/Components/Controls/SearchInput.Component';
import SubcontractorAsyncSearch, {
  SubcontractorSelectItem,
} from 'Containers/Components/Controls/SubcontractorAsyncSearch';
import { SupervisorsAsyncSearch } from 'Containers/Components/Controls/SupervisorsAsyncSearch';
import { WorkerAsyncSearch } from 'Containers/Components/Controls/WorkerAsyncSearch';
import { actions } from 'Services';
import { downloadFileToPC } from 'Utils/downloadFile';
import { ReduxState, useAppSelector } from 'createStore';

import styles from './TimesheetsFilters.module.scss';

type Props = {
  handleChangeSearchParams: (event: React.ChangeEvent<Element>, value?: { name: string; value: any }) => void;
  handleWorkerSelect: (data: { name: 'worker'; value: string | number }) => void;
  searchParams: ReturnType<ReduxState['timesheets']['search_options']['asMutable']>;
};

function TimesheetsFilters({ handleChangeSearchParams, handleWorkerSelect, searchParams }: Props) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const canSeeSubcontractors = useAppSelector((state) => state.userPermissions.canSeePage.subcontractors);
  const isSubcontractor = useAppSelector((state) => state.app.userIsSubcontractor);
  const [loading, setLoading] = useState(false);
  const { results: timesheets, related_to_one_conf } = useAppSelector(
    (state) => state.timesheets.timesheets,
    (prev, next) =>
      prev.related_to_one_conf === next.related_to_one_conf &&
      prev?.results[0]?.confirmationNumber === next?.results[0]?.confirmationNumber
  );
  const confNumber = timesheets[0]?.confirmationNumber;

  const downloadAllTimesheets = async () => {
    setLoading(true);
    try {
      const file = await dispatch(actions.TimesheetsActions.downloadAllConfTimesheets(confNumber));
      downloadFileToPC(URL.createObjectURL(file), `Conf ${confNumber}-timesheets`, file.type);
    } catch (error) {
      const message = typeof error?.error === 'string' ? error.error : 'Somethings went wrong';
      toast.error(message, baseToastConfig);
    } finally {
      setLoading(false);
    }
  };

  const selectSubcontractor = (item: SubcontractorSelectItem) =>
    handleChangeSearchParams(null, { name: 'subcontractorName', value: item?.label || '' });

  const clearSubcontractor = () => handleChangeSearchParams(null, { name: 'subcontractorName', value: '' });

  const selectRequestor = (item?: ConEdRequestor) => {
    handleChangeSearchParams(null, { name: 'requestor', value: item?.id || null });
  };

  const selectSupervisor = (item?: ConEdSupervisor) => {
    handleChangeSearchParams(null, { name: 'supervisor', value: item?.id || null });
  };

  return (
    <div className={related_to_one_conf ? styles.containerWithButton : styles.container}>
      <div>
        <span>Worker</span>
        <div>
          <WorkerAsyncSearch
            isClearable
            selectedId={searchParams.worker}
            onSelect={(item) => handleWorkerSelect({ name: 'worker', value: item ? item.value.id : '' })}
          />
        </div>
      </div>
      {canSeeSubcontractors && (
        <div>
          <span>Subcontractor</span>
          <div>
            <SubcontractorAsyncSearch
              onSelect={selectSubcontractor}
              onClear={clearSubcontractor}
              isClearable
              placeholder="Search..."
              selectedName={searchParams.subcontractorName}
            />
          </div>
        </div>
      )}
      {!isSubcontractor && (
        <div>
          <span>Requestor</span>
          <div>
            <RequestorAsyncSearch
              onSelect={selectRequestor}
              onClear={selectRequestor}
              placeholder="Search..."
              departmentId={searchParams.department}
              requestorId={searchParams.requestor}
            />
          </div>
        </div>
      )}
      <div>
        <span>Supervisor</span>
        <div>
          <SupervisorsAsyncSearch
            onSelect={selectSupervisor}
            onClear={selectSupervisor}
            placeholder="Search..."
            departmentId={searchParams.department}
            supervisorId={searchParams.supervisor}
          />
        </div>
      </div>
      <div>
        <span>Confirmation #</span>
        <CETSearchInput
          title={searchParams.confirmation}
          name="confirmation"
          placeholder="00000000"
          onChange={handleChangeSearchParams}
        />
      </div>
      <div>
        <span>Shift ID</span>
        <CETSearchInput
          title={searchParams.shift_id}
          name="shift_id"
          placeholder="00000000"
          onChange={handleChangeSearchParams}
        />
      </div>
      <div>
        <span>PO #</span>
        <div>
          <CETSearchInput title={searchParams.po} name="po" placeholder="000000" onChange={handleChangeSearchParams} />
        </div>
      </div>
      {related_to_one_conf && !isSubcontractor ? (
        <div>
          <Tooltip title={`Download all timesheets for Conf #${confNumber}`}>
            <Button
              onClick={downloadAllTimesheets}
              variant="contained"
              disabled={loading}
              color="primary"
              style={{ alignSelf: 'center', minWidth: 'unset', padding: '0.5rem', position: 'relative' }}
            >
              <FaDownload color="white" size={20} />
              {loading ? (
                <div className={styles.loading}>
                  <CircularProgress size={20} />
                </div>
              ) : null}
            </Button>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
}

export default TimesheetsFilters;
