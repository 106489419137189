import React, { useEffect, useState } from 'react';

import { Autocomplete, CircularProgress, createFilterOptions } from '@mui/material';
import TextField from '@mui/material/TextField';

import { JobsAPI } from 'Services/API';
import { ConfirmationNumber, LoadConfNumbersParams } from 'Services/API/JobsAPI.service';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';

const filterOptions = createFilterOptions<ConfirmationNumberOption>({
  matchFrom: 'any',
  limit: 200,
});

const createConfirmationNumberOption = (job: ConfirmationNumber) => ({
  label: job.id,
  value: job,
});

export type ConfirmationNumberOption = {
  label: string;
  value: ConfirmationNumber;
};

type BaseProps = {
  searchParams?: LoadConfNumbersParams;
  label?: string;
  inputSize?: 'small' | 'medium';
  placeholder?: string;
};

type PropsWithMultipleSelect = {
  multiple: true;
  onSelect: (item: ConfirmationNumberOption[]) => void;
};

type PropsWithSingleSelect = {
  multiple?: false;
  onSelect: (item: ConfirmationNumberOption) => void;
};

type Props = BaseProps & (PropsWithMultipleSelect | PropsWithSingleSelect);

export default function ConfNumberMaterialAsyncSelect({
  onSelect,
  searchParams,
  multiple = false,
  inputSize = 'medium',
  label = 'Confirmation Number',
  placeholder,
}: Props) {
  const [options, setOptions] = useState<ConfirmationNumberOption[]>([]);
  const { promiseWrapper, inProcess } = useProcessing();

  useEffect(() => {
    promiseWrapper(JobsAPI.loadConfNumbers(searchParams))
      .then(({ jobs }) => setOptions(jobs.map(createConfirmationNumberOption)))
      .catch(showErrorMessage);
  }, [searchParams]);

  return (
    <Autocomplete<ConfirmationNumberOption, boolean>
      filterOptions={filterOptions}
      multiple={multiple}
      limitTags={1}
      placeholder={placeholder}
      size={inputSize}
      id="confirmation-async-select"
      getOptionLabel={(option) => String(option?.label ?? '')}
      isOptionEqualToValue={(option, value) => {
        return option.value.id === value.value.id;
      }}
      options={options}
      loadingText="Loading..."
      loading={inProcess}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value.id}>
            <span>{option.label}</span>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {inProcess && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(event, value) => onSelect(value as ConfirmationNumberOption[] & ConfirmationNumberOption)}
    />
  );
}
