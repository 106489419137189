import { FC, useEffect, useMemo, useRef } from 'react';

import { FormikProps } from 'formik';

import { DeleteForever } from '@mui/icons-material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { Avatar, Box, BoxProps, Button, Collapse, IconButton, Tooltip, Typography } from '@mui/material';

import { Department } from 'types/Common/Companies';
import { AuthSession } from 'types/Common/User';

import { DatasetTestIDs } from 'Constants/tests';
import DepartmentMaterialAsyncSearch from 'Containers/Components/Controls/DepartmentMaterialAsyncSearch';
import RolesMaterialAsyncSearch from 'Containers/Components/Controls/RolesMaterialAsyncSearch';
import { UserSolidBold } from 'Utils/Icon';
import UserPermissions from 'Utils/PermissionsHelper';
import fileSizeFilter from 'Utils/fileSizeFilter';
import useModal from 'Utils/hooks/useModal';
import AppInputField from 'components/AppInputField/AppInputField';
import AppSwitch from 'components/AppSwitch/AppSwitch';
import ImageCropper from 'components/ImageCropper/ImageCropper';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';

import LoginsTable from './LoginsTable';
import UserNotifications, { NotificationsObject } from './UserNotifications';
import { AppInputAutocomplete } from './styled';

export const enum ProfileFormVariant {
  page = 'page',
  modal = 'modal',
}

export type ProfileFormValues = {
  avatar: File | null;
  croppedAvatar: File | null;
  avatar_url: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  departments: Department[];
  departmentGroups: Department[];
  roles: number[];
  two_factor_auth_enabled: 0 | 1;
  currentPassword?: string;
  password?: string;
  repeatPassword?: string;
  co_employee_id?: string;
  notifications?: NotificationsObject;
  initial_notifications?: NotificationsObject;
  auth_sessions?: AuthSession[];
};

export enum ProfileFormTabs {
  PROFILE = 'PROFILE',
  PASSWORD = 'PASSWORD',
  NOTIFICATIONS = 'NOTIFICATIONS',
  LOGINS = 'LOGINS',
}

type ProfileFormProps = {
  formikBag: FormikProps<ProfileFormValues>;
  activeTab: ProfileFormTabs;
  showRolesSelect?: boolean;
  showEmployeeNumberField?: boolean;
  containerProps?: BoxProps;
  inputSize?: 'small' | 'medium';
  variant?: ProfileFormVariant;
  isCurrentUser: boolean;
};

const ProfileForm: FC<ProfileFormProps> = ({
  formikBag,
  activeTab,
  showRolesSelect = false,
  showEmployeeNumberField = false,
  containerProps = {},
  inputSize = 'medium',
  variant = ProfileFormVariant.page,
  isCurrentUser,
}) => {
  const inputOpenFileRef = useRef<HTMLInputElement>(null);
  const userCanSeeAllRoles = UserPermissions.has.see_all_roles;
  const userCanUpdateOwnDepartments = UserPermissions.has.update_own_departments;

  const isProfileTab = activeTab === ProfileFormTabs.PROFILE;
  const isPasswordTab = activeTab === ProfileFormTabs.PASSWORD;
  const isNotificationsTab = activeTab === ProfileFormTabs.NOTIFICATIONS;
  const isLoginsTab = activeTab === ProfileFormTabs.LOGINS;

  const { values, errors, handleChange, setFieldValue, setValues } = formikBag;

  const avatarCropperModal = useModal();

  const changePhoneNumber = (value: string) => {
    setFieldValue('phoneNumber', value);
  };

  const onChangeAvatarFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const avatarFile = fileSizeFilter(e.target.files)[0];
      inputOpenFileRef.current.value = '';
      if (!avatarFile) return;
      setValues((prevValues) => ({
        ...prevValues,
        avatar: avatarFile,
        croppedAvatar: avatarFile,
      }));

      avatarCropperModal.open();
    }
  };

  const removeProfileAvatar = () => {
    setValues((prevValues) => ({
      ...prevValues,
      avatar: null,
      avatar_url: '',
      croppedAvatar: null,
    }));
  };

  const changeDepartments = (departments, departmentGroups) => {
    setValues((prevValues) => ({
      ...prevValues,
      departments,
      departmentGroups,
    }));
  };

  const changeRoles = (roles = []) => {
    if (!roles) {
      setFieldValue('roles', null);
      return;
    }
    setFieldValue(
      'roles',
      roles.map((role) => role?.value?.id)
    );
  };

  const changeTwoFactorAuth = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('two_factor_auth_enabled', Number(e.target.checked));
  };

  const profileAvatar = useMemo(() => {
    if (values.croppedAvatar) {
      return URL.createObjectURL(values.croppedAvatar);
    }
    if (values.avatar_url) {
      return values.avatar_url;
    }
    return null;
  }, [values.croppedAvatar, values.avatar_url]);

  useEffect(() => {
    const enterKeyListener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        formikBag.submitForm();
      }
    };
    document.addEventListener('keydown', enterKeyListener);
    return () => {
      document.removeEventListener('keydown', enterKeyListener);
    };
  }, []);

  return (
    <Box {...containerProps}>
      <Collapse in={isProfileTab}>
        <Box display="flex" flexDirection="column" gap={ProfileFormVariant.modal ? '14px' : '30px'}>
          {/* Avatar  */}
          <Box display="flex" flexDirection="column" gap="10px">
            <input
              type="file"
              ref={inputOpenFileRef}
              style={{ display: 'none' }}
              onChange={onChangeAvatarFile}
              accept="image/*"
              {...DatasetTestIDs.components.profileForm.profileInfoTab.fields.avatar}
            />
            <Typography fontFamily="Poppins Medium" fontSize="14px">
              Avatar
            </Typography>

            <Box display="flex" gap="14px">
              <Avatar
                sx={{
                  width: 74,
                  height: 74,
                  backgroundColor: '#F2F3F7',
                  cursor: 'pointer',
                  transform: 'scale(1)',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    filter: 'brightness(0.9)',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    transform: 'scale(1.02)',
                  },
                  '&:active': {
                    transition: 'all .1s ease-in-out',
                    transform: 'scale(0.99)',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  },
                }}
                src={profileAvatar}
                onClick={() => inputOpenFileRef.current.click()}
              >
                <UserSolidBold width={26} height={26} color="#404B5B30" />
              </Avatar>

              <Box display="flex" flexDirection="column" gap="6px">
                <Box display="flex" gap={1} flexWrap="wrap">
                  <Button
                    sx={(theme) => ({
                      border: `1px solid #E4E9EB`,
                      borderRadius: 2,
                      transition: 'border .2s ease-in-out',
                      padding: '6px 12px',
                      '&:hover': { borderColor: theme.palette.primary.main },
                    })}
                    onClick={() => inputOpenFileRef.current.click()}
                    {...DatasetTestIDs.components.profileForm.profileInfoTab.buttons.uploadImageBtn}
                  >
                    Upload image
                  </Button>

                  {(values.avatar_url || values.avatar) && (
                    <Box display="flex" gap={1}>
                      <Tooltip title="Crop image" disableInteractive arrow>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={avatarCropperModal.open}
                          {...DatasetTestIDs.components.profileForm.profileInfoTab.buttons.cropImageBtn}
                          sx={(theme) => ({
                            border: `1px solid #E4E9EB`,
                            borderRadius: 2,
                            transition: 'border .2s ease-in-out',
                            '&:hover': { borderColor: theme.palette.primary.main },
                          })}
                        >
                          <CenterFocusWeakIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete image" disableInteractive arrow>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={removeProfileAvatar}
                          {...DatasetTestIDs.components.profileForm.profileInfoTab.buttons.deleteImageBtn}
                          sx={(theme) => ({
                            border: `1px solid #E4E9EB`,
                            borderRadius: 2,
                            transition: 'border .2s ease-in-out',
                            '&:hover': { borderColor: theme.palette.error.main },
                          })}
                        >
                          <DeleteForever fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <ImageCropper
                        image={values.avatar ? URL.createObjectURL(values.avatar) : values.avatar_url || ''}
                        open={avatarCropperModal.isOpen}
                        onClose={avatarCropperModal.close}
                        onCrop={(croppedImageFile) => setFieldValue('croppedAvatar', croppedImageFile)}
                      />
                    </Box>
                  )}
                </Box>

                <Typography
                  fontFamily="Poppins Regular"
                  fontSize={12}
                  maxWidth={220}
                  color="#404B5B"
                  sx={{ opacity: 0.7 }}
                >
                  At least 800x800 px recommended. JPG or PNG and GIF is allowed.
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Form fields  */}
          <Box display="flex" flexDirection="column" gap="14px">
            {/* Name  */}
            <Box
              sx={{
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
              }}
              display="flex"
              gap="14px"
            >
              <AppInputField
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                fullWidth
                label="First Name"
                placeholder="Enter your first name"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName && errors.firstName}
                inputProps={{ ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.firstName }}
                size={inputSize}
              />
              <AppInputField
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                fullWidth
                label="Last Name"
                placeholder="Enter your last name"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName}
                inputProps={{ ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.lastName }}
                size={inputSize}
              />
            </Box>

            {/* Email  */}
            <AppInputField
              name="email"
              value={values.email}
              onChange={handleChange}
              fullWidth
              label="Email"
              placeholder="Enter your email"
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email}
              inputProps={{ ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.email }}
              size={inputSize}
            />

            {/* Roles  */}
            {showRolesSelect && (
              <RolesMaterialAsyncSearch
                id="profile-roles-select"
                fullWidth
                disabledValue={{ label: 'Worker', value: { id: 9, name: 'Worker', role: 'worker' } }}
                selectedRoleIds={values.roles}
                limitTags={5}
                onSelectRole={changeRoles}
                onlyDispatcher={!userCanSeeAllRoles}
                small={inputSize === 'small'}
                relatedRolesOnly
                renderInput={(params) => (
                  <AppInputAutocomplete
                    {...params}
                    label="Role(s)"
                    error={Boolean(errors.roles)}
                    helperText={<>{errors.roles}</>}
                    inputProps={{
                      ...params.inputProps,
                      ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.roles,
                    }}
                  />
                )}
              />
            )}

            {/* Phone Number  */}
            <PhoneNumberInput
              value={values.phoneNumber}
              onChange={changePhoneNumber}
              label="Phone Number"
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber && errors.phoneNumber}
              size={inputSize}
              inputProps={{ ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.phoneNumber }}
              countrySelectProps={{
                containerProps: { ...DatasetTestIDs.components.profileForm.select.country },
              }}
            />

            {/* Employee Number  */}
            {showEmployeeNumberField && (
              <AppInputField
                name="co_employee_id"
                value={values.co_employee_id}
                onChange={handleChange}
                fullWidth
                label="Employee Number"
                placeholder="Enter your employee number"
                error={Boolean(errors.co_employee_id)}
                helperText={errors.co_employee_id && errors.co_employee_id}
                size={inputSize}
                inputProps={{ ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.employeeNumber }}
              />
            )}

            <DepartmentMaterialAsyncSearch
              disabled={!userCanUpdateOwnDepartments}
              limitTags={5}
              inputSize={inputSize}
              renderInput={(params) => (
                <AppInputAutocomplete
                  {...params}
                  sx={{
                    '& .Mui-disabled .MuiOutlinedInput-input': {
                      bgcolor: !userCanSeeAllRoles && 'transparent',
                    },
                  }}
                  label="Department(s)"
                  error={Boolean(errors.departments)}
                  helperText={<>{errors.departments}</>}
                  inputProps={{
                    ...params.inputProps,
                    ...DatasetTestIDs.components.profileForm.profileInfoTab.fields.departments,
                  }}
                />
              )}
              departments={values.departments}
              departmentGroups={values.departmentGroups}
              onSelect={changeDepartments}
            />
          </Box>
        </Box>
      </Collapse>

      <Collapse in={isPasswordTab}>
        <Box display="flex" flexDirection="column" gap="14px" pb={2}>
          <Box display="flex" alignItems="center" gap="14px">
            <AppSwitch
              id="two-factor-switch"
              checked={Boolean(values.two_factor_auth_enabled)}
              onChange={changeTwoFactorAuth}
              // @ts-ignore
              inputProps={{
                ...DatasetTestIDs.components.profileForm.profilePasswordTab.buttons.twoFactorAuthentication,
              }}
            />
            <Typography
              component="label"
              htmlFor="two-factor-switch"
              fontFamily="Poppins Regular"
              fontSize={14}
              color="#404B5B"
              sx={{ cursor: 'pointer' }}
            >
              Two Factor Authentication
            </Typography>
          </Box>

          {isCurrentUser && (
            <AppInputField
              name="currentPassword"
              value={values.currentPassword}
              onChange={handleChange}
              fullWidth
              label="Current Password"
              placeholder="Enter your current password"
              error={Boolean(errors.currentPassword)}
              helperText={errors.currentPassword && errors.currentPassword}
              inputProps={{
                ...DatasetTestIDs.components.profileForm.profilePasswordTab.fields.currentPasswordField,
              }}
              sx={{ mt: 2 }}
            />
          )}

          <AppInputField
            name="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            fullWidth
            label="Create Password"
            placeholder={isCurrentUser ? 'Enter your new password' : 'Enter new password'}
            error={Boolean(errors.password)}
            helperText={errors.password && errors.password}
            size={inputSize}
            inputProps={{
              ...DatasetTestIDs.components.profileForm.profilePasswordTab.fields.createPasswordField,
            }}
          />

          <AppInputField
            name="repeatPassword"
            type="password"
            value={values.repeatPassword}
            onChange={handleChange}
            fullWidth
            label="Repeat New Password"
            placeholder={isCurrentUser ? 'Repeat your new password' : 'Repeat new password'}
            error={Boolean(errors.repeatPassword)}
            helperText={errors.repeatPassword && errors.repeatPassword}
            inputProps={{
              ...DatasetTestIDs.components.profileForm.profilePasswordTab.fields.repeatNewPasswordField,
            }}
            size={inputSize}
          />
        </Box>
      </Collapse>

      <Collapse in={isNotificationsTab}>
        <UserNotifications
          containerProps={{
            width: '100%',
            sx: {
              translate: {
                xs: '0 0',
                sm: `0 ${variant === ProfileFormVariant.modal ? 0 : '-50px'}`,
              },
            },
          }}
          notifications={values.notifications}
          onChange={(notifications) => {
            setFieldValue('notifications', notifications);
          }}
        />
      </Collapse>

      <Collapse in={isLoginsTab}>
        <LoginsTable sessions={values.auth_sessions} />
      </Collapse>
    </Box>
  );
};

export default ProfileForm;
