import { memo } from 'react';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';

const BillingCheckbox = (props: Omit<CheckboxProps, 'value'> & Omit<FormControlLabelProps, 'control'>) => (
  <FormControlLabel
    control={
      <Checkbox
        key={props.name}
        name={props.name}
        icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />}
        checked={props.checked}
        color="primary"
        onChange={props.onChange}
      />
    }
    style={{ margin: 0 }}
    {...props}
  />
);

export default memo(BillingCheckbox);
