import React, { useState, useCallback, memo, CSSProperties, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';

import { Button } from '@mui/material';

import { ImageWithAuthor } from 'types/Location';

import PDFicon from 'Assets/pdf-icon.png';
import { getFileLink } from 'Utils/PrepareData';
import fileSizeFilter from 'Utils/fileSizeFilter';
import AppPaperModal from 'components/AppPaperModal/AppPaperModal';
import { ImagePreviewProps } from 'components/ImagePreview/types';

import ImageViewer from '../ImageViewer/ImageViewer';
import { ImageWithAuthorHelper } from '../ImageViewer/helpers';
import './ImageUpload.scss';

interface Props {
  onChangeImage: (images) => void;
  defaultImages?: Array<any>;
  disabled?: boolean;
  filesMode?: boolean;
  jobType?: string | number;
  dropzoneInnerStyle?: CSSProperties;
  getDownloadedImageName?: ImagePreviewProps<ImageWithAuthor>['getDownloadedImageName'];
  canRemove?: boolean;
  fullWidth?: boolean;
}

const ImageUpload: React.FC<Props> = ({
  defaultImages = [],
  onChangeImage,
  disabled = false,
  filesMode = false,
  jobType,
  dropzoneInnerStyle,
  canRemove = true,
  fullWidth = false,
  getDownloadedImageName = () => '',
}) => {
  const [images, setImages] = useState([...defaultImages]);
  const [index, setIndex] = useState(-1);
  const acceptType = filesMode ? '.pdf' : 'image/* , * .HEIC, ';
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptType,
    onDrop: (acceptedFiles) => {
      const files = fileSizeFilter(acceptedFiles);
      images.forEach((file) => {
        const isNewFile = files.filter((f) => f instanceof File && f.name === file.name).length === 0;
        if (isNewFile) {
          files.push(file);
        } else {
          alert('Duplicate file: ' + file.name);
        }
      });
      setImages(files);
      onChangeImage(files);
    },
    disabled: disabled,
  });

  const removeImage = (idx) => {
    const newImages = [...images];
    if (idx > -1) {
      newImages.splice(idx, 1);
    }
    setImages(newImages);
    onChangeImage(newImages);
    setIndex(-1);
  };

  const openPdf = (url) => {
    const link = getFileLink(url);

    window.open(link, '_blank', 'fullscreen=yes');
    return false;
  };

  const onImageRemove = useCallback((_, index: number) => setIndex(index), []);

  return (
    <div style={{ width: fullWidth ? '100%' : 'auto' }}>
      <div>
        <div>
          <div className="form-group">
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{ paddingTop: 10, paddingBottom: 10, height: 'auto' }}
            >
              <input {...getInputProps()} />
              <span>
                <div className="text-center">
                  <div className="folder" />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 15,
                      ...dropzoneInnerStyle,
                    }}
                  >
                    <div className="text-dropzone">Drag & Drop Files</div>
                    <div className="text-dropzone-14"> or</div>
                    <Button variant="contained" disabled={disabled}>
                      Browse Files
                    </Button>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap" style={{ marginTop: 25 }}>
          {filesMode ? (
            <div>
              {images.map((url, indx) => {
                return (
                  <div key={indx} className="thumb-wrapper" style={{ cursor: 'pointer' }}>
                    <div className="thumb">
                      {onChangeImage && canRemove ? <div className="delete" onClick={() => setIndex(indx)} /> : null}
                      <img src={PDFicon} alt="pdf" onClick={() => openPdf(url)} />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <ImageViewer
              images={images.map((image) => new ImageWithAuthorHelper(image))}
              canRemoveImage={!!(onChangeImage && canRemove)}
              onRemoveImage={onChangeImage && canRemove ? onImageRemove : undefined}
              jobType={jobType}
              showImagesRow
              getDownloadedImageName={getDownloadedImageName}
            />
          )}
        </div>
      </div>
      <AppPaperModal
        modalId="remove-file-confirm-modal"
        open={index !== -1}
        onClose={() => setIndex(-1)}
        title={'Remove file'}
        submitButton={{
          onClick: () => removeImage(index),
          title: 'Confirm',
        }}
      >
        <p>Are you sure you want to remove the file?</p>
      </AppPaperModal>
    </div>
  );
};

export default memo(ImageUpload);
