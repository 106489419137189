import { Notifications } from 'types/Notifications';

import { AppAPI, NotificationsAPI } from 'Services/API';
import { createAppAsyncThunk } from 'Services/utils';
import { objectValuesFilter } from 'Utils/valueFilter';

export const getNotificationsList = createAppAsyncThunk('notifications/getNotifications', async (_, { getState }) => {
  const options = getState().notifications.search_options;
  return await NotificationsAPI.getAll(objectValuesFilter(options, [null, undefined]));
});

export const markNotificationsAsRead = createAppAsyncThunk(
  'notifications/markNotificationsAsRead',
  async (notification: Notifications.Notification, { getState }) => {
    return await NotificationsAPI.update(notification);
  }
);

export const deleteNotification = createAppAsyncThunk(
  'notifications/deleteNotification',
  async (id: number | string, { getState }) => {
    return await NotificationsAPI.delete(id);
  }
);

export const getCurrentUserNotifications = createAppAsyncThunk(
  'notifications/getCurrentUserNotifications',
  async () => {
    return await AppAPI.getCurrentUserNotifications();
  }
);
