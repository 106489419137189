import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ComplaintNote, Complaints } from 'Models/APITypes';

import { PagingComponent } from '../../Components';
import { ComplaintsTypeTab } from '../WorkerDetails';
import ComplaintItem from './ComplaintItem';
import ComplaintItemNote from './ComplaintItemNote';

type Props = {
  complaintsType: ComplaintsTypeTab;
  complaints: {
    job: Complaints[];
    worker: ComplaintNote[];
  };
};

const initPagination = {
  page: 0,
  per_page: 4,
};

const ComplaintsTab: FC<Props> = ({ complaints, complaintsType }) => {
  const [pagination, setPagination] = useState(initPagination);

  const complaintsList = useMemo(() => {
    return complaints[complaintsType].slice(
      pagination.page * pagination.per_page,
      pagination.page * pagination.per_page + pagination.per_page
    );
  }, [complaints, complaintsType, pagination]);

  const changePage = (_page: number) => {
    const page = _page ? _page - 1 : 0;
    setPagination((prev) => ({ ...prev, page }));
  };

  const showNoComplaintsMessage = useMemo(() => {
    return complaintsType === ComplaintsTypeTab.job ? !complaints.job.length : !complaints.worker.length;
  }, [complaints, complaintsType]);

  const noComplaintsLabelsMap = useMemo(
    () => ({
      [ComplaintsTypeTab.job]: 'No complains',
      [ComplaintsTypeTab.worker]: 'There are no complaints against the worker.',
    }),
    []
  );

  useEffect(() => {
    setPagination(initPagination);
  }, [complaintsType]);

  return (
    <>
      <Box p={2} flex={1} overflow={'auto'}>
        {complaintsList.map((complaint) => {
          if (complaintsType === ComplaintsTypeTab.job) {
            return <ComplaintItem key={complaint.job_id} complaint={complaint} />;
          }
          return (
            <div
              key={complaint.id}
              className="box-item-body job-history-page"
              style={{ padding: 0, marginBottom: 10, borderRadius: 16 }}
            >
              <ComplaintItemNote note={complaint} title="Worker complaint" />
            </div>
          );
        })}

        {showNoComplaintsMessage && (
          <Box
            display="flex"
            justifyContent="center"
            height={'100%'}
            alignItems="center"
            bgcolor={'#fff'}
            borderRadius={4}
          >
            <Typography variant="h6" color="textSecondary">
              {noComplaintsLabelsMap[complaintsType]}
            </Typography>
          </Box>
        )}
      </Box>
      {!showNoComplaintsMessage && (
        <div className="pagination-invoices">
          <PagingComponent
            activePage={pagination.page + 1}
            totalItemsCount={complaints[complaintsType].length}
            itemsCountPerPage={pagination.per_page}
            onChangePage={changePage}
          />
        </div>
      )}
    </>
  );
};

export default ComplaintsTab;
