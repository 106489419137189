import Immutable from 'seamless-immutable';

import Invoice from 'types/Invoices';

import { InvoiceStatus } from 'Constants/invoices';
import { DEPARTMENT_GROUPS } from 'Constants/user';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

export type ConfigurationInvoicesSearchOptions = {
  po?: string;
  conf?: string;
  id?: string;
  status?: InvoiceStatus;
};

const initialState = Immutable({
  headers: [],
  sources: [],
  configurations: [] as Invoice.Configuration[],
  timesheets: [],
  invoices: [] as Invoice.Item[],
  configuration_invoices: [] as Invoice.Item[],
  conf: null,
  timesheet_pagination: {
    page: 1 as string | number,
    total: 0,
    limit: 10 as string | number,
    order_by: 'id',
    order_by_type: true,
    timesheet_id: null,
    job_id: null,
  },
  configuration_pagination: { page: 1, totalPage: 0, total: 0, limit: 10 },
  invoices_pagination: {
    page: 1,
    total: 0,
    limit: 100,
    order_by: 'id',
    order_by_type: true,
  },
  invoices_search_options: {
    po: '',
    conf: '',
    id: '',
    status: '' as InvoiceStatus,
  } as ConfigurationInvoicesSearchOptions,
  invoices_config_id: 0,
  timesheets_invoice_id: 0,
  invoice: {
    departmentType: DEPARTMENT_GROUPS.CONSTRUCTION_SERVICE_GROUP,
    timesheets: [],
    startDate: undefined,
    endDate: undefined,
    id: 0,
    config_id: 0,
    status: '' as InvoiceStatus,
    /** '2021-01-01 00:00:00' */
    from_datetime: null,
    /** '2021-01-01 00:00:00' */
    to_datetime: null,
  },
  processing: false,
  processing_deactivate: false,
  get_hours_processing: false,
  revert_invoice_processing: false,
  download_invoice_processing: false,
  download_pos_processing: false,
  generate_invoice_processing: false,
  mark_timesheets_processing: false,
  remove_timesheet_processing: false,
  search_options: {
    page: 1,
    limit: 10,
    totalPage: 0,
    total: 0,
    startDate: '',
    finishDate: '',
    departments: [],
    billing_cycles: [],
    department_groups: [],
    job_types: [],
    order_by: 'id',
    order_by_type: false,
    invoice_id: '',
  } as InvoicesSearchOptions,
});

let filters;
let index;
let configs;

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    //get configurations
    case actionTypes.GET_CONFIGURATIONS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_CONFIGURATIONS_SUCCESS:
      return state.merge({
        processing: false,
        configurations: action.list,
        configuration_pagination: action.pagination,
        search_options: action.search_options,
      });
    case actionTypes.GET_CONFIGURATIONS_ERROR:
      return state.merge({
        processing: false,
      });

    //get invoices
    case actionTypes.GET_INVOICES_REQUEST:
      return state.merge({
        processing: true,
      });

    case actionTypes.GET_INVOICES_SUCCESS: {
      const invoices_pagination = { ...state.invoices_pagination, ...action.options };
      return state.merge({
        processing: false,
        invoices: action.list,
        invoices_pagination,
      });
    }

    case actionTypes.GET_INVOICES_ERROR:
      return state.merge({
        processing: false,
      });

    //get invoices for configuration
    case actionTypes.GET_CONFIGURATION_INVOICES_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_CONFIGURATION_INVOICES_SUCCESS:
      const options = { ...state.invoices_pagination, ...action.options };
      return state.merge({
        processing: false,
        configuration_invoices: action.list,
        invoices_pagination: options,
        invoices_config_id: action.id,
      });
    case actionTypes.GET_CONFIGURATION_INVOICES_ERROR:
      return state.merge({
        processing: false,
      });

    //get timesheets for invoice
    case actionTypes.GET_INVOICE_TIMESHEETS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_INVOICE_TIMESHEETS_SUCCESS:
      return state.merge({
        processing: false,
        timesheets: action.timesheets,
        invoice: action.invoice,
        timesheet_pagination: action.pagination,
        timesheets_invoice_id: action.invoice_id,
        invoices_config_id: action.config_id,
      });
    case actionTypes.GET_INVOICE_TIMESHEETS_ERROR:
      return state.merge({
        processing: false,
      });

    //get configuration
    case actionTypes.GET_CONFIGURATION_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_CONFIGURATION_SUCCESS:
      return state.merge({
        processing: false,
        conf: action.conf,
      });
    case actionTypes.GET_CONFIGURATION_ERROR:
      return state.merge({
        processing: false,
      });

    //update configuration
    case actionTypes.UPDATE_INVOICE_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.UPDATE_INVOICE_SUCCESS:
      index = state.configurations.findIndex((i) => i.id === action.item.id);
      if (index === -1) {
        return state;
      } else {
        configs = Immutable.asMutable(state.configurations);
        configs[index] = action.item;
        return state.merge({
          processing: false,
          configurations: configs,
          conf: null,
        });
      }
    case actionTypes.UPDATE_INVOICE_ERROR:
      return state.merge({
        processing: false,
      });

    //create invoice from config
    case actionTypes.CREATE_INVOICE_FROM_CONFIG_REQUEST:
      return state.merge({
        processing: true,
        generate_invoice_processing: true,
      });
    case actionTypes.CREATE_INVOICE_FROM_CONFIG_SUCCESS:
      configs = JSON.parse(JSON.stringify(state.configurations));
      const indx = configs.findIndex((i) => i.id === action.config.id);
      if (indx !== -1) {
        configs[indx] = action.config;
      }
      return state.merge({
        processing: false,
        generate_invoice_processing: false,
        configurations: configs,
      });
    case actionTypes.CREATE_INVOICE_FROM_CONFIG_ERROR:
      return state.merge({
        processing: false,
        generate_invoice_processing: false,
      });

    //delete configuration
    case actionTypes.DELETE_INVOICE_REQUEST:
      return state.merge({
        processing_deactivate: true,
      });
    case actionTypes.DELETE_INVOICE_SUCCESS:
      configs = state.configurations.filter((i) => i.id !== action.item.id);
      return state.merge({
        processing_deactivate: false,
        configurations: configs,
      });
    case actionTypes.DELETE_INVOICE_ERROR:
      return state.merge({
        processing_deactivate: false,
      });

    //mark timesheets as paid
    case actionTypes.MARK_TIMESHEET_AS_PAID_REQUEST:
      return state.merge({
        mark_timesheets_processing: true,
      });
    case actionTypes.MARK_TIMESHEET_AS_PAID_SUCCESS:
    case actionTypes.MARK_TIMESHEET_AS_PAID_ERROR:
      return state.merge({
        mark_timesheets_processing: false,
      });

    //download invoice
    case actionTypes.DOWNLOAD_INVOICE_REQUEST:
      return state.merge({
        download_invoice_processing: true,
        processing: true,
      });
    case actionTypes.DOWNLOAD_INVOICE_SUCCESS:
      return state.merge({
        download_invoice_processing: false,
        processing: false,
      });
    case actionTypes.DOWNLOAD_INVOICE_ERROR:
      return state.merge({
        download_invoice_processing: false,
        processing: false,
      });

    //download pos
    case actionTypes.DOWNLOAD_POS_REQUEST:
      return state.merge({
        download_pos_processing: true,
        processing: true,
      });
    case actionTypes.DOWNLOAD_POS_SUCCESS:
      return state.merge({
        download_pos_processing: false,
        processing: false,
      });
    case actionTypes.DOWNLOAD_POS_ERROR:
      return state.merge({
        download_pos_processing: false,
        processing: false,
      });

    //revert invoice
    case actionTypes.REVERT_INVOICE_REQUEST:
      return state.merge({
        revert_invoice_processing: true,
      });
    case actionTypes.REVERT_INVOICE_SUCCESS:
      return state.merge({
        revert_invoice_processing: false,
      });
    case actionTypes.REVERT_INVOICE_ERROR:
      return state.merge({
        revert_invoice_processing: false,
      });
    //update invoice status
    case actionTypes.UPDATE_INVOICE_STATUS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.UPDATE_INVOICE_STATUS_SUCCESS:
      return state.merge({
        processing: false,
        invoice: action.invoice,
      });
    case actionTypes.UPDATE_INVOICE_STATUS_ERROR:
      return state.merge({
        processing: false,
      });
    //assign more timesheets
    case actionTypes.ASSIGN_MORE_TIMESHEETS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.ASSIGN_MORE_TIMESHEETS_SUCCESS:
      return state.merge({
        processing: false,
      });
    case actionTypes.ASSIGN_MORE_TIMESHEETS_ERROR:
      return state.merge({
        processing: false,
      });

    case actionTypes.REMOVE_UNPAID_TIMESHEET_REQUEST:
      return state.merge({
        remove_timesheet_processing: true,
      });
    case actionTypes.REMOVE_UNPAID_TIMESHEET_SUCCESS:
    case actionTypes.REMOVE_UNPAID_TIMESHEET_ERROR:
      return state.merge({
        remove_timesheet_processing: false,
      });

    //update fillters
    case actionTypes.UPDATE_FILTERS:
      filters = {
        ...state.search_options,
        ...action.filters,
      };
      return state.merge({
        search_options: filters,
      });
    case actionTypes.UPDATE_INVOICES_OPTIONS:
      const invoices_pagination = { ...state.invoices_pagination, ...action.options };
      return state.merge({ invoices_pagination });
    case actionTypes.UPDATE_INVOICES_SEARCH_OPTIONS:
      return state.merge({
        invoices_search_options: {
          ...state.invoices_search_options,
          ...action.search_options,
        },
      });

    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}

export interface InvoicesSearchOptions {
  page: number | string;
  limit: number;
  totalPage: number;
  total: number;
  startDate: string;
  finishDate: string;
  departments: number[];
  billing_cycles: string[];
  department_groups: number[];
  job_types: number[];
  order_by: string;
  order_by_type: boolean;
  invoice_id: string;
}

export type InvoicesReducer = typeof initialState;
