import { useCallback, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button, Box } from '@mui/material';

import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import TimesheetFormContext from 'context/TimesheetEditContext';
import { useAppSelector } from 'createStore';

import styles from './InvoiceInfo.module.scss';

type Props = RouteComponentProps;

const InvoiceInfo = ({ history }: Props) => {
  const formikBag = useContext(TimesheetFormContext);
  const timesheet = formikBag.values;
  const canSeeInvoices = useAppSelector((state) => state.userPermissions.canSeePage.invoicing);

  const openInvoice = useCallback(() => {
    history.push(`/invoices/${timesheet?.invoice?.config_id}/${timesheet?.invoice?.id}`);
  }, [history?.push, timesheet?.invoice?.config_id, timesheet?.invoice?.id]);

  if (!timesheet.invoice || !canSeeInvoices) return;

  return (
    <TimesheetBox>
      <Box className={styles.invoiceBox}>
        <p>
          Assigned to invoice <strong>{timesheet.invoice.id}</strong>
        </p>
        <Button variant="contained" className={styles.viewInvoiceButton} onClick={openInvoice}>
          View Invoice
        </Button>
      </Box>
    </TimesheetBox>
  );
};

export default withRouter(InvoiceInfo);
