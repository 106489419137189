import { FC, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';

import { Settings } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Tab, Tabs } from '@mui/material';

import { ConedUser } from 'types/Common/User';

import { DatasetTestIDs } from 'Constants/tests';
import { actions } from 'Services';
import { AppAPI, UsersAPI } from 'Services/API';
import { LockSolidBold, UserSolidBold } from 'Utils/Icon';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import { useAppDispatch, useAppSelector } from 'createStore';

import { ProfileValidation } from './Profile.Validation';
import ProfileForm, { ProfileFormTabs, ProfileFormValues, ProfileFormVariant } from './components/ProfileForm';
import ProfileModalSkeleton from './components/ProfileModal.Skeleton';
import { NotificationsObject, getNotificationsArray } from './components/UserNotifications';

const newUserFormValues: ProfileFormValues = {
  avatar: null,
  croppedAvatar: null,
  avatar_url: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  departments: [],
  departmentGroups: [],
  roles: [],
  co_employee_id: '',
  password: '',
  repeatPassword: '',
  two_factor_auth_enabled: 0,
  notifications: null,
  initial_notifications: null,
  auth_sessions: [],
};

type ConedUserWithNotifications = ConedUser & { notifications: NotificationsObject };

type EditUserProfileModalProps = {
  modalId?: string;
  userID?: number | string;
  open: boolean;
  onClose: () => void;
};

const ProfileModal: FC<EditUserProfileModalProps> = ({ modalId = 'profile-modal', userID, open, onClose }) => {
  const { id: appUserID } = useAppSelector((state) => state.app.user);
  const isCurrentUser = Number(appUserID) === Number(userID);
  const [user, setUser] = useState<ConedUserWithNotifications>(null);

  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(ProfileFormTabs.PROFILE);
  const { inProcess: userLoading, promiseWrapper } = useProcessing();

  const changeTab = (event, value) => setActiveTab(value);

  const initialValues: ProfileFormValues = useMemo(() => {
    if (!user) return newUserFormValues;
    return {
      ...newUserFormValues,
      avatar_url: user.avatar,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      departments: user.departments,
      departmentGroups: user.departmentGroups,
      roles: user.roles,
      two_factor_auth_enabled: user.two_factor_auth_enabled,
      co_employee_id: user.co_employee_id,
      notifications: user.notifications,
      initial_notifications: user.notifications,
      auth_sessions: user.auth_sessions,
    };
  }, [user]);

  const createUserProfile = (values) => dispatch(actions.UsersActions.createUser(values));
  const updateUserProfile = (values) => dispatch(actions.WorkersActions.updateWorker(userID, values));

  const onSubmit = async (formValues: ProfileFormValues) => {
    try {
      const values = {
        ...formValues,
        departments: formValues.departments.map((department) => department.id),
        departmentGroups: formValues.departmentGroups.map((departmentGroup) => departmentGroup.id),
      };

      delete values.initial_notifications;
      delete values.avatar;
      delete values.croppedAvatar;
      delete values.co_employee_id;
      delete values.currentPassword;
      delete values.repeatPassword;
      delete values.notifications;

      if (formValues.croppedAvatar) {
        const { avatar_url } = await UsersAPI.uploadAvatar(formValues.croppedAvatar);
        values.avatar_url = avatar_url;
      }

      if (!userID) {
        await createUserProfile(values);
        showSuccessMessage('User successfully created');
        onClose();
        return;
      }

      if (isCurrentUser && formValues.password) {
        delete values.password;
        await AppAPI.updateCurrentUserPassword({
          password: formValues.password,
          currentPassword: formValues.currentPassword,
        });
      }

      await updateUserProfile(values);
      showSuccessMessage('Profile successfully updated');

      if (!isEqual(formValues.notifications, formValues.initial_notifications)) {
        await UsersAPI.updateUserNotifications(userID, getNotificationsArray(formValues.notifications));

        showSuccessMessage('Notifications successfully updated');
      }

      onClose();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const formikBag = useFormik<ProfileFormValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema: isCurrentUser ? ProfileValidation.editWithCurrentPassword : ProfileValidation.edit,
    onSubmit,
  });

  const profileModalTabs = [
    {
      value: ProfileFormTabs.PROFILE,
      title: 'Information',
      icon: <InfoIcon sx={{ width: 14, height: 14 }} />,
      error: Boolean(
        formikBag.errors.firstName ||
          formikBag.errors.lastName ||
          formikBag.errors.email ||
          formikBag.errors.phoneNumber ||
          formikBag.errors.departments ||
          formikBag.errors.departmentGroups
      ),
      datasetTestID: DatasetTestIDs.components.profileForm.tabs.information,
    },
    {
      value: ProfileFormTabs.PASSWORD,
      title: 'Password',
      icon: <LockSolidBold style={{ width: 14, height: 14 }} />,
      error: Boolean(formikBag.errors.password || formikBag.errors.repeatPassword),
      datasetTestID: DatasetTestIDs.components.profileForm.tabs.password,
    },
    {
      value: ProfileFormTabs.NOTIFICATIONS,
      title: 'Notification Settings',
      icon: <Settings sx={{ width: 14, height: 14 }} />,
      visible: Boolean(userID),
      datasetTestID: DatasetTestIDs.components.profileForm.tabs.notifications,
    },
    {
      value: ProfileFormTabs.LOGINS,
      title: 'Logins',
      icon: <UserSolidBold width={14} height={14} />,
      visible: !!formikBag.values.auth_sessions.length,
    },
  ];

  useEffect(() => {
    if (!userID || !open) return;
    promiseWrapper(Promise.all([UsersAPI.getById(userID), UsersAPI.getUserNotifications(userID)]))
      .then(([user, { notifications }]) => {
        setUser({ ...user, notifications });
      })
      .catch((error) => {
        setUser(null);
        showErrorMessage(error);
      });
  }, [userID, open]);

  return (
    <AppPaperModal
      containerStyle={{
        width: '100%',
        height: '100%',
        maxWidth: 568,
        maxHeight: 800,
      }}
      modalId={modalId}
      contentStyle={{
        display: 'flex',
        padding: '0 24px 24px 24px',
        width: '100%',
        height: '100%',
      }}
      title="Profile information"
      open={open}
      onClose={onClose}
      submitButton={{
        onClick: formikBag.submitForm,
        loading: formikBag.isSubmitting,
      }}
    >
      <form autoComplete="off" role="presentation" style={{ width: '100%', margin: 0 }}>
        <input type="text" name="email" style={{ display: 'none' }} />
        <input type="password" name="password" style={{ display: 'none' }} />
        <Box display="flex" flexDirection="column" flex={1} pb={2}>
          {userLoading ? (
            <ProfileModalSkeleton />
          ) : (
            <>
              <Tabs
                value={activeTab}
                onChange={changeTab}
                variant="scrollable"
                scrollButtons={false}
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 2,
                  '& .MuiTabs-flexContainer': { gap: '18px' },
                  borderBottom: '1px solid #E4E9EB',
                  mb: '24px',
                }}
              >
                {profileModalTabs.map(
                  ({ title, icon, value, error = false, visible = true, datasetTestID = {} }) =>
                    visible && (
                      <Tab
                        key={value}
                        sx={{
                          minHeight: 'auto',
                          padding: '14px 0',
                          textTransform: 'none',
                          fontFamily: 'Poppins Bold, system-ui, sans-serif',
                          fontSize: '13px',
                          opacity: activeTab === value ? 1 : 0.5,
                          color: error && 'error.main',
                        }}
                        value={value}
                        label={title}
                        icon={icon}
                        iconPosition="start"
                        {...datasetTestID}
                      />
                    )
                )}
              </Tabs>

              <ProfileForm
                containerProps={{
                  flex: 1,
                  sx: {
                    transition: 'opacity 0.15s ease-in-out',
                    pointerEvents: formikBag.isSubmitting ? 'none' : 'all',
                    opacity: formikBag.isSubmitting ? 0.7 : 1,
                  },
                }}
                formikBag={formikBag}
                activeTab={activeTab}
                showRolesSelect
                inputSize="small"
                variant={ProfileFormVariant.modal}
                isCurrentUser={isCurrentUser}
              />
            </>
          )}
        </Box>
      </form>
    </AppPaperModal>
  );
};

export default ProfileModal;
