import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { AppThunkDispatch } from 'types';

import { InvoiceStatus, InvoiceStatusLabels } from 'Constants/invoices';
import { updateInvoicesFilters } from 'Services/invoices/actions';
import { useAppSelector } from 'createStore';

import styles from './InvoicesSearch.module.css';

const SearchField = styled((props: TextFieldProps) => (
  <TextField variant="outlined" size="small" fullWidth InputLabelProps={{ shrink: true }} {...props} />
))({ '& .MuiInputBase-root': { backgroundColor: '#fff' }, maxWidth: 300 });

type Props = {
  configId: string | number;
};

const InvoicesSearch = ({ configId }: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { conf, po, id, status } = useAppSelector((state) => state.invoices.invoices_search_options);

  const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateInvoicesFilters(configId, { [name]: value }));
  };

  const handleChangeInvoiceStatus = (event: SelectChangeEvent<InvoiceStatus>, child: React.ReactNode) => {
    const status = event.target.value as InvoiceStatus;
    dispatch(updateInvoicesFilters(configId, { conf, po, id, status }));
  };

  return (
    <div className={styles.container}>
      <SearchField
        name="id"
        value={id}
        onChange={changeInput}
        label="Invoice #"
        placeholder="Search for Invoice by #"
      />
      <SearchField
        name="conf"
        value={conf}
        onChange={changeInput}
        label="Confirmation #"
        placeholder="Search Invoices by Conf #"
      />
      <SearchField name="po" value={po} onChange={changeInput} label="PO #" placeholder="Search Invoices by PO #" />
      <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
        <InputLabel>Status</InputLabel>
        <Select sx={{ backgroundColor: '#fff' }} value={status} label="Status" onChange={handleChangeInvoiceStatus}>
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {Object.entries(InvoiceStatusLabels).map(([status, label]) => (
            <MenuItem key={status} value={status}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default InvoicesSearch;
