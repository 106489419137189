// import actions
//import actionTypes
import * as AppActionTypes from './app/actionTypes';
import * as AppActions from './app/actions';
import * as BillingActionsTypes from './billing/actionTypes';
import * as BillingActions from './billing/actions';
import * as CommentsActionsTypes from './comments/actionTypes';
import * as CommentsActions from './comments/actions';
import * as GrouppedTimesheetsActionTypes from './grouppedTimesheets/actionTypes';
import * as GrouppedTimesheetsActions from './grouppedTimesheets/actions';
import * as InvoicesActionsTypes from './invoices/actionTypes';
import * as InvoicesActions from './invoices/actions';
import * as JobsActionTypes from './jobs/actionTypes';
import * as JobsActions from './jobs/actions';
import * as MapActionsTypes from './map/actionTypes';
import * as MapActions from './map/actions';
import * as NotesActionsTypes from './notes/actionTypes';
import * as NotesActions from './notes/actions';
import * as NotificationsActions from './notifications/actions';
import * as ReportActionsTypes from './reports/actionTypes';
import * as ReportActions from './reports/actions';
import * as SubcontractorsActionTypes from './subcontractors/actionTypes';
import * as SubcontractorsActions from './subcontractors/actions';
import * as SupervisorsActionTypes from './supervisors/actionTypes';
import * as SupervisorsActions from './supervisors/actions';
import * as TimesheetsActionTypes from './timesheets/actionTypes';
import * as TimesheetsActions from './timesheets/actions';
import * as UsersActionsTypes from './users/actionTypes';
import * as UsersActions from './users/actions';
import * as WorkersActionTypes from './workers/actionTypes';
import * as WorkersActions from './workers/actions';
import * as ZonesActions from './zones/actions';

export const actions = {
  AppActions,
  JobsActions,
  WorkersActions,
  SupervisorsActions,
  TimesheetsActions,
  GrouppedTimesheetsActions,
  SubcontractorsActions,
  ZonesActions,
  UsersActions,
  InvoicesActions,
  BillingActions,
  NotificationsActions,
  ReportActions,
  NotesActions,
  CommentsActions,
  MapActions,
};

export const ActionTypes = {
  AppActionTypes,
  JobsActionTypes,
  WorkersActionTypes,
  SupervisorsActionTypes,
  TimesheetsActionTypes,
  GrouppedTimesheetsActionTypes,
  SubcontractorsActionTypes,
  UsersActionsTypes,
  InvoicesActionsTypes,
  BillingActionsTypes,
  ReportActionsTypes,
  NotesActionsTypes,
  CommentsActionsTypes,
  MapActionsTypes,
};

export default { actions, ActionTypes };
