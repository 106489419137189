// const fileTypeRegExp = /.*\/(.*)/
const fileTypeRegExp = /\w*?\/?(?<type>(\w*)$)/;
export const downloadFileToPC = (url = '', name = '', type?: string) => {
  const link = document.createElement('a');
  link.href = url;
  const fileName = type ? `${name}.${fileTypeRegExp.exec(type).groups.type}` : name;
  link.download = fileName;
  link.click();
  link.remove();
};
