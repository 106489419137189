import { Avatar, Box, styled } from '@mui/material';

export const ChatItemAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ theme, isSelected }) => ({
  width: 35,
  height: 35,
  borderRadius: '12px',
  backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.secondary.light,
  color: isSelected ? 'white' : theme.palette.text.primary,
  fontFamily: 'Poppins Medium',
  fontSize: 13,
  transition: 'all 0.15s ease-in-out',
}));

export const ChatMessageAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'isConedSupervisor',
})<{
  isConedSupervisor?: boolean;
}>(({ theme, isConedSupervisor }) => ({
  width: 40,
  height: 40,
  borderRadius: '12px',
  fontFamily: 'Poppins Medium',
  fontSize: 14,
  lineHeight: '22px',
  backgroundColor: isConedSupervisor ? theme.palette.secondary.light : theme.palette.primary.main,
  color: isConedSupervisor ? theme.palette.text.primary : 'white',
  position: 'sticky',
  top: 0,
}));
