import React, { memo, useCallback, useMemo, useRef, useState } from 'react';

import moment from 'moment-timezone';
import { DateRangePicker } from 'react-date-range';
import { Range } from 'react-date-range';

import { ArrowRightAltRounded, CloseRounded } from '@mui/icons-material';
import { IconButton, Popover, Tooltip } from '@mui/material';

import s from './DatePicker.module.scss';

type Props = {
  numberMonth?: number;
  from_datetime: null | string;
  to_datetime: null | string;
  showClearDates?: boolean;
  onChange: (update: { from_datetime: string; to_datetime: string }) => void;
  style?: React.CSSProperties;
};

const DatePicker = ({
  numberMonth = 2,
  from_datetime,
  to_datetime,
  showClearDates = false,
  onChange = () => {},
  style = {},
}: Props) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const selectionName = 'selection';

  const fromDate = useMemo(
    () => (from_datetime && moment(from_datetime).isValid() ? moment(from_datetime) : null),
    [from_datetime]
  );
  const toDate = useMemo(
    () => (to_datetime && moment(to_datetime).isValid() ? moment(to_datetime) : null),
    [to_datetime]
  );
  const onUpdate = ({ selection: { startDate, endDate } }: { [selectionName]: Range }) => {
    const dateFormat = 'YYYY-MM-DDTHH:mm';
    const from_date = moment(startDate).format(dateFormat);
    const finish = moment(endDate);
    const to_date = finish.isSameOrAfter(from_date) ? finish?.format(dateFormat) : null;
    onChange({ from_datetime: from_date, to_datetime: to_date || to_datetime });
  };

  const openPopover = useCallback(() => setOpen(true), []);
  const closePopover = useCallback(() => setOpen(false), []);

  const dateFormat = 'MM/DD/YY';

  const clearDateRange = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onChange({ from_datetime: '', to_datetime: '' });
  }, []);

  return (
    <>
      <div ref={anchorRef} className={s.container} style={style} onClick={openPopover}>
        <span>{fromDate?.format(dateFormat) || dateFormat}</span>
        {showClearDates && (fromDate?.isValid() || toDate?.isValid()) ? (
          <Tooltip title="Clear date range">
            <IconButton size="small" sx={{ backgroundColor: 'white', margin: '0 4px' }} onClick={clearDateRange}>
              <CloseRounded />
            </IconButton>
          </Tooltip>
        ) : (
          <ArrowRightAltRounded style={{ margin: '0 4px' }} fontSize="small" />
        )}
        <span>{toDate?.format(dateFormat) || dateFormat}</span>
      </div>
      <Popover
        open={open}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorEl={anchorRef.current}
        onClose={closePopover}
        slotProps={{
          paper: {
            style: { overflow: 'auto' },
          },
        }}
      >
        <DateRangePicker
          ranges={[{ startDate: fromDate?.toDate(), endDate: toDate?.toDate(), key: selectionName }]}
          onChange={onUpdate}
          displayMode="dateRange"
          months={numberMonth}
          preventSnapRefocus
          showPreview
          direction="horizontal"
        />
      </Popover>
    </>
  );
};

export default memo(DatePicker);
