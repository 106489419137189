import { JobListItem } from 'Models/jobListItem';

import * as actionTypes from './actionTypes';

/*export function retrieve(): any {
  return function (dispatch, getState): any {
    try {
      dispatch({ type: actionTypes.GET_ZONES_REQUEST });
      HttpService.get(
        '/zones',
        {},
        (response) => {
          dispatch({
            type: actionTypes.GET_ZONES_SUCCESS,
            zones: response.zones,
            stats: response.stats,
          });
        },
        (error) => {
          dispatch({ type: actionTypes.GET_ZONES_FAIL });
        }
      );
    } catch (error) {
      dispatch({ type: actionTypes.GET_ZONES_ERROR });
    }
  };
}*/

export function setCurrentLocation(location): any {
  return function (dispatch, getState): any {
    dispatch({
      type: actionTypes.SET_CURRENT_LOCATION,
      currentLocation: location,
      jobLocation: {
        lat: location.Latitude,
        lng: location.Longtitude,
      },
    });
  };
}

export function selectJob(job: JobListItem) {
  if (job) {
    if (job.locations && job.locations.length > 0) {
      // eslint-disable-next-line
      let jobLocation = {
        lat: Number(job.locations[0].lat),
        lng: Number(job.locations[0].lon ? job.locations[0].lon : job.locations[0].lng),
      };
    }
    this.workerLocations = [...job.workers];
  } else {
    this.jobLocation = null;
    this.workerLocations = null;
  }
  this.selected = job;
}
