import { Box, styled } from '@mui/material';

const PageTitle = styled(Box)(({ theme }) => ({
  fontFamily: 'Poppins Medium',
  fontSize: 24,
  margin: '30px 0 20px 0',
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    margin: '20px 0 12px 0',
  },
}));

export default PageTitle;
