import { ImmutableArray } from 'seamless-immutable';

import { CalendarIcon, CancelIcon } from 'Utils/Icon';

import { JOB_STATUSES, JobStatus, JobType } from './job';
import { CESRoles, EROLES } from './user';

export const PAID_ONLY_STATUSES = [
  { label: 'Invoice Paid Only', value: 'paidOnly' },
  { label: 'Invoiced Only', value: 'invoicedOnly' },
  { label: 'Paid Worker Only', value: 'paidWorkerOnly' },
  { label: 'Not Invoiced Only', value: 'notInvoicedOnly' },
];

export const VERIFIED_STATUSES = [
  { label: 'CES Verified', value: 1 },
  { label: 'CES Unverified', value: 0 },
];

export const CE_VERIFIED_STATUSES = [
  { label: 'CE Verified', value: 1 },
  { label: 'CE Unverified', value: 0 },
];

export const JOB_TYPE_STATUSES = [
  { label: 'Flagging', value: JobType.Flagging },
  { label: 'Parking', value: JobType.Parking },
  { label: 'Signage', value: JobType.Signage },
];

export const JOB_STATUS_OPTIONS = [
  {
    value: JOB_STATUSES.New,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.New),
  },
  {
    value: JOB_STATUSES.InProgress,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.InProgress),
  },
  {
    value: JOB_STATUSES.Review,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.Review),
  },
  {
    value: [JOB_STATUSES.Completed, JOB_STATUSES.CancelledBillable],
    label: 'Completed & C/B',
  },
  {
    value: JOB_STATUSES.Completed,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.Completed),
  },
  {
    value: JOB_STATUSES.Billed,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.Billed),
  },
  {
    value: JOB_STATUSES.Paid,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.Paid),
  },
  {
    value: JOB_STATUSES.Cancelled,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.Cancelled),
  },
  {
    value: JOB_STATUSES.CancelledBillable,
    label: JobStatus.parsedFromNumber(JOB_STATUSES.CancelledBillable),
  },
];

export const PER_PAGES = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

export const SUBCONTRACTOR_CELL_STYLES = {
  maxWidth: 150,
  wordBreak: 'break-word',
} as React.CSSProperties;

export const TABLE_HEADER = [
  { label: '', value: 'selectAll', sortable: false, styles: { zIndex: 4 } },
  { label: 'Start Date', value: 'timesheets.start_at', sortable: true },
  { label: 'Finish Date', value: 'timesheets.finish_at', sortable: true },
  { label: 'Confirmation №', value: 'timesheets.job_id', sortable: true },
  { label: 'Shift Id', value: 'timesheets.job_worker_id', sortable: true },
  { label: 'Total Hrs', value: 'totalHours', sortable: true },
  { label: 'Workers', value: 'worker_name', sortable: true },
  { label: 'Subcontractor', value: 'companies.name', sortable: true, styles: SUBCONTRACTOR_CELL_STYLES },
  { label: 'PO#', value: 'jobs.po_number', sortable: true },
  { label: 'Job Status', value: 'timesheets.job_id', sortable: true },
  { label: 'Status', value: 'status', sortable: true },
  { label: 'Paid Status', value: 'paid', sortable: true },
  { label: 'CES Verified', value: 'timesheets.verified', sortable: true },
  { label: 'CE Verified', value: 'supervisor_verified', sortable: true },
  { label: 'Actions', value: 'actions' },
];

export const enum TimesheetStatuses {
  clocked_in = 'clocked_in',
  clocked_out = 'clocked_out',
  cancelled = 'cancelled',
}

export const TimesheetStatus = Object.freeze({
  clockedIn: TimesheetStatuses.clocked_in,
  clockedOut: TimesheetStatuses.clocked_out,
  cancelled: TimesheetStatuses.cancelled,

  toUserString: (status = '') => {
    const statusMap = {
      [TimesheetStatuses.clocked_in]: 'Clocked In',
      [TimesheetStatuses.clocked_out]: 'Clocked Out',
      [TimesheetStatuses.cancelled]: 'Cancelled',
    };

    return statusMap[status] || status;
  },

  getIcon: (status = '') => {
    const statusMap = {
      [TimesheetStatuses.clocked_in]: CalendarIcon,
      [TimesheetStatuses.clocked_out]: CalendarIcon,
      [TimesheetStatuses.cancelled]: CancelIcon,
    };

    return statusMap[status] || status;
  },

  getColor: (status = '') => {
    const statusMap = {
      [TimesheetStatuses.clocked_in]: 'text.secondary',
      [TimesheetStatuses.clocked_out]: 'primary.main',
      [TimesheetStatuses.cancelled]: 'text.secondary',
    };

    return statusMap[status] || status;
  },
});

export const canSeeCancelledTimesheets = (roles: EROLES[] | ImmutableArray<EROLES>) => {
  return roles.some((role) => CESRoles.includes(role));
};

export const TimesheetNames = {
  signatures: 'signatures',
  startDate: 'startDate',
  finishDate: 'finishDate',
  conEdisonTruck: 'conEdisonTruck',
  totalHours: 'totalHours',
  total_billable_hours: 'total_billable_hours',
  signatureName: 'signatureName',
  employeeNumber: 'employeeNumber',
  sign: 'sign',
  workerPaid: 'workerPaid',
  isVerified: 'isVerified',
  invoiced: 'invoiced',
  paid: 'paid',
  images: 'images',
  timesheetPdfs: 'timesheetPdfs',
  use_min_flagging_hours_limit: 'use_min_flagging_hours_limit',
};
