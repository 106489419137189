import { UsersAPI } from 'Services/API';

import { SEARCH_SUPERVISORS_ERROR, SEARCH_SUPERVISORS_REQUEST, SEARCH_SUPERVISORS_SUCCESS } from './actionTypes';

export function search(search_options): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SEARCH_SUPERVISORS_REQUEST });
      const response = await UsersAPI.getSupervisors(search_options);
      dispatch({
        type: SEARCH_SUPERVISORS_SUCCESS,
        supervisors: response.supervisors,
      });
      return response.supervisors;
    } catch (error) {
      dispatch({ type: SEARCH_SUPERVISORS_ERROR });
      throw error;
    }
  };
}
