import React, { useMemo } from 'react';

import moment from 'moment';

import { Tooltip, Typography } from '@mui/material';

import { ImageWithAuthor } from 'types/Location';

import { JobType } from 'Constants/job';
import { WStatus } from 'Constants/worker';
import { copyWithInfo } from 'Utils/copy';
import { openGoogleMapPlace } from 'Utils/links';
import { getWorketStatusString } from 'Utils/worker';
import { useAppSelector } from 'createStore';

import { ImageWithAuthorHelper } from './helpers';

type Props = { selectedImg: ImageWithAuthor; jobType: JobType | string };

const ImageTitleHeader = ({ selectedImg = new ImageWithAuthorHelper(''), jobType }: Props) => {
  const FileTypeStatuses = useAppSelector((state) => state.app.file_types);

  const FiltTypeStatusesMap = useMemo<Record<string, string[]>>(() => {
    if (!jobType) return {};
    const copy = [...FileTypeStatuses.asMutable({ deep: true })];
    return copy.reduce((acc, file) => {
      const currentStatuses = file.job_worker_statuses.split(',');
      return {
        ...acc,
        [file.id]: acc[file.id] ? [...acc[file.id], ...currentStatuses] : currentStatuses,
      };
    }, {});
  }, [FileTypeStatuses, jobType]);

  const uploadedBy =
    selectedImg?.user?.name && selectedImg?.created_at
      ? moment(selectedImg.created_at).format(`[Uploaded:] ddd, MMM, D [at] h:mm a [by ${selectedImg.user.name}]`)
      : '';

  const allFileStatuses = selectedImg?.file_type?.id !== 1 ? FiltTypeStatusesMap[selectedImg?.file_type?.id] || [] : [];
  const fileWithSecuredStatus = allFileStatuses.includes(WStatus.Secure);
  const fileWithCannotSecureStatus = allFileStatuses.includes(WStatus.CannotSecure);

  const placeLink =
    selectedImg?.lat && selectedImg?.lon ? (
      <>
        {' '}
        at{' '}
        <Tooltip
          title={
            <span>
              Open in a new tab in Google Maps or{' '}
              <strong
                style={{ fontSize: 12, padding: 5, cursor: 'pointer', textDecorationLine: 'underline' }}
                onClick={copyWithInfo(`${selectedImg.lat} ${selectedImg.lon}`, 'Copied to clipboard')}
              >
                Click here to Copy
              </strong>
            </span>
          }
        >
          <a href={openGoogleMapPlace({ lat: selectedImg.lat, lon: selectedImg.lon })} target="_blank" rel="noreferrer">
            {parseFloat(selectedImg.lat.toFixed(5))} {parseFloat(selectedImg.lon.toFixed(5))}
          </a>
        </Tooltip>
      </>
    ) : null;

  const placeStatus = allFileStatuses.length ? (
    <span
      style={{
        color: fileWithSecuredStatus ? '#0F0' : fileWithCannotSecureStatus ? '#F00' : '#fff',
        marginLeft: 4,
      }}
    >
      ({allFileStatuses.map((wStatus) => getWorketStatusString(wStatus, jobType)).join(', ')})
    </span>
  ) : null;

  return (
    <Typography color="white" component="span">
      {selectedImg?.title}
      {uploadedBy}
      {placeLink}
      {placeStatus}
      {!!selectedImg?.is_rejected && (
        <Typography pl={1} component="span" color="error">
          (Rejected)
        </Typography>
      )}
    </Typography>
  );
};

export default ImageTitleHeader;
