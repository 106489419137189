import { PropsWithChildren, forwardRef, memo, useState } from 'react';

import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

type Props = PropsWithChildren;

export type ZoomableImageRef = ReactZoomPanPinchRef;

const ZoomableImage = forwardRef<ZoomableImageRef, Props>(({ children }, ref) => {
  const [cursorStyle, setCursorStyle] = useState<'grab' | 'zoom-in' | 'zoom-out'>('zoom-in');
  const step = 0.1;

  return (
    <TransformWrapper
      initialScale={1}
      smooth={false}
      maxScale={7}
      minScale={1}
      wheel={{ step }}
      ref={ref}
      centerOnInit
      disablePadding
      centerZoomedOut={false}
    >
      <TransformComponent
        wrapperStyle={{
          width: '100%',
          height: '100%',
          cursor: cursorStyle,
        }}
        wrapperProps={{
          onMouseDown: () => {
            setCursorStyle('grab');
          },
          onMouseUp: () => {
            setCursorStyle('zoom-in');
          },
        }}
      >
        {children}
      </TransformComponent>
    </TransformWrapper>
  );
});

export default memo(ZoomableImage);
