import { connect, ConnectedProps } from 'react-redux';

import { FormikProps, withFormik } from 'formik';

import { AppThunkDispatch } from 'types';
import { CESWorker, WorkerTypeItem } from 'types/Common/User';

import AppPermissions from 'Constants/permissions';
import { actions } from 'Services';
import { selectUserIsSubcontractor } from 'Services/app/selectors';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import { ReduxState } from 'createStore';

import { WorkerValidation } from './WorkerValidation';
import WorkerForm, { CreateEditWorkerForm, getTime } from './components/WorkerForm';

type Props = ReduxProps;

const WorkerEditComponent = (props: Props & FormikProps<CreateEditWorkerForm>) => {
  return (
    <div style={{ flex: 1, overflow: 'auto' }}>
      <WorkerForm {...props} pageTitle="Edit Worker" buttonTitle="Save Changes" />
    </div>
  );
};

function mapStateToProps(state: ReduxState) {
  return {
    // FIX WORKER FORMAT FROM BACKEND AND USERS/WORKERS REDUCERS!!!!!
    worker: state.users.user.asMutable({ deep: true }) as CESWorker,
    canSeeSubcontractors: state.app.permissions.includes(AppPermissions.can_see_subcontractors),
    userIsSubcontractor: selectUserIsSubcontractor(state),
  };
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatch,
    updateWorker: (id, user) => dispatch(actions.WorkersActions.updateWorker(id, user)),
    uploadImages: (data) => dispatch(actions.WorkersActions.uploadImages(data)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const WorkerEdit = connector(
  withFormik<Props, CreateEditWorkerForm>({
    mapPropsToValues: ({ worker }) => ({
      avatar: worker?.avatar || '',
      firstName: worker?.firstName || '',
      lastName: worker?.lastName || '',
      email: worker?.email || '',
      phoneNumber: worker?.phoneNumber || '',
      subcontractorId: worker?.subcontractor_id || 0,
      workerTypes:
        // FIX THIS ON BACKEND!!!
        worker?.workerTypes?.map((type: WorkerTypeItem | number) => (typeof type === 'number' ? type : type.value)) ||
        [],
      password: '',
      address_zipcode: worker?.address_zipcode || '',
      ces_supervisor: worker?.ces_supervisor || 0,
      begin_time: getTime(worker?.begin_hour, worker?.begin_minute),
      end_time: getTime(worker?.end_hour, worker?.end_minute),
      two_factor_auth_enabled: worker?.two_factor_auth_enabled || 0,
    }),
    validationSchema: WorkerValidation,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const [begin_hour, begin_minute] = values.begin_time ? values.begin_time.split(':').map(Number) : [0, 0];
      const [end_hour, end_minute] = values.end_time ? values.end_time.split(':').map(Number) : [0, 0];
      const update = {
        ...values,
        ces_supervisor: Number(values.ces_supervisor),
        begin_hour,
        begin_minute,
        end_hour,
        end_minute,
        begin_time: undefined,
        end_time: undefined,
      };

      if (update.avatar) {
        if (typeof update.avatar !== 'string') {
          try {
            const { avatar_url } = await props.uploadImages(update.avatar);
            update.avatar = avatar_url;
          } catch (error) {
            showErrorMessage(error);
          }
        }
      } else {
        // WE NEED TO FIX THIS HELL ON BACKEND ASAP!!!
        update.avatar = 'null';
      }

      await props
        .updateWorker(props.worker.id, update)
        .then((res) => {
          if (res.status_code === 200) showSuccessMessage('Worker was updated!');
        })
        .catch((err) => {
          showErrorMessage(err || 'Something went wrong');
        })
        .finally(() => setSubmitting(false));
    },
  })(WorkerEditComponent)
);

export default WorkerEdit;
