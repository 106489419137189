import { FC, Fragment } from 'react';

import { ImmutableObject } from 'seamless-immutable';

import { Box, BoxProps, Table, TableBody, TableCell, TableRow } from '@mui/material';

import { DatasetTestIDs } from 'Constants/tests';
import { APP_API } from 'Services/API/AppAPI.service';
import { AppCheckbox } from 'components/AppCheckbox/AppCheckbox';

type Notification = {
  id: number;
  title: string;
  notify_email: 0 | 1;
  notify_web_push: 0 | 1;
};

export type NotificationsObject = {
  Jobs?: Notification[];
  Worker?: Notification[];
  Timesheet?: Notification[];
};

type Props = {
  containerProps?: BoxProps;
  notifications: NotificationsObject;
  onChange: (notifications: NotificationsObject) => void;
};

export const getNotificationsArray = (
  notificationObject: NotificationsObject | ImmutableObject<NotificationsObject> = {}
): APP_API.UpdateUserNotificationsParams =>
  Object.keys(notificationObject).reduce((all, current) => [...all, ...notificationObject[current]], []);

const UserNotifications: FC<Props> = ({ onChange: updateNotifications, notifications, containerProps = {} }: Props) => {
  const changeNotification =
    (group: keyof NotificationsObject) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const { name, id } = event.target;

      const updatedNotifications = {
        ...notifications,
        [group]: notifications[group]?.map((notification) => ({
          ...notification,
          [name]: notification.id === Number(id) ? Number(checked) : notification[name],
        })),
      };
      updateNotifications(updatedNotifications);
    };

  const changeAllNotifications = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = event.target;

    const updatedNotifications = Object.entries(notifications).reduce(
      (all, [group, notifications]) => ({
        ...all,
        [group]: notifications.map((notification) => ({
          ...notification,
          [name]: Number(checked),
        })),
      }),
      {} as NotificationsObject
    );

    updateNotifications(updatedNotifications);
  };

  if (!notifications) {
    return null;
  }

  return (
    <Box {...containerProps}>
      <Table
        sx={{
          '& td': {
            padding: '6px',
            border: 'none',
            whiteSpace: 'break-spaces',
          },
          '& .head': {
            '& td': {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
            '&:not(:first-of-type)': {
              '& td': {
                paddingTop: '20px',
              },
            },
          },
        }}
      >
        <TableBody>
          <TableRow sx={{ borderBottom: '1px solid #00000010' }}>
            <TableCell>Notification Settings</TableCell>
            <TableCell>
              <AppCheckbox
                name="notify_email"
                checked={Object.values(notifications).some((notifications) =>
                  notifications.some(({ notify_email }) => Boolean(notify_email))
                )}
                onChange={changeAllNotifications}
                {...DatasetTestIDs.components.profileForm.profileNotificationsTab.checkboxes.allEmailCheckbox}
              />
            </TableCell>
            <TableCell>
              <AppCheckbox
                name="notify_web_push"
                checked={Object.values(notifications).some((notifications) =>
                  notifications.some(({ notify_web_push }) => Boolean(notify_web_push))
                )}
                onChange={changeAllNotifications}
                {...DatasetTestIDs.components.profileForm.profileNotificationsTab.checkboxes.allWebCheckbox}
              />
            </TableCell>
          </TableRow>
          {Object.entries(notifications).map(([notificationKey, notifications]) => (
            <Fragment key={notificationKey}>
              <TableRow className="head">
                <TableCell>{notificationKey}</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Web/Push</TableCell>
              </TableRow>

              {notifications.map(({ id, title, notify_email, notify_web_push }) => (
                <TableRow key={id}>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <AppCheckbox
                      id={id.toString()}
                      checked={Boolean(notify_email)}
                      name="notify_email"
                      onChange={changeNotification(notificationKey as keyof NotificationsObject)}
                      {...DatasetTestIDs.components.profileForm.profileNotificationsTab.checkboxes.emailCheckbox}
                    />
                  </TableCell>
                  <TableCell>
                    <AppCheckbox
                      id={id.toString()}
                      checked={Boolean(notify_web_push)}
                      name="notify_web_push"
                      data-group={notificationKey}
                      onChange={changeNotification(notificationKey as keyof NotificationsObject)}
                      {...DatasetTestIDs.components.profileForm.profileNotificationsTab.checkboxes.webCheckbox}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default UserNotifications;
