import React, { Component } from 'react';

import mapboxgl from 'mapbox-gl';

import jobImage from 'Assets/map/ellipse.png';
import ceStructureImage from 'Assets/map/icon-barrier.png';
import workerFlagging from 'Assets/map/worker-flagging.png';
import workerImage from 'Assets/map/worker-image.png';

const mapImages = Object.freeze({
  parking: 'workerImg',
  flagging: 'workerFlagging',
  job: 'jobImg',
  ceStructure: 'ceStructureImg',
});

const mapSources = Object.freeze({
  workers: 'available_workers',
  locations: 'job_locations',
  ce_structures: 'ce_structures',
});

export const NewYorkCenter = [-73.935242, 40.73061];

export type MapBaseClickEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData;

class MapBase<P = {}, S = {}> extends Component<P, S> {
  map: mapboxgl.Map;

  mapContainer = React.createRef<HTMLDivElement>();

  Mapbox = mapboxgl;
  get source() {
    return mapSources;
  }
  get images() {
    return mapImages;
  }

  initMap = () => {
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/navigation-day-v1',
      center: NewYorkCenter as [number, number],
      zoom: 10,
      pitch: 0,
      bearing: 0,
      maxZoom: 19,
      dragRotate: false,
    });

    return new Promise<void>((resolve) => {
      this.map.on('load', () => {
        this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        this.map.loadImage(workerImage, (error, image) => {
          if (error) throw error;
          this.map.addImage(mapImages.parking, image);
        });

        this.map.loadImage(workerFlagging, (error, image) => {
          if (error) throw error;
          this.map.addImage(mapImages.flagging, image);
        });

        this.map.loadImage(jobImage, (error, image) => {
          if (error) throw error;
          this.map.addImage(mapImages.job, image);
        });

        this.map.loadImage(ceStructureImage, (error, image) => {
          if (error) throw error;
          this.map.addImage(mapImages.ceStructure, image, { sdf: true });
        });

        this.map
          .addSource(mapSources.workers, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          })
          .once('load', () => this.addWorkersSource());

        this.map
          .addSource(mapSources.locations, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          })
          .once('load', () => this.addLocationsSource());

        this.map
          .addSource(mapSources.ce_structures, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          })
          .once('load', () => this.addCeStructuresSource());

        this.map.addLayer({
          id: mapSources.locations,
          source: mapSources.locations,
          type: 'symbol',
          layout: {
            'icon-image': { type: 'identity', property: 'image' },
            'icon-size': 1,
            'icon-padding': 1,
            'icon-allow-overlap': true,
            'icon-rotate': { type: 'identity', property: 'heading' },

            'text-font': ['Lato Black'],
            'text-padding': 10,
            'text-anchor': 'bottom',
            'text-offset': [0, -1.5],
            'text-size': 10,
            'text-allow-overlap': true,
            'text-field': { type: 'identity', property: 'label' },
            'text-justify': 'center',
            'text-letter-spacing': 0.15,
          },
          paint: {
            'text-color': '#000000',
            'text-halo-color': '#fff',
            'text-halo-width': 1,
          },
        });
        this.map.addLayer({
          id: mapSources.workers,
          source: mapSources.workers,
          type: 'symbol',
          layout: {
            'icon-image': { type: 'identity', property: 'image' },
            'icon-size': 1,
            'icon-padding': 1,
            'icon-allow-overlap': true,
            'icon-rotate': { type: 'identity', property: 'rotation' },
            'text-font': ['Lato Black'],
            'text-padding': 10,
            'text-anchor': 'top',
            'text-offset': [0, 1],
            'text-size': 10,
            'text-allow-overlap': true,
            'text-field': { type: 'identity', property: 'title' },
            'text-justify': 'center',
            'icon-offset': [0, 0],
            'text-letter-spacing': 0.15,
          },
          paint: {
            'text-color': '#000000',
            'text-halo-color': '#fff',
            'text-halo-width': 1,
          },
        });
        this.map.addLayer({
          id: mapSources.ce_structures,
          source: mapSources.ce_structures,
          type: 'symbol',
          layout: {
            'icon-image': { type: 'identity', property: 'icon' },
            'icon-size': 0.06,
            'icon-allow-overlap': false,
          },
          paint: {
            'icon-color': { type: 'identity', property: 'iconColor' },
          },
        });

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        this.map.on('mouseenter', mapSources.workers, () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', mapSources.workers, () => {
          this.map.getCanvas().style.cursor = '';
        });

        this.map.on('mouseenter', mapSources.locations, () => {
          this.map.getCanvas().style.cursor = 'pointer';
        });

        this.map.on('mouseleave', mapSources.locations, () => {
          this.map.getCanvas().style.cursor = '';
        });

        this.map.on('mouseenter', mapSources.ce_structures, (e) => {
          this.map.getCanvas().style.cursor = 'pointer';
          const feature = (e.features as unknown as GeoJSON.FeatureCollection<GeoJSON.Point, {}>)[0];

          const coordinates = feature.geometry.coordinates.slice();
          const description = feature.properties.description;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
        });

        this.map.on('mouseleave', mapSources.ce_structures, () => {
          this.map.getCanvas().style.cursor = '';
          popup.remove();
        });

        this.map.on('click', mapSources.workers, (e) => {
          const feature = (e.features as unknown as GeoJSON.FeatureCollection<GeoJSON.Point, {}>)[0];
          if (!feature) return;
          this.map.flyTo({
            center: feature.geometry.coordinates,
          });
          this.handleDriverClick(e);
        });

        this.map.on('click', mapSources.locations, (e) => {
          const feature = (e.features as unknown as GeoJSON.FeatureCollection<GeoJSON.Point, {}>)[0];
          if (!feature) return;
          this.map.flyTo({
            center: feature.geometry.coordinates,
          });
        });

        this.map.on('click', mapSources.ce_structures, (e) => {
          const feature = (e.features as unknown as GeoJSON.FeatureCollection<GeoJSON.Point, {}>)[0];
          if (!feature) return;
          this.map.flyTo({
            center: feature.geometry.coordinates,
            zoom: 17,
          });
        });
        resolve();
      });
    });
  };

  updateMap = (prevId = 0, nextId = 0) => {
    if (nextId && !prevId) {
      this.map.resize();
      this.#addSources();
      this.fitMap();
      return;
    }
    if (prevId && nextId) {
      if (prevId !== nextId) {
        this.closePopup();
        this.map.resize();
        this.#addSources();
        this.fitMap();
        return;
      }
      this.updatePopup();
      this.map.resize();
      this.#addSources();
      return;
    }

    if (prevId && !nextId) this.#addSources();
  };

  #addSources = () => {
    this.addLocationsSource();
    this.addWorkersSource();
    this.addCeStructuresSource();
  };

  centerMap = (lat = NewYorkCenter[0], lng = NewYorkCenter[1]) => {
    try {
      this.map.flyTo({
        center: [lat, lng],
        zoom: 12,
      });
    } catch (error) {
      return;
    }
  };

  handleDriverClick = (e?: MapBaseClickEvent) => {};

  createWorkersFeatures = () => [];

  createJobsFeatures = () => [];

  createCeStructuresFeatures = () => [];

  addWorkersSource = () => {
    try {
      const available_drivers = this.map.getSource(this.source.workers) as mapboxgl.GeoJSONSource;
      if (available_drivers) {
        available_drivers.setData({
          type: 'FeatureCollection',
          features: this.createWorkersFeatures(),
        });
      }
    } catch (error) {
      return;
    }
  };

  addLocationsSource = () => {
    try {
      const locations_jobs = this.map.getSource(this.source.locations) as mapboxgl.GeoJSONSource;
      if (locations_jobs) {
        locations_jobs.setData({
          type: 'FeatureCollection',
          features: this.createJobsFeatures(),
        });
      }
    } catch (error) {
      return;
    }
  };

  addCeStructuresSource = () => {
    try {
      const ce_structures = this.map.getSource(this.source.ce_structures) as mapboxgl.GeoJSONSource;
      if (ce_structures) {
        ce_structures.setData({
          type: 'FeatureCollection',
          features: this.createCeStructuresFeatures(),
        });
      }
    } catch (error) {
      return;
    }
  };

  updatePopup = () => {};

  closePopup = () => {};

  fitMap = () => {};
}

export default MapBase;
