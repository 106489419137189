import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton, IconButtonProps, alpha } from '@mui/material';

import { JOB_STATUSES, JobStatusHelper, JobType, JobTypeHelper } from 'Constants/job';
import { CalendarIcon } from 'Utils/Icon';
import UserPermissions from 'Utils/PermissionsHelper';
import { useAppSelector } from 'createStore';

type Props = {
  confirmation: number;
  buttonProps?: IconButtonProps;
  jobType: JobType;
  jobStatus: JOB_STATUSES;
};

const MasterTimesheetOpenButton: FC<Props> = ({ confirmation, jobStatus, jobType, ...buttonProps }) => {
  const user = useAppSelector((state) => state.app.user);
  const history = useHistory();

  const canOpenMasterTimesheet = useMemo(() => {
    const userCanOpenMasterTimesheet = UserPermissions.has.can_open_master_timesheet;
    const { isParking } = new JobTypeHelper(jobType);

    if (!isParking || !userCanOpenMasterTimesheet) return false;

    const { isReview, isCompleted, isCancelledBillable } = new JobStatusHelper(jobStatus);

    return Boolean(isReview || isCompleted || isCancelledBillable);
  }, [jobStatus, jobType, user]);

  const openMasterTimesheet = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      history.push(`/timesheets/master?confirmation=${confirmation}&tab=information&signature_type=1`);
    },
    [confirmation, history]
  );

  if (!canOpenMasterTimesheet) {
    return null;
  }

  return (
    <IconButton
      onClick={openMasterTimesheet}
      size="small"
      sx={(theme) => ({
        width: 30,
        height: 30,
        flexShrink: 0,
        bgcolor: 'primary.light',
        borderRadius: '10px',
        color: 'primary.main',
        '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.2) },
      })}
      {...buttonProps}
    >
      <CalendarIcon />
    </IconButton>
  );
};

export default MasterTimesheetOpenButton;
