export const SUBCONTRACTOR_CELL_STYLES = {
  maxWidth: 150,
  wordBreak: 'break-word',
} as React.CSSProperties;

export enum BillingStatus {
  pending = 'timesheets_pending_approval',
  approved = 'coned_approved',
  verified = 'timesheets_verified',
  allowToBill = 'bill_allowed',
  invoiced = 'invoiced',
  billed = 'billed',
}

export const BillingStatusReadableNamesMap = {
  [BillingStatus.pending]: 'Timesheets pending for approval',
  [BillingStatus.verified]: 'Timesheets verified',
  [BillingStatus.approved]: 'Con-Ed Approved',
  [BillingStatus.invoiced]: 'Invoiced',
  [BillingStatus.billed]: 'Billed and Paid',
};

export const billingTimesheetsTableHead = [
  { label: 'Start Date & Time', value: 'billing.start_at', sortable: true },
  { label: 'Finish Date & Time  ', value: 'billing.finish_at', sortable: true },
  { label: 'Worker', value: 'billing.worker_id', sortable: true },
  { label: 'Subcontractor', value: 'companies.name', sortable: true },
  { label: 'Total Hrs', value: 'hrs' },
  { label: 'Paid Status', value: 'paid', sortable: true },
  { label: 'Verified', value: 'verified', sortable: true },
  { label: 'Invoice Status', value: 'billing.invoice_paid', sortable: true },
  { label: 'Total Due', value: 'total_due', sortable: true },
  { label: 'Pending', value: 'pending', sortable: true },
  { label: 'Actions', value: 'actions' },
];

export const BILLING_STATUS_OPTIONS = [
  { label: 'Pending Approval', value: BillingStatus.pending },
  { label: 'Coned Approved', value: BillingStatus.approved },
  { label: 'Timesheets Verified', value: BillingStatus.verified },
  // { label: 'Coned Disputed', value: 'coned_disputed' },
  { label: 'Invoiced', value: BillingStatus.invoiced },
  { label: 'Billed and Paid', value: BillingStatus.billed },
];

export const BILLING_CYCLE = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];
