import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import { AutoSizer, Index, List, ListRowRenderer } from 'react-virtualized';

import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import UserAvatar from 'Assets/avatar.png';
import { JobStatus, JobType } from 'Constants/job';
import { JobListItem } from 'Models/jobListItem';
import UserPermissions from 'Utils/PermissionsHelper';

import '../Job/joblist.scss';
import styles from './JobList.module.scss';

// interface Props {
//   jobs?: JobListItem[];
//   items?: Array<JobItem>;
//   onJobFocus: (po: number) => void;
//   selectJob: (job: JobListItem) => void;
//   onJobBlur: () => void;
//   active: number;
//   footer?: any;
//   isSelected?: Boolean;
//   selectedJob?: JobListItem;
// }

const JobList = ({ jobs, selectJob, selectedJob, isSelected }) => {
  const processing = useSelector((state: any) => state.jobs.processing);
  const history = useHistory();
  const userCanDoJobAction = UserPermissions.has.can_do_job_action;

  let timer = null;

  const onSingleClick = (job: JobListItem, fromMap: boolean) => {
    selectJob(job);
    /*if (isSelected !== undefined && isSelected === false)
        //setSelectedJobId(null);
      else {
        //setSelectedJobId(job.id);
      }*/
  };

  const onDoubleClick = (job: JobListItem) => {
    history.push(`/job/${job.id}`);
  };

  const onClick = (job: JobListItem, type = 'single', fromMap = false) => {
    if (timer) clearTimeout(timer);
    if (type === 'single') {
      timer = setTimeout(() => onSingleClick(job, fromMap), 300);
    } else {
      onDoubleClick(job);
    }
  };

  const createJob = () => {
    history.push('/job/create');
  };

  // const getJobStatus = (job) => {
  //     const notifcation = notificationStore.notification;
  //     if (notifcation) {
  //         switch (notifcation.notifiableType) {
  //           case NOTIFIABLE_TYPES.CREATE_JOB:
  //           case NOTIFIABLE_TYPES.CANCEL_JOB:
  //           case NOTIFIABLE_TYPES.ASSIGN_JOB:
  //           case NOTIFIABLE_TYPES.WORKER_EN_ROUTE:
  //           case NOTIFIABLE_TYPES.WORKER_ON_LOCATION:
  //           case NOTIFIABLE_TYPES.WORKER_SECURED_SITE:
  //           case NOTIFIABLE_TYPES.WORKER_UPLOAD_AN_IMAGE:
  //           case NOTIFIABLE_TYPES.WORKER_ENDED_SHIFT:
  //           case NOTIFIABLE_TYPES.EDIT_JOB:
  //           case NOTIFIABLE_TYPES.PO_NUMBER_HAS_BEEN_ADDED:
  //             if ((notifcation.notifiableRecord as JobListItem).id === job.id) {
  //               return Number(
  //                 (notifcation.notifiableRecord as JobListItem).jobStatus
  //               );
  //             }
  //           default:
  //             return job.jobStatus;
  //         }
  //       }
  //   };

  const jobSingleClick = useCallback(
    ({ currentTarget: { dataset } }: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      onClick(jobs[parseInt(dataset?.index)]),
    [jobs]
  );

  const jobDoubleClick = useCallback(
    ({ currentTarget: { dataset } }: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      onClick(jobs[parseInt(dataset?.index)], 'double'),
    [jobs]
  );

  const jobIdClick = useCallback(
    ({ currentTarget: { dataset } }: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      history.push(`/job/${jobs[parseInt(dataset?.index)].id}`),
    [jobs]
  );

  const listRowRenderer = useCallback<ListRowRenderer>(
    ({ index, style }) => {
      const job = jobs[index];

      const jobAddresses: string = Array.isArray(job.locations)
        ? job.locations.map((loc) => loc.address).join(', ')
        : '';
      return (
        <div style={style} key={job.id}>
          <div
            id={job.id}
            onDoubleClick={jobDoubleClick}
            onClick={jobSingleClick}
            data-index={index}
            className={`job-item ${selectedJob && selectedJob.id === job.id ? 'job-active' : ''}`}
          >
            <button className="job-component">
              <div className="header">
                <div className="header-title">
                  <span className="title mr-5px">{JobType[job.jobType]}</span>
                  <span className={`title ${styles.job_id}`} data-index={index} onClick={jobIdClick}>
                    #{job.uid}
                  </span>
                </div>
                <div className="d-flex">
                  <div className="text-right">
                    <div className="job-status">{JobStatus.parsedFromNumber(Number(job.jobStatus))}</div>
                  </div>
                </div>
              </div>

              <div className="content">
                <div style={{ display: 'flex', textAlign: 'left', flexDirection: 'column' }}>
                  <div style={{ maxHeight: 3 * 21, overflowY: 'auto', scrollbarWidth: 'none' }}>{jobAddresses}</div>
                  <div>
                    <span className="request-date-time-title">Request Date/Time</span>
                    <div className="request-date-time">
                      <span>{moment(job.requestTime).format('MM/DD/YYYY hh:mm A')}</span>
                    </div>
                  </div>
                </div>
                <div className="job-worker ml-3">
                  {Array.isArray(job.workers) ? (
                    <div className="worker-box" style={{ zIndex: job.workers.length }}>
                      <div className="worker-box-round">
                        <span className="worker-total"> {job.workers.length}</span>
                      </div>
                    </div>
                  ) : null}
                  {job.workers[0] && (
                    <div className="worker-box" style={{ zIndex: 2 }} key={job.workers[0]?.id}>
                      <img className="worker-img  avatar" alt="avatar" src={UserAvatar} />
                    </div>
                  )}
                </div>
              </div>
            </button>
          </div>
        </div>
      );
    },
    [jobs]
  );

  const calculateRowHeight = ({ index }: Index) => {
    const job = jobs[index];
    const addressLength = job.locations?.map((loc) => loc.address).join(', ').length;

    const addressRowsCount = Math.floor(addressLength / 55);
    const addressHeight = addressRowsCount < 3 ? addressRowsCount * 21 : 2 * 21;
    return 152 + addressHeight;
  };

  return (
    <>
      {processing ? <LinearProgress color="primary" /> : null}
      <div className={`job-list-box ${styles['side-bar']}`}>
        <div style={{ height: '100%' }}>
          <div style={{ height: '100%' }}>
            {/* @ts-ignore */}
            <AutoSizer className="auto-sizer">
              {({ height, width }) => (
                // @ts-ignore
                <List
                  rowCount={jobs.length}
                  rowRenderer={listRowRenderer}
                  width={width}
                  rowHeight={calculateRowHeight}
                  height={height}
                />
              )}
            </AutoSizer>
          </div>
        </div>
        {userCanDoJobAction && (
          <div className="left-item-body bottom-create">
            <Button
              variant="contained"
              style={{
                overflow: 'hidden',
                marginRight: 10,
                width: '60%',
              }}
              onClick={createJob}
              disableRipple
            >
              Create Job
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default JobList;
