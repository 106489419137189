import { JobType, JobTypeHelper } from '../Constants/job';
import { WorkerStatus, WStatus } from '../Constants/worker';
import { ConEdWorkerForTable } from '../types/Common/User';

export const workerStatusConvertMap = Object.freeze({
  [WorkerStatus.Assigned]: WStatus.Assigned,
  [WorkerStatus.EnRoute]: WStatus.EnRoute,
  [WorkerStatus.OnLocation]: WStatus.OnLocation,
  [WorkerStatus.Secure]: WStatus.Secure,
  [WorkerStatus.Review]: WStatus.Review,
  [WorkerStatus.CanNotSecure]: WStatus.CannotSecure,
  [WorkerStatus.Completed]: WStatus.Completed,
  [WorkerStatus.CrewArrived]: WStatus.CrewArrived,
});

export const getWorketStatusString = (key: string | number, jobType: string | number) => {
  let status = key;
  if (typeof key === 'number') {
    status = workerStatusConvertMap[status];
  }
  if (typeof status === 'string') {
    switch (status) {
      case WStatus.Assigned:
        return 'Assigned';
      case WStatus.EnRoute:
        return 'En Route';
      case WStatus.OnLocation:
        return 'On Location';
      case WStatus.Secure:
        return new JobTypeHelper(jobType).isFlagging ? 'Ready For Crew' : 'Secured';
      case WStatus.Review:
        return 'Review';
      case WStatus.CannotSecure:
        return 'Cannot Secure';
      case WStatus.Completed:
        return 'Review Finished';
      case WStatus.CrewArrived:
        return 'Crew Arrived';
      default:
        return status;
    }
  }
  return JSON.stringify(status);
};

export const getFilteredWorkersWithOptions = (
  workers: ConEdWorkerForTable[] = [],
  options = {
    workerType: 0 as JobType,
    status: '',
    subcontractorId: 0,
    query: '',
    order_by: '',
    order_by_type: false,
  }
) => {
  const { workerType, status, subcontractorId, query, order_by, order_by_type } = options;
  const filtered = workers
    .filter((worker) => {
      const { name, email, phoneNumber, id } = worker;

      const haveType = workerType ? worker.workerTypes.includes(workerType) : true;
      const haveStatus = status ? worker.status.toLowerCase() === status.toLowerCase() : true;
      const haveSubcontractor = subcontractorId ? worker.subcontractorId === subcontractorId : true;

      const haveQuery = query
        ? [name, email, phoneNumber, id.toString()].some((v) => v.toLowerCase().includes(query.toLowerCase()))
        : true;

      return haveType && haveStatus && haveSubcontractor && haveQuery;
    })
    .sort((first, second) => {
      if (order_by === 'app_versions') {
        const a = Number(first[order_by].split('.').join(''));
        const b = Number(second[order_by].split('.').join(''));
        return order_by_type ? a - b : b - a;
      }
      let a = first[order_by];
      let b = second[order_by];

      if (!a) {
        a = '';
      }

      if (!b) {
        b = '';
      }

      if (a < b) {
        return order_by_type ? -1 : 1;
      }

      if (a > b) {
        return order_by_type ? 1 : -1;
      }
      return 0;
    });

  return filtered;
};

export function clipArrayPerPage<T>(workers: T[] = [], { page = 1, perPage = 10 }): T[] {
  const firstIndex = (page - 1) * perPage;
  const lastIndex = firstIndex + perPage;
  return workers.slice(firstIndex, lastIndex);
}
