import { Note } from 'types/Notes';

import ApiService from 'Services/HttpService';

class NotesAPI {
  getAll = (
    options = {
      page: 1,
      per_page: 1000,
      entity_type: '',
      entity_id: 0,
      sort_by: '',
      sort_by_type: true,
    }
  ) => {
    return ApiService.GET<{ notes: { data: Note[] } }>(`/notes`, options);
  };

  create = ({ entity_type = '', entity_id = 0, category = '', body = '' }) => {
    return ApiService.POST('/notes', { entity_type, entity_id, category, body });
  };

  // NOT USING ANYWHERE
  update = (id: string, data: any) => {
    return ApiService.POST(`/notes/${id}`, data);
  };
}

export default new NotesAPI();
