import { AppAPI } from 'Services/API';

import * as actionTypes from './actionTypes';

export function retrieve(): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_ZONES_REQUEST });
      const response = await AppAPI.getZones();
      dispatch({
        type: actionTypes.GET_ZONES_SUCCESS,
        zones: response.zones,
        stats: response.stats,
      });
    } catch (error) {
      dispatch({ type: actionTypes.GET_ZONES_ERROR });
    }
  };
}
