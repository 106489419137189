import { DetailedHTMLProps, HTMLAttributes, MouseEventHandler } from 'react';

import { Button, Tooltip, TooltipProps, createSvgIcon } from '@mui/material';

import { ReactComponent as CanceledIcon } from 'Assets/icons/rule-cancelled-svgrepo-com.svg';

const CanceledTimesheetsIcon = createSvgIcon(<CanceledIcon />, 'CanceledTimesheetsIcon');

const CancelledTimesheetButton: React.FC<{
  active?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  divProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  tooltipProps?: TooltipProps;
}> = ({ active = false, onClick = () => {}, divProps = {}, tooltipProps = {} }) => {
  return (
    <Tooltip
      disableInteractive
      title={
        <span>
          {active ? 'Hide' : 'Show'} cancelled timesheets <b>({active ? 'Showed' : 'Hid'})</b>
        </span>
      }
      aria-label="import"
      arrow
      {...tooltipProps}
    >
      <div {...divProps}>
        <Button onClick={onClick} variant={active ? 'contained' : 'outlined'}>
          <CanceledTimesheetsIcon />
        </Button>
      </div>
    </Tooltip>
  );
};

export default CancelledTimesheetButton;
