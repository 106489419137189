export const APP_INIT = 'app.init';
export const APP_INIT_FINISHED = 'app.init_finished';

// GET ACCOUNT
export const TOKEN_SUCCESS = 'app.token_success';
export const LOGOUT = 'logout';

// GET ACCOUNT
export const GET_ACCOUNT_REQUEST = 'app.get_account_request';
export const GET_ACCOUNT_SUCCESS = 'app.get_account_success';
export const GET_ACCOUNT_ERROR = 'app.get_account_error';

// ACTIVATE ACCOUNT
export const ACTIVATE_ACCOUNT_REQUEST = 'app.activate_account_request';
export const ACTIVATE_ACCOUNT_SUCCESS = 'app.activate_account_success';
export const ACTIVATE_ACCOUNT_ERROR = 'app.activate_account_error';

// GET ROLES
export const GET_ROLES_REQUEST = 'app.get_roles_request';
export const GET_ROLES_SUCCESS = 'app.get_roles_success';
export const GET_ROLES_ERROR = 'app.get_roles_error';

// GET PERMISIONS
export const GET_PERMISSIONS_REQUEST = 'app.get_permissions_request';
export const GET_PERMISSIONS_SUCCESS = 'app.get_permissions_success';
export const GET_PERMISSIONS_ERROR = 'app.get_permissions_error';

// GET DEPARTMENTS
export const GET_DEPARTMENTS_REQUEST = 'app.get_departments_request';
export const GET_DEPARTMENTS_SUCCESS = 'app.get_departments_success';
export const GET_DEPARTMENTS_ERROR = 'app.get_departments_error';

// GET DEPARTMENT GROUPS
export const GET_DEPARTMENT_GROUPS_REQUEST = 'app.get_department_groups_request';
export const GET_DEPARTMENT_GROUPS_SUCCESS = 'app.get_department_groups_success';
export const GET_DEPARTMENT_GROUPS_ERROR = 'app.get_department_groups_error';

// LOGIN
export const LOGIN_REQUEST = 'app.LOGIN_request';
export const LOGIN_SUCCESS = 'app.LOGIN_success';
export const LOGIN_ERROR = 'app.LOGIN_error';

// USER
export const UPDATE_NOTIFICATIONS_SUCCESS = 'app.update_notifications_success';
export const UPDATE_NOTIFICATIONS_REQUEST = 'app.update_notifications_request';
export const UPDATE_NOTIFICATIONS_ERROR = 'app.update_notifications_error';

export const UPDATE_USER_SUCCESS = 'app.update_user_success';
export const UPDATE_USER_REQUEST = 'app.update_user_request';
export const UPDATE_USER_ERROR = 'app.update_user_error';

export const GET_NOTIFICATIONS_REQUEST = 'app.get_notifications_request';
export const GET_NOTIFICATIONS_SUCCESS = 'app.get_notifications_success';
export const GET_NOTIFICATIONS_ERROR = 'app.get_notifications_error';

// GET FILE TYPES
export const GET_FILE_TYPES_REQUEST = 'app.get_file_types_request';
export const GET_FILE_TYPES_SUCCESS = 'app.get_file_types_success';
export const GET_FILE_TYPES_ERROR = 'app.get_file_types_error';

export const SOCKET_NOTIFICATIONS = 'socket.notifications';

export const CHANGE_DISPATCH_PAGE_CONFIG = 'app.change_dispatch_page_config';
