export const GET_COMMENTS_REQUEST = 'comments.get_comments_request';
export const GET_COMMENTS_SUCCESS = 'comments.get_comments_success';
export const GET_COMMENTS_FAIL = 'comments.get_comments_fail';
export const GET_COMMENTS_ERROR = 'comments.get_comments_error';

export const DELETE_COMMENTS_REQUEST = 'comments.delete_comments_request';
export const DELETE_COMMENTS_SUCCESS = 'comments.delete_comments_success';
export const DELETE_COMMENTS_FAIL = 'comments.delete_comments_fail';
export const DELETE_COMMENTS_ERROR = 'comments.delete_comments_error';

export const UPDATE_COMMENTS_REQUEST = 'comments.update_comments_request';
export const UPDATE_COMMENTS_SUCCESS = 'comments.update_comments_success';
export const UPDATE_COMMENTS_FAIL = 'comments.update_comments_fail';
export const UPDATE_COMMENTS_ERROR = 'comments.update_comments_error';

export const CREATE_COMMENTS_REQUEST = 'comments.create_comments_request';
export const CREATE_COMMENTS_SUCCESS = 'comments.create_comments_success';
export const CREATE_COMMENTS_FAIL = 'comments.create_comments_fail';
export const CREATE_COMMENTS_ERROR = 'comments.create_comments_error';
