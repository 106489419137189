import { FC, useEffect, useMemo, useRef, useState } from 'react';

import ReactSignatureCanvas from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';

import { Box, Typography, useTheme } from '@mui/material';

import { SignaturePlaceholderIcon } from 'Utils/Icon';

type Props = {
  value: string;
  onChange: (data: string) => void;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
};

const parseSignBase64 = (value: string) => {
  if (!value) return '';
  const { data, type } = JSON.parse(value) as { data: string; type: string };
  return `data:${type};base64,${data}`;
};

const SignatureBox: FC<Props> = ({ value, onChange, disabled, error, helperText }) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(Boolean(value));
  const signCanvas = useRef<ReactSignatureCanvas>(null);
  const canvasContainer = useRef<HTMLDivElement>(null);
  const currentSignatureBase64 = useRef<string>('');

  const onStartSignature = () => {
    setIsActive(true);
  };

  const onEndSignature = () => {
    if (signCanvas.current.isEmpty()) return;
    currentSignatureBase64.current = signCanvas.current.toDataURL();
    const data = currentSignatureBase64.current.replace('data:image/png;base64,', '');
    const signatureData = {
      data,
      type: 'image/jpeg',
      name: 'photo.jpg',
    };

    onChange(JSON.stringify(signatureData));
  };

  const signatureBoxBorder = useMemo(() => {
    if (error) {
      return `2px solid ${theme.palette.error.main}`;
    }

    if (disabled) {
      return '1px solid #dcdcdc';
    }

    if (isActive) {
      return `2px solid ${theme.palette.primary.main}`;
    }

    return `1px dashed ${theme.palette.primary.main}`;
  }, [disabled, isActive, error, value]);

  useEffect(() => {
    const canvasResized = () => {
      if (!signCanvas.current) return;

      const { width, height } = canvasContainer.current.getBoundingClientRect();
      signCanvas.current.fromDataURL(currentSignatureBase64.current, {
        width,
        height,
        ratio: 1,
      });
    };
    window.addEventListener('resize', canvasResized);
    return () => {
      window.removeEventListener('resize', canvasResized);
    };
  }, []);

  useEffect(() => {
    if (value) setIsActive(true);
    if (!signCanvas.current) return;
    signCanvas.current.clear();
    const { width, height } = canvasContainer.current.getBoundingClientRect();
    signCanvas.current.fromDataURL(parseSignBase64(value), { width, height, ratio: 1 });
    currentSignatureBase64.current = parseSignBase64(value);
  }, [value]);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        ref={canvasContainer}
        width="100%"
        sx={{
          position: 'relative',
          border: signatureBoxBorder,
          borderRadius: '8px',
          aspectRatio: 3 / 1,
          overflow: 'hidden',
          backgroundColor: theme.palette[isActive ? 'secondary' : 'primary'].light,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <SignatureCanvas
          clearOnResize
          penColor="black"
          ref={signCanvas}
          onEnd={onEndSignature}
          onBegin={onStartSignature}
          canvasProps={{
            style: {
              width: '100%',
              height: '100%',
              pointerEvents: disabled ? 'none' : 'auto',
              opacity: disabled ? 0.5 : 1,
            },
          }}
        />
        {!isActive && (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            top={0}
            left={0}
            sx={{
              pointerEvents: 'none',
            }}
          >
            <SignaturePlaceholderIcon />
          </Box>
        )}
      </Box>
      {helperText && (
        <Typography variant="caption" color={error ? 'error' : 'text.secondary'} pl={2}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default SignatureBox;
