import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { JobListItem } from 'Models/jobListItem';
import { JobsAPI } from 'Services/API';

import { PagingComponent } from '../../Components';
import JobItemSchedule from '../../Job/JobItemSchedule';

class AppointedJob extends Component<any> {
  state = {
    jobs: [] as JobListItem[],
    options: { page: 1, totalPage: 1, total: 0, limit: 0 },
  };
  componentDidMount = () => {
    this.fetchWorkerJobs();
  };

  fetchWorkerJobs = () => {
    const { id } = this.props.match.params;
    if (!id) return;
    this.getJobsList({ workerId: id });
  };

  changedPage = (page: number) => {
    const { id } = this.props.match.params;
    if (!id) return;
    this.getJobsList({ workerId: id, page });
  };

  async getJobsList(params: any = {}) {
    if (params.jobStatus && params.jobStatus.length === 0 && !params.search) {
      params.jobStatus = [0, 1, 4, 5];
    }
    const { results, page, totalPage, total, limit } = await JobsAPI.getJobs(params);
    if (!results) {
      this.setState({ jobs: [] });
      return;
    }

    this.setState({ jobs: results, options: { page, totalPage, total, limit } });
  }

  render() {
    return (
      <>
        <div
          style={{
            overflowY: 'auto',
            marginLeft: -20,
            marginRight: -20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
          }}
        >
          {this.state.jobs.map((job) => (
            // @ts-ignore TODO: Check and fix types for Job Details and Job List/Job Grid
            <JobItemSchedule key={job.id} selectable={false} job={job} index={0} />
          ))}
        </div>
        <div className="pagination-invoices">
          <PagingComponent
            totalItemsCount={this.state.options.total}
            onChangePage={this.changedPage}
            itemsCountPerPage={this.state.options.limit}
          />
        </div>
      </>
    );
  }
}

export default withRouter(AppointedJob);
