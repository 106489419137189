import { FC } from 'react';

import { Box, Skeleton } from '@mui/material';

import { repeatSkeleton } from 'Utils/skeleton';

import styles from './NotificationsFilter.module.scss';

const NotificationsFiltersButtonSkeleton = () => {
  return (
    <Box className={styles.notificationsFilterButton} p={0}>
      <Skeleton variant="text" height={36} />
    </Box>
  );
};

const NotificationsFiltersGroupSkeleton = () => {
  return (
    <Box className={styles.notificationsFilterGroup} p={0}>
      <Skeleton variant="rectangular" height={26} />
      <Box display="flex" flexDirection="column">
        {repeatSkeleton(15, (index) => (
          <NotificationsFiltersButtonSkeleton key={index} />
        ))}
      </Box>
    </Box>
  );
};

const NotificationsFiltersSkeleton: FC = () => {
  return (
    <Box className={styles.notificationsFilterBox}>
      <Skeleton variant="rounded" height={130} />

      <Box className={styles.notificationsFilterGroupsList}>
        {repeatSkeleton(3, (index) => (
          <NotificationsFiltersGroupSkeleton key={index} />
        ))}
      </Box>
    </Box>
  );
};

export default NotificationsFiltersSkeleton;
