import { FC, useState } from 'react';

import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';

import { JobsAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import { AppSelect } from 'components/AppSelect/AppSelect';

enum BillingType {
  RegularJob,
  EmergencyJob,
}

type Props = {
  jobID: number | string;
  isEmergency: boolean;
  onSuccessfulChange?: () => void;
};

const BillingTypeSelect: FC<Props> = ({ jobID, isEmergency: initialIsEmergency, onSuccessfulChange = () => {} }) => {
  const confirmationModal = useModal();
  const [isEmergency, setIsEmergency] = useState(initialIsEmergency);
  const { inProcess, promiseWrapper } = useProcessing();

  const closeConfirmationModal = (withResetEmergency = false) => {
    if (withResetEmergency) {
      setIsEmergency(initialIsEmergency);
    }
    confirmationModal.close();
  };

  const handleChangeEmergency = ({ target: { value } }: SelectChangeEvent<BillingType>) => {
    setIsEmergency(Boolean(value));
    confirmationModal.open();
  };

  const updateJobEmergencyType = async () => {
    try {
      await promiseWrapper(JobsAPI.updateJobEmergencyType(jobID, isEmergency));
      showSuccessMessage(`Job rate type has been successfully changed to ${isEmergency ? 'Emergency' : 'Regular'}.`);
      onSuccessfulChange();
      closeConfirmationModal();
    } catch (error) {
      showErrorMessage(error);
      closeConfirmationModal(true);
    }
  };

  return (
    <>
      <AppSelect<BillingType> value={Number(isEmergency)} onChange={handleChangeEmergency} sx={{ maxWidth: 180 }}>
        <MenuItem value={BillingType.RegularJob}>Bill as regular job</MenuItem>
        <MenuItem value={BillingType.EmergencyJob}>Bill as ASAP job</MenuItem>
      </AppSelect>
      {confirmationModal.isOpen && (
        <AppPaperModal
          open
          modalId="confirm-change-emergency"
          onClose={() => closeConfirmationModal(true)}
          title="Confirm action"
          submitButton={{
            title: 'Confirm',
            onClick: updateJobEmergencyType,
            loading: inProcess,
            timer: 3,
          }}
          cancelButton={{ color: 'error' }}
        >
          <Typography fontFamily="Poppins Medium" fontSize={13} color="text.secondary">
            Are you sure, you want to
            <Typography component="span" color="primary" fontFamily="inherit" fontSize="inherit">
              {' change rate type to '}
              {initialIsEmergency ? 'Regular' : 'Emergency'}
            </Typography>
            ?
          </Typography>
        </AppPaperModal>
      )}
    </>
  );
};

export default BillingTypeSelect;
