import { FC } from 'react';

import { Country } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import { Box } from '@mui/material';

type Props = {
  country: Country | undefined;
  width?: number;
  height?: number;
  rounded?: boolean;
};

const CountryFlagImage: FC<Props> = ({ country, width = 16, height = 16, rounded = false }) => {
  const Flag = flags[country] || flags.US;

  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="center"
      width={width}
      height={height}
      borderRadius={rounded ? '9999px' : 0}
      overflow="hidden"
      sx={{
        '& svg': {
          scale: '1.5',
        },
      }}
    >
      <Flag title={country} />
    </Box>
  );
};

export default CountryFlagImage;
