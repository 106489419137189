import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import generalStyles from 'general.module.scss';

import Box from '@mui/material/Box';

import { Comment } from 'types/Timesheet';

import { TimesheetStatus } from 'Constants/timesheet';
import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import AppPaperModal from 'components/AppPaperModal';
import { ReduxState, useAppSelector } from 'createStore';

import AddCommentForm from './AddCommentForm/AddCommentForm';
import CommentsList from './CommentsList/CommentsList';
import styles from './TimesheetComments.module.scss';

const TimesheetComments: React.FC = () => {
  const dispatch = useDispatch();
  const timesheet = useAppSelector((state) => state.timesheets.timesheet);
  const comments = useAppSelector((state: ReduxState) => state.comments.comments);
  const isCommentProcessing = useAppSelector((state: ReduxState) => state.comments.processing);
  const isCommentDeleting = useAppSelector((state: ReduxState) => state.comments.processing_delete);
  const isCommentCreating = useAppSelector((state: ReduxState) => state.comments.processing_create);

  const [newComment, setNewComment] = useState('');
  const [willUpdatedComment, setWillUpdatedComment] = useState({ id: 0, comment: '' });
  const [willDeletedComment, setWillDeletedComment] = useState({ id: 0, comment: '' });
  const deleteCommentModal = useModal();
  const isTimesheetCancelled = timesheet?.status === TimesheetStatus.cancelled;

  const resetNewComment = () => setNewComment('');

  const resetUpdatedComment = () => setWillUpdatedComment({ id: 0, comment: '' });

  const resetDeletedComment = () => {
    setWillDeletedComment({ id: 0, comment: '' });
    deleteCommentModal.close();
  };

  const retrieveComments = () => dispatch(actions.CommentsActions.retrieve('timesheet', timesheet.id));

  const createComment = () => {
    const data = {
      entity_id: timesheet.id,
      entity_type: 'timesheet',
      comment: newComment,
    };
    dispatch(actions.CommentsActions.create(data))
      .then(resetNewComment)
      .catch(showErrorMessage)
      .finally(() => {
        resetDeletedComment();
        resetUpdatedComment();
        setNewComment('');
        retrieveComments();
      });
  };

  const updateComment = () => {
    const oldComment = comments.find((comment) => comment.id === willUpdatedComment.id);
    if (oldComment?.comment === willUpdatedComment.comment) {
      resetUpdatedComment();
      return;
    }
    dispatch(actions.CommentsActions.update(willUpdatedComment.id, willUpdatedComment.comment))
      .then(retrieveComments)
      .catch(showErrorMessage)
      .finally(resetUpdatedComment);
  };

  const deleteComment = () => {
    dispatch(actions.CommentsActions.deleteComment(willDeletedComment.id))
      .then(retrieveComments)
      .catch(showErrorMessage)
      .finally(() => {
        resetDeletedComment();
        resetUpdatedComment();
      });
  };

  const onDeleteComment = (comment: Comment) => {
    deleteCommentModal.open();
    setWillDeletedComment({
      id: comment.id,
      comment: comment.comment ?? comment.body,
    });
  };

  const onChangeComment = (id, comment) => {
    setWillUpdatedComment({ id, comment });
  };

  useEffect(() => {
    retrieveComments();
  }, []);

  return (
    <TimesheetBox>
      <Box className={classNames(styles.commentsBox, isCommentProcessing && styles.commentsBox__proccesing)}>
        <p className={generalStyles.title}>
          <b>{comments.length ? 'Comments' : 'No comments'}</b>
        </p>

        {!isTimesheetCancelled && (
          <AddCommentForm
            loading={isCommentCreating}
            onAddComment={createComment}
            onInputChange={(event) => setNewComment(event.target.value)}
            newComment={newComment}
          />
        )}

        {!!comments.length && (
          <CommentsList
            willUpdatedComment={willUpdatedComment}
            onUpdateComment={updateComment}
            onDeleteComment={onDeleteComment}
            onChangeComment={onChangeComment}
            onCloseComment={resetUpdatedComment}
          />
        )}
      </Box>

      {deleteCommentModal.isOpen && (
        <AppPaperModal
          open={deleteCommentModal.isOpen}
          onClose={deleteCommentModal.close}
          title="Delete comment"
          submitButton={{
            title: 'Delete',
            onClick: deleteComment,
            loading: isCommentDeleting,
          }}
        >
          <p
            style={{ maxWidth: '350px', wordWrap: 'break-word' }}
          >{`Are you sure you want to delete comment: "${willDeletedComment.comment}" ?`}</p>
        </AppPaperModal>
      )}
    </TimesheetBox>
  );
};

export default TimesheetComments;
