import { Department } from 'types/Common/Companies';

export enum TimeLimitation {
  WithoutLimit = 'no_limit',
  OnlyHours = 'hours',
}

export const TimeLimitationReadableValues = {
  [TimeLimitation.WithoutLimit]: 'no limit',
  [TimeLimitation.OnlyHours]: 'hours',
};

/**
 * This functions needs to filter out old departments in some cases
 *
 * <strong>As per Nate's request in this task [CS-1293](https://gtnetwork.atlassian.net/browse/CS-1293):</strong>
 *
 * Some users still use CES depts, instead of WMS depts when creating a job. Mike wants them to use the WMS depts, so let’s hide the unncessary depts from Create/Update Job.
 *
 * Here is the list of Dept IDs to hide:
 *
 * - 1 - 9
 * - 15 - 20
 * - 25(Nate asked to show dept with id:24) - 27
 * - 945, (Nate asked to show dept with id:946) 947 - 950
 *
 * Note: These Depts should still appear in Job Map/List/Grid Filters (and any other places with dept filters), so they can find old jobs.
 *
 * We should only remove it from Create/Edit Job + Job Details Update Dept.
 *
 * @param department
 */
export const shouldShowDepartmentOnJobCreation = ({ show_on_create_job_page }: Department) =>
  Boolean(show_on_create_job_page);
