import { memo } from 'react';

import { Badge, Fab, Tooltip } from '@mui/material';

import CommentIconImage from 'Assets/comment-icon.png';
import { useNotesContext } from 'context/NotesContext';
import { useAppSelector } from 'createStore';

const ToggleInvoicesNotes = () => {
  const { notes, toggleNotes } = useNotesContext();
  return (
    <Badge badgeContent={notes?.length} color="primary" overlap="circular">
      <Tooltip disableInteractive title="Show/hide comments">
        <Fab style={{ backgroundColor: '#FFF', zIndex: 1 }} onClick={toggleNotes} size="small">
          <img src={CommentIconImage} alt="comment" width={24} height={24} />
        </Fab>
      </Tooltip>
    </Badge>
  );
};

export default memo(ToggleInvoicesNotes);
