import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';

import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, MenuItem, SelectChangeEvent, Tooltip, Typography } from '@mui/material';

import { Notifications } from 'types/Notifications';

import NotificationsImage from 'Assets/notifications.png';
import NotificationItem from 'Containers/Notifications/components/NotificationItem/NotificationItem';
import { NotifiableTypeSelect, PaginationButton, PerPageSelect } from 'Containers/Notifications/components/styled';
import { deleteNotification, getNotificationsList } from 'Services/notifications/actions';
import { NotificationActions, UpdateSearchNotificationsOptions } from 'Services/notifications/reducer';
import { NotificationsIcon } from 'Utils/Icon';
import { AppCheckbox } from 'components/AppCheckbox/AppCheckbox';
import { useAppDispatch, useAppSelector } from 'createStore';

import NotificationsListSkeleton from './NotificationsList.Skeleton';
import styles from './NotificationsList.module.scss';

const PER_PAGES = [10, 25, 50, 100].map((value) => ({ label: value + ' per page', value }));

const NotificationsList: FC = () => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const filtersList = useAppSelector((state) => state.notifications.filtersList);
  const search_options = useAppSelector((state) => state.notifications.search_options);
  const next_cursor = useAppSelector((state) => state.notifications.next_cursor);
  const prev_cursor = useAppSelector((state) => state.notifications.prev_cursor);
  const processing = useAppSelector((state) => state.notifications.processing);

  const filtersOptions = useMemo(() => {
    return filtersList.reduce(
      (acc, filter) => {
        return [...acc, ...filter.options];
      },
      [{ label: 'All notifications', value: 0 }] as Notifications.Filter['options']
    );
  }, [filtersList]);

  const getNotifications = useCallback(() => {
    dispatch(getNotificationsList());
  }, [dispatch]);

  const updateSearchOptions = (options: UpdateSearchNotificationsOptions) =>
    dispatch(NotificationActions.updateOptions({ ...search_options, ...options }));

  const changePerPage = (event: SelectChangeEvent<number>) => {
    updateSearchOptions({ limit: Number(event.target.value), cursor: null });
  };

  const toggleUnread = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    updateSearchOptions({ unread: checked });
  };

  const nextPage = () => {
    updateSearchOptions({ cursor: next_cursor });
  };

  const prevPage = () => {
    updateSearchOptions({ cursor: prev_cursor });
  };

  const deleteNotificationItem = (notificationID: Notifications.Notification['id']) => {
    dispatch(deleteNotification(notificationID));
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications, search_options]);

  return (
    <Box className={styles.notificationsListBox}>
      <Box className={styles.notificationsListHeader}>
        <Box className={styles.titleWrapper}>
          <NotificationsIcon className={styles.icon} />
          <Typography className={styles.title}>Notifications</Typography>
        </Box>
        <Box className={styles.unreadCheckbox} component="label" htmlFor="unread-checkbox">
          <AppCheckbox id="unread-checkbox" checked={search_options.unread} onChange={toggleUnread} />
          <Typography component="span">Unread</Typography>
        </Box>
      </Box>

      <Box className={styles.notificationsListMobileHeader}>
        <NotifiableTypeSelect
          fullWidth
          startAdornment={<NotificationsIcon height={14} />}
          onChange={(event) => updateSearchOptions({ notifiableType: event.target.value as number })}
          color="primary"
          value={search_options.notifiableType || 0}
          IconComponent={KeyboardArrowDown}
        >
          {filtersOptions.map(({ label, value }) => (
            <MenuItem
              key={value}
              value={value}
              sx={{
                '& .MuiButton-root': { p: 0, pointerEvents: 'none', color: 'inherit' },
                '&.Mui-selected': {
                  color: 'primary.main',
                },
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
            >
              <Button>{label}</Button>
            </MenuItem>
          ))}
        </NotifiableTypeSelect>
      </Box>

      {!processing ? (
        <Box className={styles.notificationsList}>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onDelete={() => deleteNotificationItem(notification.id)}
            />
          ))}
          {!notifications.length && (
            <Box
              display="flex"
              width="100%"
              height="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={3}
            >
              <img src={NotificationsImage} alt="No notifications" />
              <Typography color="text.secondary" fontFamily="Poppins Medium" fontSize={17}>
                No notifications found
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <NotificationsListSkeleton />
      )}

      {!!notifications.length && !processing && (
        <Box className={styles.notificationsListFooter}>
          <PerPageSelect
            disabled={processing}
            IconComponent={KeyboardArrowDown}
            renderValue={(value) => `${value} per page`}
            value={search_options.limit}
            onChange={changePerPage}
          >
            {PER_PAGES.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </PerPageSelect>
          <Box className={styles.notificationsListPagination}>
            <Tooltip title="Previous page" disableInteractive>
              <PaginationButton disabled={!prev_cursor || processing} onClick={prevPage}>
                <KeyboardArrowLeft />
              </PaginationButton>
            </Tooltip>
            <Tooltip title="Next page" disableInteractive>
              <PaginationButton disabled={!next_cursor || processing} onClick={nextPage}>
                <KeyboardArrowRight />
              </PaginationButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NotificationsList;
