import { FC, useMemo } from 'react';

import moment from 'moment';

import { Typography, TableBody, TableCell, TableHead, TableRow, colors, TableFooter } from '@mui/material';

import { MasterTimesheetUpdatedItem } from 'types/Timesheet';

import { StyledTable } from 'Containers/Timesheets/MasterTimesheet/styled';
import { FORMATS } from 'Utils/Date';
import TextOverflowTooltip from 'components/TextOverflowTooltip';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';

type MasterTimesheetTableColumn = {
  key: string;
  title?: string;
  renderCellBody: (timesheet: MasterTimesheetUpdatedItem) => number | string | JSX.Element;
};

const BaseTableColumns: MasterTimesheetTableColumn[] = [
  {
    key: 'id',
    title: 'Shift ID',
    renderCellBody: (timesheet) => timesheet.id,
  },
  {
    key: 'worker_name',
    title: 'Name',
    renderCellBody: (timesheet) => (
      <TextOverflowTooltip title={timesheet.worker_name} disableInteractive>
        <Typography width={50} overflow="hidden" textOverflow="ellipsis">
          {timesheet.worker_name}
        </Typography>
      </TextOverflowTooltip>
    ),
  },
];

const StartDateTableColumns: MasterTimesheetTableColumn[] = [
  ...BaseTableColumns,
  {
    key: 'old_start_at',
    title: 'Old Start Date',
    renderCellBody: (timesheet) => moment(timesheet.old_start_at).format(FORMATS.masterTimesheetDateTimeChanges),
  },
  {
    key: 'new_start_at',
    title: 'New Start Date',
    renderCellBody: (timesheet) => moment(timesheet.new_start_at).format(FORMATS.masterTimesheetDateTimeChanges),
  },
];

const FinishDateTableColumns: MasterTimesheetTableColumn[] = [
  ...BaseTableColumns,
  {
    key: 'old_finish_at',
    title: 'Old Finish Date',
    renderCellBody: (timesheet) => moment(timesheet.old_finish_at).format(FORMATS.masterTimesheetDateTimeChanges),
  },
  {
    key: 'new_finish_at',
    title: 'New Finish Date',
    renderCellBody: (timesheet) => moment(timesheet.new_finish_at).format(FORMATS.masterTimesheetDateTimeChanges),
  },
];

export enum Dates {
  start = 'start',
  finish = 'finish',
}

const ColumnsMap = {
  [Dates.start]: StartDateTableColumns,
  [Dates.finish]: FinishDateTableColumns,
};

type Props = {
  datesType: Dates;
};

const TimesheetsDateChangesTable: FC<Props> = ({ datesType }) => {
  const { values } = useMasterTimesheetContext();

  const timesheetsWillBeChanged = useMemo(() => {
    return values.timesheetsThatWillBeChanged.filter((timesheet) => {
      switch (datesType) {
        case Dates.start:
          return timesheet.start_date_changed;
        case Dates.finish:
          return timesheet.finish_date_changed;
        default:
          return false;
      }
    });
  }, [values.timesheetsThatWillBeChanged, datesType]);

  return (
    <StyledTable sx={{ '& .MuiTableCell-root': { padding: 0.5 } }} stickyHeader>
      <TableHead>
        <TableRow>
          {ColumnsMap[datesType].map(({ key, title = '' }) => (
            <TableCell key={key}>{title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {timesheetsWillBeChanged.map((timesheet) => (
          <TableRow key={timesheet.id} sx={{ bgcolor: timesheet.will_be_cancelled ? colors.red[50] : '' }}>
            {ColumnsMap[datesType].map(({ key, renderCellBody }) => {
              return <TableCell key={timesheet.id + '-' + key}>{renderCellBody(timesheet)}</TableCell>;
            })}
          </TableRow>
        ))}
      </TableBody>
      {timesheetsWillBeChanged.some(({ will_be_cancelled }) => will_be_cancelled) && (
        <TableFooter
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
          }}
        >
          <TableRow>
            <TableCell colSpan={ColumnsMap[datesType].length} align="left">
              <Typography variant="caption" color={'error'}>
                *Timesheets marked in red will be CANCELLED. Is it correct?
              </Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      )}
    </StyledTable>
  );
};

export default TimesheetsDateChangesTable;
