import React from 'react';

import moment from 'moment/moment';

import styles from './LogDate.module.scss';

const LogDate: React.FC<{ date: string; isDeleted?: boolean }> = ({ date, isDeleted = false }) => {
  return (
    <article className={styles.logDate}>
      <p className={styles.logDate__item}>{moment(date).format('MM/DD/YY')}</p>
      <p className={styles.logDate__item}>{moment(date).format('HH:mm:ss')}</p>
      {isDeleted && <span style={{ color: '#f45858', fontSize: 12, textAlign: 'right' }}>(Deleted)</span>}
    </article>
  );
};

export default LogDate;
