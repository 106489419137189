import moment from 'moment';

import { Box, Typography } from '@mui/material';

import { parseCommentAuthorFullName } from 'Containers/Timesheets/utils';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';

import { MasterTimesheetContentBox } from '../../styled';

const MasterTimesheetComments = () => {
  const { values } = useMasterTimesheetContext();

  return (
    <MasterTimesheetContentBox>
      <Box display="flex" flexDirection="column" gap="20px">
        {values.comments?.map((comment) => (
          <Box display="flex" flexDirection="column" gap="5px" key={comment.id}>
            <Typography fontSize={13} fontFamily="Poppins Medium">
              {parseCommentAuthorFullName(comment)}:
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              p="16px 20px"
              bgcolor="secondary.light"
              border="1px solid #E8EDF1"
              borderRadius="8px"
              position="relative"
            >
              <Typography color="text.primary" fontSize={13} sx={{ overflowWrap: 'break-word' }}>
                {comment.comment}
              </Typography>
              <Typography
                position={'absolute'}
                lineHeight={1}
                bottom={4}
                right={8}
                variant="caption"
                fontFamily="Poppins Medium"
                sx={{ opacity: 0.7 }}
              >
                {moment(comment.updated_at).format('MMM, DD [at] h:mm a')}
              </Typography>
            </Box>
          </Box>
        ))}

        {!values.comments?.length && (
          <Typography fontSize={13} fontFamily="Poppins Medium">
            No comments yet
          </Typography>
        )}
      </Box>
    </MasterTimesheetContentBox>
  );
};

export default MasterTimesheetComments;
