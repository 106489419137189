import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import {
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  Menu,
  MenuItem,
  IconButton,
  Chip,
  Box,
  Typography,
  Alert,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import { AppThunkDispatch } from 'types';
import { ConedUser } from 'types/Common/User';

import { TABLE_HEADER, APPROVE, USER_STATUSES_LABELS, USER_STATUS_COLORS, USER_STATUSES_STRING } from 'Constants/user';
import { actions } from 'Services';
import { getUserAuthSessionData } from 'Utils/user';
import app_history from 'app_history';
import TextOverflowTooltip from 'components/TextOverflowTooltip';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppSelector } from 'createStore';

import RolesTableMobile from './RolesTableMobile';
import DeleteUserModal from './components/modals/DeleteUserModal';
import UpdateUserStatusModal from './components/modals/UpdateUserStatusModal';
import { RolesStyledTable } from './styled';

export const USER_STATUS_ICONS = {
  [USER_STATUSES_STRING.active]: <CheckIcon fontSize="inherit" />,
  [USER_STATUSES_STRING.rejected]: <DoNotDisturbIcon fontSize="inherit" />,
  [USER_STATUSES_STRING.waiting_for_approval]: <AccessTimeIcon fontSize="inherit" />,
};

interface Props {
  users: ConedUser[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onChangeSort: (sortName: string) => void;
}

const RolesSortTable: React.FC<Props> = ({ users, onChangeSort, sortBy, sortDirection = 'asc' }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState<ConedUser>(null);
  const { openPopover, closeAll } = usePopoverContext();
  const processingUsers = useAppSelector((state) => state.users.processingUsers);
  const rolesMap = useAppSelector((state) => state.app.rolesMap);
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up('md'));

  const activateEmail = (id: number | string) => dispatch(actions.UsersActions.activateEmail(id));

  const openEditUserModal = (userID: number) => {
    app_history.push(`/roles/${userID}`);
  };

  const menuItemsActionsMap = {
    Approval: () =>
      openPopover(
        'approval',
        <UpdateUserStatusModal newStatus={APPROVE.approved} user={selectedRow} onClose={closeAll} />
      ),
    Reject: () =>
      openPopover(
        'reject',
        <UpdateUserStatusModal newStatus={APPROVE.rejected} user={selectedRow} onClose={closeAll} />
      ),
    Edit: () => openEditUserModal(selectedRow.id),
    Delete: () => openPopover('delete-user', <DeleteUserModal user={selectedRow} onClose={closeAll} />),
    'Resend Activation Email': () => activateEmail(selectedRow.id),
  };

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>, row: ConedUser) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const closeActionsMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  return (
    <>
      {isDesktop && (
        <Box display="flex" flexDirection="column" overflow="hidden">
          <Paper
            style={{
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TableContainer>
              <RolesStyledTable>
                <TableHead>
                  <TableRow>
                    {TABLE_HEADER.map((headCell, index) => (
                      <TableCell
                        key={headCell.label}
                        onClick={headCell.sortable ? () => onChangeSort(headCell.value) : () => {}}
                      >
                        <TableSortLabel
                          hideSortIcon={!headCell.sortable}
                          active={sortBy === headCell.value}
                          direction={sortDirection}
                          sx={{ width: '100%', height: '100%' }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row) => (
                    <TableRow key={row.id} onClick={() => openEditUserModal(row.id)}>
                      <TableCell sx={{ maxWidth: 200 }}>
                        <TextOverflowTooltip
                          disableInteractive
                          title={row.name}
                          style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <span>{row.name}</span>
                        </TextOverflowTooltip>
                      </TableCell>
                      <TableCell sx={{ maxWidth: 200 }}>
                        <TextOverflowTooltip
                          disableInteractive
                          title={row.email}
                          style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <span>{row.email}</span>
                        </TextOverflowTooltip>
                      </TableCell>
                      <TableCell sx={{ maxWidth: 200 }}>
                        <TextOverflowTooltip
                          disableInteractive
                          title={getUserAuthSessionData(row).lastLoggedIP}
                          style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <span>{getUserAuthSessionData(row).lastLoggedIP}</span>
                        </TextOverflowTooltip>
                      </TableCell>
                      <TableCell sx={{ maxWidth: 200 }}>
                        <TextOverflowTooltip
                          disableInteractive
                          title={getUserAuthSessionData(row).lastLogin}
                          style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          <span>{getUserAuthSessionData(row).lastLogin}</span>
                        </TextOverflowTooltip>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" flexWrap="wrap" width={'400px'} gap={1}>
                          {row?.roles?.map((role) => (
                            <Chip
                              sx={{ backgroundColor: '#00000020' }}
                              label={rolesMap[role]?.name}
                              key={rolesMap[role]?.name}
                            />
                          )) || <Chip color="error" label="No role" />}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: '200px' }}>
                        <Alert
                          sx={{
                            width: 'max-content',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 12,
                            fontWeight: 500,
                            padding: '0 8px',
                            gap: '4px',
                            '& .MuiAlert-icon': {
                              margin: 0,
                            },
                          }}
                          icon={USER_STATUS_ICONS[row.status]}
                          severity={USER_STATUS_COLORS[row.status]}
                        >
                          {USER_STATUSES_LABELS[row.status] || 'No status'}
                        </Alert>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(event) => openActionsMenu(event, row)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </RolesStyledTable>
            </TableContainer>

            {!users.length && !processingUsers && (
              <Box display="flex" justifyContent="center" alignItems="center" p={10}>
                <Typography variant="body1">No users found</Typography>
              </Box>
            )}
          </Paper>
        </Box>
      )}
      {!isDesktop && <RolesTableMobile users={users} openActionsMenu={openActionsMenu} selectedUser={selectedRow} />}
      {selectedRow && (
        <Menu id="long-menu" anchorEl={anchorEl} open onClose={closeActionsMenu}>
          {Object.entries(menuItemsActionsMap).map(([label, callback]) => (
            <MenuItem
              key={label}
              onClick={() => {
                callback();
                closeActionsMenu();
              }}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default RolesSortTable;
