export const SUBCONTRACTOR_CELL_STYLES = {
  maxWidth: 150,
  wordBreak: 'break-word',
} as React.CSSProperties;

export const CONFIGURATION_TABLE = [
  { label: 'ID', value: 'id', sortable: true },
  { label: 'Name', value: 'name', sortable: true },
  { label: 'Invoice Date', value: 'date', sortable: true },
  { label: 'Departments', value: 'departments', sortable: true },
  { label: 'Timesheet Amount', value: 'timesheets_amount', sortable: true },
  { label: 'POs', value: 'po_amount', sortable: true },
  { label: 'Paid ', value: 'paid_amount', sortable: true },
  { label: 'Billing Cycle', value: 'billing_cycle', sortable: true },
  { label: 'Job Type', value: 'job_types', sortable: true },
  { label: 'Invoices', value: 'invoice_count', sortable: true },
  { label: 'Actions', value: 'actions', sortable: false },
];
export const INVOICES_TABLE = [
  { label: 'ID', value: 'id', sortable: true },
  { label: 'Create Date', value: 'date', sortable: true },
  { label: 'Start Date', value: 'start_date', sortable: true },
  { label: 'Finish Date', value: 'finish_date', sortable: true },
  { label: 'Invoiced Date', value: 'invoiced_date', sortable: true },
  // { label: 'Departments',      value: 'departments' },
  // { label: 'Job Type',         value: 'job_type'  },
  { label: '# of TS', value: 'timesheets', sortable: true, tooltipTitle: 'Timesheets Count' },
  { label: 'POs', value: 'po', sortable: true },
  { label: 'Paid', value: 'paid', sortable: true },
  { label: 'Regular', value: 'regular_hours', sortable: true },
  { label: 'OT', value: 'overtime_hours', sortable: true, tooltipTitle: 'Overtime Hours' },
  { label: 'Holiday', value: 'holiday_hours', sortable: true },
  { label: 'Emergency', value: 'emergency_hours', sortable: true },
  { label: 'Total Amount', value: 'total_due', sortable: true },
  { label: 'Total Paid', value: 'total_paid', sortable: true },
  { label: 'Total Outstanding', value: 'total_outstanding', sortable: true },
  { label: 'Status', value: 'status', sortable: true },
  { label: '', value: 'actions', sortable: false },
];
export const TIMESHEETS_TABLE = [
  { label: 'ID', value: 'id', sortable: true },
  { label: 'Start Date/Time', value: 'start_at', sortable: true },
  { label: 'End Date/Time', value: 'finish_at', sortable: true },
  { label: 'Departments', value: 'department', sortable: true },
  { label: 'L2 Org', value: 'l2_org', sortable: true },
  { label: 'L2 Code', value: 'l2_code', sortable: true },
  { label: 'Section #', value: 'section', sortable: true },
  { label: 'PO #', value: 'po', sortable: true },
  { label: ' Requestor', value: 'requestor', sortable: true },
  { label: 'Request Time', value: 'request_time', sortable: true },
  { label: 'Supervisor', value: 'supervisor', sortable: true },
  { label: 'Address', value: 'location', sortable: true },
  { label: 'Muni', value: 'muni', sortable: true },
  { label: 'Worker', value: 'flagger_name', sortable: true },
  {
    label: 'Hours',
    values: [
      { label: 'Regular', value: 'regular_hours', sortable: true },
      { label: 'Overtime', value: 'overtime_hours', sortable: true },
      { label: 'Holiday', value: 'holiday_hours', sortable: true },
      { label: 'Emergency', value: 'emergency_hours', sortable: true },
    ],
    sortable: false,
  },
  { label: 'Paid', value: 'billed', sortable: true },
  { label: '', value: '', sortable: false },
];

export enum InvoiceStatus {
  BILLED = 'billed',
  DRAFT = 'draft',
  PAID = 'paid',
}

export const InvoiceStatusLabels = {
  [InvoiceStatus.DRAFT]: 'Draft',
  [InvoiceStatus.BILLED]: 'Billed',
  [InvoiceStatus.PAID]: 'Paid',
};
