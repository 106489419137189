import Immutable from 'seamless-immutable';

import { ResponseSubcontractorItem } from 'types/Subcontractors';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

interface SubcontractorsState {
  subcontractors: ResponseSubcontractorItem[];
  processing: boolean;
  processing_delete: boolean;
  update_subcontractor_processing: boolean;
}

const initialState = Immutable<SubcontractorsState>({
  subcontractors: [],
  processing: false,
  processing_delete: false,
  update_subcontractor_processing: false,
});

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    // CREATE
    case actionTypes.CREATE_SUBCONTRACTOR_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.CREATE_SUBCONTRACTOR_SUCCESS: {
      const subcontractors = state.subcontractors.asMutable();
      subcontractors.push(action.subcontractor);
      return state.merge({
        processing: false,
        subcontractors,
      });
    }
    case actionTypes.CREATE_SUBCONTRACTOR_ERROR:
      return state.merge({
        processing: false,
      });

    // GET SUBCONTRACTORS
    case actionTypes.GET_SUBCONTRACTORS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_SUBCONTRACTORS_SUCCESS:
      return state.merge({
        subcontractors: action.subcontractors,
        processing: false,
      });
    case actionTypes.GET_SUBCONTRACTORS_ERROR:
      return state.merge({
        processing: false,
      });

    // UPDATE SUBCONTRACTOR
    case actionTypes.UPDATE_SUBCONTRACTOR_REQUEST:
      return state.merge({
        update_subcontractor_processing: true,
      });
    case actionTypes.UPDATE_SUBCONTRACTOR_SUCCESS: {
      const subcontractors = state.subcontractors.asMutable().map((s) => {
        if (s.id === action.subcontractor.id) {
          return { ...s, ...action.subcontractor };
        }
        return s;
      });
      return state.merge({
        update_subcontractor_processing: false,
        subcontractors,
      });
    }
    case actionTypes.UPDATE_SUBCONTRACTOR_ERROR:
      return state.merge({
        update_subcontractor_processing: false,
      });

    //delete subcontractor
    case actionTypes.DELETE_SUBCONTRACTOR_REQUEST:
      return state.merge({
        processing_delete: true,
      });
    case actionTypes.DELETE_SUBCONTRACTOR_SUCCESS: {
      const subcontractors = state.subcontractors
        .asMutable()
        .filter((i) => i.subcontractor && i.subcontractor.id !== action.item.id);
      return state.merge({
        processing_delete: false,
        subcontractors,
      });
    }
    case actionTypes.DELETE_SUBCONTRACTOR_ERROR:
      return state.merge({
        processing_delete: false,
      });
    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
