import React, { CSSProperties, PropsWithChildren } from 'react';

import css from './FilterGroup.module.css';

type Props = PropsWithChildren<{
  title: string;
  onReset: () => void;
  gap?: CSSProperties['gap'];
}>;

const FilterGroup = ({ title, onReset = () => {}, gap, children }: Props) => {
  return (
    <div className={css.wrap}>
      <div className={css['title-container']}>
        <span className={css['title-text']}>{title}</span>
        <span onClick={onReset} className={css['reset-link']}>
          Reset All
        </span>
      </div>
      <div className={css.container} style={{ gap }}>
        {children}
      </div>
    </div>
  );
};

export default FilterGroup;
