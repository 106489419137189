import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

import { AppThunkDispatch } from 'types';
import { ConEdWorker, ConEdWorkerForTable } from 'types/Common/User';

import { TestIDs, addTestID } from 'Constants/tests';
import { actions, ActionTypes } from 'Services';
import { useAppSelector } from 'createStore';

interface Props {
  onSelect?: (item: ConEdWorkerForTable[]) => void;
  width?: React.CSSProperties['width'];
  selectedWorkers?: Array<ConEdWorkerForTable | ConEdWorker>;
  selectedWorkerIds?: number[];
  limitTags?: any;
  searchParams?: any;
  placeholder?: string;
  filterConfig?: Partial<ConEdWorkerForTable> | ((worker: ConEdWorkerForTable) => boolean);
  disabled?: boolean;
  inputSize?: 'small' | 'medium';
  label?: string;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  error?: boolean;
  helperText?: string;
  optionDataCy?: {
    [key: string]: string;
  };
  InputComponent?: React.ElementType;
}

export default function WorkersMaterialAsyncSearch({
  onSelect = () => {},
  width,
  selectedWorkers,
  selectedWorkerIds,
  limitTags = 0,
  placeholder = '',
  filterConfig = {},
  disabled = false,
  inputSize = 'small',
  label,
  onBlur,
  error,
  helperText,
  optionDataCy = null,
  InputComponent,
}: Props) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const [open, setOpen] = React.useState(false);
  const workers = useAppSelector((state) => state.workers.workers);
  const loading = useAppSelector((state) => state.workers.processing);

  useEffect(() => {
    dispatch(actions.WorkersActions.retrieve());

    return () => {
      dispatch({ type: ActionTypes.WorkersActionTypes.GET_WORKERS_ERROR });
    };
  }, []);

  const options = useMemo(() => {
    const filterOptions = filterConfig && typeof filterConfig === 'object' ? Object.entries(filterConfig) : [];
    const filterOptionFunction = (worker: ConEdWorkerForTable) => {
      if (!filterOptions.length) return true;
      return filterOptions.every(([key, value]) => {
        if (typeof value === 'string') {
          return worker[key]?.toLowerCase() === value?.toLowerCase();
        }
        return worker[key] === value;
      });
    };
    const workerFilter = typeof filterConfig === 'function' ? filterConfig : filterOptionFunction;

    return workers.asMutable({ deep: true }).filter(workerFilter);
  }, [workers, filterConfig]);

  const autocompleteValue = useMemo(() => {
    if (selectedWorkers) {
      const onlyIds = selectedWorkers.map(({ id }) => id);
      return options.filter(({ id }) => onlyIds.includes(id));
    }
    if (selectedWorkerIds) {
      return options.filter(({ id }) => selectedWorkerIds.includes(id));
    }
    return [];
  }, [selectedWorkers, selectedWorkerIds, options]);

  return (
    <Autocomplete<ConEdWorkerForTable, true>
      id="asynchronous-demo"
      multiple
      disabled={disabled || loading}
      limitTags={limitTags ? limitTags : 1}
      size={inputSize}
      value={autocompleteValue}
      style={{ width, minWidth: 150 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      renderOption={(props, option) => (
        <li {...props} key={option.id} {...optionDataCy}>
          <span>{option.name}</span>
        </li>
      )}
      options={options}
      loading={loading}
      onBlur={onBlur}
      renderInput={(params) =>
        InputComponent ? (
          <InputComponent {...params} />
        ) : (
          <TextField
            {...params}
            label={label}
            placeholder={!autocompleteValue.length ? placeholder : null}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            error={error}
            helperText={helperText}
            {...addTestID(TestIDs.components.subcontractorForm.fields.workers)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )
      }
      onChange={(event, value) => onSelect(value as ConEdWorkerForTable[])}
    />
  );
}
