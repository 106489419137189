import * as React from 'react';

import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

import './CheckboxComponent.scss';

interface Props {
  name?: string;
  hasTitle?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  className?: string;
  classNameIcon?: string;
  id: any;
  skipReceiveProps?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  color?: string;
  iconSize?: number;
}

export class CheckboxComponent extends React.Component<Props> {
  static defaultProps = {
    style: {},
    iconSize: 24,
  };

  checked: boolean = this.props.checked;

  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.skipReceiveProps) {
      this.checked = nextProps.checked;
      this.setState({ change: true });
    }
  }

  public render() {
    const cursor = this.props.disabled ? 'not-allowed' : 'pointer';
    return (
      <div
        className={`ce-chk-control ml-0 ${this.props.disabled ? null : 'cursor-pointer '}` + this.props.className}
        onClick={(e) => {
          e.stopPropagation();
          if (!this.props.disabled) {
            this.checked = !this.checked;
            this.setState({ checked: true });
            if (this.props.onChange) {
              this.props.onChange(this.checked);
            }
          }
        }}
        style={{ cursor, ...this.props.style }}
      >
        {this.checked ? (
          !this.props.disabled ? (
            <CircleCheckedFilled
              color="primary"
              style={{ ...(this.props.color && { fill: this.props.color }), fontSize: this.props.iconSize }}
            />
          ) : (
            <CircleCheckedFilled style={{ fill: 'lightgrey', fontSize: this.props.iconSize }} />
          )
        ) : (
          <CircleUnchecked
            style={{ color: `${this.props.disabled ? 'lightgrey' : ''}`, fontSize: this.props.iconSize }}
          />
        )}

        <label className="ce-title no-margin" htmlFor={this.props.id} style={{ cursor }}>
          {this.props.hasTitle && <span className="ce-ml-10">{this.props.hasTitle}</span>}
        </label>
      </div>
    );
  }
}

export default CheckboxComponent;
