// CREATE_NOTE
export const CREATE_NOTE_REQUEST = 'app.create_note_request';
export const CREATE_NOTE_SUCCESS = 'app.create_note_success';
export const CREATE_NOTE_ERROR = 'app.create_note_error';

// UPDATE_NOTE
export const UPDATE_NOTE_REQUEST = 'app.update_note_request';
export const UPDATE_NOTE_SUCCESS = 'app.update_note_success';
export const UPDATE_NOTE_ERROR = 'app.update_note_error';

// GET_NOTES
export const GET_NOTES_REQUEST = 'app.get_notes_request';
export const GET_NOTES_SUCCESS = 'app.get_notes_success';
export const GET_NOTES_ERROR = 'app.get_notes_error';
