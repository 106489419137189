import React, { ReactElement } from 'react';

import { Dialog, DialogActions, DialogProps, DialogTitle, Tooltip } from '@mui/material';

import Button from 'components/Button/Button';

import classes from './WarningDialog.module.scss';

interface DialogPropsWithoutOpen extends Omit<DialogProps, 'open'> {
  open?: boolean;
}

interface WProps {
  open: boolean;
  close: () => void;
  text: string | ReactElement;
  processing?: boolean;
  confirm: () => void;
  confirmWidth?: string;
  confirmTitle?: string;
  confirmTooltipTitle?: string;
  dialogProps?: DialogPropsWithoutOpen;
  skip?: () => void;
  skipButtonText?: string;
  skipButtonWidth?: string;
}

const WarningDialog = (props: WProps) => {
  const ConfirmButton = () => (
    <Button
      color={'dark'}
      width={props.confirmWidth ? props.confirmWidth : '158px'}
      height={'48px'}
      borderRadius={'20px'}
      textTransform={false}
      processing={props.processing}
      onClick={props.confirm}
    >
      {props.confirmTitle ? props.confirmTitle : 'Confirm'}
    </Button>
  );

  const CustomTooltip = ({ children }) => (
    <Tooltip placement="top" arrow classes={{ tooltip: classes.tooltip }} title={props.confirmTooltipTitle}>
      <div>{children}</div>
    </Tooltip>
  );

  return (
    <Dialog onClose={props.close} aria-labelledby="simple-dialog-title" open={props.open} {...props.dialogProps}>
      <DialogTitle>{props.text}</DialogTitle>
      <DialogActions className="action-btns">
        <Button
          color={'gray'}
          height={'48px'}
          width={'158px'}
          borderRadius={'20px'}
          textTransform={false}
          onClick={props.close}
        >
          Cancel
        </Button>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          {props.skipButtonText && (
            <Button
              color={'gray'}
              height={'48px'}
              width={props.skipButtonWidth ? props.skipButtonWidth : '158px'}
              borderRadius={'20px'}
              textTransform={false}
              onClick={props.skip}
              className="mr-3"
            >
              {props.skipButtonText}
            </Button>
          )}
          {props.confirmTooltipTitle ? (
            <CustomTooltip>
              <ConfirmButton />
            </CustomTooltip>
          ) : (
            <ConfirmButton />
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
