import { TimesheetTableItem, TimesheetTableItemWithColor } from '../types/Timesheet';

const hslaConfig = {
  hue: null as number,
  saturation: '100%',
  lightness: '80%',
  alpha: 0.3,

  hueStep: 155,
  maxHue: 360,

  getRandomHue: () => Math.floor(Math.random() * hslaConfig.maxHue),

  getNextHue: () => {
    const { hue, hueStep, maxHue } = hslaConfig;
    const nextHue = hue + hueStep;

    if (nextHue > maxHue) {
      return nextHue - maxHue;
    }

    return nextHue;
  },

  getHue: () => {
    const { hue, getRandomHue, getNextHue } = hslaConfig;
    hslaConfig.hue = hue ? getNextHue() : getRandomHue();

    return hslaConfig.hue;
  },

  parseColorString: () => {
    const { getHue, saturation, lightness, alpha } = hslaConfig;
    const hslaString = [getHue(), saturation, lightness, alpha].join(',');

    return `hsla(${hslaString})`;
  },
};

export const getTimesheetsThreads = (
  timesheets: TimesheetTableItem[]
): { threadCount: number; list: TimesheetTableItemWithColor[] } => {
  const haveDifferentWorkers =
    timesheets.length > 1 &&
    timesheets.some((ts, i, arr) => (arr[i + 1] ? ts.worker_id !== arr[i + 1].worker_id : false));

  // if we have not completely full thread list or all timesheets assigned to one worker - we need to return original timesheets list
  if (!haveDifferentWorkers) {
    return { threadCount: 0, list: timesheets };
  }

  // Creating ordered object(Map) for timesheets with id as key
  const timesheetsMap = new Map<number, TimesheetTableItemWithColor>();

  for (const timesheet of timesheets) {
    timesheetsMap.set(timesheet.id, timesheet);
  }

  const threadColorMap = new Map<number, string>();
  // Recoursivly adding color to timesheetsMap
  const addColorAndThread = (timesheet: TimesheetTableItemWithColor, color = '', threadIndex = 0) => {
    if (!timesheet) return;
    if (timesheet.color) return;
    timesheetsMap.set(timesheet.id, { ...timesheet, color, threadIndex });
    addColorAndThread(timesheetsMap.get(timesheet.replaced_timesheet), color, threadIndex);
    addColorAndThread(timesheetsMap.get(timesheet.replaces_timesheet), color, threadIndex);
  };

  timesheetsMap.forEach((timesheet) => {
    // Old condition - if timesheets have broken chains - then color coding will not work
    // if (!timesheet.color && (timesheet.replaced_timesheet || timesheet.replaces_timesheet)) {

    // New condition - if timesheets have broken chains - then user will see all timesheets but some of them will be self-colored
    if (!timesheet.color) {
      const index = threadColorMap.size;
      const color = hslaConfig.parseColorString();

      threadColorMap.set(index, color);
      addColorAndThread(timesheet, color, index);
    }
  });
  // Convert map to array
  const timesheetsWithColors = Array.from(timesheetsMap.values());
  const timesheetsFilteredByThread = [];

  threadColorMap.forEach((color, index) => {
    timesheetsFilteredByThread.push(timesheetsWithColors.filter(({ threadIndex }) => threadIndex === index));
  });

  const list = timesheetsFilteredByThread.flat();
  // Old condition - if timesheets have broken chains - then color coding will not work
  // if (list.length < timesheets.length) {
  //   return { threadCount: 0, list: timesheets };
  // }

  // If all timesheets have different colors - then there are no replacement chains and we don't need to show threads
  if (threadColorMap.size && threadColorMap.size === timesheetsMap.size) {
    return { threadCount: 0, list: timesheets };
  }

  return { threadCount: threadColorMap.size, list };
};
