import { FC, PropsWithChildren, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, ButtonProps, IconButton, Snackbar, SnackbarProps, Tooltip } from '@mui/material';

import { copyToClipboard } from 'Utils/copy';

type Props = {
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  value: string;
  snackbarMessage?: string;
  tooltipText?: string;
} & PropsWithChildren;

const CopyToClipboardButton: FC<Props> = ({
  buttonProps = {},
  value = '',
  snackbarMessage = 'Copied to clipboard',
  tooltipText = '',
  children,
}) => {
  const [open, setOpen] = useState(false);
  const closeSnackbar = () => setOpen(false);
  const handleClick = () => {
    setOpen(true);
    copyToClipboard(value);
  };

  return (
    <>
      <Tooltip title={tooltipText} disableInteractive>
        <Box>
          {children ? (
            <Button
              {...buttonProps}
              onClick={handleClick}
              startIcon={buttonProps?.startIcon || <ContentCopyIcon fontSize="small" />}
            >
              {children}
            </Button>
          ) : (
            <IconButton {...buttonProps} onClick={handleClick}>
              {buttonProps?.startIcon || <ContentCopyIcon fontSize="small" />}
            </IconButton>
          )}
        </Box>
      </Tooltip>

      <Snackbar open={open} onClose={closeSnackbar} autoHideDuration={1000} message={snackbarMessage} />
    </>
  );
};

export default CopyToClipboardButton;
