import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker } from '@mui/x-date-pickers';

import Invoice from 'types/Invoices';

import { JobType } from 'Constants/job';
import { emailRegExp, invoiceConfigurationNameRegExp } from 'Constants/reg_exp';
import { maximumYearValue } from 'Constants/validation';
import closeRegular from 'Images/close-regular.png';
import { BILLING_CYCLE } from 'Models/invoiceItem';
import { InvoicesAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';
import Button from 'components/Button/Button';
import { ReduxState } from 'createStore';

import CheckboxComponent from '../Components/Controls/Checkbox.Component';
import DepartmentMaterialAsyncSearch from '../Components/Controls/DepartmentMaterialAsyncSearch';
import RadioCustomComponent from '../Components/Controls/Radio.Component';
import './CreateConfiguration.scss';

interface Props extends ReduxProps {
  showed: boolean;
  closeSlide: Function;
  submit?: Function;
  updateInvoices?: any;
  invoice?: Invoice.DetailedConfiguration;
}
const _state = {
  invoice: {
    name: '',
    job_types: [],
    emails: [],
    billing_cycle: BILLING_CYCLE.Monthly,
    date: null,
    completed_jobs_only: 0,
    force_break_time: '00:30',
    departments: [],
    department_groups: [],
    excel_template_id: null,
    validate_billing_status: 1,
    include_missing_po: 0,
    generate_automatically: 0,
  } as Invoice.ConfigurationForm,
  defaultDepartments: [],
  defaultDepartmentGroups: [],
  defaultEmails: null,
  invoiceId: null,
  processing: false,
  showNonInvoicedDepts: false,
  templateOptions: [],
};

class AddInvoiceSliderComponent extends React.Component<Props, typeof _state> {
  state = _state;

  getTemplatesOptions = () => {
    if (this.state.templateOptions.length) return;
    InvoicesAPI.getTemplates()
      .then(({ templates }) => {
        const templateOptions = templates.map(({ name, id }) => ({ label: name, value: id }));
        this.setState({ templateOptions });
      })
      .catch(showErrorMessage);
  };

  componentDidMount() {
    this.getTemplatesOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    this.getTemplatesOptions();
    if (this.props.invoice && prevState.invoiceId !== this.props.invoice.id && this.props.departments) {
      const defaultDepartments = this.props.departments
        .asMutable()
        .filter((i) => this.props.invoice.departments.includes(i.id));
      const defaultDepartmentGroups = this.props.departmentGroups
        .asMutable()
        .filter((dg) => this.props.invoice?.department_groups?.includes(dg.id));
      let emails = [];
      if (this.props.invoice.emails) {
        emails = this.props.invoice.emails.map((e) => ({ label: e, value: e }));
      }
      const invoice = {
        name: this.props.invoice.name,
        job_types: this.props.invoice.job_types,
        emails: this.props.invoice.emails ? this.props.invoice.emails : [],
        billing_cycle: this.props.invoice.billing_cycle,
        date: this.props.invoice.date ? moment(this.props.invoice.date).format('YYYY-MM-DDTHH:mm') : null,
        completed_jobs_only: this.props.invoice.completed_jobs_only,
        force_break_time: this.props.invoice.force_break_time
          ? this.props.invoice.force_break_time.substring(0, 5)
          : '00:30',
        departments: this.props.invoice.departments,
        department_groups: this.props.invoice.department_groups || [],
        excel_template_id: this.props.invoice.excel_template_id || null,
        validate_billing_status: this.props.invoice.validate_billing_status,
        include_missing_po: this.props.invoice.include_missing_po,
        generate_automatically: this.props.invoice.generate_automatically,
      };
      this.setState({
        invoice,
        defaultDepartments,
        defaultDepartmentGroups,
        defaultEmails: emails,
        invoiceId: this.props.invoice.id,
      });
    }
  }

  componentWillUnmount = () => {
    this.setState({ ..._state }, () => this.props.closeSlide());
  };

  handleClose = () => {
    this.setState({ ..._state }, () => this.props.closeSlide());
  };

  handleSubmit = async () => {
    this.setState({ processing: true });
    try {
      await this.props.submit(this.state.invoice);
      this.setState({ processing: false }, () => this.handleClose());
    } catch (error) {
      this.setState({ processing: false });
    }
  };

  handleChangeBillingType = (billingType) => () => {
    const inv = this.state.invoice;
    inv.date = null;
    this.setState({ invoice: inv });
    this.handleChangeValue('billing_cycle', billingType);
  };

  handleInputChange = (event) => {
    const { name, value } = event.currentTarget;
    this.handleChangeValue(name, value);
  };

  handleChangeValue = (name: keyof (typeof _state)['invoice'], value) => {
    this.setState((state) => ({ ...state, invoice: { ...state.invoice, [name]: value } }));
  };

  handleChangeDateValue = (name, value) => {
    this.setState({ invoice: { ...this.state.invoice, [name]: value, billing_cycle: null } });
  };

  onDepartmentSelect = (department = [], departmentGroups = []) => {
    this.setState({ defaultDepartments: department, defaultDepartmentGroups: departmentGroups });
    this.handleChangeValue(
      'departments',
      department.map((dep) => dep.id)
    );
    this.handleChangeValue(
      'department_groups',
      departmentGroups.map((dep) => dep.id)
    );
  };

  toggleJobType = (jobType: number) => {
    const idx = this.state.invoice.job_types.findIndex((price) => price === jobType);
    if (!~idx) {
      this.handleChangeValue('job_types', [...this.state.invoice.job_types, jobType]);
      return;
    }
    this.handleChangeValue(
      'job_types',
      this.state.invoice.job_types.filter((price) => price !== jobType)
    );
  };

  renderJobType = (jobType: number) => (
    <button
      type="button"
      className={`btn ${this.state.invoice.job_types.indexOf(jobType) !== -1 ? 'active' : ''}`}
      onClick={() => this.toggleJobType(jobType)}
    >
      {JobType[jobType]}
    </button>
  );

  isEmailValid = (email: string) => emailRegExp.test(email);

  getDatePickerError = (date: string) => {
    if (this.state.invoice.billing_cycle) return '';
    if (!date) return 'Date is required field';
    if (!moment(date).isValid()) return 'Invalid date';
    if (!moment(date).isAfter(moment('2020-01-01'))) return 'Date is too early';
    if (!moment(date).isBefore(moment(maximumYearValue))) return 'Date is too late';
    return '';
  };

  public render() {
    const isNameLengthValid = this.state.invoice.name ? this.state.invoice.name.length <= 64 : true;
    const isNameValid = this.state.invoice.name
      ? invoiceConfigurationNameRegExp.test(this.state.invoice.name) && isNameLengthValid
      : true;
    const isDateValid = !this.getDatePickerError(this.state.invoice.date);

    return (
      <Dialog
        open={this.props.showed}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={'slide-container-invoices showed-invoices'}>
          <div className="slide-content">
            <div className="slide-header d-flex align-items-center justify-content-between">
              <div className="slide-title">Invoice Configuration</div>
              <div className="close-slider">
                <img className="cursor-pointer p-1" onClick={this.handleClose} src={closeRegular} alt="" />
              </div>
            </div>
            <div className="border-bottom mb27">
              <div className="sub-slide-title">Fill Details for new invoice</div>
            </div>
            <div className="slide-body ">
              <div className="slide-body-item-content" style={{ marginBottom: '1rem' }}>
                <label htmlFor="department" className="d-block first-title">
                  Invoice Configuration Name
                </label>
                <FormControl>
                  <TextField
                    id="name"
                    variant="outlined"
                    name="name"
                    value={this.state.invoice.name}
                    onChange={this.handleInputChange}
                    size="small"
                    error={!isNameValid}
                    helperText={
                      !isNameLengthValid
                        ? 'Name is too long. Max length is 64 characters'
                        : !isNameValid
                        ? 'Name can contain only letters, numbers, spaces, dashes, underscores and dots'
                        : ''
                    }
                  />
                </FormControl>
              </div>

              <div className="slide-body-item-content" style={{ marginBottom: '1rem' }}>
                <label htmlFor="department" className="d-block first-title">
                  Select Job types *
                </label>
                <FormControl required>
                  <div className="btn-group group-job-type w-100" role="group">
                    {this.renderJobType(JobType.Flagging)}
                    {this.renderJobType(JobType.Parking)}
                    {this.renderJobType(JobType.Signage)}
                  </div>
                </FormControl>
              </div>

              <div className="slide-body-item-content">
                <div className="form-group deps">
                  <label htmlFor="department" className="d-block first-title">
                    Select Department *
                  </label>
                  <FormControl required>
                    <DepartmentMaterialAsyncSearch
                      noLabel={true}
                      limitTags={10}
                      width={'100%'}
                      departments={this.state.defaultDepartments}
                      departmentGroups={this.state.defaultDepartmentGroups}
                      onSelect={this.onDepartmentSelect}
                      showNonInvoiced={this.state.showNonInvoicedDepts}
                    />
                  </FormControl>

                  <CheckboxComponent
                    hasTitle="Show Non Invoiced Departments"
                    id="non_invoiced_depts"
                    checked={this.state.showNonInvoicedDepts}
                    onChange={(checked) => this.setState({ showNonInvoicedDepts: checked })}
                    className="mt-2"
                  />
                </div>
              </div>

              <div>
                <div className="slide-body-item-header first-title">Set billing cycle</div>
                <div className="slide-body-item-content">
                  <div className="pl9">
                    <RadioCustomComponent
                      title="Weekly"
                      name="billing_cycle"
                      id="Weekly"
                      checked={this.state.invoice.billing_cycle === BILLING_CYCLE.Weekly}
                      onChange={this.handleChangeBillingType(BILLING_CYCLE.Weekly)}
                    />
                    <RadioCustomComponent
                      title="Monthly"
                      id="Monthly"
                      name="billing_cycle"
                      checked={this.state.invoice.billing_cycle === BILLING_CYCLE.Monthly}
                      onChange={this.handleChangeBillingType(BILLING_CYCLE.Monthly)}
                    />
                    <RadioCustomComponent
                      title="Daily"
                      id="Daily"
                      name="billing_cycle"
                      checked={this.state.invoice.billing_cycle === BILLING_CYCLE.Daily}
                      onChange={this.handleChangeBillingType(BILLING_CYCLE.Daily)}
                    />
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap={10}>
                      <RadioCustomComponent
                        title="Set Specific Date and Time"
                        id="BillingCycle"
                        name="billing_cycle"
                        checked={!this.state.invoice.billing_cycle}
                        onChange={(checked) => this.handleChangeDateValue('date', new Date())}
                      />
                      {!this.state.invoice.billing_cycle && (
                        <DatePicker
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: !isDateValid,
                              helperText: this.getDatePickerError(this.state.invoice.date),
                            },
                          }}
                          value={this.state.invoice.date ? moment(this.state.invoice.date) : null}
                          onChange={(date) => this.handleChangeDateValue('date', date?.format('YYYY-MM-DD') || null)}
                          format="MM.DD.YYYY"
                        />
                      )}
                    </Box>
                  </div>

                  <div className="form-group mt-3 ce-date d-flex justify-content"></div>
                </div>
                <hr />
              </div>
              <div className="slide-body-item-content pl5">
                <CheckboxComponent
                  hasTitle="Validate Billing Status"
                  id="validate_billing_status"
                  checked={!!this.state.invoice.validate_billing_status}
                  onChange={(checked) => this.handleChangeValue('validate_billing_status', checked)}
                  className="mb-2"
                />
                <CheckboxComponent
                  hasTitle="Include Missing PO"
                  id="include_missing_po"
                  checked={!!this.state.invoice.include_missing_po}
                  onChange={(checked) => this.handleChangeValue('include_missing_po', checked)}
                  className="mb-2"
                />
                <CheckboxComponent
                  hasTitle="Generate Automatically"
                  id="generate_automatically"
                  checked={!!this.state.invoice.generate_automatically}
                  onChange={(checked) => this.handleChangeValue('generate_automatically', checked)}
                  className="mb-2"
                />
              </div>

              <div className="d-flex justify-content">
                <div className="form-group width-48">
                  <label className="d-block first-title" htmlFor="exceltemplate">
                    Excel Template *
                  </label>
                  <FormControl required>
                    {this.props.invoice ? (
                      <Select
                        value={this.state.templateOptions.find(
                          (template) => template.value === this.state.invoice.excel_template_id
                        )}
                        options={this.state.templateOptions}
                        onChange={({ value }) => this.handleChangeValue('excel_template_id', value)}
                        menuPosition="fixed"
                        menuPlacement="top"
                        menuShouldScrollIntoView
                      />
                    ) : null}
                    {!this.props.invoice && (
                      <Select
                        options={this.state.templateOptions}
                        onChange={({ value }) => this.handleChangeValue('excel_template_id', value)}
                        menuPosition="fixed"
                        menuPlacement="top"
                        menuShouldScrollIntoView
                      />
                    )}
                  </FormControl>
                </div>
                <div className="form-group width-48">
                  <label className="d-block first-title" htmlFor="emailto">
                    Email to
                  </label>
                  {this.props.invoice && this.state.defaultEmails && (
                    <CreatableSelect
                      isMulti
                      isClearable
                      name="emails"
                      isValidNewOption={this.isEmailValid}
                      formatCreateLabel={(email) => `Send email to ${email}`}
                      defaultValue={this.state.defaultEmails}
                      onChange={(emails) => {
                        this.handleChangeValue(
                          'emails',
                          Array.isArray(emails) ? emails.map((email) => email.value) : []
                        );
                      }}
                    />
                  )}
                  {!this.props.invoice && (
                    <CreatableSelect
                      isMulti
                      isClearable
                      name="emails"
                      isValidNewOption={this.isEmailValid}
                      formatCreateLabel={(email) => `Send email to ${email}`}
                      onChange={(emails) =>
                        this.handleChangeValue(
                          'emails',
                          Array.isArray(emails) ? emails.map((email) => email.value) : []
                        )
                      }
                    />
                  )}
                </div>
              </div>

              <div className="sub-slide-title">*Required</div>

              <hr style={{ marginTop: '0' }} />

              <div className="d-flex justify-content" style={{ marginBottom: '15px' }}>
                <Button
                  color={'gray'}
                  width={'158px'}
                  borderRadius={'20px'}
                  textTransform={false}
                  onClick={this.handleClose}
                >
                  Cancel
                </Button>
                <Tooltip
                  disableInteractive
                  title={
                    !(
                      this.state.invoice.excel_template_id &&
                      this.state.invoice.job_types?.length !== 0 &&
                      (this.state.invoice.departments?.length || this.state.invoice.department_groups?.length)
                    )
                      ? 'Fill the required fields!'
                      : ''
                  }
                  arrow
                  aria-label="disabled button"
                >
                  <div>
                    <Button
                      color={'dark'}
                      width={'158px'}
                      borderRadius={'20px'}
                      textTransform={false}
                      onClick={this.handleSubmit}
                      processing={this.state.processing}
                      disabled={
                        !(
                          this.state.invoice.excel_template_id &&
                          this.state.invoice.job_types?.length !== 0 &&
                          (this.state.invoice.departments?.length || this.state.invoice.department_groups?.length) &&
                          isNameValid &&
                          isDateValid
                        )
                      }
                    >
                      Confirm
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps({ app }: ReduxState) {
  return {
    departments: app.departments,
    departmentGroups: app.departmentGroups,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddInvoiceSliderComponent);
