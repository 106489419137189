import { RouteComponentProps } from 'react-router-dom';

import { useSearchParams } from 'Utils/hooks/useSearchParams';

import Timesheets from './Timesheets';
import GrouppedTimesheets from './components/GrouppedTimesheets/GrouppedTimesheets';

type Props = {} & RouteComponentProps;

const TimesheetsPage = (props: Props) => {
  const { params } = useSearchParams<{ grouped: '1' }>();

  return (
    <div className="timesheet-list-page">
      {params.grouped === '1' ? <GrouppedTimesheets {...props} /> : <Timesheets {...props} />}
    </div>
  );
};

export default TimesheetsPage;
