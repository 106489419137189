import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Props = Partial<{
  baseUrl?: string;
}>;

export const useSearchParams = <T>({ baseUrl }: Props = { baseUrl: '/' }) => {
  const { search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const params = useMemo(() => Object.fromEntries(searchParams.entries()) as T, [searchParams]);

  const setSearchParam = <K extends keyof T>(key: K, value: T[K]) => {
    searchParams.set(key as string, value as string);
    history.push({
      search: searchParams.toString(),
    });
  };

  const removeSearchParam = (key: keyof T) => {
    searchParams.delete(key as string);
    history.push({ search: searchParams.toString() });
  };

  const toBase = () => {
    history.push(baseUrl);
  };

  return {
    params,
    setSearchParam,
    removeSearchParam,
    toBase,
  };
};
