import { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import { CloseRounded, Done, DoneAll, GetApp, VerifiedUser } from '@mui/icons-material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box, Popover } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { GrouppedTimesheetItem, TimesheetTableItem } from 'types/Timesheet';

import { baseToastConfig } from 'Constants/app';
import { actions } from 'Services';
import UserPermissions from 'Utils/PermissionsHelper';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import UnverifyIcon from 'components/UnverifyIcon';
import { usePopoverContext } from 'context/PopoversContext';
import { useAppSelector } from 'createStore';

import ApproveTimesheetsModal from '../ApproveTimesheetsModal/ApproveTimesheetsModal';
import classes from './SelectedTimesheetActions.module.scss';
import TimesheetActionButton from './TimesheetActionButton';

const StyledActionButton = (props: Parameters<typeof TimesheetActionButton>[0]) => {
  return (
    <TimesheetActionButton tooltipProps={{ classes: { tooltip: classes.lightTooltip }, arrow: false }} {...props} />
  );
};

type Props = {
  anchorEl: Element;
  selectedTimesheets: TimesheetTableItem[];
  handleClose: () => void;
  uncheckSome?: (timesheets: TimesheetTableItem[]) => void;
  hideUncheckButton?: boolean;
  immediatelyClose?: boolean;
  selectedGroup?: GrouppedTimesheetItem;
};

const SelectedTimesheetActions = ({
  anchorEl = null,
  selectedTimesheets = [],
  handleClose = () => {},
  uncheckSome = () => {},
  hideUncheckButton = false,
  immediatelyClose = false,
  selectedGroup = null,
}: Props) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const downloading = useAppSelector((state) => state.timesheets.downloadingChecked);
  const { openPopover, closePopover } = usePopoverContext();

  const updateTimesheets = (
    timesheetsIds: number[],
    update: Parameters<(typeof actions)['TimesheetsActions']['updateMultiple']>['1']
  ) =>
    dispatch(
      selectedGroup?.next_signature_type && update.sign
        ? actions.TimesheetsActions.approveMultipleWithServiceDept(timesheetsIds, {
            signature_data: update.sign,
            employee_number: update.employee_number,
            signature_type_id: selectedGroup.next_signature_type.id,
          })
        : actions.TimesheetsActions.updateMultiple(timesheetsIds, update)
    )
      .then(() => showSuccessMessage('Timesheets were updated!'))
      .catch(showErrorMessage);

  const downloadPdf = async () => {
    if (immediatelyClose) {
      handleClose();
    }
    try {
      const timesheetsCount = selectedTimesheets.length;
      const response = await dispatch(
        actions.TimesheetsActions.downloadMultiple(selectedTimesheets.map(({ id }) => id))
      );
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `timesheets - ${timesheetsCount} items.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      const message = error.error || 'Something went wrong';
      toast.error(message, baseToastConfig);
    } finally {
      handleClose();
    }
  };

  const updateGrouppedTimesheets =
    (timesheets: TimesheetTableItem[] = []) =>
    () =>
      new Set(timesheets.map(({ confirmationNumber }) => confirmationNumber)).forEach((conf) =>
        dispatch(actions.GrouppedTimesheetsActions.refetchTimesheetsWithConf(conf))
      );

  const makeCesVerified = () => {
    // We need to filter timesheets, which don't need to update to prevent extra history records
    const onlyUnverified = selectedTimesheets.filter(({ isVerified }) => !isVerified);
    updateTimesheets(
      onlyUnverified.map(({ id }) => id),
      { verified: 1 }
    ).then(updateGrouppedTimesheets(onlyUnverified));
    handleClose();
  };

  const makeWorkerPaid = () => {
    // We need to filter timesheets, which don't need to update to prevent extra history records
    const onlyWorkerUnpaid = selectedTimesheets.filter(({ workerPaid }) => !workerPaid);
    updateTimesheets(
      onlyWorkerUnpaid.map(({ id }) => id),
      { worker_paid: 1 }
    ).then(updateGrouppedTimesheets(onlyWorkerUnpaid));
    handleClose();
  };

  const makeCESUnverified = () => {
    // We need to filter timesheets, which don't need to update to prevent extra history records
    const onlyCESVerified = selectedTimesheets.filter(({ isVerified }) => isVerified);
    updateTimesheets(
      onlyCESVerified.map(({ id }) => id),
      { verified: 0 }
    ).then(updateGrouppedTimesheets(onlyCESVerified));
    handleClose();
  };

  const makeWorkerUnpaid = () => {
    // We need to filter timesheets, which don't need to update to prevent extra history records
    const onlyWorkerPaid = selectedTimesheets.filter(({ workerPaid }) => workerPaid);
    updateTimesheets(
      onlyWorkerPaid.map(({ id }) => id),
      { worker_paid: 0 }
    ).then(updateGrouppedTimesheets(onlyWorkerPaid));
    handleClose();
  };

  const openApproveTimesheetsModal = () => {
    handleClose();
    openPopover(
      'approve-selected-timesheets-modal',
      <ApproveTimesheetsModal
        open
        modalId="approve-selected-timesheets-modal"
        onClose={() => closePopover('approve-selected-timesheets-modal')}
        selectedTimesheets={selectedTimesheets}
        onApprove={(timesheetIds, update) =>
          updateTimesheets(timesheetIds, update)
            .then(updateGrouppedTimesheets(selectedTimesheets))
            .finally(() => closePopover('approve-selected-timesheets-modal'))
        }
      />
    );
  };

  const { notClockedOut, notInvoiced, invoiced, paid, notVerified } = selectedTimesheets.reduce(
    (acc, timesheet) => {
      // If all conditions is true - just skip and return acc;
      if (acc.notClockedOut && acc.notInvoiced && acc.invoiced && acc.paid) return acc;
      let { notClockedOut, notInvoiced, invoiced, paid, notVerified } = acc;

      //   If some condition already true - keep it true, othervise - assign new value
      if (!notClockedOut) {
        notClockedOut = timesheet.status !== 'clocked_out';
      }
      if (!notInvoiced) {
        notInvoiced = !timesheet.invoiced && !timesheet.paid;
      }
      if (!invoiced) {
        invoiced = Boolean(timesheet.invoiced);
      }
      if (!paid) {
        paid = Boolean(timesheet.paid);
      }
      if (!notVerified) {
        notVerified = !timesheet.isVerified;
      }
      return { notClockedOut, notInvoiced, invoiced, paid, notVerified };
    },
    { notClockedOut: false, notInvoiced: false, invoiced: false, paid: false, notVerified: false }
  );

  const { notClockedOutCount, notInvoicedCount, invoicedCount, paidCount, workerPaidCount, supervisorVerifiedCount } =
    selectedTimesheets.reduce(
      (acc, timesheet) => {
        let {
          notClockedOutCount,
          notInvoicedCount,
          invoicedCount,
          paidCount,
          workerPaidCount,
          supervisorVerifiedCount,
        } = acc;
        const { status, invoiced, paid, workerPaid, supervisor_verified } = timesheet;
        if (status !== 'clocked_out') {
          notClockedOutCount += 1;
        }
        if (!invoiced && !paid) {
          notInvoicedCount += 1;
        }
        if (invoiced) {
          invoicedCount += 1;
        }
        if (paid) {
          paidCount += 1;
        }

        if (workerPaid) {
          workerPaidCount += 1;
        }

        if (supervisor_verified) {
          supervisorVerifiedCount += 1;
        }

        return {
          notClockedOutCount,
          notInvoicedCount,
          invoicedCount,
          paidCount,
          workerPaidCount,
          supervisorVerifiedCount,
        };
      },
      {
        notClockedOutCount: 0,
        notInvoicedCount: 0,
        invoicedCount: 0,
        paidCount: 0,
        workerPaidCount: 0,
        supervisorVerifiedCount: 0,
      }
    );

  const allWorkerPaid = workerPaidCount === selectedTimesheets.length;
  const allPaid = paidCount === selectedTimesheets.length;
  const allCEApproved = supervisorVerifiedCount === selectedTimesheets.length;

  const uncheckNotClockedOut = () => {
    uncheckSome(selectedTimesheets.filter(({ status }) => status === 'clocked_out'));
  };

  const unchekNotInvoiced = () => {
    uncheckSome(selectedTimesheets.filter(({ invoiced }) => Boolean(invoiced)));
  };

  const uncheckInvoicedAndPaid = () => {
    uncheckSome(selectedTimesheets.filter(({ invoiced, paid }) => !invoiced && !paid));
  };

  const uncheckPaid = () => {
    uncheckSome(selectedTimesheets.filter(({ paid }) => !paid));
  };

  const TooltipWithAction = ({ title = '', buttonTitle = 'Uncheck Them', onUncheck = () => {} }) => (
    <div>
      <div>{title}</div>
      <div className={classes.uncheckButton} onClick={onUncheck}>
        {buttonTitle}
      </div>
    </div>
  );

  const titles = {
    notClockedOut:
      notClockedOut &&
      TooltipWithAction({
        title: 'Some timesheets are not clocked out',
        onUncheck: uncheckNotClockedOut,
        buttonTitle: hideUncheckButton ? '' : `Uncheck Them(${notClockedOutCount} items)`,
      }),
    notInvoiced:
      !invoiced &&
      !paid &&
      TooltipWithAction({
        title: 'Some timesheets are not invoiced',
        onUncheck: unchekNotInvoiced,
        buttonTitle: hideUncheckButton ? '' : `Uncheck Them(${notInvoicedCount} items)`,
      }),
    invoiced:
      (invoiced || paid) &&
      TooltipWithAction({
        title: 'Some timesheets are Invoiced or Invoice Paid. You cannot change CES Verified value for them',
        onUncheck: uncheckInvoicedAndPaid,
        buttonTitle: hideUncheckButton ? '' : `Uncheck Them(${invoicedCount} items)`,
      }),
    paid:
      paid &&
      TooltipWithAction({
        title: 'Some timesheets are Invoice Paid',
        onUncheck: uncheckPaid,
        buttonTitle: hideUncheckButton ? '' : `Uncheck Them(${paidCount} items)`,
      }),
    allVerified:
      !notVerified && TooltipWithAction({ title: 'All timesheets have already been verified', buttonTitle: '' }),
    allWorkerPaid:
      allWorkerPaid && TooltipWithAction({ title: 'All timesheets have already been Worker Paid', buttonTitle: '' }),
    allPaid: allPaid && TooltipWithAction({ title: 'All timesheets have already been Invoice Paid', buttonTitle: '' }),
    allCEApproved:
      allCEApproved && TooltipWithAction({ title: 'All timesheets have already been approved', buttonTitle: '' }),
  };

  const getConditionalTitle = (conditions: (string | JSX.Element)[] = []) => conditions.find(Boolean) || '';

  const canSignTimesheets = useMemo(() => {
    if (!selectedGroup) return false;
    if (!selectedGroup.next_signature_type) return selectedGroup.can_be_signed && UserPermissions.has.sign_timesheet;
    return selectedGroup.next_signature_type.can_be_signed;
  }, [selectedGroup]);

  const canDoTimesheetAction = UserPermissions.has.can_do_timesheet_action;

  const allSelectedAreWorkerUnpaid = selectedTimesheets.every(({ workerPaid }) => !workerPaid);
  const allSelectedAreCesUnverified = selectedTimesheets.every(({ isVerified }) => !isVerified);
  const someOfSelectedIsInvoiced = selectedTimesheets.some(({ invoiced }) => invoiced);

  return (
    <>
      <Popover
        id={'row-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: classes.paper }}
      >
        <Box display="flex" flexDirection="column">
          <StyledActionButton
            onClick={downloadPdf}
            Icon={<GetApp />}
            tooltipTitle={downloading ? 'Downloading...' : 'Download pdf file with all selected timesheets'}
            disabled={downloading}
            tooltipProps={{ disableInteractive: hideUncheckButton }}
          >
            Download .pdfs
          </StyledActionButton>

          {canSignTimesheets && (
            <StyledActionButton
              tooltipTitle={getConditionalTitle([titles.allCEApproved])}
              Icon={<EventAvailableIcon />}
              onClick={openApproveTimesheetsModal}
              disabled={allCEApproved}
              tooltipProps={{ disableInteractive: hideUncheckButton }}
            >
              Approve timesheets group
            </StyledActionButton>
          )}

          {canDoTimesheetAction && (
            <>
              <StyledActionButton
                tooltipTitle={getConditionalTitle([
                  titles.notClockedOut,
                  titles.invoiced,
                  titles.paid,
                  titles.allVerified,
                ])}
                onClick={makeCesVerified}
                Icon={<VerifiedUser />}
                disabled={notClockedOut || invoiced || paid || !notVerified}
                tooltipProps={{ disableInteractive: hideUncheckButton }}
              >
                Mark selected 'CES Verified'
              </StyledActionButton>

              <StyledActionButton
                tooltipTitle={getConditionalTitle([titles.notClockedOut, titles.allWorkerPaid])}
                onClick={makeWorkerPaid}
                Icon={<Done />}
                disabled={notClockedOut || allWorkerPaid}
                tooltipProps={{ disableInteractive: hideUncheckButton }}
              >
                Mark selected 'Worker Paid'
              </StyledActionButton>
              <StyledActionButton
                tooltipTitle={
                  allSelectedAreCesUnverified
                    ? 'All selected timesheets are CES Unverified'
                    : someOfSelectedIsInvoiced
                    ? 'Some selected timesheets are invoiced'
                    : ''
                }
                onClick={makeCESUnverified}
                Icon={<UnverifyIcon />}
                disabled={allSelectedAreCesUnverified || someOfSelectedIsInvoiced}
                tooltipProps={{ disableInteractive: hideUncheckButton }}
              >
                Mark selected 'CES Unverified'
              </StyledActionButton>
              <StyledActionButton
                tooltipTitle={allSelectedAreWorkerUnpaid ? 'All selected timesheets are Worker Unpaid' : ''}
                onClick={makeWorkerUnpaid}
                Icon={<CloseRounded />}
                disabled={allSelectedAreWorkerUnpaid}
                tooltipProps={{ disableInteractive: hideUncheckButton }}
              >
                Mark selected 'Worker Unpaid'
              </StyledActionButton>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default memo(SelectedTimesheetActions);
