import { useEffect } from 'react';

import clsx from 'clsx';

import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';

import { JobShiftForReroute } from 'types/Common/JobData';

import { JobType, JobTypeHelper } from 'Constants/job';
import { Structure_Validation } from 'Constants/validation';

import { MapLocationItem } from './MapSelect';
import styles from './RerouteWorkersList.module.scss';

type Props = {
  jobType: JobType | string;
  location: MapLocationItem;
  workers: JobShiftForReroute[];
  closeModal: () => void;
  unselectWorker: (worker: JobShiftForReroute) => void;
  isDefaultLocationSelected?: boolean;
  onChangeWorkers: (updatedWorkers: JobShiftForReroute[]) => void;
};

const RerouteWorkersList = ({ jobType, location, workers, closeModal, unselectWorker, onChangeWorkers }: Props) => {
  useEffect(() => {
    if (workers.length < 1) {
      closeModal();
    }
  }, [workers.length, closeModal]);

  const onChangeTextField = ({ target: { value, name } }, index: number) => {
    const updatedWorkers = workers.map((worker, workerIndex) =>
      workerIndex === index ? { ...worker, [name]: value } : worker
    );
    onChangeWorkers(updatedWorkers);
  };

  const getStructureError = (structure: string) => {
    try {
      Structure_Validation.validateSync(structure);
      return '';
    } catch (error) {
      return error.message;
    }
  };
  const selectedDefaultLocation = location?.exist ?? false;

  return (
    <div className={styles.workersList}>
      {workers.map((worker, index) => {
        const structureErrorMessage = getStructureError(worker?.structure);
        return (
          <div key={String(worker.shift_id)} className={styles.worker}>
            <div className={styles.workerInfoRow}>
              <span onClick={() => unselectWorker(worker)}>
                <CancelIcon className="cursor-pointer" />
              </span>

              <img className={styles.avatar} alt="avatar" src={`${worker.worker?.avatar}`} />

              <div className={styles.workerName}>{worker.worker?.name}</div>
            </div>
            <div className={styles.input}>
              <TextField
                label="Structure Number"
                type="text"
                name={'structure'}
                InputLabelProps={{ shrink: !!worker.structure }}
                value={worker.structure || ''}
                size="small"
                fullWidth
                disabled={selectedDefaultLocation}
                onChange={(event) => onChangeTextField(event, index)}
                error={!!structureErrorMessage && !!location}
                helperText={location ? structureErrorMessage : ''}
              />
            </div>

            <div className={clsx(styles.input, styles.commentInput)}>
              <TextField
                disabled={selectedDefaultLocation}
                label="Comment"
                size="small"
                name="note"
                value={worker.note}
                InputLabelProps={{ shrink: !!worker.note }}
                fullWidth
                multiline
                maxRows={2}
                onChange={(event) => onChangeTextField(event, index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RerouteWorkersList;
