import React, { Component } from 'react';

import { Icon, TextField, styled } from '@mui/material';

import classes from './Search.module.scss';

const TextFieldStyled = styled(TextField)({
  minWidth: 120,
  border: 'none',
  '& .MuiInput-underline.Mui-disabled:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline.Mui-focused:after': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
});

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_value: '',
      focused: false,
      initSearch: true,
      instant: props.instant ? props.instant : false,
    };
    this.handleChange = this._handleChange.bind(this);
    this.handleBlur = this._handleBlur.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.initSearch) {
      return {
        search_value: props.search_value,
        initSearch: false,
      };
    }
    return null;
  }

  _handleChange(e) {
    this.setState(
      {
        search_value: e.target.value,
      },
      () => {
        if (this.props.instant) {
          this.props.updateFilters({ search: this.state.search_value, page: 1 });
        }
      }
    );
  }

  _handleBlur() {
    if (this.state.focused) {
      this.props.updateFilters({ search: this.state.search_value, page: 1 });
    }
    this.setState({ focused: false });
  }

  showSearch = () => {
    this.setState({ focused: true });
    document.addEventListener('keydown', this.enterFunction, false);
  };

  enterFunction = (event) => {
    if (event.keyCode === 13 && this.state.focused) {
      /*let page = this.props.page;
            if(this.props.search_value !== this.state.search_value){
                page = 1;
            }*/
      this.props.updateFilters({ search: this.state.search_value, page: 1 });
    }
  };

  render() {
    const { placeholder = 'Search', dialog } = this.props;

    if (dialog) {
      return (
        <TextFieldStyled
          fullWidth
          value={this.state.search_value}
          onChange={this.handleChange}
          onFocus={this.showSearch}
          autoFocus={this.props.autoFocus ? true : false}
          placeholder={placeholder}
          size="small"
          onBlur={this.handleBlur}
        />
      );
    }

    return (
      <div className={classes.root}>
        <Icon className={classes.icon} color="action">
          search
        </Icon>
        <TextFieldStyled
          fullWidth
          value={this.state.search_value}
          onChange={this.handleChange}
          onFocus={this.showSearch}
          autoFocus={this.props.autoFocus ? true : false}
          placeholder={placeholder}
          type="search"
          onBlur={this.handleBlur}
        />
      </div>
    );
  }
}

export default Search;
