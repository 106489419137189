import { useCallback, useRef, useState } from 'react';

export const useWaitTimer = (initialTimer = 0) => {
  const [timer, setTimer] = useState(initialTimer);
  const intervalId = useRef<NodeJS.Timeout>();

  const runTimer = useCallback((timerValue?: number) => {
    if (timerValue === 0) {
      setTimer(0);
      return;
    }
    setTimer(timerValue || initialTimer);
    intervalId.current = setInterval(() => {
      setTimer((prev) => {
        if (prev) {
          return prev - 1;
        }
        clearInterval(intervalId.current);
        intervalId.current = null;
      });
    }, 1000);
  }, []);

  const clearTimer = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, []);

  return { timer, runTimer, clearTimer };
};

export function withWaitTimer<Props>(Component: React.ComponentClass<Props>) {
  const Wrapper = (props: Props) => {
    const { timer, runTimer, clearTimer } = useWaitTimer(0);

    return <Component checkTimeout={timer} runTimer={runTimer} clearTimer={clearTimer} {...props} />;
  };

  return Wrapper;
}
