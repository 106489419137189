import { Button, Menu, MenuItem, styled } from '@mui/material';

export const StyledProfileMenuButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  borderRadius: 8,

  '& .MuiTypography-root': {
    fontFamily: 'Poppins Medium, sans-serif',
  },

  '& .MuiIcon-root': {
    width: 'max-content',
    height: 'max-content',
    transition: 'all 0.15s ease-in-out',
    svg: {
      width: 14,
      height: 14,
    },
  },
});

export const StyledProfileMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    borderRadius: 8,
    boxShadow: '0px 4px 22px 0px #00000020',
  },
  '& .MuiList-root': {
    padding: 10,
  },
  '& .MuiTypography-root': {
    fontFamily: 'Poppins Medium, system-ui, sans-serif',
    color: 'inherit',
  },

  '& .MuiDivider-root': {
    marginBlock: 6,
  },
});

export const StyledProfileMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{
  isActive?: boolean;
}>(({ theme: { palette }, isActive = false }) => ({
  display: 'flex',
  gap: '10px',
  padding: '6px 10px',
  borderRadius: 8,
  transition: 'all 0.15s ease-in-out',
  color: isActive ? palette.primary.main : palette.text.primary,
  backgroundColor: isActive ? palette.primary.light : 'transparent',
  opacity: isActive ? 1 : 0.6,

  '&:hover': {
    backgroundColor: palette.primary.light,
    opacity: 1,
  },
  '& .MuiListItemIcon-root': {
    width: '15px',
    height: '15px',
    minWidth: 'auto',
    color: 'inherit',
    svg: {
      width: 15,
      height: 15,
    },
  },
}));
