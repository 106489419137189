import React, { useCallback, useState, MouseEvent } from 'react';

import { FaHardHat } from 'react-icons/fa';

import {
  AssignmentRounded,
  DoneAllRounded,
  MonetizationOnRounded,
  PeopleAltRounded,
  VerifiedUser,
  WorkRounded,
} from '@mui/icons-material';
import { Box, Icon, Tooltip } from '@mui/material';

import { TimesheetTableItem } from 'types/Timesheet';

import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';

import SelectedTimesheetActions from '../../Dialog/TimesheetActions/SelectedTimesheetActions';
import ActionButton from '../ActionButton';
import s from './SelectedTimesheetsSummary.module.css';

type Props = {
  timesheets?: TimesheetTableItem[];
  canSeeSubcontractors: boolean;
  changeSelectedTimesheets?: (newSelected: TimesheetTableItem[]) => void;
};

const SelectedTimesheetsSummary = ({
  timesheets = [],
  canSeeSubcontractors = false,
  changeSelectedTimesheets = () => {},
}: Props) => {
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const closeActions = useCallback(() => setActionsAnchorEl(null), []);
  const assignAnchor = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => setActionsAnchorEl(event.currentTarget),
    []
  );

  const uncheckTimesheets = useCallback(
    (selectedTimesheets: TimesheetTableItem[]) => {
      if (!selectedTimesheets.length) {
        setActionsAnchorEl(null);
      }
      changeSelectedTimesheets(selectedTimesheets);
    },
    [changeSelectedTimesheets]
  );
  const { isDesktop } = useAppMediaQuery();
  if (!timesheets.length) return null;

  const timesheetsCount = timesheets.length;
  const jobs = new Set(timesheets.map(({ confirmationNumber }) => confirmationNumber));
  const workers = new Set(timesheets.map(({ worker_name }) => worker_name));
  const subcontractors = new Set(timesheets.map(({ subcontractorName }) => subcontractorName));
  const cesVerifiedCount = timesheets.filter(({ isVerified }) => isVerified).length;
  const paidCount = timesheets.filter(({ paid }) => paid).length;
  const workerPaidCount = timesheets.filter(({ workerPaid }) => workerPaid).length;

  const selectedTimesheetsSummaryList = [
    {
      label: 'Timesheets',
      icon: <AssignmentRounded />,
      value: timesheetsCount,
    },
    {
      label: 'Jobs',
      icon: <WorkRounded />,
      value: jobs.size,
    },
    {
      label: 'Workers',
      icon: <PeopleAltRounded />,
      value: workers.size,
    },
    {
      label: 'Subcontractors',
      icon: <FaHardHat size={24} />,
      value: subcontractors.size,
      condition: canSeeSubcontractors,
    },
    {
      label: 'CES Verified',
      icon: <VerifiedUser />,
      value: `${cesVerifiedCount}/${timesheetsCount}`,
    },
    {
      label: 'Worker Paid',
      icon: <MonetizationOnRounded />,
      value: `${workerPaidCount}/${timesheetsCount}`,
    },
    {
      label: 'Invoice Paid',
      icon: <DoneAllRounded />,
      value: `${paidCount}/${timesheetsCount}`,
    },
  ];

  return (
    <div
      className={s.container}
      style={{
        opacity: timesheetsCount ? 1 : 0.3,
        pointerEvents: timesheetsCount ? 'all' : 'none',
      }}
    >
      <div className={s.summary}>
        {selectedTimesheetsSummaryList.map(
          ({ label, icon, value, condition = true }) =>
            condition && (
              <Tooltip title={!isDesktop && label} arrow disableHoverListener enterTouchDelay={0} key={label}>
                <Box display="flex" alignItems="center" gap={1} minWidth={75}>
                  {icon}
                  <span>
                    {isDesktop && `${label}: `}
                    {value}
                  </span>
                </Box>
              </Tooltip>
            )
        )}
      </div>
      <div className={s.actions}>
        <div className={s.item}>
          <ActionButton onClick={assignAnchor} />
          <SelectedTimesheetActions
            anchorEl={actionsAnchorEl}
            handleClose={closeActions}
            selectedTimesheets={timesheets}
            uncheckSome={uncheckTimesheets}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectedTimesheetsSummary;
