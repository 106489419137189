import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material';
import TextField from '@mui/material/TextField';

import { Role } from 'types/Common/User';

import { CESRoles, ConEdRoles, EROLES } from 'Constants/user';
import { AppAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';

interface Props {
  fullWidth?: boolean;
  onSelectRole?: (item: any) => void;
  onlyDispatcher?: boolean;
  defaultValue?: any;
  selectedRoleIds?: number[];
  limitTags?: any;
  noLabel?: boolean;
  small?: boolean;
  disabledValue?: any;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
  id?: string;
  relatedRolesOnly?: boolean;
}

type RoleOption = {
  label: string;
  value: Role;
};

export default function RolesMaterialAsyncSearch({
  onSelectRole,
  onlyDispatcher,
  defaultValue,
  selectedRoleIds = [],
  limitTags,
  noLabel,
  small,
  disabledValue,
  renderInput,
  fullWidth = false,
  id = 'roles-material-async-search',
  relatedRolesOnly = false,
}: Props) {
  const [rolesOptions, setRolesOptions] = useState<RoleOption[]>([]);

  const fetchRoles = useCallback(async () => {
    try {
      const roles = await AppAPI.getRoles();

      const options = roles.reduce((acc, role) => {
        if (role.id === EROLES.worker) {
          return acc;
        }
        const option = { label: role.name, value: role };

        return [...acc, option];
      }, [] as RoleOption[]);

      if (onlyDispatcher) {
        const dispatcherRoles = [EROLES.dispatcher, EROLES.dispatcher_supervisor];
        const onlyDispatcherOptions = options.filter((item) => dispatcherRoles.includes(item.value.id));

        setRolesOptions(onlyDispatcherOptions);
      }

      setRolesOptions(options);
    } catch (error) {
      showErrorMessage(error);
    }
  }, [onlyDispatcher]);

  const defaultAutocompleteValue = useMemo(() => (defaultValue ? defaultValue : []), []);
  const value = useMemo(
    () =>
      selectedRoleIds.length
        ? selectedRoleIds.map((id) => rolesOptions.find((role) => role?.value?.id === id)).filter(Boolean)
        : [],
    [selectedRoleIds, rolesOptions]
  );

  const showedRolesOptions = useMemo(() => {
    if (!relatedRolesOnly || !value.length) {
      return rolesOptions;
    }

    const relatedRoles = value.some((role) => ConEdRoles.includes(role.value.id))
      ? ConEdRoles
      : value.some((role) => CESRoles.includes(role.value.id))
      ? CESRoles
      : value.map((role) => role.value.id);

    return rolesOptions.filter((role) => relatedRoles.includes(role.value.id));
  }, [relatedRolesOnly, value, rolesOptions]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <Autocomplete<RoleOption, true>
      sx={{
        minWidth: 310,
        maxWidth: {
          xs: 'auto',
          sm: fullWidth ? '100%' : 310,
        },
        '@media (max-width: 900px)': {
          flex: 1,
        },
      }}
      multiple
      size={small ? 'small' : 'medium'}
      limitTags={limitTags ? limitTags : 1}
      defaultValue={defaultAutocompleteValue}
      value={value}
      id={id}
      getOptionDisabled={(option) => disabledValue && option.value.id === disabledValue.value.id}
      isOptionEqualToValue={(option, value) => option.value?.id === value.value?.id}
      getOptionLabel={(option) => (option.label ? option.label : '')}
      renderOption={(props, option) => (
        <li {...props} key={option.value.id}>
          <span>{option.label}</span>
        </li>
      )}
      options={showedRolesOptions}
      loading={!rolesOptions.length}
      renderInput={
        renderInput
          ? (params) => renderInput(params)
          : (params) => (
              <TextField
                {...params}
                label={noLabel ? '' : 'Select Roles'}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: {
                    ...params.inputProps?.style,
                    minWidth: 10,
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                }}
              />
            )
      }
      onChange={(event, value) => onSelectRole(value)}
    />
  );
}
