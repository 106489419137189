import AppPermissions from 'Constants/permissions';

class PermissionsHelper {
  #appPermissions = Object.values(AppPermissions).reduce((acc, permission) => {
    return {
      ...acc,
      [permission]: false,
    };
  }, {} as Record<AppPermissions, boolean>);

  #userPermissions = {} as Record<AppPermissions, boolean>;

  set(permissions: AppPermissions[]) {
    this.#userPermissions = {
      ...this.#appPermissions,
      ...permissions.reduce((acc, permission) => {
        return {
          ...acc,
          [permission]: true,
        };
      }, {} as Record<AppPermissions, boolean>),
    };
  }

  get has() {
    return this.#userPermissions;
  }
}

const UserPermissions = new PermissionsHelper();

export default UserPermissions;
