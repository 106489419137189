import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Alert, AlertTitle, Button, Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { BillingStatusReadableNamesMap } from 'Constants/billing';
import { JobType } from 'Constants/job';
import CheckboxComponent from 'Containers/Components/Controls/Checkbox.Component';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';

import MarkAsBilled from '../dialog/MarkAsBilled';
import UnmarkBilledConfirmationModal from '../dialog/UnmarkBilledConfirmationModal';
import BillingTimesheetsTable from './BillingTimesheetsTable';

class BillingInfoRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timesheetsPendingVerification: 0,
      showDetails: false,
      po_number: this.props.job.po,
      locationsArray: [],
      openModal: false,
      unmarkBilledConfirmationModal: false,
      unmarkInProcess: false,
    };
  }

  ShowDetailsClick = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  handleClickAway = () => {
    this.setState({ showDetails: false });
  };

  changeJobStatus = async (id, status) => {
    try {
      await this.props.updateStatus(id, { status });
      showSuccessMessage('Job was marked as billed and paid successfully');
    } catch (error) {
      showErrorMessage(error);
    }
  };

  unmarkBilled = async (jobId) => {
    try {
      this.setState({ unmarkInProcess: true });
      await this.props.revertStatus(jobId);
      showSuccessMessage('Job status has been reverted successfully');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      this.closeUnmarkConfirmationModal();
      this.setState({ unmarkInProcess: false });
    }
  };

  openMarkAsBilledModal = () => {
    this.setState({ openModal: true });
  };

  closeMarkAsBilledModal = () => {
    this.setState({ openModal: false });
  };

  openUnmarkConfirmationModal = () => {
    this.setState({ unmarkBilledConfirmationModal: true });
  };

  closeUnmarkConfirmationModal = () => {
    this.setState({ unmarkBilledConfirmationModal: false });
  };

  render() {
    const { job, departmentsMap } = this.props;
    const isServiceDept = departmentsMap[job.department_id]?.is_service_dept;

    const markAsBilledButtonEnabled =
      !job.unverifiedCount &&
      !!job.timesheets.length &&
      (job.billing_status === 'invoiced' || job.allow_bill_not_invoiced);

    const showMarkAsBilledButton = job.billing_status !== 'billed';
    const showUnmarkBilledButton = job.billing_status === 'billed' && job.allow_bill_not_invoiced;

    const topMenu = (
      <div className="billing-row-menu">
        <div className="d-flex justify-content-between align-items-center ">
          <CheckboxComponent
            onChange={this.props.onCheckboxChange}
            checked={this.props.checked}
            id={`job-checkbox-${job.id}`}
            className="title-project mb-0 mr-3"
          />
          <div className="d-flex">
            {JobType[job.type_id]} #{job.id}{' '}
            <div className="color-blue ml-7">(Location: {job.locations.join(', #')})</div>
          </div>
        </div>
        <div className="billing-row-menu__buttons-block">
          <div>{BillingStatusReadableNamesMap[job.billing_status]}</div>
          {showMarkAsBilledButton && (
            <div className="billing-row-menu__buttons-container">
              <Tooltip
                followCursor
                disableInteractive
                title={
                  !markAsBilledButtonEnabled
                    ? 'All timesheets must be verified and invoiced before being marked as billed and paid'
                    : ''
                }
              >
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={(theme) => ({
                      borderRadius: 20,
                      backgroundColor: !markAsBilledButtonEnabled ? 'lightgrey' : theme.palette.primary.main,
                    })}
                    disabled={!markAsBilledButtonEnabled}
                    onClick={this.openMarkAsBilledModal}
                  >
                    Mark as Billed and Paid
                  </Button>
                </div>
              </Tooltip>
            </div>
          )}
          {showUnmarkBilledButton && (
            <Button
              variant="outlined"
              color="primary"
              style={{ borderRadius: 20 }}
              onClick={this.openUnmarkConfirmationModal}
            >
              Unmark Billed/Paid
            </Button>
          )}
        </div>
      </div>
    );
    const buttonMenu = (
      <div>
        <div
          className="d-flex justify-content-between align-items-center pl-4 pr-4 pb-3  mt-3 cursor-pointer"
          onClick={this.ShowDetailsClick}
        >
          <Alert severity={job.unverifiedCount ? 'error' : 'success'} sx={{ display: 'flex', alignItems: 'center' }}>
            <AlertTitle sx={{ margin: 0 }}>
              {job.unverifiedCount
                ? `${job.unverifiedCount} timesheets pending verification`
                : 'All Timesheets CES Verified'}
            </AlertTitle>
          </Alert>

          <div className="  d-flex">
            {this.state.showDetails ? (
              <div className=" d-flex">
                <div className="show-details cursor-pointer">Hide details</div>
                <ArrowDropUpIcon fontSize="small" color="primary" />
              </div>
            ) : (
              <div className="d-flex">
                <div className="show-details cursor-pointer">Show details</div>
                <ArrowDropDownIcon fontSize="small" color="primary" />
              </div>
            )}
          </div>
        </div>
        {this.state.showDetails && (
          <BillingTimesheetsTable
            timesheets={job.timesheets}
            jobId={job.id}
            cancelledJob={job.status === 'cancelled' || job.status === 'cancelled_billable'}
            jobStatus={job.billing_status}
            jobRequestDate={job.requestTime}
          />
        )}
      </div>
    );
    return (
      <>
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <div className="BillingInfo">
            <div className="block-white-info">
              {topMenu}
              <div className="table-info-billing billing-row__table">
                <div className="col" style={{ flexGrow: 1.7 }}>
                  <div className="table-title"> Request Date & Time</div>
                  <div className="table-description"> {moment(job.requestTime).format('MM/DD/YYYY hh:mm A')} </div>
                </div>
                <div className="col" style={{ flexGrow: 1.3 }}>
                  <div className="table-title"> Department</div>
                  <div className="table-description">{job.departmentName}</div>
                </div>
                <div className="col">
                  <div className="table-title">Requestor</div>
                  <div className="table-description color-blue">{job.requestorName}</div>
                </div>
                <div className="col">
                  <div className="table-title"> Total Work Time</div>
                  <div className="table-description">{job.total_billable_hours + ' h'}</div>
                </div>
                <div className="col">
                  <div className="table-title">PO #</div>
                  <Tooltip title={job.po && job.po.length > 12 ? job.po : ''} arrow aria-label="po">
                    <div className="table-description">
                      {job.po && job.po.length > 12 ? `${job.po.slice(0, 11)}...` : job.po}
                    </div>
                  </Tooltip>
                </div>
                <div className="col">
                  <div className="table-title">Requisition #</div>
                  <Tooltip
                    title={job.requisition && job.requisition.length > 12 ? job.requisition : ''}
                    arrow
                    aria-label="requisition"
                  >
                    <div className="table-description">
                      {job.requisition && job.requisition.length > 12
                        ? `${job.requisition.slice(0, 11)}...`
                        : job.requisition}
                    </div>
                  </Tooltip>
                </div>
                <div className="col">
                  <Tooltip
                    title={job.receipt_number && job.receipt_number.length > 12 ? job.receipt_number : ''}
                    arrow
                    aria-label="receipt_number"
                  >
                    <div>
                      <div className="table-title">Receipt #</div>
                      <div className="table-description">
                        {job.receipt_number && job.receipt_number.length > 12
                          ? `${job.receipt_number.slice(0, 11)}...`
                          : job.receipt_number}
                      </div>
                    </div>
                  </Tooltip>
                </div>
                {isServiceDept && (
                  <div className="col">
                    <Tooltip title={job.account_no?.length > 12 && job.account_no} arrow aria-label="receipt_number">
                      <div>
                        <div className="table-title">Job #</div>
                        <div className="table-description">
                          {job.account_no?.length > 12 ? `${job.account_no?.slice(0, 11)}...` : job.account_no}
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                )}

                <div className="col" style={{ flexGrow: 0.7 }}>
                  <div className="table-title">Total Due</div>
                  <div className="table-description">{job.total_billable_amount}</div>
                </div>
              </div>
              {job && job.timesheets && job.timesheets.length > 0 && buttonMenu}
            </div>
          </div>
        </ClickAwayListener>
        <MarkAsBilled
          open={this.state.openModal}
          confirm={this.changeJobStatus}
          jobId={job.id}
          status={'billed'}
          onClose={this.closeMarkAsBilledModal}
        />
        {this.state.unmarkBilledConfirmationModal && (
          <UnmarkBilledConfirmationModal
            open={this.state.unmarkBilledConfirmationModal}
            onClose={this.closeUnmarkConfirmationModal}
            jobId={job.id}
            onConfirm={this.unmarkBilled}
            loading={this.state.unmarkInProcess}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    departmentsMap: state.app.departmentsMap,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: (id, status) => dispatch(actions.BillingActions.updateStatus(id, status)),
    revertStatus: (id) => dispatch(actions.BillingActions.revertStatus(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfoRow);
