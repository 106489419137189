const addDate = (url = '', updated_at: string | number = '') =>
  url.includes('blob:') ? url : `${url}${updated_at ? `?time=${updated_at}` : ''}`;

const removeDate = (url = '') => url.split('?time=')[0];

export type NameConfigItem = string | number | [any, string | number] | [any, string | number, string | number];

const generateName = (nameConfig: NameConfigItem[] = [], separator = ' ') => {
  const serializedConfig = nameConfig.reduce((acc, item) => {
    if (typeof item === 'string' || typeof item === 'number') {
      return [...acc, item];
    }

    const [condition, trueValue, falseValue] = item;

    if (condition) {
      return [...acc, trueValue];
    } else if (falseValue) {
      return [...acc, falseValue];
    }

    return acc;
  }, [] as (string | number)[]);
  return serializedConfig.join(separator);
};

// For future use
const getRotatedFile = (image = new Image(), degree = 0, mimeType: 'jpeg' | 'png' = 'jpeg'): Promise<File> =>
  new Promise((resolve, reject) => {
    const img: CanvasImageSource = image;
    const canvas = document.createElement('canvas');
    const cContext = canvas.getContext('2d');
    let cw = img.naturalWidth;
    let ch = img.naturalHeight;
    let cx = 0;
    let cy = 0;
    //   Calculate new canvas size and x/y coorditates for image
    switch (degree) {
      case 90:
        cw = img.naturalHeight;
        ch = img.naturalWidth;
        cy = img.naturalHeight * -1;
        break;
      case 180:
        cx = img.naturalWidth * -1;
        cy = img.naturalHeight * -1;
        break;
      case 270:
        cw = img.naturalHeight;
        ch = img.naturalWidth;
        cx = img.naturalWidth * -1;
        break;
      default:
        console.warn("You didn't pass rotate angle");
        canvas.remove();
        reject();
        return;
    }

    //  Rotate image
    canvas.setAttribute('width', cw.toString());
    canvas.setAttribute('height', ch.toString());
    cContext.rotate((degree * Math.PI) / 180);
    cContext.drawImage(img, cx, cy);
    canvas.toBlob((blob) => {
      const file = new File([blob], 'fileName.jpg', { type: `image/${mimeType}` });
      canvas.remove();
      return resolve(file);
    }, `image/${mimeType}`);
  });

export default { addDate, removeDate, getRotatedFile, generateName };
