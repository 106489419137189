import { DragEvent, useRef } from 'react';

type Params = {
  acceptType: string;
  classNames?: { dropzoneHover?: string; dropzoneContainer?: string; dropzoneVisible?: string };
  onDrop: (files: File[], elemId?: string) => void;
};

export default function useMultipleDrop<
  ContainerElement extends HTMLElement = HTMLDivElement,
  DropZoneElement extends HTMLElement = HTMLDivElement,
  HandlerElement extends HTMLElement = HTMLDivElement
>({
  acceptType = '',
  classNames: { dropzoneHover = '', dropzoneContainer = '', dropzoneVisible = '' } = {},
  onDrop = () => {},
}: Params) {
  const dropZoneRefs = useRef(new Map<string, DropZoneElement>()).current;
  const dropZoneContainerRefs = useRef(new Map<string, ContainerElement>()).current;
  const dropZoneHandlers = useRef({
    onDragEnterCapture: (ev: DragEvent<HandlerElement>) => {
      if (ev.dataTransfer.types.some((type) => type !== 'Files')) {
        return;
      }
      if (dropzoneContainer) {
        dropZoneContainerRefs.forEach((ref) => ref.classList.add(dropzoneContainer));
      }
      if (dropzoneVisible) {
        dropZoneRefs.forEach((ref) => ref.classList.add(dropzoneVisible));
      }
    },
    onDragEnter: (ev: DragEvent<HandlerElement>) => {
      const id = (ev.target as HandlerElement)?.id;
      const currentZone = dropZoneRefs.get(id);
      if (dropzoneHover) {
        currentZone?.classList?.add(dropzoneHover);
        dropZoneRefs.forEach((ref) => (ref !== currentZone ? ref?.classList?.remove(dropzoneHover) : null));
      }
    },
    onDragLeave: (ev: DragEvent<HandlerElement>) => {
      const id = (ev.target as HandlerElement)?.id;
      const currentZone = dropZoneRefs.get(id);
      if (dropzoneHover) {
        currentZone?.classList?.remove(dropzoneHover);
      }
    },
    onDragOver: (ev: DragEvent<HandlerElement>) => {
      ev.preventDefault();
    },
    onDrop: (ev: DragEvent<HandlerElement>) => {
      ev.preventDefault();
      const id = (ev.target as HandlerElement)?.id;
      if (dropzoneVisible) {
        dropZoneRefs.forEach((ref) => ref?.classList?.remove(dropzoneVisible));
      }
      if (dropzoneHover) {
        dropZoneRefs.forEach((ref) => ref?.classList?.remove(dropzoneHover));
      }
      if (dropzoneContainer) {
        dropZoneContainerRefs.forEach((ref) => ref.classList.remove(dropzoneContainer));
      }
      if (Array.from(ev.dataTransfer.files).some((file) => !file.type.includes(acceptType))) {
        return;
      }
      if (!id) return;

      onDrop(Array.from(ev.dataTransfer.files), id);
    },
  }).current;

  return { dropZoneContainerRefs, dropZoneHandlers, dropZoneRefs };
}
