import { FC, useEffect, useMemo } from 'react';

import { Box, Tab, Typography } from '@mui/material';

import SignatureBox from 'Containers/Timesheets/components/SignatureBox/SignatureBox';
import { DeleteIcon } from 'Utils/Icon';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import AppInputField from 'components/AppInputField/AppInputField';
import { useMasterTimesheetContext } from 'context/MasterTimesheetContext';
import { useAppSelector } from 'createStore';

import { StyledDeleteButton } from '../../styled';
import { StyledSignaturesTabs } from './styled';

const MasterTimesheetSignatures: FC = () => {
  const user = useAppSelector((state) => state.app.user);
  const { values, errors, setFieldValue, setValues, handleChange } = useMasterTimesheetContext();
  const { params, setSearchParam } = useSearchParams<{ signature_type: string }>();

  const changeSignature =
    (signature_type_id: number = 0) =>
    (signatureData: string) => {
      const newSignatures = values?.signatures.map((signature) => {
        if (signature.signature_type_id === signature_type_id) {
          return {
            ...signature,
            signature_data: signatureData,
            signee: user?.name,
            employee_number: signature.employee_number || user?.co_employee_id || '',
          };
        }
        return signature;
      });

      setFieldValue('signatures', newSignatures);
    };

  const removeSignature = (signature_type_id: number = null) => {
    const newSignatures = values?.signatures.map((signature) => {
      if (signature.signature_type_id === signature_type_id) {
        return {
          ...signature,
          signature_data: null,
          signee: null,
          employee_number: null,
        };
      }
      return signature;
    });
    setFieldValue('signatures', newSignatures);
  };

  const changeSignatureEmployeeNumber =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const updatedSignatures = values.signatures.map((signature) => {
        if (signature.signature_type_id === id) {
          signature.employee_number = event.target.value;
        }
        return signature;
      });

      setFieldValue('signatures', updatedSignatures);
    };

  const firstCanBeSignedSignatureID = useMemo(
    () => String(values?.signatures?.find((signature) => signature.can_be_signed)?.signature_type_id || 1),
    [values]
  );

  useEffect(() => {
    if (!params.signature_type) {
      setSearchParam('signature_type', firstCanBeSignedSignatureID);
    }
  }, [params.signature_type, firstCanBeSignedSignatureID]);

  return (
    <Box>
      <Typography variant="caption" sx={{ display: 'block', mb: '10px' }}>
        Select Signature
      </Typography>

      <StyledSignaturesTabs
        value={params.signature_type || firstCanBeSignedSignatureID}
        onChange={(e, value) => setSearchParam('signature_type', value)}
      >
        {values?.signatures?.map(
          (signature, index) =>
            (signature.signature_data || signature.can_be_signed) && (
              <Tab key={index} label={signature.name} value={String(signature.signature_type_id)} />
            )
        )}
      </StyledSignaturesTabs>

      {values?.signatures?.map((signature, index) => (
        <Box
          key={signature.signature_type_id}
          overflow="hidden"
          height={Number(params.signature_type) !== signature.signature_type_id && 0}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontSize={16} fontFamily={'Poppins Medium'} sx={{ mt: '30px', mb: '26px' }}>
              {signature.name} Signature
            </Typography>
            {signature.can_be_signed && signature.signature_data && (
              <StyledDeleteButton
                startIcon={<DeleteIcon />}
                onClick={() => removeSignature(signature.signature_type_id)}
              >
                Remove signature
              </StyledDeleteButton>
            )}
          </Box>
          <Box display="flex" gap="20px">
            <AppInputField fullWidth size="small" label="Signature Name" value={signature.signee || ''} disabled />
            <AppInputField
              fullWidth
              size="small"
              label="Employee # "
              value={signature.employee_number || ''}
              name="employee_number"
              onChange={changeSignatureEmployeeNumber(signature.signature_type_id)}
              disabled={!!user?.co_employee_id || !signature.can_be_signed}
              // @ts-ignore
              error={Boolean(errors.signatures?.[index]?.employee_number)}
              // @ts-ignore
              helperText={errors.signatures?.[index]?.employee_number}
            />
          </Box>
          <Box mt="20px">
            <SignatureBox
              disabled={!signature.can_be_signed}
              value={signature.signature_data || ''}
              onChange={changeSignature(signature.signature_type_id)}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MasterTimesheetSignatures;
