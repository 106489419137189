import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Container, LinearProgress, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { MasterTimesheetType, MasterTimesheetUpdatedItem } from 'types/Timesheet';

import PageTitle from 'Containers/Components/Typography/PageTitle';
import { actions } from 'Services';
import { UpdateMasterTimesheetParams } from 'Services/API/TimesheetsAPI.service';
import { DownloadIcon, KeyLeftIcon, SaveIcon } from 'Utils/Icon';
import { downloadFileToPC } from 'Utils/downloadFile';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { useSearchParams } from 'Utils/hooks/useSearchParams';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import MasterTimesheetContext from 'context/MasterTimesheetContext';
import { useAppDispatch, useAppSelector } from 'createStore';

import MasterTimesheetValidation from './MasterTimesheet.Validation';
import MasterTimesheetTabs, { MasterTimesheetTab } from './components/MasterTimesheetTabs/MasterTimesheetTabs';
import MasterTimesheetAttachments from './containers/MasterTimesheetAttachments/MasterTimesheetAttachments';
import MasterTimesheetComments from './containers/MasterTimesheetComments/MasterTimesheetComments';
import MasterTimesheetInfo from './containers/MasterTimesheetInfo/MasterTimesheetInfo';
import MasterTimesheetLocations from './containers/MasterTimesheetLocations/MasterTimesheetLocations';
import MasterTimesheetWorkers from './containers/MasterTimesheetWorkers/MasterTimesheetWorkers';

const MasterTimesheetsContentMap = {
  [MasterTimesheetTab.information]: <MasterTimesheetInfo />,
  [MasterTimesheetTab.locations]: <MasterTimesheetLocations />,
  [MasterTimesheetTab.workers]: <MasterTimesheetWorkers />,
  [MasterTimesheetTab.attachments]: <MasterTimesheetAttachments />,
  [MasterTimesheetTab.comments]: <MasterTimesheetComments />,
};

type Props = { modal: true; confirmation: number; open: boolean; onClose: () => void } | { modal: false | undefined };

export type MasterTimesheetFormikValues = Partial<MasterTimesheetType> & {
  initialStartDate: string;
  initialFinishDate: string;
  timesheetsThatWillBeChanged: MasterTimesheetUpdatedItem[];
  confirmation_number: number;
};

const MasterTimesheet: FC<Props> = (props) => {
  const { modal: isModalActive } = props;
  const dispatch = useAppDispatch();
  const masterTimesheet = useAppSelector((state) => state.timesheets.masterTimesheet)?.asMutable({ deep: true });
  const masterTimesheetRetrieving = useAppSelector((state) => state.timesheets.masterTimesheetRetrieving);
  const masterTimesheetApiError = useAppSelector((state) => state.timesheets.masterTimesheetApiError);
  const { inProcess: downloadingTimesheets, promiseWrapper } = useProcessing();

  const { params } = useSearchParams<{
    confirmation: number;
    tab: MasterTimesheetTab;
  }>();
  const history = useHistory();

  const retrieveMasterTimesheet = (confirmation: number) =>
    dispatch(actions.TimesheetsActions.retrieveMasterTimesheet(confirmation));

  const updateMasterTimesheet = (values: UpdateMasterTimesheetParams) =>
    dispatch(
      actions.TimesheetsActions.updateMasterTimesheet({
        confirmation_id: isModalActive ? props.confirmation : params.confirmation,
        updateMasterTimesheetParams: values,
      })
    );

  const saveMasterTimesheet = async (formValues: MasterTimesheetFormikValues) => {
    const signatures = formValues.signatures.reduce(
      (acc, signature) => {
        if (signature.can_be_signed) {
          const { signee, signed_at, can_be_signed, name, ...rest } = signature;
          acc.push({
            ...rest,
            ...(!rest.signature_data && {
              signature_data: null,
              employee_number: null,
            }),
          });
        }
        return acc;
      },
      [] as {
        signature_type_id: number;
        employee_number: string;
        signature_data: string;
      }[]
    );

    const changedTimesheets = formValues.timesheetsThatWillBeChanged.reduce(
      (acc, { id, start_date_changed, finish_date_changed }) => {
        if (start_date_changed) {
          acc.startDateTimesheetIds.push(id);
        }
        if (finish_date_changed) {
          acc.finishDateTimesheetIds.push(id);
        }
        return acc;
      },
      { startDateTimesheetIds: [] as number[], finishDateTimesheetIds: [] as number[] }
    );

    const values: UpdateMasterTimesheetParams = {
      startDate: formValues.start_at,
      finishDate: formValues.finish_at,
      ...changedTimesheets,
      signatures,
    };

    if (!signatures.length) {
      values.sign = formValues.signature_data;
      values.employeeNumber = formValues.employee_number;
    }

    try {
      await updateMasterTimesheet(values);

      showSuccessMessage('Master Timesheet has been updated successfully');
      if (isModalActive) {
        props.onClose();
      } else {
        history.push('/timesheets');
      }
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const masterTimesheetFormikBag = useFormik<MasterTimesheetFormikValues>({
    enableReinitialize: true,
    initialValues: {
      ...masterTimesheet,
      initialStartDate: masterTimesheet?.start_at || '',
      initialFinishDate: masterTimesheet?.finish_at || '',
      timesheetsThatWillBeChanged: [],
      confirmation_number: isModalActive ? props.confirmation : params.confirmation,
    },
    validationSchema: MasterTimesheetValidation,
    onSubmit: saveMasterTimesheet,
  });

  const backToTimesheets = () => {
    history.push('/timesheets');
  };

  const downloadAllTimesheets = async () => {
    const confirmationNumber = isModalActive ? props.confirmation : params.confirmation;

    if (!confirmationNumber) {
      return;
    }

    try {
      const file = await promiseWrapper(
        dispatch(actions.TimesheetsActions.downloadAllConfTimesheets(confirmationNumber))
      );
      downloadFileToPC(URL.createObjectURL(file), `Conf ${confirmationNumber}-timesheets`, file.type);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  useEffect(() => {
    if (!params.confirmation && !(isModalActive && props.confirmation)) {
      history.replace('/timesheets');
    }
    retrieveMasterTimesheet(isModalActive ? props.confirmation : params.confirmation).catch(showErrorMessage);
  }, [params.confirmation, isModalActive]);

  if (isModalActive && props.confirmation) {
    return (
      <AppPaperModal
        open={props.open}
        onClose={props.onClose}
        title={
          <Box display="flex" flexDirection="column" gap="10px">
            <Chip label={`Conf #${props.confirmation}`} sx={{ bgcolor: grey[100], width: 'max-content' }} />
            <Box display="flex" alignItems="center" gap="20px">
              Master Timesheet
              <Tooltip title="Download all timesheets" disableInteractive>
                <LoadingButton
                  loading={downloadingTimesheets}
                  startIcon={<DownloadIcon />}
                  sx={{
                    minWidth: 'fit-content',
                    height: 'fit-content',
                  }}
                  size="small"
                  onClick={downloadAllTimesheets}
                  loadingPosition="start"
                >
                  Download All Timesheets
                </LoadingButton>
              </Tooltip>
            </Box>
          </Box>
        }
        containerStyle={{
          width: '100%',
          maxWidth: 712,
          height: '100%',
        }}
        contentStyle={{
          paddingTop: 0,
          paddingInline: 0,
          height: '100%',
        }}
        submitButton={{
          onClick: masterTimesheetFormikBag.submitForm,
          loading: masterTimesheetFormikBag.isSubmitting,
        }}
      >
        <MasterTimesheetContext.Provider value={masterTimesheetFormikBag}>
          {masterTimesheetRetrieving && <LinearProgress />}
          <MasterTimesheetTabs />

          {MasterTimesheetsContentMap[params.tab]}
        </MasterTimesheetContext.Provider>
      </AppPaperModal>
    );
  }

  return (
    <Box overflow="auto">
      {masterTimesheetRetrieving && <LinearProgress />}
      <Container maxWidth={'md'}>
        <Box>
          <PageTitle sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Button sx={{ textTransform: 'none' }} startIcon={<KeyLeftIcon />} onClick={backToTimesheets}>
              Back to list
            </Button>
            <Box display="flex" alignItems="center" gap={2}>
              Master Timesheet
              <Chip label={`Conf #${params.confirmation}`} sx={{ bgcolor: 'white' }} />
            </Box>
          </PageTitle>

          <Box display="flex" flexDirection="column" gap="10px">
            {/* TABS */}
            <MasterTimesheetTabs showDownloadButton onDownloadAllTimesheets={downloadAllTimesheets} />

            {/* CONTENT */}
            {masterTimesheet && !masterTimesheetRetrieving && (
              <MasterTimesheetContext.Provider value={masterTimesheetFormikBag}>
                {MasterTimesheetsContentMap[params.tab]}
              </MasterTimesheetContext.Provider>
            )}

            {/* ERROR */}
            {masterTimesheetApiError && !masterTimesheetRetrieving && (
              <Box textAlign="center" height="100%" bgcolor="white" borderRadius="10px" px="20px" py="40px">
                <Typography variant="body1">{masterTimesheetApiError}</Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box display="flex" justifyContent="end" my={'30px'} gap={1}>
          <Button sx={{ bgcolor: 'primary.light' }} onClick={backToTimesheets}>
            Cancel
          </Button>
          <LoadingButton
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={masterTimesheetFormikBag.submitForm}
            loading={masterTimesheetFormikBag.isSubmitting}
          >
            Save changes
          </LoadingButton>
        </Box>
      </Container>
    </Box>
  );
};

export default MasterTimesheet;
