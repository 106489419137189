/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import LockIcon from '@mui/icons-material/Lock';
import LockResetIcon from '@mui/icons-material/LockReset';
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Tooltip,
  LinearProgress,
} from '@mui/material';

import { AppThunkDispatch } from 'types';
import { ResponseSubcontractorItem, SubcontractorStatus } from 'types/Subcontractors';

import { TABLE_HEADER, SubcontractorStatuses } from 'Constants/subcontractors';
import { DatasetTestIDs } from 'Constants/tests';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { useAppSelector } from 'createStore';

import classNames from './SubcontractorsTable.module.scss';

const rowSxStyles = {
  transition: '0.2s',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#cecece59',
  },
};

function SubcontractorsTable({ onRowClick }) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const processing = {
    delete: useAppSelector((state) => state.subcontractors.processing_delete),
    update: useAppSelector((state) => state.subcontractors.update_subcontractor_processing),
  };
  const subcontractors = useAppSelector((state) => state.subcontractors.subcontractors);
  const [orderBy, setOrderBy] = useState('id');
  const [orderByType, setOrderByType] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [deleteSubcontractorData, setDeleteSubcontractorData] = useState<{ id: number; name: string }>(null);
  const [changeStatusData, setChangeStatusData] = useState<{ id: number; name: string; status: SubcontractorStatus }>(
    null
  );

  const handleSort = (value) => {
    if (orderBy === value) {
      setOrderByType((prev) => !prev);
    } else {
      setOrderBy(value);
      setOrderByType(true);
    }
  };

  const subcontractorsFiltered = useMemo(() => {
    const subs = subcontractors.asMutable({ deep: true });

    subs.sort((a, b) => {
      let first: number, second: number;
      if (orderBy === 'workers') {
        first = a[orderBy].length;
        second = b[orderBy].length;
      } else if (orderBy === 'id') {
        first = a[orderBy];
        second = b[orderBy];
      } else {
        first = a.subcontractor[orderBy].toString().toLowerCase();
        second = b.subcontractor[orderBy].toString().toLowerCase();
      }
      if (first < second) {
        return orderByType ? -1 : 1;
      }
      if (first > second) {
        return orderByType ? 1 : -1;
      }
      return 0;
    });

    const start = (currentPage - 1) * limit;
    const end = currentPage * limit;

    return subs.slice(start, end);
  }, [subcontractors, orderBy, orderByType, limit, currentPage]);

  const onChangePage = useCallback((event, page) => setCurrentPage(page), []);

  const onPaginationChange = useCallback((itemsPerPage: number) => {
    setLimit(itemsPerPage);
    setCurrentPage(1);
  }, []);

  const SubcontractorsHeader = useMemo(
    () =>
      TABLE_HEADER.map((headCell, index) => (
        <TableCell key={index} className={classNames.sticky_table}>
          {headCell.sortable && (
            <TableSortLabel
              active={orderBy === headCell.value}
              direction={orderBy === headCell.value && orderByType ? 'asc' : 'desc'}
              onClick={() => handleSort(headCell.value)}
              {...(headCell.dataCy || {})}
            >
              {headCell.label}
            </TableSortLabel>
          )}
          {!headCell.sortable && headCell.label}
        </TableCell>
      )),
    [orderByType, orderBy]
  );

  const deleteButtonPress = useCallback(
    ({ subcontractor: { id, name } }: ResponseSubcontractorItem) =>
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setDeleteSubcontractorData({ id, name });
      },
    []
  );

  const changeStatusButtonPress = useCallback(
    ({ id, status, subcontractor: { name } }: ResponseSubcontractorItem) =>
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setChangeStatusData({ id, name, status });
      },
    []
  );

  const deleteSubcontractor = async () => {
    try {
      await dispatch(actions.SubcontractorsActions.deleteSubcontractor(deleteSubcontractorData.id));
      showSuccessMessage('Subcontractor was deleted!');
    } catch (err) {
      showErrorMessage(err);
    } finally {
      setDeleteSubcontractorData(null);
    }
  };

  const toggleSubcontractorStatus = async (id: number, currentStatus: SubcontractorStatus) => {
    const status =
      currentStatus === SubcontractorStatuses.active ? SubcontractorStatuses.onHold : SubcontractorStatuses.active;
    const message =
      currentStatus === SubcontractorStatuses.active ? 'Subcontractor is on hold' : 'Subcontractor is active';

    try {
      await dispatch(actions.SubcontractorsActions.updateSubcontractor(id, { status }));
      showSuccessMessage(message);
    } catch (err) {
      showErrorMessage(err);
    } finally {
      setChangeStatusData(null);
    }
  };

  const editSubcontractor = (row: ResponseSubcontractorItem) => () => onRowClick(row);

  const SubcontractorsRows = useMemo(
    () =>
      subcontractorsFiltered.map((row) => (
        <TableRow
          sx={rowSxStyles}
          key={row.id}
          onClick={editSubcontractor(row)}
          style={{ opacity: row.status === SubcontractorStatuses.onHold ? 0.5 : 1 }}
        >
          <TableCell style={{ position: 'relative' }}>
            <div
              className={classNames.color_line}
              style={{ backgroundColor: row.color || 'inherit' }}
              {...DatasetTestIDs.pages.subcontractors.table.colorCell}
            />
            {row?.id}
          </TableCell>
          <TableCell {...DatasetTestIDs.pages.subcontractors.table.firstNameCell}>
            {row.subcontractor?.firstName}
          </TableCell>
          <TableCell {...DatasetTestIDs.pages.subcontractors.table.lastNameCell}>
            {row.subcontractor?.lastName}
          </TableCell>
          <TableCell {...DatasetTestIDs.pages.subcontractors.table.emailCell}>{row.subcontractor?.email}</TableCell>
          <TableCell {...DatasetTestIDs.pages.subcontractors.table.phoneNumberCell}>
            {row.subcontractor?.phoneNumber}
          </TableCell>
          <TableCell {...DatasetTestIDs.pages.subcontractors.table.workersCell}>{row.workers?.length}</TableCell>
          <TableCell {...DatasetTestIDs.pages.subcontractors.table.companyNameCell}>
            {row.subcontractor?.subcontractorName}
          </TableCell>
          <TableCell>
            <div className={classNames.user_status} {...DatasetTestIDs.pages.subcontractors.table.statusCell}>
              <div className={classNames[row?.subcontractor?.isOnline ? 'user_online' : 'user_offline']} />
              {row?.subcontractor?.isOnline ? 'Online' : 'Offline'}
              {row.status === SubcontractorStatuses.onHold ? ' (on hold)' : ''}
            </div>
          </TableCell>
          <TableCell>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>
              <Tooltip
                disableInteractive
                title={
                  row?.status === SubcontractorStatuses.onHold ? 'Activate subcontractor' : 'Suspend subcontractor'
                }
                arrow
                aria-label="change-status"
              >
                <div
                  className={'table-action_img-btn'}
                  onClick={changeStatusButtonPress(row)}
                  {...DatasetTestIDs.pages.subcontractors.buttons.changeStatus}
                >
                  {row?.status !== SubcontractorStatuses.onHold ? <LockIcon /> : <LockResetIcon />}
                </div>
              </Tooltip>
              <Tooltip disableInteractive title="Delete subcontractor" arrow aria-label="delete">
                <div
                  className={'table-action_img-btn'}
                  onClick={deleteButtonPress(row)}
                  {...DatasetTestIDs.pages.subcontractors.buttons.removeSubcontractor}
                >
                  <HighlightOffSharpIcon style={{ color: 'black' }} />
                </div>
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
      )),
    [subcontractorsFiltered]
  );

  return (
    <div className={classNames.tableContainer}>
      {subcontractors?.length ? (
        <>
          <Paper style={{ overflow: 'auto' }}>
            <Table style={{ position: 'relative' }} padding="normal">
              <TableHead>
                <TableRow>{SubcontractorsHeader}</TableRow>
              </TableHead>
              <TableBody>{SubcontractorsRows}</TableBody>
            </Table>
          </Paper>
          <AppTablePagination
            page={currentPage}
            total={subcontractors.length}
            perPage={limit}
            onPaginationChange={onPaginationChange}
            onChangePage={onChangePage}
          />
          {!!deleteSubcontractorData && (
            <AppPaperModal
              modalId="delete-subcontractor-modal"
              title="Delete Subcontractor"
              submitButton={{
                title: 'Confirm',
                onClick: deleteSubcontractor,
                loading: processing.delete,
              }}
              onClose={() => setDeleteSubcontractorData(null)}
              open={!!deleteSubcontractorData}
            >
              <span>
                Are you sure you want to delete subcontractor <b>{deleteSubcontractorData.name}</b>?
              </span>
            </AppPaperModal>
          )}
          {!!changeStatusData && (
            <AppPaperModal
              modalId="change-status-subcontractor-modal"
              title={`${changeStatusData.status === SubcontractorStatuses.active ? 'Hold' : 'Activate'} Subcontractor`}
              submitButton={{
                title: 'Confirm',
                onClick: () => toggleSubcontractorStatus(changeStatusData.id, changeStatusData.status),
                loading: processing.update,
              }}
              onClose={() => setChangeStatusData(null)}
              open={!!changeStatusData}
            >
              <span>
                Are you sure you want to{' '}
                {changeStatusData.status === SubcontractorStatuses.active
                  ? `put subcontractor ${changeStatusData.name} on hold?`
                  : `activate a subcontractor ${changeStatusData.name}?`}
                ?
              </span>
            </AppPaperModal>
          )}
        </>
      ) : (
        <LinearProgress />
      )}
    </div>
  );
}

export default React.memo(SubcontractorsTable);
