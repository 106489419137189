import { useState, useEffect, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { Job } from 'types/Job';

import { actions } from 'Services';
import { UpdatePOSData } from 'Services/API/JobsAPI.service';
import { showErrorMessage } from 'Utils/errorMessage';
import useModal from 'Utils/hooks/useModal';
import { showSuccessMessage } from 'Utils/successMessage';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { useAppDispatch, useAppSelector } from 'createStore';

import './billing.scss';
import BillingFilters from './components/BillingFilters';
import BillingInfoRow from './components/BillingInfoRow';

const Billing = () => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state) => state.billing.jobs);
  const searchOptions = useAppSelector((state) => state.billing.search_options);
  const loading = useAppSelector((state) => state.billing.processing);
  const updatePOSModal = useModal();
  const [selectedJobs, setSelectedJobs] = useState<{ [key: number]: Job }>({});
  const ref = useRef<HTMLDivElement>(null);

  const handleSelectJob = (job: Job) => {
    setSelectedJobs((prev) => {
      if (prev[job.id]) {
        const { [job.id]: omit, ...rest } = prev;
        return rest;
      }
      return {
        ...prev,
        [job.id]: job,
      };
    });
  };

  const retrieveJobs = (searchOptions) => {
    dispatch(actions.BillingActions.retrieve(searchOptions));
  };

  const handleOpenUpdatePOSModal = () => {
    if (!Object.keys(selectedJobs).length) {
      showErrorMessage('Please select any job');
      return;
    }
    updatePOSModal.open();
  };

  const handleUpdatePOS = async (posData: UpdatePOSData) => {
    try {
      const updatedFields = await dispatch(actions.JobsActions.updatePOS(posData));
      showSuccessMessage(`${updatedFields} updated successfully!`);
      updatePOSModal.close();
      setSelectedJobs({});
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const onPerPageChange = (itemsPerPage = 10) => {
    retrieveJobs({
      ...searchOptions,
      page: 1,
      limit: itemsPerPage,
    });
  };

  const onPaginationChange = (event, page) => {
    retrieveJobs({
      ...searchOptions,
      page,
    });

    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    retrieveJobs(searchOptions);
  }, []);

  useEffect(() => {
    setSelectedJobs({});
  }, [jobs]);

  return (
    <>
      {loading ? <LinearProgress /> : <div style={{ height: 4 }} />}

      <div className="billing-container">
        <BillingFilters
          openUpdatePOModal={handleOpenUpdatePOSModal}
          open={updatePOSModal.isOpen}
          close={updatePOSModal.close}
          updatePO={handleUpdatePOS}
          selectedJobs={Object.values(selectedJobs)}
        />

        <div className="billing-body">
          <div ref={ref} className="billing-table-container">
            {jobs.map((job, index) => {
              return (
                <BillingInfoRow
                  job={job}
                  key={index}
                  checked={!!selectedJobs[job.id]}
                  onCheckboxChange={() => handleSelectJob(job)}
                />
              );
            })}
            {!jobs.length && (
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#fff'}>
                <Typography paddingY={4} variant={'body1'}>
                  No jobs found
                </Typography>
              </Box>
            )}
          </div>
          <Box padding={'0 1rem 1rem'}>
            <AppTablePagination
              page={searchOptions.page}
              total={searchOptions.total}
              perPage={searchOptions.limit}
              onPaginationChange={onPerPageChange}
              onChangePage={onPaginationChange}
            />
          </Box>
        </div>
      </div>
    </>
  );
};
export default Billing;
