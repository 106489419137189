/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AddRounded, NoteAddRounded } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { AppThunkDispatch } from 'types';

import { TimesheetStatus, canSeeCancelledTimesheets } from 'Constants/timesheet';
import { EROLES } from 'Constants/user';
import { actions } from 'Services';
import UserPermissions from 'Utils/PermissionsHelper';
import { downloadFileToPC } from 'Utils/downloadFile';
import { showErrorMessage } from 'Utils/errorMessage';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import { showSuccessMessage } from 'Utils/successMessage';
import { ReduxState, useAppSelector } from 'createStore';

import ExportToFileButton from '../Components/ExportToFileButton/ExportToFileButton';
import './Timesheet.scss';
import TimesheetHeader from './TimesheetHeader';
import { ThreadLegend, TimesheetsFilters } from './components';
import CancelledTimesheetButton from './components/CancelledTimesheetButton';
import MainTimesheetsTable from './components/MainTimesheets/MainTimesheetsTable';
import PendingTimesheetsFilterButton from './components/PendingTimesheetsFilterButton';
import TimesheetsHeaderWrap from './components/TimesheetsHeaderWrap';

const Timesheets: React.FC<RouteComponentProps> = ({ history, ...restRouteProps }) => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const user = useAppSelector((state) => state.app.user);
  const isConEdFieldSupervisor = user?.roles?.includes(EROLES.coned_field_supervisor);
  const isSubcontractor = useAppSelector((state) => state.app.userIsSubcontractor);
  const canGeneratePayrollReport = UserPermissions.has.generate_payroll_report;
  const { results: timesheets = [], related_to_one_conf } = useAppSelector((state) => state.timesheets.timesheets);
  const searchOptions = useAppSelector((state) => state.timesheets.search_options);
  const loading = useAppSelector((state) => state.timesheets.processing);
  const mark_timesheets_as_paid_procession = useAppSelector((state) => state.timesheets.mark_as_paid_processing);
  const exportCSVProcessing = useAppSelector((state) => state.timesheets.exportCSVProcessing);
  const threads = useAppSelector((state) => state.timesheets.timesheetsThreadsCount);
  const showMobileFilters = useAppSelector((state) => state.timesheets.showMobileFilters);
  const inputRef = useRef(null);
  const searchParams = useMemo(() => searchOptions.asMutable({ deep: true }), [searchOptions]);
  const { isDesktop } = useAppMediaQuery();

  const retrieve = () => dispatch(actions.TimesheetsActions.retrieve());
  const updateFilters = (search_options: Partial<ReduxState['timesheets']['search_options']>) => {
    dispatch(actions.TimesheetsActions.updateFilters(search_options));
  };
  const importExcel = (file) => dispatch(actions.TimesheetsActions.importExcel(file));

  const handleChangeSearchParams = (event, userParams = null) => {
    event?.preventDefault();
    const { name, value } = userParams ? userParams : event.target;
    updateFilters({ [name]: value });
  };

  const handleChangeFileExcel = async (e: any) => {
    const file = e.target.files[0];
    try {
      await importExcel(file);
      showSuccessMessage('Timesheets imported!');
    } catch (error) {
      if (error.hasOwnProperty('error')) {
        showErrorMessage(error);
      } else {
        const errors = error.errors;
        Object.keys(errors).forEach((key) => {
          showErrorMessage(errors[key][0]);
        });
      }
    }
  };

  const triggerInputFile = () => {
    inputRef.current.click();
  };

  const handleWorkerSelect = ({ name, value }) => {
    updateFilters({ [name]: value });
  };

  const cancelledTimesheetsShowed =
    searchOptions.show_cancelled_timesheets && searchOptions.status === TimesheetStatus.cancelled;
  const toggleCancelledTimesheets = () => {
    if (cancelledTimesheetsShowed) {
      updateFilters({ show_cancelled_timesheets: false, status: null });
      return;
    }
    updateFilters({ show_cancelled_timesheets: true, status: TimesheetStatus.cancelled });
  };

  const exportTimesheetsToCSV = async () => {
    try {
      const timesheetsCSV = await dispatch(actions.TimesheetsActions.exportToCSV());
      downloadFileToPC(URL.createObjectURL(timesheetsCSV), 'timesheets-list', 'xlsx');
      showSuccessMessage('Timesheets downloaded!');
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const togglePendingTimesheets = () => {
    updateFilters({ pending: !searchOptions.pending });
  };

  useEffect(() => {
    retrieve();
  }, []);

  return (
    <>
      <TimesheetsHeaderWrap>
        {isConEdFieldSupervisor && (
          <Tooltip disableInteractive title="View Timesheets for all your departments">
            <FormControlLabel
              style={{ marginBottom: 0 }}
              control={
                <Checkbox
                  checked={searchParams.field_supervisor}
                  color="primary"
                  onChange={(event) =>
                    handleChangeSearchParams(event, {
                      name: 'field_supervisor',
                      value: event.target.checked,
                    })
                  }
                  name="unassigned"
                />
              }
              label="All Dept"
            />
          </Tooltip>
        )}
        {!isSubcontractor && (
          <Tooltip disableInteractive title="Create new Timesheet">
            <Button
              variant="contained"
              size="small"
              style={{ minWidth: 20 }}
              onClick={() => history.push('/timesheets/create')}
            >
              <AddRounded htmlColor="white" />
            </Button>
          </Tooltip>
        )}
        <TimesheetHeader />
        {canGeneratePayrollReport && (
          <Tooltip disableInteractive title="Import and Mark the Timesheets as Worker Paid" aria-label="import" arrow>
            <div style={{ marginInline: 8 }}>
              <input
                className="input-excel"
                accept=".xls,.xlsx"
                ref={inputRef}
                type="file"
                onChange={handleChangeFileExcel}
              />
              <Button variant="contained" onClick={triggerInputFile}>
                <NoteAddRounded />
              </Button>
            </div>
          </Tooltip>
        )}
        {canSeeCancelledTimesheets(user.roles) && (
          <CancelledTimesheetButton
            active={cancelledTimesheetsShowed}
            onClick={toggleCancelledTimesheets}
            divProps={{ style: { marginInline: 8 } }}
          />
        )}

        <PendingTimesheetsFilterButton active={searchOptions.pending} onClick={togglePendingTimesheets} />

        <ExportToFileButton processing={exportCSVProcessing} onClick={exportTimesheetsToCSV}>
          Export to CSV
        </ExportToFileButton>
      </TimesheetsHeaderWrap>

      {loading || mark_timesheets_as_paid_procession ? <LinearProgress /> : <div style={{ height: 4 }} />}

      <div className="timesheet-body">
        {(isDesktop || showMobileFilters) && (
          <TimesheetsFilters
            handleWorkerSelect={handleWorkerSelect}
            handleChangeSearchParams={handleChangeSearchParams}
            searchParams={searchParams}
          />
        )}
        <ThreadLegend visible={related_to_one_conf && threads && !showMobileFilters} />

        {timesheets.length > 0 && <MainTimesheetsTable history={history} {...restRouteProps} />}
      </div>
    </>
  );
};

export default Timesheets;
