import React, { useCallback, useState } from 'react';

import { ExpandMore } from '@mui/icons-material';
import { IconProps } from '@mui/material';

import styles from './ExpandableBlock.module.scss';

type Props = {
  title: string;
  additionalInfo?: string | number;
  iconSize?: IconProps['fontSize'];
  children?: JSX.Element;
};

const ExpandableBlock = ({ title = '', additionalInfo = '', iconSize = 'medium', children = null }: Props) => {
  const [expand, setExpand] = useState(false);

  const toggleExpand = useCallback(() => setExpand((prev) => !prev), []);

  return (
    <div>
      <div className={styles.headerContainer} onClick={toggleExpand}>
        <p>{title}</p>
        <div className={styles.iconContainer}>
          {additionalInfo ? <p>{additionalInfo}</p> : null}
          <ExpandMore fontSize={iconSize} className={[styles.svgIcon, expand ? styles.rotated : ''].join(' ')} />
        </div>
      </div>
      {children ? (
        <div className={[styles.animatedContent, expand ? styles.visible : ''].join(' ')}>{children}</div>
      ) : null}
    </div>
  );
};

export default ExpandableBlock;
