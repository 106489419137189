import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Path } from 'history';

import { CurrentLocation } from 'Models/geoLocation';
import { actions } from 'Services';
import { useAppSelector } from 'createStore';

interface Props extends RouteProps<Path> {
  /** @default true */
  canSeePage?: boolean;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, canSeePage = true, ...rest }) => {
  const token = useAppSelector((state) => state.app.token);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const currentLocation: CurrentLocation = {
        Longtitude: position.coords.longitude,
        Latitude: position.coords.latitude,
      };
      actions.MapActions.setCurrentLocation(currentLocation);
    });
  }, []);

  const render = useCallback(
    (props) => {
      if (!token) {
        return <Redirect to="/login" push={false} />;
      }
      // Token exists, but user doesn't have permission to see this page
      if (!canSeePage) {
        return <Redirect to="/" push={false} />;
      }
      return <Component {...props} />;
    },
    [token, Component, canSeePage]
  );

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
