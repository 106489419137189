export const GET_USER_BY_ID_REQUEST = 'users.get_user_by_id_request';
export const GET_USER_BY_ID_SUCCESS = 'users.get_user_by_id_success';
export const GET_USER_BY_ID_ERROR = 'users.get_user_by_id_error';

export const GET_USERS_REQUEST = 'users.get_users_request';
export const GET_USERS_SUCCESS = 'users.get_users_success';
export const GET_USERS_ERROR = 'users.get_users_error';

export const CREATE_USER_REQUEST = 'users.create_user_request';
export const CREATE_USER_SUCCESS = 'users.create_user_success';
export const CREATE_USER_ERROR = 'users.create_user_error';

export const USER_APPROVE_REQUEST = 'users.users_approve_request';
export const USER_APPROVE_SUCCESS = 'users.users_approve_success';
export const USER_APPROVE_ERROR = 'users.users_approve_error';

export const DELETE_USER_REQUEST = 'users.delete_user_request';
export const DELETE_USER_SUCCESS = 'users.delete_user_success';
export const DELETE_USER_ERROR = 'users.delete_user_error';

export const UPDATE_WORKER_REQUEST = 'workers.update_worker_request';
export const UPDATE_WORKER_SUCCESS = 'workers.update_worker_success';
export const UPDATE_WORKER_ERROR = 'workers.update_worker_error';

export const USER_ACTIVATE_EMAIL_REQUEST = 'user.user_activate_email_request';
export const USER_ACTIVATE_EMAIL_SUCCESS = 'user.user_activate_email_success';
export const USER_ACTIVATE_EMAIL_ERROR = 'user.user_activate_email_error';

export const UPDATE_FILTERS = 'users.update_filters';
export const UPDATE_FILTER_OPTIONS = 'users.update_filter_options';
export const UPDATE_SEARCH_VALUE = 'users.update_search_value';
