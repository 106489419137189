import React, { CSSProperties } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment';
import { toast } from 'react-toastify';
import { ImmutableObject } from 'seamless-immutable';

import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Paper,
  PaperProps,
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip, // Tooltip,
} from '@mui/material';

import Invoice from 'types/Invoices';

import { baseToastConfig } from 'Constants/app';
import { INVOICES_TABLE } from 'Constants/invoices';
import { actions } from 'Services';
import { FORMATS } from 'Utils/Date';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { ReduxState, useAppSelector } from 'createStore';

import '../Invoices.scss';
import { statusLabel } from '../components/StatusLabel';

const commaRegExp = /\B(?=(\d{3})+(?!\d))/g;

const StyledTableCell = styled((props: TableCellProps) => <TableCell align="left" {...props} />)({
  padding: '10px',
});

interface Props extends ReduxProps {
  handleRowClick: (invoice: Invoice.Item) => void;
  configId?: string | number;
  style?: CSSProperties;
}
const doubleDigitValue = (value: string | number) => value && parseFloat(value.toString()).toFixed(2);

const InvoiceTableRow = ({
  configurationInvoices,
  searchOptions,
  handleRowClick,
  configId,
  getInvoices,
  getConfigurationInvoices,
  download,
  style,
}: Props) => {
  const { order_by, order_by_type, page, limit, total } = searchOptions;
  const invoices = useAppSelector((state) => state.invoices.invoices);

  const downloadInvoice = (invoice: ImmutableObject<Invoice.Item>) => async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    await download(invoice.config_id, invoice.id)
      .then((res) => {
        toast.success('Invoiced download!', baseToastConfig);
      })
      .catch((err) => {
        toast.error(err.error, baseToastConfig);
      });
  };

  const onPerPageChange = (limit = 10) => {
    if (configId) {
      getConfigurationInvoices(configId, { page: 1, limit });
      return;
    }
    getInvoices({ page: 1, limit });
  };

  const onPaginationChange = (event, page) => {
    if (configId) {
      getConfigurationInvoices(configId, { page });
      return;
    }
    getInvoices({ page });
  };

  const handleSort = (value: string) => () => {
    const new_order_by_type = order_by === value ? !order_by_type : true;
    const params = {
      order_by: value,
      order_by_type: new_order_by_type,
    };
    if (configId) {
      getConfigurationInvoices(configId, params);
    } else {
      getInvoices(params);
    }
  };
  const addCommas = (totalAmount: string) => {
    const [beforeDot, afterDot] = totalAmount.split('.');
    return `${beforeDot.replace(commaRegExp, ',')}.${afterDot}`;
  };

  const openInvoice = (invoice: Invoice.Item) => () => handleRowClick(invoice);

  const showedInvoices = configId ? configurationInvoices : invoices;

  return (
    <>
      <Paper className="invoices-table-container" style={{ maxHeight: '100%', ...style }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead style={{ background: '#fff' }}>
              <TableRow>
                {INVOICES_TABLE.map((headCell) => {
                  if (headCell.sortable) {
                    return (
                      <StyledTableCell key={headCell.value}>
                        <Tooltip title={headCell.tooltipTitle || ''} disableInteractive placement="top">
                          <TableSortLabel
                            active={order_by === headCell.value}
                            direction={order_by === headCell.value && order_by_type ? 'desc' : 'asc'}
                            onClick={handleSort(headCell.value)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </Tooltip>
                      </StyledTableCell>
                    );
                  } else {
                    return (
                      <Tooltip title={headCell.tooltipTitle || ''} disableInteractive placement="top">
                        <StyledTableCell key={headCell.value}>{headCell.label}</StyledTableCell>
                      </Tooltip>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            {showedInvoices?.length ? (
              <TableBody>
                {showedInvoices.map((invoice) => (
                  <TableRow
                    hover={true}
                    style={{ cursor: 'pointer' }}
                    key={invoice.id}
                    onClick={openInvoice(invoice.asMutable({ deep: true }))}
                  >
                    <StyledTableCell>{invoice.id}</StyledTableCell>
                    <StyledTableCell>{moment(invoice.date).format(FORMATS.dateWithFullYear)}</StyledTableCell>
                    <StyledTableCell>
                      {invoice.start_date && moment(invoice.start_date).format(FORMATS.dateWithFullYear)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.finish_date && moment(invoice.finish_date).format(FORMATS.dateWithFullYear)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.invoiced_date && moment(invoice.invoiced_date).format(FORMATS.dateWithFullYear)}
                    </StyledTableCell>
                    <StyledTableCell>{invoice.timesheets}</StyledTableCell>
                    <StyledTableCell>{invoice.po}</StyledTableCell>
                    <StyledTableCell>{invoice.paid}</StyledTableCell>
                    <StyledTableCell>{doubleDigitValue(invoice.regular_hours)}</StyledTableCell>
                    <StyledTableCell>{doubleDigitValue(invoice.overtime_hours)}</StyledTableCell>
                    <StyledTableCell>{doubleDigitValue(invoice.holiday_hours)}</StyledTableCell>
                    <StyledTableCell>{doubleDigitValue(invoice.emergency_hours)}</StyledTableCell>
                    <StyledTableCell>
                      {invoice.total_due ? '$' + addCommas(invoice.total_due.toFixed(2)) : '$0.00'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.total_paid ? '$' + addCommas(invoice.total_paid.toFixed(2)) : '$0.00'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {invoice.total_outstanding ? '$' + addCommas(invoice.total_outstanding.toFixed(2)) : '$0.00'}
                    </StyledTableCell>
                    <StyledTableCell>{statusLabel(invoice.status)}</StyledTableCell>
                    <StyledTableCell align="inherit">
                      <div className="d-flex">
                        <Tooltip disableInteractive title="Download" aria-label="download" arrow>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={'table-action_img-btn'} onClick={downloadInvoice(invoice)}>
                              <GetAppIcon style={{ color: 'black' }} />
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={11} align={'center'} style={{ fontWeight: 500, fontSize: 14 }}>
                    No invoices yet
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <AppTablePagination
          page={page}
          total={total}
          perPage={limit}
          onChangePage={onPaginationChange}
          onPaginationChange={onPerPageChange}
        />
      </Paper>
    </>
  );
};

type GetInvoicesParams = Parameters<(typeof actions)['InvoicesActions']['getConfigurationInvoices']>;

function mapStateToProps({ invoices }: ReduxState) {
  return {
    searchOptions: invoices.invoices_pagination,
    invoices: invoices.invoices,
    configurationInvoices: invoices.configuration_invoices,
    storedConfigId: invoices.invoices_config_id,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    getInvoices: (search_options: GetInvoicesParams[1]) =>
      dispatch(actions.InvoicesActions.getInvoices(search_options)),
    getConfigurationInvoices: (id: GetInvoicesParams[0], search_options: GetInvoicesParams[1]) =>
      dispatch(actions.InvoicesActions.getConfigurationInvoices(id, search_options)),
    download: (config_id, invoice_id) => dispatch(actions.InvoicesActions.downloadInvoice(config_id, invoice_id)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(InvoiceTableRow);
