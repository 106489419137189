/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { toast, ToastOptions } from 'react-toastify';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, LinearProgress, Typography } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { ConedUser } from 'types/Common/User';
import { AtLeastOne, Mutable } from 'types/utils';

import { baseToastConfig } from 'Constants/app';
import ProfileModal from 'Containers/Profile/ProfileModal';
import { actions } from 'Services';
import { UsersAPI } from 'Services/API';
import { API_BASE_ENDPOINT } from 'Services/endpoint';
import { showErrorMessage } from 'Utils/errorMessage';
import useAppMediaQuery from 'Utils/hooks/useAppMediaQuery';
import usePagination from 'Utils/hooks/usePagination';
import useProcessing from 'Utils/hooks/useProcessing';
import { fastSort } from 'Utils/sortUtils';
import app_history from 'app_history';
import AppTablePagination from 'components/AppTablePagination/AppTablePagination';
import { usePopoverContext } from 'context/PopoversContext';
import { ReduxState, useAppSelector } from 'createStore';

import RolesFilter from './RolesFilter';
import RolesSortTable from './RolesSortTable';
import { BlackButton } from './styled';

const getPluralWord = (count = 0, word = '') => (count === 1 ? word : word + 's');

const getSortedUsers = (
  users: ConedUser[],
  sort: {
    name: keyof ConedUser;
    direction: 'asc' | 'desc';
  }
) => {
  if (!users) return [];
  const { name, direction } = sort;
  return fastSort(users, name, direction);
};

const RolesComponent: React.FC = () => {
  const params = useParams();

  const dispatch = useDispatch<AppThunkDispatch>();
  const conedUsers = useAppSelector((state) => state.users.users);
  const loading = useAppSelector((state) => state.users.processingUsers);
  const page = useAppSelector((state) => state.users.filterOptions.page);
  const perPage = useAppSelector((state) => state.users.filterOptions.per_page);

  const sortBy = useAppSelector((state) => state.users.filterOptions.sortBy);
  const sortDirection = useAppSelector((state) => state.users.filterOptions.sortByType);

  const { openPopover, closeAll } = usePopoverContext();

  const { isDesktop } = useAppMediaQuery();

  const updateFilters = useCallback(
    (filters?: AtLeastOne<Mutable<ReduxState['users']['filterOptions']>>) =>
      dispatch(actions.UsersActions.updatePagination(filters)),
    [dispatch]
  );

  const { onChangePage, onPerPageChange } = usePagination(updateFilters);

  const [filtersOpened, setFiltersOpened] = useState(false);

  const { inProcess: importing, promiseWrapper } = useProcessing();

  const retrieveUsers = () => dispatch(actions.UsersActions.retrieveUsers());

  const changeSortDirection = (sortByType: 'asc' | 'desc') => updateFilters({ sortByType });

  const changeSort = (name: keyof ConedUser) => {
    if (name !== sortBy) {
      updateFilters({ sortBy: name, sortByType: 'desc' });
      return;
    }
    if (sortDirection === 'desc') {
      updateFilters({ sortBy: name, sortByType: 'asc' });
      return;
    }
    updateFilters({ sortBy: 'id', sortByType: 'desc' });
  };

  const changeExcelFile = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await importFromExcelFile(e.target.files[0]);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      e.target.value = null;
    }
  };

  const importFromExcelFile = async (file: File) => {
    try {
      const res = await promiseWrapper(UsersAPI.importFromExcel(file));
      const noCloseToast: ToastOptions = { ...baseToastConfig, autoClose: false };
      const { created = 0, updated = 0, failures = [] } = res;
      const errors = failures?.length || 0;
      if (created || updated) {
        const createdUsers = `${created} ${getPluralWord(created, 'user')} imported`;
        const updatedUsers = `${updated} ${getPluralWord(updated, 'user')} updated`;
        const errorUsers = `${errors} ${getPluralWord(errors, 'user')} throwed ${getPluralWord(errors, 'error')}`;
        toast.success(`${createdUsers}, ${updatedUsers}${errors ? ', ' + errorUsers : ''}`, noCloseToast);
        retrieveUsers();
      }
      if (errors) {
        failures?.forEach((failure) => {
          const row = failure?.row ? `Table Row: ${failure.row}` : '';
          const email = failure?.email ? `Email: ${failure.email}` : '';
          const error = failure?.error ? `Error: ${failure.error}` : '';
          setTimeout(
            () =>
              toast.error(
                <div key={email + row}>
                  {[row, email, error].map((text) => (text ? <p style={{ margin: 0 }}>{text}</p> : null))}
                </div>,
                noCloseToast
              ),
            10
          );
        });
      }
    } catch (error) {
      if (error.hasOwnProperty('error')) {
        toast.error(error.error, baseToastConfig);
      } else {
        const { errors = [] } = error;
        Object.keys(errors).forEach((key) => toast.error(errors[key][0], baseToastConfig));
      }
    }
  };

  const downloadImportTemplate = () => {
    const url = API_BASE_ENDPOINT?.replace('api/v1', 'ces_users_import_template.xlsx');
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = 'ces_users_import_template.xlsx';
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
    a.remove();
  };

  const renderUsers = useMemo(() => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return getSortedUsers(conedUsers.asMutable({ deep: true }), { direction: sortDirection, name: sortBy }).slice(
      startIndex,
      endIndex
    );
  }, [conedUsers, sortBy, sortDirection, page, perPage]);

  const openCreateUserModal = () => {
    openPopover('create-user-modal', <ProfileModal open onClose={closeAll} />);
  };

  return (
    <div className="container-fluid roles-list-page">
      <Box className="page-header" display="flex">
        <Typography variant="h6">Manage Users Roles</Typography>

        {!isDesktop && (
          <Box display="flex">
            <Button
              variant={filtersOpened ? 'contained' : 'outlined'}
              onClick={() => setFiltersOpened((prev) => !prev)}
            >
              <FilterAltIcon />
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          gap={2}
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}
          style={{ ...(filtersOpened ? { display: 'flex' } : {}) }}
        >
          <RolesFilter
            onChangeSort={changeSort}
            sort={{ direction: sortDirection, name: sortBy }}
            onChangeSortDirection={changeSortDirection}
          />

          <Box
            display="flex"
            alignItems="center"
            gap={1}
            width="max-content"
            flexWrap={'wrap'}
            justifyContent={'center'}
          >
            <Button variant="contained" color="secondary" onClick={openCreateUserModal}>
              Create new
            </Button>

            <Link to={`/workers/create`}>
              <Button variant="contained" color="secondary">
                Add Worker
              </Button>
            </Link>

            <Button variant="contained" component="label">
              Import from Excel
              <input
                className="input-excel"
                accept=".xls,.xlsx"
                type="file"
                id="input-file"
                onChange={changeExcelFile}
              />
            </Button>

            <Button variant="contained" color="success" onClick={downloadImportTemplate}>
              Download Template
            </Button>
          </Box>
        </Box>
      </Box>
      <LinearProgress style={{ visibility: loading || importing ? 'visible' : 'hidden' }} />
      <Box
        display="flex"
        flexDirection={'column'}
        paddingBottom={2}
        overflow={'auto'}
        className="container-fluid page-body"
        onClick={() => setFiltersOpened(false)}
      >
        <RolesSortTable users={renderUsers} onChangeSort={changeSort} sortBy={sortBy} sortDirection={sortDirection} />

        <Box
          borderTop={'1px solid #00000010'}
          sx={{
            position: {
              xs: 'fixed',
              md: 'relative',
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
            left: 0,
            bottom: 0,
          }}
        >
          <AppTablePagination
            total={conedUsers.length}
            perPage={perPage}
            page={page}
            onChangePage={onChangePage}
            onPaginationChange={onPerPageChange}
          />
        </Box>
      </Box>
      {params['id'] && (
        <ProfileModal
          modalId="edit-profile-modal"
          open={Boolean(params['id'])}
          userID={params['id']}
          onClose={() => app_history.push('/roles')}
        />
      )}
    </div>
  );
};

export default RolesComponent;
