import { DoNotDisturbAltRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { ImageWithAuthor } from 'types/Location';

import { ImageHeaderButtonsProps } from 'components/ImagePreview/types';

type Props = ImageHeaderButtonsProps<ImageWithAuthor> & {
  onRejectPhoto?: (image: ImageWithAuthor) => void;
  canRejectPhoto?: boolean;
};

const HeaderButtons = ({ image, onRejectPhoto = () => {}, canRejectPhoto }: Props) => {
  return (
    <>
      {canRejectPhoto && !image.is_rejected && !image.url.includes('blob') && (
        <Tooltip disableInteractive title={image?.is_rejected ? 'Already rejected' : 'Reject bad photo'}>
          <IconButton color="error" aria-label="rotate" onClick={() => onRejectPhoto(image)}>
            <DoNotDisturbAltRounded color="error" fontSize="large" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default HeaderButtons;
