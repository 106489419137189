import React from 'react';

import generalStyles from 'general.module.scss';

import { Box } from '@mui/material';

import { TimesheetNames } from 'Constants/timesheet';
import TimesheetBox from 'Containers/Timesheets/components/TimesheetBox/TimesheetBox';
import AppInput from 'components/AppInput';
import { useTimesheetFormContext } from 'context/TimesheetEditContext';

import styles from '../DateTimeInfo/DateTimeInfo.module.scss';

const ConEdisonInfo = () => {
  const formikBag = useTimesheetFormContext();
  const timesheet = formikBag.values;

  return (
    <TimesheetBox>
      <Box className={generalStyles.inputsBox}>
        <AppInput
          FormHelperTextProps={{ className: styles.helperText }}
          name={TimesheetNames.conEdisonTruck}
          disabled={formikBag.isLockedByConEdVerified}
          label="ConEdison Truck #"
          placeholder="ConEdison Truck #"
          value={timesheet.conEdisonTruck ?? ''}
          onChange={formikBag.handleChange}
          error={!!formikBag.errors.conEdisonTruck}
          helperText={formikBag.errors.conEdisonTruck && formikBag.errors.conEdisonTruck}
        />
        <AppInput label="ConEdison Supervisor" value={timesheet.conEdisonSupervisorName ?? ''} disabled />
      </Box>
    </TimesheetBox>
  );
};

export default ConEdisonInfo;
