import { Select, styled } from '@mui/material';

export const StyledSelect = styled(Select)(({ theme }) => ({
  boxShadow: '0px 4px 3px 0px #F0F0F0',
  width: '100%',
  '& .MuiSelect-select.MuiInputBase-input': { paddingRight: '0 !important', fontSize: 13 },
  fieldset: { borderRadius: 8, border: '1px solid #E8EDF1' },
  textTransform: 'capitalize',
  backgroundColor: 'white',
  '& .MuiSvgIcon-root': { color: 'text.secondary', transform: 'translateX(-8px)' },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#E8EDF1' },
  },
  '& .MuiSelect-select[aria-expanded="true"]': {
    '& ~ .MuiOutlinedInput-notchedOutline': { borderColor: 'primary.main' },
  },
}));
