import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

import {
  emailRegExp,
  lettersAndNumbersWithSpacesRegExp,
  lettersWithSpacesRegExp,
  numbersRegExp,
} from 'Constants/reg_exp';
import { PhoneNumberValidation } from 'Constants/validation';

export const CreateSubcontractorValidation = Yup.object().shape({
  firstName: Yup.string()
    .matches(lettersWithSpacesRegExp, 'First Name can only contain Latin letters')
    .required('First Name is required')
    .trim(),
  lastName: Yup.string()
    .matches(lettersWithSpacesRegExp, 'Last Name can only contain Latin letters')
    .required('Last Name is required')
    .trim(),
  phoneNumber: PhoneNumberValidation.required('Phone Number is required'),
  companyName: Yup.string()
    .matches(lettersAndNumbersWithSpacesRegExp, 'Company Name can only contain Latin letters or numbers')
    .required('Company Name is required')
    .min(2, 'Company Name is too short')
    .trim(),
  email: Yup.string().required('Email is required').matches(emailRegExp, 'Email is not correct'),
});
