import { AppThunkAction } from 'types';

import { ReportType } from 'Constants/reports';
import { ReportsAPI } from 'Services/API';
import { ReportParams } from 'Services/API/ReportsAPI.service';

import { GET_REPORT_REQUEST, GET_REPORT_SUCCESS, GET_REPORT_ERROR } from './actionTypes';

const createSendReport = (
  type: (typeof ReportType)[keyof typeof ReportType],
  data: ReportParams
): AppThunkAction<Promise<Blob>> => {
  return async function (dispatch) {
    try {
      dispatch({ type: GET_REPORT_REQUEST });
      const reportFile = await ReportsAPI.downloadReportByType(type, data);
      dispatch({ type: GET_REPORT_SUCCESS });
      return reportFile;
    } catch (error) {
      dispatch({ type: GET_REPORT_ERROR });
      throw error;
    }
  };
};

export const sendReportPayroll = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.Payroll, data);

export const sendReportDaily = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.Daily, data);

export const sendReportDailyOpen = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.DailyOpen, data);

export const sendReportWeekly = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.Weekly, data);

export const sendReportWeeklyUnpaid = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.WeeklyUnpaid, data);

export const sendReportMetrics = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.Metrics, data);

export const sendTecorienPayroll = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.TecorienPayroll, data);

export const sendWorkersTotalHours = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.WorkersTotalHours, data);

export const sendCESSupervisorsTotalHours = (data: ReportParams) =>
  createSendReport(ReportsAPI.TYPE.CESSupervisorsTotalHours, data);

export const sendDepartmentInvoices = (data: ReportParams) =>
  createSendReport(ReportsAPI.TYPE.DepartmentInvoices, data);

export const sendPaidTimesheets = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.PaidTimesheets, data);

export const sendBilledTimesheets = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.BilledTimesheets, data);

export const sendUnpaidInvoices = (data: ReportParams) => createSendReport(ReportsAPI.TYPE.UnpaidInvoices, data);

export const sendSuspiciousFlaggingTimesheets = (data: ReportParams) =>
  createSendReport(ReportsAPI.TYPE.SuspiciousFlaggingTimesheets, data);
