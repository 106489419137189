import { toast } from 'react-toastify';

import { baseToastConfig } from '../Constants/app';

export default function fileSizeFilter(retrievedFiles: File[] | FileList = [], limitInMb = 20) {
  const filesLowestThanLimit = Array.from(retrievedFiles).filter((file) => {
    if (!file?.size) return true;
    const oneMbInBytes = 1024 * 1024;
    if (file?.size > limitInMb * oneMbInBytes) {
      setTimeout(
        () =>
          toast.error(
            `The file "${file?.name}" wasn't uploaded because its size (${parseFloat(
              (file?.size / oneMbInBytes).toFixed(2)
            )} MB) exceeds the ${limitInMb} MB limit`,
            { ...baseToastConfig, autoClose: false, className: 'toast-error-brake-word' }
          ),
        5
      );
      return false;
    }
    return true;
  });
  return filesLowestThanLimit;
}
