import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import { EnvVariables } from 'Constants/app';
import LocationsMaterialAsyncSearch from 'Containers/Components/Controls/LocationsMaterialAsyncSearch';
import Point from 'Containers/Maps/Point';
import { JobsAPI } from 'Services/API';
import { showErrorMessage } from 'Utils/errorMessage';

import styles from './MapSelect.module.scss';

export type MapLocationItem = {
  id?: number;
  address: string;
  lat: number;
  lng: number;
  structure: string;
  images: any;
  note?: string;
  exist?: boolean;
};

interface IProps {
  withMap?: boolean;
  location: MapLocationItem;
  confirmationNumber?: string;
  onChangeLocation: (location: MapLocationItem) => void;
}

class MapSelect extends Component<IProps> {
  selectedLocation: MapLocationItem;

  static defaultProps = {
    withMap: true,
  };

  state = {
    loading: false,
    defaultLocations: [] as { label: string; value: MapLocationItem }[],
  };

  getDefaultLocations = (jobId) => {
    this.setState({ loading: true });
    JobsAPI.getJob({ jobId })
      .then(({ locations }) => {
        this.setState({ defaultLocations: this.parseLocationsOptions(locations) });
      })
      .catch(showErrorMessage)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  componentDidMount(): void {
    if (!this.props.confirmationNumber) {
      return;
    }
    this.getDefaultLocations(this.props.confirmationNumber);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.confirmationNumber === this.props.confirmationNumber) {
      return;
    }
    if (!this.props.confirmationNumber) {
      this.setState({ defaultLocations: [] });
      this.props.onChangeLocation(null);
      return;
    }
    this.getDefaultLocations(this.props.confirmationNumber);
  }

  parseLocationsOptions(locations) {
    return locations.map(({ id, lat, lng, images, structure, address, note }) => ({
      label: address,
      value: { id, lat, lng, images, structure, address, note, exist: true },
    }));
  }

  onSelectLocation = (item: { value: MapLocationItem }) => {
    this.props.onChangeLocation(item?.value || null);
  };

  get mapCenter() {
    return {
      lat: this.props.location.lat,
      lng: this.props.location.lng,
    };
  }

  render() {
    const { location } = this.props;
    return (
      <div className={styles.mapSelectBlock}>
        <LocationsMaterialAsyncSearch
          className={styles.locationSearchInput}
          loading={this.state.loading}
          label="Location Address"
          inputSize="small"
          defaultOptions={this.state.defaultLocations}
          onSelect={this.onSelectLocation}
        />

        {this.props.withMap && !!location && (
          <div className={styles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: EnvVariables.GoogleMapApiKey,
              }}
              center={this.mapCenter}
              defaultCenter={{
                lat: Number(EnvVariables.MapCenterLat),
                lng: Number(EnvVariables.MapCenterLng),
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={11}
            >
              {!!location && (
                <Point lat={location.lat} lng={location.lng}>
                  {1}
                </Point>
              )}
            </GoogleMapReact>
          </div>
        )}
      </div>
    );
  }
}

export default MapSelect;
