import ApiService, { API } from 'Services/HttpService';

class BillingAPI {
  getJobs = (params: API.RequestBody) => {
    return ApiService.GET('/billing/jobs', params);
  };

  updateDispute = (jobId: string | number, disputeData: API.RequestBody) => {
    return ApiService.POST(`/billing/jobs/${jobId}/dispute`, disputeData);
  };

  updateStatus = (jobId: string | number, statusData: API.RequestBody) => {
    return ApiService.POST(`/billing/jobs/${jobId}/change-status`, statusData);
  };

  revertStatus = (jobId: string | number) => {
    return ApiService.POST(`/billing/jobs/${jobId}/revert-status`);
  };
}

export default new BillingAPI();
