import { FC } from 'react';

import { Box } from '@mui/material';

import { StyledActionButton } from './styled';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
  loading?: boolean;
};

const DepartmentActions: FC<Props> = ({ onSubmit, onCancel, loading = false }) => {
  return (
    <Box display={'flex'} gap={'10px'} flex={1}>
      <StyledActionButton cancelButton onClick={onCancel} disabled={loading}>
        Cancel
      </StyledActionButton>
      <StyledActionButton onClick={onSubmit} loading={loading}>
        Save
      </StyledActionButton>
    </Box>
  );
};

export default DepartmentActions;
