import Immutable from 'seamless-immutable';

import { ConEdWorkerForTable } from 'types/Common/User';

import { JobType } from 'Constants/job';
import { convertWorkerAppVersionsToString } from 'Utils/PrepareData';
import { getFilteredWorkersWithOptions } from 'Utils/worker';

import { LOGOUT } from '../app/actionTypes';
import * as actionTypes from './actionTypes';

const initialState = Immutable({
  workers: [] as Array<ConEdWorkerForTable>,
  filteredWorkers: [] as ConEdWorkerForTable[],
  processing: false,
  exportToCsvLoading: false,
  search_options: {
    workerType: 0 as JobType,
    status: '',
    subcontractorId: 0,
    query: '',
    order_by: 'id',
    order_by_type: true,
  },
  tablePagination: {
    page: 1,
    perPage: 10,
    total: 0,
  },
});

export default function (state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.GET_WORKERS_REQUEST:
      return state.merge({
        processing: true,
      });
    case actionTypes.GET_WORKERS_SUCCESS:
      const workers = action.workers.map((worker) => ({
        ...worker,
        app_versions: convertWorkerAppVersionsToString([...worker.app_versions]),
      }));
      const tablePagination: (typeof state)['tablePagination'] = {
        ...state.tablePagination,
        total: workers.length,
      };
      const filteredWorkers = getFilteredWorkersWithOptions(workers, state.search_options);
      return state.merge({
        processing: false,
        workers,
        filteredWorkers,
        tablePagination,
      });
    case actionTypes.GET_WORKERS_ERROR:
      return state.merge({
        processing: false,
      });

    case actionTypes.UPDATE_FILTER_OPTIONS: {
      const search_options: (typeof state)['search_options'] = {
        ...state.search_options,
        ...action.filters,
      };
      const filteredWorkers = getFilteredWorkersWithOptions(state.workers.asMutable({ deep: true }), search_options);
      const tablePagination: (typeof state)['tablePagination'] = {
        ...state.tablePagination,
        page: 1,
        total: filteredWorkers.length,
      };
      return state.merge({
        search_options,
        filteredWorkers,
        tablePagination,
      });
    }
    case actionTypes.UPDATE_PAGINATION: {
      const tablePagination: (typeof state)['tablePagination'] = {
        ...state.tablePagination,
        ...action.pagination,
      };

      return state.merge({
        tablePagination,
      });
    }
    // Update Worker Status
    case actionTypes.UPDATE_WORKER_STATUS_REQUEST:
      return state.merge({
        processing: true,
      });

    case actionTypes.UPDATE_WORKER_STATUS_SUCCESS: {
      const workers = state.workers.asMutable({ deep: true }).map((worker) =>
        worker.id === action.worker.id
          ? {
              ...worker,
              status: action.worker.status,
            }
          : worker
      );
      const filteredWorkers = getFilteredWorkersWithOptions(workers, state.search_options);

      return state.merge({
        processing: false,
        workers,
        filteredWorkers,
      });
    }

    case actionTypes.UPDATE_WORKER_STATUS_ERROR:
      return state.merge({
        processing: false,
      });

    // UPDATE WORKER
    case actionTypes.UPDATE_WORKER_REQUEST:
      return state.merge({
        processing: true,
      });

    case actionTypes.UPDATE_WORKER_SUCCESS: {
      const workers = state.workers
        .asMutable()
        .map((worker) => (worker.id === action.worker.id ? action.worker : worker));
      return state.merge({
        processing: false,
        workers,
      });
    }

    case actionTypes.UPDATE_WORKER_ERROR:
      return state.merge({
        processing: false,
      });

    case actionTypes.EXPORT_TO_EXCEL_REQUEST:
      return state.merge({
        exportToCsvLoading: true,
      });
    case actionTypes.EXPORT_TO_EXCEL_SUCCESS:
    case actionTypes.EXPORT_TO_EXCEL_ERROR:
      return state.merge({
        exportToCsvLoading: false,
      });

    case LOGOUT:
      return state.merge(initialState);

    default:
      return state;
  }
}
