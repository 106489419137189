import { FC } from 'react';

import { Box, Skeleton } from '@mui/material';

const ChatItemSkeleton: FC = () => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" p={1} gap={1}>
      <Box display="flex" gap={1} alignItems="center" flex={1}>
        <Skeleton variant="rounded" width={34} height={34} />
        <Skeleton variant="rectangular" sx={{ flex: 1 }} height={16} />
      </Box>
      <Skeleton variant="rectangular" width={64} height={16} />
    </Box>
  );
};

const ChatsHistorySkeleton: FC = () => {
  return (
    <Box width="100%" height="100%" display="flex" flexDirection="column" gap={1}>
      {Array.from({ length: 10 }).map((_, index) => (
        <ChatItemSkeleton key={index} />
      ))}
    </Box>
  );
};

export default ChatsHistorySkeleton;
