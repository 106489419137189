import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment-timezone';
import { DateRange, Calendar, Range } from 'react-date-range';
import Select, { CSSObjectWithLabel } from 'react-select';

import { LoadingButton } from '@mui/lab';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';

import { AppThunkDispatch } from 'types';

import { MUNICIPALITY } from 'Constants/job';
import { REPORTS, ReportType } from 'Constants/reports';
import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import { showSuccessMessage } from 'Utils/successMessage';
import { useAppSelector } from 'createStore';

import './Report.scss';

const ReportsSendMap = {
  [ReportType.Daily]: actions.ReportActions.sendReportDaily,
  [ReportType.DailyOpen]: actions.ReportActions.sendReportDailyOpen,
  [ReportType.Metrics]: actions.ReportActions.sendReportMetrics,
  [ReportType.Monthly]: actions.ReportActions.sendReportWeekly,
  [ReportType.Payroll]: actions.ReportActions.sendReportPayroll,
  [ReportType.TecorienPayroll]: actions.ReportActions.sendTecorienPayroll,
  [ReportType.Weekly]: actions.ReportActions.sendReportWeekly,
  [ReportType.WeeklyUnpaid]: actions.ReportActions.sendReportWeeklyUnpaid,
  [ReportType.WorkersTotalHours]: actions.ReportActions.sendWorkersTotalHours,
  [ReportType.CESSupervisorsTotalHours]: actions.ReportActions.sendCESSupervisorsTotalHours,
  [ReportType.DepartmentInvoices]: actions.ReportActions.sendDepartmentInvoices,
  [ReportType.PaidTimesheets]: actions.ReportActions.sendPaidTimesheets,
  [ReportType.BilledTimesheets]: actions.ReportActions.sendBilledTimesheets,
  [ReportType.UnpaidInvoices]: actions.ReportActions.sendUnpaidInvoices,
  [ReportType.SuspiciousFlaggingTimesheets]: actions.ReportActions.sendSuspiciousFlaggingTimesheets,
};

const dateFormat = 'YYYY-MM-DDTHH:mm';

const Reports = () => {
  const theme = useTheme();
  const dispatch = useDispatch<AppThunkDispatch>();

  const processing = useAppSelector((state) => state.reports.processing);
  const permissions = useAppSelector((state) => state.app.permissions);

  const onlyAllowedReports = useMemo(
    () => REPORTS.filter((report) => permissions.includes(report.permission)),
    [permissions]
  );
  const [dates, setDates] = useState({ start: new Date(), end: new Date() });
  const [reportType, setReportType] = useState(onlyAllowedReports[0].value || ReportType.Daily);
  const [municipalities, setMunicipalities] = useState<typeof MUNICIPALITY>([]);

  const rangeMode = reportType !== ReportType.Daily;

  const municipalityRequired = [
    ReportType.Daily,
    ReportType.DailyOpen,
    ReportType.Weekly,
    ReportType.WeeklyUnpaid,
    ReportType.Metrics,
    ReportType.Monthly,
  ].some((type) => type === reportType);

  const showRange = [
    ReportType.Metrics,
    ReportType.Payroll,
    ReportType.TecorienPayroll,
    ReportType.WorkersTotalHours,
    ReportType.CESSupervisorsTotalHours,
    ReportType.DepartmentInvoices,
    ReportType.PaidTimesheets,
    ReportType.BilledTimesheets,
    ReportType.UnpaidInvoices,
    ReportType.SuspiciousFlaggingTimesheets,
  ].some((type) => type === reportType);

  const error = municipalityRequired && !municipalities.length ? 'Select Borough!' : '';

  const resetState = () => {
    setDates({ start: new Date(), end: new Date() });
    setReportType(onlyAllowedReports[0].value || ReportType.Daily);
    setMunicipalities([]);
  };

  const handleChangeReportType = (value) => {
    setReportType(value);
  };

  const onChangeRange = ({ selection: { startDate: start, endDate: end } }: { selection: Range }) => {
    setDates(getDates({ start, end }));
  };

  const onChangeDate = (date: Date) => {
    setDates(getDates({ start: date, end: date }));
  };

  const getNearestDate = ({ start, end }: { start: Date; end: Date }) => {
    const startDateDiff = Math.abs(moment(start).diff(moment(), 'days'));
    const endDateDiff = Math.abs(moment(end).diff(moment(), 'days'));
    return startDateDiff > endDateDiff ? end : start;
  };

  const getDates = useCallback(
    (selectedDates: typeof dates) => {
      const nearestDate = getNearestDate(selectedDates);
      switch (reportType) {
        case ReportType.Daily:
          return { start: nearestDate, end: nearestDate };
        case ReportType.DailyOpen:
          const today = moment();

          return { start: today.subtract(1, 'month').toDate(), end: new Date() };
        case ReportType.Weekly:
        case ReportType.WeeklyUnpaid:
          const weekStart = moment(nearestDate).startOf('week').toDate();
          const weekEnd = moment(nearestDate).endOf('week').toDate();

          return { start: weekStart, end: weekEnd };
        case ReportType.Monthly:
          // If the user pressed on a certain date - we choose the whole month that includes this date
          let startDate = moment(nearestDate).startOf('month');
          let finishDate = startDate.clone().add(1, 'month').subtract(1, 'day');
          // Change dates if finishDate is after today. Set finishDate as today and set startDate as today - 1month
          if (finishDate.isAfter(moment(), 'days')) {
            startDate = moment().subtract(1, 'month').startOf('day');
            finishDate = moment().startOf('day');
          }
          return { start: startDate.toDate(), end: finishDate.toDate() };
        default:
          return selectedDates;
      }
    },
    [reportType]
  );

  useEffect(() => {
    if (reportType === ReportType.Daily) {
      setDates({ start: moment().toDate(), end: moment().toDate() });
    } else {
      setDates(getDates);
    }
    if (!municipalityRequired) {
      setMunicipalities([]);
    }
  }, [reportType]);

  const createReportBody = () => {
    const {
      Daily,
      DailyOpen,
      Metrics,
      Monthly,
      Payroll,
      TecorienPayroll,
      Weekly,
      WeeklyUnpaid,
      WorkersTotalHours,
      CESSupervisorsTotalHours,
      DepartmentInvoices,
      PaidTimesheets,
      BilledTimesheets,
      UnpaidInvoices,
      SuspiciousFlaggingTimesheets,
    } = ReportType;
    switch (reportType) {
      case Payroll:
      case TecorienPayroll:
      case WorkersTotalHours:
      case CESSupervisorsTotalHours:
      case DepartmentInvoices:
      case PaidTimesheets:
      case BilledTimesheets:
      case UnpaidInvoices:
      case SuspiciousFlaggingTimesheets:
        return {
          cutOffTimeStart: moment(dates.start).startOf('day').format(dateFormat),
          cutOffTimeFinish: moment(dates.end).startOf('day').format(dateFormat),
        };
      case Daily:
        return {
          date: moment(dates.start).format(dateFormat),
          municipalities: municipalities.map((item) => item.value),
        };
      case DailyOpen:
        return {
          municipalities: municipalities.map((item) => item.value),
        };
      case Weekly:
      case WeeklyUnpaid:
      case Metrics:
        return {
          cutOffTimeStart: moment(dates.start).format(dateFormat),
          cutOffTimeFinish: moment(dates.end).format(dateFormat),
          municipalities: municipalities.map((item) => item.value),
        };
      case Monthly:
        return {
          cutOffTimeStart: moment(dates.start).format(dateFormat),
          cutOffTimeFinish: moment(dates.end).format(dateFormat),
          municipalities: municipalities.map((item) => item.value),
          report_type: 'monthly',
        };

      default:
        return {};
    }
  };

  const dailyOpenReportType = reportType === ReportType.DailyOpen;

  const generateReport = async () => {
    if (error) {
      showErrorMessage(error);
      return;
    }
    try {
      await dispatch(ReportsSendMap[reportType](createReportBody()));
      showSuccessMessage('Report generated!');
    } catch (error) {
      showErrorMessage(error);
    }
  };

  return (
    <div className="report-page">
      <div className="main-block">
        <div className="d-flex justify-content">
          <p className="title-1 mt-3">Reports</p>
          <p className="title-blue" onClick={resetState}>
            Reset Selected
          </p>
        </div>
        <div className="white-block">
          <div className="left-column">
            <p className="title-2">Select Borough</p>
            <Select
              styles={{
                container: (baseStyles) =>
                  ({
                    ...baseStyles,
                    maxWidth: 330,
                  } as CSSObjectWithLabel),
                control: (baseStyles, props) =>
                  ({
                    ...baseStyles,
                    ...(props.isDisabled && { backgroundColor: 'rgb(242, 242, 242)' }),
                    ...(!props.isDisabled && !props.getValue().length && { borderColor: 'red' }),
                    height: 60,
                    overflow: 'hidden',
                    borderRadius: 12,
                  } as CSSObjectWithLabel),
                placeholder: (baseStyles, props) =>
                  ({
                    ...baseStyles,
                    ...(!props.getValue().length && !props.isDisabled && { color: 'red' }),
                  } as CSSObjectWithLabel),
              }}
              isDisabled={!municipalityRequired}
              options={MUNICIPALITY}
              isMulti
              placeholder="Select municipality"
              value={municipalities}
              onChange={(newValue) => setMunicipalities([...newValue])}
            />
            <p className="title-2 mt1">Choose Report Type:</p>
            <div className="options">
              <FormControl component="fieldset">
                <RadioGroup value={reportType} onChange={(event, value) => handleChangeReportType(value)}>
                  {onlyAllowedReports.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio color="primary" />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="right-column">
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',
                  top: '10%',
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: dailyOpenReportType ? 9999 : -999,
                }}
                onClick={(e) => {
                  if (dailyOpenReportType) {
                    e.preventDefault();
                    return;
                  }
                }}
              />
              {showRange ? (
                <DateRange
                  onChange={onChangeRange}
                  rangeColors={[theme.palette.primary.main]}
                  ranges={[{ key: 'selection', startDate: dates.start, endDate: dates.end }]}
                  calendarFocus="forwards"
                />
              ) : (
                <Calendar
                  ranges={[{ key: 'selection', startDate: dates.start, endDate: dates.end }]}
                  date={dates.start}
                  rangeColors={[theme.palette.primary.main]}
                  displayMode="dateRange"
                  dragSelectionEnabled={false}
                  onChange={onChangeDate}
                />
              )}
            </div>
            {rangeMode && (
              <div className="report-range-text">
                Date Range:{' '}
                <span style={{ fontWeight: 600 }}>
                  {moment(dates.start).format('MMM, D YYYY')} - {moment(dates.end).format('MMM, D YYYY')}
                  {moment(dates.end).isSame(new Date(), 'day') ? ' (Today)' : ''}
                </span>
              </div>
            )}
            <div className="btn-row">
              <LoadingButton
                color="secondary"
                variant="contained"
                sx={{
                  width: 169,
                  height: 48,
                  borderRadius: 24,
                }}
                onClick={generateReport}
                loading={processing}
                disabled={processing}
              >
                Generate
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
