import { Notifications } from 'types/Notifications';

import ApiService, { API } from 'Services/HttpService';

class NotificationAPI {
  getAll = (params: Notifications.API.GET_List.Request) => {
    return ApiService.GET<Notifications.API.GET_List.Response>('/notifications', params);
  };

  update = (updateBody: API.RequestBody) => {
    return ApiService.PUT('/notifications', updateBody);
  };

  delete = (notifiactionId: string | number) => {
    return ApiService.DELETE(`/notifications/${notifiactionId}`);
  };
}

export default new NotificationAPI();
