import { Tabs, colors, styled, Box, Table, Button } from '@mui/material';

export const StyledMasterTimesheetsTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 'auto',
  '& .MuiTabs-flexContainer': { gap: '18px' },

  '& .MuiTab-root': {
    textTransform: 'capitalize',
    padding: 0,
    fontWeight: 600,
    color: theme.palette.text.primary,
    opacity: 0.5,
    transition: 'color 0.15s ease-in-out, opacity 0.15s ease-in-out',
    letterSpacing: 0.5,
    '&.MuiButtonBase-root': {
      minHeight: 50,
    },

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      opacity: 1,
    },

    '&:hover:not(.Mui-Selected)': {
      opacity: 0.8,
    },
  },
}));

export const MasterTimesheetContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  backgroundColor: colors.common.white,
  borderRadius: 10,
  padding: '30px 26px',
});

export const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableHead-root': {
    '& .MuiTableRow-head': {
      '& .MuiTableCell-root': {
        backgroundColor: theme.palette.secondary.light,
        border: 'none',

        '&:first-of-type': {
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        },

        '&:last-of-type': {
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        },

        '& .MuiTableSortLabel-root': {
          '&.Mui-active': {
            color: theme.palette.primary.main,
            '& .MuiTableSortLabel-icon': {
              color: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },

  '& .MuiTableCell-root': {
    padding: '10px 15px',
    fontSize: 13,
  },
}));

export const StyledDeleteButton = styled(Button)({
  lineHeight: '19.5px',
  color: '#C52525',
  backgroundColor: '#FF2F2F1A',
  padding: '8px 15px',
  display: 'flex',
  gap: '8px',
  '& .MuiButton-startIcon': { margin: 0 },
  '&:hover': {
    backgroundColor: '#FF2F2F33',
  },
});
