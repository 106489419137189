import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import moment from 'moment';
import { MdCheck, MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import { DoneAllRounded, AssignmentTurnedIn, AddPhotoAlternateRounded } from '@mui/icons-material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AddAlarmRounded from '@mui/icons-material/AddAlarmRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, Button, Tooltip, Typography, IconButton, Box, Tab, alpha } from '@mui/material';

import { AppThunkDispatch } from 'types';
import { Job } from 'types/Job';

import LocationImg from 'Assets/location.png';
import PDFicon from 'Assets/pdf-icon.png';
import { baseToastConfig } from 'Constants/app';
import { BillingStatus } from 'Constants/billing';
import { JOB_STATUSES, JobStatusHelper, JobType, POET_NUMBER } from 'Constants/job';
import { JobTypeHelper } from 'Constants/job';
import { TimesheetStatus, canSeeCancelledTimesheets } from 'Constants/timesheet';
import { EROLES, UserRoles } from 'Constants/user';
import ImageViewer from 'Containers/Components/ImageViewer/ImageViewer';
import ExtendEndDate from 'Containers/Dispatch/dialog/ExtendEndDate';
import CompleteJob from 'Containers/Dispatch/dialog/completeJob/CompleteJob';
import PageNotFound from 'Containers/PageNotFound/PageNotFound';
import MasterTimesheet from 'Containers/Timesheets/MasterTimesheet/MasterTimesheet';
import { actions, ActionTypes } from 'Services';
import { JobsAPI } from 'Services/API';
import { UpdatePOSData } from 'Services/API/JobsAPI.service';
import * as CEIcon from 'Utils/Icon';
import UserPermissions from 'Utils/PermissionsHelper';
import { getFileLink } from 'Utils/PrepareData';
import { downloadFileToPC } from 'Utils/downloadFile';
import { showErrorMessage } from 'Utils/errorMessage';
import { canVerifyJob } from 'Utils/job';
import { showSuccessMessage } from 'Utils/successMessage';
import AppDialog from 'components/AppDialog/AppDialog';
import ChangesHistory from 'components/ChangesHistory';
import ChatsHistory from 'components/ChatsHistory/ChatsHistory';
import ImageButton from 'components/ImageButton/ImageButton';
import ModalBase from 'components/ModalBase/ModalBase';
import TextOverflowTooltip from 'components/TextOverflowTooltip';
import UpdatePOButton from 'components/UpdatePOButton/UpdatePOButton';
import { ReduxState } from 'createStore';

import './JobDetails.scss';
import JobMenuItemComponent from './JobMenuItem';
import WorkerGroup from './RerouteWorker/WorkerGroup';
import BillingTypeSelect from './components/BillingTypeSelect';
import CancelJob from './dialogs/CancelJob';
import EditDepartmentAndSectionModal, {
  EditDepartmentFormValues,
} from './dialogs/EditDepartmentAndSectionModal/EditDepartmentAndSectionModal';
import LocationsPhotoEdit from './dialogs/LocationsPhotoEdit/LocationsPhotoEdit';
import ReleaseWorkersModal from './dialogs/ReleaseWorkersModal';
import UpdatePODialog from './dialogs/UpdatePODialog';
import { StyledIconButton, StyledJobDetailsTabs } from './styled';
import { getCanEditEndDate } from './utils';

enum TabContent {
  TabDetails = 1,
  TabHistory,
  TabChatsHistory,
}

type State = {
  tab: TabContent;
  open_complete_job: boolean;
  completed: boolean;
  isInProgressModal: boolean;
  releaseWorkersPrompt: boolean;
  showUpdatePODialog: boolean;
  editLocationPhotosOpen: boolean;
  showEditDepartmentAndSectionModal: boolean;
  editedJob: Partial<Job>;
  downloadAllImagesProcessing: boolean;
  verifyJobModalOpen: boolean;
  masterTimesheetModalOpen: boolean;
};

const dateDisplayFormat = 'MM/DD/YY HH:mm';

interface Props extends RouteComponentProps<{ id: string }>, ReduxProps {}

class JobDetailsComponent extends React.Component<Props, State> {
  state: State = {
    tab: TabContent.TabDetails,
    open_complete_job: false,
    completed: false,
    isInProgressModal: false,
    releaseWorkersPrompt: false,
    showUpdatePODialog: false,
    editLocationPhotosOpen: false,
    showEditDepartmentAndSectionModal: false,
    editedJob: {},
    downloadAllImagesProcessing: false,
    verifyJobModalOpen: false,
    masterTimesheetModalOpen: false,
  };

  getIsConedUser = () => {
    return UserRoles.has.ConEdRole;
  };

  handleCloseCompleteJobModal = () => {
    this.setState({ open_complete_job: false });
  };
  completeJob = () => {
    this.setState({
      open_complete_job: true,
    });
  };

  componentDidUpdate = (prevProps) => {
    const searchParams = new URLSearchParams(this.props.history.location.search);
    const isMasterTimesheetOpenedInSearchParams = Boolean(searchParams.get('master_timesheet'));

    if (this.state.masterTimesheetModalOpen !== isMasterTimesheetOpenedInSearchParams) {
      this.setState({ masterTimesheetModalOpen: isMasterTimesheetOpenedInSearchParams });
    }

    const canSeeDeletedNotes = this.canSeeDeletedNotes();
    if (this.props.match.params.id !== prevProps.match.params.id && this.props.match.params.id !== 'create') {
      this.props.retrieveJob(this.props.match.params.id, '', canSeeDeletedNotes).catch(() => {});
    }
  };

  setComplete = () => {
    this.setState({ completed: true });
  };

  componentDidMount = () => {
    this.fetchNewData();
  };

  componentWillUnmount = () => {
    this.props.clearJob();
  };

  canSeeDeletedNotes = () => {
    return UserPermissions.has.see_deleted_notes;
  };

  fetchNewData = async () => {
    const canSeeDeletedNotes = this.canSeeDeletedNotes();
    if (this.props.match.params.id !== 'create') {
      this.props.retrieveJob(this.props.match.params.id, '', canSeeDeletedNotes).catch(() => {});
    }
  };

  changeTab(tab: TabContent) {
    this.setState({ tab });
  }

  showModal(show: boolean) {
    this.setState({ isInProgressModal: show });
  }

  downloadPdf = async () => {
    const response = await JobsAPI.downloadPdf(this.props.match.params.id);
    downloadFileToPC(window.URL.createObjectURL(new Blob([response])), `Conf_#${this.props.match.params.id}.pdf`);
  };

  downloadHistoryPdf = async () => {
    const response = await JobsAPI.downloadHistoryPdf(this.props.job.id.toString());
    downloadFileToPC(
      window.URL.createObjectURL(new Blob([response])),
      `Conf_#${this.props.match.params.id}_History.pdf`
    );
  };

  editJob = () => {
    this.props.history.push(`/job/${this.props.match.params.id}/edit`);
  };

  viewShifts = () => {
    this.props.history.push(`/dispatch?search=id:${this.props.match.params.id}`);
    this.props.retrieveLocationJob(Number(this.props.match.params.id));
  };

  copyJob = () => {
    this.props.history.push(`/job/${this.props.match.params.id}/copy`);
  };

  openMasterTimesheetModal = () => {
    this.props.history.push(`/job/${this.props.match.params.id}?master_timesheet=1`);
  };

  closeMasterTimesheetModal = () => {
    this.props.history.push(`/job/${this.props.match.params.id}`);
  };

  viewTimeSheets = (openMasterTimesheet = false) => {
    if (openMasterTimesheet) {
      this.openMasterTimesheetModal();
      return;
    }
    this.props.history.push(`/timesheets?grouped=1&confirmation=${this.props.match.params.id}`);
  };

  viewCancelledTimesheets = () => {
    this.props.viewCancelledTimesheets(this.props.match.params.id);
    this.props.history.push('/timesheets');
  };

  openReleaseWorkersPrompt = () => {
    this.setState({ releaseWorkersPrompt: true });
  };

  closeReleaseWorkersPrompt = () => {
    this.setState({ releaseWorkersPrompt: false });
  };

  showUpdatePOModal = () => {
    this.setState({
      showUpdatePODialog: true,
    });
  };

  closeUpdatePOModal = () => {
    this.setState({
      showUpdatePODialog: false,
    });
  };

  downloadAllImages = async () => {
    try {
      this.setState({ downloadAllImagesProcessing: true });
      await JobsAPI.downloadAllImages(this.props.match.params.id);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      this.setState({ downloadAllImagesProcessing: false });
    }
  };

  retrieveCurrentJob = () => {
    const canSeeDeletedNotes = this.canSeeDeletedNotes();
    this.props.retrieveJob(this.props.job.id, '', canSeeDeletedNotes).catch(() => {});
  };

  updatePOS = (posData: UpdatePOSData) => {
    this.props
      .updatePOS(posData)
      .then((updatedFields) => {
        this.setState({ showUpdatePODialog: false });
        this.retrieveCurrentJob();
        showSuccessMessage(`${updatedFields} updated successfully!`);
      })
      .catch((error) => {
        let msg = error.error;
        if (msg.includes('SQL')) {
          msg = 'Error! Your data is invalid. The maximum limit is 30 characters or numbers.';
        }
        toast.error(msg, baseToastConfig);
      });
  };

  openEditDepartmentAndSectionModal = () => {
    this.setState({ showEditDepartmentAndSectionModal: true });
  };

  closeEditDepartmentAndSectionModal = () => {
    this.setState({ showEditDepartmentAndSectionModal: false });
  };

  updateDepartmentAndSection = async ({ department, section }: EditDepartmentFormValues) => {
    const isSelectedDepartmentService = this.props.departmentsMap[department.id]?.is_service_dept;
    const updatedPoet = !isSelectedDepartmentService ? POET_NUMBER[this.props.job?.municipality.label] : '';

    const update: Partial<Job> = {
      department: department.id,
      departmentName: department.name,
      poet: updatedPoet,
      section,
      ...(!isSelectedDepartmentService && { account_no: '' }),
    };

    try {
      await this.props.updateJobDeptAndSection(this.props.job.id, update);
      showSuccessMessage('Job updated successfully');
      this.closeEditDepartmentAndSectionModal();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  openExtendJobTime = async () => {
    await this.props.retrieveLocationJob(Number(this.props.match.params.id));
    this.props.dispatch({ type: ActionTypes.JobsActionTypes.OPEN_EXTEND_JOB_TIME });
  };

  renderMenu() {
    const { isSignage } = new JobTypeHelper(this.props.job.jobType);
    const { isCompleted, isInProgress } = new JobStatusHelper(this.props.job.jobStatus);
    const isJobCanBeEdited = this.props.job.can_edit;
    const extendJobTimePerms =
      getCanEditEndDate({
        jobType: this.props.job.jobType,
        department: this.props.departmentsMap[this.props.job.department],
        isStandby: Boolean(this.props.job.is_standby),
      }) && UserPermissions.has.extend_job_end_time;
    const isJobCanBeCancelled = this.props.job.can_cancel;

    let isAllShiftsCompleted = this.props.job?.workers?.length > 0;
    for (let i = 0; this.props.job && this.props.job.workers && i < this.props.job.workers.length; i++) {
      const worker = this.props.job.workers[i];
      if (
        ['assigned', 'en_route', 'on_location', 'secured', 'cannot_secure', 'crew_arrived', 'review'].indexOf(
          worker.workerStatus
        ) !== -1
      ) {
        isAllShiftsCompleted = false;
      }
    }

    const userCanReleaseWorkers = UserPermissions.has.release_workers;
    const userCanDoJobAction = UserPermissions.has.can_do_job_action;
    const isConedUser = this.getIsConedUser();

    return (
      <Box
        display="flex"
        flexWrap="wrap"
        columnGap={1}
        justifyContent="space-between"
        alignItems="center"
        bgcolor="white"
        borderRadius={2}
        px={'20px'}
        mb={1}
      >
        <StyledJobDetailsTabs
          scrollButtons={false}
          value={this.state.tab}
          onChange={(e, value) => this.changeTab(value)}
        >
          <Tab icon={<CEIcon.JobIcon />} iconPosition="start" label="Job Details" value={TabContent.TabDetails} />
          <Tab
            icon={<AccessTimeFilledIcon sx={{ width: 14, height: 14 }} />}
            iconPosition="start"
            label="History"
            value={TabContent.TabHistory}
          />
          <Tab icon={<CEIcon.ChatIcon />} iconPosition="start" label={'Chats'} value={TabContent.TabChatsHistory} />
        </StyledJobDetailsTabs>
        <Box flexWrap="wrap" alignItems="center" gap={1} py={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {this.renderVerifyJobButton()}
          {userCanDoJobAction && <UpdatePOButton onClick={this.showUpdatePOModal} size="small" />}
          {UserPermissions.has.download_job && (
            <Tooltip title="Download Job" disableInteractive>
              <StyledIconButton onClick={this.downloadPdf}>
                <CEIcon.DownloadIcon />
              </StyledIconButton>
            </Tooltip>
          )}
          {UserPermissions.has.copy_job && !isSignage && (
            <Tooltip title="Copy Job" disableInteractive>
              <StyledIconButton onClick={this.copyJob}>
                <CEIcon.CopyIcon />
              </StyledIconButton>
            </Tooltip>
          )}
          {isJobCanBeEdited && (
            <Tooltip title="Edit Job" disableInteractive>
              <StyledIconButton onClick={this.editJob}>
                <CEIcon.EditIcon />
              </StyledIconButton>
            </Tooltip>
          )}
          {UserPermissions.has.view_shifts && (
            <Tooltip title="View shifts" disableInteractive>
              <StyledIconButton onClick={this.viewShifts}>
                <CEIcon.ShiftIcon />
              </StyledIconButton>
            </Tooltip>
          )}
          {extendJobTimePerms && (
            <Tooltip
              title={!this.props.job.finish_at ? 'Job must have a finish date to extend job time' : 'Extend Job Time'}
              disableInteractive
            >
              <Box>
                <StyledIconButton
                  onClick={this.openExtendJobTime}
                  disabled={!this.props.job.finish_at || this.props.location_job_processing}
                >
                  {this.props.location_job_processing ? <CircularProgress size={14} /> : <AddAlarmRounded />}
                </StyledIconButton>
              </Box>
            </Tooltip>
          )}
          {isJobCanBeCancelled && (
            <Tooltip title="Cancel Job" disableInteractive>
              <StyledIconButton
                onClick={() => this.showModal(true)}
                sx={(theme) => ({
                  color: theme.palette.error.main,
                  backgroundColor: alpha(theme.palette.error.main, 0.1),
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.error.main, 0.2),
                  },
                  '&:disabled': {
                    backgroundColor: alpha(theme.palette.error.main, 0.05),
                  },
                })}
              >
                <CEIcon.CloseIcon />
              </StyledIconButton>
            </Tooltip>
          )}
          {UserPermissions.has.complete_job && (
            <>
              {/* USER TYPE CONDITION*/}

              {(this.props.job.jobStatus !== JOB_STATUSES.Completed &&
                this.props.job.jobStatus !== JOB_STATUSES.Cancelled &&
                this.props.job.jobStatus !== JOB_STATUSES.CancelledBillable &&
                this.props.job.jobStatus !== JOB_STATUSES.Review &&
                !this.state.completed) ||
                (this.props.job.jobStatus === JOB_STATUSES.Review && !isConedUser && (
                  <Tooltip
                    disableInteractive
                    title={isAllShiftsCompleted ? 'Complete Job' : 'Please complete all shifts before completing job'}
                    placement={'top'}
                  >
                    <Box>
                      <StyledIconButton
                        onClick={this.completeJob}
                        disabled={!isAllShiftsCompleted}
                        sx={(theme) => ({
                          color: theme.palette.success.main,
                          backgroundColor: alpha(theme.palette.success.main, 0.1),
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.success.main, 0.2),
                          },
                          '&:disabled': {
                            backgroundColor: alpha(theme.palette.success.main, 0.05),
                          },
                        })}
                      >
                        <AssignmentTurnedIn />
                        <Typography
                          fontSize={10}
                          ml={0.5}
                          color={isAllShiftsCompleted ? 'success.main' : 'text.disabled'}
                        >
                          Complete Job
                        </Typography>
                      </StyledIconButton>
                    </Box>
                  </Tooltip>
                ))}
            </>
          )}
          {userCanReleaseWorkers && isInProgress && (
            <Tooltip
              disableInteractive
              title={isCompleted ? 'Job already completed' : 'Close timesheets and complete job'}
              placement={'bottom'}
            >
              <StyledIconButton onClick={this.openReleaseWorkersPrompt} disabled={isCompleted}>
                <DoneAllRounded />
                <Typography fontSize={10} ml={0.5} color="primary">
                  Release Workers
                </Typography>
              </StyledIconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  }

  renderComponentJobDetails() {
    const job = this.props.job;
    const departmentsMap = this.props.departments.reduce((acc, dept) => {
      acc[dept.id] = dept;
      return acc;
    }, {});

    const userIsSubcontractor = this.props.user.roles.every((role) => role === EROLES.subcontractor);
    const canAddDeptAndSection = UserPermissions.has.edit_job_department_section;
    const userCanChangeEmergencyType = UserPermissions.has.change_job_emergency_type;
    const isServiceDepartment = departmentsMap[job.department]?.is_service_dept;
    const { isSignage, isParking, isFlagging } = new JobTypeHelper(job.jobType);
    const { isCompleted, isCancelledBillable, isReview } = new JobStatusHelper(job.jobStatus);
    const jobImages = this.props?.job?.locations
      ?.asMutable({ deep: true })
      ?.map((location) => location.images)
      .flat();

    const isEligibleForModal = isCompleted || isCancelledBillable || isReview;
    const canOpenModal = isEligibleForModal && isParking && this.getIsConedUser();
    const openOnlyMasterTimesheetModal = canOpenModal;

    return (
      <div className="job-details-content-box" style={{ overflowY: 'auto' }}>
        <div className="job-details-info-box">
          <ul className="job-item job-item--contacts">
            <li>
              <span className=" label">Requestor</span>
              <p>{this.props.job.requestorName}</p>
              {this.props.job.requestorObj && (
                <>
                  <TextOverflowTooltip title={this.props.job.requestorObj.phoneNumber || ''}>
                    <p className="showNameEmail">{this.props.job.requestorObj.phoneNumber || ''}</p>
                  </TextOverflowTooltip>

                  {!userIsSubcontractor && (
                    <TextOverflowTooltip title={this.props.job.requestorObj.email || ''}>
                      <p className="showNameEmail">{this.props.job.requestorObj.email || ''}</p>
                    </TextOverflowTooltip>
                  )}
                </>
              )}
            </li>

            <li>
              <span className="label">Supervisor</span>
              <p>{this.props.job.supervisorName}</p>
              {this.props.job.supervisorObj && (
                <>
                  <TextOverflowTooltip title={this.props.job.supervisorObj.phoneNumber || ''}>
                    <p className="showNameEmail">{this.props.job.supervisorObj.phoneNumber || ''}</p>
                  </TextOverflowTooltip>
                  {!userIsSubcontractor && (
                    <TextOverflowTooltip title={this.props.job.supervisorObj.email || ''}>
                      <p className="showNameEmail">{this.props.job.supervisorObj.email || ''}</p>
                    </TextOverflowTooltip>
                  )}
                </>
              )}
            </li>

            <li>
              <span className="label">CC User</span>
              {this.props.job && this.props.job.ccUser
                ? this.props.job.ccUser.map((user, i) => {
                    return (
                      <div key={i}>
                        <div>{user.firstName + ' ' + user.lastName}</div>
                        <p className="showNameEmail" style={{ margin: 0 }} title={user.phoneNumber || ''}>
                          {user.phoneNumber || ''}
                        </p>
                        {!userIsSubcontractor && (
                          <p className="showNameEmail" style={{ margin: 0 }} title={user.email || ''}>
                            {user.email || ''}
                          </p>
                        )}
                      </div>
                    );
                  })
                : null}
            </li>

            <li>
              <span className="label">Municipality</span>
              <p>{this.props.job.municipality ? this.props.job.municipality.label : `---`}</p>
            </li>

            <li>
              <Box display="flex" alignItems="center" gap={1}>
                <span className="label">Department</span>
                {canAddDeptAndSection && (
                  <Tooltip disableInteractive title="Edit department and/or section">
                    <IconButton onClick={this.openEditDepartmentAndSectionModal} sx={{ padding: 0 }}>
                      <BorderColorIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <p>{job.departmentName || '---'}</p>
            </li>

            <li>
              <span className="label">Section</span>
              <p>{job.section || '---'}</p>
            </li>
          </ul>
        </div>
        <div className="job-details-info-box">
          <ul className="job-item job-item--counts">
            <li>
              <span className="label">Confirmation #</span>
              <p>{this.props.job.id || '---'}</p>
            </li>
            <li>
              <span className="label">PO #</span>
              <p>{this.props.job.po || '---'}</p>
            </li>
            <li>
              <span className="label">Feeder #</span>
              <p>{this.props.job.feeder || '---'}</p>
            </li>
            <li>
              <span className="label">POET #</span>
              <p>{this.props.job.poet || '---'}</p>
            </li>
            <li>
              <span className="label">Work Request #</span>
              <p>{this.props.job.wr || '---'}</p>
            </li>
            <li>
              <span className="label">Requisition #</span>
              <p>{this.props.job.requisition || '---'}</p>
            </li>
            <li>
              <span className="label">Max Workers</span>
              <p>{this.props.job.maxWorkers || '---'}</p>
            </li>
            <li>
              <span className="label">Receipt #</span>
              <p>{this.props.job.receipt || '---'}</p>
            </li>
            {this.props.job.coned_imported && (
              <>
                <li>
                  <span className="label">Permit #</span>
                  <p>{this.props.job.legacy_id || '---'}</p>
                </li>
                {isSignage && (
                  <>
                    <li>
                      <span className="label">Effective Start Date</span>
                      <p>{job.effective_start_at ? moment(job.effective_start_at).format(dateDisplayFormat) : ''}</p>
                    </li>
                    <li>
                      <span className="label">Effective Finish Date</span>
                      <p>{job.effective_finish_at ? moment(job.effective_finish_at).format(dateDisplayFormat) : ''}</p>
                    </li>
                  </>
                )}
              </>
            )}
            {job?.verifierName && (
              <li>
                <span className="label">
                  Verification:{' '}
                  {job?.verified ? (
                    <span style={{ color: 'green' }}>
                      <MdCheck size={16} style={{ paddingBottom: 2 }} /> Verified
                    </span>
                  ) : (
                    <span style={{ color: 'red' }}>
                      <MdClose size={16} style={{ paddingBottom: 2 }} /> Unverified
                    </span>
                  )}
                </span>
                <div>{job?.verifierName}</div>
                <div>{job?.verifierObj.phoneNumber}</div>
                <div>{job?.verifierObj.email}</div>
              </li>
            )}
            {isServiceDepartment && (
              <li>
                <span className="label">Job #</span>
                <p>{this.props.job.account_no || '---'}</p>
              </li>
            )}
            {!!this.props.job.l2_org && (
              <li>
                <span className="label">L2 Org</span>
                <p>{this.props.job.l2_org}</p>
              </li>
            )}
            {!!this.props.job.l2_code && (
              <li>
                <span className="label">L2 Code</span>
                <p>{this.props.job.l2_code}</p>
              </li>
            )}
          </ul>
        </div>
        <div className="job-details-info-box" style={{ background: '#F3F3F3' }}>
          {this.props.job && this.props.job.locations && this.props.job.workers && (
            <WorkerGroup
              jobId={this.props.job.id}
              onSaveSuccess={this.fetchNewData}
              jobDetail={this.props.job.asMutable({ deep: true })}
              selectRow={() => {}}
              isJobStandby={Boolean(this.props.job.is_standby)}
            />
          )}
        </div>
        <div className="resizble-job-details-container">
          <div className="job-details-info-box job-details-info-box--hours">
            <div className="job-item">
              <div className="label">Request Date/Time</div>
              <div>{moment(this.props.job.requestTime).format(dateDisplayFormat)}</div>
            </div>
            {job.finish_at && (
              <div className="job-item">
                <div className="label">Finish Date/Time</div>
                {moment(job.finish_at).format(dateDisplayFormat)}
              </div>
            )}
            <div className="job-item">
              <div className="label">Billable hours</div>
              <div>{this.props.job.total_billable_hours}h</div>
            </div>
            <div className="job-item">
              <div className="label">Total Hours</div>
              <div>{this.props.job.totalHours}h</div>
            </div>
            <div className="job-item">
              <div className="label">Regular Hours</div>
              <div>{this.props.job.regularHours || 0}h</div>
            </div>
            <div className="job-item">
              <div className="label">Overtime Hours</div>
              <div>{this.props.job.overtimeHours || 0}h</div>
            </div>
            <div className="job-item">
              <div className="label">Holiday Hours</div>
              <div>{this.props.job.holidayHours || 0}h</div>
            </div>
            <div className="job-item">
              <div className="label">Emergency Hours</div>
              <div>{this.props.job.emergencyHours || 0}h</div>
              {isFlagging && userCanChangeEmergencyType && (
                <Box mt={3}>
                  <div className="label">Billing Type</div>
                  <BillingTypeSelect
                    jobID={this.props.job.id}
                    isEmergency={this.props.job.is_emergency}
                    onSuccessfulChange={this.retrieveCurrentJob}
                  />
                </Box>
              )}
            </div>
          </div>
          <div className="job-details-info-box">
            <div className="job-item job-item-comment">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div style={{ flex: 1 }}>
                  <div className="label">Billed</div>
                  <div>{this.props.job.billing_status === BillingStatus.billed ? `Yes` : `No`}</div>
                </div>
                <div style={{ flex: 1 }}>
                  {this.props.job.total_count_timesheets === 0 ? (
                    <div className="label">No current timesheets</div>
                  ) : (
                    <div className="label">{this.props.job.total_count_timesheets} Timesheet(s) Received</div>
                  )}
                  <div>
                    <Button
                      variant={'outlined'}
                      onClick={() => this.viewTimeSheets(openOnlyMasterTimesheetModal)}
                      disabled={this.props.job.total_count_timesheets === 0}
                    >
                      View Timesheets
                    </Button>
                  </div>
                </div>
                {Boolean(this.props.job.cancelled_timesheets_count) &&
                  canSeeCancelledTimesheets(this.props.user.roles) && (
                    <div style={{ flex: 1 }}>
                      <div className="label">{this.props.job.cancelled_timesheets_count} Timesheet(s) Cancelled</div>

                      <div>
                        <Button variant={'outlined'} onClick={this.viewCancelledTimesheets}>
                          View Cancelled
                        </Button>
                      </div>
                    </div>
                  )}
              </div>
              <div className="mt-2">
                <div className="label">Comments</div>
                <div>{this.props.job.comment}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="resizble-job-details-container">
          <div className="job-details-info-box job-details-location-box">
            <div style={{ flex: 1 }}>
              {this.props?.job?.locations?.map((location, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    padding: 10,
                    overflow: 'hidden',
                    wordBreak: 'break-all',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <CEIcon.MapMarkerAltSolidIcon className="mr-2" style={{ width: 20, height: 20, paddingTop: 5 }} />
                  <span>
                    {location.address}
                    {location.structure && location.structure !== 'null' ? (
                      <span className="label" style={{ textTransform: 'inherit' }}>
                        {' - STRUCTURE: ' + location.structure}
                      </span>
                    ) : (
                      ''
                    )}
                    <br />
                    {location.note !== 'null' ? location.note : ''}
                  </span>
                </div>
              ))}
            </div>
            <div className="col-right no-margin" style={{ width: 'auto' }}>
              <div
                style={{ height: 180, width: '100%', display: 'flex', justifyContent: 'center' }}
                onClick={() => this.props.history.push(`/map/${this.props.job.id}`)}
              >
                <Tooltip disableInteractive title="See on the Map" aria-label="add" arrow>
                  <img src={LocationImg} width={200} height={170} alt="Map" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="job-details-image-pdf-container">
            <div className="job-details-info-box" style={{ flex: 'none' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <ImageViewer
                  images={jobImages}
                  jobType={this.props?.job?.jobType}
                  splitPreviewImagesTo={4}
                  onSaveRotatedImage={this.retrieveCurrentJob}
                  showImagesRow
                />
                <ImageButton url="" withoutImage onClick={() => this.setState({ editLocationPhotosOpen: true })}>
                  <AddPhotoAlternateRounded fontSize="large" />
                  <div>Add Photos</div>
                </ImageButton>
                {!!jobImages.length && (
                  <div style={{ padding: 8, display: 'flex', alignItems: 'center' }}>
                    <LoadingButton
                      onClick={this.downloadAllImages}
                      loading={this.state.downloadAllImagesProcessing}
                      loadingPosition="start"
                      startIcon={<CEIcon.DownloadIcon />}
                    >
                      <span>Download All</span>
                    </LoadingButton>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="job-details-info-box">
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {this.props?.job?.jobPdfs?.map((url, indx) => {
                    return (
                      <div key={indx} className="thumb-wrapper" style={{ cursor: 'pointer' }}>
                        <div className="thumb">
                          <img
                            src={PDFicon}
                            alt="pdf"
                            onClick={() => {
                              const link = getFileLink(url);
                              window.open(link, '_blank', 'fullscreen=yes');
                              return false;
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderContent(tab) {
    switch (tab) {
      case TabContent.TabDetails:
        return this.renderComponentJobDetails();
      case TabContent.TabHistory:
        return (
          <ChangesHistory
            jobType={JobType[this.props.job.jobType]}
            changesLog={this.props.job.changesLog}
            isDownloadable
            onDownloadHistoryPdf={this.downloadHistoryPdf}
          />
        );
      case TabContent.TabChatsHistory:
        return <ChatsHistory jobId={this.props.job.id} updateTimeInterval={5000} />;
    }
  }

  openVerifyJobModal = () => {
    this.setState({ verifyJobModalOpen: true });
  };

  closeVerifyJobModal = () => {
    this.setState({ verifyJobModalOpen: false });
  };

  changeJobVerification = async () => {
    const jobVerified = this.props.job?.verified;
    try {
      await this.props.updateJob(this.props.job?.id, { verified: !jobVerified });
      showSuccessMessage(`Job marked as ${jobVerified ? 'unverified' : 'verified'}!`);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      this.closeVerifyJobModal();
    }
  };

  renderVerifyJobButton = () => {
    const { job } = this.props;

    if (!job) return null;

    const canSeeVerifyButton =
      UserPermissions.has.can_verify_jobs && canVerifyJob(job.jobType, job.jobStatus, job.coned_imported);

    if (!canSeeVerifyButton) return null;

    const jobVerified = job.verified;
    return (
      <Button
        onClick={this.openVerifyJobModal}
        color={jobVerified ? 'inherit' : 'primary'}
        variant="contained"
        size="small"
        startIcon={!jobVerified ? <CEIcon.ShieldTickIcon /> : null}
      >
        {jobVerified ? 'Unverify' : 'Verify'} Job
      </Button>
    );
  };

  handleIsInProgressModal = (show) => {
    this.setState({ isInProgressModal: show });
  };

  render() {
    const job = this.props.job;

    const isConedUser = this.getIsConedUser();

    if (job === null && this.props.retrieve_job_processing) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <CircularProgress color="primary" size={60} />
        </div>
      );
    }

    if (job == null) {
      return <PageNotFound />;
    }

    const jobVerified = !!job.verified;

    return (
      <div className="d-flex job-details-page">
        <JobMenuItemComponent />
        <div className="col-right p-4" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          {this.renderMenu()}
          {this.renderContent(this.state.tab)}
        </div>
        <CancelJob
          job={this.props.job.asMutable({ deep: true })}
          open={this.state.isInProgressModal}
          onClose={this.handleIsInProgressModal}
        />
        <CompleteJob
          open={this.state.open_complete_job}
          onClose={this.handleCloseCompleteJobModal}
          job_id={job.id}
          setComplete={this.setComplete}
          calculateTotalHours={isConedUser}
        />
        <ReleaseWorkersModal
          open={this.state.releaseWorkersPrompt}
          onClose={this.closeReleaseWorkersPrompt}
          jobId={this.props.job.id}
        />

        <UpdatePODialog
          open={this.state.showUpdatePODialog}
          close={this.closeUpdatePOModal}
          updatePOS={this.updatePOS}
          receiptUpdate
          selectedJobs={[this.props.job.asMutable({ deep: true })]}
        />
        <LocationsPhotoEdit
          jobId={this.props.job.id}
          open={this.state.editLocationPhotosOpen}
          onClose={() => this.setState({ editLocationPhotosOpen: false })}
          locations={this.props.job.locations.asMutable({ deep: true })}
          jobType={this.props.job?.jobType}
        />
        <ModalBase id="Verify-job-modal" open={this.state.verifyJobModalOpen} onClose={this.closeVerifyJobModal}>
          <AppDialog
            containerStyle={{ maxWidth: 400 }}
            title={jobVerified ? 'Unverify job' : 'Verify job'}
            waitTimer={{
              initialValue: jobVerified ? 0 : 3,
              getButtonTitle: (seconds) => `Wait...${seconds}s`,
            }}
            onClose={this.closeVerifyJobModal}
            submitButton={{
              loading: this.props.update_job_processing,
              title: jobVerified ? 'Unverify' : 'Verify',
              onClick: this.changeJobVerification,
            }}
          >
            <Typography>
              {jobVerified
                ? 'You can set job as unverified if you found some mistakes'
                : 'If everything is ok, click the "Verify" button when it appears. '}
            </Typography>
            {!jobVerified && <Typography>Mark job as verified if you are sure that everything is ok</Typography>}
          </AppDialog>
        </ModalBase>

        {this.state.showEditDepartmentAndSectionModal && (
          <EditDepartmentAndSectionModal
            open={this.state.showEditDepartmentAndSectionModal}
            onClose={this.closeEditDepartmentAndSectionModal}
            onSubmit={this.updateDepartmentAndSection}
          />
        )}

        {this.state.masterTimesheetModalOpen && (
          <MasterTimesheet
            modal
            open={this.state.masterTimesheetModalOpen}
            onClose={this.closeMasterTimesheetModal}
            confirmation={job.id}
          />
        )}

        {job.finish_at && <ExtendEndDate />}
      </div>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    confirm_jobs_processing: state.jobs.confirm_jobs_processing,
    job: state.jobs.job,
    location_job_processing: state.jobs.location_job_processing,
    retrieve_job_processing: state.jobs.retrieve_job_processing,
    user: state.app.user,
    can_complete_jobs: state.app.user ? [2, 5, 6, 8].some((r) => state.app.user.roles.includes(r)) : false,
    update_job_processing: state.jobs.update_job_processing,
    departments: state.app.departments,
    departmentsMap: state.app.departmentsMap,
  };
}
function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatch,
    retrieveJob: (job_id, workerId = '', showDeletedNotes = false) =>
      dispatch(actions.JobsActions.retrieveJob(job_id, workerId, showDeletedNotes)),
    updateJobDeptAndSection: (job_id: number, data: Partial<Job>) =>
      dispatch(actions.JobsActions.updateJobDeptAndSection(job_id, data)),
    clearJob: () => dispatch(actions.JobsActions.clearJob()),
    releaseWorkers: (job_id) => dispatch(actions.JobsActions.releaseWorkers(job_id)),
    retrieveLocationJob: (job_id) => dispatch(actions.JobsActions.retrieveLocationJob({ job_id, id: 0 })),
    updateJob: (job_id, data) => dispatch(actions.JobsActions.updateJob(job_id, data)),
    updatePOS: (params: UpdatePOSData) => dispatch(actions.JobsActions.updatePOS(params)),
    viewJobTimesheets: (confirmation = '') =>
      dispatch(
        actions.TimesheetsActions.updateFilters({
          confirmation,
          page: 1,
          status: null,
          show_cancelled_timesheets: false,
        })
      ),
    viewCancelledTimesheets: (confirmation = '') =>
      dispatch(
        actions.TimesheetsActions.updateFilters({
          confirmation,
          page: 1,
          status: TimesheetStatus.cancelled,
          show_cancelled_timesheets: true,
        })
      ),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(JobDetailsComponent);
