import moment from 'moment';
import * as Yup from 'yup';

import { maximumYearValue } from 'Constants/validation';
import { employeeNumberValidation } from 'Containers/Profile/Profile.Validation';

import { signatureSchema } from '../TimesheetEdit/TimesheetEditValidation';

const MasterTimesheetValidation = Yup.object().shape({
  start_at: Yup.string()
    .label('Start Date')

    .test('valid_date', 'Invalid Date', (value) => moment(value).isValid())
    .test('after_maximum_year', 'Start Date is too late', (value) => moment(value).isBefore(maximumYearValue))
    .test('to_early', 'Start Date is too early', (value) => moment().set('year', 2020).isBefore(value))
    .when('finish_at', ([finish_at]: [string], schema) =>
      schema.test('start_date', 'Start Date must be before Finish Date', (value) => moment(value).isBefore(finish_at))
    )
    .when('initialStartDate', ([initialStartDate]: [string], schema) => {
      if (initialStartDate) {
        return schema.test('start_date', 'The new Start Date must be same or after of initial', (value) =>
          moment(value).isSameOrAfter(initialStartDate)
        );
      }
      return schema;
    })
    .required(),
  finish_at: Yup.string()
    .test('valid_date', 'Invalid Date', (value) => moment(value).isValid())
    .test('after_maximum_year', 'Finish Date is too late', (value) => moment(value).isBefore(maximumYearValue))
    .test('to_early', 'Finish Date is too early', (value) => moment().set('year', 2020).isBefore(value))
    .label('Finish Date')
    .when('initialFinishDate', ([initialFinishDate]: [string], schema) => {
      if (initialFinishDate) {
        return schema.test('finish_date', 'The new Finish Date must be same or before of initial', (value) =>
          moment(value).isSameOrBefore(initialFinishDate)
        );
      }
      return schema;
    })
    .required(),
  signatures: Yup.array().of(signatureSchema),
  signature_data: Yup.string().nullable(),
  employee_number: employeeNumberValidation.when('signature_data', ([signature_data]: [string], schema) => {
    if (signature_data) {
      return schema.required('Employee Number is required');
    }
    return schema;
  }),
});

export default MasterTimesheetValidation;
