import { FC } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import TextField from '@mui/material/TextField';

import { actions } from 'Services';
import { showErrorMessage } from 'Utils/errorMessage';
import AppPaperModal from 'components/AppPaperModal';
import { useAppDispatch, useAppSelector } from 'createStore';

type AddCommentFormValues = {
  comment: string;
};

type Props = {
  title?: string;
  subtitle?: string;
  helperText?: string;
  open: boolean;
  onClose: () => void;
  entity_id: number;
  entity_type?: string;
  onSubmit?: () => void;
};

const AddCommentModal: FC<Props> = ({
  title = 'Timesheet Comment',
  subtitle = 'You can add comments to the timesheet',
  open,
  onClose,
  entity_id,
  entity_type = 'timesheet',
  onSubmit = () => {},
}) => {
  const dispatch = useAppDispatch();
  const processing = useAppSelector((state) => state.comments.processing_create);

  const addComment = async ({ comment }: AddCommentFormValues) => {
    try {
      await dispatch(actions.CommentsActions.create({ entity_id, entity_type, comment }));
      onSubmit();
      onClose();
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const { touched, values, errors, handleChange, submitForm } = useFormik<AddCommentFormValues>({
    initialValues: {
      comment: '',
    },

    onSubmit: addComment,
    validationSchema: Yup.object().shape({
      comment: Yup.string().required('Comment is required.').trim(),
    }),
  });

  return (
    <AppPaperModal
      title={title}
      subtitle={subtitle}
      open={open}
      onClose={onClose}
      containerStyle={{ maxWidth: 450, width: '100%' }}
      contentStyle={{ width: '100%' }}
      submitButton={{
        title: 'Save Changes',
        onClick: submitForm,
        loading: processing,
        disabled: Boolean(touched.comment && errors.comment),
      }}
    >
      <TextField
        fullWidth
        name="comment"
        label="Comment"
        placeholder="Enter your comment"
        multiline
        value={values.comment}
        disabled={processing}
        onChange={handleChange}
        variant="outlined"
        error={Boolean(touched.comment && errors.comment)}
        helperText={touched.comment && errors.comment}
      />
    </AppPaperModal>
  );
};

export default AddCommentModal;
