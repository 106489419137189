import { Select, styled, IconButton, alpha } from '@mui/material';

export const PerPageSelect = styled(Select)({
  fontWeight: 500,
  fontSize: '12px',
  '& .MuiSelect-select': {
    padding: '5px 12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    padding: '0',
    borderRadius: '5px',
    borderColor: '#E4E9EB',
  },
});

export const PaginationButton = styled(IconButton)({
  padding: 0,
  borderRadius: '6px',
  border: '1px solid #E4E9EB',
});

export const NotifiableTypeSelect = styled(Select)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '13px',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '& .MuiSelect-select': {
    padding: '6px 12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    padding: '0',
    border: 'none',
  },
}));
