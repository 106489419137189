import React, { useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Collapsible from 'react-collapsible';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import { JobShift } from 'types/Common/JobData';
import { Job } from 'types/Job';

import { JOB_STATUSES, JobTypeHelper } from 'Constants/job';
import { EROLES } from 'Constants/user';
import { canRerouteJobWorker, getWorkerNextStatus, WorkerStatus } from 'Constants/worker';
import TrimmedText from 'Containers/Timesheets/components/TrimmedText';
import * as CeIcon from 'Utils/Icon';
import useModal from 'Utils/hooks/useModal';
import { getWorketStatusString } from 'Utils/worker';
import app_history from 'app_history';
import { useAppSelector } from 'createStore';

import ReleaseSelectedWorkersDialog from '../dialogs/ReleaseSelectedWorkersDialog';
import RerouteWorkersModal from '../dialogs/RerouteWorkersModal';
import UpdateWorkersStatusesModal from '../dialogs/UpdateWorkersStatusesModal';
import styles from './WorkerGroup.module.scss';
import './WorkerGroup.scss';

interface Props {
  jobId: number | string;
  onSaveSuccess: () => void;
  jobDetail?: Job;
  noBorder?: boolean;
  selectRow?: (selected?: boolean) => void;
  isJobStandby?: boolean;
}

const WorkerGroup: React.FC<Props> = ({
  jobId,
  onSaveSuccess,
  jobDetail,
  noBorder,
  selectRow = () => {},
  isJobStandby = false,
}) => {
  const user = useAppSelector((state) => state.app.user);

  const rerouteWorkersModal = useModal();
  const updateWorkerStatusModal = useModal();
  const releaseWorkersModal = useModal();

  const [openedGroupIdxs, setOpenedGroupIdxs] = useState(new Set<number>());
  const [workerStatusUpdateId, setWorkerStatusUpdateId] = useState(0);
  const [selectedWorkers, setSelectedWorkers] = useState<JobShift[]>([]);
  const [rerouteToJob, setRerouteToJob] = useState(false);

  const { isFlagging, isParking, isSignage } = useMemo(
    () => new JobTypeHelper(jobDetail?.jobType),
    [jobDetail?.jobType]
  );

  const groups = useMemo(() => {
    const { locations = [], workers = [], is_standby } = jobDetail;
    const sourceWorkers = new Set(workers);
    return locations.map((loc) => {
      const locationWorkers: JobShift[] = [];
      sourceWorkers.forEach((worker) => {
        if (worker?.locationID === loc.id) {
          locationWorkers.push(worker);
          sourceWorkers.delete(worker);
        }
      });
      return {
        location: loc,
        workers: locationWorkers,
        newJobID: is_standby && loc.job_id !== jobDetail.id ? loc.job_id : '',
      };
    });
  }, [jobDetail]);

  const openRerouteWorkerModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, toJob = false) => {
    event.stopPropagation();
    selectRow(true);
    setRerouteToJob(toJob);
    rerouteWorkersModal.open();
  };

  const closeRerouteWorkerModal = () => {
    selectRow(false);
    rerouteWorkersModal.close();
  };

  const selectWorker = (worker: JobShift) => {
    setSelectedWorkers((prevState) => [...prevState, worker]);
  };

  const unselectWorker = (worker: JobShift) => {
    setSelectedWorkers((prevState) => prevState.filter((item) => item.shift_id !== worker.shift_id));
  };

  const toggleSelectWorker = (worker: JobShift) => {
    if (!selectedWorkers) {
      setSelectedWorkers([worker]);
      return;
    }
    const isWorkerSelected = selectedWorkers.findIndex((item) => item.shift_id === worker.shift_id) > -1;
    isWorkerSelected ? unselectWorker(worker) : selectWorker(worker);
  };

  const onChangeWorkers = (updatedWorkers: JobShift[]) => {
    setSelectedWorkers(updatedWorkers);
  };

  const needToProveStatus = (status: WorkerStatus) =>
    [WorkerStatus.OnLocation, WorkerStatus.Secure, WorkerStatus.CanNotSecure].includes(status) &&
    jobDetail.coned_imported &&
    (isParking || isSignage);

  const isWorkerStatusUpdatable = (worker: JobShift) =>
    worker?.status !== WorkerStatus.Completed && !needToProveStatus(worker.status);

  const showUpdateWorkerStatusButton = useMemo(
    () => user?.roles?.some((role) => [5, 6, 8].includes(role)) && selectedWorkers?.every(isWorkerStatusUpdatable),
    [user?.roles, selectedWorkers]
  );

  const isWorkerReleasable = (worker: JobShift) =>
    worker && worker.status !== WorkerStatus.Completed && worker.status !== WorkerStatus.Review;

  const onChangeWorkerStatusUpdateId = (workerId: number) => {
    setWorkerStatusUpdateId(workerId);
  };

  const getWorkerStatusName = useCallback(
    (status: string | number) => getWorketStatusString(status, jobDetail.jobType),
    [jobDetail?.jobType]
  );

  const updateWorkerStatusButtonName = useMemo(() => {
    if (!selectedWorkers?.length) return;
    const [worker] = selectedWorkers?.filter((selectedWorker) => selectedWorker.status !== WorkerStatus.Completed);
    return worker && selectedWorkers.length > 1
      ? 'Update workers statuses'
      : worker
      ? `Update to ${getWorkerStatusName(getWorkerNextStatus(worker.status))}`
      : 'Update not available';
  }, [selectedWorkers]);

  const resetSelectedWorkers = () => setSelectedWorkers(null);

  const userIsSubcontractor = user.roles?.every((role) => role === EROLES.subcontractor);

  const showBottomButtons = useMemo(
    () =>
      Boolean(selectedWorkers?.length) &&
      !workerStatusUpdateId &&
      !userIsSubcontractor &&
      ![JOB_STATUSES.Cancelled, JOB_STATUSES.CancelledBillable, JOB_STATUSES.Review].includes(jobDetail.jobStatus),
    [selectedWorkers, workerStatusUpdateId, userIsSubcontractor, jobDetail.jobStatus]
  );

  const handleSaveSuccess = () => {
    onSaveSuccess();
    resetSelectedWorkers();
  };

  const goToJobDetails = (event) => {
    event?.stopPropagation();
    const jobID = event.target.getAttribute('data-jobID');
    app_history.push(`/job/${jobID}`);
  };

  return (
    <>
      <div className={noBorder ? 'worker-group no-border' : 'worker-group'}>
        {groups.map((group, gIdx) =>
          group.workers.length <= 0 ? (
            <div key={group.location.id} className="location-row">
              <div className="d-flex mr-2">
                <RoomOutlinedIcon fontSize="large" style={{ color: '#BDBDBD' }} />
              </div>

              <div className="job-location logo-location-address">
                <span>
                  {group.location.address}{' '}
                  <span style={{ color: '#6f7780' }}>
                    {group.location.structure && group.location.structure !== 'null'
                      ? `(Structure #${group.location.structure})`
                      : ''}
                  </span>
                </span>
              </div>
            </div>
          ) : (
            <div className="d-flex" key={String(gIdx)}>
              <Collapsible
                trigger={
                  <div className="location-row">
                    <div className="location-address-row">
                      <div className="font-weight-bold border-right d-flex align-items-center px-2">
                        <span style={{ minWidth: '10px' }}>{openedGroupIdxs.has(gIdx) ? '-' : '+'}</span>
                        <span>({group.workers.length})</span>
                      </div>
                      <div className="job-location">
                        <div className="d-flex mr-2">
                          <RoomOutlinedIcon style={{ color: '#BDBDBD' }} />
                        </div>

                        <div className="logo-location-address">
                          <span>
                            {group.location.address}{' '}
                            <span style={{ color: '#6f7780' }}>
                              {group.location.structure && group.location.structure !== 'null'
                                ? `(Structure #${group.location.structure})`
                                : ''}
                            </span>
                            {Boolean(group.newJobID) && (
                              <span className="link" data-jobID={String(group.newJobID)} onClick={goToJobDetails}>
                                Job #{group.newJobID}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="avtars-and-epxand-row">
                      <div className="d-flex worker-link">
                        {group.workers.map((worker, idx) => (
                          <div
                            key={String(idx + worker.shift_id + worker.id)}
                            className="avatar-wrapper"
                            style={{ zIndex: 0 - idx }}
                          >
                            <img className="avatar" alt="avatar" src={worker.worker.avatar} />
                          </div>
                        ))}
                      </div>
                      <div className="d-flex px-2">
                        <div className="show-workers d-flex">
                          <PeopleOutlineIcon
                            sx={(theme) => ({
                              color: openedGroupIdxs.has(gIdx) ? '#aeb7c4' : theme.palette.primary.main,
                              marginRight: 5,
                              '& + span': {
                                color: openedGroupIdxs.has(gIdx) ? '#aeb7c4' : theme.palette.primary.main,
                              },
                            })}
                          />
                          <span style={{ minWidth: 100 }}>
                            {openedGroupIdxs.has(gIdx) ? 'Hide Workers' : 'Show Workers'}
                          </span>
                          <ArrowDropDownIcon
                            fontSize="small"
                            sx={(theme) => ({
                              transition: 'transform 0.2s ease-out',
                              color: openedGroupIdxs.has(gIdx) ? '#aeb7c4' : theme.palette.primary.main,
                              transform: openedGroupIdxs.has(gIdx) ? 'scale(1, -1)' : '',
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
                onOpen={() => setOpenedGroupIdxs((prev) => new Set([...Array.from(prev), gIdx]))}
                onClose={() =>
                  setOpenedGroupIdxs((prev) => {
                    const result = new Set(prev);
                    result.delete(gIdx);
                    return result;
                  })
                }
                easing="ease"
                className="collaped-content"
              >
                <div className="workers-row">
                  {group.workers.map((worker, id) => {
                    const isSelected = selectedWorkers?.find((item) => item.shift_id === worker.shift_id);
                    return (
                      <div
                        key={worker.shift_id + '-' + id}
                        className={styles.workerElement}
                        onClick={() => toggleSelectWorker(worker)}
                      >
                        <Checkbox
                          style={{ paddingTop: 8, alignSelf: 'flex-start' }}
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleCheckedFilled color="primary" />}
                          checked={Boolean(isSelected)}
                          id={worker.shift_id.toString() + id}
                          color="primary"
                          disabled={
                            user?.roles?.every((role) => role === EROLES.subcontractor) &&
                            worker?.worker?.company_id !== user?.subcontractor_id
                          }
                        />
                        <div style={{ fontSize: 14 }}>
                          <div className="text-16-blue">
                            <NavLink to={`/workers/${worker.id}`}>{worker.worker.name}</NavLink>
                            {worker.hasSeen ? <CeIcon.DobuleTickIcon className="has-seen ml-3" fill="green" /> : null}
                          </div>
                          <a style={{ color: '#000000' }} href={`tel:${worker.worker.phoneNumber}`}>
                            {worker.worker.phoneNumber}
                          </a>
                          <div className="worker-status-name" style={{ justifyContent: 'start' }}>
                            {workerStatusUpdateId === worker.id ? 'Updating...' : getWorkerStatusName(worker.status)}
                          </div>
                          {worker.blocked_by_reason &&
                          worker.blocked_by_reason !== 'null' &&
                          worker.status === WorkerStatus.CanNotSecure ? (
                            <div style={{ color: 'red', fontSize: 14, fontWeight: 400 }}>
                              <TrimmedText text={worker.blocked_by_reason} length={25} />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Collapsible>
            </div>
          )
        )}

        {showBottomButtons && (
          <div className="bottom-buttons">
            {selectedWorkers.some(isWorkerReleasable) && (
              <Button variant="contained" onClick={releaseWorkersModal.open} className="new-buttons blue">
                {selectedWorkers.length > 1 ? 'Release Workers' : 'Release Worker'}
              </Button>
            )}

            {selectedWorkers.every(({ status }) => canRerouteJobWorker(status)) ? (
              <>
                <Button
                  variant="contained"
                  onClick={(event) => openRerouteWorkerModal(event, false)}
                  style={{ width: '200px' }}
                  className="new-buttons blue"
                >
                  CES ReRoute
                </Button>
                {isFlagging && isJobStandby && (
                  <Button
                    variant="contained"
                    onClick={(event) => openRerouteWorkerModal(event, true)}
                    style={{ width: '200px' }}
                    className="new-buttons blue"
                  >
                    Reroute to Job
                  </Button>
                )}
              </>
            ) : null}

            {showUpdateWorkerStatusButton && (
              <Button className="new-buttons light" variant="outlined" onClick={updateWorkerStatusModal.open}>
                {updateWorkerStatusButtonName}
              </Button>
            )}
          </div>
        )}
      </div>

      {rerouteWorkersModal.isOpen && (
        <RerouteWorkersModal
          jobId={jobId}
          initToJob={rerouteToJob}
          jobType={jobDetail.jobType}
          is_standby={jobDetail.is_standby}
          confirmationNumber={jobDetail.confirmationNumber}
          department={jobDetail.department}
          open={rerouteWorkersModal.isOpen}
          onClose={closeRerouteWorkerModal}
          selectedWorkers={selectedWorkers}
          onSaveSuccess={handleSaveSuccess}
        />
      )}

      {updateWorkerStatusModal.isOpen && (
        <UpdateWorkersStatusesModal
          jobId={jobId}
          jobDetail={jobDetail}
          onChangeWorkers={onChangeWorkers}
          open={updateWorkerStatusModal.isOpen}
          onClose={updateWorkerStatusModal.close}
          selectedWorkers={selectedWorkers}
          onChangeWorkerStatusUpdateId={onChangeWorkerStatusUpdateId}
        />
      )}

      {releaseWorkersModal.isOpen && (
        <ReleaseSelectedWorkersDialog
          jobId={jobId}
          selectedWorkers={selectedWorkers}
          open={releaseWorkersModal.isOpen}
          onClose={releaseWorkersModal.close}
          unselectWorker={unselectWorker}
          onSuccess={resetSelectedWorkers}
        />
      )}
    </>
  );
};

export default WorkerGroup;
