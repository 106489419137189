import React from 'react';
import { useLocation } from 'react-router-dom';

import { FilterAltRounded, FilterRounded } from '@mui/icons-material';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { changeDispatchPageConfig } from 'Services/app/actions';
import { useAppDispatch, useAppSelector } from 'createStore';

const DispatchDisplayConfig = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const displayType = useAppSelector((state) => state.app.dispatchPageConfig.displayType);
  const showAllFilters = useAppSelector((state) => state.app.dispatchPageConfig.showAllFilters);
  const changeDisplayType = (event: React.MouseEvent<HTMLElement>, newDisplayType: 'list_map' | 'list' | null) => {
    if (newDisplayType !== null) {
      dispatch(changeDispatchPageConfig({ displayType: newDisplayType }));
    }
  };

  const toggleShowAllFilters = () => {
    dispatch(changeDispatchPageConfig({ showAllFilters: !showAllFilters }));
  };

  if (!location.pathname.includes('dispatch')) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', gap: 16 }}>
      <ToggleButtonGroup value={displayType} exclusive size="small" onChange={changeDisplayType}>
        <ToggleButton value="list_map">Full</ToggleButton>
        <ToggleButton value="list">Table</ToggleButton>
      </ToggleButtonGroup>
      <Button variant="outlined" sx={{ padding: 0.4, borderRadius: 2, minWidth: 20 }} onClick={toggleShowAllFilters}>
        <FilterAltRounded />
      </Button>
    </div>
  );
};

export default DispatchDisplayConfig;
